import React, { createContext, useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getNavigation } from '../store/actions/navigation';
import {isLogin} from "../utils/auth"; // Adjust the import path as needed

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
    const dispatch = useDispatch();
    const navigation = useSelector((state) => state.navigation.list);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchNavigation = async () => {
            setIsLoading(true);
            await dispatch(getNavigation());
            setIsLoading(false);
        };

        if(isLogin())
        {
            fetchNavigation();
        }

    }, [dispatch]);

    return (
        <NavigationContext.Provider value={{ navigation, isLoading }}>
            {children}
        </NavigationContext.Provider>
    );
};

export const useNavigation = () => useContext(NavigationContext);