import {
    ADD_SYSTEM_ROLE,
    GET_SYSTEM_ROLE,
    GET_SYSTEM_ROLES,
    UPDATE_SYSTEM_ROLE
} from "../types";


const initialState = {list: [], single: null};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_SYSTEM_ROLES:
            return {...state, list: payload};

        case GET_SYSTEM_ROLE:
            return {
                ...state,
                list: [payload, ...state.list],
            };
        case ADD_SYSTEM_ROLE:
            return {
                ...state,
                list: [payload, ...state.list],
            };

        case UPDATE_SYSTEM_ROLE:
            return {
                ...state,
                list: state.list.map((item) =>
                    item.id === payload.id ? payload : item
                ),
            };

        default:
            return state;
    }
};

export default reducer;
