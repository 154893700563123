import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
    Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input,
    Label, Modal, ModalBody, ModalFooter, ModalHeader, Row
} from "reactstrap";
import { PlusCircleFilled, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Select from "react-select";
import ReactStrapDataTable from "../../CustomDatatable/ReactStrapDataTable";
import { getMasterTables, getTableValues, addMasterForTables, updateMaster, deleteMaster } from "../../../store/actions/master";
import { dateTimeSort, stringSorter } from "../../../utils/functions";

const Master = () => {
    document.title = "HSSE Meinhardt EPCM | Master";
    const dispatch = useDispatch();
    const masterTables = useSelector((state) => state.master.list);
    const tableValues = useSelector((state) => state.master.tableValues);

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedMaster, setSelectedMaster] = useState(null);
    const [editingMaster, setEditingMaster] = useState(null);

    const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        defaultValues: {
            masterSelect: null,
            name: "",
            description: ""
        }
    });

    const masterOptions = masterTables.map(table => ({
        value: table.tableName,
        label: table.name
    }));

    const columns = [
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: dateTimeSort("createdAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: stringSorter("name"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            sorter: stringSorter("description"),
            sortDirections: ["ascend", "descend"],

        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            width: '120px',
            render: (status) => (
                <Button
                    color={status ? "primary" : "secondary"}
                    onClick={() => handleStatus(status)}
                >
                    {status ? "Active" : "Inactive"}
                </Button>
            )
        },

        {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
                <>
                    <Button color="link" onClick={() => handleEdit(record)}>
                        <EditOutlined />
                    </Button>
                    <Button color="link" onClick={() => handleDelete(record)}>
                        <DeleteOutlined />
                    </Button>
                </>
            ),
        },
    ];

    const handleStatus = async (status) => {

        console.log("Status toggled:", status);
    };

    const handleEdit = (master) => {
        setEditingMaster(master);
        setValue("masterSelect", { value: selectedMaster.value, label: selectedMaster.label });
        setValue("name", master.name);
        setValue("description", master.description);
        setModalOpen(true);
    };

    const handleDelete = async (master) => {
        if (window.confirm("Are you sure you want to delete this master?")) {
            await dispatch(deleteMaster(selectedMaster.value, master.id));
            dispatch(getTableValues(selectedMaster.value));
        }
    };

    const onFormSubmit = async (formData) => {
        try {
            const payload = {
                tableName: formData.masterSelect.value,
                name: formData.name,
                description: formData.description
            };
            if (editingMaster) {
                payload.id=editingMaster.id
                await dispatch(updateMaster(payload));
            } else {
                await dispatch(addMasterForTables(payload));
            }
            handleCloseModal();
            if (selectedMaster) {
                dispatch(getTableValues(selectedMaster.value));
            }
        } catch (error) {
            alert('An error occurred. Please try again.');
        }
    };

    const handleCloseModal = useCallback(() => {
        reset();
        setEditingMaster(null);
        setModalOpen(false);
    }, [reset]);

    const handleMasterChange = (selectedOption) => {
        setSelectedMaster(selectedOption);
        if (selectedOption) {
            dispatch(getTableValues(selectedOption.value));
        }
    };

    useEffect(() => {
        dispatch(getMasterTables());
    }, [dispatch]);

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">Manage Masters</h5>
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" onClick={() => setModalOpen(true)}>
                                <PlusCircleFilled /> Add New
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row className="mb-3">
                        <Col md={4}>
                            <Select
                                options={masterOptions}
                                value={selectedMaster}
                                onChange={handleMasterChange}
                                placeholder="Select Master"
                                isSearchable
                                isClearable
                                styles={{
                                    container: (provided) => ({
                                        ...provided,
                                        zIndex: 999 // You can adjust this value as needed
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                    <ReactStrapDataTable
                        dataSource={tableValues}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} backdrop="static">
                <ModalHeader toggle={handleCloseModal}>
                    {editingMaster ? 'Edit Master' : 'Add Master'}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="masterSelect">Select Master</Label>
                            <Controller
                                name="masterSelect"
                                control={control}
                                rules={{ required: "Master selection is required" }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={masterOptions}
                                        id="masterSelect"
                                        isDisabled={editingMaster !== null}
                                    />
                                )}
                            />
                            {errors.masterSelect && <span className="text-danger">{errors.masterSelect.message}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: "Name is required" }}
                                render={({ field }) => <Input {...field} id="name" />}
                            />
                            {errors.name && <span className="text-danger">{errors.name.message}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="description">Description</Label>
                            <Controller
                                name="description"
                                control={control}
                                rules={{ required: "Description is required" }}
                                render={({ field }) => <Input {...field} id="description" type="textarea" />}
                            />
                            {errors.description && <span className="text-danger">{errors.description.message}</span>}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {editingMaster ? 'Update' : 'Save'}
                        </Button>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
}

export default Master;