import {
    GET_COMPLAINT_VALUES,
    GET_SINGLE_USER_COMPLAINT,
    GET_USER_COMPLAINT,
    ADD_COMPLAINT,
    UPDATE_COMPLAINT,
    GENERATE_COMPLAINT,
    UPDATE_COMPLAINT_ACTION,
    DELETE_COMPLATIN_ACTION
} from "../../types";

const initialState = { list: [], single: null, userComplaint: {} };

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_COMPLAINT_VALUES:
            return { ...state, list: payload };
        case GET_USER_COMPLAINT:
            return { ...state, userComplaint: payload };
        case GET_SINGLE_USER_COMPLAINT:
            return { ...state, single: payload };
        case ADD_COMPLAINT:
            return { ...state, userComplaint: payload };
        case UPDATE_COMPLAINT:
            return {
                ...state,
                userComplaint: payload
            };
        case GENERATE_COMPLAINT:
            return {
                ...state,
                userComplaint: payload
            };
        case UPDATE_COMPLAINT_ACTION:
            return {
                ...state,
                userComplaint: state.userComplaint.map(complaint =>
                    complaint.id === payload.complaintId
                        ? {
                            ...complaint,
                            actions: complaint.actions.map(action =>
                                action.id === payload.updatedAction.id
                                    ? payload.updatedAction
                                    : action
                            )
                        }
                        : complaint
                )
            };
        case DELETE_COMPLATIN_ACTION:
                return {
                    ...state,
                    userComplaint: state.userComplaint.user.filter(object => object.id !== payload)
                };
        default:
            return state;
    }
};

export default reducer;