import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import { DeleteOutlined, EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import ReactStrapDataTable from "../../../CustomDatatable/ReactStrapDataTable";
import { getBusinessGroups, addBusinessGroup, updateBusinessGroup, deleteBusinessGroup } from "../../../../store/actions/business/businessgroup";
import { dateTimeSort, stringSorter } from "../../../../utils/functions";

const BusinessGroup = () => {
    const dispatch = useDispatch();
    const businessGroups = useSelector((state) => state.businessgroup.list);

    const [modalOpen, setModalOpen] = useState(false);
    const [editingBusinessGroup, setEditingBusinessGroup] = useState(null);

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        dispatch(getBusinessGroups());
    }, [dispatch]);

    const handleEdit = (businessGroup) => {
        setEditingBusinessGroup(businessGroup);
        reset(businessGroup);
        setModalOpen(true);
    };

    const handleDelete = async (businessGroup) => {
        if (window.confirm("Are you sure you want to delete this Business Group?")) {
            await dispatch(deleteBusinessGroup(businessGroup.id));
            dispatch(getBusinessGroups());
        }
    };

    const onFormSubmit = (data) => {
        if (editingBusinessGroup) {
            const payload = {
                id: editingBusinessGroup.id,
                name: data.name,
                code: data.code,
            };
            dispatch(updateBusinessGroup(payload));
        } else {
            dispatch(addBusinessGroup(data));
        }
        handleCloseModal();
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setEditingBusinessGroup(null);
        reset();
    };

    const handleAddNew = () => {
        setEditingBusinessGroup(null);
        reset({ name: '', code: '' });
        setModalOpen(true);
    };

    const columns = [
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: dateTimeSort("createdAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: stringSorter("name"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Code",
            dataIndex: "code",
            key: "code",
            sorter: stringSorter("code"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
                <>
                    <Button color="link" onClick={() => handleEdit(record)}>
                        <EditOutlined />
                    </Button>
                    <Button color="link" onClick={() => handleDelete(record)}>
                        <DeleteOutlined />
                    </Button>
                </>
            ),
        },
    ];

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">Manage Business Group</h5>
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" onClick={handleAddNew}>
                                <PlusCircleFilled /> Add New
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactStrapDataTable
                        dataSource={businessGroups}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} backdrop="static">
                <ModalHeader toggle={handleCloseModal}>
                    {editingBusinessGroup ? 'Edit Business Group' : 'Add Business Group'}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: "Name is required" }}
                                render={({ field }) => <Input {...field} id="name" />}
                            />
                            {errors.name && <span className="text-danger">{errors.name.message}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="code">Code</Label>
                            <Controller
                                name="code"
                                control={control}
                                rules={{ required: "Code is required" }}
                                render={({ field }) => <Input {...field} id="code" />}
                            />
                            {errors.code && <span className="text-danger">{errors.code.message}</span>}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {editingBusinessGroup ? 'Update' : 'Save'}
                        </Button>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
};

export default BusinessGroup;