import React, { useEffect, useState,useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Table,
} from "reactstrap";
import {
  EditOutlined,
  PlusCircleFilled,
  EyeOutlined,
  CheckOutlined,
  CloseOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import { message, Tag } from "antd";
import {
  getPermitToWorkValues,
  addPermit,
  updatePermit,
  getUserPermits,
  submitPermitToWork,
  submitReviewComment,
  submitApproveAndReject,
  startPermitToWork,
  finishPermitToWork,
  deletePermitToWork
} from "../../store/actions/PermitToWork";
import { AccessFilter, stringSorter } from "../../utils/functions";
import ReactStrapDataTable from "../CustomDatatable/ReactStrapDataTable";
import fileDownload from "react-file-download";
import { format, isAfter, isBefore, isEqual } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { GET_USER_PERMIT_WORK } from "../../store/types";

const AttachmentsSection = ({ attachments = [], onAttachmentsChange }) => {
  const [internalKeys, setInternalKeys] = useState({});

  useEffect(() => {
    // Initialize internalKeys with existing attachments
    const initialKeys = attachments.reduce((acc, attachment, index) => {
      acc[`attachment_${index}`] = attachment;
      return acc;
    }, {});
    setInternalKeys(initialKeys);
  }, []);

  const generateKey = () =>
    `attachment_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

  const addNewRow = () => {
    const newAttachment = {
      filename: "",
      name: "",
      description: "",
      createdBy: null,
      updatedAt: null,
      updatedBy: null,
      file: null,
      path: null,
    };
    const newKey = generateKey();
    setInternalKeys((prev) => ({ ...prev, [newKey]: newAttachment }));
    onAttachmentsChange([...attachments, newAttachment]);
  };

  const updateAttachment = (key, field, value) => {
    const updatedAttachments = Object.values(internalKeys).map((att, index) =>
      att === internalKeys[key] ? { ...att, [field]: value } : att,
    );
    setInternalKeys((prev) => ({
      ...prev,
      [key]: { ...prev[key], [field]: value },
    }));
    onAttachmentsChange(updatedAttachments);
  };

  const handleFileChange = (key, file) => {
    if (file && file.size > 5 * 1024 * 1024) {
      alert("File size should not exceed 5MB");
      return;
    }

    const updatedAttachment = {
      ...internalKeys[key],
      filename: file.name,
      name: file.name,
      file: file,
      path: null,
    };
    setInternalKeys((prev) => ({
      ...prev,
      [key]: updatedAttachment,
    }));
    const updatedAttachments = Object.values(internalKeys).map((att) =>
      att === internalKeys[key] ? updatedAttachment : att,
    );
    onAttachmentsChange(updatedAttachments);
  };

  const deleteAttachment = (key) => {
    const filteredAttachments = Object.values(internalKeys).filter(
      (att) => att !== internalKeys[key],
    );
    setInternalKeys((prev) => {
      const newKeys = { ...prev };
      delete newKeys[key];
      return newKeys;
    });
    onAttachmentsChange(filteredAttachments);
  };

  const viewAttachment = (attachment) => {
    if (attachment.file instanceof File) {
      const url = URL.createObjectURL(attachment.file);
      window.open(url, "_blank");
    } else if (attachment.fullPath || attachment.path) {
      fetch(`${encodeURIComponent(attachment.fullPath || attachment.path)}`)
        .then((response) => response.blob())
        .then((blob) => {
          fileDownload(
            blob,
            attachment.fileName || attachment.filename || "download",
          );
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
          alert("Error downloading file. Please try again.");
        });
    }
  };

  return (
    <Card
      style={{
        border: "2px solid #ced4da",
        borderRadius: "5px",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
      }}
    >
      <CardHeader
        style={{
          backgroundColor: "#e6f2ff",
          borderBottom: "1px solid #b8daff",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2 className="card-title mb-0">Attachments</h2>
        <Button color="primary" size="sm" onClick={addNewRow}>
          <i className="fas fa-plus"></i>
        </Button>
      </CardHeader>
      <CardBody>
        <Table>
          <thead>
            <tr>
              <th>File Name</th>
              <th>Description</th>
              <th>Upload File</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(internalKeys).map(([key, attachment]) => (
              <tr key={key}>
                <td>{attachment.filename || attachment.name}</td>
                <td>
                  <Input
                    type="text"
                    value={attachment.description}
                    onChange={(e) =>
                      updateAttachment(key, "description", e.target.value)
                    }
                    style={{ borderColor: "black" }}
                  />
                </td>
                <td>
                  <FormGroup>
                    <Label for={`file-${key}`} className="btn btn-secondary">
                      {attachment.file || attachment.path
                        ? "Change File"
                        : "Upload File"}
                    </Label>
                    <Input
                      type="file"
                      id={`file-${key}`}
                      onChange={(e) => handleFileChange(key, e.target.files[0])}
                      style={{ display: "none" }}
                    />
                  </FormGroup>
                </td>
                <td>
                  <Button
                    color="secondary"
                    size="sm"
                    className="mr-2"
                    onClick={() => viewAttachment(attachment)}
                    disabled={!attachment.file && !attachment.path}
                  >
                    {attachment.fullPath || attachment.path
                      ? "Download"
                      : "View"}
                  </Button>
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() => deleteAttachment(key)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};
const PermitIsolationSection = ({
  permitIsolationTypes,
  initialData,
  onDataChange,
  defaultIsRequired = "No", // New prop with default value
}) => {
  const [data, setData] = useState(initialData || []);

  useEffect(() => {
    if (initialData) {
      setData(initialData);
    }
  }, [initialData]);

  const handleChange = (index, field, value) => {
    const newData = [...data];
    newData[index][field] = value;
    setData(newData);
    onDataChange(newData);
  };

  const addRow = () => {
    const newRow = {
      type: "",
      description: "",
      isRequired: defaultIsRequired, // Use the default value here
      comments: "",
    };
    setData([...data, newRow]);
    onDataChange([...data, newRow]);
  };

  const removeRow = (index) => {
    const newData = data.filter((_, i) => i !== index);
    setData(newData);
    onDataChange(newData);
  };

  const yesNoOptions = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  return (
    <Card
      style={{
        border: "2px solid #ced4da",
        borderRadius: "5px",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
      }}
    >
      <CardHeader
        style={{
          backgroundColor: "#e6f2ff",
          borderBottom: "1px solid #b8daff",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2 className="card-title mb-0">Permit Isolation</h2>
        <Button color="primary" size="sm" onClick={addRow}>
          <i className="fas fa-plus"></i>
        </Button>
      </CardHeader>
      <CardBody>
        <Table bordered>
          <thead>
            <tr>
              <th>Permit Isolation Type</th>
              <th>Description</th>
              <th>Required</th>
              <th>Comments</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                <td>
                  <Select
                    value={permitIsolationTypes.find(
                      (option) => option.value === row.type,
                    )}
                    onChange={(selectedOption) =>
                      handleChange(index, "type", selectedOption.value)
                    }
                    options={permitIsolationTypes}
                    placeholder="Select Type"
                  />
                </td>
                <td>
                  <Input
                    type="text"
                    value={row.description}
                    onChange={(e) =>
                      handleChange(index, "description", e.target.value)
                    }
                    placeholder="Description"
                  />
                </td>
                <td>
                  <Select
                    value={yesNoOptions.find(
                      (option) => option.value === row.isRequired,
                    )}
                    onChange={(selectedOption) =>
                      handleChange(index, "isRequired", selectedOption.value)
                    }
                    options={yesNoOptions}
                    placeholder="Select"
                  />
                </td>
                <td>
                  <Input
                    type="text"
                    value={row.comments}
                    onChange={(e) =>
                      handleChange(index, "comments", e.target.value)
                    }
                    placeholder="Comments"
                  />
                </td>
                <td>
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() => removeRow(index)}
                  >
                    <i className="fas fa-minus"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

const PermitToWork = ({access}) => {
  const dispatch = useDispatch();
  const permitValues = useSelector((state) => state.permit.values);
  const singlePermit = useSelector((state) => state.permit.single);
  const userPermits = useSelector((state) => state.permit.userPermitWork);
  const [filteredPermits, setFilteredPermits] = useState([]);
  const [statusFilter, setStatusFilter] = useState("ALL");
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [editingPermit, setEditingPermit] = useState(null);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [selectedPermit, setSelectedPermit] = useState(null);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [reviewComment, setReviewComment] = useState("");
  const [selectedAction, setSelectedAction] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [permitToWorkDelete, setPermitToDelete] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const [startModalOpen, setStartModalOpen] = useState(false);
  const [finishModalOpen, setFinishModalOpen] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedFinishDate, setSelectedFinishDate] = useState(null);
  const [currentPermit, setCurrentPermit] = useState(null);


   //SharedAccessFilter
   const [accessFilter, setAccessFilter] = useState('My');

   const handleAccessFilterChange = (selectedAccess) => {
       setAccessFilter(selectedAccess);
   };

  

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    disabled: isFormDisabled,
  });

  const dateFrom = watch("dateFrom");
  const [attachments, setAttachments] = useState([]);
  const [permitIsolations, setPermitIsolations] = useState([]);

  //GENERATE PERMIT WORK
  //ViewRecord
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [generatePermitWork, setGeneratePermitWork] = useState(null);

  const confirmPermitWork = async () => {
    if (generatePermitWork) {
      const object={
        moduleId:access.moduleId,
        id:generatePermitWork.id
      }
      const response = await dispatch(
        submitPermitToWork(object),
      );
      if (response && response.data.code === 1) {
        message.success("Permit to Work generated successfully");
      } else {
        message.error("Failed to generate Permit to Work");
      }
      setConfirmModalOpen(false);
      setGeneratePermitWork(null);
      dispatch(getUserPermits(access.moduleId));
    }
  };

  useEffect(() => {
    dispatch(getPermitToWorkValues());
    dispatch(getUserPermits(access.moduleId));
  }, [dispatch]);

  useEffect(() => {
    setFilteredPermits(userPermits);
  }, [userPermits]);

  useEffect(() => {
    console.log("userPermits")
    console.log(userPermits)
  }, [userPermits]);

  const handleStatusFilterChange = (selectedOption) => {
    setStatusFilter(selectedOption);

    if (selectedOption.value === "ALL") {
      setFilteredPermits(userPermits);
    } else {
      const filtered = userPermits.filter(
        (permit) =>
          permit.aStatus.toLowerCase() === selectedOption.value.toLowerCase(),
      );
      setFilteredPermits(filtered);
    }
  };

  const statusOptions = [
    { value: "ALL", label: "ALL" },
    { value: "PENDING", label: "PENDING" },
    { value: "APPROVED", label: "APPROVED" },
    { value: "REJECTED", label: "REJECTED" },
  ];

  useEffect(() => {
    console.log("isFormDisabled is now:", isFormDisabled);
  }, [isFormDisabled]);

  useEffect(() => {
    console.log(permitValues);
    setIsLoading(false);
  }, [permitValues, userPermits]);

  const filterPermitToWork = useMemo(() => {
    switch (accessFilter) {
        case 'My':
            return [
                ...(userPermits.user || []),
                ...(userPermits.shared || [])
            ];
        case 'Shared':
            return userPermits.shared || [];
        case 'Assigned':
            return userPermits.assigned || [];
        default:
            return [];
    }
}, [userPermits, accessFilter]);

  const handleAddNew = () => {
    setEditingPermit(null);
    setAttachments([]);
    setPermitIsolations([]);
    reset({});
    setModalOpen(true);
  };

  const handleEdit = (permit) => {
    setEditingPermit(permit);

    const permitTypeOptions = permitValues.permitTypes.map((type) => ({
      value: type.id,
      label: type.name,
    }));
    const selectedPermitType =
      permitTypeOptions.find((source) => source.value === permit.ptId) || null;

    reset({
      description: permit.description,
      permitIssuer: { value: permit.issuerId, label: permit.permitIssuer },
      permitReviewer: {
        value: permit.reviewerId,
        label: permit.permitReviewer,
      },
      permitType: selectedPermitType, // Assuming permitType is available
      l3: { value: permit.l3Id, label: permit.l3Contractor },
      site: { value: permit.siteId, label: permit.site },
      locationOfWork: permit.workLocation,
      supervisor: permit.supervisorName,
      supervisorMobile: permit.supervisorMobile,
      dateFrom: new Date(permit.fromDate).toISOString().slice(0, 16),
      dateTo: new Date(permit.toDate).toISOString().slice(0, 16),
      summaryOfWork: permit.workSummary,
      checklistsAttached: permit.checklist
        ? { value: true, label: "Yes" }
        : { value: false, label: "No" },
    });

    // Set attachments
    if (permit.files !== null) {
      const formattedAttachments = permit.files.map((file) => ({
        id: file.id,
        filename: file.fileName,
        name: file.fileName,
        description: file.description,
        path: file.path,
        fullPath: file.fullPath,
        createdAt: file.createdAt,
        createdBy: file.createdBy,
        updatedAt: file.updatedAt,
        updatedBy: file.updatedBy,
      }));
      setAttachments(formattedAttachments);
    } else {
      setAttachments([]);
    }

    // Set permit isolations
    const formattedIsolations = permit.isolations.map((isolation) => ({
      id: isolation.id,
      type: isolation.type,
      description: isolation.description,
      isRequired: isolation.isRequired,
      comments: isolation.comments,
      createdAt: isolation.createdAt,
      createdBy: isolation.createdBy,
      updatedAt: isolation.updatedAt,
      updatedBy: isolation.updatedBy,
    }));
    setPermitIsolations(formattedIsolations);
    setModalOpen(true);
  };

  const handleView = (permit) => {
    setEditingPermit(permit);

    const permitTypeOptions = permitValues.permitTypes.map((type) => ({
      value: type.id,
      label: type.name,
    }));
    const selectedPermitType =
      permitTypeOptions.find((source) => source.value === permit.ptId) || null;

    reset({
      description: permit.description,
      permitIssuer: { value: permit.issuerId, label: permit.permitIssuer },
      permitReviewer: {
        value: permit.reviewerId,
        label: permit.permitReviewer,
      },
      permitType: selectedPermitType, // Assuming permitType is available
      l3: { value: permit.l3Id, label: permit.l3Contractor },
      site: { value: permit.siteId, label: permit.site },
      locationOfWork: permit.workLocation,
      supervisor: permit.supervisorName,
      supervisorMobile: permit.supervisorMobile,
      dateFrom: new Date(permit.fromDate).toISOString().slice(0, 16),
      dateTo: new Date(permit.toDate).toISOString().slice(0, 16),
      summaryOfWork: permit.workSummary,
      checklistsAttached: permit.checklist
        ? { value: true, label: "Yes" }
        : { value: false, label: "No" },
    });

    // Set attachments
    if (permit.files !== null) {
      const formattedAttachments = permit.files.map((file) => ({
        id: file.id,
        filename: file.fileName,
        name: file.fileName,
        description: file.description,
        path: file.path,
        fullPath: file.fullPath,
        createdAt: file.createdAt,
        createdBy: file.createdBy,
        updatedAt: file.updatedAt,
        updatedBy: file.updatedBy,
      }));
      setAttachments(formattedAttachments);
    } else {
      setAttachments([]);
    }

    // Set permit isolations
    const formattedIsolations = permit.isolations.map((isolation) => ({
      id: isolation.id,
      type: isolation.type,
      description: isolation.description,
      isRequired: isolation.isRequired,
      comments: isolation.comments,
      createdAt: isolation.createdAt,
      createdBy: isolation.createdBy,
      updatedAt: isolation.updatedAt,
      updatedBy: isolation.updatedBy,
    }));
    setPermitIsolations(formattedIsolations);
    setModalOpen(true);
    setIsFormDisabled(true);
  };



  const onSubmit = async (data) => {
    const formDataObj = new FormData();

    const mappedData = {
      description: data.description,
      issuerId: data.permitIssuer.value,
      reviewerId: data.permitReviewer.value,
      siteId: data.site.value,
      l3Id: data.l3.value,
      supervisorName: data.supervisor,
      supervisorMobile: data.supervisorMobile,
      workLocation: data.locationOfWork,
      ptId: data.permitType.value,
      fromDate: data.dateFrom,
      toDate: data.dateTo,
      workSummary: data.summaryOfWork,
      checklist: data.checklistsAttached.value,
    };

    // Append mapped data to FormData
    Object.keys(mappedData).forEach((key) => {
      formDataObj.append(key, mappedData[key]);
    });

    // Handle attachments
    if (Array.isArray(attachments)) {
      const filesMetadata = attachments.map((attachment) => ({
        id: attachment.id || null,
        fileName: attachment.filename || attachment.name || "",
        description: attachment.description || null,
        path: attachment.path || null,
        createdAt: attachment.createdAt || null,
        createdBy: attachment.createdBy || null,
        updatedBy: attachment.updatedBy || null,
        updatedAt: attachment.updatedAt || null,
      }));
      formDataObj.append("files", JSON.stringify(filesMetadata));

      attachments.forEach((attachment, index) => {
        if (attachment.file instanceof File) {
          formDataObj.append(
            `files[${index}]`,
            attachment.file,
            attachment.filename || attachment.name,
          );
        }
      });
    } else {
      formDataObj.append("files", JSON.stringify([]));
    }

    // Handle permit isolations
    if (Array.isArray(permitIsolations)) {
      const isolationsWithMetadata = permitIsolations.map((isolation) => ({
        ...isolation,
        id: isolation.id || null,
        createdAt: isolation.createdAt || null,
        createdBy: isolation.createdBy || null,
        updatedAt: isolation.updatedAt || null,
        updatedBy: isolation.updatedBy || null,
      }));
      formDataObj.append("isolations", JSON.stringify(isolationsWithMetadata));
    } else {
      formDataObj.append("isolations", JSON.stringify([]));
    }

    let result;
    if (editingPermit) {
      formDataObj.append("id", editingPermit.id);
      formDataObj.append("fileId", editingPermit.fileId);
      formDataObj.append("isolationId", editingPermit.isolationId);
      result = dispatch(updatePermit(formDataObj));
    } else {
      result = dispatch(addPermit(formDataObj));
    }

    dispatch({type:GET_USER_PERMIT_WORK,payload:[]})
    dispatch(getUserPermits(access.moduleId));
    setModalOpen(false)
    // if (result) {
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 0);
    // }
  };

  const handleGenerate = async (data) => {
    setGeneratePermitWork(data);
    setConfirmModalOpen(true);
  };

  const handleReview = (data) => {
    setSelectedPermit(data);
    setIsReviewModalOpen(true);
  };
  const handleConfirm = () => {
    let object = {};
    if (selectedAction === "accept") {
      object = { aStatus: "APPROVED", id: parseInt(selectedPermit.id) };
    } else if (selectedAction === "reject") {
      object = { aStatus: "REJECTED", id: parseInt(selectedPermit.id) };
    }
    dispatch(submitApproveAndReject(object));
    setIsModalVisible(false);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000);
    dispatch(getUserPermits(access.moduleId));
  };
  const showConfirmModal = (actionType, permit) => {
    setSelectedAction(actionType);
    setSelectedPermit(permit);
    setIsModalVisible(true);
  };

  const handleStart = (permit) => {
    setCurrentPermit(permit);
    setSelectedStartDate(new Date());
    setStartModalOpen(true);
  };

  const handleStartConfirm = () => {
    if (selectedStartDate) {
      
      const object={
        startedAt:format(selectedStartDate, 'yyyy-MM-dd HH:mm:ss'),
        id:parseInt(currentPermit.id)
      }
      dispatch(startPermitToWork(object));
      setStartModalOpen(false);
      dispatch({type:GET_USER_PERMIT_WORK,payload:[]})
      dispatch(getUserPermits(access.moduleId));
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
    }
  };
  const handleFinish = (permit) => {
    setCurrentPermit(permit);
    setSelectedFinishDate(new Date());
    setFinishModalOpen(true);
  };

  const handleFinishConfirm = () => {
    if (selectedFinishDate) {
      const object={
        finishedAt:format(selectedFinishDate, 'yyyy-MM-dd HH:mm:ss'),
        id:parseInt(currentPermit.id)
      }
      dispatch(finishPermitToWork(object));
      setFinishModalOpen(false);
      dispatch({type:GET_USER_PERMIT_WORK,payload:[]})
      dispatch(getUserPermits(access.moduleId));
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
    }
  };

  const isDateDisabled = (date, type) => {
    if (!currentPermit) return true;
    const fromDate = new Date(currentPermit.fromDate);
    const toDate = new Date(currentPermit.toDate);
    const startedAt = currentPermit.startedAt ? new Date(currentPermit.startedAt) : null;

    if (type === 'start') {
      return isBefore(date, fromDate) || isAfter(date, toDate);
    } else if (type === 'finish') {
      return isBefore(date, startedAt) || isAfter(date, toDate);
    }
    return false;
  };
  const handleDelete = (object) => {
    setPermitToDelete(object);
    setDeleteModalVisible(true);
  };
  const confirmDelete = async () => {
    try {
      dispatch(deletePermitToWork(permitToWorkDelete.id))
      setDeleteModalVisible(false);
      setPermitToDelete(null)
    } catch (error) {
      alert('An error occurred while deleting the hazard');
    }
  };

  let columns = [
    {
      title: "PermitStatus",
      dataIndex: "aStatus",
      key: "aStatus",
      sorter: stringSorter("aStatus"),
      sortDirections: ["ascend", "descend"],
      render: (status) => {
        const statusConfig = {
          pending: {
            icon: <ClockCircleOutlined />,
            color: "warning",
            label: "Pending",
          },
          approved: {
            icon: <CheckCircleOutlined />,
            color: "success",
            label: "Approved",
          },
          rejected: {
            icon: <CloseCircleOutlined />,
            color: "error",
            label: "Rejected",
          },
        };

        const { icon, color, label } = (statusConfig[status?.toLowerCase()] ||
          statusConfig.pending) ?? { icon: null, color: null, label: null };

        return (
          <Tag icon={icon} color={color}>
            {label}
          </Tag>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "220px",
      render: (text, data) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <Button color="info" size="sm" onClick={() => handleView(data)}>
            <EyeOutlined />
          </Button>
          {data.submittedAt === null && (
            <>
              <Button color="primary" size="sm" onClick={() => handleEdit(data)}>
                <EditOutlined />
              </Button>
              <Button color="danger" size="sm" onClick={() => handleDelete(data)}>
                <DeleteOutlined />
              </Button>
            </>
          )}
          {data.submittedAt!==null && data.aStatus === "APPROVED" && data.canWork && (
            <>
              {data.startedAt === null ? (
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => handleStart(data)}
                >
                  Start
                </Button>
              ) : data.finishedAt === null ? (
                <Button
                  color="success"
                  size="sm"
                  onClick={() => handleFinish(data)}
                >
                  Finish
                </Button>
              ) : null}
            </>
          )}
        </div>
      ),
    },
    {
      title: "Sequence Number",
      dataIndex: "submittedAt",
      key: "submittedAt",
      sorter: stringSorter("submittedAt"),
      sortDirections: ["ascend", "descend"],
      render: (text, data) => {
        if (data.submittedAt !== null) {
          return data.sequenceNo; // Assuming the sequence string is stored in the 'sequence' property
        } else {
          return (
            <Button
              color="primary"
              size="sm"
              onClick={() => handleGenerate(data)}
            >
              Generate
            </Button>
          );
        }
      },
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      sorter: stringSorter("comment"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "L3 Contractor",
      dataIndex: "l3Contractor",
      key: "l3Contractor",
      sorter: stringSorter("l3Contractor"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      sorter: stringSorter("country"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Business Unit",
      dataIndex: "businessUnit",
      key: "businessUnit",
      sorter: stringSorter("businessUnit"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Project/Program",
      dataIndex: "project",
      key: "project",
      sorter: stringSorter("project"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Site",
      dataIndex: "site",
      key: "site",
      sorter: stringSorter("site"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Location Place",
      dataIndex: "location",
      key: "location",
      sorter: stringSorter("location"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: stringSorter("description"),
      ellipsis: true,
    },
  ];

  if (isLoading) {
    return <Spinner color="primary" />;
  }

  const yesNoOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const handleReviewSubmit = async () => {
    console.log("Review submitted for permit:", selectedPermit.id);
    console.log("Review comment:", reviewComment);

    const object = {
      id: parseInt(selectedPermit.id),
      comment: reviewComment,
    };
    dispatch(submitReviewComment(object));
    setTimeout(() => {
      window.location.reload();
    }, 1000);
    setIsReviewModalOpen(false);
    setIsFormDisabled(false);
    setReviewComment("");
  };

  const handleMainModalClose = () => {
    reset(
      {},
      {
        keepValues: false,
        keepDirty: false,
        keepIsSubmitted: false,
        keepTouched: false,
        keepErrors: false,
        keepDirtyValues: false,
      },
    );
    setIsFormDisabled((prevState) => {
      console.log("isFormDisabled will be set to:", false);
      return false;
    });
  };

  return (
    <div>
      <Card>
        <CardHeader>
        <Row className="align-items-center mb-3">
                <Col md={3}>
                    <h5 className="mb-0">Manage Permit To Work</h5>
                </Col>
                <Col md={9} className="d-flex justify-content-end align-items-center">
                    <AccessFilter
                        accessFilter={accessFilter}
                        handleAccessFilterChange={handleAccessFilterChange}
                        sharedAccess={userPermits?.sharedAccess || []}
                    />
                    <Button color="primary" onClick={() => setModalOpen(true)} className="ms-2">
                        <PlusCircleFilled size={18} /> New
                    </Button>
                </Col>
          </Row>
          {/* <Row className="align-items-center">
            <Col>
              <h5 className="mb-0">Manage Permits to Work</h5>
            </Col>
            <Col xs="auto">
              <FormGroup className="mb-0 mr-2" style={{ minWidth: "200px" }}>
                <Label for="statusFilter" className="mr-2">
                  Filter Status:
                </Label>
                <Select
                  id="statusFilter"
                  options={statusOptions}
                  value={statusFilter}
                  onChange={handleStatusFilterChange}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs="auto">
              <Button color="primary" onClick={handleAddNew}>
                <PlusCircleFilled /> Add New Permit
              </Button>
            </Col>
          </Row> */}
        </CardHeader>
        <CardBody>
          <ReactStrapDataTable
            dataSource={filterPermitToWork}
            columns={columns}
            rowKey="id"
          />
        </CardBody>
      </Card>

      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(false)}
        size="xl"
        onClosed={handleMainModalClose}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpen(false)}>
          {isFormDisabled
            ? "Permit to Work"
            : editingPermit
              ? "Edit Permit to Work"
              : "Add New Permit to Work"}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="description">Description</Label>
                  <Controller
                    name="description"
                    control={control}
                    rules={{ required: "Description is required" }}
                    render={({ field }) => (
                      <Input {...field} type="textarea" id="description" />
                    )}
                  />
                  {errors.description && (
                    <span className="text-danger">
                      {errors.description.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label for="permitIssuer">Permit Issuer</Label>
                  <Controller
                    name="permitIssuer"
                    control={control}
                    rules={{ required: "Permit Issuer is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={
                          permitValues.issuer?.map((issuer) => ({
                            value: issuer.id,
                            label: issuer.name,
                          })) || []
                        }
                        isDisabled={
                          isFormDisabled ||
                          (editingPermit && editingPermit?.submittedAt !== null)
                        }
                      />
                    )}
                  />
                  {errors.permitIssuer && (
                    <span className="text-danger">
                      {errors.permitIssuer.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="permitReviewer">Permit Reviewer</Label>
                  <Controller
                    name="permitReviewer"
                    control={control}
                    rules={{ required: "Permit Reviewer is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={
                          permitValues.reviewer?.map((reviewer) => ({
                            value: reviewer.id,
                            label: reviewer.name,
                          })) || []
                        }
                        isDisabled={
                          isFormDisabled ||
                          (editingPermit && editingPermit?.submittedAt !== null)
                        }
                      />
                    )}
                  />
                  {errors.permitReviewer && (
                    <span className="text-danger">
                      {errors.permitReviewer.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="permitType">Permit Type</Label>
                  <Controller
                    name="permitType"
                    control={control}
                    rules={{ required: "Permit Type is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={
                          permitValues.permitTypes?.map((type) => ({
                            value: type.id,
                            label: type.name,
                          })) || []
                        }
                        isDisabled={isFormDisabled}
                      />
                    )}
                  />
                  {errors.permitType && (
                    <span className="text-danger">
                      {errors.permitType.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label for="l3">L3</Label>
                  <Controller
                    name="l3"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={
                          permitValues.l3?.map((l3) => ({
                            value: l3.id,
                            label: l3.name,
                          })) || []
                        }
                        isDisabled={isFormDisabled}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="site">Site</Label>
                  <Controller
                    name="site"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={
                          permitValues.sites?.map((site) => ({
                            value: site.id,
                            label: site.name,
                          })) || []
                        }
                        isDisabled={isFormDisabled}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="locationOfWork">Location of Work</Label>
                  <Controller
                    name="locationOfWork"
                    control={control}
                    rules={{ required: "Location of Work is required" }}
                    render={({ field }) => (
                      <Input {...field} type="text" id="locationOfWork" />
                    )}
                  />
                  {errors.locationOfWork && (
                    <span className="text-danger">
                      {errors.locationOfWork.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="supervisor">Supervisor</Label>
                  <Controller
                    name="supervisor"
                    control={control}
                    rules={{ required: "Supervisor is required" }}
                    render={({ field }) => (
                      <Input {...field} type="text" id="supervisor" />
                    )}
                  />
                  {errors.supervisor && (
                    <span className="text-danger">
                      {errors.supervisor.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="supervisorMobile">Supervisor Mobile</Label>
                  <Controller
                    name="supervisorMobile"
                    control={control}
                    rules={{ required: "Supervisor Mobile is required" }}
                    render={({ field }) => (
                      <Input {...field} type="tel" id="supervisorMobile" />
                    )}
                  />
                  {errors.supervisorMobile && (
                    <span className="text-danger">
                      {errors.supervisorMobile.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="dateFrom">Date From</Label>
                  <Controller
                    name="dateFrom"
                    control={control}
                    rules={{ required: "Date From is required" }}
                    defaultValue={new Date().toISOString().slice(0, 16)}
                    render={({ field }) => (
                      <Input {...field} type="datetime-local" id="dateFrom" />
                    )}
                  />
                  {errors.dateFrom && (
                    <span className="text-danger">
                      {errors.dateFrom.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="dateTo">Date To</Label>
                  <Controller
                    name="dateTo"
                    control={control}
                    rules={{
                      required: "Date To is required",
                      validate: (value) =>
                        new Date(value) > new Date(dateFrom) ||
                        "Date To must be greater than Date From",
                    }}
                    defaultValue={new Date(Date.now() + 7 * 86400000)
                      .toISOString()
                      .slice(0, 16)}
                    render={({ field }) => (
                      <Input {...field} type="datetime-local" id="dateTo" />
                    )}
                  />
                  {errors.dateTo && (
                    <span className="text-danger">{errors.dateTo.message}</span>
                  )}
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for="summaryOfWork">Summary of Work</Label>
                  <Controller
                    name="summaryOfWork"
                    control={control}
                    rules={{ required: "Summary of Work is required" }}
                    render={({ field }) => (
                      <Input {...field} type="textarea" id="summaryOfWork" />
                    )}
                  />
                  {errors.summaryOfWork && (
                    <span className="text-danger">
                      {errors.summaryOfWork.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for="checklistAttached">Are Checklists Attached</Label>
                  <Controller
                    name="checklistsAttached"
                    control={control}
                    defaultValue=""
                    rules={{ required: "This field is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={yesNoOptions}
                        placeholder="Select"
                        isDisabled={isFormDisabled}
                      />
                    )}
                  />
                  {errors.checklistAttached && (
                    <span className="text-danger">
                      {errors.checklistAttached.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <AttachmentsSection
              attachments={attachments}
              onAttachmentsChange={setAttachments}
            />

            <PermitIsolationSection
              permitIsolationTypes={(permitValues.permitIsolations || []).map(
                (type) => ({ value: type.id, label: type.name }),
              )}
              initialData={permitIsolations}
              onDataChange={setPermitIsolations}
            />

            {!isFormDisabled && (
              <Button color="primary" type="submit">
                {editingPermit ? "Update" : "Save"}
              </Button>
            )}
          </Form>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={confirmModalOpen}
        toggle={() => setConfirmModalOpen(false)}
      >
        <ModalHeader toggle={() => setConfirmModalOpen(false)}>
          Confirm Submission
        </ModalHeader>
        <ModalBody>
          <p>Are you sure you want to submit this Permit to Work?</p>
          <p style={{ color: "red" }}>
            Note: Once the Permit to Work is submitted it cannot be edited.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setConfirmModalOpen(false)}>
            Cancel
          </Button>
          <Button color="primary" onClick={confirmPermitWork}>
            Yes, Submit
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={startModalOpen} toggle={() => setStartModalOpen(false)}>
        <ModalHeader toggle={() => setStartModalOpen(false)}>Select Start Date</ModalHeader>
        <ModalBody>
          <DatePicker
            selected={selectedStartDate}
            onChange={(date) => setSelectedStartDate(date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="yyyy-MM-dd HH:mm"
            minDate={currentPermit ? new Date(currentPermit.fromDate) : null}
            maxDate={currentPermit ? new Date(currentPermit.toDate) : null}
            filterDate={(date) => !isDateDisabled(date, 'start')}
            inline
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleStartConfirm}>Confirm</Button>{' '}
          <Button color="secondary" onClick={() => setStartModalOpen(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={finishModalOpen} toggle={() => setFinishModalOpen(false)}>
        <ModalHeader toggle={() => setFinishModalOpen(false)}>Select Finish Date</ModalHeader>
        <ModalBody>
          <DatePicker
            selected={selectedFinishDate}
            onChange={(date) => setSelectedFinishDate(date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="yyyy-MM-dd HH:mm"
            minDate={currentPermit ? new Date(currentPermit.startedAt) : null}
            maxDate={currentPermit ? new Date(currentPermit.toDate) : null}
            filterDate={(date) => !isDateDisabled(date, 'finish')}
            inline
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleFinishConfirm}>Confirm</Button>{' '}
          <Button color="secondary" onClick={() => setFinishModalOpen(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={deleteModalVisible} toggle={() => setDeleteModalVisible(false)}>
        <ModalHeader toggle={() => setDeleteModalVisible(false)}>Confirm Delete</ModalHeader>
        <ModalBody>
          <p>Are you sure you want to delete this Permit to Work?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setDeleteModalVisible(false)}>Cancel</Button>
          <Button color="danger" onClick={confirmDelete}>Delete</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PermitToWork;
