import axios from "../../../axios";
import {
    ADD_INCIDENT,
    GET_INCIDENTS, GET_SINGLE_INCIDENT,
    GET_USER_INCIDENT, SUBMIT_ACTION_FOR_INCIDENT, SUBMIT_INCIDENT, UPDATE_INCIDENT, DELETE_INCIDENT
} from "../../types";
import {setAlert} from "../alert";

export const getIncidentValues = () => async (dispatch) => {
    try {
        const res = await axios.get("/incident/report/values");
        if (res.data.code === 1) {
            dispatch({type: GET_INCIDENTS, payload: res.data.data});
            return {message: "Incident values fetched successfully", success: true};
        }
        return {message: "Failed to fetch incident values", success: false};
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return {message: err.message, success: false};
    }
};

export const deleteIncident = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/incident/report/delete/${id}`);
        // console.log("delete incident")
        // console.log(res)
        if (res.data.code === 1) {
            dispatch({type: DELETE_INCIDENT, payload: id});
            return {message: res.data.message, success: true};   
        }
        return {message: res.data.message, success: false};
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return {message: err.message, success: false};
    }
};

export const addIncidentReport = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/incident/report/add", formData, config);
        if (res.data.code === 1) {
            dispatch({type: ADD_INCIDENT, payload: res.data.data});
            return {message: res.data.message, success: true};
        } else {
            return {message: res.data.message, success: false};
        }
    } catch (err) {
        console.error("Error in addIncidentReport:", err);
        return {message: err.response?.data?.message || "Contact Admin", success: false};
    }
};

export const getUserIncidents = (accessModuleId) => async (dispatch) => {
    try {
        const object={
            moduleId:parseInt(accessModuleId)
        }
        const res = await axios.post("/incident/report/user",object);
        if (res.data.code === 1) {
            dispatch({type: GET_USER_INCIDENT, payload: res.data.data});
            return {message: "User incidents fetched successfully", success: true};
        }
        return {message: "Failed to fetch user incidents", success: false};
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return {message: err.message, success: false};
    }
};

export const getUserSingleIncident = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/incident/report/${id}`);
        if (res.data.code === 1) {
            dispatch({ type: GET_SINGLE_INCIDENT, payload: res.data.data });
            return { message: "Single incident fetched successfully", success: true, data: res.data.data };
        } else {
            dispatch(setAlert(res.data.message, "danger"));
            return { message: res.data.message, success: false };
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return { message: err.message, success: false };
    }
};

export const updateIncidentReport = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/incident/report/update", formData, config);
        if (res.data.code === 1) {
            dispatch({ type: UPDATE_INCIDENT, payload: res.data.data });
            return { message: res.data.message, success: true };
        } else {
            return { message: res.data.message, success: false };
        }
    } catch (err) {
        console.error("Error in updateIncidentReport:", err);
        return { message: err.response?.data?.message || "Contact Admin", success: false };
    }
};

export const submitIncident = (object) => async (dispatch) => {
    try {
        const res = await axios.post(`/incident/report/submit/`,object);
        if (res.data.code === 1) {
            dispatch({ type: SUBMIT_INCIDENT, payload: res.data.data });
            return { message: "Incident submitted successfully", success: true, data: res.data.data };
        } else {
            dispatch(setAlert(res.data.message, "danger"));
            return { message: res.data.message, success: false };
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return { message: err.message, success: false };
    }
};

export const submitActionForIncident = (payload) => async (dispatch) => {
    try {
        const res = await axios.post("/incident/report/action/update", payload);
        if (res.data.code === 1) {
            dispatch({ type: SUBMIT_ACTION_FOR_INCIDENT, payload: res.data.data });
            return { message: res.data.message, success: true };
        } else {
            return { message: res.data.message, success: false };
        }
    } catch (err) {
        console.error("Error in submitActionForIncident:", err);
        return { message: err.response?.data?.message || "Contact Admin", success: false };
    }
};