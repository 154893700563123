import axios from "../../../axios";
import { showSuccessDialog, showWarningDialog } from "../../../utils/dialogs";
import {ADD_USER_DASHBOARD, DELETE_USER_DASHBOARD,  GET_USER_DASHBOARDS, UPDATE_USER_DASHBOARD,GET_DASHBOARD_VALUES} from "../../types";
import {setAlert} from "../alert";
import {message} from "antd";


export const saveDashboardWidget=(formData)=>async(dispatch)=>{
    try {
        const res = await axios.post("/dashboard/incident/widget/add",formData);
        console.log(res)
        if (res.data.code === 1) {
            // dispatch({type: GET_DASHBOARD_VALUES, payload: res.data.data});
            return {success:true,result:res.data.data};
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const getDashboardValues = () => async (dispatch) => {
    try {
        const res = await axios.get("/dashboard/incident/chart");
        if (res.data.code === 1) {
            dispatch({type: GET_DASHBOARD_VALUES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const getDashboards = () => async (dispatch) => {
    try {
        const res = await axios.get("/dashboard/incident/all");
        
        if (res.data.code === 1) {
            dispatch({type: GET_USER_DASHBOARDS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const addDashboard = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/dashboard/incident/add", formData);
        ;
        if (res.data.code === 1) {
            dispatch({type: ADD_USER_DASHBOARD, payload: res.data.data});
            showSuccessDialog(res.data.message)
        } else {
            showWarningDialog(res.data.message)
            
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const getDashboard = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/dashboad/incident/${id}`);
        if (res.data.code === 1) {
            dispatch({type: GET_USER_DASHBOARDS, payload: res.data.data});
        }
    } catch (err) {
        // dispatch(setAlert(err.message, "danger"));
    }
};

export const updateDashboard = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(`/dashboard/incident/update`, formData);
        ;
        if (res.data.code === 1) {
            dispatch({type: UPDATE_USER_DASHBOARD, payload: res.data.data});
            showSuccessDialog(res.data.message)
        } else {
            showWarningDialog(res.data.message)
        }
    } catch (err) {
        // dispatch(setAlert(err.message, "danger"));
    }
};
export const deleteDashboard = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/dashboard/incident/delete/${id}`);
        if (res.data.code === 1) {
            message.success(res.data.message);
            dispatch({type: DELETE_USER_DASHBOARD, payload: res.data.data});
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
