import Swal from 'sweetalert2';

// Success Dialog
export const showSuccessDialog = (message, title = 'Success') => {
  return Swal.fire({
    icon: 'success',
    title: title,
    text: message,
    confirmButtonText: 'OK'
  });
};

// Warning Dialog
export const showWarningDialog = (message, title = 'Warning') => {
  return Swal.fire({
    icon: 'warning',
    title: title,
    text: message,
    confirmButtonText: 'OK'
  });
};

// Confirm Dialog
export const showConfirmDialog = (message, title = 'Are you sure?') => {
  return Swal.fire({
    icon: 'question',
    title: title,
    text: message,
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
  });
};

// Info Dialog
export const showInfoDialog = (message, title = 'Information') => {
  return Swal.fire({
    icon: 'info',
    title: title,
    text: message,
    confirmButtonText: 'OK'
  });
};

export const showProgressDialog = (title = 'Loading...', message = '') => {
  return Swal.fire({
    title: title,
    html: message,
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });
};


export const closeProgressDialog = () => {
  Swal.close();
};