import React, {useEffect, useState, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {dateSorter, dateTimeSort, formatDateWithTime, stringSorter} from "../../utils/functions";
import DataTable from "../../components/CustomDatatable";
import {
    Modal,
    Input,
    Button,
    Card,
    Space,
    message,
    Typography,
    Breadcrumb,
    Row,
    Col,
    DatePicker, Select, Menu
} from 'antd';
import {useForm, Controller} from "react-hook-form";
import {PlusOutlined, EditOutlined, DeleteOutlined, HomeOutlined, FilterOutlined} from '@ant-design/icons';
import {addSalesman, deleteSalesman, getSalesmans, updateSalesMan} from "../../store/actions/salesman";
import moment from "moment-timezone";
import {enhanceColumnsWithFilters} from "../../components/ColumnFilters";

const {Title} = Typography;
const {Option} = Select;

const Salesman = () => {
    document.title = "HSSE Meinhardt EPCM | Salesman";
    const dispatch = useDispatch();
    const salesman = useSelector((state) => state.salesman.list);

    const [modalOpen, setModalOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    const {control, handleSubmit, reset, setValue, formState: {errors}} = useForm({
        defaultValues: {
            name: "",
            mobile: "",
            dob: "",
            address: "",
            type: "",
            email: ""
        }
    });

    const handleStatus = async (id, status) => {
        await dispatch(updateSalesMan({id, status: status === 1 ? 0 : 1}));
        await dispatch(getSalesmans());
    };

    const onFormSubmit = async (formData) => {
        try {
            const submissionData = {
                ...formData,
                dob: formData.dob ? moment(formData.dob).format('YYYY-MM-DD') : null
            };

            if (currentItem) {
                await dispatch(updateSalesMan({...submissionData, id: currentItem.id}));
            } else {
                await dispatch(addSalesman(submissionData));
            }
            handleCloseModal();
            dispatch(getSalesmans());
        } catch (error) {
            message.error('An error occurred. Please try again.');
        }
    };

    const handleEdit = (item) => {
        setCurrentItem(item);
        Object.keys(item).forEach(key => {
            if (key === 'dob') {
                // Convert the date string to a Moment object
                setValue(key, item[key] ? moment(item[key]) : null);
            } else {
                setValue(key, item[key]);
            }
        });
        setModalOpen(true);
    };
    const handleCloseModal = useCallback(() => {
        reset();
        setCurrentItem(null);
        setModalOpen(false);
    }, [reset]);

    const handleDelete = (item) => {
        setItemToDelete(item);
        setDeleteModalVisible(true);
    };

    const confirmDelete = async () => {
        try {
            await dispatch(deleteSalesman(itemToDelete.id));
            setDeleteModalVisible(false);
            dispatch(getSalesmans());
        } catch (error) {
            message.error('An error occurred while deleting the item');
        }
    };

    let columns = [
        {
            title: "Sr. No",
            key: 'srno',
            render: (text, record, index) => index + 1,
            width: '80px',
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: stringSorter("name"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Mobile",
            dataIndex: "mobile",
            key: "mobile",
            sorter: stringSorter("mobile"),
        },
        {
            title: "BirthDate",
            dataIndex: "dob",
            key: "dob",
            sorter: dateSorter("dob",),
            render: (text, record, index) => formatDateWithTime(text, false),
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            sorter: stringSorter("type"),
        },
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            width: '120px',
            sorter: dateTimeSort("createdAt"),
        },
        {
            title: "Created By",
            dataIndex: "createdBy",
            key: "createdBy",
            width: '120px',
            sorter: dateTimeSort("createdBy"),

        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            width: '120px',
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
                <div style={{padding: 8}}>
                    <Menu
                        onClick={({key}) => {
                            setSelectedKeys(key === 'ALL' ? [] : [key]);
                            confirm();
                        }}
                        selectedKeys={selectedKeys}
                    >
                        <Menu.Item key="ALL">All</Menu.Item>
                        <Menu.Item key="1">Active</Menu.Item>
                        <Menu.Item key="0">Inactive</Menu.Item>
                    </Menu>
                    <Button
                        onClick={() => {
                            clearFilters();
                            confirm();
                        }}
                        size="small"
                        style={{width: '100%', marginTop: 8}}
                    >
                        Reset
                    </Button>
                </div>
            ),
            filterIcon: filtered => (
                <FilterOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
            ),
            onFilter: (value, record) => {
                if (value === 'ALL' || value === undefined) return true;
                return record.status.toString() === value;
            },
            render: (text, item) => (
                <Button
                    type={item?.status === 1 ? "primary" : "default"}
                    onClick={() => handleStatus(item.id, item.status)}
                >
                    {item?.status === 1 ? "Active" : "Inactive"}
                </Button>
            )
        },
        {
            title: "Action",
            key: "action",
            width: '200px',
            render: (text, item) => (
                <Space>
                    <Button type="primary" icon={<EditOutlined/>} onClick={() => handleEdit(item)}>
                        Edit
                    </Button>
                    <Button danger icon={<DeleteOutlined/>} onClick={() => handleDelete(item)}>
                        Delete
                    </Button>
                </Space>
            ),
        },
    ];
    columns = enhanceColumnsWithFilters(columns);

    useEffect(() => {
        dispatch(getSalesmans());
    }, [dispatch]);

    return (
        <div className="page-content">
            <Breadcrumb style={{marginBottom: '16px'}}>
                <Breadcrumb.Item href="/">
                    <HomeOutlined/>
                </Breadcrumb.Item>
                <Breadcrumb.Item>HSSE Meinhardt EPCM</Breadcrumb.Item>
                <Breadcrumb.Item>Salesman</Breadcrumb.Item>
            </Breadcrumb>

            <Card>
                <Space direction="vertical" size="middle" style={{width: '100%'}}>
                    <Row justify="space-between" align="middle">
                        <Col>
                            <Title level={2} style={{margin: 0}}>Manage Salesman</Title>
                        </Col>
                        <Col>
                            <Button type="primary" icon={<PlusOutlined/>} onClick={() => setModalOpen(true)}>
                                Add Salesman
                            </Button>
                        </Col>
                    </Row>

                    <DataTable
                        dataSource={salesman.map((item, index) => ({
                            ...item,
                            key: index,
                        }))}
                        columns={columns}
                    />
                </Space>
            </Card>

            <Modal
                title={currentItem ? 'Edit Salesman' : 'Add Salesman'}
                visible={modalOpen}
                onCancel={handleCloseModal}
                footer={null}
                width={900}
                zIndex={100000000}
            >
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    <Title level={2}>Salesman Information</Title>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: 'Name is required',
                                    pattern: {
                                        value: /^[a-zA-Z\s]+$/,
                                        message: 'Name should only contain letters and spaces'
                                    }
                                }}
                                render={({field}) => (
                                    <div style={{marginBottom: 16}}>
                                        <label>Name</label>
                                        <Input {...field} />
                                        {errors.name && <span style={{color: 'red'}}>{errors.name.message}</span>}
                                    </div>
                                )}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Controller
                                name="mobile"
                                control={control}
                                rules={{
                                    required: 'Mobile number is required',
                                    pattern: {
                                        value: /^[0-9]{10}$/,
                                        message: 'Mobile number must be a 10-digit number'
                                    }
                                }}
                                render={({field}) => (
                                    <div style={{marginBottom: 16}}>
                                        <label>Mobile Number</label>
                                        <Input {...field} />
                                        {errors.mobile && <span style={{color: 'red'}}>{errors.mobile.message}</span>}
                                    </div>
                                )}
                            />
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Invalid email format'
                                    }
                                }}
                                render={({field}) => (
                                    <div style={{marginBottom: 16}}>
                                        <label>Email</label>
                                        <Input {...field} />
                                        {errors.email && <span style={{color: 'red'}}>{errors.email.message}</span>}
                                    </div>
                                )}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Controller
                                name="dob"
                                control={control}
                                rules={{required: 'Date of birth is required'}}
                                render={({field}) => (
                                    <div style={{marginBottom: 16}}>
                                        <label>Date of Birth</label>
                                        <DatePicker
                                            {...field}
                                            style={{width: '100%'}}
                                            format="YYYY-MM-DD"
                                            value={field.value ? moment(field.value) : null}
                                            onChange={(date) => field.onChange(date ? date.format('YYYY-MM-DD') : null)}
                                        />
                                        {errors.dob && <span style={{color: 'red'}}>{errors.dob.message}</span>}
                                    </div>
                                )}
                            />
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Controller
                                name="address"
                                control={control}
                                rules={{required: 'Address is required'}}
                                render={({field}) => (
                                    <div style={{marginBottom: 16}}>
                                        <label>Address</label>
                                        <Input.TextArea {...field} rows={4}/>
                                        {errors.address && <span style={{color: 'red'}}>{errors.address.message}</span>}
                                    </div>
                                )}
                            />
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Controller
                                name="type"
                                control={control}
                                rules={{required: 'Type is required'}}
                                render={({field}) => (
                                    <div style={{marginBottom: 16}}>
                                        <label>Type</label>
                                        <Select {...field} style={{width: '100%'}}>
                                            <Option value="office">Office</Option>
                                            <Option value="salesman">Salesman</Option>
                                        </Select>
                                        {errors.type && <span style={{color: 'red'}}>{errors.type.message}</span>}
                                    </div>
                                )}
                            />
                        </Col>
                    </Row>

                    <Space>
                        <Button type="primary" htmlType="submit">
                            {currentItem ? "Update" : "Save"}
                        </Button>
                        <Button onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </Space>
                </form>
            </Modal>

            <Modal
                title="Confirm Delete"
                zIndex={100000000}
                visible={deleteModalVisible}
                onCancel={() => setDeleteModalVisible(false)}
                footer={[
                    <Button key="cancel" onClick={() => setDeleteModalVisible(false)}>
                        Cancel
                    </Button>,
                    <Button key="delete" danger onClick={confirmDelete}>
                        Delete
                    </Button>,
                ]}
            >
                <p>Are you sure you want to delete this item?</p>
                {itemToDelete && (
                    <p>
                        <strong>Record Name:</strong> {itemToDelete.name}
                    </p>
                )}
            </Modal>
        </div>
    );
}

export default Salesman;