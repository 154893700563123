import React from "react";
import {Navigate} from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";

// Import Calender
import Calender from "../Pages/Template-Tools/Calender";


// Import E-mail
import Inbox from "../Pages/Template-Tools/E-mail/Inbox";
import ReadEmail from "../Pages/Template-Tools/E-mail/ReadEmail";
import EmailCompose from "../Pages/Template-Tools/E-mail/EmailCompose";

// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/Authentication/Register";
import UserProfile from "../Pages/Authentication/user-profile";

// Import Authentication Inner Pages
import Login1 from "../Pages/AuthenticationPages/Login";
import Register1 from "../Pages/AuthenticationPages/Register";
import RecoverPassword from "../Pages/AuthenticationPages/RecoverPassword";
import LockScreen from "../Pages/AuthenticationPages/LockScreen";

// Import Utility Pages
import StarterPage from "../Pages/Template-Tools/Utility/Starter-Page";
import Maintenance from "../Pages/Template-Tools/Utility/Maintenance-Page";
import ComingSoon from "../Pages/Template-Tools/Utility/ComingSoon-Page";
import TimeLine from "../Pages/Template-Tools/Utility/TimeLine-Page";
import FAQs from "../Pages/Template-Tools/Utility/FAQs-Page";
import Pricing from "../Pages/Template-Tools/Utility/Pricing-Page";
import Error404 from "../Pages/Template-Tools/Utility/Error404-Page";
import Error500 from "../Pages/Template-Tools/Utility/Error500-Page";

// Import UIElement Pages
import UiAlerts from "../Pages/UiElements/UiAlerts";
import UiBadge from "../Pages/UiElements/UiBadge";
import UiBreadcrumb from "../Pages/UiElements/UiBreadcrumb";
import UiButtons from "../Pages/UiElements/UiButtons";
import UiCards from "../Pages/UiElements/UiCards";
import UiCarousel from "../Pages/UiElements/UiCarousel";
import UiDropdown from "../Pages/UiElements/UiDropdowns";
import UiGrid from "../Pages/UiElements/UiGrid";
import UiImages from "../Pages/UiElements/UiImages";
import UiLightbox from "../Pages/UiElements/UiLightbox";
import UiModals from "../Pages/UiElements/UiModals";
import UiOffcanvas from "../Pages/UiElements/UiOffcanvas";
import UiRangeSlider from "../Pages/UiElements/UiRangeSlider";
import UiSessionTimeout from "../Pages/UiElements/UiSessionTimeout";
import UiPagination from "../Pages/UiElements/UiPagination";
import UiProgressBars from "../Pages/UiElements/UiProgressBars";
import UiPlaceholders from "../Pages/UiElements/UiPlaceholders";
import UiTabs from "../Pages/UiElements/UiTabs&Accordions";
import UiTypography from "../Pages/UiElements/UiTypography";
import UiToasts from "../Pages/UiElements/UiToasts";
import UiVideo from "../Pages/UiElements/UiVideo";
import UiPopovers from "../Pages/UiElements/UiPopovers&Tooltips";
import UiRating from "../Pages/UiElements/UiRating";

// Import Forms
import FormEditors from "../Pages/Template-Tools/Forms/FormEditors";
import FormUpload from "../Pages/Template-Tools/Forms/FormUpload";
import FormXeditable from "../Pages/Template-Tools/Forms/FormXeditable";
import FormMask from "../Pages/Template-Tools/Forms/FormMask";
import FormElements from "../Pages/Template-Tools/Forms/FormElements";
import FormAdvanced from "../Pages/Template-Tools/Forms/FormAdvanced";
import FormValidations from "../Pages/Template-Tools/Forms/FormValidations";
import FormWizard from "../Pages/Template-Tools/Forms/FormWizard";

// Import Tables
import BasicTable from "../Pages/Template-Tools/Tables/BasicTable.js";
import ListJs from "../Pages/Template-Tools/Tables/ListTables/ListTables";
import DataTable from "../Pages/Template-Tools/Tables/DataTables/DataTables";


// Import Charts
import ApexCharts from "../Pages/Template-Tools/Charts/ApexCharts";
import ChartJs from "../Pages/Template-Tools/Charts/ChartjsCharts";
import Sparklinechart from "../Pages/Template-Tools/Charts/SparklineCharts";
import FloatChart from "../Pages/Template-Tools/Charts/FloatCharts";
import JknobCharts from "../Pages/Template-Tools/Charts/JqueryKnobCharts";

// Import Icon Pages
import IconMaterialdesign from "../Pages/Icons/IconMaterialdesign";
import IconFontawesome from "../Pages/Icons/IconFontAwesome";
import IconDripicons from "../Pages/Icons/IconDrip";
import IconBoxicons from "../Pages/Icons/IconBoxicons"

// Import Map Pages
import VectorMaps from "../Pages/Template-Tools/Maps/VectorMap";

import Salesman from "../Pages/Salesman";
import Items from "../Pages/Items";
import Orders from "../Pages/Orders";
import Visit from "../Pages/Visits";
import DisciplineTable from "../Pages/DisciplineTable/index.js";
import Roles from "../Pages/System-admin/Roles";
import Incident from "../Pages/Incident";
import DynamicPageLayout from "../components/DynamicPageLayout";


const authProtectedRoutes = [

    {path: "/adminroles", component: <Roles/>},
    //dashboard
    {path: "/dashboard", component: <Dashboard/>},
    { path: "/:menuName", component: <DynamicPageLayout /> },

    // Calender
    {path: "/calendar", component: <Calender/>},

    // Profile
    {path: "/userprofile", component: <UserProfile/>},

    // E-mail
    {path: "/inbox", component: <Inbox/>},
    {path: "/read-email", component: <ReadEmail/>},
    {path: "/compose-email", component: <EmailCompose/>},

    // Utility Pages
    {path: "/pages-starter", component: <StarterPage/>},
    {path: "/pages-timeline", component: <TimeLine/>},
    {path: "/pages-faqs", component: <FAQs/>},
    {path: "/pages-pricing", component: <Pricing/>},

    // UiElements Pages
    {path: "/ui-alerts", component: <UiAlerts/>},
    {path: "/ui-badge", component: <UiBadge/>},
    {path: "/ui-breadcrumb", component: <UiBreadcrumb/>},
    {path: "/ui-buttons", component: <UiButtons/>},
    {path: "/ui-cards", component: <UiCards/>},
    {path: "/ui-carousel", component: <UiCarousel/>},
    {path: "/ui-dropdowns", component: <UiDropdown/>},
    {path: "/ui-grid", component: <UiGrid/>},
    {path: "/ui-images", component: <UiImages/>},
    {path: "/ui-lightbox", component: <UiLightbox/>},
    {path: "/ui-modals", component: <UiModals/>},
    {path: "/ui-offcanvas", component: <UiOffcanvas/>},
    {path: "/ui-rangeslider", component: <UiRangeSlider/>},
    {path: "/ui-sessiontimeout", component: <UiSessionTimeout/>},
    {path: "/ui-pagination", component: <UiPagination/>},
    {path: "/ui-progressbars", component: <UiProgressBars/>},
    {path: "/ui-placeholders", component: <UiPlaceholders/>},
    {path: "/ui-tabs-accordions", component: <UiTabs/>},
    {path: "/ui-typography", component: <UiTypography/>},
    {path: "/ui-toasts", component: <UiToasts/>},
    {path: "/ui-video", component: <UiVideo/>},
    {path: "/ui-popovers", component: <UiPopovers/>},
    {path: "/ui-rating", component: <UiRating/>},

    // Forms pages
    {path: "/form-elements", component: <FormElements/>},
    {path: "/form-validation", component: <FormValidations/>},
    {path: "/form-advanced", component: <FormAdvanced/>},
    {path: "/form-editor", component: <FormEditors/>},
    {path: "/form-uploads", component: <FormUpload/>},
    {path: "/form-editors", component: <FormXeditable/>},
    {path: "/form-wizard", component: <FormWizard/>},
    {path: "/form-mask", component: <FormMask/>},

    // Tables pages
    {path: "/tables-basic", component: <BasicTable/>},
    {path: "/tables-listjs", component: <ListJs/>},
    {path: "/table-datatables", component: <DataTable/>},

    // Charts Pages
    {path: "/chart-apexcharts", component: <ApexCharts/>},
    {path: "/chart-chartjscharts", component: <ChartJs/>},
    {path: "/chart-floatcharts", component: <FloatChart/>},
    {path: "/chart-jknobcharts", component: <JknobCharts/>},
    {path: "/chart-sparklinecharts", component: <Sparklinechart/>},

    // Icons Pages
    {path: "/icon-boxicon", component: <IconBoxicons/>},
    {path: "/icons-materialdesign", component: <IconMaterialdesign/>},
    {path: "/icons-fontawesome", component: <IconFontawesome/>},
    {path: "/icon-dripicons", component: <IconDripicons/>},

    // Maps Pages
    {path: "/maps-vector", component: <VectorMaps/>},
    // {path: "/maps-google", component: <GoogleMap/>},

    //Default
    {
        path: "/",
        exact: true,
        component: <Navigate to="/dashboard"/>,
    },
    //Salesman
    {
        path: "/salesman",
        exact: true,
        component: <Salesman/>,
    },
    {
        path: "/items",
        exact: true,
        component: <Items/>,
    },
    {
        path: "/orders",
        exact: true,
        component: <Orders/>,
    },
    {
        path: "/visits",
        exact: true,
        component: <Visit/>,
    },
    {
        path: "/discipline",
        exact: true,
        component: <DisciplineTable/>,
    },
    {
        path: "/sysroles",
        exact: true,
        component: <Roles/>,
    },

];

const publicRoutes = [

    // Authentication Page
    {path: "/logout", component: <Logout/>},
    {path: "/login", component: <Login/>},
    {path: "/forgot-password", component: <ForgetPasswordPage/>},
    {path: "/register", component: <Register/>},

    // Authentication Inner Pages
    {path: "/auth-login", component: <Login1/>},
    {path: "/auth-register", component: <Register1/>},
    {path: "/auth-recoverpw", component: <RecoverPassword/>},
    {path: "/auth-lock-screen", component: <LockScreen/>},

    // Utility Pages
    {path: "/pages-404", component: <Error404/>},
    {path: "/pages-500", component: <Error500/>},
    {path: "/pages-maintenance", component: <Maintenance/>},
    {path: "/pages-comingsoon", component: <ComingSoon/>},
];

export {authProtectedRoutes, publicRoutes};
