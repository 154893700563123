import React from "react";
import { Routes, Route } from "react-router-dom";
import AppRoutes from "./Routes/index";

// Import Scss
import './assets/scss/theme.scss';

// Fake Backend
import fakeBackend from "./helpers/AuthType/fakeBackend";
import { NavigationProvider } from "./Navigation";
import Login from "./Pages/Authentication/Login";
import Register from "./Pages/Authentication/Register";
import ResetPassword from "./Pages/Authentication/ResetPassowrd";

// Activating fake backend
fakeBackend();

function App() {
    return (
        <React.Fragment>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route
                    path="/*"
                    element={
                        <NavigationProvider>
                            <AppRoutes />
                        </NavigationProvider>
                    }
                />
            </Routes>
        </React.Fragment>
    );
}

export default App;