import { object } from "prop-types";
import axios from "../../../axios";
import {
    ADD_HAZARD,
    ADD_ITEM, DELETE_HAZARD, GENERATE_HAZARD, GET_ACTION_HAZARD,
    GET_HAZARD_VALUES,
    GET_SINGLE_USER_HAZARD,
    GET_USER,
    GET_USER_HAZARD,
    UPDATE_HAZARD
} from "../../types";
import {setAlert} from "../alert";

export const getHazards = () => async (dispatch) => {
    try {
        const res = await axios.get("/hazard/values");
        if (res.data.code === 1) {
            dispatch({type: GET_HAZARD_VALUES, payload: res.data.data});
            return {message: "Hazards fetched successfully", success: true};
        }
        return {message: "Failed to fetch hazards", success: false};
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return {message: err.message, success: false};
    }
};

export const submitHazard = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(`/hazard/submit`,formData);
        if (res.data.code === 1) {
            dispatch({type: GENERATE_HAZARD, payload: res.data.data});
            return {message: "Hazard submitted successfully", success: true};
        }
        return {message: res.data.message, success: false};
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return {message: err.message, success: false};
    }
};

export const deleteHazard = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/hazard/delete/${id}`);
        
        if (res.data.code === 1) {
            dispatch({type: DELETE_HAZARD, payload: id});
            return {message: res.data.message, success: true};
        }
        return {message: res.data.message, success: false};
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return {message: err.message, success: false};
    }
};

export const addHazardForm = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/hazard/add", formData, config);
        if (res.data.code === 1) {
            dispatch({type: ADD_HAZARD, payload: res.data.data});
            return {message: res.data.message, success: true};
        } else {
            return {message: res.data.message, success: false};
        }
    } catch (err) {
        console.error("Error in addHazardForm:", err);
        return {message: err.response?.data?.message || "Contact Admin", success: false};
    }
};

export const updateHazardForm = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/hazard/update", formData, config);
        
        if (res.data.code === 1) {
            dispatch({type: UPDATE_HAZARD, payload: res.data.data});
            return {message: res.data.message, success: true};
        } else {
            return {message: res.data.message, success: false};
        }
    } catch (err) {
        console.error("Error in updateHazardForm:", err);
        return {message: err.response?.data?.message || "Contact Admin", success: false};
    }
};

export const getUserHazards = (accessModuleId) => async (dispatch) => {
    try {
        const object={
            moduleId:parseInt(accessModuleId)
        }
        const res = await axios.post("/hazard/user",object);
        console.log(res.data)
        if (res.data.code === 1) {
            dispatch({type: GET_USER_HAZARD, payload: res.data.data});
            return {message: "User hazards fetched successfully", success: true};
        }
        return {message: "Failed to fetch user hazards", success: false};
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return {message: err.message, success: false};
    }
}

export const getUserSingleHazard = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/hazard/${id}`);
        
        if (res.data.code === 1) {
            dispatch({type: GET_SINGLE_USER_HAZARD, payload: res.data.data});
            return {message: "Single user hazard fetched successfully", success: true};
        }
        return {message: "Failed to fetch single user hazard", success: false};
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return {message: err.message, success: false};
    }
}

export const submitActionForHazard = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/hazard/action/update", formData);

        if (res.data.code === 1) {
            return {message: res.data.message, success: true};
        } else {
            return {message: res.data.message, success: false};
        }
    } catch (err) {
        return {message: err.response?.data?.message || "Contact Admin", success: false};
    }
}