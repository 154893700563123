import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button, Card, CardHeader, CardBody, Row, Col, Form,
    Modal, ModalHeader, ModalBody, ModalFooter, Table,
} from 'reactstrap';
import {
    addHazardForm,
    getHazards,
    getUserHazards,
    submitHazard,
    getUserSingleHazard, updateHazardForm, submitActionForHazard,
    deleteHazard
} from "../../../store/actions/Incident/hazard";

import {
    ActionModal,
    AdminSection,
    AttachmentsSection,
    ContributingFactorsSection,
    GeneralSection,
    HazardSection
} from "../Common/Forms";
import { message } from "antd";
import { DeleteOutlined, EditOutlined, EyeFilled, PlusCircleFilled } from "@ant-design/icons";
import {
    AccessFilter,
    dateSorter,
    formatDateWithTime,

    manageCURDAccess,
    stringSorter
} from "../../../utils/functions";
import ReactStrapDataTable from "../../CustomDatatable/ReactStrapDataTable";
import { ViewHazardModal } from "../Views";
import DebouncedSaveButton from '../../../components/Buttons/DebounceSaveButton';
import { showConfirmDialog, showSuccessDialog, showWarningDialog } from '../../../utils/dialogs';
import Swal from "sweetalert2";


const Hazards = ({ access }) => {

    const accessRights = manageCURDAccess(access);

    const dispatch = useDispatch();
    const hazards = useSelector((state) => state.hazard.list);
    const userHazards = useSelector((state) => state.hazard.userHazard);
    const getSingleHazard = useSelector((state) => state.hazard.single);

    const [modalOpen, setModalOpen] = useState(false);
    const [currentHazard, setCurrentHazard] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [actionModalOpen, setActionModalOpen] = useState(false);
    const [selectedHazard, setSelectedHazard] = useState(null);
    const [actionData, setActionData] = useState({})

    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [hazardToGenerate, setHazardToGenerate] = useState(null);

    //View Hazard
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedViewHazard, setSelectedViewHazard] = useState(null);

    //SaveButton
    const [isProcessing, setIsProcessing] = useState(false);


    //SharedAccessFilter
    const [accessFilter, setAccessFilter] = useState('My');

    const handleAccessFilterChange = (selectedAccess) => {
        console.log(selectedAccess)
        setAccessFilter(selectedAccess);
    };
    //

    const toggleActionModal = () => setActionModalOpen(!actionModalOpen);

    const handleActionClick = (hazard) => {
        //console.log(hazard)
        setSelectedHazard(hazard);
        setActionModalOpen(true);
    };

    const viewHazard = (hazard) => {
        setSelectedViewHazard(hazard);
        setViewModalOpen(true);
    }

    const handleInitiate = (actionData) => {
        setActionData(actionData)
    };
    const handleInitiateAction = async (actionData) => {
        delete actionData.submitDate
        if (actionData.message) {
            actionData.reply = actionData.message;
            delete actionData.message;
        }

        const payload = {
            hazardId: selectedHazard.id,
            id: selectedHazard.actionId,
            actions: JSON.stringify(actionData)
        }
        const response = await dispatch(submitActionForHazard(payload))
        if (response.success) {
            showSuccessDialog(response.message)
            setActionData([]);
            setActionModalOpen(false);
            setModalOpen(false)
            dispatch(getUserHazards(access.moduleId))
        } else {
            showWarningDialog(response.message)
        }
    };

    const [formData, setFormData] = useState({
        responsibleManager: '',
        closeoutManager: '',
        supervisor: '',
        site: '',
        l3Contractor: '',
        locationPlace: '',
        detectedOn: new Date().toISOString().split('T')[0],
        furtherActionsRequired: 'No',
        identifiedBy: '',
        reportType: '',
        hazardSource: '',
        potentialHazards: [],
        details: '',
        immediateActions: '',
        isImprovementOpportunity: { value: false, label: 'No' },
        actions: [],
        attachments: [],
        factors: []
    });

    const resetForm = () => {
        setFormData({
            responsibleManager: '',
            closeoutManager: '',
            supervisor: '',
            site: '',
            l3Contractor: '',
            locationPlace: '',
            detectedOn: new Date().toISOString().split('T')[0],
            identifiedBy: '',
            reportType: '',
            hazardSource: '',
            potentialHazards: [],
            details: '',
            immediateActions: '',
            furtherActionsRequired: 'No',
            isImprovementOpportunity: { value: false, label: 'No' },
            actions: [],
            attachments: [],
            factors: []

        });
    };

    //Validation
    const validateForm = () => {
        if (!formData.responsibleManager) {
            message.error("Responsible Manager is required.");
            return false;
        }
        if (!formData.closeoutManager) {
            message.error("Closeout Manager is required.");
            return false;
        }
        if (!formData.supervisor) {
            message.error("Supervisor is required.");
            return false;
        }
        if (!formData.site) {
            message.error("Site is required.");
            return false;
        }
        if (!formData.l3Contractor) {
            message.error("L3 Contractor is required.");
            return false;
        }
        if (!formData.detectedOn) {
            message.error("Detected On date is required.");
            return false;
        }
        if (!formData.reportType) {
            message.error("Report Type is required.");
            return false;
        }
        if (formData.furtherActionsRequired === 'Yes' && formData.actions.length === 0) {
            message.error("At least one action is required when Further Actions Required is Yes.");
            return false;
        }
        if (formData.furtherActionsRequired === 'Yes') {
            for (let action of formData.actions) {

                if (!action.userId || !action.description || !action.targetDays) {
                    // console.log('Action validation failed:', {
                    //     action: action,
                    //     userId: {
                    //         value: action.userId,
                    //         isValid: !!action.userId,
                    //     },
                    //     description: {
                    //         value: action.description,
                    //         isValid: !!action.description,
                    //     },
                    //     targetDays: {
                    //         value: action.targetDays,
                    //         isValid: !!action.targetDays,
                    //     }
                    // });

                    let errorFields = [];
                    if (!action.userId) errorFields.push('User ID');
                    if (!action.description) errorFields.push('Description');
                    if (!action.targetDays) message.error('Target Days must be greater than 0');

                    message.error(`The following fields in Contributing Factors and Actions are required: ${errorFields.join(', ')}`);
                    return false;
                }
            }
        }
        return true;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSelectChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };

    const handleImprovementOpp = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        setFormData(prevFormData => {
            const newFormData = { ...prevFormData, [name]: selectedOption };
            return newFormData;
        });
    };
    const addHazard = async (hazardData) => {
        try {

            const formdata = transformFormDataForSubmission(hazardData)
            setIsProcessing(true);
            if (formData.id) {
                const result = await dispatch(updateHazardForm(formdata))
                if (result.success) {
                    showSuccessDialog("Hazard Updated")
                    resetForm()
                    handleCloseModal()
                    //dispatch(getUserHazards(access.moduleId))

                } else {
                    showWarningDialog(result.message)

                }
            } else {
                const result = await dispatch(addHazardForm(formdata))
                if (result.success) {
                    showSuccessDialog("Hazard Submitted")
                    resetForm()
                    handleCloseModal()
                    dispatch(getUserHazards(access.moduleId))
                } else {
                    showWarningDialog(result.message)
                }
            }

        } catch (error) {
            console.error("Error adding hazard:", error);
            setErrorMessage("An error occurred while adding the hazard. Please try again.");
        }
        finally {
            setIsProcessing(false);
        }
    };

    const transformFormDataForSubmission = (formData) => {

        // console.log(formData)
        const formDataObj = new FormData();
        formDataObj.append('moduleId', parseInt(access.moduleId))
        if (formData.id) {
            formDataObj.append('id', parseInt(formData.id));
            formDataObj.append('actionId', parseInt(formData.actionId));
            formDataObj.append('fileId', parseInt(formData.fileId));
            formDataObj.append('costId', parseInt(formData.costId));
            formDataObj.append('factorId', parseInt(formData.factorId));
        }

        formDataObj.append('rmId', parseInt(formData.responsibleManager.value));
        formDataObj.append('cmId', parseInt(formData.closeoutManager.value));
        formDataObj.append('supId', parseInt(formData.supervisor.value));
        formDataObj.append('siteId', parseInt(formData.site.value));
        formDataObj.append('l3Id', parseInt(formData.l3Contractor.value));
        formDataObj.append('location', formData.locationPlace);
        formDataObj.append('detectedAt', formData.detectedOn);
        if (formData.identifiedBy) {
            formDataObj.append('identifiedBy', parseInt(formData.identifiedBy.value))
        }
        formDataObj.append('identifiedOther', formData.identifiedByOther || '');
        formDataObj.append('rtId', parseInt(formData.reportType.value));
        formDataObj.append('hsId', formData.hazardSource ? parseInt(formData.hazardSource.value) : '');
        formDataObj.append('ahId', JSON.stringify(formData.potentialHazards.map(hazard => parseInt(hazard.value))));
        formDataObj.append('details', formData.details);
        formDataObj.append('immediateAction', formData.immediateActions);
        formDataObj.append('actionRequired', formData.furtherActionsRequired === 'Yes');
        formDataObj.append('isImprovement', formData.isImprovementOpportunity.value);
        formDataObj.append('actions', JSON.stringify(formData.actions.map(action => ({
            id: action.id ?? null,
            userId: parseInt(action.userId),
            description: action.description ?? null,
            createdAt: action.createdAt ?? null,
            createdBy: action.createdBy ?? null,
            updatedAt: action.updatedAt ?? null,
            updatedBy: action.updatedBy ?? null,
            submittedAt: action.submittedAt ?? null,
            targetAt: action.targetAt ?? null,
            reply: action.reply ?? null,
            tags: action.tags ?? [],
            comments: action.comments ?? null,
            targetDays: parseInt(action.targetDays, 10),
            type: action.type ?? "N",
            status: action.status ?? "PENDING",
        }))));

        // Handle file attachments
        formData.attachments.forEach((attachment, index) => {
            if (attachment.file instanceof File) {
                formDataObj.append(`attachments[${index}]`, attachment.file, attachment.filename);
            }
        });

        formDataObj.append('files', JSON.stringify(formData.attachments.map(attachment => ({
            id: attachment.id ?? null,
            fileName: attachment.filename,
            description: attachment.description ?? null,
            path: attachment.path ?? null,
            createdAt: attachment.createdAt ?? null,
            createdBy: attachment.createdBy ?? null,
            updateBy: attachment.updateBy ?? null,
            updatedAt: attachment.updatedAt ?? null,
        }))));

        if (formData.costImplications !== undefined) {
            formDataObj.append('costs', JSON.stringify(formData.costImplications.map(action => ({
                id: action.id ?? null,
                description: action.description ?? null,
                amount: parseFloat(action.amount),
                allocation: action.allocation ?? null,
                comment: action.comment ?? null,
                createdAt: action.createdAt ?? null,
                createdBy: action.createdBy ?? null,
                updatedAt: action.updatedAt ?? null,
                updatedBy: action.updatedBy ?? null,
            }))));
        }
        else {
            formDataObj.append('costs', JSON.stringify([]))
        }
        formDataObj.append('factors', JSON.stringify(formData.factors))

        return formDataObj;
    };

    const handleSubmit = async () => {

        setErrorMessage("");
        if (!validateForm()) {
            throw new Error("Form validation failed");
        }

        const contributingFactorsAndActions = formData.furtherActionsRequired === 'Yes'
            ? formData.actions.map(action => ({
                // id: Date.now(), // This should be replaced with a proper ID generation method
                userId: action.userId,
                description: action.description,
                // createdAt: null,
                createdBy: null, // This should be set to the current user's ID
                updatedAt: null,
                updatedBy: null,
                submittedAt: null,
                targetDays: action.targetDays,
                targetAt: null,
                reply: null,
                tags: [],
                comments: []
            }))
            : null;

        const hazardPayload = {
            ...formData,
            contributingFactorsAndActions
        };

        await addHazard(hazardPayload);
    };

    const handleEdit = async (hazard) => {
        setCurrentHazard(hazard);
        await dispatch(getUserSingleHazard(hazard.id));
        setModalOpen(true);
    };

    useEffect(() => {
        if (getSingleHazard && currentHazard) {
            const hazardData = getSingleHazard;
            const newFormData = {
                actionId: hazardData.actionId,
                fileId: hazardData.fileId,
                costId: hazardData.costId,
                factorId: hazardData.factorId,
                id: hazardData.id,
                responsibleManager: { value: hazardData.rmId.toString(), label: hazardData.responsibleManager },
                closeoutManager: { value: hazardData.cmId.toString(), label: hazardData.closeoutManager },
                supervisor: { value: hazardData.supId.toString(), label: hazardData.supervisor },
                site: { value: hazardData.siteId.toString(), label: hazardData.site },
                l3Contractor: { value: hazardData.l3Id.toString(), label: hazardData.l3Contractor },
                locationPlace: hazardData.location || '',
                detectedOn: hazardData.detectedAt ? hazardData.detectedAt.split(' ')[0] : '',
                identifiedBy: hazardData.identifiedBy ? {
                    value: hazardData.identifiedBy.toString(),
                    label: hazardData.identifiedByName
                } : '',
                identifiedByOther: hazardData.identifiedOther || '',
                reportType: { value: hazardData.rtId.toString(), label: hazardData.reportType },
                hazardSource: hazardData.hsId ? {
                    value: hazardData.hsId.toString(),
                    label: hazardData.hazardSource
                } : '',
                potentialHazards: (hazardData.ahId || []).map(id => ({ value: id.toString(), label: '' })),
                details: hazardData.details || '',
                immediateActions: hazardData.immediateAction || '',
                furtherActionsRequired: hazardData.actionRequired ? 'Yes' : 'No',
                isImprovementOpportunity: hazardData.isImprovement === true ? { value: true, label: 'Yes' } : { value: false, label: 'No' },
                actions: (hazardData.actions || []).map(action => ({
                    id: action.id,
                    userId: action.userId,
                    description: action.description,
                    targetDays: action.targetDays,
                    createdAt: action.createdAt,
                    createdBy: action.createdBy,
                    updatedAt: action.updatedAt,
                    updatedBy: action.updatedBy,
                    submittedAt: action.submittedAt,
                    targetAt: action.targetAt,
                    reply: action.reply,
                    tags: action.tags,
                    comments: action.comments,
                    type: action.type,
                    status: action.status
                })),
                attachments: (hazardData.files || []).map(file => ({
                    id: file.id,
                    path: file.path,
                    filename: file.fileName,
                    description: file.description,
                    createdAt: file.createdAt,
                    createdBy: file.createdBy,
                    updatedAt: file.updatedAt,
                    updatedBy: file.updatedBy,
                    fullPath: file.fullPath,
                })),
                costImplications: (hazardData.costs || []).map(cost => ({
                    id: cost.id,
                    description: cost.description,
                    amount: cost.amount,
                    allocation: cost.allocation,
                    comment: cost.comment,
                    createdAt: cost.createdAt,
                    createdBy: cost.createdBy,
                    updatedAt: cost.updatedAt,
                    updatedBy: cost.updatedBy,
                })),
                factors: hazardData.factors ?? []
            };

            setFormData(newFormData);
        }
    }, [getSingleHazard, currentHazard]);

    const handleCloseModal = () => {
        resetForm();
        setCurrentHazard(null);
        setModalOpen(false);
    };

    const handleDelete = async (hazard) => {
        try {
            const result = await showConfirmDialog("Are you sure you want to delete this Hazard?");
            if (result.isConfirmed) {
                const object = await dispatch(deleteHazard(hazard.id))
                if (object.success) {
                    Swal.fire('Deleted!', object.message, 'success');
                    dispatch(getHazards(access.moduleId));
                }
                else {
                    Swal.fire('Error!', object.message, 'error');
                }
            }
        } catch (error) {
            console.error('Error in handleDelete:', error);
        }
    };


    const handleGenerate = async (hazard) => {
        setHazardToGenerate(hazard);
        setConfirmModalOpen(true);
    };

    const confirmGenerate = async () => {
        if (hazardToGenerate) {
            const object = {
                moduleId: parseInt(access.moduleId),
                id: hazardToGenerate.id
            }
            const response = await dispatch(submitHazard(object));
            if (response.success) {
                showSuccessDialog(response.message)
            } else {
                showWarningDialog(response.message)
            }
            setConfirmModalOpen(false);
            setHazardToGenerate(null);
        }
    };

    let columns = [
        {
            title: "Action",
            key: "action",
            width: '120px',
            render: (text, hazard) => (
                <div style={{ display: 'flex', gap: '8px' }}>
                    {hazard.submittedAt === null && (
                        <Button color="primary" size="sm" onClick={() => handleEdit(hazard)} disabled={!accessRights.U}>
                            <EditOutlined />
                        </Button>
                    )}
                    {hazard.submittedAt === null && (<>
                        <Button color="danger" size="sm" onClick={() => handleDelete(hazard)} disabled={!accessRights.D}>
                            <DeleteOutlined />
                        </Button>
                    </>)}
                    <Button color="info" size="sm" onClick={() => viewHazard(hazard)} disabled={!accessRights.R}>
                        <EyeFilled />
                    </Button>
                    {hazard.submittedAt !== null && (
                        <Button color="info" size="sm" onClick={() => handleActionClick(hazard)} disabled={accessFilter === 'Shared'}>
                            <PlusCircleFilled />
                        </Button>
                    )}
                </div>
            ),
        },
        {
            title: "Sequence Number",
            dataIndex: "submittedAt",
            key: "submittedAt",
            sorter: stringSorter("submittedAt"),
            sortDirections: ["ascend", "descend"],
            render: (text, hazard) => {
                if (hazard.submittedAt !== null) {

                    return hazard.sequenceNo; // Assuming the sequence string is stored in the 'sequence' property
                } else {
                    return (
                        <Button color="primary" size="sm" onClick={() => handleGenerate(hazard)}>
                            Generate
                        </Button>
                    );
                }
            }
        },
        {
            title: "Detected On",
            dataIndex: "detectedAt",
            key: "detectedAt",
            sorter: dateSorter("detectedAt"),
            sortDirections: ["ascend", "descend"],
            render: (text, hazard) => (
                <>{formatDateWithTime(text, false)}</>
            )
        },
        {
            title: "Entered By",
            dataIndex: "createdByUser",
            key: "createdByUser",
            sorter: stringSorter("createdByUser"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "L3 Contractor",
            dataIndex: "l3Contractor",
            key: "l3Contractor",
            sorter: stringSorter("l3Contractor"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
            sorter: stringSorter("country"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Business Unit",
            dataIndex: "businessUnit",
            key: "businessUnit",
            sorter: stringSorter("businessUnit"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Project/Program",
            dataIndex: "project",
            key: "project",
            sorter: stringSorter("project"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Site",
            dataIndex: "site",
            key: "site",
            sorter: stringSorter("site"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Location Place",
            dataIndex: "location",
            key: "location",
            sorter: stringSorter("location"),
            sortDirections: ["ascend", "descend"],
        },
    
    ];

    // columns = enhanceColumnsWithFilters(columns)

    useEffect(() => {
        dispatch(getHazards());
        dispatch(getUserHazards(access.moduleId))
    }, [dispatch]);

    const filteredHazards = useMemo(() => {
        switch (accessFilter) {
            case 'My':
                return [
                    ...(userHazards.user || []),
                ];
            case 'Shared':
                return userHazards.shared || [];
            case 'Assigned':
                return userHazards.assigned || [];
            default:
                return [];
        }
    }, [userHazards, accessFilter]);



    const handleAttachmentChange = (newAttachments) => {
        setFormData({ ...formData, attachments: newAttachments });
    };

    const wrapperStyle = {
        width: '100%',
        overflowX: 'auto'
    };

    const scrollStyle = {
        width: '100%'
    };


    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="align-items-center mb-3">
                        <Col md={3}>
                            <h5 className="mb-0">Manage Hazards</h5>
                        </Col>
                        <Col md={9} className="d-flex justify-content-end align-items-center">
                            <AccessFilter
                                accessFilter={accessFilter}
                                handleAccessFilterChange={handleAccessFilterChange}
                                sharedAccess={userHazards?.sharedAccess || []}
                            />
                            <Button color="primary" onClick={() => setModalOpen(true)} className="ms-2" disabled={!accessRights.C}>
                                <PlusCircleFilled size={18} /> New
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody className="p-0">
                    <div style={wrapperStyle}>
                        <div style={scrollStyle}>
                            <ReactStrapDataTable
                                dataSource={filteredHazards ?? []}
                                columns={columns}
                                rowKey="id"
                                scroll={{ x: true }}
                            />
                        </div>
                    </div>
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} size="xl" backdrop="static">
                <Form onSubmit={handleSubmit}>
                    <ModalHeader toggle={handleCloseModal}>
                        {currentHazard ? 'Edit Hazard' : 'Add Hazard'}
                    </ModalHeader>
                    <ModalBody>
                        <GeneralSection
                            formData={formData}
                            handleSelectChange={handleSelectChange}
                            hazardData={hazards}
                        />
                        <AdminSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            hazardData={hazards}
                        />
                        <HazardSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            hazardData={hazards}
                        />
                        <ContributingFactorsSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleImprovementOpp}
                            actionUsers={hazards.actionUsers}
                            hazardData={hazards}
                            showImprovementOpportunity={true}
                            showFactors={true}
                            showCostImplications={false}
                        />
                        <AttachmentsSection
                            attachments={formData.attachments}
                            onAttachmentsChange={handleAttachmentChange}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleCloseModal}>Back</Button>
                        <DebouncedSaveButton
                            onSave={handleSubmit}
                            isProcessing={isProcessing}
                        />
                    </ModalFooter>
                </Form>
            </Modal>

            <ViewHazardModal
                isOpen={viewModalOpen}
                toggle={() => setViewModalOpen(!viewModalOpen)}
                hazard={selectedViewHazard}
                backdrop="static"
            />

            <Modal isOpen={confirmModalOpen} toggle={() => setConfirmModalOpen(false)} >
                <ModalHeader toggle={() => setConfirmModalOpen(false)}>Confirm Submission</ModalHeader>
                <ModalBody>
                    <p>Are you sure you want to submit this hazard?</p>
                    <p style={{ color: 'red' }}>
                        Note: Once the Hazard is submitted it cannot be edited.
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setConfirmModalOpen(false)}>Cancel</Button>
                    <Button color="primary" onClick={confirmGenerate}>Yes, Submit</Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={actionModalOpen}
                toggle={toggleActionModal}
                size="xl"
                backdrop="static"

            >
                <ModalHeader toggle={toggleActionModal}>
                    Hazard Number: {selectedHazard?.sequenceNo}
                </ModalHeader>
                <ModalBody>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Action Type</th>
                                <th>Submit Date</th>
                                <th>From</th>
                                <th>Message Description</th>
                                <th>To</th>
                                <th>Response Message</th>
                                <th>Response On</th>
                                <th>Response By</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedHazard?.actions?.map((action, index) => (
                                <tr key={index}>
                                    <td>
                                        <Button color="primary" size="sm" onClick={() => handleInitiate(action)}>
                                            Initiate
                                        </Button>
                                    </td>
                                    <td>{action.actionType}</td>
                                    <td>{action.submittedAt}</td>
                                    <td>{action.createdByUser}</td>
                                    <td>{action.description}</td>
                                    <td>{action.createdFor}</td>
                                    <td>{action.reply}</td>
                                    <td>{action.responseAt ?? "-"}</td>
                                    <td>{action.responseByUser ?? "-"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleActionModal}>Close</Button>
                </ModalFooter>
            </Modal>

            <ActionModal
                isOpen={actionModalOpen}
                toggle={toggleActionModal}
                selectedHazard={selectedHazard}
                message={"Action For Hazard"}
                onInitiateAction={handleInitiateAction}
            />
        </div>

        //Confirm Dialog

    );
}

export default Hazards;