import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Container,
} from "reactstrap";
import { DeleteOutlined, EditOutlined, PlusCircleFilled, BookOutlined } from "@ant-design/icons";
import ReactStrapDataTable from "../../CustomDatatable/ReactStrapDataTable";
import {
    getChapters,
    addChapter,
    updateChapter,
    deleteChapter,
    getCoursesChapters
} from "../../../store/actions/competancy/chapters";
import { stringSorter } from "../../../utils/functions";
import Topics from "../Topics";

const Chapter = ({ courseId }) => {
    const dispatch = useDispatch();
    const chapters = useSelector((state) => state.chapter.list);

    const [modalOpen, setModalOpen] = useState(false);
    const [topicsModalOpen, setTopicsModalOpen] = useState(false);
    const [editingChapter, setEditingChapter] = useState(null);
    const [selectedChapterId, setSelectedChapterId] = useState(null);
    const [hasAssessment, setHasAssessment] = useState(false);
    const [hasTotalLimit, setHasTotalLimit] = useState(false);

    const {
        control,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            chapterNo: '',
            name: '',
            hasAssessment: false,
            totalQuestions: '',
            totalMarks: '',
            totalLimit: false,
            totalLimitValue: '',
        }
    });

    const watchHasAssessment = watch("hasAssessment");
    const watchTotalLimit = watch("totalLimit");

    useEffect(() => {
        if(courseId !== null) {
            dispatch(getCoursesChapters(courseId));
        }
    }, [dispatch, courseId]);

    useEffect(() => {
        setHasAssessment(watchHasAssessment);
    }, [watchHasAssessment]);

    useEffect(() => {
        setHasTotalLimit(watchTotalLimit);
    }, [watchTotalLimit]);

    const handleEdit = (chapter) => {
        setEditingChapter(chapter);
        reset({
            chapterNo: chapter.chapterNo,
            name: chapter.name,
            hasAssessment: chapter.hasAssessment || false,
            totalQuestions: chapter.totalQuestions || '',
            totalMarks: chapter.totalMarks || '',
            totalLimit: chapter.totalLimit !== null && chapter.totalLimit > 0 ? true : false,
            totalLimitValue: chapter.totalLimit || '',
        });
        setModalOpen(true);
    };

    const handleDelete = async (chapter) => {
        if (window.confirm("Are you sure you want to delete this chapter?")) {
            await dispatch(deleteChapter(chapter.id));
            dispatch(getChapters(courseId));
        }
    };

    const handleTopics = (chapterId) => {
        setSelectedChapterId(chapterId);
        setTopicsModalOpen(true);
    };

    const onFormSubmit = (data) => {
        let payload = {
            courseId: courseId,
            chapterNo: parseInt(data.chapterNo),
            name: data.name,
            hasAssessment: data.hasAssessment,
            totalQuestions: data.hasAssessment ? parseInt(data.totalQuestions) : null,
            totalMarks: data.hasAssessment ? parseInt(data.totalMarks) : null,
            totalLimit: data.totalLimit ? parseInt(data.totalLimitValue) : null,
        };

        if(!payload.hasAssessment) {
            delete payload.totalQuestions
            delete payload.totalMarks
            delete payload.totalLimit
        }
        if (editingChapter) {
            dispatch(updateChapter({ id: parseInt(editingChapter.id), ...payload }));
        } else {
            dispatch(addChapter(payload));
        }
        handleCloseModal();
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setEditingChapter(null);
        reset({
            chapterNo: '',
            name: '',
            hasAssessment: false,
            totalQuestions: '',
            totalMarks: '',
            totalLimit: false,
            totalLimitValue: '',
        });
    };

    const handleCloseTopicsModal = () => {
        setTopicsModalOpen(false);
        setSelectedChapterId(null);
    };

    const handleAddNew = () => {
        setEditingChapter(null);
        reset({
            chapterNo: '',
            name: '',
            hasAssessment: false,
            totalQuestions: '',
            totalMarks: '',
            totalLimit: false,
            totalLimitValue: '',
        });
        setModalOpen(true);
    };

    const columns = [
        {
            title: "Chapter No",
            dataIndex: "chapterNo",
            key: "chapterNo",
            sorter: (a, b) => a.chapterNo - b.chapterNo,
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: stringSorter("name"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Has Assessment",
            dataIndex: "hasAssessment",
            key: "hasAssessment",
            render: (hasAssessment) => (hasAssessment ? "Yes" : "No"),
        },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
                <>
                    <Button color="link" onClick={() => handleEdit(record)}>
                        <EditOutlined />
                    </Button>
                    <Button color="link" onClick={() => handleDelete(record)}>
                        <DeleteOutlined />
                    </Button>
                    <Button color="link" onClick={() => handleTopics(record.id)}>
                        <BookOutlined /> Topics
                    </Button>
                </>
            ),
        },
    ];

    return (
        <Container fluid={true}>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">Chapter List</h5>
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" onClick={handleAddNew}>
                                <PlusCircleFilled /> Add New Chapter
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactStrapDataTable
                        dataSource={chapters}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} backdrop={"static"}>
                <ModalHeader toggle={handleCloseModal}>
                    {editingChapter ? 'Edit Chapter' : 'Add Chapter'}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="chapterNo">Chapter No</Label>
                            <Controller
                                name="chapterNo"
                                control={control}
                                rules={{ required: "Chapter No is required" }}
                                render={({ field }) => <Input {...field} id="chapterNo" type="number" />}
                            />
                            {errors.chapterNo && <span className="text-danger">{errors.chapterNo.message}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: "Name is required" }}
                                render={({ field }) => <Input {...field} id="name" />}
                            />
                            {errors.name && <span className="text-danger">{errors.name.message}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="hasAssessment">Has Assessment</Label>
                            <Controller
                                name="hasAssessment"
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        type="select"
                                        {...field}
                                        onChange={(e) => field.onChange(e.target.value === 'true')}
                                    >
                                        <option value={false}>No</option>
                                        <option value={true}>Yes</option>
                                    </Input>
                                )}
                            />
                        </FormGroup>

                        {hasAssessment && (
                            <>
                                <FormGroup>
                                    <Label for="totalQuestions">Total Questions</Label>
                                    <Controller
                                        name="totalQuestions"
                                        control={control}
                                        rules={{
                                            required: "Total Questions is required",
                                            min: { value: 1, message: "Value must be greater than 0" }
                                        }}
                                        render={({ field }) => <Input type="number" {...field} id="totalQuestions" min="1" />}
                                    />
                                    {errors.totalQuestions && <span className="text-danger">{errors.totalQuestions.message}</span>}
                                </FormGroup>

                                <FormGroup>
                                    <Label for="totalMarks">Total Marks</Label>
                                    <Controller
                                        name="totalMarks"
                                        control={control}
                                        rules={{
                                            required: "Total Marks is required",
                                            min: { value: 1, message: "Value must be greater than 0" }
                                        }}
                                        render={({ field }) => <Input type="number" {...field} id="totalMarks" min="1" />}
                                    />
                                    {errors.totalMarks && <span className="text-danger">{errors.totalMarks.message}</span>}
                                </FormGroup>

                                <FormGroup>
                                    <Label for="totalLimit">Total Limit</Label>
                                    <Controller
                                        name="totalLimit"
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                type="select"
                                                {...field}
                                                onChange={(e) => field.onChange(e.target.value === 'true')}
                                            >
                                                <option value={false}>No</option>
                                                <option value={true}>Yes</option>
                                            </Input>
                                        )}
                                    />
                                </FormGroup>

                                {hasTotalLimit && (
                                    <FormGroup>
                                        <Label for="totalLimitValue">Total Limit Value</Label>
                                        <Controller
                                            name="totalLimitValue"
                                            control={control}
                                            rules={{
                                                required: "Total Limit Value is required",
                                                min: { value: 1, message: "Value must be greater than 0" }
                                            }}
                                            render={({ field }) => <Input type="number" {...field} id="totalLimitValue" min="1" />}
                                        />
                                        {errors.totalLimitValue && <span className="text-danger">{errors.totalLimitValue.message}</span>}
                                    </FormGroup>
                                )}
                            </>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {editingChapter ? 'Update' : 'Save'}
                        </Button>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>

            <Modal isOpen={topicsModalOpen} toggle={handleCloseTopicsModal} size="xl" backdrop={"static"}>
                <ModalHeader toggle={handleCloseTopicsModal}>
                    Manage Topics
                </ModalHeader>
                <ModalBody>
                    <Topics chapterId={selectedChapterId} onClose={handleCloseTopicsModal} />
                </ModalBody>
            </Modal>
        </Container>
    );
};

export default Chapter;