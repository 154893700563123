import {ADD_CHAPTER, GET_CHAPTERS, GET_CHAPTER, UPDATE_CHAPTER} from "../../types";


const initialState = {list: [], single: null};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_CHAPTERS:
            return {...state, list: payload};

        case GET_CHAPTER:
            return {
                ...state,
                list: [payload, ...state.list],
            };
        case ADD_CHAPTER:
            return {
                ...state,
                list: [payload, ...state.list],
            };

        case UPDATE_CHAPTER:
            return {
                ...state,
                list: state.list.map((item) =>
                    item.id === payload.id ? payload : item
                ),
            };
        default:
            return state;
    }
};

export default reducer;
