import axios from "../../axios";
import { login, logout } from "../../utils/auth";
import { AUTH_ERROR, AUTH_LOGIN, AUTH_LOGOUT } from "../types";
import { setAlert } from "./alert";


export const authLogin = (data, history) => async (dispatch) => {
    try {
        const res = await axios.post('user/login', data);
        return res;
    } catch (err) {
        dispatch({ type: AUTH_ERROR, payload: err.message });
        // dispatch(setAlert(err.message, 'danger'));
    }
};

export const sendVerificationEmail = (data) => async (dispatch) => {
    try {
        const res = await axios.post('user/send-verification', data);
        return res;
    } catch (err) {
        dispatch({ type: AUTH_ERROR, payload: err.message });
        // dispatch(setAlert(err.message, 'danger'));
    }

}

export const resetPassword = (object) => async (dispatch) => {
    try {
        const res = await axios.post('user/forgot-password',object);
        return res;
    } catch (err) {
        dispatch({ type: AUTH_ERROR, payload: err.message });
        // dispatch(setAlert(err.message, 'danger'));
    }

}
export const resetUserPassword=(object)=>async(dispatch)=>{
    try {
        const res = await axios.post('user/reset-password',object);
        return res;
    } catch (err) {
        dispatch({ type: AUTH_ERROR, payload: err.message });
        // dispatch(setAlert(err.message, 'danger'));
    }
}

// User Logout
export const authLogout = () => async (dispatch) => {
    try {
        logout();
        dispatch({ type: AUTH_LOGOUT, payload: '' });
        // dispatch(setAlert('Logout done', 'success'));
    } catch (err) {
        dispatch({ type: AUTH_ERROR, payload: err.message });
        // dispatch(setAlert(err.message, 'danger'));
    }
};
