const layoutTypes = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
}

const layoutModeTypes = {
  LIGHTMODE : "light",
  DARKMODE : "dark",
}

const layoutWidthTypes = {
  FLUID: "fluid",
  BOXED: "boxed",
}

const topBarThemeTypes = {
  LIGHT: "light",
  DARK: "dark",
}

const leftSidebarTypes = {
  DEFAULT: "default",
  COMPACT: "compact",
  ICON: "icon",
}

const leftSideBarThemeTypes = {
  LIGHT: "light",
  DARK: "dark",
}
const cardStyle={
  boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
  marginBottom: '10px',
  backgroundColor: '#f5f5f5',
  border: '1px solid #ddd',
  padding: '20px'
}

const newsCardStyle={
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  backgroundColor: '#f5f5f5',
}

const buttonCardStyle={

  boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
  marginBottom: '10px',
  backgroundColor: '#013e7d',
  border: '1px solid #ddd',
  padding: '20px'
}



export {
  layoutTypes,
  layoutModeTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
  cardStyle,
  buttonCardStyle,
  newsCardStyle
}
