import {
    GET_IMPROVEMENT_OPPORTUNITY_VALUES,
    GET_SINGLE_USER_IMPROVEMENT_OPPORTUNITY,
    GET_USER_IMPROVEMENT_OPPORTUNITY,
    ADD_IMPROVEMENT_OPPORTUNITY,
    UPDATE_IMPROVEMENT_OPPORTUNITY,
    GENERATE_IMPROVEMENT_OPPORTUNITY,
    UPDATE_IMPROVEMENT_OPPORTUNITY_ACTION
} from "../../types";

const initialState = { list: [], single: null, userImprovementOpportunity: {} };

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_IMPROVEMENT_OPPORTUNITY_VALUES:
            return { ...state, list: payload };
        case GET_USER_IMPROVEMENT_OPPORTUNITY:
            return { ...state, userImprovementOpportunity: payload };
        case GET_SINGLE_USER_IMPROVEMENT_OPPORTUNITY:
            return { ...state, single: payload };
        case ADD_IMPROVEMENT_OPPORTUNITY:
            return { ...state, userImprovementOpportunity: payload };
        case UPDATE_IMPROVEMENT_OPPORTUNITY:
            return {
                ...state,
                userImprovementOpportunity: payload
            };
        case GENERATE_IMPROVEMENT_OPPORTUNITY:
            return {
                ...state,
                userImprovementOpportunity: payload
            };
        case UPDATE_IMPROVEMENT_OPPORTUNITY_ACTION:
            return {
                ...state,
                userImprovementOpportunity: state.userImprovementOpportunity.map(improvementOpportunity =>
                    improvementOpportunity.id === payload.improvementOpportunityId
                        ? {
                            ...improvementOpportunity,
                            actions: improvementOpportunity.actions.map(action =>
                                action.id === payload.updatedAction.id
                                    ? payload.updatedAction
                                    : action
                            )
                        }
                        : improvementOpportunity
                )
            };
        default:
            return state;
    }
};

export default reducer;