import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Card, CardBody, Button, ButtonGroup } from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";
import {
    Legend,
    ResponsiveContainer,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
    Radar,
} from "recharts";

import { getGlobalActionCount } from "../../../../store/actions/Incident/action"
const STATUS_TYPES = ['pending', 'open', 'closed', 'delayed'];

const calculateProgressValue = (status, data) => {
    const total = STATUS_TYPES.reduce((sum, type) => {
        if (type !== 'delayed') {
            return sum + (data[type] || 0);
        }
        return sum;
    }, 0);
    return total === 0 ? 0 : ((data[status] || 0) * 100 / total);
};


const ACTION_TYPES = {
    C: 'Closeout',
    R: 'Responsible',
    S: 'Supervisor',
    N: 'User',
    CREATOR: 'Creator'
};

const SpiderCountIncident = ({ dateRange, startDate, endDate, isLoading }) => {
    const dispatch = useDispatch();
    const actionCounts = useSelector((state) => state.action.userActions);

    useEffect(() => {
        dispatch(getGlobalActionCount())
    }, [dispatch]);

    useEffect(() => {
        console.log("action counts")
        console.log(actionCounts)
    }, [actionCounts]);

    const prepareSpiderChartData = () => {
        const incidentTypes = ['hazards', 'incidentReports', 'nonConformities', 'improvementOpportunities', 'complaints'];

        return incidentTypes.map(incidentType => {
            const data = { subject: incidentType.charAt(0).toUpperCase() + incidentType.slice(1) };
            const typeData = actionCounts[incidentType]?.reduce((acc, item) => {
                STATUS_TYPES.forEach(status => {
                    acc[status] = (acc[status] || 0) + parseInt(item[status] || 0);
                });
                return acc;
            }, {}) || {};

            STATUS_TYPES.forEach(status => {
                data[status] = calculateProgressValue(status, typeData);
            });

            return data;
        });
    };

    const prepareAggregatedSpiderChartData = (actionCounts) => {
        return Object.entries(ACTION_TYPES).map(([actionType, actionName]) => {
            const data = { subject: actionName };

            STATUS_TYPES.forEach(status => {
                let sum = 0;
                Object.values(actionCounts).forEach(incidentTypeData => {
                    const item = incidentTypeData.find(item => item.type === actionType);
                    if (item!=="delayed") {
    
                        sum += parseInt(item[status] || 0);
                    }
                });
                data[status] = sum;
            });
            return data;
        });
    };

    const aggregatedSpiderChartData = prepareAggregatedSpiderChartData(actionCounts);
    const colors = {
        pending: "#FF6384",  // Red
        open: "#36A2EB",     // Blue
        closed: "#4BC0C0",   // Teal
        delayed: "#FFCE56"   // Yellow
    };
    const spiderChartData = prepareSpiderChartData();


    return (
        <Container fluid>
            <Row>
                <Col lg={6}>
                    <Card>
                        <CardBody>
                            <h5 className="card-title mb-3">
                                Spider Chart Incident Wise
                            </h5>
                            <ResponsiveContainer width="100%" height={500}>
                                <RadarChart outerRadius={200} data={spiderChartData}>
                                    <PolarGrid />
                                    <PolarAngleAxis dataKey="subject" />
                                    <PolarRadiusAxis angle={30} domain={[0, 100]} />
                                    <Radar
                                        name="Pending"
                                        dataKey="pending"
                                        stroke="#8884d8"
                                        fill="#8884d8"
                                        fillOpacity={0.6}
                                    />
                                    <Radar
                                        name="Open"
                                        dataKey="open"
                                        stroke="#82ca9d"
                                        fill="#82ca9d"
                                        fillOpacity={0.6}
                                    />
                                    <Radar
                                        name="Closed"
                                        dataKey="closed"
                                        stroke="#ffc658"
                                        fill="#ffc658"
                                        fillOpacity={0.6}
                                    />
                                    <Radar
                                        name="Delayed"
                                        dataKey="delayed"
                                        stroke="#ff7300"
                                        fill="#ff7300"
                                        fillOpacity={0.6}
                                    />
                                    <Legend />
                                </RadarChart>
                            </ResponsiveContainer>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card>
                        <CardBody>
                        <h5 className="card-title mb-3">
                                Spider Chart Action Wise
                            </h5>
                            <ResponsiveContainer width="100%" height={500}>
                                <RadarChart outerRadius={200} data={aggregatedSpiderChartData}>
                                    <PolarGrid />
                                    <PolarAngleAxis dataKey="subject" />
                                    <PolarRadiusAxis angle={30} domain={[0, 'auto']} />
                                    {STATUS_TYPES.map((status) => (
                                        <Radar
                                            key={status}
                                            name={status.charAt(0).toUpperCase() + status.slice(1)}
                                            dataKey={status}
                                            stroke={colors[status]}
                                            fill={colors[status]}
                                            fillOpacity={0.6}
                                        />
                                    ))}
                                    <Legend />
                                </RadarChart>
                            </ResponsiveContainer>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default SpiderCountIncident;