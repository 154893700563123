import axios from "../../axios";
import {ADD_ORGANIZATION, GET_ORGANIZATION_VALUES, GET_ORGANIZATIONS, UPDATE_ORGANIZATION} from "../types";
import {setAlert} from "./alert";

export const getOrganizations = () => async (dispatch) => {
    try {
        const res = await axios.get("organization/active");
        if (res.data.code === 1) {
            dispatch({type: GET_ORGANIZATIONS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const getOrganizationValues = () => async (dispatch) => {
    try {
        const res = await axios.get("organization/values");
        if (res.data.code === 1) {
            dispatch({type: GET_ORGANIZATION_VALUES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const addOrganization=(formData)=> async (dispatch) => {
    try {
        const res = await axios.post("organization/add",formData);
        if (res.data.code === 1) {
            dispatch({type: ADD_ORGANIZATION, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}
export const updateOrganization=(formData)=> async (dispatch) => {
    try {
        const res = await axios.post("organization/update",formData);
        if (res.data.code === 1) {
            dispatch({type: UPDATE_ORGANIZATION, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}