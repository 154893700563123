import React from "react";
import {Container} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";


const Orders =()=> {
    document.title = "HSSE Meinhardt EPCM | Orders";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Orders" breadcrumbItem="Manage Orders" />
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Orders;