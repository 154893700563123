import {ADD_ITEM, GET_ITEM, GET_ITEMS, UPDATE_ITEM} from "../types";


const initialState = {list: [], single: null};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_ITEMS:
            return {...state, list: payload};

        case GET_ITEM:
            return {
                ...state,
                list: [payload, ...state.list],
            };
        case ADD_ITEM:
            return {
                ...state,
                list: [payload, ...state.list],
            };

        case UPDATE_ITEM:
            return {
                ...state,
                list: state.list.map((item) =>
                    item.id === payload.id ? payload : item
                ),
            };

        default:
            return state;
    }
};

export default reducer;
