import { GET_GLOBAL_MODULES } from '../../types';

const initialState = {globalLinks:[],globalModules:[]};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_GLOBAL_MODULES:
            return {...state, globalModules: payload} 
       
        default:
            return state;
    }
};

export default reducer;
