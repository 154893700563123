import {
    CFR_VALUES, INCIDENT_CATEGORY_VALUES, INCIDENT_FILTER_CATEGORY,
    CFR_FILTER, CIR_VALUES, CIR_FILTER, IS_VALUES, IS_FILTER, IO_VALUES, IO_FILTER,
    PDR_VALUES,
    CSR_VALUES,
    IER_VALUES,
    IAC_VALUES, INCIDENT_FILTER_VALUES
} from "../../../types";
import axios from "../../../../axios";
import {setAlert} from "../../alert";



export const getIncidentReportCategoryValues = () => async (dispatch) => {
    try {
        const res = await axios.get("incident/reports/irc/values");
        if (res.data.code === 1) {
            dispatch({type: INCIDENT_CATEGORY_VALUES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const getFilterIncidentReportCategory=(object)=>async(dispatch)=>{
    try {
        const res = await axios.post("/incident/reports/irc", object);
        if (res.data.code === 1) {
            dispatch({type: INCIDENT_FILTER_CATEGORY, payload: res.data.data});
            return {success: true, message: res.data.message,};
        } else {
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        console.error("Error in addComplaintForm:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }

}

export const getContributingFactorReportValues=()=>async(dispatch)=>{
    try {
        const res = await axios.get("incident/reports/cfr/values");
        if (res.data.code === 1) {
            dispatch({type: CFR_VALUES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const getFilterContributingFactorReport=(object)=>async(dispatch)=>{
    try {
        const res = await axios.post("/incident/reports/cfr", object);
        
        if (res.data.code === 1) {
            dispatch({type: CFR_FILTER, payload: res.data.data});
            return {success: true, message: res.data.message,};
        } else {
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }

}

export const getContractorInvolvmentReportValues=()=>async(dispatch)=>{
    try {
        const res = await axios.get("incident/reports/cir/values");
        if (res.data.code === 1) {
            dispatch({type: CIR_VALUES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const getFilterContractorInvolvementReport=(object)=>async(dispatch)=>{
    try {
        const res = await axios.post("/incident/reports/cir", object);
        
        
        if (res.data.code === 1) {
            dispatch({type: CIR_FILTER, payload: res.data.data});
            return {success: true, message: res.data.message,};
        } else {
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }

}

export const getIssueSummaryValues=()=>async(dispatch)=>{
    try {
        const res = await axios.get("incident/reports/is/values");
        if (res.data.code === 1) {
            dispatch({type: IS_VALUES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const getIssueSummaryFilter=()=>async(dispatch)=>{
    try {
        const res = await axios.get("incident/reports/is");
        if (res.data.code === 1) {
            dispatch({type: IS_FILTER, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const getIssueOverviewValues=()=>async(dispatch)=>{
    try {
        const res = await axios.get("incident/reports/io/values");
        if (res.data.code === 1) {
            dispatch({type: IO_VALUES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const getPlantDamageReportValues=()=>async(dispatch)=>{
    try {
        const res = await axios.get("incident/reports/pdr/values");
        if (res.data.code === 1) {
            dispatch({type: PDR_VALUES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const getCostSummaryReportValues=()=>async(dispatch)=>{
    try {
        const res = await axios.get("incident/reports/csr/values");
        if (res.data.code === 1) {
            dispatch({type: CSR_VALUES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const getIncidentEnvironmentReportValues=()=>async(dispatch)=>{
    try {
        const res = await axios.get("incident/reports/ier/values");
        if (res.data.code === 1) {
            dispatch({type: IER_VALUES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const getIssueActionReportValues=()=>async(dispatch)=>{
    try {
        const res = await axios.get("incident/reports/iar/values");
        if (res.data.code === 1) {
            dispatch({type: IAC_VALUES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}


export const getIncidentReportValues=()=>async(dispatch)=>{
    try {
        const res = await axios.get("incident/reports/ir/values");
        if (res.data.code === 1) {
            dispatch({type: INCIDENT_FILTER_VALUES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}




