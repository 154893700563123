import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    Card, CardBody, CardHeader, Col, Progress, Row
} from "reactstrap";

import Select from "react-select";
import ReactStrapDataTable from "../../CustomDatatable/ReactStrapDataTable";

import {getListofCoursesUserEnrolled, getTableValuesofEnrolledUsers} from "../../../store/actions/competancy/courses";

const getContrastColor = (bgColor) => {
    // Convert hex to RGB
    const hex = bgColor.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);

    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Return black for light backgrounds, white for dark
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

const CompetencyDashboard = () => {

    const dispatch = useDispatch();
    const masterTables = useSelector((state) => state.course.courseEnrolledByUsers);
    const tableValues = useSelector((state) => state.course.masterCourseUser);

    const [selectedMaster, setSelectedMaster] = useState(null);


    const masterOptions = masterTables.map(table => ({
        value: table.id,
        label: table.name
    }));

    const columns = [
        {
            title: "User",
            dataIndex: "userName",
            key: "userName",
            sorter: (a, b) => a.userName.localeCompare(b.userName),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Total Topics",
            dataIndex: "totalTopics",
            key: "totalTopics",
            sorter: (a, b) => a.totalTopics - b.totalTopics,
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Completed Topics",
            dataIndex: "completedTopics",
            key: "completedTopics",
            sorter: (a, b) => a.completedTopics - b.completedTopics,
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Progress",
            dataIndex: "progressPercentage",
            key: "progressPercentage",
            sorter: (a, b) => a.progressPercentage - b.progressPercentage,
            sortDirections: ["ascend", "descend"],
            render: (progressPercentage) => {
                const progressColor = '#0d6efd'; // Bootstrap primary blue
                const textColor = getContrastColor(progressColor);

                return (
                    <div style={{
                        position: 'relative',
                        width: '100%',
                        border: '1px solid #ced4da',
                        borderRadius: '4px',
                        overflow: 'hidden'
                    }}>
                        <Progress
                            value={progressPercentage}
                            color="primary"
                            style={{
                                height: '24px',
                                borderRadius: '0'
                            }}
                        >
            <span style={{
                position: 'absolute',
                color: textColor,
                width: '100%',
                textAlign: 'center',
                lineHeight: '24px',
                fontWeight: 'bold',
                mixBlendMode: 'difference'
            }}>
              {`${progressPercentage}%`}
            </span>
                        </Progress>
                    </div>
                );
            },
        },
    ];


    const handleMasterChange = (selectedOption) => {
        //console.log(selectedOption);
        setSelectedMaster(selectedOption);
        if (selectedOption) {
            dispatch(getTableValuesofEnrolledUsers(selectedOption.value));
        }
    };

    useEffect(() => {
        dispatch(getListofCoursesUserEnrolled());
    }, [dispatch]);

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">Courses Dashboard</h5>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row className="mb-3">
                        <Col md={4}>
                            <Select
                                options={masterOptions}
                                value={selectedMaster}
                                onChange={handleMasterChange}
                                placeholder="Select Course"
                                isSearchable
                                isClearable
                                styles={{
                                    container: (provided) => ({
                                        ...provided,
                                        zIndex: 999 // You can adjust this value as needed
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                    <ReactStrapDataTable
                        dataSource={tableValues}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>
        </div>
    );
}

export default CompetencyDashboard;