import {
    GENERATE_TNT,
    TNT_VALUES,
    GET_USER_TNT,
    GET_SINGLE_TNT,
    ADD_TNT,
    UPDATE_TNT
} from "../../../types";

const initialState = { list: [], single: null, userTNT: [],values:[] };

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GENERATE_TNT:
            return {
                ...state,
                userTNT: state.userTNT.map(data =>
                    data.id === payload.id ? payload : data
                )
            };
        case TNT_VALUES:
            return { ...state, values: payload };
        case GET_USER_TNT:
            return { ...state, userTNT: payload };
        case GET_SINGLE_TNT:
            return { ...state, single: payload };
        case ADD_TNT:
            return { ...state, userTNT: [...state.userTNT, payload] };
        case UPDATE_TNT:
            return {
                ...state,
                userTNT: state.userTNT.map(data =>
                    data.id === payload.id ? payload : data
                )
            };
        default:
            return state;
    }
};

export default reducer;