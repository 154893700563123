import axios from "../../../../axios";
import {INFORM_FILTER_VALUES} from "../../../types";
import {setAlert} from "../../alert";

export const getInformFilterValues=()=>async(dispatch)=>{
    try {
        const res = await axios.get("incident/reports/in/values");
        if (res.data.code === 1) {
            dispatch({type: INFORM_FILTER_VALUES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}