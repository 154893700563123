import React, { useRef, useState, useCallback } from 'react';
import { Card, CardBody, CardTitle, CardText, Modal, ModalHeader, ModalBody, Button, Container, Row, Col } from 'reactstrap';
import { ChevronLeft, ChevronRight, Newspaper } from "lucide-react";
import parse from 'html-react-parser';
import styled from 'styled-components';

const CarouselWrapper = styled.div`
  --primary-color: #f8f9fa;
  --text-color: #013e7d;
  --hover-color: #e2e6ea;
  --accent-color: #4a90e2;

  .card {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: none;
  }

  .btn-primary {
    background-color: var(--accent-color);
    border-color: var(--accent-color);
    &:hover {
      background-color: darken(var(--accent-color), 10%);
      border-color: darken(var(--accent-color), 10%);
    }
  }

  .btn-light {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--text-color);
    &:hover {
      background-color: var(--hover-color);
      border-color: var(--hover-color);
    }
  }
`;

const ScrollableRow = styled.div`
    display: flex;
    overflow-x: hidden;
    scroll-behavior: smooth;
    padding-bottom: 15px;
`;

const NewsCard = styled(Card)`
    width: 300px;
    flex: 0 0 auto;
    margin-right: 20px;
    height: 400px;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }
`;

const NewsImage = styled.div`
    height: 200px;
    background-image: ${props => props.imageUrl ? `url(${props.imageUrl})` : 'none'};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f2f5;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
`;

const ContentSnippet = styled.div`
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`;

const ModalContent = styled.div`
    img {
        max-width: 100%;
        height: auto;
    }
`;

const ControlsContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 15px;
    gap: 10px;
`;

const CustomNewsCarousel = ({ news }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedNews, setSelectedNews] = useState(null);
    const scrollRef = useRef(null);

    const toggleModal = useCallback(() => {
        setModalOpen(prevState => !prevState);
    }, []);

    const handleReadMore = useCallback((e, newsItem) => {
        e.preventDefault();
        setSelectedNews(newsItem);
        setModalOpen(true);
    }, []);

    const scroll = (direction) => {
        if (scrollRef.current) {
            const scrollAmount = direction === 'left' ? -300 : 300;
            scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    return (
        <CarouselWrapper>
            <Card className="mb-4">
                <CardBody>
                    <Container>
                        <Row>
                            <Col>
                                <h4 className="card-title mb-3">Latest News</h4>
                                <div style={{ position: 'relative' }}>
                                    <ScrollableRow ref={scrollRef}>
                                        {news.map((newsItem, index) => (
                                            <NewsCard key={index}>
                                                {newsItem.image ? (
                                                    <NewsImage imageUrl={newsItem.fullpath} />
                                                ) : (
                                                    <NewsImage>
                                                        <Newspaper style={{ color: '#013e7d' }} size={64} />
                                                    </NewsImage>
                                                )}
                                                <CardBody style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                    <div>
                                                        <CardTitle tag="h5">
                                                            {newsItem.title || 'Untitled News'}
                                                        </CardTitle>
                                                        <CardText>
                                                            <small className="text-muted">{new Date(newsItem.createdAt).toLocaleDateString()}</small>
                                                        </CardText>
                                                        <ContentSnippet>
                                                            {newsItem.shortDescription || 'No description available'}
                                                        </ContentSnippet>
                                                    </div>
                                                    <Button size="sm" onClick={(e) => handleReadMore(e, newsItem)}>
                                                        Read More
                                                    </Button>
                                                </CardBody>
                                            </NewsCard>
                                        ))}
                                    </ScrollableRow>
                                </div>
                                <ControlsContainer>
                                    <Button color="light" onClick={() => scroll('left')}>
                                        <ChevronLeft size={20} />
                                    </Button>
                                    <Button color="light" onClick={() => scroll('right')}>
                                        <ChevronRight size={20} />
                                    </Button>
                                </ControlsContainer>
                            </Col>
                        </Row>
                    </Container>

                    <Modal isOpen={modalOpen} toggle={toggleModal} size="xl" fullscreen>
                        <ModalHeader toggle={toggleModal}>
                            {selectedNews && selectedNews.title ? selectedNews.title : 'News Details'}
                        </ModalHeader>
                        <ModalBody>
                            {selectedNews && (
                                <ModalContent>
                                    <p><strong>Date: </strong>{new Date(selectedNews.createdAt).toLocaleDateString()}</p>
                                    {selectedNews.description ? (
                                        parse(selectedNews.description)
                                    ) : (
                                        <p>No additional content available</p>
                                    )}
                                </ModalContent>
                            )}
                        </ModalBody>
                    </Modal>
                </CardBody>
            </Card>
        </CarouselWrapper>
    );
};

export default CustomNewsCarousel;