import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Container,
} from "reactstrap";
import { DeleteOutlined, EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import ReactStrapDataTable from "../../CustomDatatable/ReactStrapDataTable";
import JoditEditor from "jodit-react";
import {
    addNews,
    updateNews,
    deleteNews,
    getUserNews,
} from "../../../store/actions/news";
import {dateTimeSort, formDataToObject} from "../../../utils/functions";
import { Image } from 'antd';

// Separate component for JoditEditor
const MemoizedJoditEditor = React.memo(({ content, setContent }) => {
    console.log("Rendering JoditEditor");

    const config = useMemo(() => ({
        readonly: false,
        height: 400,
        uploader: {
            insertImageAsBase64URI: true
        },
        buttons: ['bold', 'italic', 'underline', 'strikethrough', '|',
            'align', '|', 'ul', 'ol', '|', 'font', 'fontsize', 'brush',
            'paragraph', '|', 'image', 'table', 'link', '|', 'undo', 'redo', '|',
            'hr', 'eraser', 'copyformat', '|', 'symbol', 'fullsize', 'print', 'about'],
        buttonsXS: ['bold', 'image', '|', 'brush', 'paragraph', '|', 'align', '|', 'undo', 'redo', '|', 'eraser', 'dots'],
        events: {
            afterInit: (instance) => {
                console.log("Jodit Editor initialized:", instance.uid);
            },
            beforeDestruct: (instance) => {
                console.log("Jodit Editor before destruct:", instance.uid);
            },
            change: () => {
                console.log("Content changed");
            },
            focus: () => {
                console.log("Editor focused");
            },
            blur: () => {
                console.log("Editor blurred");
            }
        }
    }), []);

    return (
        <JoditEditor
            value={content}
            config={config}
            tabIndex={1}
            onBlur={newContent => {
                console.log("Jodit Editor onBlur");
                setContent(newContent);
            }}
        />
    );
});

const News = () => {
    const dispatch = useDispatch();
    const newsList = useSelector((state) => state.news.userNews);

    const [modalOpen, setModalOpen] = useState(false);
    const [editingNews, setEditingNews] = useState(null);
    const [title, setTitle] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [content, setContent] = useState('');
    const [previewImage, setPreviewImage] = useState('');

    const { handleSubmit, reset } = useForm();

    useEffect(() => {
        dispatch(getUserNews());
    }, [dispatch]);

    const handleEdit = useCallback((news) => {
        console.log("Editing news:", news.id);
        setEditingNews(news);
        setTitle(news.title);
        setShortDescription(news.shortDescription);
        setImageFile(null);
        setPreviewImage(news.fullpath || '');
        setContent(news.description);
        setModalOpen(true);
    }, []);

    const handleDelete = useCallback(async (news) => {
        console.log("Attempting to delete news:", news.id);
        if (window.confirm("Are you sure you want to delete this news item?")) {
            await dispatch(deleteNews(news.id));
            dispatch(getUserNews());
        }
    }, [dispatch]);

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setImageFile(base64String);
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const onFormSubmit = useCallback(async () => {
        console.log("Form submitted");
        if (!title || !shortDescription || !content) {
            alert("Title, Short Description, and Description are mandatory fields.");
            return;
        }

        const formData = new FormData();
        formData.append('title', title);
        formData.append('shortDescription', shortDescription);
        formData.append('description', content);
        if (imageFile) {
            formData.append('file', imageFile);
        }

        let result;
        if (editingNews) {
            formData.append('id', parseInt(editingNews.id));
            result = await dispatch(updateNews(formData));
        } else {
            console.log("Adding new news");
            result = await dispatch(addNews(formData));
        }
        console.log("Submission result:", result.success);
        if (result.success) {
            reset();
            handleCloseModal();
            dispatch(getUserNews());
        }
    }, [title, shortDescription, content, imageFile, editingNews, dispatch, reset]);

    const handleCloseModal = useCallback(() => {
        console.log("Closing modal");
        setModalOpen(false);
        setEditingNews(null);
        setTitle('');
        setShortDescription('');
        setImageFile(null);
        setPreviewImage('');
        setContent('');
    }, []);

    const handleAddNew = useCallback(() => {
        console.log("Opening modal for new news");
        setEditingNews(null);
        setTitle('');
        setShortDescription('');
        setImageFile(null);
        setPreviewImage('');
        setContent('');
        setModalOpen(true);
    }, []);

    const columns = useMemo(() => [
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: dateTimeSort("createdAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Short Description",
            dataIndex: "shortDescription",
            key: "shortDescription",
        },
        {
            title: "Image",
            dataIndex: "image",
            key: "image",
            render: (image, record) => image ? (
                <Image
                    src={record.fullpath}
                    alt="News thumbnail"
                    style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                    preview={{
                        zIndex: 9999,
                        src: record.fullpath,
                        mask: <div style={{ fontSize: '14px' }}>View</div>
                    }}
                />
            ) : 'No image',
        },
        {
            title: "Actions",
            key: "actions",
            render: (_, record) => (
                <>
                    <Button color="link" onClick={() => handleEdit(record)}>
                        <EditOutlined />
                    </Button>
                    <Button color="link" onClick={() => handleDelete(record)}>
                        <DeleteOutlined />
                    </Button>
                </>
            ),
        },
    ], [handleEdit, handleDelete]);

    return (
        <Container fluid={true}>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">Manage News</h5>
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" onClick={handleAddNew}>
                                <PlusCircleFilled /> Add New News
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactStrapDataTable
                        dataSource={newsList}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} size="xl" backdrop="static">
                <ModalHeader toggle={handleCloseModal}>
                    {editingNews ? 'Edit News' : 'Add News'}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="title">Title *</Label>
                            <Input
                                type="text"
                                name="title"
                                id="title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="shortDescription">Short Description *</Label>
                            <Input
                                type="textarea"
                                name="shortDescription"
                                id="shortDescription"
                                value={shortDescription}
                                onChange={(e) => setShortDescription(e.target.value)}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="image">Image</Label>
                            <Input
                                type="file"
                                name="image"
                                id="image"
                                onChange={handleImageChange}
                                accept="image/*"
                            />
                            {previewImage && (
                                <img
                                    src={previewImage}
                                    alt="Preview"
                                    style={{ marginTop: '10px', maxWidth: '200px', maxHeight: '200px' }}
                                />
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label for="description">Description *</Label>
                            {modalOpen && (
                                <MemoizedJoditEditor
                                    content={content}
                                    setContent={setContent}
                                />
                            )}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {editingNews ? 'Update' : 'Save'}
                        </Button>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </Container>
    );
};

export default News;