import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';

const DebouncedSaveButton = ({ onSave, isProcessing, debounceTime = 1000 }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    let timer;
    if (isProcessing) {
      setIsDisabled(true);
    } else {
      timer = setTimeout(() => {
        setIsDisabled(false);
      }, debounceTime);
    }

    return () => clearTimeout(timer);
  }, [isProcessing, debounceTime]);

  const handleClick = async (e) => {
    e.preventDefault();
    if (!isDisabled) {
      setIsDisabled(true);
      try {
        await onSave();
      } catch (error) {
        // If there's an error (including validation error), re-enable the button
        setIsDisabled(false);
      }
    }
  };

  return (
    <Button
      color="primary"
      onClick={handleClick}
      disabled={isDisabled}
    >
      {isDisabled ? 'Saving...' : 'Save'}
    </Button>
  );
};

export default DebouncedSaveButton;