import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Container,
    Progress,
} from "reactstrap";
import { DeleteOutlined, EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import ReactStrapDataTable from "../../CustomDatatable/ReactStrapDataTable";

import {
    getTopicsByChapterId,
    addTopic,
    updateTopic,
    deleteTopic,
    uploadFileForTopic,
} from "../../../store/actions/competancy/topics";
import { stringSorter } from "../../../utils/functions";
import DebouncedSaveButton from "../../../components/Buttons/DebounceButton";

const CHUNK_SIZE = 1024 * 1024; // 1MB chunk size

const Topics = ({ chapterId }) => {
    const dispatch = useDispatch();
    const topics = useSelector((state) => state.topic.list);

    const [modalOpen, setModalOpen] = useState(false);
    const [editingTopic, setEditingTopic] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if(chapterId !== null && chapterId !== "") {
            dispatch(getTopicsByChapterId(chapterId));
        }

    }, [dispatch, chapterId]);

    const handleEdit = (topic) => {
        setEditingTopic(topic);
        reset({
            topicNo: topic.topicNo,
            name: topic.name,
            type: topic.type,
        });
        setSelectedFile(null);
        setUploadProgress(0);
        setModalOpen(true);
    };

    const handleDelete = async (topic) => {
        if (window.confirm("Are you sure you want to delete this topic?")) {
            await dispatch(deleteTopic(topic.id));
            dispatch(getTopicsByChapterId(chapterId));
        }
    };

    const uploadChunk = async (file, start, topicData, uniqueFilename, chunkIndex = 0) => {
        setIsUploading(true);
        const end = Math.min(start + CHUNK_SIZE, file.size);
        const chunk = file.slice(start, end);
        const formData = new FormData();
        formData.append('file', chunk, uniqueFilename);
        formData.append('fileName', uniqueFilename);  // Add unique file name to formData

        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
        formData.append('chunkIndex', parseInt(chunkIndex.toString()));
        formData.append('totalChunks', parseInt(totalChunks.toString()));

        if (end < file.size) {
            await dispatch(uploadFileForTopic(formData));
            setUploadProgress(Math.round((end / file.size) * 100));
            await uploadChunk(file, end, topicData, uniqueFilename, chunkIndex + 1);
        } else {
            // Last chunk, include all data in formData
            setUploadProgress(100);
            const lastChunkFormData = new FormData();
            lastChunkFormData.append('file', chunk, uniqueFilename);
            lastChunkFormData.append('fileName', uniqueFilename);  // Add unique file name to lastChunkFormData
            lastChunkFormData.append('chunkIndex', parseInt(chunkIndex.toString()));
            lastChunkFormData.append('totalChunks', parseInt(totalChunks.toString()));
            lastChunkFormData.append('topicNo', topicData.topicNo);
            lastChunkFormData.append('name', topicData.name);
            lastChunkFormData.append('type', topicData.type);
            lastChunkFormData.append('chapterId', parseInt(topicData.chapterId));

            if (editingTopic) {
                lastChunkFormData.append('id', parseInt(topicData.id));
                await dispatch(updateTopic(lastChunkFormData));
                setIsUploading(false);
            } else {
                await dispatch(addTopic(lastChunkFormData));
                setIsUploading(false);
            }
        }
    };

    const onFormSubmit = async (data) => {
        const timestamp = Date.now();
        const uniqueFilename = `${timestamp}${selectedFile.name.substring(selectedFile.name.lastIndexOf("."))}`;
        const topicData = {
            topicNo: data.topicNo,
            name: data.name,
            type: data.type,
            chapterId: parseInt(chapterId),
        };

        if (editingTopic) {
            topicData.id = editingTopic.id;
        }

        if (selectedFile) {
            await uploadChunk(selectedFile, 0, topicData, uniqueFilename);
        } else {
            const formData = new FormData();
            for (let key in topicData) {
                formData.append(key, topicData[key]);
            }
            if (editingTopic) {
                await dispatch(updateTopic(formData));
            } else {
                await dispatch(addTopic(formData));
            }
        }

        handleCloseModal();
        dispatch(getTopicsByChapterId(chapterId));
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setEditingTopic(null);
        setSelectedFile(null);
        setUploadProgress(0);
        reset();
    };

    const handleAddNew = () => {
        setEditingTopic(null);
        reset({ topicNo: '', name: '', type: '' });
        setSelectedFile(null);
        setUploadProgress(0);
        setModalOpen(true);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && (file.type === "video/mp4" || file.type === "application/pdf")) {
            setSelectedFile(file);
            setUploadProgress(0);
        } else {
            alert("Please select only MP4 or PDF files.");
            event.target.value = null;
        }
    };

    const columns = [
        {
            title: "Topic No",
            dataIndex: "topicNo",
            key: "topicNo",
            sorter: (a, b) => a.topicNo - b.topicNo,
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: stringSorter("name"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            render: (type) => type === 'L' ? 'Learning' : 'Exercises',
        },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
                <>
                    <Button color="link" onClick={() => handleEdit(record)}>
                        <EditOutlined />
                    </Button>
                    <Button color="link" onClick={() => handleDelete(record)}>
                        <DeleteOutlined />
                    </Button>
                </>
            ),
        },
    ];

    return (
        <Container fluid={true}>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">Topics List</h5>
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" onClick={handleAddNew}>
                                <PlusCircleFilled /> Add New Topic
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactStrapDataTable
                        dataSource={topics}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} backdrop={"static"}>
                <ModalHeader toggle={handleCloseModal}>
                    {editingTopic ? 'Edit Topic' : 'Add Topic'}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="topicNo">Topic No</Label>
                            <Controller
                                name="topicNo"
                                control={control}
                                rules={{ required: "Topic No is required" }}
                                render={({ field }) => <Input {...field} id="topicNo" type="text" />}
                            />
                            {errors.topicNo && <span className="text-danger">{errors.topicNo.message}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: "Name is required" }}
                                render={({ field }) => <Input {...field} id="name" />}
                            />
                            {errors.name && <span className="text-danger">{errors.name.message}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="type">Type</Label>
                            <Controller
                                name="type"
                                control={control}
                                rules={{ required: "Type is required" }}
                                render={({ field }) => (
                                    <Input {...field} type="select" id="type">
                                        <option value="">Select Type</option>
                                        <option value="L">Learning</option>
                                        <option value="E">Exercises</option>
                                    </Input>
                                )}
                            />
                            {errors.type && <span className="text-danger">{errors.type.message}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="file">File Upload (MP4 or PDF only)</Label>
                            <Input
                                type="file"
                                id="file"
                                accept=".mp4,.pdf"
                                onChange={handleFileChange}
                            />
                            {selectedFile && (
                                <p>Selected file: {selectedFile.name}</p>
                            )}
                        </FormGroup>

                        {selectedFile && (
                            <FormGroup>
                                <Label>Upload Progress</Label>
                                <Progress value={uploadProgress} className="mb-3">
                                    {uploadProgress}%
                                </Progress>
                            </FormGroup>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <DebouncedSaveButton onSave={handleSubmit(onFormSubmit)} isUploading={isUploading} />
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </Container>
    );
};

export default Topics;