
import React from 'react';
import { Modal, ModalHeader, ModalBody, Card, CardHeader, CardBody, Row, Col, Table, Button } from 'reactstrap';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { FilePdfOutlined } from "@ant-design/icons";
import meinhardtlogo from "../../../assets/images/meinhardt.png"
import {isImageFile} from "../../../utils/functions";


const ViewNonComformityModel = ({ isOpen, toggle, hazard }) => {
    console.log(hazard)
    const generatePDF = async () => {
        const input = document.getElementById('hazardDetails');

        const logo = input.querySelector('#meinhardtLogo');
        const originalStyle = logo.style.cssText;
        logo.style.maxWidth = '250px';
        logo.style.height = '250px';

        const canvas = await html2canvas(input, {
            scale: 2,
            useCORS: true,
            logging: false,
            windowWidth: input.scrollWidth,
            windowHeight: input.scrollHeight,
            onclone: (clonedDoc) => {
                clonedDoc.querySelectorAll('img').forEach(img => img.style.maxWidth = 'none');
            }
        });

        // Restore original logo style
        logo.style.cssText = originalStyle;

        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);

        let heightLeft = imgHeight * ratio;
        let position = 2;

        pdf.addImage(imgData, 'JPEG', 0, position, imgWidth * ratio, imgHeight * ratio);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
            position = -pageHeight * pdf.internal.getNumberOfPages();
            pdf.addPage();
            pdf.addImage(imgData, 'JPEG', 0, position, imgWidth * ratio, imgHeight * ratio);
            heightLeft -= pageHeight;
        }

        pdf.save(`NONCONFORMITY_${hazard.sequenceNo ?? "Sequence Not Generated"}.pdf`);
    };

    if (!hazard) return null;

    const renderValue = (value) => {
        if (value === undefined || value === null) return 'N/A';
        if (typeof value === 'boolean') return value ? '✅' : '❌';
        return value;
    };

    const isImageFile = (fileName) => {
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
        const extension = fileName.split('.').pop().toLowerCase();
        return imageExtensions.includes(extension);
    };

    const renderAttachment = (file, index) => {
        if (isImageFile(file.fileName)) {
            return (
                <div key={index} className="mb-2">
                    <p>Description: {renderValue(file.description)}</p>
                    <img
                        src={file.fullPath}
                        alt={file.fileName}
                        style={{width: '50%', height: 'auto'}}
                        className="max-w-full h-auto mb-2"
                        crossOrigin="anonymous"
                    />
                    <hr/>
                </div>
            );
        } else {
            return (
                <div key={index} className="mb-2">
                    <a href={file.fullPath} target="_blank" rel="noopener noreferrer">
                        {file.fileName}
                    </a>
                    <p>Description: {renderValue(file.description)}</p>
                </div>
            );
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="xl">
            <ModalHeader toggle={toggle}>
                <div className="d-flex justify-content-between align-items-center w-100">
                    <span style={{ marginRight: '10px' }}>View Non-Conformity</span>
                    <Button color="primary" onClick={generatePDF} style={{ marginLeft: 'auto' }}>
                        <FilePdfOutlined /> Download PDF
                    </Button>
                </div>
            </ModalHeader>
            <ModalBody id="hazardDetails">
                <div className="d-flex justify-content-between align-items-start mb-4">
                    <h3>NonConformity No. : {renderValue(hazard.sequenceNo)}</h3>
                    <div style={{
                        borderRadius: '5px'
                    }}>
                        <img
                            id="meinhardtLogo"
                            src={meinhardtlogo}
                            alt="Meinhardt Logo"
                            style={{maxWidth: '150px', height: 'auto'}}
                            crossOrigin="anonymous"
                        />
                    </div>
                </div>

                <Card className="mb-3">
                    <CardHeader>
                        <h4>General Detail</h4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={3}><strong>Responsible Manager:</strong></Col>
                            <Col md={9}>{renderValue(hazard.responsibleManager)}</Col>
                            <Col md={3}><strong>Closeout Manager:</strong></Col>
                            <Col md={9}>{renderValue(hazard.closeoutManager)}</Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card className="mb-3">
                    <CardHeader>
                        <h4>Admin details</h4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={3}><strong>Site:</strong></Col>
                            <Col md={9}>{renderValue(hazard.site)}</Col>
                            <Col md={3}><strong>L3 Contractor:</strong></Col>
                            <Col md={9}>{renderValue(hazard.l3Contractor)}</Col>
                            <Col md={3}><strong>Entered By:</strong></Col>
                            <Col md={9}>{renderValue(hazard.createdByUser)}</Col>
                            <Col md={3}><strong>Location Place:</strong></Col>
                            <Col md={9}>{renderValue(hazard.location)}</Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card className="mb-3">
                    <CardHeader>
                        <h4>Non-Conformity details</h4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={4}><strong>Detected On:</strong></Col>
                            <Col md={8}>{renderValue(new Date(hazard.detectedAt).toLocaleDateString())}</Col>
                            <Col md={4}><strong>Actual/Potential Non Confirmity On:</strong></Col>
                            <Col md={8}>{renderValue(hazard.ncAP)}</Col>
                            <Col md={4}><strong>Source of Nonconformity:</strong></Col>
                            <Col md={8}>{renderValue(hazard.ncAP)}</Col>
                            <Col md={4}><strong>Summary:</strong></Col>
                            <Col md={8}>{renderValue(hazard.summary)}</Col>
                            <Col md={4}><strong>Detail:</strong></Col>
                            <Col md={8}>{renderValue(hazard.detail)}</Col>
                            {/*<Col md={4}><strong>Detect By:</strong></Col>*/}
                            {/*<Col md={8}>{renderValue(hazard.identifiedByName)}</Col>*/}
                            {/*<Col md={4}><strong>Report Type:</strong></Col>*/}
                            {/*<Col md={8}>{renderValue(hazard.reportType)}</Col>*/}
                            {/*<Col md={4}><strong>Hazard Source:</strong></Col>*/}
                            {/*<Col md={8}>{renderValue(hazard.hazardSource)}</Col>*/}
                            {/*<Col md={4}><strong>Details:</strong></Col>*/}
                            {/*<Col md={8}>{renderValue(hazard.details)}</Col>*/}
                            {/*<Col md={4}><strong>Immediate Actions:</strong></Col>*/}
                            {/*<Col md={8}>{renderValue(hazard.immediateAction)}</Col>*/}
                        </Row>
                    </CardBody>
                </Card>

                <Card className="mb-3">
                    <CardHeader>
                        <h4>Contributing factors and actions</h4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={3}><strong>Further actions required?</strong></Col>
                            <Col md={9}>{renderValue(hazard.actionRequired)}</Col>

                        </Row>
                        <h3>Required Actions</h3>
                        <Table striped>
                            <thead>
                            <tr>
                                <th>From</th>
                                <th>Message</th>
                                <th>To</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(hazard.actions || []).map((action, index) => (
                                <tr key={index}>
                                    <td>{renderValue(action.createdByUser)}</td>
                                    <td>{renderValue(action.type)}</td>
                                    <td>{renderValue(action.createdFor)}</td>
                                    <td>{renderValue(action.description)}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>

                <Card className="mb-3">
                    <CardHeader>
                        <h4>Attachments</h4>
                    </CardHeader>
                    <CardBody>
                        {(hazard.files || []).map((file, index) => renderAttachment(file, index))}
                    </CardBody>
                </Card>
            </ModalBody>
        </Modal>
    );
};
const ViewComplaintModel = ({ isOpen, toggle, hazard }) => {
    console.log("Complaint")
    console.log(hazard)

    const generatePDF = async () => {
        const input = document.getElementById('hazardDetails');

        const logo = input.querySelector('#meinhardtLogo');
        const originalStyle = logo.style.cssText;
        logo.style.maxWidth = '250px';
        logo.style.height = '250px';

        const canvas = await html2canvas(input, {
            scale: 2,
            useCORS: true,
            logging: false,
            windowWidth: input.scrollWidth,
            windowHeight: input.scrollHeight,
            onclone: (clonedDoc) => {
                clonedDoc.querySelectorAll('img').forEach(img => img.style.maxWidth = 'none');
            }
        });

        // Restore original logo style
        logo.style.cssText = originalStyle;

        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);

        let heightLeft = imgHeight * ratio;
        let position = 2;

        pdf.addImage(imgData, 'JPEG', 0, position, imgWidth * ratio, imgHeight * ratio);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
            position = -pageHeight * pdf.internal.getNumberOfPages();
            pdf.addPage();
            pdf.addImage(imgData, 'JPEG', 0, position, imgWidth * ratio, imgHeight * ratio);
            heightLeft -= pageHeight;
        }

        pdf.save(`COMPLAINT_${hazard.sequenceNo ?? "Sequence Not Generated"}.pdf`);
    };

    if (!hazard) return null;

    const renderValue = (value) => {
        if (value === undefined || value === null) return 'N/A';
        if (typeof value === 'boolean') return value ? '✅' : '❌';
        return value;
    };

    const isImageFile = (fileName) => {
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
        const extension = fileName.split('.').pop().toLowerCase();
        return imageExtensions.includes(extension);
    };

    const renderAttachment = (file, index) => {
        if (isImageFile(file.fileName)) {
            return (
                <div key={index} className="mb-2">
                    <p>Description: {renderValue(file.description)}</p>
                    <img
                        src={file.fullPath}
                        alt={file.fileName}
                        style={{width: '50%', height: 'auto'}}
                        className="max-w-full h-auto mb-2"
                        crossOrigin="anonymous"
                    />
                    <hr/>
                </div>
            );
        } else {
            return (
                <div key={index} className="mb-2">
                    <a href={file.fullPath} target="_blank" rel="noopener noreferrer">
                        {file.fileName}
                    </a>
                    <p>Description: {renderValue(file.description)}</p>
                </div>
            );
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="xl">
            <ModalHeader toggle={toggle}>
                <div className="d-flex justify-content-between align-items-center w-100">
                    <span style={{ marginRight: '10px' }}>View Complaint</span>
                    <Button color="primary" onClick={generatePDF} style={{ marginLeft: 'auto' }}>
                        <FilePdfOutlined /> Download PDF
                    </Button>
                </div>
            </ModalHeader>
            <ModalBody id="hazardDetails">
                <div className="d-flex justify-content-between align-items-start mb-4">
                    <h3>Complaint No. : {renderValue(hazard.sequenceNo)}</h3>
                    <div style={{
                        borderRadius: '5px'
                    }}>
                        <img
                            id="meinhardtLogo"
                            src={meinhardtlogo}
                            alt="Meinhardt Logo"
                            style={{maxWidth: '150px', height: 'auto'}}
                            crossOrigin="anonymous"
                        />
                    </div>
                </div>

                <Card className="mb-3">
                    <CardHeader>
                        <h4>General Detail</h4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={3}><strong>Responsible Manager:</strong></Col>
                            <Col md={9}>{renderValue(hazard.responsibleManager)}</Col>
                            <Col md={3}><strong>Closeout Manager:</strong></Col>
                            <Col md={9}>{renderValue(hazard.closeoutManager)}</Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card className="mb-3">
                    <CardHeader>
                        <h4>Admin details</h4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={3}><strong>Site:</strong></Col>
                            <Col md={9}>{renderValue(hazard.site)}</Col>
                            <Col md={3}><strong>L3 Contractor:</strong></Col>
                            <Col md={9}>{renderValue(hazard.l3Contractor)}</Col>
                            <Col md={3}><strong>Entered By:</strong></Col>
                            <Col md={9}>{renderValue(hazard.createdByUser)}</Col>
                            <Col md={3}><strong>Location Place:</strong></Col>
                            <Col md={9}>{renderValue(hazard.location)}</Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card className="mb-3">
                    <CardHeader>
                        <h4>Complaint details</h4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={4}><strong>Detected On:</strong></Col>
                            <Col md={8}>{renderValue(new Date(hazard.detectedAt).toLocaleDateString())}</Col>
                            <Col md={4}><strong>Issue At:</strong></Col>
                            <Col md={8}>{renderValue(new Date(hazard.issuedAt).toLocaleDateString())}</Col>
                            <Col md={4}><strong>Complaint Source:</strong></Col>
                            <Col md={8}>{renderValue(new Date(hazard.issuedAt).toLocaleDateString())}</Col>
                            <Col md={4}><strong>Identifid By User:</strong></Col>
                            <Col md={8}>{renderValue(hazard.identifiedByUser)}</Col>
                            <Col md={4}><strong>Recieved Type:</strong></Col>
                            <Col md={8}>{renderValue(hazard.receivedByUser)}</Col>
                            <Col md={4}><strong>Complaint Significance:</strong></Col>
                            <Col md={8}>{renderValue(hazard.significance)}</Col>
                            <Col md={4}><strong>Complaint Receive Type:</strong></Col>
                            <Col md={8}>{renderValue(hazard.receiveType)}</Col>
                            <Col md={4}><strong>Client Name:</strong></Col>
                            <Col md={8}>{renderValue(hazard.clientName)}</Col>
                            <Col md={4}><strong>Complainant Name:</strong></Col>
                            <Col md={8}>{renderValue(hazard.complainantName)}</Col>
                            <Col md={4}><strong>Address Line 1:</strong></Col>
                            <Col md={8}>{renderValue(hazard.address1)}</Col>
                            <Col md={4}><strong>Address Line 2:</strong></Col>
                            <Col md={8}>{renderValue(hazard.address2)}</Col>
                            <Col md={4}><strong>Suburb:</strong></Col>
                            <Col md={8}>{renderValue(hazard.suburb)}</Col>
                            <Col md={4}><strong>State:</strong></Col>
                            <Col md={8}>{renderValue(hazard.state)}</Col>
                            <Col md={4}><strong>Postcode:</strong></Col>
                            <Col md={8}>{renderValue(hazard.postalCode)}</Col>
                            <Col md={4}><strong>Location:</strong></Col>
                            <Col md={8}>{renderValue(hazard.location)}</Col>
                            <Col md={4}><strong>Contact 1:</strong></Col>
                            <Col md={8}>{renderValue(hazard.contact1)}</Col>
                            <Col md={4}><strong>Contact 2:</strong></Col>
                            <Col md={8}>{renderValue(hazard.contact2)}</Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card className="mb-3">
                    <CardHeader>
                        <h4>Contributing factors and actions</h4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={3}><strong>Further actions required?</strong></Col>
                            <Col md={9}>{renderValue(hazard.actionRequired)}</Col>
                            <Col md={3}><strong>Is Improvement Opportunity:</strong></Col>
                            <Col md={9}>{renderValue(hazard.isImprovement)}</Col>
                        </Row>
                        <h3>Required Actions</h3>
                        <Table striped>
                            <thead>
                            <tr>
                                <th>From</th>
                                <th>Message</th>
                                <th>To</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(hazard.actions || []).map((action, index) => (
                                <tr key={index}>
                                    <td>{renderValue(action.createdByUser)}</td>
                                    <td>{renderValue(action.type)}</td>
                                    <td>{renderValue(action.createdFor)}</td>
                                    <td>{renderValue(action.description)}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>

                <Card className="mb-3">
                    <CardHeader>
                        <h4>Attachments</h4>
                    </CardHeader>
                    <CardBody>
                        {(hazard.files || []).map((file, index) => renderAttachment(file, index))}
                    </CardBody>
                </Card>
            </ModalBody>
        </Modal>
    );
};
const ViewHazardModal = ({ isOpen, toggle, hazard }) =>    {

    const generatePDF = async () => {
        const input = document.getElementById('hazardDetails');

        const logo = input.querySelector('#meinhardtLogo');
        const originalStyle = logo.style.cssText;
        logo.style.maxWidth = '250px';
        logo.style.height = '250px';

        const canvas = await html2canvas(input, {
            scale: 2,
            useCORS: true,
            logging: false,
            windowWidth: input.scrollWidth,
            windowHeight: input.scrollHeight,
            onclone: (clonedDoc) => {
                clonedDoc.querySelectorAll('img').forEach(img => img.style.maxWidth = 'none');
            }
        });

        // Restore original logo style
        logo.style.cssText = originalStyle;

        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);

        let heightLeft = imgHeight * ratio;
        let position = 2;

        pdf.addImage(imgData, 'JPEG', 0, position, imgWidth * ratio, imgHeight * ratio);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
            position = -pageHeight * pdf.internal.getNumberOfPages();
            pdf.addPage();
            pdf.addImage(imgData, 'JPEG', 0, position, imgWidth * ratio, imgHeight * ratio);
            heightLeft -= pageHeight;
        }

        pdf.save(`Hazard_${hazard.sequenceNo || 'Unknown'}.pdf`);
    };


    if (!hazard) return null;

    const renderValue = (value) => {
        if (value === undefined || value === null) return 'N/A';
        if (typeof value === 'boolean') return value ? '✅' : '❌';
        return value;
    };



    const renderAttachment = (file, index) => {
        if (isImageFile(file.fileName)) {
            return (
                <div key={index} className="mb-2">
                    <p>Description: {renderValue(file.description)}</p>
                    <img
                        src={file.fullPath}
                        alt={file.fileName}
                        style={{width: '50%', height: 'auto'}}
                        className="max-w-full h-auto mb-2"
                        crossOrigin="anonymous"
                    />
                    <hr/>
                </div>
            );
        } else {
            return (
                <div key={index} className="mb-2">
                    <a href={file.fullPath} target="_blank" rel="noopener noreferrer">
                        {file.fileName}
                    </a>
                    <p>Description: {renderValue(file.description)}</p>
                </div>
            );
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="xl" fullscreen>
            <ModalHeader toggle={toggle}>
                <div className="d-flex justify-content-between align-items-center w-100">
                    <span style={{ marginRight: '10px' }}>View Hazard</span>
                    <Button color="primary" onClick={generatePDF} style={{ marginLeft: 'auto' }}>
                        <FilePdfOutlined /> Download PDF
                    </Button>
                </div>
            </ModalHeader>
            <ModalBody id="hazardDetails">
                <div className="d-flex justify-content-between align-items-start mb-4">
                    <h3>Hazard No. : {renderValue(hazard.sequenceNo)}</h3>
                    <div style={{
                        borderRadius: '5px'
                    }}>
                        <img
                            id="meinhardtLogo"
                            src={meinhardtlogo}
                            alt="Meinhardt Logo"
                            style={{maxWidth: '150px', height: 'auto'}}
                            crossOrigin="anonymous"
                        />
                    </div>
                </div>

                <Card className="mb-3">
                    <CardHeader>
                        <h4>General Detail</h4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={3}><strong>Responsible Manager:</strong></Col>
                            <Col md={9}>{renderValue(hazard.responsibleManager)}</Col>
                            <Col md={3}><strong>Closeout Manager:</strong></Col>
                            <Col md={9}>{renderValue(hazard.closeoutManager)}</Col>
                            <Col md={3}><strong>Supervisor:</strong></Col>
                            <Col md={9}>{renderValue(hazard.supervisor)}</Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card className="mb-3">
                    <CardHeader>
                        <h4>Admin details</h4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={3}><strong>Site:</strong></Col>
                            <Col md={9}>{renderValue(hazard.site)}</Col>
                            <Col md={3}><strong>L3 Contractor:</strong></Col>
                            <Col md={9}>{renderValue(hazard.l3Contractor)}</Col>
                            <Col md={3}><strong>Entered By:</strong></Col>
                            <Col md={9}>{hazard.createdByUser}</Col>
                            <Col md={3}><strong>Location Place:</strong></Col>
                            <Col md={9}>{renderValue(hazard.location)}</Col>
                            <Col md={3}><strong>Project:</strong></Col>
                            <Col md={9}>{renderValue(hazard.project)}</Col>
                            <Col md={3}><strong>City:</strong></Col>
                            <Col md={9}>{renderValue(hazard.city)}</Col>
                            <Col md={3}><strong>State:</strong></Col>
                            <Col md={9}>{renderValue(hazard.state)}</Col>
                            <Col md={3}><strong>Country:</strong></Col>
                            <Col md={9}>{renderValue(hazard.country)}</Col>
                            <Col md={3}><strong>Business Unit:</strong></Col>
                            <Col md={9}>{renderValue(hazard.businessUnit)}</Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card className="mb-3">
                    <CardHeader>
                        <h4>Hazard details</h4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={4}><strong>Detected On:</strong></Col>
                            <Col md={8}>{renderValue(new Date(hazard.detectedAt).toLocaleDateString())}</Col>
                            <Col md={4}><strong>Detect By:</strong></Col>
                            <Col md={8}>{renderValue(hazard.identifiedByName)}</Col>
                            <Col md={4}><strong>Report Type:</strong></Col>
                            <Col md={8}>{renderValue(hazard.reportType)}</Col>
                            <Col md={4}><strong>Hazard Source:</strong></Col>
                            <Col md={8}>{renderValue(hazard.hazardSource)}</Col>

                            <hr/>
                            <Col md={4}><strong>Identified potential/Actual hazards:</strong></Col>
                            <Col md={8}>
                                {hazard.actualHazards ? (
                                    <ul className="p-0">
                                        {hazard.actualHazards.split(',').map((item, index) => (
                                            <li key={index}>{item.trim()}</li>
                                        ))}
                                    </ul>
                                ) : null}
                            </Col>
                            <hr/>

                            <Col md={4}><strong>Details:</strong></Col>
                            <Col md={8}>{renderValue(hazard.details)}</Col>
                            <Col md={4}><strong>Immediate Actions:</strong></Col>
                            <Col md={8}>{renderValue(hazard.immediateAction)}</Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card className="mb-3">
                    <CardHeader>
                        <h4>Contributing factors and actions</h4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={3}><strong>Further actions required?</strong></Col>
                            <Col md={9}>{renderValue(hazard.actionRequired)}</Col>
                            <Col md={3}><strong>Is Improvement Opportunity:</strong></Col>
                            <Col md={9}>{renderValue(hazard.isImprovement)}</Col>
                        </Row>

                        <h5 className="mt-4">Contributing Factors</h5>
                        <Table striped>
                            <thead>
                            <tr>
                                <th>Description</th>
                                <th>Contributing Factor</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(hazard.factors || []).map((factor, index) => (
                                <tr key={index}>
                                    <td>{renderValue(factor.description)}</td>
                                    <td>{renderValue(factor.contributingFactor)}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>

                        {/*<h5 className="mt-4">Cost Implications</h5>
                        <Table striped>
                            <thead>
                            <tr>
                                <th>Description</th>
                                <th>Amount</th>
                                <th>Cost Allocation</th>
                                <th>Comment</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(hazard.costs || []).map((cost, index) => (
                                <tr key={index}>
                                    <td>{renderValue(cost.description)}</td>
                                    <td>{renderValue(cost.amount)}</td>
                                    <td>{renderValue(cost.allocation)}</td>
                                    <td>{renderValue(cost.comment)}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
*/}
                        <h5 className="mt-4">Required Actions</h5>
                        <Table striped>
                            <thead>
                            <tr>
                                <th>From</th>
                                <th>Message</th>
                                <th>To</th>
                                <th>Description</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(hazard.actions || []).map((action, index) => (
                                <tr key={index}>
                                    <td>{renderValue(action.createdByUser)}</td>
                                    <td>{renderValue(action.type)}</td>
                                    <td>{renderValue(action.createdFor)}</td>
                                    <td>{renderValue(action.description)}</td>
                                    <td>{renderValue(action.status)}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>

                <Card className="mb-3">
                    <CardHeader>
                        <h4>Attachments</h4>
                    </CardHeader>
                    <CardBody>
                        {(hazard.files || []).map((file, index) => renderAttachment(file, index))}
                    </CardBody>
                </Card>

            </ModalBody>
        </Modal>
    );
};
const ViewImprovementOpportunityModal = ({ isOpen, toggle, improvementOpportunity }) => {

    console.log(improvementOpportunity)

    const generatePDF = async () => {
        const input = document.getElementById('improvementOpportunityDetails');

        const logo = input.querySelector('#meinhardtLogo');
        const originalStyle = logo.style.cssText;
        logo.style.maxWidth = '250px';
        logo.style.height = '250px';

        const canvas = await html2canvas(input, {
            scale: 2,
            useCORS: true,
            logging: false,
            windowWidth: input.scrollWidth,
            windowHeight: input.scrollHeight,
            onclone: (clonedDoc) => {
                clonedDoc.querySelectorAll('img').forEach(img => img.style.maxWidth = 'none');
            }
        });

        // Restore original logo style
        logo.style.cssText = originalStyle;

        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);

        let heightLeft = imgHeight * ratio;
        let position = 2;

        pdf.addImage(imgData, 'JPEG', 0, position, imgWidth * ratio, imgHeight * ratio);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
            position = -pageHeight * pdf.internal.getNumberOfPages();
            pdf.addPage();
            pdf.addImage(imgData, 'JPEG', 0, position, imgWidth * ratio, imgHeight * ratio);
            heightLeft -= pageHeight;
        }

        pdf.save(`ImprovementOpportunity_${improvementOpportunity.sequenceNo || 'Unknown'}.pdf`);
    };

    if (!improvementOpportunity) return null;

    const renderValue = (value) => {
        if (value === undefined || value === null) return 'N/A';
        if (typeof value === 'boolean') return value ? '✅' : '❌';
        return value;
    };

    const isImageFile = (fileName) => {
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
        const extension = fileName.split('.').pop().toLowerCase();
        return imageExtensions.includes(extension);
    };

    const renderAttachment = (file, index) => {
        if (isImageFile(file.fileName)) {
            return (
                <div key={index} className="mb-2">
                    <p>Description: {renderValue(file.description)}</p>
                    <img
                        src={file.fullPath}
                        alt={file.fileName}
                        style={{width: '50%', height: 'auto'}}
                        className="max-w-full h-auto mb-2"
                        crossOrigin="anonymous"
                    />
                    <hr/>
                </div>
            );
        } else {
            return (
                <div key={index} className="mb-2">
                    <a href={file.fullPath} target="_blank" rel="noopener noreferrer">
                        {file.fileName}
                    </a>
                    <p>Description: {renderValue(file.description)}</p>
                </div>
            );
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="xl" fullscreen>
            <ModalHeader toggle={toggle}>
                <div className="d-flex justify-content-between align-items-center w-100">
                    <span style={{ marginRight: '10px' }}>View Improvement Opportunity</span>
                    <Button color="primary" onClick={generatePDF} style={{ marginLeft: 'auto' }}>
                        <FilePdfOutlined /> Download PDF
                    </Button>
                </div>
            </ModalHeader>
            <ModalBody id="improvementOpportunityDetails">
                <div className="d-flex justify-content-between align-items-start mb-4">
                    <h3>Improvement Opportunity No. : {renderValue(improvementOpportunity.sequenceNo)}</h3>
                    <div style={{
                        borderRadius: '5px'
                    }}>
                        <img
                            id="meinhardtLogo"
                            src={meinhardtlogo}
                            alt="Meinhardt Logo"
                            style={{maxWidth: '150px', height: 'auto'}}
                            crossOrigin="anonymous"
                        />
                    </div>
                </div>

                <Card className="mb-3">
                    <CardHeader>
                        <h4>General Detail</h4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={3}><strong>Responsible Manager:</strong></Col>
                            <Col md={9}>{renderValue(improvementOpportunity.responsibleManager)}</Col>
                            <Col md={3}><strong>Closeout Manager:</strong></Col>
                            <Col md={9}>{renderValue(improvementOpportunity.closeoutManager)}</Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card className="mb-3">
                    <CardHeader>
                        <h4>Admin details</h4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={3}><strong>Site:</strong></Col>
                            <Col md={9}>{renderValue(improvementOpportunity.site)}</Col>
                            <Col md={3}><strong>L3 Contractor:</strong></Col>
                            <Col md={9}>{renderValue(improvementOpportunity.l3Contractor)}</Col>
                            <Col md={3}><strong>Entered By:</strong></Col>
                            <Col md={9}>{renderValue(improvementOpportunity.createdByUser)}</Col>
                            <Col md={3}><strong>Location Place:</strong></Col>
                            <Col md={9}>{renderValue(improvementOpportunity.location)}</Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card className="mb-3">
                    <CardHeader>
                        <h4>Improvement Opportunity details</h4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={4}><strong>Detected On:</strong></Col>
                            <Col md={8}>{renderValue(new Date(improvementOpportunity.detectedAt).toLocaleDateString())}</Col>
                            <Col md={4}><strong>Detect By:</strong></Col>
                            <Col md={8}>{renderValue(improvementOpportunity.identifiedByName)}</Col>
                            <Col md={4}><strong>Improvement Category:</strong></Col>
                            <Col md={8}>{renderValue(improvementOpportunity.improvementCategory)}</Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card className="mb-3">
                    <CardHeader>
                        <h4>Contributing factors and actions</h4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={3}><strong>Further actions required?</strong></Col>
                            <Col md={9}>{renderValue(improvementOpportunity.actionRequired)}</Col>
                        </Row>
                        <h3>Required Actions</h3>
                        <Table striped>
                            <thead>
                            <tr>
                                <th>From</th>
                                <th>Message</th>
                                <th>To</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(improvementOpportunity.actions || []).map((action, index) => (
                                <tr key={index}>
                                    <td>{renderValue(action.createdByUser)}</td>
                                    <td>{renderValue(action.type)}</td>
                                    <td>{renderValue(action.createdFor)}</td>
                                    <td>{renderValue(action.description)}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>

                <Card className="mb-3">
                    <CardHeader>
                        <h4>Attachments</h4>
                    </CardHeader>
                    <CardBody>
                        {(improvementOpportunity.files || []).map((file, index) => renderAttachment(file, index))}
                    </CardBody>
                </Card>

            </ModalBody>
        </Modal>
    );
};
const ViewIncidentModal = ({ isOpen, toggle, incident }) => {
    const generatePDF = async () => {
        const input = document.getElementById('incidentDetails');

        const logo = input.querySelector('#meinhardtLogo');
        const originalStyle = logo.style.cssText;
        logo.style.maxWidth = '250px';
        logo.style.height = '250px';

        const canvas = await html2canvas(input, {
            scale: 2,
            useCORS: true,
            logging: false,
            windowWidth: input.scrollWidth,
            windowHeight: input.scrollHeight,
            onclone: (clonedDoc) => {
                clonedDoc.querySelectorAll('img').forEach(img => img.style.maxWidth = 'none');
            }
        });

        // Restore original logo style
        logo.style.cssText = originalStyle;

        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);

        let heightLeft = imgHeight * ratio;
        let position = 2;

        pdf.addImage(imgData, 'JPEG', 0, position, imgWidth * ratio, imgHeight * ratio);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
            position = -pageHeight * pdf.internal.getNumberOfPages();
            pdf.addPage();
            pdf.addImage(imgData, 'JPEG', 0, position, imgWidth * ratio, imgHeight * ratio);
            heightLeft -= pageHeight;
        }

        pdf.save(`Incident_${incident.sequenceNo || 'Unknown'}.pdf`);
    };

    if (!incident) return null;

    const renderValue = (value) => {
        if (value === undefined || value === null) return 'N/A';
        if (typeof value === 'boolean') return value ? '✅' : '❌';
        return value;
    };

    const renderAttachment = (file, index) => {
        if (isImageFile(file.fileName)) {
            return (
                <div key={index} className="mb-2">
                    <p>Description: {renderValue(file.description)}</p>
                    <img
                        src={file.fullPath}
                        alt={file.fileName}
                        style={{width: '50%', height: 'auto'}}
                        className="max-w-full h-auto mb-2"
                        crossOrigin="anonymous"
                    />
                    <hr/>
                </div>
            );
        } else {
            return (
                <div key={index} className="mb-2">
                    <a href={file.fullPath} target="_blank" rel="noopener noreferrer">
                        {file.fileName}
                    </a>
                    <p>Description: {renderValue(file.description)}</p>
                </div>
            );
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="xl">
            <ModalHeader toggle={toggle}>
                <div className="d-flex justify-content-between align-items-center w-100">
                    <span style={{ marginRight: '10px' }}>View Incident Report</span>
                    <Button color="primary" onClick={generatePDF} style={{ marginLeft: 'auto' }}>
                        <FilePdfOutlined /> Download PDF
                    </Button>
                </div>
            </ModalHeader>
            <ModalBody id="incidentDetails">
                <div className="d-flex justify-content-between align-items-start mb-4">
                    <h3>Incident Report No. : {renderValue(incident.sequenceNo)}</h3>
                    <div style={{
                        borderRadius: '5px'
                    }}>
                        <img
                            id="meinhardtLogo"
                            src={meinhardtlogo}
                            alt="Meinhardt Logo"
                            style={{maxWidth: '150px', height: 'auto'}}
                            crossOrigin="anonymous"
                        />
                    </div>
                </div>

                <Card className="mb-3">
                    <CardHeader>
                        <h4>General Detail</h4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={3}><strong>Responsible Manager:</strong></Col>
                            <Col md={9}>{renderValue(incident.responsibleManager)}</Col>
                            <Col md={3}><strong>Closeout Manager:</strong></Col>
                            <Col md={9}>{renderValue(incident.closeoutManager)}</Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card className="mb-3">
                    <CardHeader>
                        <h4>Admin details</h4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={3}><strong>Site:</strong></Col>
                            <Col md={9}>{renderValue(incident.site)}</Col>
                            <Col md={3}><strong>L3 Contractor:</strong></Col>
                            <Col md={9}>{renderValue(incident.l3Contractor)}</Col>
                            <Col md={3}><strong>Entered By:</strong></Col>
                            <Col md={9}>{renderValue(incident.createdByUser)}</Col>
                            <Col md={3}><strong>Location Place:</strong></Col>
                            <Col md={9}>{renderValue(incident.location)}</Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card className="mb-3">
                    <CardHeader>
                        <h4>Incident Report details</h4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={4}><strong>Detected On:</strong></Col>
                            <Col md={8}>{renderValue(new Date(incident.detectedAt).toLocaleDateString())}</Col>
                            <Col md={4}><strong>Detect By:</strong></Col>
                            <Col md={8}>{renderValue(incident.identifiedByName)}</Col>
                            <Col md={4}><strong>Hazard Source:</strong></Col>
                            <Col md={8}>{renderValue(incident.hazardSource)}</Col>
                            <Col md={4}><strong>Description:</strong></Col>
                            <Col md={8}>{renderValue(incident.description)}</Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card className="mb-3">
                    <CardHeader>
                        <h4>Contributing factors and actions</h4>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={3}><strong>Further actions required?</strong></Col>
                            <Col md={9}>{renderValue(incident.actionRequired)}</Col>
                        </Row>
                        {incident.actionRequired && (
                            <>
                                <h3>Required Actions</h3>
                                <Table striped>
                                    <thead>
                                    <tr>
                                        <th>From</th>
                                        <th>Message</th>
                                        <th>To</th>
                                        <th>Description</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {(incident.actions || []).map((action, index) => (
                                        <tr key={index}>
                                            <td>{renderValue(action.createdByUser)}</td>
                                            <td>{renderValue(action.type)}</td>
                                            <td>{renderValue(action.createdFor)}</td>
                                            <td>{renderValue(action.description)}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </>
                        )}
                    </CardBody>
                </Card>

                <Card className="mb-3">
                    <CardHeader>
                        <h4>Attachments</h4>
                    </CardHeader>
                    <CardBody>
                        {(incident.files || []).map((file, index) => renderAttachment(file, index))}
                    </CardBody>
                </Card>


            </ModalBody>
        </Modal>
    );
};

export { ViewHazardModal, ViewIncidentModal, ViewComplaintModel, ViewImprovementOpportunityModal, ViewNonComformityModel }