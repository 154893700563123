import React, { useRef, useLayoutEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Button, ButtonGroup } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    Legend,
    ResponsiveContainer,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
    Radar,
} from "recharts";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import SpiderCountIncident from '../Global/spidercount';



const CountryGlobalDashboard = () => {
    const chartRef = useRef(null);
    const [dateRange, setDateRange] = useState('1M');
    const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const [endDate, setEndDate] = useState(new Date());

    const spiderChartData = {
        '1M': [
            { subject: "Safety Culture", A: 120, B: 110, fullMark: 150 },
            { subject: "Risk Assessment", A: 98, B: 130, fullMark: 150 },
            { subject: "Training", A: 86, B: 130, fullMark: 150 },
            { subject: "Incident Reporting", A: 99, B: 100, fullMark: 150 },
            { subject: "Emergency Preparedness", A: 85, B: 90, fullMark: 150 },
            { subject: "Health Programs", A: 65, B: 85, fullMark: 150 },
        ],
        '3M': [
            { subject: "Safety Culture", A: 110, B: 105, fullMark: 150 },
            { subject: "Risk Assessment", A: 95, B: 125, fullMark: 150 },
            { subject: "Training", A: 80, B: 120, fullMark: 150 },
            { subject: "Incident Reporting", A: 95, B: 95, fullMark: 150 },
            { subject: "Emergency Preparedness", A: 80, B: 85, fullMark: 150 },
            { subject: "Health Programs", A: 60, B: 80, fullMark: 150 },
        ],
        '6M': [
            { subject: "Safety Culture", A: 100, B: 100, fullMark: 150 },
            { subject: "Risk Assessment", A: 90, B: 120, fullMark: 150 },
            { subject: "Training", A: 75, B: 110, fullMark: 150 },
            { subject: "Incident Reporting", A: 90, B: 90, fullMark: 150 },
            { subject: "Emergency Preparedness", A: 75, B: 80, fullMark: 150 },
            { subject: "Health Programs", A: 55, B: 75, fullMark: 150 },
        ],
        '1Y': [
            { subject: "Safety Culture", A: 90, B: 95, fullMark: 150 },
            { subject: "Risk Assessment", A: 85, B: 115, fullMark: 150 },
            { subject: "Training", A: 70, B: 100, fullMark: 150 },
            { subject: "Incident Reporting", A: 85, B: 85, fullMark: 150 },
            { subject: "Emergency Preparedness", A: 70, B: 75, fullMark: 150 },
            { subject: "Health Programs", A: 50, B: 70, fullMark: 150 },
        ],
        'custom': [
            { subject: "Safety Culture", A: 105, B: 100, fullMark: 150 },
            { subject: "Risk Assessment", A: 92, B: 122, fullMark: 150 },
            { subject: "Training", A: 78, B: 115, fullMark: 150 },
            { subject: "Incident Reporting", A: 93, B: 92, fullMark: 150 },
            { subject: "Emergency Preparedness", A: 78, B: 82, fullMark: 150 },
            { subject: "Health Programs", A: 58, B: 78, fullMark: 150 },
        ],
    };

    const pyramidChartData = {
        '1M': [
            { category: "Fatality", value: 1 },
            { category: "Lost Time Injury", value: 10 },
            { category: "Recordable Injury", value: 30 },
            { category: "First Aid", value: 100 },
            { category: "Near Miss", value: 300 },
        ],
        '3M': [
            { category: "Fatality", value: 2 },
            { category: "Lost Time Injury", value: 15 },
            { category: "Recordable Injury", value: 45 },
            { category: "First Aid", value: 150 },
            { category: "Near Miss", value: 450 },
        ],
        '6M': [
            { category: "Fatality", value: 3 },
            { category: "Lost Time Injury", value: 20 },
            { category: "Recordable Injury", value: 60 },
            { category: "First Aid", value: 200 },
            { category: "Near Miss", value: 600 },
        ],
        '1Y': [
            { category: "Fatality", value: 5 },
            { category: "Lost Time Injury", value: 30 },
            { category: "Recordable Injury", value: 90 },
            { category: "First Aid", value: 300 },
            { category: "Near Miss", value: 900 },
        ],
        'custom': [
            { category: "Fatality", value: 4 },
            { category: "Lost Time Injury", value: 25 },
            { category: "Recordable Injury", value: 75 },
            { category: "First Aid", value: 250 },
            { category: "Near Miss", value: 750 },
        ],
    };

    useLayoutEffect(() => {
        let root = am5.Root.new("chartdiv");
    
        root.setThemes([am5themes_Animated.new(root)]);
    
        let chart = root.container.children.push(
            am5percent.SlicedChart.new(root, {
                layout: root.verticalLayout,
                bottomRatio: 1/4,
            })
        );
    
        let series = chart.series.push(
            am5percent.PyramidSeries.new(root, {
                valueField: "value",
                categoryField: "category",
                alignLabels: true,
            })
        );
    
        series.get("colors").set("colors", [
            am5.color("#FF0000"),  // Red for Fatality
            am5.color("#FFA500"),  // Orange for Lost Time/Serious Injury
            am5.color("#008000"),  // Green for Minor Injury
            am5.color("#00FFFF"),  // Cyan for Near Miss
            am5.color("#800080")   // Purple for Unsafe Act
        ]);
    
        series.data.setAll(pyramidChartData[dateRange]);
    
        series.appear();
    
        chart.appear(1000, 100);
    
        chartRef.current = root;
    
        return () => {
            root.dispose();
        };
    }, [dateRange]);

    const handleDateRangeChange = (range) => {
        setDateRange(range);
        if (range === '1M') {
            setStartDate(new Date(new Date().setMonth(new Date().getMonth() - 1)));
            setEndDate(new Date());
        } else if (range === '3M') {
            setStartDate(new Date(new Date().setMonth(new Date().getMonth() - 3)));
            setEndDate(new Date());
        } else if (range === '6M') {
            setStartDate(new Date(new Date().setMonth(new Date().getMonth() - 6)));
            setEndDate(new Date());
        } else if (range === '1Y') {
            setStartDate(new Date(new Date().setFullYear(new Date().getFullYear() - 1)));
            setEndDate(new Date());
        }
    };

    const handleCustomDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start && end) {
            setDateRange('custom');
            // Here you would typically fetch data for the custom date range
            // For this example, we're using mock data
        }
    };

    return (
        <Container fluid>
            <Row className="mb-3">
                <Col>
                    <h4>Country Dashboard</h4>
                    <ButtonGroup className="me-3">
                        <Button color="primary" onClick={() => handleDateRangeChange('1M')} active={dateRange === '1M'}>1M</Button>
                        <Button color="primary" onClick={() => handleDateRangeChange('3M')} active={dateRange === '3M'}>3M</Button>
                        <Button color="primary" onClick={() => handleDateRangeChange('6M')} active={dateRange === '6M'}>6M</Button>
                        <Button color="primary" onClick={() => handleDateRangeChange('1Y')} active={dateRange === '1Y'}>1Y</Button>
                    </ButtonGroup>
                    <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={handleCustomDateChange}
                        className="form-control d-inline-block"
                        placeholderText="Select custom date range"
                        style={{ width: "200px" }}
                    />
                </Col>
            </Row>
            <SpiderCountIncident/>
            <Row>
                <Col lg={6}>
                    <Card>
                        <CardBody>
                            <h5 className="card-title mb-3">
                                Spider Chart
                            </h5>
                            <ResponsiveContainer width="100%" height={500}>
                                <RadarChart outerRadius={200} data={spiderChartData[dateRange]}>
                                    <PolarGrid />
                                    <PolarAngleAxis dataKey="subject" />
                                    <PolarRadiusAxis angle={30} domain={[0, 150]} />
                                    <Radar
                                        name="Company A"
                                        dataKey="A"
                                        stroke="#8884d8"
                                        fill="#8884d8"
                                        fillOpacity={0.6}
                                    />
                                    <Radar
                                        name="Company B"
                                        dataKey="B"
                                        stroke="#82ca9d"
                                        fill="#82ca9d"
                                        fillOpacity={0.6}
                                    />
                                    <Legend />
                                </RadarChart>
                            </ResponsiveContainer>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card>
                        <CardBody>
                            <h5 className="card-title mb-3">
                                Safety Incident Pyramid
                            </h5>
                            <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
           
        </Container>
    );
}

export default CountryGlobalDashboard;