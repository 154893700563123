export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

//ADMIN LOGIN
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_ERROR = "AUTH_ERROR";

//ITEMS
export const ADD_ITEM = "ADD_ITEM";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const GET_ITEMS = "GET_ITEMS";
export const GET_ITEM = "GET_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";

//SALESMAN
export const ADD_SALESMAN = "ADD_SALESMAN";
export const UPDATE_SALESMAN = "UPDATE_SALESMAN";
export const GET_SALESMANS = "GET_SALESMANS";
export const GET_SALESMAN = "GET_SALESMAN";
export const DELETE_SALESMAN = "DELETE_SALESMAN";

//VISIT
export const GET_VISITS = "GET_VISITS";

//SYSROLES
export const GET_ROLES = "GET_ROLES";
export const GET_ROLE_MODULES = "GET_ROLES_MODULES";
export const GET_SPECIFIC_ROLE_MODULES = "GET_SPECIFIC_ROLE_MODULES";

//ORGANIZATION
export const GET_ORGANIZATIONS = "GET_ORGANIZATION";
export const GET_ORGANIZATION_VALUES="GET_ORGANIZATION_VALUES"
export const ADD_ORGANIZATION="ADD_ORGANIZATION"
export const UPDATE_ORGANIZATION="UPDATE_ORGANIZATION"
export const DELETE_ORGANIZATION="DELETE_ORGANIZATION"
export const CLEAR_SINGLE_ORGANIZATION="CLEAR_SINGLE_ORGANIZATION"


//USER
export const GET_USERS_ACTIVE = "GET_USERS_ACTIVE";
export const GET_USERS = "GET_USERS";
export const GET_USER_VALUES = "GET_USER_VALUES";
export const GET_USER = "GET_USER";
export const ADD_USER = "ADD_USER";
export const UPDATE_USER = "UPDATE_USER";
export const USER_OTP_VERIFY_SUCCESS = "USER_OTP_VERIFY_SUCCESS";
export const USER_OTP_VERIFY_FAIL = "USER_OTP_VERIFY_FAIL";
export const GET_USER_MENU = "GET_USER_MENU";
export const GET_USER_BINDING = "GET_USER_BINDING";
export const GET_USER_BINDINGS = "GET_USER_BINDINGS";
export const SAVE_USER_BINDINGS = "SAVE_USER_BINDINGS";
export const GET_USER_PROFILE="GET_USER_PROFILE"


//QUICK LINKS
export const GET_USER_LINKS="GET_USER_LINKS"
export const ADD_USER_LINKS="ADD_USER_LINKS"
export const UPDATE_USER_LINKS="UPDATE_USER_LINKS"
export const DELETE_USER_LINKS="DELETE_USER_LINKS"

//QUICK ROUTES
export const GET_USER_MODULES="GET_USER_MODULES"
export const ADD_USER_MODULES="ADD_USER_MODULES"
export const UPDATE_USER_MODULES="UPDATE_USER_MODULES"





//export const UPDATE_USER_BINDINGS = "UPDATE_USER_BINDINGS"

//NAVIGATION
export const GET_NAVIGATION = "GET_NAVIGATION";

//HAZARD
export const GET_HAZARD_VALUES = "GET_HAZARD_VALUES";
export const ADD_HAZARD = "ADD_HAZARD";
export const UPDATE_HAZARD = "UPDATE_HAZARD";
export const GET_USER_HAZARD = "GET_USER_HAZARD";
export const GET_SINGLE_USER_HAZARD = "GET_SINGLE_USER_HAZARD";
export const GENERATE_HAZARD = "GENERATE_HAZARD";
export const DELETE_HAZARD="DELETE_HAZARD"

//ACTION
export const GET_ACTION_HAZARD = "GET_ACTION_HAZARD";
export const UPDATE_HAZARD_ACTION = "UPDATE_HAZARD_ACTION";

//INCIDENT
export const GET_INCIDENTS = "GET_INCIDENTS";
export const GET_USER_INCIDENT = "GET_USER_INCIDENT";
export const GET_SINGLE_INCIDENT = "GET_SINGLE_INCIDENT";
export const ADD_INCIDENT = "ADD_INCIDENT";
export const UPDATE_INCIDENT = "UPDATE_INCIDENT";
export const SUBMIT_INCIDENT = "SUBMIT_INCIDENT";
export const SUBMIT_ACTION_FOR_INCIDENT = "SUBMIT_ACTION_FOR_INCIDENT";
export const DELETE_INCIDENT="DELETE_INCIDENT";

//COUNTRY
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRY = "GET_COUNTRY";
export const ADD_COUNTRY = "ADD_COUNTRY";
export const UPDATE_COUNTRY = "UPDATE_COUNTRY";
export const DELETE_COUNTRY = "DELETE_COUNTRY";

//STATE
export const GET_STATES = "GET_STATES";
export const GET_STATE = "GET_STATE";
export const ADD_STATE = "ADD_STATE";
export const UPDATE_STATE = "UPDATE_STATE";
export const DELETE_STATE = "DELETE_STATE";

//CITY
export const ADD_CITY = "ADD_CITY";
export const GET_CITIES = "GET_CITIES";
export const GET_CITY = "GET_CITY";
export const UPDATE_CITY = "UPDATE_CITY";
export const DELETE_CITY = "DELETE_CITY";

//PERMISSION
export const ADD_PERMISSION = "ADD_PERMISSION";

//SYSTEM ROLES
export const GET_SYSTEM_ROLES = "GET_SYSTEM_ROLES";
export const GET_SYSTEM_ROLE = "GET_SYSTEM_ROLE";
export const ADD_SYSTEM_ROLE = "ADD_SYSTEM_ROLE";
export const UPDATE_SYSTEM_ROLE = "UPDATE_SYSTEM_ROLE";
export const DELETE_SYSTEM_ROLE="DELETE_SYSTEM_ROLE"

//TAGS MASTER
export const GET_TAGS = "GET_TAGS";
export const ADD_TAG = "ADD_TAGS";
export const UPDATE_TAG = "ADD_TAGS";

//BUSINESSGROUPS
export const GET_BUSINESS_GROUPS = "GET_BUSINESS_GROUPS";
export const GET_BUSINESS_GROUP = "GET_BUSINESS_GROUP";
export const ADD_BUSINESS_GROUP = "ADD_BUSINESS_GROUP";
export const UPDATE_BUSINESS_GROUP = "UPDATE_BUSINESS_GROUP";
export const DELETE_BUSINESS_GROUP = "DELETE_BUSINESS_GROUP";

//BUSINESS UNITS
export const GET_BUSINESS_UNITS = "GET_BUSINESS_UNITS";
export const GET_BUSINESS_UNIT = "GET_BUSINESS_UNIT";
export const ADD_BUSINESS_UNIT = "ADD_BUSINESS_UNIT";
export const UPDATE_BUSINESS_UNIT = "UPDATE_BUSINESS_UNIT";
export const DELETE_BUSINESS_UNIT = "DELETE_BUSINESS_UNIT";

//PROJECT CLIENT
export const GET_PROJECT_CLIENTS = "GET_PROJECT_CLIENTS";
export const GET_PROJECT_CLIENT = "GET_PROJECT_CLIENT";
export const GET_PROJECT_CLIENT_VALUES = "GET_PROJECT_CLIENT_VALUES";
export const ADD_PROJECT_CLIENT = "ADD_PROJECT_CLIENT";
export const UPDATE_PROJECT_CLIENT = "UPDATE_PROJECT_CLIENT";
export const DELETE_PROJECT_CLIENT = "DELETE_PROJECT_CLIENT";

//PROJECT
export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECT = "GET_PROJECT";
export const GET_PROJECT_VALUES = "GET_PROJECT_VALUES";
export const ADD_PROJECT = "ADD_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";

//SITE
export const GET_SITES = "GET_SITES";
export const GET_SITE_VALUES = "GET_SITE_VALUES";
export const GET_SITE = "GET_SITE";
export const ADD_SITE = "ADD_SITE";
export const UPDATE_SITE = "UPDATE_SITE";
export const DELETE_SITE = "DELETE_SITE";

//NON CONFORMITY
export const GET_NONCONFORMITIES = "GET_NONCONFORMITIES";
export const GET_USER_NONCONFORMITIES = "GET_USER_NONCONFORMITIES";
export const GET_SINGLE_NONCONFORMITY = "GET_SINGLE_NONCONFORMITY";
export const ADD_NONCONFORMITY = "ADD_NONCONFORMITY";
export const UPDATE_NONCONFORMITY = "UPDATE_NONCONFORMITY";
export const DELETE_NONCONFORMITY = "DELETE_NONCONFORMITY";
export const SUBMIT_NONCONFORMITY = "SUBMIT_NONCONFORMITY";
export const SUBMIT_ACTION_FOR_NONCONFORMITY ="SUBMIT_ACTION_FOR_NONCONFORMITY";


//IMPROVEMENT OPPOURTIONITY
export const GET_IMPROVEMENT_OPPORTUNITIES = "GET_IMPROVEMENT_OPPORTUNITIES";
export const ADD_IMPROVEMENT_OPPORTUNITY = "ADD_IMPROVEMENT_OPPORTUNITY";
export const GET_USER_IMPROVEMENT_OPPORTUNITY =
  "GET_USER_IMPROVEMENT_OPPORTUNITY";
export const GET_SINGLE_USER_IMPROVEMENT_OPPORTUNITY =
  "GET_SINGLE_USER_IMPROVEMENT_OPPORTUNITY";
export const UPDATE_IMPROVEMENT_OPPORTUNITY = "UPDATE_IMPROVEMENT_OPPORTUNITY";
export const UPDATE_IMPROVEMENT_OPPORTUNITY_ACTION =
  "UPDATE_IMPROVEMENT_OPPORTUNITY_ACTION";
export const DELETE_IMPROVEMENT_OPPORTUNITY = "DELETE_IMPROVEMENT_OPPORTUNITY";
export const GET_IMPROVEMENT_OPPORTUNITY_FORM_VALUES =
  "GET_IMPROVEMENT_OPPORTUNITY_FORM_VALUES";
export const GET_IMPROVEMENT_OPPORTUNITY_VALUES =
  "GET_IMPROVEMENT_OPPORTUNITY_VALUES";
export const GENERATE_IMPROVEMENT_OPPORTUNITY =
  "GENERATE_IMPROVEMENT_OPPORTUNITY";

//COMPLAINT
export const GET_COMPLAINT_VALUES = "GET_COMPLAINT_VALUES";
export const GET_USER_COMPLAINT = "GET_USER_COMPLAINT";
export const GET_SINGLE_USER_COMPLAINT = "GET_SINGLE_USER_COMPLAINT";
export const ADD_COMPLAINT = "ADD_COMPLAINT";
export const UPDATE_COMPLAINT = "UPDATE_COMPLAINT";
export const GENERATE_COMPLAINT = "GENERATE_COMPLAINT";
export const UPDATE_COMPLAINT_ACTION = "UPDATE_COMPLAINT_ACTION";
export const DELETE_COMPLATIN_ACTION="DELETE_COMPLAINT_ACTION"

//MASTER
export const GET_MASTERS_TABLES = "GET_MASTER_TABLES";
export const ADD_MASTER = "ADD_MASTER";
export const UPDATE_MASTER = "UPDATE_MASTER";
export const DELETE_MASTER = "DELETE_MASTER";
export const GET_MASTER = "GET_MASTER";
export const GET_TABLE_VALUES = "GET_TABLE_VALUES";

//GO SEE PROGRAM
export const GO_SEE_PROGRAM_VALUES = "GO_SEE_PROGRAM_VALUES";
export const GET_SINGLE_GO_SEE_PROGRAM = "GET_SINGLE_GO_SEE_PROGRAM";
export const ADD_GO_SEE_PROGRAM = "ADD_GO_SEE_PROGRAM";
export const GET_USER_SEE_PROGRAM = "GET_USER_SEE_PROGRAM";
export const UPDATE_GO_SEE_PROGRAM = "UPDATE_GO_SEE_PROGRAM";
export const GENERATE_GO_SEE_PROGRAM = "GENERATE_GO_SEE_PROGRAM";
export const DELETE_GO_SEE_PROGRAM="DELETE_GO_SEE_PROGRAM"

//ToolBox Talk
export const TBT_VALUES = "TBT_VALUES";
export const GET_SINGLE_TBT = "GET_SINGLE_TBT";
export const ADD_TBT = "ADD_TBT";
export const GET_USER_TBT = "GET_USER_TBT";
export const UPDATE_TBT = "UPDATE_TBT";
export const GENERATE_TBT = "GENERATE_TBT";
export const DELETE_TBT="DELETE_TBT"

//ToolBox Not Tick
export const TNT_VALUES = "TNT_VALUES";
export const GET_SINGLE_TNT = "GET_SINGLE_TNT";
export const ADD_TNT = "ADD_TNT";
export const GET_USER_TNT = "GET_USER_TNT";
export const UPDATE_TNT = "UPDATE_TNT";
export const GENERATE_TNT = "GENERATE_TNT";

//L2 Weekly
export const L2PM_VALUES = "L2PM_VALUES";
export const GET_SINGLE_L2PM = "GET_SINGLE_L2PM";
export const ADD_L2PM = "ADD_L2PM";
export const GET_USER_L2PM = "GET_USER_L2PM";
export const UPDATE_L2PM = "UPDATE_L2PM";
export const GENERATE_L2PM = "GENERATE_L2PM";
export const DELETE_L2PM="DELETE_L2PM"

//Weekly Project Report HSSE
export const RHPW_VALUES = "RHPW_VALUES";
export const GET_SINGLE_RHPW = "GET_SINGLE_RHPW";
export const ADD_RHPW = "ADD_RHPW";
export const GET_USER_RHPW = "GET_USER_RHPW";
export const UPDATE_RHPW = "UPDATE_RHPW";
export const GENERATE_RHPW = "GENERATE_RHPW";
export const DELETE_RHPW="DELETE_RHPW"

//HSSEAudit
export const FAUDIT_VALUES = "FAUDIT_VALUES";
export const GET_SINGLE_FAUDIT = "GET_SINGLE_FAUDIT";
export const ADD_FAUDIT = "ADD_FAUDIT";
export const GET_USER_FAUDIT = "GET_USER_FAUDIT";
export const UPDATE_FAUDIT = "UPDATE_FAUDIT";
export const GENERATE_FAUDIT = "GENERATE_FAUDIT";
export const DELETE_FAUDIT="DELETE_FAUDIT"

//SITE
export const SSUC_VALUES = "SSUC_VALUES";
export const GET_SINGLE_SSUC = "GET_SINGLE_SSUC";
export const ADD_SSUC = "ADD_SSUC";
export const GET_USER_SSUC = "GET_USER_SSUC";
export const UPDATE_SSUC = "UPDATE_SSUC";
export const GENERATE_SSUC = "GENERATE_SSUC";

//LDO1
export const LDO1_VALUES = "LDO1_VALUES";
export const GET_SINGLE_LDO1 = "GET_SINGLE_LDO1";
export const ADD_LDO1 = "ADD_LDO1";
export const GET_SINGLE_LD01 = "GET_SINGLE_LDO1";
export const UPDATE_LDO1 = "UPDATE_LDO1";
export const GENERATE_LDO1 = "GENERATE_LDO1";
export const GET_USER_LDO1 = "GET_USER_LDO1";
export const DELETE_LDO1="DELETE_LDO1"

//INCIDENT ACTIONS
export const GET_ACTIONS = "GET_ACTIONS";
export const GET_ACTION_COUNTS = "GET_ACTION_COUNTS";
export const GET_ACTION_FILTER = "GET_ACTION_FILTER";

//INFORM ACTIONS
export const GET_INFORM_ACTIONS = "GET_INFORM_ACTIONS";
export const GET_INFORM_ACTION_COUNTS = "GET_INFORM_ACTION_COUNTS";
export const GET_INFORM_ACTION_FILTER = "GET_INFORM_ACTION_FILTER";

//COMPETANCY
//COURSE
export const GET_COURSES = "GET_COURSES";
export const GET_ENROLLED_COURSES = "GET_ENROLLED_COURSES";
export const GET_ASSIGNED_COURSES = "GET_ASSIGNED_COURSES";
export const ASSINGED_COURSE = "ASSIGNED_COURSE";
export const ADD_COURSE = "ADD_COURSE";
export const UPDATE_COURSE = "UPDATE_COURSE";
export const GET_COURSE = "GET_COURSE";
export const DELETE_COURSE = "DELETE_COURSE";
export const GET_COURSE_QUESTIONS = "GET_COURSE_QUESTIONS";
export const COURSES_ENROLLED_BY_USER = "COURSES_ENROLLED_BY_USER";
export const MASTER_COURSE_USERS = "MASTER_COURSE_USERS";

//CHAPTER
export const GET_CHAPTERS = "GET_CHAPTERS";
export const ADD_CHAPTER = "ADD_CHAPTER";
export const UPDATE_CHAPTER = "UPDATE_CHAPTER";
export const GET_CHAPTER = "GET_CHAPTER";
export const DELETE_CHAPTER = "DELETE_CHAPTER";

//TOPIC
export const GET_TOPICS = "GET_TOPICS";
export const GET_TOPIC = "GET_TOPIC";
export const ADD_TOPIC = "ADD_TOPIC";
export const UPDATE_TOPIC = "UPDATE_TOPIC";
export const DELETE_TOPIC = "DELETE_TOPIC";

//QUESTION
export const GET_QUESTIONS = "GET_QUESTIONS";
export const ADD_QUESTION = "ADD_QUESTION";
export const UPDATE_QUESTION = "UPDATE_QUESTION";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const GET_QUESTION = "GET_QUESTION";

//NEWS
export const GET_ALL_NEWS = "GET_ALL_NEWS";
export const GET_LATEST_NEWS = "GET_LATEST_NEWS";
export const GET_SINGLE_NEWS = "GET_SINGLE_NEWS";
export const GET_USER_NEWS = "GET_USER_NEWS";
export const ADD_NEWS = "ADD_NEWS";
export const UPDATE_NEWS = "UPDATE_NEWS";
export const DELETE_NEWS = "DELETE_NEWS";

//FILE CABINET
export const GET_PARENT_CABINET = "GET_PARENT_CABINET";
export const GET_CHILD_CABINET_BY_PARENT = "GET_CHILD_CABINET_BY_PARENT";
export const UPDATE_CABINET_HIERARCHY = "UPDATE_CABINET_HIERARCHY";

//PERMIT TO WORK
export const GET_PERMIT_WORK_VALUES = "GET_PERMIT_WORK_VALUES";
export const ADD_PERMIT_WORK = "ADD_PERMIT_WORK";
export const UPDATE_PERMIT_WORK = "UPDATE_PERMIT_WORK";
export const GET_PERMIT_WORKS = "GET_PERMIT_WORK";
export const GET_PERMIT_WORK = "GET_PERMIT_WORK";
export const GET_USER_PERMIT_WORK = "GET_USER_PERMIT_WORK";
export const GENERATE_PERMIT_TO_WORK = "GENERATE_PERMIT_TO_WORK";
export const SUBMIT_REVIEW_COMMENT = "SUBMIT_REVIEW_COMMENT";
export const SUBMIT_APPROVE_REJECT = "SUBMIT_APPROVE_REJECT";
export const START_PERMIT_TO_WORK = "START_PERMIT_TO_WORK";
export const FINISH_PERMIT_TO_WORK = "FINISH_PERMIT_TO_WORK";
export const DELETE_PERMIT_TO_WORK="DELETE_PERMIT_TO_WORK"
export const GET_ISSUER_PERMITS="GET_ISSUER_PERMITS"
export const GET_REVIEWER_PERMITS="GET_REVIEWER_PERMITS"

//TEMPLATE
export const GET_TEMPLATES="GET_TEMPLATES"
export const GET_TEMPLATE_VALUES="GET_TEMPLATE_VALUES"
export const GET_TEMPLATE="GET_TEMPLATE"
export const ADD_TEMPLATE="ADD_TEMPLATE"
export const UPDATE_TEMPLATE="UPDATE_TEMPLATE"
export const DELETE_TEMPLATE="DELETE_TEMPLATE"

//RISK
export const GET_RISKS="GET_RISKS"
export const GET_RISK_VALUES="GET_RISK_VALUES"
export const GET_RISK="GET_RISK"
export const ADD_RISK="ADD_RISK"
export const UPDATE_RISK="UPDATE_RISK"
export const DELETE_RISK="DELETE_RISK"



//INFORM REPORT
export const INFORM_FILTER_VALUES = "INFORM_FILTER_VALUES"
export const INFORM_FILTER="INFORM_FILTER"

//COMPETENCY REPORT FILTER
export const COMPETENCY_FILTER_VALUES="COMPETENCY_FILTER_VALUES"
export const COMPETENCY_FILTER="COMPETENCY_FILTER"

//PERMIT TO WORK REPORT FILTER
export const PERMIT_FILTER_VALUES="PERMIT_FILTER_VALUES"
export const PERMIT_FILTER="PERMIT_FILTER"


//INCIDENT REPORT
export const INCIDENT_CATEGORY_VALUES="INCIDENT_CATEGORY_VALUES"
export const INCIDENT_FILTER_CATEGORY="INCIDENT_FILTER_CATEGORY"


//INCIDENT FILTER REPORT
export const INCIDENT_FILTER_VALUES = "INCIDENT_FILTER_VALUES"
export const INCIDENT_FILTER_FILTER="INCIDENT_FILTER_FILTER"


//CONTRIBUTING FACTOR REPORT
export const CFR_VALUES="CFR_VALUES"
export const CFR_FILTER="CFR_FILTER"

//CONTRACTOR INVOLVMENT REPORT
export const CIR_VALUES="CIR_VALUES"
export const CIR_FILTER="CIL_FILTER"


//ISSUE SUMMARY
export const IS_VALUES="IS_VALUES"
export const IS_FILTER="IS_FILTER"

//ISSUE OVERVIEW
export const IO_VALUES="IO_VALUES"
export const IO_FILTER="IO_FILTER"

//PLANT DAMAGE REPORT
export const PDR_VALUES="PDR_VALUES"
export const PDR_FILTER="PDR_FILTER"

//COST SUMMARY REPORT
export const CSR_VALUES="CSR_VALUES"
export const CSR_FILTERS="CSR_FILTERS"


//INCIDENT ENVIRONMENT REPORT
export const IER_VALUES="IER_VALUES"
export const IER_FILTER="IER_FILTERS"

//ISSUE ACTION REPORT
export const IAC_VALUES="IAC_VALUES"
export const IAC_FILTERS="IAC_FILTERS"


//GLOBAL MODULES && GLOBAL LINKS
export const GET_GLOBAL_MODULES="GET_GLOBAL_MODULES"
export const GET_GLOBAL_LINKS="GET_GLOBAL_LINKS"

//PROJECT MONITORING
export const GET_QCVALUES="GET_QCVALUES"
export const GET_QCFORMVALUES="GET_FORMVALUES"
export const SAVE_QC="SAVE_QC"
export const UPDATE_QC="UPDATE_QC"
export const GET_QC_DATA="GET_QC_DATA"


//USER DASHBOARD
export const GET_USER_DASHBOARDS="GET_USER_DASHBOARDS"
export const ADD_USER_DASHBOARD="ADD_USER_DASHBOARD"
export const UPDATE_USER_DASHBOARD="UPDATE_USER_DASHBOARD"
export const DELETE_USER_DASHBOARD="DELETE_USER_DASHBOARD"
export const GET_DASHBOARD_VALUES="GET_DASHBOARD_VALUES"


//NCR
export const GENERATE_NCR="GENERATE_NCR"
export const NCR_VALUES="NCR_VALUES"
export const GET_USER_NCR="GET_USER_NCR"
export const ADD_NCR="ADD_NCR"
export const UPDATE_NCR="UPDATE_NCR"
export const DELETE_NCR="DELETE_NCR"
export const GET_SINGLE_NCR="GET_SINGLE_NCR"
export const GET_NCRS="GET_NCRS"


//INCR
export const GENERATE_INCR = "GENERATE_INCR";
export const INCR_VALUES = "INCR_VALUES";
export const GET_USER_INCR = "GET_USER_INCR";
export const ADD_INCR = "ADD_INCR";
export const UPDATE_INCR = "UPDATE_INCR";
export const DELETE_INCR = "DELETE_INCR";
export const GET_SINGLE_INCR = "GET_SINGLE_INCR";
export const GET_INCRS = "GET_INCRS";
