import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import Select from "react-select";
import { getIssueOverviewValues, getIssueSummaryFilter } from "../../../../store/actions/Incident/IncidentReport/incidentReportCategory";
import { FilterOutlined } from "@ant-design/icons";
import ReactStrapDataTable from "../../../CustomDatatable/ReactStrapDataTable";
import { stringSorter, formatDateWithTime, numberSort, dateTimeSort } from '../../../../utils/functions';




const IssueOverview = () => {
    const dispatch = useDispatch();
    const ioValues = useSelector((state) => state.incidentReportCategory.ioValues);
    const ioFilters = useSelector((state) => state.incidentReportCategory.ioFilters)

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState({
        status: [],
        excelTypes: null,
        contractors: [],
        countries: [],
        states: [],
        businessUnits: [],
        projectClients: [],
        projects: [],
        sites: [],
        l3: [],
        issueTypes: [],
        propertyFilters: [],
        nWIncidentTypes: [],
        ceIncidentTypes: [],
        injurySeverity: [],
        actual: [],
        potential: [],
        enteredBys: [],
        identifiers: [],
        responsible: [],
        closeout: [],
        plantOperators: [],
        injuredEmployees: [],
        involvedUsers: []

    });

    const { control, handleSubmit, reset, watch } = useForm();
    const dateFrom = watch("dateFrom");

    useEffect(() => {
        dispatch(getIssueOverviewValues());
    }, [dispatch]);

    useEffect(() => {
        // console.log("IO VALUES")
        // console.log(ioValues)
    }, [ioValues]);



    let columns = [
        {
            title: "Type",
            dataIndex: "issueType",
            key: "issueType",
            sorter: stringSorter("issueType"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Issue At",
            dataIndex: "detectedAt",
            key: "detectedAt",
            sorter: dateTimeSort("detectedAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Contractor",
            dataIndex: "l3Contractor",
            key: "l3Contractor",
            sorter: stringSorter("l3Contractor"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
            sorter: stringSorter("country"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Business Unit",
            dataIndex: "businessUnit",
            key: "businessUnit",
            sorter: stringSorter("businessUnit"),
            sortDirections: ["ascend", "descend"],
        },

        {
            title: "Incident",
            dataIndex: "incidentCategories",
            key: "incidentCategories",
            sorter: stringSorter("incidentCategories"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            sorter: stringSorter("description"),
            ellipsis: true,
        },
        {
            title: "Open Actions",
            dataIndex: "openActions",
            key: "openActions",
            sorter: numberSort("openActions"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Total Actions",
            dataIndex: "totalActions",
            key: "totalActions",
            sorter: numberSort("totalActions"),
            sortDirections: ["ascend", "descend"],
        },
    ];

    const handleCloseModal = () => {
        setModalOpen(false);
        reset();
    };

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleResetFilters = () => {
        reset();
        setSelectedItems({
            status: [],
            excelTypes: null,
            contractors: [],
            countries: [],
            states: [],
            businessUnits: [],
            projectClients: [],
            projects: [],
            sites: [],
            l3: [],
            issueTypes: [],
            propertyFilters: [],
            nWIncidentTypes: [],
            ceIncidentTypes: [],
            injurySeverity: [],
            actual: [],
            potential: [],
            enteredBys: [],
            identifiers: [],
            responsible: [],
            closeout: [],
            plantOperators: [],
            injuredEmployees: [],
            involvedUsers: []
        });
    };

    const isFieldEnabled = (field) => {
        switch (field) {
            case 'businessUnits':
                return selectedItems.countries.length > 0;
            case 'projectClients':
                return selectedItems.businessUnits.length > 0;
            case 'projects':
                return selectedItems.projectClients.length > 0;
            case 'sites':
                return selectedItems.projects.length > 0;
            case 'l3':
                return selectedItems.sites.length > 0;
            default:
                return true;
        }
    };

    const handleItemSelect = (field, selectedOptions) => {
        setSelectedItems((prevItems) => {
            const newItems = {
                ...prevItems,
                [field]: selectedOptions,
            };

            // Clear child selections based on parent-child relationships
            switch (field) {
                case 'countries':
                    if (selectedOptions.length === 0) {
                        newItems.businessUnits = [];
                        newItems.projectClients = [];
                        newItems.projects = [];
                        newItems.sites = [];
                        newItems.l3 = [];
                    }
                    break;
                case 'businessUnits':
                    if (selectedOptions.length === 0) {
                        newItems.projectClients = [];
                        newItems.projects = [];
                        newItems.sites = [];
                        newItems.l3 = [];
                    }
                    break;
                case 'projectClients':
                    if (selectedOptions.length === 0) {
                        newItems.projects = [];
                        newItems.sites = [];
                        newItems.l3 = [];
                    }
                    break;
                case 'projects':
                    if (selectedOptions.length === 0) {
                        newItems.sites = [];
                        newItems.l3 = [];
                    }
                    break;
                case 'sites':
                    if (selectedOptions.length === 0) {
                        newItems.l3 = [];
                    }
                    break;
            }

            return newItems;
        });
    };

    const getFilteredStates = () => {
        if (selectedItems.countries.length === 0) {
            return ioValues.states || [];
        }

        return (ioValues.states || []).filter((state) =>
            selectedItems.countries.some((country) => state.coId === country.value)
        );
    };

    const getFilteredBusinessUnits = () => {
        if (selectedItems.countries.length === 0) {
            return ioValues.businessUnits || [];
        }

        return (ioValues.businessUnits || []).filter((bu) =>
            selectedItems.countries.some((country) => bu.coId === country.value)
        );
    };

    const getFilteredProjectClients = () => {
        if (selectedItems.countries.length === 0) {
            return ioValues.projectClients || [];
        }

        return (ioValues.projectClients || []).filter((pc) =>
            pc.coId.some((coId) =>
                selectedItems.countries.some((country) => coId === country.value)
            )
        );
    };

    const getFilteredProjects = () => {
        if (selectedItems.businessUnits.length === 0 && selectedItems.projectClients.length === 0) {
            return ioValues.projects || [];
        }

        return (ioValues.projects || []).filter((project) => {
            const matchesBusinessUnit = selectedItems.businessUnits.length === 0 ||
                selectedItems.businessUnits.some((bu) => bu.value === project.buId);

            const matchesProjectClient = selectedItems.projectClients.length === 0 ||
                selectedItems.projectClients.some((pc) => pc.value === project.pcId);

            return matchesBusinessUnit && matchesProjectClient;
        });
    };

    const getFilteredSites = () => {
        if (selectedItems.projectClients.length === 0) {
            return ioValues.sites || [];
        }

        const relevantProjectIds = (ioValues.projects || [])
            .filter((project) =>
                selectedItems.projectClients.some((pc) => project.pcId === pc.value)
            )
            .map((project) => project.id);

        return (ioValues.sites || []).filter((site) => relevantProjectIds.includes(site.prId));
    };

    const onSubmit = (data) => {
        const payload = {
            issueStartDate: formatDateWithTime(data.dateFrom, false),
            issueEndDate: formatDateWithTime(data.dateTo, false),
            status: JSON.stringify(selectedItems.status.map(item => item.value)),
            excelTypes: selectedItems.excelTypes ? selectedItems.excelTypes.value : null,
            countries: JSON.stringify(selectedItems.countries.map(item => item.value)),
            states: JSON.stringify(selectedItems.states.map(item => item.value)),
            businessUnits: JSON.stringify(selectedItems.businessUnits.map(item => item.value)),
            projectClients: JSON.stringify(selectedItems.projectClients.map(item => item.value)),
            projects: JSON.stringify(selectedItems.projects.map(item => item.value)),
            sites: JSON.stringify(selectedItems.sites.map(item => parseInt(item.value))),
            l3s: JSON.stringify(selectedItems.l3.map(item => item.value)),
            issueTypes: JSON.stringify(selectedItems.issueTypes.map(item => item.value)),
            propertyFilters: JSON.stringify(selectedItems.propertyFilters.map(item => item.value)),
            nWIncidentTypes: JSON.stringify(selectedItems.nWIncidentTypes.map(item => item.value)),
            injurySeverity: JSON.stringify(selectedItems.injurySeverity.map(item => item.value)),
            potential: JSON.stringify(selectedItems.potential.map(item => item.value)),
            actual: JSON.stringify(selectedItems.actual.map(item => item.value)),
            enteredBys: JSON.stringify(selectedItems.enteredBys.map(item => item.value)),
            identifiers: JSON.stringify(selectedItems.identifiers.map(item => item.value)),
            responsible: JSON.stringify(selectedItems.responsible.map(item => item.value)),
            closeout: JSON.stringify(selectedItems.closeout.map(item => item.value)),
            plantOperators: JSON.stringify(selectedItems.plantOperators.map(item => item.value)),
            injuredEmployees: JSON.stringify(selectedItems.injuredEmployees.map(item => item.value)),
            involvedUsers: JSON.stringify(selectedItems.involvedUsers.map(item => item.value))
        };
    
        // TODO: Dispatch action to update issue overview filter
        // dispatch(getIssueOverviewFilter(JSON.stringify(payload)));
    
        setModalOpen(false);
    };

    const now = new Date();
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    const formatDate = (date) => date.toISOString().slice(0, 10);


    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">Issue OverView</h5>
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" onClick={handleOpenModal}>
                                <FilterOutlined />
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactStrapDataTable
                        dataSource={ioFilters ?? []}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} size="xl" backdrop="static">
                <ModalHeader toggle={handleCloseModal}>
                    Filter Issue OverView Report
                </ModalHeader>
                <ModalBody>
                    <div className="d-flex justify-content-end mb-3">
                        <Button color="secondary" onClick={handleResetFilters}>
                            Reset Filters
                        </Button>
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <hr />
                            <h5>General</h5>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="dateFrom">Date From</Label>
                                    <Controller
                                        name="dateFrom"
                                        control={control}
                                        rules={{ required: "Date From is required" }}
                                        defaultValue={formatDate(firstDayOfMonth)} x
                                        render={({ field }) => (
                                            <Input {...field} type="date" id="dateFrom" />
                                        )}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="dateTo">Date To</Label>
                                    <Controller
                                        name="dateTo"
                                        control={control}
                                        rules={{
                                            required: "Date To is required",
                                            validate: (value) =>
                                                new Date(value) > new Date(dateFrom) ||
                                                "Date To must be greater than Date From",
                                        }}
                                        defaultValue={formatDate(lastDayOfMonth)}
                                        render={({ field }) => (
                                            <Input {...field} type="date" id="dateTo" />
                                        )}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label>Status</Label>
                                    <Select
                                        options={(ioValues.statuses || []).map((status) => ({
                                            value: status,
                                            label: status,
                                        }))}
                                        value={selectedItems.issueTypes}
                                        onChange={(options) => handleItemSelect("status", options)}
                                        placeholder="Select Status..."
                                        isMulti
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label>Create Excel As</Label>
                                    <Select
                                        options={(ioValues.excelTypes || []).map((excel) => ({
                                            value: excel,
                                            label: excel,
                                        }))}
                                        value={selectedItems.excelTypes}
                                        onChange={(options) => handleItemSelect("excelTypes", options)}
                                        placeholder="Select Excel Types."

                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr />
                        <h5>Location</h5>
                        <hr />
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Countries</Label>
                                    <Select
                                        options={(ioValues.countries || []).map((country) => ({
                                            value: country.id,
                                            label: country.name,
                                        }))}
                                        value={selectedItems.countries}
                                        onChange={(options) => handleItemSelect("countries", options)}
                                        placeholder="Select countries..."
                                        isMulti
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>State</Label>
                                    <Select
                                        options={getFilteredStates().map((state) => ({
                                            value: state.id,
                                            label: state.name,
                                        }))}
                                        value={selectedItems.states}
                                        onChange={(options) => handleItemSelect("states", options)}
                                        placeholder="Select States..."
                                        isMulti
                                        isDisabled={selectedItems.countries.length === 0}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Business Units</Label>
                                    <Select
                                        options={getFilteredBusinessUnits().map((bu) => ({
                                            value: bu.id,
                                            label: bu.name,
                                        }))}
                                        value={selectedItems.businessUnits}
                                        onChange={(options) => handleItemSelect("businessUnits", options)}
                                        placeholder="Select business units..."
                                        isMulti
                                        isDisabled={!isFieldEnabled('businessUnits')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Project Clients</Label>
                                    <Select
                                        options={getFilteredProjectClients().map((pc) => ({
                                            value: pc.id,
                                            label: pc.name,
                                        }))}
                                        value={selectedItems.projectClients}
                                        onChange={(options) => handleItemSelect("projectClients", options)}
                                        placeholder="Select project clients..."
                                        isMulti
                                        isDisabled={!isFieldEnabled('projectClients')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Projects</Label>
                                    <Select
                                        options={getFilteredProjects().map((project) => ({
                                            value: project.id,
                                            label: project.name,
                                        }))}
                                        value={selectedItems.projects}
                                        onChange={(options) => handleItemSelect("projects", options)}
                                        placeholder="Select projects..."
                                        isMulti
                                        isDisabled={!isFieldEnabled('projects')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Sites</Label>
                                    <Select
                                        options={getFilteredSites().map((site) => ({
                                            value: site.id,
                                            label: site.name,
                                        }))}
                                        value={selectedItems.sites}
                                        onChange={(options) => handleItemSelect("sites", options)}
                                        placeholder="Select sites..."
                                        isMulti
                                        isDisabled={!isFieldEnabled('sites')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>L3</Label>
                                    <Select
                                        options={(ioValues.l3 || []).map((item) => ({
                                            value: item.id,
                                            label: item.name,
                                        }))}
                                        value={selectedItems.l3}
                                        onChange={(options) => handleItemSelect("l3", options)}
                                        placeholder="Select L3..."
                                        isMulti
                                        isDisabled={!isFieldEnabled('l3')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <h5>Issue Details</h5>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Issue Type</Label>
                                    <Select
                                        options={(ioValues.issueTypes || []).map((issue) => ({
                                            value: issue,
                                            label: issue,
                                        }))}
                                        value={selectedItems.issueTypes}
                                        onChange={(options) => handleItemSelect("issueTypes", options)}
                                        placeholder="Select Issue Types..."
                                        isMulti
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Property Filters</Label>
                                    <Select
                                        options={(ioValues.propertyFilters || []).map((property) => ({
                                            value: property,
                                            label: property,
                                        }))}
                                        value={selectedItems.propertyFilters}
                                        onChange={(options) => handleItemSelect("propertyFilters", options)}
                                        placeholder="Select Property Filter..."
                                        isMulti
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Non-Work Incident Type</Label>
                                    <Select
                                        options={(ioValues.nwIncidentTypes || []).map((incident) => ({
                                            value: incident,
                                            label: incident,
                                        }))}
                                        value={selectedItems.nwIncidentTypes}
                                        onChange={(options) => handleItemSelect("nWIncidentTypes", options)}
                                        placeholder="Select Non Work Incident..."

                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Injury Severity</Label>
                                    <Select
                                        options={(ioValues.injurySeverity || []).map((severity) => ({
                                            value: severity,
                                            label: severity,
                                        }))}
                                        value={selectedItems.injurySeverity}
                                        onChange={(options) => handleItemSelect("injurySeverity", options)}
                                        placeholder="Select Injury Severity"

                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Potential Category</Label>
                                    <Select
                                        options={(ioValues.apCategories || []).map((category) => ({
                                            value: category,
                                            label: category,
                                        }))}
                                        value={selectedItems.potential}
                                        onChange={(options) => handleItemSelect("potential", options)}
                                        placeholder="Select Potential Categories"

                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Actual Category</Label>
                                    <Select
                                        options={(ioValues.apCategories || []).map((category) => ({
                                            value: category,
                                            label: category,
                                        }))}
                                        value={selectedItems.actual}
                                        onChange={(options) => handleItemSelect("actual", options)}
                                        placeholder="Select Actual Categories"

                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr />
                        <h5>People Involved</h5>
                        <hr />
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Entered By</Label>
                                    <Select
                                        options={(ioValues.enteredBys || []).map((person) => ({
                                            value: person.id,
                                            label: person.name,
                                        }))}
                                        value={selectedItems.enteredBys}
                                        onChange={(options) => handleItemSelect("enteredBys", options)}
                                        placeholder="Select Entered Bys..."
                                        isMulti
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Identified By</Label>
                                    <Select
                                        options={(ioValues.identifiers || []).map((person) => ({
                                            value: person.id,
                                            label: person.name,
                                        }))}
                                        value={selectedItems.identifiers}
                                        onChange={(options) => handleItemSelect("identifiers", options)}
                                        placeholder="Select Identified By"
                                        isMulti
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Responsible Manager</Label>
                                    <Select
                                        options={(ioValues.responsible || []).map((person) => ({
                                            value: person.id,
                                            label: person.name,
                                        }))}
                                        value={selectedItems.responsible}
                                        onChange={(options) => handleItemSelect("responsible", options)}
                                        placeholder="Select Responsible Manger..."
                                        isMulti
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Closeout Manager</Label>
                                    <Select
                                        options={(ioValues.closeout || []).map((person) => ({
                                            value: person.id,
                                            label: person.name,
                                        }))}
                                        value={selectedItems.closeout}
                                        onChange={(options) => handleItemSelect("closeout", options)}
                                        placeholder="Select Closeout Manager..."
                                        isMulti
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Plant Operator</Label>
                                    <Select
                                        options={(ioValues.plantOperators || []).map((person) => ({
                                            value: person.id,
                                            label: person.name,
                                        }))}
                                        value={selectedItems.plantOperators}
                                        onChange={(options) => handleItemSelect("plantOperators", options)}
                                        placeholder="Select Plant Operator ..."
                                        isMulti
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Injured Employee</Label>
                                    <Select
                                        options={(ioValues.injuredEmployees || []).map((person) => ({
                                            value: person.id,
                                            label: person.name,
                                        }))}
                                        value={selectedItems.injuredEmployees}
                                        onChange={(options) => handleItemSelect("injuredEmployees", options)}
                                        placeholder="Select Injured Employees ..."
                                        isMulti
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Person Involved</Label>
                                    <Select 

                                         options={(ioValues.involvedUsers || []).map((person) => ({
                                            value: person.id,
                                            label: person.name,
                                        }))}
                                        value={selectedItems.involvedUsers}
                                        onChange={(options) => handleItemSelect("involvedUsers", options)}
                                        placeholder="Select Involved Users..."
                                        isMulti
                                        
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit(onSubmit)}>
                        Apply Filters
                    </Button>
                    <Button color="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default IssueOverview;