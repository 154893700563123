import axios from "../../../axios";
import {
  GET_PERMIT_WORK_VALUES,
  ADD_PERMIT_WORK,
  GET_USER_PERMIT_WORK,
  UPDATE_PERMIT_WORK,
  GET_PERMIT_WORK,
  GENERATE_PERMIT_TO_WORK,
  SUBMIT_REVIEW_COMMENT,
  SUBMIT_APPROVE_REJECT,
  START_PERMIT_TO_WORK,
  FINISH_PERMIT_TO_WORK,
  DELETE_PERMIT_TO_WORK,
  GET_ISSUER_PERMITS,
  GET_REVIEWER_PERMITS
} from "../../types";
import { setAlert } from "../alert";
import { message } from "antd";

export const getPermitToWorkValues = () => async (dispatch) => {
  try {
    const res = await axios.get("ptw/values");
    if (res.data.code === 1) {
      dispatch({ type: GET_PERMIT_WORK_VALUES, payload: res.data.data });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const getSinglePermit = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/ptw/${id}`);
    if (res.data.code === 1) {
      dispatch({ type: GET_PERMIT_WORK, payload: res.data.data });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const getUserPermits = () => async (dispatch) => {
  try {
    const res = await axios.get("ptw/user");
    if (res.data.code === 1) {
      dispatch({ type: GET_USER_PERMIT_WORK, payload: res.data.data });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};


export const getIssuerPermits = () => async (dispatch) => {
  try {
    const res = await axios.get("ptw/user/issue");
    
    if (res.data.code === 1) {
      dispatch({ type: GET_ISSUER_PERMITS, payload: res.data.data });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const getReviewerPermits = () => async (dispatch) => {
  try {
    const res = await axios.get("ptw/user/review");
    
    if (res.data.code === 1) {
      dispatch({ type: GET_REVIEWER_PERMITS, payload: res.data.data });
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const addPermit = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const res = await axios.post("/ptw/add", formData, config);

    if (res.data.code === 1) {
      dispatch({ type: ADD_PERMIT_WORK, payload: res.data.data });
      message.success(res.data.message);
      return true;
    } else {
      message.warning(res.data.message);
      return false;
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const deletePermitToWork = (id) => async (dispatch) => {
  try {
      const res = await axios.get(`/ptw/delete/${id}`);
      
      if (res.data.code === 1) {
          
          dispatch({type: DELETE_PERMIT_TO_WORK, payload: id});
      }
      return res
  } catch (err) {
      dispatch(setAlert(err.message, "danger"));
  }
};
export const submitReviewComment = (formData) => async (dispatch) => {
  try {
    const res = await axios.post("/ptw/review", formData);
    if (res.data.code === 1) {
      dispatch({ type: SUBMIT_REVIEW_COMMENT, payload: res.data.data });
      message.success(res.data.message);
    } else {
      message.warning(res.data.message);
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};
export const submitApproveAndReject = (formData) => async (dispatch) => {
  try {
    const res = await axios.post("/ptw/approval", formData);
    if (res.data.code === 1) {
      dispatch({ type: SUBMIT_APPROVE_REJECT, payload: res.data.data });
      message.success(res.data.message);
    } else {
      message.warning(res.data.message);
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const updatePermit = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const res = await axios.post("/ptw/update", formData, config);
    if (res.data.code === 1) {
      dispatch({ type: UPDATE_PERMIT_WORK, payload: res.data.data });
      message.success(res.data.message);
      return true;
    } else {
      message.warning(res.data.message);
      return false;
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const submitPermitToWork = (object) => async (dispatch) => {
  try {
    const res = await axios.post(`ptw/submit/`, object);
    if (res.data.code === 1) {
      dispatch({ type: GENERATE_PERMIT_TO_WORK, payload: res.data.data });
    }
    return res;
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};

export const startPermitToWork = (object) => async (dispatch) => {
  try {
    const res = await axios.post(`ptw/start/`,object);
    
    if (res.data.code === 1) {
      dispatch({ type: START_PERMIT_TO_WORK, payload: res.data.data });
    }
    return res;
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};
export const finishPermitToWork = (object) => async (dispatch) => {
  try {
    
    const res = await axios.post(`ptw/finish/`,object);
    if (res.data.code === 1) {
      dispatch({ type: FINISH_PERMIT_TO_WORK, payload: res.data.data });
    }
    return res;
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
  }
};
