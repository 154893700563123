import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Container,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import { getUserMenu, getUsers } from "../../../../store/actions/user";
import { savePermission } from "../../../../store/actions/permission";

const PermissionAccess = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.list) || [];
  const userMenu = useSelector((state) => state.users.permissions.menu) || [];
  const countries =
    useSelector((state) => state.users.permissions.countries) || [];
  const businessUnits =
    useSelector((state) => state.users.permissions.businessUnits) || [];
  const projectClients =
    useSelector((state) => state.users.permissions.projectClients) || [];
  const projects =
    useSelector((state) => state.users.permissions.projects) || [];
  const sites = useSelector((state) => state.users.permissions.sites) || [];
  const l3 = useSelector((state) => state.users.permissions.l3) || [];
  const selectedPermissions =
    useSelector((state) => state.users.permissions.selected) || {};
  const [selectedUser, setSelectedUser] = useState(null);
  const [activeTab, setActiveTab] = useState("");
  const [selectedItems, setSelectedItems] = useState({});
  const [selectedRoles, setSelectedRoles] = useState({});
  const [isLoading, setIsLoading] = useState(false);


  const countryLookup = useMemo(() => {
    return countries.reduce((acc, country) => {
      acc[country.id] = country.name;
      return acc;
    }, {});
  }, [countries]);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);



  useEffect(() => {
    if (userMenu.length > 0) {
      setActiveTab(userMenu[0].id.toString());
      const initialItems = {};
      const initialRoles = {};
      userMenu.forEach((menu) => {
        initialItems[menu.id] = {
          countries: [],
          businessUnits: [],
          projectClients: [],
          sites: [],
          l3: [],
        };
        initialRoles[menu.id] = [];
        console.log(initialRoles[menu.id]);
      });
      setSelectedItems(initialItems);
      setSelectedRoles(initialRoles);
      setIsLoading(false);
    }
  }, [userMenu]);

  useEffect(() => {
    if (selectedPermissions.roles && selectedPermissions.scopes) {
      const newSelectedItems = { ...selectedItems };
      const newSelectedRoles = { ...selectedRoles };

      console.log("SELECTED ITEMS")
      console.log(newSelectedItems)

      console.log("SELECTED ROLES")
      console.log(newSelectedRoles)

      selectedPermissions.roles.forEach((role) => {
        const menuId = role.menuId;
        if (!newSelectedRoles[menuId]) newSelectedRoles[menuId] = [];
        newSelectedRoles[menuId].push({
          value: role.permissionId,
          label: userMenu
            .find((menu) => menu.id === menuId)
            ?.permissions.find((perm) => perm.id === role.permissionId)?.name,
        });
      });

      selectedPermissions.scopes.forEach((scope) => {
        const menuId = scope.menuId;
        if (!newSelectedItems[menuId]) {
          newSelectedItems[menuId] = {
            countries: [],
            businessUnits: [],
            projectClients: [],
            projects: [], // Add projects array
            sites: [],
            l3: [],
          };
        }
        if (Array.isArray(scope.coId) && scope.coId.length > 0) {
          newSelectedItems[menuId].countries = scope.coId.map((id) => ({
            value: id,
            label: countryLookup[id],
          }));
        }
        if (Array.isArray(scope.buId) && scope.buId.length > 0) {
          newSelectedItems[menuId].businessUnits = scope.buId.map((id) => ({
            value: id,
            label: businessUnits.find((bu) => bu.id === id)?.name,
          }));
        }
        if (Array.isArray(scope.pcId) && scope.pcId.length > 0) {
          newSelectedItems[menuId].projectClients = scope.pcId.map((id) => ({
            value: id,
            label: projectClients.find((pc) => pc.id === id)?.name,
          }));
        }
        // Add projects
        if (Array.isArray(scope.prId) && scope.prId.length > 0) {
          newSelectedItems[menuId].projects = scope.prId.map((id) => ({
            value: id,
            label: projects.find((pr) => pr.id === id)?.name,
          }));
        }
        if (Array.isArray(scope.siteId) && scope.siteId.length > 0) {
          newSelectedItems[menuId].sites = scope.siteId.map((id) => ({
            value: id,
            label: sites.find((s) => s.id.toString() === id.toString())?.name,
          }));
        }
        if (Array.isArray(scope.l3Id) && scope.l3Id.length > 0) {
          newSelectedItems[menuId].l3 = scope.l3Id.map((id) => ({
            value: id,
            label: l3.find((l) => l.id === id)?.name,
          }));
        }
      });
      setSelectedItems(newSelectedItems);
      setSelectedRoles(newSelectedRoles);

      console.log("UPDATED SELECTED ITEMS")
      console.log(newSelectedItems)

      console.log("UPDATED SELECTED ROLES")
      console.log(newSelectedRoles)
    }
  }, [
    selectedPermissions,
    userMenu,
    countryLookup,
    businessUnits,
    projectClients,
    projects, // Add projects to the dependency array
    sites,
    l3,
  ]);

  const handleUserSelect = async (selectedOption) => {
    setSelectedUser(selectedOption);
    if (selectedOption) {
      setIsLoading(true);
      await dispatch(getUserMenu(selectedOption.value));
    } else {
      setActiveTab("");
      setSelectedItems({});
      setSelectedRoles({});
    }
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    setSelectedRoles((prev) => ({
      ...prev,
      [tab]: prev[tab] || [],
    }));
    setSelectedItems((prev) => ({
      ...prev,
      [tab]: prev[tab] || {
        countries: [],
        businessUnits: [],
        projectClients: [],
        projects: [],
        sites: [],
        l3: [],
      },
    }));
  };
  const isFieldEnabled = (menuId, field) => {
    switch (field) {
      case 'businessUnits':
        return selectedItems[menuId]?.countries?.length > 0;
      case 'projectClients':
        return selectedItems[menuId]?.businessUnits?.length > 0;
      case 'projects':
        return selectedItems[menuId]?.projectClients?.length > 0
      case 'sites':
        return selectedItems[menuId]?.projects?.length > 0
      case 'l3':
        return selectedItems[menuId]?.sites?.length > 0;
      default:
        return true;
    }
  };

  const handleItemSelect = (menuId, field, selectedOptions) => {
    
    setSelectedItems((prevItems) => {
      const newItems = {
        ...prevItems,
        [menuId]: {
          ...prevItems[menuId],
          [field]: selectedOptions,
        },
      };

      // Clear child selections based on parent-child relationships
      switch (field) {
        case 'countries':
          if (selectedOptions.length === 0) {
            newItems[menuId].businessUnits = [];
            newItems[menuId].projectClients = [];
            newItems[menuId].projects = [];
            newItems[menuId].sites = [];
            newItems[menuId].l3 = [];
          }
          break;
        case 'businessUnits':
          if (selectedOptions.length === 0) {
            newItems[menuId].projectClients = [];
            newItems[menuId].projects = [];
            newItems[menuId].sites = [];
            newItems[menuId].l3 = [];
          }
          break;
        case 'projectClients':
          if (selectedOptions.length === 0) {
            newItems[menuId].projects = [];
            newItems[menuId].sites = [];
            newItems[menuId].l3 = [];
          }
          break;
        case 'projects':
          if (selectedOptions.length === 0) {
            newItems[menuId].sites = [];
            newItems[menuId].l3 = [];
          }
          break;
        case 'sites':
          if (selectedOptions.length === 0) {
            newItems[menuId].l3 = [];
          }
          break;
      }

      return newItems;
    });
  };

  const handleRoleSelect = (menuId, selectedOptions) => {
    setSelectedRoles((prev) => ({
      ...prev,
      [menuId]: selectedOptions,
    }));

    // Clear all items if no roles are selected
    if (selectedOptions.length === 0) {
      setSelectedItems((prevItems) => ({
        ...prevItems,
        [menuId]: {
          countries: [],
          businessUnits: [],
          projectClients: [],
          sites: [],
          l3: [],
        },
      }));
    }
  };

  const getFilteredBusinessUnits = (menuId) => {
    const selectedCountriesForMenu = selectedItems[menuId]?.countries || [];

    if (selectedCountriesForMenu.length === 0) {
      return businessUnits;
    }

    const filteredBU = businessUnits.filter((bu) =>
      selectedCountriesForMenu.some((country) => bu.coId === country.value)
    );

    return filteredBU;
  };

  const getFilteredProjectClients = (menuId) => {
    const selectedCountriesForMenu = selectedItems[menuId]?.countries || [];

    if (selectedCountriesForMenu.length === 0) {
      return projectClients;
    }

    const filteredPC = projectClients.filter((pc) =>
      pc.coId.some((coId) =>
        selectedCountriesForMenu.some((country) => coId === country.value)
      )
    );

    return filteredPC;
  };

  const getFilteredProjects = (menuId) => {
    const selectedBusinessUnitsForMenu = selectedItems[menuId]?.businessUnits || [];
    const selectedProjectClientsForMenu = selectedItems[menuId]?.projectClients || [];

    if (selectedBusinessUnitsForMenu.length === 0 && selectedProjectClientsForMenu.length === 0) {
      return projects;
    }

    const filteredProjects = projects.filter((project) => {
      const matchesBusinessUnit = selectedBusinessUnitsForMenu.length === 0 ||
        selectedBusinessUnitsForMenu.some((bu) => bu.value === project.buId);

      const matchesProjectClient = selectedProjectClientsForMenu.length === 0 ||
        selectedProjectClientsForMenu.some((pc) => pc.value === project.pcId);

      return matchesBusinessUnit && matchesProjectClient;
    });

    return filteredProjects;
  };

  const getFilteredSites = (menuId) => {
    const selectedProjectClientsForMenu =
      selectedItems[menuId]?.projectClients || [];
    if (selectedProjectClientsForMenu.length === 0) {
      return sites;
    }
    const relevantProjectIds = projects
      .filter((project) =>
        selectedProjectClientsForMenu.some((pc) => project.pcId === pc.value),
      )
      .map((project) => project.id);


    return sites.filter((site) => relevantProjectIds.includes(site.prId));
  };

  const handleSubmit = async () => {
    console.log("Starting handleSubmit function");
    console.log("selectedUser:", selectedUser);
  
    if (!selectedUser) {
      console.error("No user selected");
      return;
    }
    
    console.log("selectedItems:", selectedItems);
    console.log("selectedRoles:", selectedRoles);
  
    const scopes = Object.entries(selectedItems).map(([menuId, items]) => {
      console.log(`Processing menuId: ${menuId}`);
      console.log(`Items for menuId ${menuId}:`, items);
  
      const scope = {
        menuId: parseInt(menuId),
        coId: [],
        buId: [],
        pcId: [],
        prId: [],
        siteId: [],
        l3Id: []
      };
  
      // Remove the condition checking for selected roles
      console.log(`Processing items for menuId ${menuId}`);
      scope.coId = (items.countries || []).map(country => parseInt(country.value));
      scope.buId = (items.businessUnits || []).map(bu => parseInt(bu.value));
      scope.pcId = (items.projectClients || []).map(pc => parseInt(pc.value));
      scope.prId = (items.projects || []).map(project => parseInt(project.value));
      scope.siteId = (items.sites || []).map(site => parseInt(site.value));
      scope.l3Id = (items.l3 || []).map(l => parseInt(l.value));
  
      console.log(`Roles for menuId ${menuId}:`, selectedRoles[menuId]);
      console.log(`Scope for menuId ${menuId}:`, scope);
      return scope;
    });
  
    console.log("Final scopes:", scopes);
  
    const roles = Object.entries(selectedRoles).flatMap(([menuId, roles]) =>
      roles.map((role) => ({
        menuId: parseInt(menuId),
        permissionId: role.value,
      }))
    );
    console.log("Processed roles:", roles);
  
    const payload = {
      userId: selectedUser.value,
      roles: JSON.stringify(roles),
      scopes: JSON.stringify(scopes),
    };
    
    console.log("Final payload:", payload);
  
    try {
      const result = await dispatch(savePermission(payload));
      console.log("savePermission result:", result);
    } catch (error) {
      console.error("Error in savePermission:", error);
    }
  }

  const userOptions = users.map((user) => ({
    value: user.id,
    label: user.name,
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "38px",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  return (
    <Container fluid>
      <Card className="my-3">
        <CardHeader>
          <h3 className="mb-0">Permissions Access</h3>
        </CardHeader>
        <CardBody className="p-4">
          <Row className="mb-4">
            <Col md={6}>
              <Select
                options={userOptions}
                value={selectedUser}
                onChange={handleUserSelect}
                placeholder="Select a user..."
                isClearable
                styles={customStyles}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
              />
            </Col>
          </Row>
          {isLoading ? (
            <div className="text-center">
              <Spinner color="primary" />
            </div>
          ) : (
            selectedUser && (
              <>
                <Nav tabs className="mb-3">
                  {userMenu.map((menu) => (
                    <NavItem key={menu.id}>
                      <NavLink
                        className={
                          activeTab === menu.id.toString() ? "active" : ""
                        }
                        onClick={() => toggleTab(menu.id.toString())}
                      >
                        {menu.name}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <TabContent activeTab={activeTab}>
                  {userMenu.map((menu) => (
                    <TabPane key={menu.id} tabId={menu.id.toString()}>
                      <Row className="mb-3">
                        <Col md={12}>
                          <h5>Features Permissions</h5>
                          <Select
                            options={menu.permissions.map((perm) => ({
                              value: perm.id,
                              label: perm.name,
                            }))}
                            value={selectedRoles[menu.id]}
                            onChange={(options) =>
                              handleRoleSelect(menu.id, options)
                            }
                            placeholder="Select permissions..."
                            isMulti
                          />
                        </Col>
                      </Row>
                      <>
                        <hr />
                        <h5>Scope</h5>
                        <Row className="mb-3">
                          <Col md={6}>
                            <h5>Countries</h5>
                            <Select
                              options={countries.map((country) => ({
                                value: country.id,
                                label: country.name,
                              }))}
                              value={selectedItems[menu.id]?.countries}
                              onChange={(options) =>
                                handleItemSelect(menu.id, "countries", options)
                              }
                              placeholder="Select countries..."
                              isMulti
                            />
                          </Col>
                          <Col md={6}>
                            <h5>Business Units</h5>
                            <Select
                              options={getFilteredBusinessUnits(menu.id).map(
                                (bu) => ({
                                  value: bu.id,
                                  label: bu.name,
                                }),
                              )}
                              value={selectedItems[menu.id]?.businessUnits}
                              onChange={(options) =>
                                handleItemSelect(
                                  menu.id,
                                  "businessUnits",
                                  options,
                                )

                              }
                              placeholder="Select business units..."
                              isMulti
                              isDisabled={!isFieldEnabled(menu.id, 'businessUnits')}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md={6}>
                            <h5>Project Clients</h5>
                            <Select
                              options={getFilteredProjectClients(menu.id).map(
                                (pc) => ({
                                  value: pc.id,
                                  label: pc.name,
                                }),
                              )}
                              value={selectedItems[menu.id]?.projectClients}
                              onChange={(options) =>
                                handleItemSelect(
                                  menu.id,
                                  "projectClients",
                                  options,
                                )
                              }
                              placeholder="Select project clients..."
                              isMulti
                              isDisabled={!isFieldEnabled(menu.id, 'projectClients')}
                            />
                          </Col>
                          <Col md={6}>
                            <h5>Projects</h5>
                            <Select
                              options={getFilteredProjects(menu.id).map((project) => ({
                                value: project.id,
                                label: project.name,
                              }))}
                              value={selectedItems[menu.id]?.projects}
                              onChange={(options) =>
                                handleItemSelect(menu.id, "projects", options)
                              }
                              placeholder="Select projects..."
                              isMulti
                              styles={customStyles}
                              isDisabled={!isFieldEnabled(menu.id, 'projects')}
                            />
                          </Col>

                        </Row>
                        <Row className="mb-3">
                          <Col md={6}>
                            <h5>Sites</h5>
                            <Select
                              options={getFilteredSites(menu.id).map(
                                (site) => ({
                                  value: site.id,
                                  label: site.name,
                                }),
                              )}
                              value={selectedItems[menu.id]?.sites}
                              onChange={(options) =>
                                handleItemSelect(menu.id, "sites", options)
                              }
                              placeholder="Select sites..."
                              isMulti
                              isDisabled={!isFieldEnabled(menu.id, 'sites')}
                            />
                          </Col>
                          <Col md={6}>
                            <h5>L3</h5>
                            <Select
                              options={l3.map((item) => ({
                                value: item.id,
                                label: item.name,
                              }))}
                              value={selectedItems[menu.id]?.l3}
                              onChange={(options) =>
                                handleItemSelect(menu.id, "l3", options)
                              }
                              placeholder="Select L3..."
                              isMulti
                              isDisabled={!isFieldEnabled(menu.id, 'l3')}
                            />
                          </Col>
                        </Row>

                      </>
                    </TabPane>
                  ))}
                </TabContent>
                <Row className="mt-4">
                  <Col>
                    <Button color="primary" size="lg" onClick={handleSubmit}>
                      Submit
                    </Button>
                  </Col>
                </Row>
              </>
            )
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default PermissionAccess;
