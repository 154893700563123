import {ADD_TEMPLATE, DELETE_TEMPLATE, GET_TEMPLATES, GET_TEMPLATE, UPDATE_TEMPLATE,GET_TEMPLATE_VALUES} from "../../types";

const initialState = { list: [], single: null,values:[] };

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_TEMPLATES:
            return { ...state, list: payload };

        case GET_TEMPLATE_VALUES:
            return { ...state, values: payload };

        case GET_TEMPLATE:
            return { ...state, single: payload || initialState.single };

        case UPDATE_TEMPLATE:
            return {
                ...state,
                list: state.list.map((item) =>
                    item.id === payload.id ? payload : item
                ),
            };

        case ADD_TEMPLATE:
            return {
                ...state,
                list: [payload, ...state.list],
            };

        case DELETE_TEMPLATE:
            return {
                ...state,
                list: state.list.filter((item) => item.id !== payload.id),
            };

        default:
            return state;
    }
};

export default reducer;