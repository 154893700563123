import {
    GET_NONCONFORMITIES,
    GET_USER_NONCONFORMITIES,
    GET_SINGLE_NONCONFORMITY,
    ADD_NONCONFORMITY,
    UPDATE_NONCONFORMITY,
    DELETE_NONCONFORMITY,
    SUBMIT_NONCONFORMITY,
    SUBMIT_ACTION_FOR_NONCONFORMITY
} from "../../types";

const initialState = {
    list: [],
    userNonconformities: {},
    single: null,
    loading: true,
    error: null
};

const nonconformityReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_NONCONFORMITIES:
            return {
                ...state,
                list: payload,
                loading: false
            };
        case GET_USER_NONCONFORMITIES:
            return {
                ...state,
                userNonconformities: payload,
                loading: false
            };
        case GET_SINGLE_NONCONFORMITY:
            return {
                ...state,
                single: payload,
                loading: false
            };
        case ADD_NONCONFORMITY:
            return {
                ...state,
                userNonconformities: payload,
                loading: false
            };
        case UPDATE_NONCONFORMITY:
            return {
                ...state,
                userNonconformities: payload,
                single: payload,
                loading: false
            };
        case DELETE_NONCONFORMITY:
            return {
                ...state,
                userNonconformities: state.userNonconformities.user.filter(
                    nonconformity => nonconformity.id !== payload
                ),
                loading: false
            };
        case SUBMIT_NONCONFORMITY:
            return {
                ...state,
                userNonconformities: payload,
                single: payload,
                loading: false
            };
        case SUBMIT_ACTION_FOR_NONCONFORMITY:
            return {
                ...state,
                userNonconformities: state.userNonconformities.map(nonconformity =>
                    nonconformity.id === payload.nonconformityId
                        ? { ...nonconformity, actions: [...nonconformity.actions, payload.action] }
                        : nonconformity
                ),
                single: state.single && state.single.id === payload.nonconformityId
                    ? { ...state.single, actions: [...state.single.actions, payload.action] }
                    : state.single,
                loading: false
            };
        default:
            return state;
    }
};

export default nonconformityReducer;