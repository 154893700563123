import React, { useCallback, useEffect, useState } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Button,
    Table, FormFeedback, Form, ModalHeader, ModalBody, ModalFooter, Modal
} from 'reactstrap';
import Select from 'react-select';
import { LabelWrapper, RedAsteriskComponent } from "../../../components/RedAsterik";
import fileDownload from 'react-file-download';
import { useDispatch, useSelector } from "react-redux";
import { getTags } from "../../../store/actions/tag";
import {formatDateWithTime, getCurrentDateString} from "../../../utils/functions";
import { DeleteOutlined } from "@ant-design/icons";


const GeneralSection = ({ formData, handleSelectChange, hazardData, showSupervisor = true }) => {
    // Helper function to find the label for a given value
    const findLabelForValue = (options, value) => {
        const option = options.find(opt => opt.value === value);
        return option ? option : null;
    };

    // Prepare options
    const responsibleManagerOptions = hazardData?.responsible?.map(manager => ({
        value: manager.id.toString(),
        label: manager.name
    })) || [];

    const closeoutManagerOptions = hazardData?.closeout?.map(manager => ({
        value: manager.id.toString(),
        label: manager.name
    })) || [];

    const supervisorOptions = hazardData?.supervisor?.map(sup => ({
        value: sup.id.toString(),
        label: `${sup.name}`
    })) || [];

    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardHeader style={{ backgroundColor: '#e6f2ff', borderBottom: '1px solid #b8daff' }}>
                <h2 className="card-title mb-0">General</h2>
            </CardHeader>
            <Row style={{ marginBottom: '1rem', marginTop: '1rem', padding: '20px' }}>
                <Col md="6" lg="6">
                    <FormGroup row>
                        <Col sm="4">
                            <LabelWrapper>
                                <RedAsteriskComponent>*</RedAsteriskComponent>
                                <Label for="responsibleManager" className="mb-0">Responsible Manager</Label>
                            </LabelWrapper>
                        </Col>
                        <Col sm="8">
                            <Select
                                id="responsibleManager"
                                value={findLabelForValue(responsibleManagerOptions, formData.responsibleManager?.value)}
                                onChange={(option) => handleSelectChange('responsibleManager', option)}
                                options={responsibleManagerOptions}
                                placeholder="--Select User--"
                                aria-label="Responsible Manager"
                            />
                        </Col>
                    </FormGroup>
                </Col>
                <Col md="6" lg="6">
                    <FormGroup row>
                        <Col sm="4">
                            <LabelWrapper>
                                <RedAsteriskComponent>*</RedAsteriskComponent>
                                <Label for="closeoutManager" className="mb-0">Closeout Manager</Label>
                            </LabelWrapper>
                        </Col>
                        <Col sm="8">
                            <Select
                                id="closeoutManager"
                                value={findLabelForValue(closeoutManagerOptions, formData.closeoutManager?.value)}
                                onChange={(option) => handleSelectChange('closeoutManager', option)}
                                options={closeoutManagerOptions}
                                placeholder="--Select User--"
                                aria-label="Closeout Manager"
                            />
                        </Col>
                    </FormGroup>
                </Col>
                {showSupervisor && (
                    <Col md="6" lg="6">
                        <FormGroup row>
                            <Col sm="4">
                                <LabelWrapper>
                                    <RedAsteriskComponent>*</RedAsteriskComponent>
                                    <Label for="supervisor" className="mb-0">Supervisor</Label>
                                </LabelWrapper>
                            </Col>
                            <Col sm="8">
                                <Select
                                    id="supervisor"
                                    value={findLabelForValue(supervisorOptions, formData.supervisor?.value)}
                                    onChange={(option) => handleSelectChange('supervisor', option)}
                                    options={supervisorOptions}
                                    placeholder="--Select User--"
                                    aria-label="Supervisor"
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                )}
            </Row>
        </Card>
    );
};
const AdminSection = ({ formData, handleInputChange, handleSelectChange, hazardData }) => (
    <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
        <CardHeader style={{ backgroundColor: '#e6f2ff', borderBottom: '1px solid #b8daff' }}>
            <h2 className="card-title mb-0">Admin Details</h2>
        </CardHeader>
        <Row style={{ marginBottom: '1rem', marginTop: '1rem', padding: '20px' }}>
            <Col md="6" lg="6">
                <FormGroup row>
                    <Col sm="4">
                        <LabelWrapper>
                            <RedAsteriskComponent />
                            <Label for="site" className="mb-0">Site</Label>
                        </LabelWrapper>
                    </Col>
                    <Col sm="8">
                        <Select
                            id="site"
                            value={formData.site}
                            onChange={(option) => handleSelectChange('site', option)}
                            options={hazardData?.sites?.map(site => ({
                                value: site.id.toString(),
                                label: site.name
                            })) || []}
                            placeholder="--Select Site--"
                            aria-label="Site"
                        />
                    </Col>
                </FormGroup>
            </Col>
            <Col md="6" lg="6">
                <FormGroup row>
                    <Col sm="4">
                        <LabelWrapper>
                            <RedAsteriskComponent />
                            <Label for="l3Contractor" className="mb-0">L3 Contractor</Label>
                        </LabelWrapper>
                    </Col>
                    <Col sm="8">
                        <Select
                            id="l3Contractor"
                            value={formData.l3Contractor}
                            onChange={(option) => handleSelectChange('l3Contractor', option)}
                            options={hazardData?.l3?.map(contractor => ({
                                value: contractor.id.toString(),
                                label: contractor.name
                            })) || []}
                            placeholder="--Select Company--"
                            aria-label="L3 Contractor"
                        />
                    </Col>
                </FormGroup>
            </Col>
            <Col md="6" lg="6">
                <FormGroup row>
                    <Col sm="4">
                        <Label for="locationPlace" className="mb-0">Location Place</Label>
                    </Col>
                    <Col sm="8">
                        <Input
                            type="text"
                            id="locationPlace"
                            name="locationPlace"
                            placeholder="For example: Hot works area of workshop"
                            value={formData.locationPlace}
                            onChange={handleInputChange}
                        />
                    </Col>
                </FormGroup>
            </Col>
        </Row>
    </Card>
);
const HazardSection = ({ formData, handleInputChange, handleSelectChange, hazardData }) => {

    useEffect(() => {
        if (!formData.detectedOn) {
            handleInputChange({
                target: {
                    name: 'detectedOn',
                    value: new Date().toISOString().split('T')[0]
                }
            });
        }
    }, []);

    // Helper function to find the label for a given value
    const findLabelForValue = (options, value) => {
        const option = options.find(opt => opt.value === value);
        return option ? option : null;
    };

    const findMatchingOptions = (selectedValues, allOptions) => {
        return selectedValues.map(value => {
            const matchingOption = allOptions.find(option => option.value === value.value);
            return matchingOption || value; // If no match found, return the original value
        });
    };

    const actualHazardsOptions = hazardData?.actualHazards?.map(hazard => ({
        value: hazard.id.toString(),
        label: hazard.name
    })) || [];

    const selectedPotentialHazards = findMatchingOptions(formData.potentialHazards, actualHazardsOptions);

    const [identifiedByType, setIdentifiedByType] = useState(

        formData.identifiedBy ? 'user' : (formData.identifiedByOther ? 'other' : 'user')
    );

    // ... (other state and useEffect hooks)

    const identifiedByOptions = [
        { value: 'user', label: 'User' },
        { value: 'other', label: 'Other' }
    ];

    const handleIdentifiedByChange = (selectedOption) => {
        const type = selectedOption.value;
        setIdentifiedByType(type);
        if (type === 'user') {
            handleInputChange({ target: { name: 'identifiedByOther', value: '' } });
        } else {
            handleSelectChange('identifiedBy', null);
        }
    };


    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardHeader style={{ backgroundColor: '#e6f2ff', borderBottom: '1px solid #b8daff' }}>
                <h2 className="card-title mb-0">Hazard Details</h2>
            </CardHeader>
            <Row style={{ marginBottom: '1rem', marginTop: '1rem', padding: '20px' }}>
                <Col md="6" lg="6">
                    <FormGroup row>
                        <Col sm="4">
                            <LabelWrapper>
                                <RedAsteriskComponent />
                                <Label for="reportType" className="mb-0">Detected On</Label>
                            </LabelWrapper>
                        </Col>
                        <Col sm="8">
                            <Input
                                type="date"
                                id="detectedOn"
                                name="detectedOn"
                                value={formData.detectedOn}
                                onChange={handleInputChange}
                                max={getCurrentDateString()}
                            />
                        </Col>
                    </FormGroup>
                </Col>
                <Col md="6" lg="6">
                    <FormGroup row>
                        <Col sm="4">
                            <Label className="mb-0">Identified By</Label>
                        </Col>
                        <Col sm="8">
                            <Select
                                options={identifiedByOptions}
                                value={identifiedByOptions.find(option => option.value === identifiedByType)}
                                onChange={handleIdentifiedByChange}
                                defaultValue={identifiedByOptions[0]}
                            />
                        </Col>
                    </FormGroup>
                </Col>
                <Col md="6" lg="6">
                    {identifiedByType === 'user' ? (
                        <FormGroup row>
                            <Col sm="4">
                                <Label for="identifiedBy" className="mb-0">Identified By User</Label>
                            </Col>
                            <Col sm="8">
                                <Select
                                    id="identifiedBy"
                                    value={findLabelForValue(hazardData?.identifiers?.map(identifier => ({
                                        value: identifier.id.toString(),
                                        label: `${identifier.name}`
                                    })) || [], formData.identifiedBy?.value)}
                                    onChange={(option) => handleSelectChange('identifiedBy', option)}
                                    options={hazardData?.identifiers?.map(identifier => ({
                                        value: identifier.id.toString(),
                                        label: `${identifier.name}`
                                    })) || []}
                                    placeholder="--Select User--"
                                    aria-label="Identified By"
                                />
                            </Col>
                        </FormGroup>
                    ) : (
                        <FormGroup row>
                            <Col sm="4">
                                <Label for="identifiedByOther" className="mb-0">Identified By Other</Label>
                            </Col>
                            <Col sm="8">
                                <Input
                                    type="text"
                                    id="identifiedByOther"
                                    name="identifiedByOther"
                                    value={formData.identifiedByOther || ''}
                                    onChange={handleInputChange}
                                    placeholder="Enter name"
                                />
                            </Col>
                        </FormGroup>
                    )}
                </Col>
                <Col md="6" lg="6">
                    <FormGroup row>
                        <Col sm="4">
                            <LabelWrapper>
                                <RedAsteriskComponent />
                                <Label for="reportType" className="mb-0">Report Type</Label>
                            </LabelWrapper>
                        </Col>
                        <Col sm="8">
                            <Select
                                id="reportType"
                                value={findLabelForValue(hazardData?.reportTypes?.map(type => ({
                                    value: type.id.toString(),
                                    label: type.name
                                })) || [], formData.reportType?.value)}
                                onChange={(option) => handleSelectChange('reportType', option)}
                                options={hazardData?.reportTypes?.map(type => ({
                                    value: type.id.toString(),
                                    label: type.name
                                })) || []}
                                placeholder="--Select Report Type--"
                                aria-label="Report Type"
                            />
                        </Col>
                    </FormGroup>
                </Col>
                <Col md="6" lg="6">
                    <FormGroup row>
                        <Col sm="4">
                            <LabelWrapper>
                                <RedAsteriskComponent />
                                <Label for="hazardSource" className="mb-0">Hazard Source</Label>
                            </LabelWrapper>
                        </Col>
                        <Col sm="8">
                            <Select
                                id="hazardSource"
                                value={findLabelForValue(hazardData?.hazardSources?.map(source => ({
                                    value: source.id.toString(),
                                    label: source.name
                                })) || [], formData.hazardSource?.value)}
                                onChange={(option) => handleSelectChange('hazardSource', option)}
                                options={hazardData?.hazardSources?.map(source => ({
                                    value: source.id.toString(),
                                    label: source.name
                                })) || []}
                                placeholder="--Select Hazard Source--"
                                aria-label="Hazard Source"
                            />
                        </Col>
                    </FormGroup>
                </Col>
                <Col md="12" lg="12">
                    <FormGroup row>
                        <Col sm="2">
                            <Label for="potentialHazards" className="mb-0">Identified potential/Actual hazards</Label>
                        </Col>
                        <Col sm="10">
                            <Select
                                id="potentialHazards"
                                value={selectedPotentialHazards}
                                onChange={(option) => handleSelectChange('potentialHazards', option)}
                                options={actualHazardsOptions}
                                isMulti
                                placeholder="--Select Hazards--"
                                aria-label="Potential Hazards"
                            />
                        </Col>
                    </FormGroup>
                </Col>
                <Col md="12" lg="12">
                    <FormGroup row>
                        <Col sm="4">
                            <Label for="details" className="mb-0">Details</Label>
                        </Col>
                        <Col sm="8">
                            <Input
                                type="textarea"
                                id="details"
                                name="details"
                                value={formData.details || ''}
                                onChange={handleInputChange}
                                placeholder="Type Details"
                            />
                        </Col>
                    </FormGroup>
                </Col>
                <Col md="12" lg="12">
                    <FormGroup row>
                        <Col sm="4">
                            <Label for="immediateActions" className="mb-0">Immediate Actions</Label>
                        </Col>
                        <Col sm="8">
                            <Input
                                type="textarea"
                                id="immediateActions"
                                name="immediateActions"
                                value={formData.immediateActions || ''}
                                onChange={handleInputChange}
                                placeholder="Type Immediate Action"
                            />
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
        </Card>
    );
};

const NonconformityDetailsSection = ({
    formData,
    handleInputChange,
    handleSelectChange,
    hazardData
}) => {
    const [identifiedByType, setIdentifiedByType] = useState(
        formData.identifiedByUser ? 'user' : (formData.identifiedByOther ? 'other' : 'user')
    );

    const identifiedByOptions = [
        { value: 'user', label: 'User' },
        { value: 'other', label: 'Other' }
    ];

    const handleIdentifiedByChange = (selectedOption) => {
        const type = selectedOption.value;
        setIdentifiedByType(type);
        if (type === 'user') {
            handleInputChange({ target: { name: 'identifiedByOther', value: '' } });
        } else {
            handleSelectChange('identifiedByUser', null);
        }
    };

    const identifiersOptions = hazardData?.identifiers?.map(source => ({
        value: source.id.toString(),
        label: source.name
    })) || [];

    const ncIdentifiersOptions = hazardData?.ncIdentifiers?.map(source => ({
        value: source.id.toString(),
        label: source.name
    })) || [];

    const ncSourcesOptions = hazardData?.ncSources?.map(source => ({
        value: source.id.toString(),
        label: source.name
    })) || [];


    const actualPotential = [
        { value: 'Actual', label: 'Actual' },
        { value: 'Potential', label: 'Potential' }
    ]


    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardHeader style={{ backgroundColor: '#e6f2ff', borderBottom: '1px solid #b8daff' }}>
                <h2 className="card-title mb-0">Nonconformity details</h2>
            </CardHeader>
            <Row style={{ marginBottom: '1rem', marginTop: '1rem', padding: '20px' }}>
                <Col md="6" lg="4">
                    <FormGroup>
                        <Label for="detectedOn">Detected On</Label>
                        <Input
                            type="date"
                            id="detectedOn"
                            name="detectedOn"
                            value={formData.detectedOn}
                            onChange={handleInputChange}
                            max={getCurrentDateString()}
                        />
                    </FormGroup>
                </Col>
                <Col md="6" lg="4">
                    <FormGroup>
                        <Label>Identified By</Label>
                        <Select
                            options={identifiedByOptions}
                            value={identifiedByOptions.find(option => option.value === identifiedByType)}
                            onChange={handleIdentifiedByChange}
                            defaultValue={identifiedByOptions[0]}
                        />
                    </FormGroup>
                </Col>
                <Col md="6" lg="4">
                    {identifiedByType === 'user' ? (
                        <FormGroup>
                            <Label for="identifiedByUser">Identified By User</Label>
                            <Select
                                id="identifiedByUser"
                                name="identifiedByUser"
                                value={formData.identifiedByUser}
                                onChange={(option) => handleSelectChange('identifiedByUser', option)}
                                options={identifiersOptions}
                                placeholder="--Select User--"
                            />
                        </FormGroup>
                    ) : (
                        <FormGroup>
                            <Label for="identifiedByOther">Identified By Other</Label>
                            <Input
                                type="text"
                                id="identifiedByOther"
                                name="identifiedByOther"
                                value={formData.identifiedByOther}
                                onChange={handleInputChange}
                                placeholder="Identified By Other"
                            />
                        </FormGroup>
                    )}
                </Col>
                <Col md="4">
                    <FormGroup>
                        <Label for="actualPotential">Actual/Potential NonConfirmity</Label>
                        <Select
                            id="actualPotential"
                            name="actualPotential"
                            value={formData.actualPotential}
                            onChange={(option) => handleSelectChange('actualPotential', option)}
                            options={actualPotential}
                            placeholder="--Select--"
                        />
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <Label for="nonconformityIdentifiedBy">Nonconformity Identified By</Label>
                        <Select
                            id="nonconformityIdentifiedBy"
                            name="nonconformityIdentifiedBy"
                            value={formData.nonconformityIdentifiedBy}
                            onChange={(option) => handleSelectChange('nonconformityIdentifiedBy', option)}
                            options={ncIdentifiersOptions}
                            placeholder="--Select Nonconformity Identified By--"
                        />
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <RedAsteriskComponent>*</RedAsteriskComponent>
                        <Label for="sourceOfNonconformity">Source of Nonconformity</Label>
                        <Select
                            id="sourceOfNonconformity"
                            name="sourceOfNonconformity"
                            value={formData.sourceOfNonconformity}
                            onChange={(option) => handleSelectChange('sourceOfNonconformity', option)}
                            options={ncSourcesOptions}
                            placeholder="--Select Nonconformity Source--"
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label for="summary">Summary</Label>
                        <Input
                            type="textarea"
                            id="summary"
                            name="summary"
                            value={formData.summary}
                            onChange={handleInputChange}
                            placeholder="Type summary"
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label for="detail">Detail</Label>
                        <Input
                            type="textarea"
                            id="detail"
                            name="detail"
                            value={formData.detail}
                            onChange={handleInputChange}
                            placeholder="Type Detail"
                        />
                    </FormGroup>
                </Col>
            </Row>
        </Card>
    );
};


const ContributingFactorsSection = ({ formData, handleInputChange,handleSelectChange,actionUsers, hazardData, showImprovementOpportunity = false, showFactors = false,showCostImplications=true }) => {
    const [actions, setActions] = useState(formData.actions || []);
    const [factors, setFactors] = useState(formData.factors || []);
    const [factorOptions, setFactorOptions] = useState([])


    useEffect(() => {
        if (showFactors) {
            setFactorOptions(hazardData.contributingFactors)
        }
    }, [hazardData]);

    useEffect(() => {
        setActions(formData.actions || []);
    }, [formData.actions]);

    useEffect(() => {
        if (formData.furtherActionsRequired === 'No') {
            setActions([]);
            handleInputChange({ target: { name: 'actions', value: [] } });
        }
    }, [formData.furtherActionsRequired]);



    const handleActionChange = (index, field, value) => {
        const newActions = [...actions];
        newActions[index][field] = value;
        setActions(newActions);
        handleInputChange({ target: { name: 'actions', value: newActions } });
    };

    const addAction = () => {
        const newActions = [...actions, { userId: '', description: '', targetDays: '', type: 'N' }];
        setActions(newActions);
        handleInputChange({ target: { name: 'actions', value: newActions } });
    };

    const removeAction = (index) => {
        const newActions = actions.filter((_, i) => i !== index);
        setActions(newActions);
        handleInputChange({ target: { name: 'actions', value: newActions } });
    };

    const userOptions = actionUsers.map(user => ({
        value: user.id,
        label: `${user.name}`
    }));

    const furtherActionsOptions = [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
    ];

    const handleFurtherActionsChange = (selectedOption) => {
        handleInputChange({
            target: {
                name: 'furtherActionsRequired',
                value: selectedOption.value
            }
        });
    };

    const handleFactorChange = (index, field, value) => {
        const newFactors = [...factors];
        if (field === 'factor') {
            const selectedFactor = factorOptions.find(option => option.id === value);
            newFactors[index] = {
                ...newFactors[index],
                id: selectedFactor.id,
                label: selectedFactor.label,
                contributingFactor: selectedFactor.contributingFactor
            };
        } else {
            newFactors[index][field] = value;
        }
        setFactors(newFactors);
        handleInputChange({ target: { name: 'factors', value: newFactors } });
    };

    const addFactor = () => {
        const newFactors = [...factors, { id: '', label: '', contributingFactor: '', description: '' }];
        setFactors(newFactors);
        handleInputChange({ target: { name: 'factors', value: newFactors } });
    };

    const removeFactor = (index) => {
        const newFactors = factors.filter((_, i) => i !== index);
        setFactors(newFactors);
        handleInputChange({ target: { name: 'factors', value: newFactors } });
    };


    return (
        <>
            <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
                <CardHeader style={{
                    backgroundColor: '#e6f2ff',
                    borderBottom: '1px solid #b8daff',
                }}>
                    <h2 className="card-title mb-0">Contributing Factors and Actions</h2>
                </CardHeader>
                <CardBody>
                    <FormGroup>
                        <Label>Further actions required?</Label>
                        <Select
                            value={furtherActionsOptions.find(option => option.value === formData.furtherActionsRequired)}
                            onChange={handleFurtherActionsChange}
                            options={furtherActionsOptions}
                            placeholder="Select..."
                        />
                    </FormGroup>

                    {showImprovementOpportunity && (
                        <FormGroup>
                            <Label for="isImprovementOpportunity">Is Improvement Opportunity</Label>
                            <Select
                                id="isImprovementOpportunity"
                                name="isImprovementOpportunity"
                                value={formData.isImprovementOpportunity}
                                onChange={handleSelectChange}
                                options={[
                                    { value: true, label: 'Yes' },
                                    { value: false, label: 'No' }
                                ]}
                            />
                        </FormGroup>
                    )}

                    {formData.furtherActionsRequired === 'Yes' && (
                        <>
                            <div className="mt-3">
                                <Table bordered>
                                    <thead>
                                        <tr>
                                            <th>To</th>
                                            <th>Description</th>
                                            <th>Target Days</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {actions.map((action, index) => (
                                            <tr key={index} style={action.type !== 'N' ? { backgroundColor: '#f0f0f0' } : {}}>
                                                <td>
                                                    <Select
                                                        value={userOptions.find(option => option.value === action.userId)}
                                                        onChange={(selectedOption) => handleActionChange(index, 'userId', selectedOption.value)}
                                                        options={userOptions}
                                                        placeholder="Select User"
                                                        isDisabled={action.type !== 'N'}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        type="textarea"
                                                        value={action.description}
                                                        onChange={(e) => handleActionChange(index, 'description', e.target.value)}
                                                        placeholder="Type Description"
                                                        disabled={action.type !== 'N'}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        type="number"
                                                        value={action.targetDays}
                                                        onChange={(e) => handleActionChange(index, 'targetDays', e.target.value)}
                                                        placeholder="Target Days"
                                                        min="0"
                                                        disabled={action.type !== 'N'}
                                                    />
                                                </td>
                                                <td>
                                                    {action.type === 'N' ? (
                                                        <Button color="danger" size="sm" onClick={() => removeAction(index)}>
                                                            <i className="fas fa-minus"></i>
                                                        </Button>
                                                    ) : (
                                                        <span>-</span>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <Button color="primary" size="sm" onClick={addAction}>
                                    <i className="fas fa-plus"></i>
                                </Button>
                            </div>
                            {showCostImplications && (<div className="mt-4">
                                <CostImplicationTableSection formData={formData} handleInputChange={handleInputChange}/>
                            </div>)}
                        </>
                    )}

                    {showFactors === true && (
                        <>
                        <CardHeader style={{
                                backgroundColor: '#e6f2ff',
                                borderBottom: '1px solid #b8daff',
                            }}>
                                <h2 className="card-title mb-0">Factors</h2>
                            </CardHeader>
                            <CardBody>
                                <div className="d-flex justify-content-end mb-2">
                                    <Button color="primary" size="sm" onClick={addFactor}>
                                        <i className="fas fa-plus"></i>
                                    </Button>
                                </div>
                                <Table bordered>
                                    <thead>
                                        <tr>
                                            <th>Factor</th>
                                            <th>Reason</th>
                                            <th>Description</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {factors.map((factor, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <Select
                                                        value={factorOptions.find(option => option.id === factor.id)}
                                                        onChange={(selectedOption) => handleFactorChange(index, 'factor', selectedOption.id)}
                                                        options={factorOptions}
                                                        getOptionLabel={(option) => option.contributingFactor}
                                                        getOptionValue={(option) => option.id}
                                                        placeholder="Select Factor"
                                                    />
                                                </td>
                                                <td>{factor.contributingFactor}</td>
                                                <td>
                                                    <Input
                                                        type="textarea"
                                                        value={factor.description}
                                                        onChange={(e) => handleFactorChange(index, 'description', e.target.value)}
                                                        placeholder="Enter Description"
                                                    />
                                                </td>
                                                <td>
                                                    <Button color="danger" size="sm" onClick={() => removeFactor(index)}>
                                                        <i className="fas fa-minus"></i>
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </>
                    )}

                </CardBody>
            </Card>

        </>
    );
};
const CostImplicationTableSection = ({ formData, handleInputChange }) => {
    const [internalKeys, setInternalKeys] = useState({});

    useEffect(() => {
        // Initialize internalKeys with existing cost implications
        const initialKeys = (formData.costImplications || []).reduce((acc, implication, index) => {
            acc[`implication_${index}`] = implication;
            return acc;
        }, {});
        setInternalKeys(initialKeys);
    }, []);

    const generateKey = () => `implication_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

    const addNewRow = () => {
        const newImplication = {
            description: '',
            amount: '',
            allocation: '',
            comment: ''
        };
        const newKey = generateKey();
        setInternalKeys(prev => ({ ...prev, [newKey]: newImplication }));
        handleInputChange({
            target: {
                name: 'costImplications',
                value: [...(formData.costImplications || []), newImplication]
            }
        });
    };

    const updateImplication = (key, field, value) => {
        const updatedImplications = Object.values(internalKeys).map((imp) =>
            imp === internalKeys[key] ? { ...imp, [field]: value } : imp
        );
        setInternalKeys(prev => ({
            ...prev,
            [key]: { ...prev[key], [field]: value }
        }));
        handleInputChange({
            target: {
                name: 'costImplications',
                value: updatedImplications
            }
        });
    };

    const deleteImplication = (key) => {
        const filteredImplications = Object.values(internalKeys).filter(imp => imp !== internalKeys[key]);
        setInternalKeys(prev => {
            const newKeys = { ...prev };
            delete newKeys[key];
            return newKeys;
        });
        handleInputChange({
            target: {
                name: 'costImplications',
                value: filteredImplications
            }
        });
    };

    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardHeader style={{
                backgroundColor: '#e6f2ff',
                borderBottom: '1px solid #b8daff',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <h2 className="card-title mb-0">Cost Implications</h2>
                <Button color="primary" onClick={addNewRow}>Add Cost Implication</Button>
            </CardHeader>
            <CardBody>
                <Table>
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Amount</th>
                            <th>Cost Allocation</th>
                            <th>Comment</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(internalKeys).map(([key, implication]) => (
                            <tr key={key}>
                                <td>
                                    <Input
                                        type="text"
                                        value={implication.description}
                                        onChange={(e) => updateImplication(key, 'description', e.target.value)}
                                        style={{ borderColor: 'black' }}
                                    />
                                </td>
                                <td>
                                    <Input
                                        type="number"
                                        value={implication.amount}
                                        onChange={(e) => updateImplication(key, 'amount', e.target.value)}
                                        style={{ borderColor: 'black' }}
                                    />
                                </td>
                                <td>
                                    <Input
                                        type="text"
                                        value={implication.allocation}
                                        onChange={(e) => updateImplication(key, 'allocation', e.target.value)}
                                        style={{ borderColor: 'black' }}
                                    />
                                </td>
                                <td>
                                    <Input
                                        type="text"
                                        value={implication.comment}
                                        onChange={(e) => updateImplication(key, 'comment', e.target.value)}
                                        style={{ borderColor: 'black' }}
                                    />
                                </td>
                                <td>
                                    <Button
                                        color="danger"
                                        size="sm"
                                        onClick={() => deleteImplication(key)}
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
};

const AttachmentsSection = ({ attachments = [], onAttachmentsChange }) => {
    const [internalKeys, setInternalKeys] = useState({});

    useEffect(() => {
        // Initialize internalKeys with existing attachments
        const initialKeys = attachments.reduce((acc, attachment, index) => {
            acc[`attachment_${index}`] = attachment;
            return acc;
        }, {});
        setInternalKeys(initialKeys);
    }, []);

    const generateKey = () => `attachment_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

    const addNewRow = () => {
        const newAttachment = {
            filename: '',
            name: '',
            description: '',
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            file: null,
            path: null
        };
        const newKey = generateKey();
        setInternalKeys(prev => ({ ...prev, [newKey]: newAttachment }));
        onAttachmentsChange([...attachments, newAttachment]);
    };

    const updateAttachment = (key, field, value) => {
        const updatedAttachments = Object.values(internalKeys).map((att, index) =>
            att === internalKeys[key] ? { ...att, [field]: value } : att
        );
        setInternalKeys(prev => ({
            ...prev,
            [key]: { ...prev[key], [field]: value }
        }));
        onAttachmentsChange(updatedAttachments);
    };

    const handleFileChange = (key, file) => {
        if (file && file.size > 5 * 1024 * 1024) {
            alert("File size should not exceed 5MB");
            return;
        }

        const updatedAttachment = {
            ...internalKeys[key],
            filename: file.name,
            name: file.name,
            file: file,
            path: null,
        };
        setInternalKeys(prev => ({
            ...prev,
            [key]: updatedAttachment
        }));
        const updatedAttachments = Object.values(internalKeys).map(att =>
            att === internalKeys[key] ? updatedAttachment : att
        );
        onAttachmentsChange(updatedAttachments);
    };

    const deleteAttachment = (key) => {
        const filteredAttachments = Object.values(internalKeys).filter(att => att !== internalKeys[key]);
        setInternalKeys(prev => {
            const newKeys = { ...prev };
            delete newKeys[key];
            return newKeys;
        });
        onAttachmentsChange(filteredAttachments);
    };

    const viewAttachment = (attachment) => {
        if (attachment.file instanceof File) {
            const url = URL.createObjectURL(attachment.file);
            window.open(url, '_blank');
        } else if (attachment.fullPath || attachment.path) {
            fetch(`${encodeURIComponent(attachment.fullPath || attachment.path)}`)
                .then(response => response.blob())
                .then(blob => {
                    fileDownload(blob, attachment.fileName || attachment.filename || 'download');
                })
                .catch(error => {
                    console.error('Error downloading file:', error);
                    alert('Error downloading file. Please try again.');
                });
        }
    };

    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardHeader style={{
                backgroundColor: '#e6f2ff',
                borderBottom: '1px solid #b8daff',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <h2 className="card-title mb-0">Attachments</h2>
                <Button color="primary" onClick={addNewRow}>Add Attachment</Button>
            </CardHeader>
            <CardBody>
                <Table>
                    <thead>
                        <tr>
                            <th>File Name</th>
                            <th>Description</th>
                            <th>Upload File</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(internalKeys).map(([key, attachment]) => (
                            <tr key={key}>
                                <td>
                                    {attachment.filename || attachment.name}
                                </td>
                                <td>
                                    <Input
                                        type="text"
                                        value={attachment.description}
                                        onChange={(e) => updateAttachment(key, 'description', e.target.value)}
                                        style={{ borderColor: 'black' }}
                                    />
                                </td>
                                <td>
                                    <FormGroup>
                                        <Label for={`file-${key}`} className="btn btn-secondary">
                                            {attachment.file || attachment.path ? 'Change File' : 'Upload File'}
                                        </Label>
                                        <Input
                                            type="file"
                                            id={`file-${key}`}
                                            onChange={(e) => handleFileChange(key, e.target.files[0])}
                                            style={{ display: 'none' }}
                                        />
                                    </FormGroup>
                                </td>
                                <td>
                                    <Button
                                        color="secondary"
                                        size="sm"
                                        className="mr-2"
                                        onClick={() => viewAttachment(attachment)}
                                        disabled={!attachment.file && !attachment.path}
                                    >
                                        {attachment.fullPath || attachment.path ? 'Download' : 'View'}
                                    </Button>
                                    <Button
                                        color="danger"
                                        size="sm"
                                        onClick={() => deleteAttachment(key)}
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
};


const IncidentTypeDropdown = ({ formData, handleIncidentTypeChange }) => {
    const options = [
        { value: 'workRelatedIncident', label: 'Work Related Incident' },
        { value: 'regulatoryNotifiableIncident', label: 'Regulatory Notifiable Incident' },
        { value: 'contractorIncident', label: 'Contractor Incident' },
        { value: 'noticesIssued', label: 'Notices Issued' }
    ];

    const getValue = () => {
        return options.filter(option => formData[option.value]);
    };

    const handleChange = (selectedOptions) => {
        const updatedFormData = {
            workRelatedIncident: false,
            regulatoryNotifiableIncident: false,
            contractorIncident: false,
            noticesIssued: false
        };

        selectedOptions.forEach(option => {
            updatedFormData[option.value] = true;
        });

        handleIncidentTypeChange(updatedFormData);
    };

    return (
        <Col md="12">
            <FormGroup>
                <Label for="incidentType">Type of Incident</Label>
                <Select
                    isMulti
                    name="incidentType"
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={getValue()}
                    onChange={handleChange}
                    placeholder="Select incident type(s)..."
                />
            </FormGroup>
        </Col>
    );
};


const IncidentReportDetailsSection = ({
    formData,
    handleInputChange,
    handleSelectChange,
    incidentData,
    errors,
    setErrors
}) => {

    console.log("inside Incident Report Details Section")
    console.log(incidentData)
    const [identifiedByType, setIdentifiedByType] = useState(
        formData.identifiedBy ? 'user' : (formData.identifiedByOther ? 'other' : 'user')
    );

    const identifiedByOptions = [
        { value: 'user', label: 'User' },
        { value: 'other', label: 'Other' }
    ];

    const actualPotentialCategory = incidentData?.apCategories?.map(category => ({
        value: category,
        label: category,
    })) || []

    let categoryOptions = incidentData?.categories?.map(category => ({
        value: category.id.toString(),
        label: category.name
    })) || [];

    let workActivityOptions = incidentData?.workActivities?.map(activity => ({
        value: activity.id.toString(),
        label: activity.name
    })) || [];

    let hazardSourceOptions = incidentData?.hazardSources?.map(source => ({
        value: source.id.toString(),
        label: source.name
    })) || [];

    let standardHazardOptions = incidentData?.standardHazards?.map(hazard => ({
        value: hazard.id.toString(),
        label: hazard.name
    })) || [];

    const handleIncidentTypeChange = useCallback((newIncidentTypeState) => {
        Object.entries(newIncidentTypeState).forEach(([key, value]) => {
            handleInputChange({ target: { name: key, value } });
        });
    }, [handleInputChange]);

    const validateField = (name, value) => {
        let error = '';
        switch (name) {
            case 'detectedOn':
                if (!value) error = 'Detected On is required';
                break;
            case 'classification':
                if (formData.dhsIncident) error = 'Classification is required for DHS Incidents';
                break;
            case 'description':
                if (!value) error = 'Description is required';
                break;
            case 'icatId':
                if (value.length === 0) error = 'At least one Incident Category is required';
                break;
            default:
                break;
        }
        setErrors(prev => ({ ...prev, [name]: error }));
    };

    const handleIdentifiedByChange = (selectedOption) => {
        const type = selectedOption.value;
        setIdentifiedByType(type);
        if (type === 'user') {
            handleInputChange({ target: { name: 'identifiedByOther', value: '' } });
        } else {
            handleSelectChange('identifiedBy', null);
        }
    };

    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardHeader style={{
                backgroundColor: '#e6f2ff',
                borderBottom: '1px solid #b8daff',
            }}>
                <h2 className="card-title mb-0">Incident Report Details</h2>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md="6" lg="4">
                        <FormGroup>
                            <Label for="detectedOn">Detected On</Label>
                            <Input
                                type="datetime"
                                id="detectedOn"
                                name="detectedOn"
                                value={formData.detectedOn}
                                onChange={(e) => {
                                    handleInputChange(e);
                                    validateField('detectedOn', e.target.value);
                                }}
                                invalid={!!errors.detectedOn}
                            />
                            <FormFeedback>{errors.detectedOn}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="6" lg="4">
                        <FormGroup>
                            <Label>Identified By</Label>
                            <Select
                                options={identifiedByOptions}
                                value={identifiedByOptions.find(option => option.value === identifiedByType)}
                                onChange={handleIdentifiedByChange}
                                defaultValue={identifiedByOptions[0]}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6" lg="4">
                        {identifiedByType === 'user' ? (
                            <FormGroup>
                                <Label for="identifiedBy">Identified By User</Label>
                                <Select
                                    id="identifiedBy"
                                    name="identifiedBy"
                                    value={formData.identifiedBy}
                                    onChange={(option) => {
                                        handleSelectChange('identifiedBy', option);
                                        validateField('identifiedBy', option);
                                    }}
                                    options={incidentData?.identifiers?.map(identifier => ({
                                        value: identifier.id.toString(),
                                        label: identifier.name
                                    })) || []}
                                    placeholder="--Select User--"
                                    isInvalid={!!errors.identifiedBy}
                                />
                                {errors.identifiedBy &&
                                    <div className="invalid-feedback d-block">{errors.identifiedBy}</div>}
                            </FormGroup>
                        ) : (
                            <FormGroup>
                                <Label for="identifiedByOther">Identified By Other</Label>
                                <Input
                                    type="text"
                                    id="identifiedByOther"
                                    name="identifiedByOther"
                                    value={formData.identifiedByOther}
                                    onChange={(e) => {
                                        handleInputChange(e);
                                        validateField('identifiedByOther', e.target.value);
                                    }}
                                    placeholder="Enter name"
                                    invalid={!!errors.identifiedByOther}
                                />
                                <FormFeedback>{errors.identifiedByOther}</FormFeedback>
                            </FormGroup>
                        )}
                    </Col>
                    <Col md="6" lg="6">
                        <FormGroup>
                            <Label for="dhsIncident">Is this Local Country Authority?</Label>
                            <Select
                                id="dhsIncident"
                                name="dhsIncident"
                                value={formData.dhsIncident ? { value: true, label: 'Yes' } : { value: false, label: 'No' }}
                                onChange={(option) => {
                                    option.value === true ? formData.dhsIncident = true : formData.dhsIncident = false;
                                    handleInputChange({ target: { name: 'dhsIncident', value: option.value } });
                                    validateField('dhsIncident', option.value);
                                    if (!option.value) {
                                        // If 'No' is selected, reset the classification
                                        handleSelectChange('classification', null);
                                    }
                                }}
                                options={[
                                    { value: false, label: 'No' },
                                    { value: true, label: 'Yes' }
                                ]}
                                defaultValue={{ value: false, label: 'No' }}
                                isInvalid={!!errors.dhsIncident}
                            />
                            {errors.dhsIncident && <div className="invalid-feedback d-block">{errors.dhsIncident}</div>}
                        </FormGroup>
                    </Col>
                    <Col md="6" lg="6">
                        {formData.dhsIncident && (
                            <FormGroup>
                                <Label for="classification">Classification</Label>
                                <Select
                                    id="classification"
                                    name="classification"
                                    value={formData.classification}
                                    onChange={(option) => {
                                        handleSelectChange('classification', option);
                                        validateField('classification', option);
                                    }}
                                    options={incidentData?.classifications?.map(classification => ({
                                        value: classification.id.toString(),
                                        label: classification.name
                                    })) || []}
                                    placeholder="--Select Classification--"
                                    isInvalid={!!errors.classification}
                                />
                                {errors.classification &&
                                    <div className="invalid-feedback d-block">{errors.classification}</div>}
                            </FormGroup>
                        )}
                    </Col>
                    <Col md="12">
                        <FormGroup>
                            <RedAsteriskComponent>*</RedAsteriskComponent>
                            <Label for="description">Description</Label>
                            <Input
                                type="textarea"
                                id="description"
                                name="description"
                                value={formData.description}
                                onChange={(e) => {
                                    handleInputChange(e);
                                    validateField('description', e.target.value);
                                }}
                                placeholder="Type Description...."
                                invalid={!!errors.description}
                            />
                            <FormFeedback>{errors.description}</FormFeedback>
                        </FormGroup>
                    </Col>

                    <IncidentTypeDropdown
                        formData={formData}
                        handleIncidentTypeChange={handleIncidentTypeChange}
                    />


                    <Col md="12">
                        <FormGroup>
                            <Label for="incidentCategories">Incident Categories</Label>
                            <Select
                                id="incidentCategories"
                                name="incidentCategories"
                                value={formData.incidentCategories}
                                onChange={(options) => handleSelectChange('incidentCategories', options)}
                                options={categoryOptions}
                                isMulti
                                placeholder="--Select Incident Categories--"
                                isInvalid={!!errors.incidentCategories}
                            />
                            {errors.incidentCategories &&
                                <div className="invalid-feedback d-block">{errors.incidentCategories}</div>}
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label for="workActivity">Work Activity</Label>
                            <Select
                                id="workActivity"
                                name="workActivity"
                                value={formData.workActivity}
                                onChange={(option) => {
                                    handleSelectChange('workActivity', option);
                                }}
                                options={workActivityOptions}
                                placeholder="--Select Work Activity--"
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label for="hazardSource">Hazard Source</Label>
                            <Select
                                id="hazardSource"
                                name="hazardSource"
                                value={formData.hazardSource}
                                onChange={(option) => {
                                    handleSelectChange('hazardSource', option);
                                }}
                                options={hazardSourceOptions}
                                placeholder="--Select Hazard Source--"
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label for="primaryHazard">Primary Hazard</Label>
                            <Select
                                id="primaryHazard"
                                name="primaryHazard"
                                value={formData.primaryHazard}
                                onChange={(option) => {
                                    handleSelectChange('primaryHazard', option);
                                }}
                                options={standardHazardOptions}
                                placeholder="--Select Primary Hazard--"
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label for="secondaryHazards">Secondary Hazards</Label>
                            <Select
                                id="secondaryHazards"
                                name="secondaryHazards"
                                value={formData.secondaryHazards}
                                onChange={(options) => handleSelectChange('secondaryHazards', options)}
                                options={standardHazardOptions}
                                isMulti
                                placeholder="--Select Secondary Hazards--"
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label for="actualHazard">Risk Rating/Level Based on RAM</Label>
                            <Select
                                id="actualHazard"
                                name="actualHazard"
                                value={formData.actualHazard}
                                onChange={(option) => {
                                    handleSelectChange('actualHazard', option);
                                }}
                                options={actualPotentialCategory}
                                placeholder="--Select Actual Hazard--"
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label for="potentialHazard">Potential Hazards</Label>
                            <Select
                                id="potentialHazard"
                                name="potentialHazard"
                                value={formData.potentialHazard}
                                onChange={(options) => handleSelectChange('potentialHazard', options)}
                                options={actualPotentialCategory}
                                placeholder="--Select Potential Hazards--"
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};


const InvolvedPersonsCard = ({
    headerText,
    users,
    involvedPersons,
    updateInvolvedPersons,
    userTypes
}) => {
    const [formData, setFormData] = useState({
        userType: null,
        selectedUser: null,
        otherPersonName: '',
        otherDetail: '',
        typeOfPerson: null
    });
    const [formErrors, setFormErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setFormErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    };

    const handleSelectChange = (selectedOption, { name }) => {
        setFormData(prevState => {
            const newState = { ...prevState, [name]: selectedOption };

            if (name === 'userType') {
                newState.selectedUser = null;
                newState.otherPersonName = '';
                newState.typeOfPerson = null;
            }

            return newState;
        });
        setFormErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    };

    const validateForm = () => {
        const errors = {};
        if (!formData.userType) {
            errors.userType = 'Please select a user type';
        }
        if (formData.userType?.value === 'registered' && !formData.selectedUser) {
            errors.selectedUser = 'Please select a user';
        }
        if (formData.userType?.value === 'other' && !formData.otherPersonName.trim()) {
            errors.otherPersonName = 'Please enter a name';
        }
        if (formData.userType?.value === 'other' && !formData.typeOfPerson) {
            errors.typeOfPerson = 'Please select a type of person';
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const addPerson = () => {
        if (validateForm()) {
            const newPerson = {
                id: Date.now().toString(),
                type: formData.userType?.value === 'registered' ? 'R' : 'O',
                userId: formData.userType?.value === 'registered' ? formData.selectedUser.value : null,
                other: formData.userType?.value === 'registered' ? null : formData.otherPersonName,
                userType: formData.userType?.value === 'registered' ? null : formData.typeOfPerson.value,
                otherDetail: formData.otherDetail
            };

            updateInvolvedPersons([...involvedPersons, newPerson]);
            setFormData({
                userType: null,
                selectedUser: null,
                otherPersonName: '',
                otherDetail: '',
                typeOfPerson: null
            });
            setFormErrors({});
        }
    };

    const removePerson = (id) => {
        const updatedPersons = involvedPersons.filter(person => person.id !== id);
        updateInvolvedPersons(updatedPersons);
    };

    const userOptions = users.map(user => ({
        value: user.id,
        label: `${user.name}`
    }));

    const typeOptions = userTypes.map(type => ({
        value: type.id.toString(),
        label: type.name
    }));

    const userTypeOptions = [
        { value: 'registered', label: 'Registered User' },
        { value: 'other', label: 'Other new one' }
    ];

    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardHeader style={{ backgroundColor: '#e6f2ff', borderBottom: '1px solid #b8daff' }}>
                <h2 className="card-title mb-0">{headerText || 'Involved'}</h2>
            </CardHeader>
            <CardBody>
                <div className="sticky-top bg-light p-3 mb-3">
                    <Row className="align-items-end">
                        <Col md={4}>
                            <FormGroup>
                                <Label>User Type</Label>
                                <Select
                                    options={userTypeOptions}
                                    value={formData.userType}
                                    onChange={(option) => handleSelectChange(option, { name: 'userType' })}
                                    placeholder="--Select User Type--"
                                />
                                {formErrors.userType && <div className="text-danger">{formErrors.userType}</div>}
                            </FormGroup>
                            <FormGroup>
                                <Label>Name</Label>
                                {formData.userType?.value === 'registered' ? (
                                    <>
                                        <Select
                                            options={userOptions}
                                            value={formData.selectedUser}
                                            onChange={(option) => handleSelectChange(option, { name: 'selectedUser' })}
                                            placeholder="--Select User--"
                                        />
                                        {formErrors.selectedUser && <div className="text-danger">{formErrors.selectedUser}</div>}
                                    </>
                                ) : (
                                    <>
                                        <Input
                                            type="text"
                                            name="otherPersonName"
                                            value={formData.otherPersonName}
                                            onChange={handleInputChange}
                                            placeholder="Other Person Name"
                                            disabled={formData.userType?.value !== 'other'}
                                        />
                                        {formErrors.otherPersonName && <div className="text-danger">{formErrors.otherPersonName}</div>}
                                    </>
                                )}
                            </FormGroup>
                        </Col>

                        <Col md={4}>
                            <FormGroup>
                                <Label>Type of person</Label>
                                <Select
                                    options={typeOptions}
                                    value={formData.typeOfPerson}
                                    onChange={(option) => handleSelectChange(option, { name: 'typeOfPerson' })}
                                    isDisabled={formData.userType?.value === 'registered'}
                                    placeholder="--Select Type--"
                                />
                                {formErrors.typeOfPerson && <div className="text-danger">{formErrors.typeOfPerson}</div>}
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Other Detail</Label>
                                <Input
                                    type="textarea"
                                    name="otherDetail"
                                    value={formData.otherDetail}
                                    onChange={handleInputChange}
                                    placeholder="Type Other Details"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="text-center mt-3">
                            <Button color="primary" onClick={addPerson}>Add Person</Button>
                        </Col>
                    </Row>
                </div>

                <Table bordered>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Type of Person</th>
                            <th>Other Detail</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {involvedPersons.map((person) => (
                            <tr key={person.id}>
                                <td>{person.type === 'R' ? userOptions.find(u => u.value === person.userId)?.label : person.other}</td>
                                <td>{person.type === 'R' ? 'Registered User' : typeOptions.find(t => t.value === person.userType)?.label}</td>
                                <td>{person.otherDetail}</td>
                                <td>
                                    <Button color="danger" size="sm" onClick={() => removePerson(person.id)}>
                                        <i className="fas fa-minus"></i> Remove
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
};



const LogOfNonconformitySection = ({ formData, handleInputChange }) => {
    const [internalKeys, setInternalKeys] = useState({});

    useEffect(() => {
        // Initialize internalKeys with existing logs
        const initialKeys = (formData.logs || []).reduce((acc, log, index) => {
            acc[`log_${index}`] = log;
            return acc;
        }, {});
        setInternalKeys(initialKeys);
    }, []);

    const generateKey = () => `log_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

    const addNewRow = () => {
        const newLog = {
            id: Date.now(),
            date: new Date().toISOString().split('T')[0],
            description: '',
        };
        const newKey = generateKey();
        setInternalKeys(prev => ({ ...prev, [newKey]: newLog }));
        handleInputChange({
            target: {
                name: 'logs',
                value: [...(formData.logs || []), newLog]
            }
        });
    };

    const updateLog = (key, field, value) => {
        const updatedLogs = Object.values(internalKeys).map((log) =>
            log === internalKeys[key] ? { ...log, [field]: value } : log
        );
        setInternalKeys(prev => ({
            ...prev,
            [key]: { ...prev[key], [field]: value }
        }));
        handleInputChange({
            target: {
                name: 'logs',
                value: updatedLogs
            }
        });
    };

    const deleteLog = (key) => {
        const filteredLogs = Object.values(internalKeys).filter(log => log !== internalKeys[key]);
        setInternalKeys(prev => {
            const newKeys = { ...prev };
            delete newKeys[key];
            return newKeys;
        });
        handleInputChange({
            target: {
                name: 'logs',
                value: filteredLogs
            }
        });
    };

    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardHeader style={{
                backgroundColor: '#e6f2ff',
                borderBottom: '1px solid #b8daff',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <h2 className="card-title mb-0">Log of Nonconformity</h2>
                <Button color="primary" onClick={addNewRow}>Add Log</Button>
            </CardHeader>
            <CardBody>
                <Table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Description</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(internalKeys).map(([key, log]) => (
                            <tr key={key}>
                                <td>
                                    <Input
                                        type="date"
                                        value={log.date}
                                        onChange={(e) => updateLog(key, 'date', e.target.value)}
                                        style={{ borderColor: 'black' }}
                                    />
                                </td>
                                <td>
                                    <Input
                                        type="text"
                                        value={log.description}
                                        onChange={(e) => updateLog(key, 'description', e.target.value)}
                                        style={{ borderColor: 'black' }}
                                    />
                                </td>
                                <td>
                                    <Button
                                        color="danger"
                                        size="sm"
                                        onClick={() => deleteLog(key)}
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
};


const ImprovementOpportunityDetailsSection = ({
    formData,
    handleInputChange,
    handleSelectChange,
    incidentData,
}) => {
    const [identifiedByType, setIdentifiedByType] = useState(
        formData.identifiedByUser ? 'user' : (formData.identifiedByOther ? 'other' : 'user')
    );

    const identifiedByOptions = [
        { value: 'user', label: 'User' },
        { value: 'other', label: 'Other' }
    ];

    const handleIdentifiedByChange = (selectedOption) => {
        const type = selectedOption.value;
        setIdentifiedByType(type);
        if (type === 'user') {
            handleInputChange({ target: { name: 'identifiedByOther', value: '' } });
        } else {
            handleSelectChange('identifiedByUser', null);
        }
    };

    const userOptions = incidentData?.identifiers?.map(identifier => ({
        value: identifier.id.toString(),
        label: identifier.name
    })) || [];

    const improvementCategories = incidentData?.improvementCategories?.map(improvement => ({
        value: improvement.id.toString(),
        label: improvement.name
    })) || [];

    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardHeader style={{ backgroundColor: '#e6f2ff', borderBottom: '1px solid #b8daff' }}>
                <h2 className="card-title mb-0">Improvement Opportunity details</h2>
            </CardHeader>
            <Row style={{ marginBottom: '1rem', marginTop: '1rem', padding: '20px' }}>
                <Col md="6" lg="4">
                    <FormGroup>
                        <RedAsteriskComponent />
                        <Label for="detectedOn">Detected On</Label>
                        <Input
                            type="date"
                            id="detectedOn"
                            name="detectedOn"
                            value={formData.detectedOn}
                            onChange={handleInputChange}
                            placeholder="Detected On"
                            max={getCurrentDateString()}
                        />
                    </FormGroup>
                </Col>
                <Col md="6" lg="4">
                    <FormGroup>
                        <Label>Identified By</Label>
                        <Select
                            options={identifiedByOptions}
                            value={identifiedByOptions.find(option => option.value === identifiedByType)}
                            onChange={handleIdentifiedByChange}
                            defaultValue={identifiedByOptions[0]}
                        />
                    </FormGroup>
                </Col>
                <Col md="6" lg="4">
                    {identifiedByType === 'user' ? (
                        <FormGroup>
                            <Label for="identifiedByUser">Identified By User</Label>
                            <Select
                                id="identifiedByUser"
                                name="identifiedByUser"
                                value={formData.identifiedByUser}
                                onChange={(option) => handleSelectChange('identifiedByUser', option)}
                                options={userOptions}
                                placeholder="--Select User--"
                            />
                        </FormGroup>
                    ) : (
                        <FormGroup>
                            <Label for="identifiedByOther">Identified By Other</Label>
                            <Input
                                type="text"
                                id="identifiedByOther"
                                name="identifiedByOther"
                                value={formData.identifiedByOther}
                                onChange={handleInputChange}
                                placeholder="Identified By Other"
                            />
                        </FormGroup>
                    )}
                </Col>
                <Col md="12">
                    <FormGroup>
                        <LabelWrapper>
                            <RedAsteriskComponent />
                            <Label for="improvementCategory">Improvement Category</Label>
                        </LabelWrapper>
                        <Select
                            id="improvementCategory"
                            name="improvementCategory"
                            value={formData.improvementCategory}
                            onChange={(option) => handleSelectChange('improvementCategory', option)}
                            options={improvementCategories}
                            placeholder="--Select Improvement Category--"
                        />
                    </FormGroup>
                </Col>
            </Row>
        </Card>
    );
};


const ComplaintDetailsSection = ({
    formData,
    handleInputChange,
    handleSelectChange,
    complaints
}) => {
    const [identifiedByType, setIdentifiedByType] = useState(
        formData.identifiedBy ? 'user' : (formData.identifiedOther ? 'other' : null)
    );

    useEffect(() => {
        setIdentifiedByType(
            formData.identifiedBy ? 'user' : (formData.identifiedOther ? 'other' : null)
        );
    }, [formData.identifiedBy, formData.identifiedOther]);

    const complaintSourceOptions = complaints?.complaintSources?.map(source => ({
        value: source.id.toString(),
        label: source.name
    })) || [];

    const identifiers = complaints?.identifiers?.map(source => ({
        value: source.id.toString(),
        label: source.name
    })) || [];

    const complaintSignificanceOptions = complaints?.significance?.map(sig => ({
        value: sig.toString(),
        label: sig.toString()
    })) || [];

    const complaintReceiveTypeOptions = complaints?.receiveType?.map(type => ({
        value: type.toString(),
        label: type.toString()
    })) || [];

    const complaintReceiveUsersOptions = complaints?.receivedUsers?.map(user => ({
        value: user.id.toString(),
        label: user.name
    })) || [];

    const stateOptions = complaints?.states?.map(state => ({
        value: state.id.toString(),
        label: state.name
    })) || [];

    const identifiedByOptions = [
        { value: 'user', label: 'User' },
        { value: 'other', label: 'Other' }
    ];

    const handleIdentifiedByChange = (selectedOption) => {
        const type = selectedOption.value;
        setIdentifiedByType(type);
        if (type === 'user') {
            handleInputChange({ target: { name: 'identifiedOther', value: '' } });
        } else {
            handleSelectChange('identifiedBy', null);
        }
    };

    const handleInputChangeWithValidation = (e) => {
        const { name, value } = e.target;
        handleInputChange({
            target: {
                name,
                value: value.trim() === '' ? null : value
            }
        });
    };

    const handleSelectChangeWithValidation = (name, selectedOption) => {
        handleSelectChange(name, selectedOption ? selectedOption.value : null);
    };


    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardHeader style={{
                backgroundColor: '#e6f2ff',
                borderBottom: '1px solid #b8daff',
            }}>
                <h2 className="card-title mb-0">Complaint Details</h2>
            </CardHeader>
            <CardBody>
                <Form>
                    <Row className="mb-3">
                        <Col md={4}>
                            <FormGroup>
                                <Label for="detectedAt">Detected On</Label>
                                <Input
                                    type="date"
                                    id="detectedAt"
                                    name="detectedAt"
                                    value={formData.detectedAt || ''}
                                    onChange={handleInputChangeWithValidation}
                                    required
                                    max={getCurrentDateString()}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="issuedAt">Date of Issue</Label>
                                <Input
                                    type="date"
                                    name="issuedAt"
                                    id="issuedAt"
                                    value={formData.issuedAt || ''}
                                    onChange={handleInputChangeWithValidation}
                                    required
                                    max={getCurrentDateString()}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="sourceId">Complaint Source</Label>
                                <Select
                                    options={complaintSourceOptions}
                                    id="sourceId"
                                    name="sourceId"
                                    value={formData.sourceId}
                                    onChange={(option) => handleSelectChange('sourceId', option)}
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={6}>
                            <FormGroup>
                                <Label>Identified By</Label>
                                <Select
                                    options={identifiedByOptions}
                                    value={identifiedByOptions.find(option => option.value === identifiedByType)}
                                    onChange={handleIdentifiedByChange}
                                    defaultValue={identifiedByOptions[0]}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            {identifiedByType === 'user' ? (
                                <FormGroup>
                                    <Label for="identifiedBy">Identified By User</Label>
                                    <Select
                                        options={identifiers}
                                        id="identifiedBy"
                                        name="identifiedBy"
                                        value={formData.identifiedBy}
                                        onChange={(option) => handleSelectChange('identifiedBy', option)}
                                        placeholder="--Select User--"

                                    />
                                </FormGroup>
                            ) : (
                                <FormGroup>
                                    <Label for="identifiedOther">Identified By Other</Label>
                                    <Input
                                        type="text"
                                        id="identifiedOther"
                                        name="identifiedOther"
                                        value={formData.identifiedOther || ''}
                                        onChange={handleInputChangeWithValidation}
                                        placeholder="Enter name"
                                    />
                                </FormGroup>
                            )}
                        </Col>
                    </Row>


                    <Row className="mb-3">
                        <Col md={4}>
                            <FormGroup>
                                <Label for="receivedBy">Received By</Label>
                                <Select
                                    options={complaintReceiveUsersOptions}
                                    id="receivedBy"
                                    name="receivedBy"
                                    placeholder="--Select User--"
                                    value={formData.receivedBy}
                                    onChange={(option) => handleSelectChange('receivedBy', option)}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="significance">Complaint Significance</Label>
                                <Select
                                    options={complaintSignificanceOptions}
                                    id="significance"
                                    name="significance"
                                    value={complaintSignificanceOptions.find(option => option.value === formData.significance)}
                                    onChange={(selectedOption) => handleSelectChangeWithValidation('significance', selectedOption)}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="receiveType">Complaint Receive Type</Label>
                                <Select
                                    options={complaintReceiveTypeOptions}
                                    id="receiveType"
                                    name="receiveType"
                                    value={complaintReceiveTypeOptions.find(option => option.value === formData.receiveType)}
                                    onChange={(selectedOption) => handleSelectChangeWithValidation('receiveType', selectedOption)}
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={12}>
                            <FormGroup>
                                <Label for="summary">Summary</Label>
                                <Input
                                    type="textarea"
                                    name="summary"
                                    id="summary"
                                    rows="3"
                                    value={formData.summary || ''}
                                    onChange={handleInputChangeWithValidation}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={6}>
                            <FormGroup>
                                <Label for="clientName">Client Name</Label>
                                <Input
                                    type="text"
                                    name="clientName"
                                    id="clientName"
                                    value={formData.clientName || ''}
                                    onChange={handleInputChangeWithValidation}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="complainantName">Complainant Name</Label>
                                <Input
                                    type="text"
                                    name="complainantName"
                                    id="complainantName"
                                    value={formData.complainantName || ''}
                                    onChange={handleInputChangeWithValidation}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={6}>
                            <FormGroup>
                                <Label for="address1">Address Line 1</Label>
                                <Input
                                    type="text"
                                    name="address1"
                                    id="address1"
                                    value={formData.address1 || ''}
                                    onChange={handleInputChangeWithValidation}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="address2">Address Line 2</Label>
                                <Input
                                    type="text"
                                    name="address2"
                                    id="address2"
                                    value={formData.address2 || ''}
                                    onChange={handleInputChangeWithValidation}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={3}>
                            <FormGroup>
                                <Label for="suburb">Suburb</Label>
                                <Input
                                    type="text"
                                    name="suburb"
                                    id="suburb"
                                    value={formData.suburb || ''}
                                    onChange={handleInputChangeWithValidation}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="stId">State</Label>
                                <Select
                                    options={stateOptions}
                                    id="stId"
                                    name="stId"
                                    placeholder="--Select State--"
                                    value={formData.stId}
                                    onChange={(option) => handleSelectChange('stId', option)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="postalCode">Postcode</Label>
                                <Input
                                    type="text"
                                    name="postalCode"
                                    id="postalCode"
                                    value={formData.postalCode || ''}
                                    onChange={handleInputChangeWithValidation}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="location">Location</Label>
                                <Input
                                    type="text"
                                    name="location"
                                    id="location"
                                    value={formData.location || ''}
                                    onChange={handleInputChangeWithValidation}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="contact1">Contact No. 1</Label>
                                <Input
                                    type="text"
                                    name="contact1"
                                    id="contact1"
                                    value={formData.contact1 || ''}
                                    onChange={handleInputChangeWithValidation}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="contact2">Contact No. 2</Label>
                                <Input
                                    type="text"
                                    name="contact2"
                                    id="contact2"
                                    value={formData.contact2 || ''}
                                    onChange={handleInputChangeWithValidation}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
        </Card>
    );
};


const ActionModal = ({ isOpen, toggle, onInitiateAction, selectedHazard, message }) => {


    const dispatch = useDispatch();
    const tags = useSelector((state) => state.tag.list);
    const [initiateModalOpen, setInitiateModalOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const [actionFormData, setActionFormData] = useState({
        submitDate: new Date().toISOString().split('T')[0],
        targetAt: '',
        status: { value: 'open', label: 'Open' },
        description: '',
        tags: [],
        comments: []
    });

    const statusOptions = [
        { value: 'OPEN', label: 'Open' },
        { value: 'CLOSED', label: 'Close' }
    ];

    const resetForm = () => {
        setActionFormData({
            submitDate: new Date().toISOString().split('T')[0],
            targetAt: '',
            status: { value: 'open', label: 'Open' },
            description: '',
            tags: [],
            comments: []
        });
    };

    const toggleInitiateModal = (action = true) => {
        setInitiateModalOpen(!initiateModalOpen);
        setSelectedAction(action);
        if (action) {
            const selectedTags = action.tags
                ? action.tags.map(tagId => {
                    const matchingTag = tags.find(tag => tag.id.toString() === tagId.toString());
                    return matchingTag
                        ? { value: matchingTag.id.toString(), label: matchingTag.name }
                        : null;
                }).filter(tag => tag !== null)
                : [];
            setActionFormData({
                submittedAt: action.submittedAt,
                submitDate: formatDateWithTime(action.submittedAt, false) || new Date().toISOString().split('T')[0],
                targetAt: formatDateWithTime(action.targetAt, false) || '',
                description: action.description,
                reply: action.message || '',
                tags: selectedTags,
                comments: action.comments || [],
                type: action.type,
                targetDays: action.targetDays,
                status: action.status,
                id: action.id,
                userId: action.userId,
                createdBy: action.createdBy,
                updatedBy: action.updatedBy,
                createdAt: action.createdAt,
                updatedAt: action.updatedAt
            });
        } else {
            resetForm();
        }
    };

    const handleActionFormChange = (e) => {
        const { name, value } = e.target;
        setActionFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSelectChange = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        setActionFormData(prevState => ({
            ...prevState,
            [name]: name === 'status' ? selectedOption.value : selectedOption
        }));
    };
    const addCommentRow = () => {
        setActionFormData(prevState => ({
            ...prevState,
            comments: [...prevState.comments, {
                id: null,
                description: '',
                createdBy: null,
                updatedBy: null,
                createdAt: null,
                updatedAt: null
            }]
        }));
    };

    const removeCommentRow = (index) => {
        setActionFormData(prevState => ({
            ...prevState,
            comments: prevState.comments.filter((_, i) => i !== index)
        }));
    };

    const handleCommentChange = (index, value) => {
        setActionFormData(prevState => ({
            ...prevState,
            comments: prevState.comments.map((comment, i) =>
                i === index ? { ...comment, description: value } : comment
            )
        }));
    };
    const handleInitiateSubmit = (e) => {
        e.preventDefault();
        const action = {
            ...actionFormData,
            submitDate: new Date().toISOString().split('T')[0],
            status: actionFormData.status || 'PENDING',
            tags: Array.isArray(actionFormData.tags) && actionFormData.tags.length > 0
                ? actionFormData.tags
                    .filter(tag => tag && typeof tag.value !== 'undefined')
                    .map(tag => parseInt(tag.value))
                : []
        };
        onInitiateAction(action);
        toggleInitiateModal();
    };

    useEffect(() => {
        dispatch(getTags());
    }, [dispatch]);

    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} size="xl">
                <ModalHeader toggle={toggle}>
                    {message && <p>{message}</p>}
                    Number: {selectedHazard?.sequenceNo}
                </ModalHeader>
                <ModalBody>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Action Type</th>
                                <th>Submit Date</th>
                                <th>From</th>
                                <th>Message Description</th>
                                <th>To</th>
                                <th>Response Message</th>
                                <th>Response On</th>
                                <th>Response By</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedHazard?.actions?.map((action, index) => (
                                <tr key={index}>
                                    <td>
                                        <Button color="primary" size="sm" onClick={() => toggleInitiateModal(action)}>
                                            {action.status}
                                        </Button>
                                    </td>
                                    <td>{action.actionType}</td>
                                    <td>{action.submittedAt}</td>
                                    <td>{action.createdByUser}</td>
                                    <td>{action.description}</td>
                                    <td>{action.createdFor}</td>
                                    <td>{action.reply}</td>
                                    <td>{action.responseAt}</td>
                                    <td>{action.res}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={initiateModalOpen} toggle={toggleInitiateModal} size="lg">
                <Form onSubmit={handleInitiateSubmit}>
                    <ModalHeader toggle={toggleInitiateModal}>
                        {selectedAction ? 'Edit Action' : 'Initiate New Action'}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="submitDate">Submit Date</Label>
                                    <Input
                                        type="date"
                                        name="submitDate"
                                        id="submitDate"
                                        value={actionFormData.submitDate}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="targetAt">Target Date</Label>
                                    <Input
                                        type="date"
                                        name="targetAt"
                                        id="targetAt"
                                        value={actionFormData.targetAt}
                                        onChange={handleActionFormChange}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="status">Status</Label>
                                    <Select
                                        name="status"
                                        options={statusOptions}
                                        value={statusOptions.find(option => option.value === actionFormData.status) || null}
                                        onChange={handleSelectChange}
                                    // isDisabled={actionFormData.status === 'PENDING'}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <Label for="description">Description</Label>
                            <Input
                                type="textarea"
                                name="description"
                                id="description"
                                value={actionFormData.description}
                                onChange={handleActionFormChange}
                                disabled
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="message">Response Message</Label>
                            <Input
                                type="textarea"
                                name="message"
                                id="message"
                                value={actionFormData.message}
                                onChange={handleActionFormChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="tags">Tags</Label>
                            <Select
                                isMulti
                                name="tags"
                                options={tags?.map(tag => ({
                                    value: tag.id.toString(),
                                    label: tag.name
                                })) || []}
                                value={actionFormData.tags}
                                onChange={handleSelectChange}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Comments</Label>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {actionFormData.comments.map((comment, index) => (
                                        <tr key={index}>
                                            <td>
                                                <Input
                                                    type="textarea"
                                                    value={comment.description}
                                                    onChange={(e) => handleCommentChange(index, e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                <Button color="danger"
                                                    onClick={() => removeCommentRow(index)}><DeleteOutlined /></Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Button color="primary" onClick={addCommentRow}>Add Comment</Button>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleInitiateModal}>Cancel</Button>
                        <Button color="primary" type="submit">Submit</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};

export {
    ActionModal,
    GeneralSection,
    AdminSection,
    HazardSection,
    ContributingFactorsSection,
    AttachmentsSection,
    IncidentReportDetailsSection,
    InvolvedPersonsCard,
    NonconformityDetailsSection,
    LogOfNonconformitySection,
    ImprovementOpportunityDetailsSection,
    ComplaintDetailsSection,
    CostImplicationTableSection
};