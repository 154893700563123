import {
    ADD_GO_SEE_PROGRAM, DELETE_GO_SEE_PROGRAM, GENERATE_GO_SEE_PROGRAM, GENERATE_HAZARD,
    GET_SINGLE_GO_SEE_PROGRAM,
    GET_USER_SEE_PROGRAM,
    GO_SEE_PROGRAM_VALUES,
    UPDATE_GO_SEE_PROGRAM
} from "../../../types";
import axios from "../../../../axios";
import {setAlert} from "../../alert";
import {message} from "antd";

export const getGoSeeProgramValues = () => async (dispatch) => {
    try {
        const res = await axios.get("/inform/gsp/values");
        if (res.data.code === 1) {
            dispatch({type: GO_SEE_PROGRAM_VALUES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

/*
export const submitHazard = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/hazard/submit/${id}`);
        if (res.data.code === 1) {
            dispatch({type: GENERATE_HAZARD, payload: res.data.data});
        }
        return res
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
*/
export const addGSPForm = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/inform/gsp/add", formData, config);
        if (res.data.code === 1) {
            dispatch({type: ADD_GO_SEE_PROGRAM, payload: res.data.data});
            return {success: true, message: res.data.message};
        } else {
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        console.error("Error in addHazardForm:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
};
export const deleteGoSeeProgram = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/inform/gsp/delete/${id}`);
        if (res.data.code === 1) {
            dispatch({type: DELETE_GO_SEE_PROGRAM, payload: id});
            return {success:true,message:res.data.message}
        }
    } catch (err) {
        return {success:false,message:'Contact to Admin'}
        dispatch(setAlert(err.message, "danger"));
    }
};
export const updateGSPForm = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/inform/gsp/update", formData, config);
        if (res.data.code === 1) {
            dispatch({type: UPDATE_GO_SEE_PROGRAM, payload: res.data.data});
            return {success: true, message: res.data.message};
        } else {
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        console.error("Error in addHazardForm:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
};

export const getUserGSP = (accessModuleId) => async (dispatch) => {
    try {
        const object={
            moduleId:accessModuleId
        }
        const res = await axios.post("/inform/gsp/user",object);
        if (res.data.code === 1) {
            dispatch({type: GET_USER_SEE_PROGRAM, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const getSingleGSP=(id)=> async (dispatch) => {
    try {
        const res = await axios.get(`/inform/gsp/${id}`);
        
        if (res.data.code === 1) {
            dispatch({type: GET_SINGLE_GO_SEE_PROGRAM, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const submitGoSeeProgram = (object) => async (dispatch) => {
    try {
        const res = await axios.post(`/inform/gsp/submit/`,object);
        if (res.data.code === 1) {
            dispatch({type: GENERATE_GO_SEE_PROGRAM, payload: res.data.data});
        }
        return res
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const submitActionForGSP = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/inform/gsp/action/update", formData);
        if (res.data.code === 1) {
            
            //dispatch({type: GET_USER_HAZARD, payload: res.data.data});
            return {success: true, message: res.data.message};
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        console.error("Error in addHazardForm:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
}


/*
export const updateHazardForm = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/hazard/update", formData, config);
        
        //message.info(res.data.message)
        if (res.data.code === 1) {
            dispatch({type: UPDATE_HAZARD, payload: res.data.data});
            return {success: true, message: res.data.message};
        } else {
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        console.error("Error in addHazardForm:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
};

export const getUserHazards = () => async (dispatch) => {
    try {
        const res = await axios.get("/hazard/user");

        if (res.data.code === 1) {
            dispatch({type: GET_USER_HAZARD, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const submitActionForHazard = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/hazard/action/update", formData);

        if (res.data.code === 1) {
            message.success(res.data.message);
            //dispatch({type: GET_USER_HAZARD, payload: res.data.data});
            return {success: true, message: res.data.message};
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        console.error("Error in addHazardForm:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
}*/
