import React, {useState, useEffect,useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    Button, Card, CardHeader, CardBody, Row, Col, Form,
    Modal, ModalHeader, ModalBody, ModalFooter, Table
} from 'reactstrap';
import {
    GeneralSection,
    AdminSection,
    AttachmentsSection,
    ContributingFactorsSection,
    NonconformityDetailsSection,
    LogOfNonconformitySection, InvolvedPersonsCard, ActionModal,
} from "../Common/Forms";
import {message} from "antd";
import {DeleteOutlined, EditOutlined, PlusCircleFilled, EyeFilled} from "@ant-design/icons";
import {
    addNonconformityReport,
    getNonconformityValues,
    getUserNonconformities,
    getUserSingleNonconformity,
    updateNonconformityReport,
    submitNonconformity, submitActionForNonconformity,
    deleteNonconformity
} from "../../../store/actions/Incident/nonconformity";
import ReactStrapDataTable from "../../CustomDatatable/ReactStrapDataTable";
import {AccessFilter, dateSorter, formatDateWithTime, manageCURDAccess, stringSorter} from "../../../utils/functions";
import {ViewNonComformityModel} from "../Views";
import DebouncedSaveButton from '../../../components/Buttons/DebounceSaveButton';
import { showConfirmDialog, showSuccessDialog, showWarningDialog } from "../../../utils/dialogs";
import Swal from "sweetalert2";


const NonconformityReport = ({access}) => {
    const accessRights = manageCURDAccess(access);


    const dispatch = useDispatch();
    const userNonconformities = useSelector((state) => state.nonconformity.userNonconformities);
    const nonconformityData = useSelector((state) => state.nonconformity.list);
    const getSingleNonconformity = useSelector((state) => state.nonconformity.single);

    const [modalOpen, setModalOpen] = useState(false);
    const [currentHazard, setCurrentHazard] = useState(null);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [hazardToDelete, setHazardToDelete] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [actionModalOpen, setActionModalOpen] = useState(false);
    const [selectedHazard, setSelectedHazard] = useState(null);
    const [actionData, setActionData] = useState({})

    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [hazardToGenerate, setHazardToGenerate] = useState(null);

    //View Hazard
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedViewHazard, setSelectedViewHazard] = useState(null);

    const toggleActionModal = () => setActionModalOpen(!actionModalOpen);


    const [involvedPersons, setInvolvedPersons] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [contractors, setContractors] = useState([]);


    //SaveButton
    const [isProcessing, setIsProcessing] = useState(false);

    //SharedAccessFilter
    const [accessFilter, setAccessFilter] = useState('My');

    const handleAccessFilterChange = (selectedAccess) => {
        setAccessFilter(selectedAccess);
    };

    const updateInvolvedPersons = (newPersons) => {
        setInvolvedPersons(newPersons);
    };

    const updateSuppliers = (newSuppliers) => {
        setSuppliers(newSuppliers);
    }
    const updateContractors = (newContractors) => {
        setContractors(newContractors)
    }


    const handleActionClick = (hazard) => {
        //console.log(hazard)
        setSelectedHazard(hazard);
        setActionModalOpen(true);
    };

    const viewHazard = (hazard) => {
        setSelectedViewHazard(hazard);
        setViewModalOpen(true);
    }

    const handleInitiate = (actionData) => {
        setActionData(actionData)
    };
    const handleInitiateAction = async (actionData) => {
        delete actionData.submitDate
        if (actionData.message) {
            actionData.reply = actionData.message;
            delete actionData.message;
        }

        const payload = {
            nonConformityId: selectedHazard.id,
            id: selectedHazard.actionId,
            actions: JSON.stringify(actionData)
        }
        const response = await dispatch(submitActionForNonconformity(payload))
        if (response.success) {
            setActionData([]);
            setActionModalOpen(false);
            setModalOpen(false)
            dispatch(getUserNonconformities(access.moduleId))
        } else {
            message.error(response.message);
        }
    };

    const [formData, setFormData] = useState({
        responsibleManager: '',
        closeoutManager: '',
        supervisor: '',
        site: '',
        l3Contractor: '',
        locationPlace: '',
        identifiedBy: 'user',
        reportType: '',
        hazardSource: '',
        potentialHazards: [],
        details: '',
        immediateActions: '',
        detectedOn: new Date().toISOString().split('T')[0],
        furtherActionsRequired: 'No',
        isImprovementOpportunity: false,
        actions: [],
        attachments: [],
        actualPotential: {value:"Actual",label:"Actual"},
        nonconformityIdentifiedBy: null,
        sourceOfNonconformity: null,
        summary: '',
        detail: '',
        logs: null,
        identifiedByUser: null,
        identifiedByOther: '',
        factors:[],

    });

    const resetForm = () => {
        setFormData({
            responsibleManager: '',
            closeoutManager: '',
            supervisor: '',
            site: '',
            l3Contractor: '',
            locationPlace: '',
            identifiedBy: '',
            reportType: '',
            hazardSource: '',
            potentialHazards: [],
            details: '',
            immediateActions: '',
            detectedOn: new Date().toISOString().split('T')[0],
            furtherActionsRequired: 'No',
            actualPotential: {value:"Actual",label:"Actual"},
            isImprovementOpportunity: false,
            nonconformityIdentifiedBy: null,
            sourceOfNonconformity: null,
            summary: '',
            detail: '',
            logs: null,
            actions: [],
            attachments: [],
            factors:[],
        });
    };

    //Validation
    const validateForm = () => {
        if (!formData.responsibleManager) {
            message.error("Responsible Manager is required.");
            return false;
        }
        if (!formData.closeoutManager) {
            message.error("Closeout Manager is required.");
            return false;
        }

        if (!formData.site) {
            message.error("Site is required.");
            return false;
        }
        if (!formData.l3Contractor) {
            message.error("L3 Contractor is required.");
            return false;
        }
        if (!formData.detectedOn) {
            message.error("Detected On date is required.");
            return false;
        }
        if (!formData.sourceOfNonconformity) {
            message.error("Please Select Non Conformity Source");
            return false;
        }
        if (formData.identifiedBy==="user") {
            if(formData.identifiedByUser===null)
            {
                message.error("Please Select Identify By User");
                return false;
            }
        }
        if (formData.identifiedBy==="other") {
            if(formData.identifiedByOther==="")
            {
                message.error("Identify by other is empty");
                return false;
            }
        }
       
        if (formData.furtherActionsRequired === 'Yes') {
            for (let action of formData.actions) {
                if (!action.userId || !action.description || !action.targetDays) {
                    message.error("All fields in Contributing Factors and Actions are required.");
                    return false;
                }
            }
        }
        return true;
    };


    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSelectChange = (name, value) => {
        setFormData({...formData, [name]: value});
    };

    const addHazard = async (hazardData) => {
        try {
            setIsProcessing(true)
            const formdata = transformFormDataForSubmission(hazardData)
            if (formData.id) {
                const result = await dispatch(updateNonconformityReport(formdata))
                if (result.success) {
                    showSuccessDialog("Non Conformity Updated Successfully")
                    resetForm()
                    handleCloseModal()
                    dispatch(getUserNonconformities(access.moduleId))
                } else {
                    
                }
            } else {
                const result = await dispatch(addNonconformityReport(formdata))
                if (result.success) {
                    showSuccessDialog("Non Conformity Added Successfully")
                    resetForm()
                    handleCloseModal()
                    dispatch(getUserNonconformities(access.moduleId))
                } else {
                    showWarningDialog(result.message)
                }
            }

        } catch (error) {
            console.error("Error adding hazard:", error);
            setErrorMessage("An error occurred while adding the hazard. Please try again.");
        }
        finally{
            setIsProcessing(false)
        }
    };

    const transformFormDataForSubmission = (formData) => {
        
        const formDataObj = new FormData();
        formDataObj.append('moduleId',parseInt(access.moduleId))

        if (formData.id) {
            formDataObj.append('id', parseInt(formData.id));
            formDataObj.append('actionId', parseInt(formData.actionId));
            formDataObj.append('fileId', parseInt(formData.fileId));
            formDataObj.append('costId', parseInt(formData.costId));
            formDataObj.append('logId', parseInt(formData.logId));
            formDataObj.append('personId', parseInt(formData.personId));
            formDataObj.append('factorId', parseInt(formData.factorId));
        }

        formDataObj.append('rmId', parseInt(formData.responsibleManager.value));
        formDataObj.append('cmId', parseInt(formData.closeoutManager.value));
        formDataObj.append('l3Id', parseInt(formData.l3Contractor.value));
        formDataObj.append('siteId', parseInt(formData.site.value));
        formDataObj.append('location', formData.locationPlace);
        formDataObj.append('detectedAt', formatDateWithTime(formData?.detectedOn, true));
        if (formData.identifiedByUser && formData.identifiedByUser.value) {
            // console.log("Appending identifiedBy from identifiedByUser:", formData.identifiedByUser.value);
            formDataObj.append('identifiedBy', formData.identifiedByUser.value);
        } else if (formData.identifiedBy) {
            // console.log("Appending identifiedBy:", formData.identifiedBy);
            formDataObj.append('identifiedBy', formData.identifiedBy);
        } else {
            // console.log("identifiedBy not provided or invalid");
            formDataObj.append('identifiedBy', ''); // or null, depending on what your backend expects
        }


        formDataObj.append('identifiedOther', formData.identifiedByOther || '');
        //formDataObj.append('nonconformityIdentifiedBy', parseInt(formData.nonconformityIdentifiedBy.value) || null);
        formDataObj.append('ncAP', formData.actualPotential.value );

        if(formData.nonconformityIdentifiedBy!==null)
        {
            formDataObj.append('ncIdentifiedBy', parseInt(formData.nonconformityIdentifiedBy.value) ?? null);
        }

        if(formData.sourceOfNonconformity!==null)
        {
            formDataObj.append('ncsId', parseInt(formData.sourceOfNonconformity.value) ?? null);
        }


        formDataObj.append('summary', formData.summary ?? null);
        formDataObj.append('detail', formData.detail ?? null);
        formDataObj.append('actionRequired', formData.furtherActionsRequired === 'Yes');
        formDataObj.append('involved', JSON.stringify(involvedPersons));
        formDataObj.append('contractors', JSON.stringify(contractors));
        formDataObj.append('suppliers', JSON.stringify(suppliers));
        formDataObj.append('actions', JSON.stringify(formData.actions.map(action => ({
            id: action.id ?? null,
            userId: parseInt(action.userId),
            description: action.description ?? null,
            createdAt: action.createdAt ?? null,
            createdBy: action.createdBy ?? null,
            updatedAt: action.updatedAt ?? null,
            updatedBy: action.updatedBy ?? null,
            submittedAt: action.submittedAt ?? null,
            targetAt: action.targetAt ?? null,
            reply: action.reply ?? null,
            tags: action.tags ?? [],
            comments: action.comments ?? null,
            targetDays: parseInt(action.targetDays, 10),
            type: action.type ?? "N",
            status: action.status ?? "PENDING",
        }))));
        // Handle file attachments
        formData.attachments.forEach((attachment, index) => {
            if (attachment.file instanceof File) {
                formDataObj.append(`attachments[${index}]`, attachment.file, attachment.filename);
            }
        });
        formDataObj.append('files', JSON.stringify(formData.attachments.map(attachment => ({
            id: attachment.id ?? null,
            fileName: attachment.filename,
            description: attachment.description ?? null,
            path: attachment.path ?? null,
            createdAt: attachment.createdAt ?? null,
            createdBy: attachment.createdBy ?? null,
            updateBy: attachment.updateBy ?? null,
            updatedAt: attachment.updatedAt ?? null,
        }))));
        if(formData.costImplications!==undefined)
        {
            formDataObj.append('costs', JSON.stringify(formData.costImplications.map(action => ({
                id: action.id ?? null,
                description: action.description ?? null,
                amount: parseFloat(action.amount),
                allocation: action.allocation ?? null,
                comment: action.comment ?? null,
                createdAt: action.createdAt ?? null,
                createdBy: action.createdBy ?? null,
                updatedAt: action.updatedAt ?? null,
                updatedBy: action.updatedBy ?? null,
            }))));
        }
        else{
            formDataObj.append('costs', JSON.stringify([]))
        }
        if(formData.logs!==null)
        {

            formDataObj.append('logs', JSON.stringify(formData.logs.map(action => ({
                id: action.id ?? null,
                description: action.description ?? null,
                date: action.date ?? null,
                createdAt: action.createdAt ?? null,
                createdBy: action.createdBy ?? null,
                updatedAt: action.updatedAt ?? null,
                updatedBy: action.updatedBy ?? null,
            }))));
        }
        else{
            formDataObj.append('logs', JSON.stringify([]))
        }
        formDataObj.append('factors', JSON.stringify(formData.factors))


        return formDataObj;
    };

    const handleSubmit = async () => {
        
        setErrorMessage("");
       

        if (!validateForm()) {
            throw new Error("Form validation failed");
        }
        
        const contributingFactorsAndActions = formData.furtherActionsRequired === 'Yes'
            ? formData.actions.map(action => ({
                // id: Date.now(), // This should be replaced with a proper ID generation method
                userId: action.userId,
                description: action.description,
                // createdAt: null,
                createdBy: null, // This should be set to the current user's ID
                updatedAt: null,
                updatedBy: null,
                submittedAt: null,
                targetDays: action.targetDays,
                targetAt: null,
                reply: null,
                tags: [],
                comments: []
            }))
            : null;

        const hazardPayload = {
            ...formData,
            contributingFactorsAndActions
        };

        await addHazard(hazardPayload);
    };

    const handleEdit = async (hazard) => {
        setCurrentHazard(hazard);
        await dispatch(getUserSingleNonconformity(hazard.id));
        setModalOpen(true);
    };

    useEffect(() => {
        if (getSingleNonconformity && currentHazard && nonconformityData) {
            const hazardData = getSingleNonconformity;
            // console.log("editData")
            // console.log(hazardData)

            const identifiersOptions = nonconformityData?.identifiers?.map(source => ({
                value: source.id.toString(),
                label: source.name
            })) || [];

            const ncIdentifiers = nonconformityData?.ncIdentifiers?.map(source => ({
                value: source.id.toString(),
                label: source.name
            })) || [];

            const ncSourcesOptions = nonconformityData?.ncSources?.map(source => ({
                value: source.id.toString(),
                label: source.name
            })) || [];

            //Settings Dropdowns
            const selectedIdentifier = identifiersOptions.find(source => source.value === hazardData.identifiedBy?.toString()) || null;
            const selectedNonConformityUser = ncIdentifiers.find(source => source.value === hazardData.ncIdentifiedBy?.toString()) || null;
            const selectedNonConformitySource = ncSourcesOptions.find(source => source.value === hazardData.ncsId?.toString()) || null;


            //Setting Form Values
            const newFormData = {
                actionId: hazardData.actionId,
                fileId: hazardData.fileId,
                factorId:hazardData.factorId,
                id: hazardData.id,
                costId:hazardData.costId,
                logId:hazardData.logId,
                personId:hazardData.personId,
                responsibleManager: {value: hazardData.rmId.toString(), label: hazardData.responsibleManager},
                closeoutManager: {value: hazardData.cmId.toString(), label: hazardData.closeoutManager},
                site: {value: hazardData.siteId.toString(), label: hazardData.site},
                l3Contractor: {value: hazardData.l3Id.toString(), label: hazardData.l3Contractor},
                locationPlace: hazardData.location || '',
                detectedOn: formatDateWithTime(hazardData.detectedAt,false),
                identifiedByUser: selectedIdentifier,
                actualPotential: {value: hazardData.ncAP.toString(), label: hazardData.ncAP.toString()},
                summary: hazardData.summary,
                detail: hazardData.detail,
                identifiedByOther: hazardData.identifiedOther || '',
                hazardSource: hazardData.hsId ? {
                    value: hazardData.hsId.toString(),
                    label: hazardData.hazardSource
                } : '',
                nonconformityIdentifiedBy: selectedNonConformityUser,
                sourceOfNonconformity: selectedNonConformitySource,
                potentialHazards: (hazardData.ahId || []).map(id => ({value: id.toString(), label: ''})),
                details: hazardData.details || '',
                immediateActions: hazardData.immediateAction || '',
                furtherActionsRequired: hazardData.actionRequired ? 'Yes' : 'No',
                actions: (hazardData.actions || []).map(action => ({
                    id: action.id,
                    userId: action.userId,
                    description: action.description,
                    targetDays: action.targetDays,
                    createdAt: action.createdAt,
                    createdBy: action.createdBy,
                    updatedAt: action.updatedAt,
                    updatedBy: action.updatedBy,
                    submittedAt: action.submittedAt,
                    targetAt: action.targetAt,
                    reply: action.reply,
                    tags: action.tags,
                    comments: action.comments,
                    type: action.type,
                    status: action.status
                })),
                attachments: (hazardData.files || []).map(file => ({
                    id: file.id,
                    path: file.path,
                    filename: file.fileName,
                    description: file.description,
                    createdAt: file.createdAt,
                    createdBy: file.createdBy,
                    updatedAt: file.updatedAt,
                    updatedBy: file.updatedBy,
                    fullPath: file.fullPath,
                })),
                logs: (hazardData.logs || []).map(log => ({
                    id: log.id,
                    description: log.description,
                    date: log.date,
                    createdAt: log.createdAt,
                    createdBy: log.createdBy,
                    updatedAt: log.updatedAt,
                    updatedBy: log.updatedBy,
                })),
                costImplications: (hazardData.costs || []).map(cost => ({
                    id: cost.id,
                    description: cost.description,
                    amount: cost.amount,
                    allocation: cost.allocation,
                    comment: cost.comment,
                    createdAt: cost.createdAt,
                    createdBy: cost.createdBy,
                    updatedAt: cost.updatedAt,
                    updatedBy: cost.updatedBy,
                })),
                factors:hazardData.factors??[]
            };

            setInvolvedPersons(hazardData?.involved ?? []);
            setContractors(hazardData?.contractors ?? []);
            setSuppliers(hazardData?.suppliers ?? []);
            setFormData(newFormData);
        }
    }, [getSingleNonconformity, currentHazard]);

    const handleCloseModal = () => {
        resetForm();
        setCurrentHazard(null);
        setModalOpen(false);
    };

    const handleDelete = async (record) => {
        try {
            const result = await showConfirmDialog("Are you sure you want to delete this Non Confirmity?");
            if (result.isConfirmed) {
                const object = await dispatch(deleteNonconformity(record.id))
                if (object.success) {
                    Swal.fire('Deleted!', object.message, 'success');
                    dispatch(getUserNonconformities(access.moduleId));
                }
                else {
                    Swal.fire('Error!', object.message, 'error');
                }
            }
        } catch (error) {
            console.error('Error in handleDelete:', error);
        }
    };

   

    const handleGenerate = async (hazard) => {
        setHazardToGenerate(hazard);
        setConfirmModalOpen(true);
    };

    const confirmGenerate = async () => {
        if (hazardToGenerate) {
            const object={
                moduleId:parseInt(access.moduleId),
                id:hazardToGenerate.id
            }
            
            const response = await dispatch(submitNonconformity(object));
            console.log("response")
            console.log(response)
            if (response.success) {
                showSuccessDialog("Non Conformity generated successfully")
            } else {
                showWarningDialog("Failed to generate Non Conformity")
            }
            setConfirmModalOpen(false);
            setHazardToGenerate(null);
            // You might want to refresh the hazard list here
            //dispatch(getUserHazards());
        }
    };

    let columns = [
        {
            title: "Action",
            key: "action",
            width: '120px',
            render: (text, hazard) => (
                <div style={{display: 'flex', gap: '8px'}}>
                    {hazard.submittedAt === null && (
                        <Button color="primary" size="sm" onClick={() => handleEdit(hazard)} disabled={!accessRights.U}>
                            <EditOutlined/>
                        </Button>
                    )}
                   {hazard.submittedAt === null && (<>
                        <Button color="danger" size="sm" onClick={() => handleDelete(hazard)} disabled={!accessRights.D}>
                            <DeleteOutlined />
                        </Button>
                    </>)}
                    <Button color="info" size="sm" onClick={() => viewHazard(hazard)} disabled={!accessRights.R}>
                        <EyeFilled/>
                    </Button>
                    {hazard.submittedAt !== null && (
                        <Button color="info" size="sm" onClick={() => handleActionClick(hazard)} disabled={accessFilter === 'Shared'}>
                            <PlusCircleFilled/>
                        </Button>
                    )}
                </div>
            ),
        },
        {
            title: "Sequence Number",
            dataIndex: "submittedAt",
            key: "submittedAt",
            sorter: stringSorter("submittedAt"),
            sortDirections: ["ascend", "descend"],
            render: (text, hazard) => {
                if (hazard.submittedAt !== null) {

                    return hazard.sequenceNo; // Assuming the sequence string is stored in the 'sequence' property
                } else {
                    return (
                        <Button color="primary" size="sm" onClick={() => handleGenerate(hazard)}>
                            Generate
                        </Button>
                    );
                }
            }
        },
        {
            title: "Detected On",
            dataIndex: "detectedAt",
            key: "detectedAt",
            sorter: dateSorter("detectedAt"),
            sortDirections: ["ascend", "descend"],
            render: (text, hazard) => (
                <>{formatDateWithTime(text, false)}</>
            )  
        },
        {
            title: "Entered By",
            dataIndex: "createdByUser",
            key: "createdByUser",
            sorter: stringSorter("createdByUser"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "L3 Contractor",
            dataIndex: "l3Contractor",
            key: "l3Contractor",
            sorter: stringSorter("l3Contractor"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
            sorter: stringSorter("country"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Business Unit",
            dataIndex: "businessUnit",
            key: "businessUnit",
            sorter: stringSorter("businessUnit"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Project/Program",
            dataIndex: "project",
            key: "project",
            sorter: stringSorter("project"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Site",
            dataIndex: "site",
            key: "site",
            sorter: stringSorter("site"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Location Place",
            dataIndex: "location",
            key: "location",
            sorter: stringSorter("location"),
            sortDirections: ["ascend", "descend"],
        },
    ];

    // columns = enhanceColumnsWithFilters(columns)

    useEffect(() => {
        dispatch(getNonconformityValues());
        dispatch(getUserNonconformities(access.moduleId));
    }, [dispatch]);


    useEffect(() => {
        // console.log("USER NON CONFIRMITY")
        // console.log(userNonconformities)
    }, [userNonconformities]);
    

    const handleAttachmentChange = (newAttachments) => {
        setFormData({...formData, attachments: newAttachments});
    };


    const filteredNonConfirmities = useMemo(() => {
        switch (accessFilter) {
            case 'My':
                return [
                    ...(userNonconformities.user || []),
                ];
            case 'Shared':
                return userNonconformities.shared || [];
            case 'Assigned':
                return userNonconformities.assigned || [];
            default:
                return [];
        }
    }, [userNonconformities, accessFilter]);
    


    return (
        <div>
            <Card>
                <CardHeader>
                   <Row className="align-items-center mb-3">
                        <Col md={3}>
                            <h5 className="mb-0">Manage Nonconformities</h5>
                        </Col>
                        <Col md={9} className="d-flex justify-content-end align-items-center">
                            <AccessFilter
                                accessFilter={accessFilter}
                                handleAccessFilterChange={handleAccessFilterChange}
                                sharedAccess={userNonconformities?.sharedAccess || []}
                            />
                            <Button color="primary" onClick={() => setModalOpen(true)} className="ms-2" disabled={!accessRights.C}>
                                <PlusCircleFilled size={18} /> New
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactStrapDataTable
                        dataSource={filteredNonConfirmities ?? []}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} size="xl" backdrop="static">
                <Form onSubmit={handleSubmit}>
                    <ModalHeader toggle={handleCloseModal}>
                        {currentHazard ? 'Edit Nonconformity' : 'Add New Nonconformity'}
                    </ModalHeader>
                    <ModalBody>
                        <GeneralSection
                            formData={formData}
                            handleSelectChange={handleSelectChange}
                            hazardData={nonconformityData}
                            showSupervisor={false}
                        />
                        <AdminSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            hazardData={nonconformityData}

                        />
                        <NonconformityDetailsSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            hazardData={nonconformityData}
                        />
                        <ContributingFactorsSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            hazardData={nonconformityData}
                            actionUsers={nonconformityData.actionUsers}
                            showFactors={true}
                        />
                        <LogOfNonconformitySection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            hazardData={nonconformityData}
                        />
                        <AttachmentsSection
                            attachments={formData.attachments}
                            onAttachmentsChange={(newAttachments) => setFormData({
                                ...formData,
                                attachments: newAttachments
                            })}
                        />
                        <InvolvedPersonsCard
                            headerText="Involved Persons"
                            users={nonconformityData.involvedUsers}
                            involvedPersons={involvedPersons}
                            updateInvolvedPersons={updateInvolvedPersons}
                            userTypes={nonconformityData.userTypes}
                        />
                        <InvolvedPersonsCard
                            headerText="Involved Contractors"
                            users={nonconformityData.contractors}
                            involvedPersons={contractors}
                            updateInvolvedPersons={updateContractors}
                            userTypes={nonconformityData.userTypes}
                        />
                        <InvolvedPersonsCard
                            headerText="Involved Suppliers"
                            users={nonconformityData.suppliers}
                            involvedPersons={suppliers}
                            updateInvolvedPersons={updateSuppliers}
                            userTypes={nonconformityData.userTypes}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleCloseModal}>Cancel</Button>
                        <DebouncedSaveButton 
                            onSave={handleSubmit} 
                            isProcessing={isProcessing}
                        />
                    </ModalFooter>
                </Form>
            </Modal>

            <ViewNonComformityModel
                isOpen={viewModalOpen}
                toggle={() => setViewModalOpen(!viewModalOpen)}
                hazard={selectedViewHazard}
            />

           
            <Modal isOpen={confirmModalOpen} toggle={() => setConfirmModalOpen(false)}>
                <ModalHeader toggle={() => setConfirmModalOpen(false)}>Confirm Submission</ModalHeader>
                <ModalBody>
                    <p>Are you sure you want to submit this Non Conformity?</p>
                    <p style={{color: 'red'}}>
                        Note: Once the Non Conformity is submitted it cannot be edited.
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setConfirmModalOpen(false)}>Cancel</Button>
                    <Button color="primary" onClick={confirmGenerate}>Yes, Submit</Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={actionModalOpen}
                toggle={toggleActionModal}
                size="xl"
            >
                <ModalHeader toggle={toggleActionModal}>
                    Hazard Number: {selectedHazard?.sequenceNo}
                </ModalHeader>
                <ModalBody>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Status</th>
                            <th>Action Type</th>
                            <th>Submit Date</th>
                            <th>From</th>
                            <th>Message Description</th>
                            <th>To</th>
                            <th>Response Message</th>
                            <th>Response On</th>
                            <th>Response By</th>
                        </tr>
                        </thead>
                        <tbody>
                        {selectedHazard?.actions?.map((action, index) => (
                            <tr key={index}>
                                <td>
                                    <Button color="primary" size="sm" onClick={() => handleInitiate(action)}>
                                        Initiate
                                    </Button>
                                </td>
                                <td>{action.actionType}</td>
                                <td>{action.submittedAt}</td>
                                <td>{action.createdByUser}</td>
                                <td>{action.description}</td>
                                <td>{action.createdFor}</td>
                                <td>{action.reply}</td>
                                <td>{action.updatedAt}</td>
                                <td>{action.updatedBy}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleActionModal}>Close</Button>
                </ModalFooter>
            </Modal>

            <ActionModal
                isOpen={actionModalOpen}
                toggle={toggleActionModal}
                selectedHazard={selectedHazard}
                message={"Action For Hazard"}
                onInitiateAction={handleInitiateAction}
            />
        </div>
    );
};

export default NonconformityReport;