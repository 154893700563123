import axios from "../../../axios";
import {ADD_COUNTRY, DELETE_COUNTRY, GET_COUNTRIES, GET_COUNTRY, UPDATE_COUNTRY} from "../../types";
import {setAlert} from "../alert";
import {message} from "antd";

export const getCountries = () => async (dispatch) => {
    try {
        const res = await axios.get("/country/all");
        
        if (res.data.code === 1) {
            dispatch({type: GET_COUNTRIES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const addCountry = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/country/add", formData);
        ;
        if (res.data.code === 1) {
            dispatch({type: ADD_COUNTRY, payload: res.data.data});
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const getCountry = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/country/${id}`);
        if (res.data.code === 1) {
            dispatch({type: GET_COUNTRY, payload: res.data.data});
        }
    } catch (err) {
        // dispatch(setAlert(err.message, "danger"));
    }
};

export const updateCountry = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(`/country/update`, formData);
        ;
        if (res.data.code === 1) {
            dispatch({type: UPDATE_COUNTRY, payload: res.data.data});
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        // dispatch(setAlert(err.message, "danger"));
    }
};
export const deleteCountry = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/country/delete/${id}`);
        if (res.data.code === 1) {
            message.success(res.data.message);
            dispatch({type: DELETE_COUNTRY, payload: res.data.data});
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
