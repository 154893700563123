import {
    INFORM_FILTER_VALUES,
    INFORM_FILTER

} from "../../../types";

const initialState = {
    inFilters:[],inValues:[]
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case INFORM_FILTER_VALUES:
            return { ...state, inValues: payload };
        case INFORM_FILTER:
            return { ...state, inFilters: payload };
        default:
            return state;
    }
};

export default reducer;