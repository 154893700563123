import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table
} from "reactstrap";
import { DeleteOutlined, EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import ReactStrapDataTable from "../../CustomDatatable/ReactStrapDataTable";
import { getRisks, getRiskValues, addRisk, updateRisk, deleteRisk } from "../../../store/actions/Risk";
import { dateTimeSort, stringSorter } from "../../../utils/functions";
import fileDownload from "react-file-download";
import Select from "react-select";

const TemplateSection = ({ templateData, onTemplatesChange, initialTemplates = [] }) => {
    const [category1, setCategory1] = useState(null);
    const [category2, setCategory2] = useState(null);
    const [category3, setCategory3] = useState(null);
    const [filteredTemplates, setFilteredTemplates] = useState([]);
    const [selectedTemplates, setSelectedTemplates] = useState([]);

    const category1Options = templateData.templateCategories1.map(cat => ({ value: cat.id, label: cat.name }));
    const category2Options = templateData.templateCategories2.map(cat => ({ value: cat.id, label: cat.name }));
    const category3Options = templateData.templateCategories3.map(cat => ({ value: cat.id, label: cat.name }));

    useEffect(() => {
        filterTemplates();
    }, [category1, category2, category3]);

    useEffect(() => {
        // Initialize selectedTemplates with the provided initialTemplates
        setSelectedTemplates(initialTemplates.map(template => ({
            id: template.id,
            template: {
                value: template.id,
                label: template.name,
                ...template
            }
        })));
    }, [initialTemplates]);

    const filterTemplates = () => {
        const filtered = templateData.templates.filter(template =>
            (!category1 || template.tc1Id === category1.value) &&
            (!category2 || template.tc2Id === category2.value) &&
            (!category3 || template.tc3Id === category3.value)
        );
        setFilteredTemplates(filtered);
    };

    const handleAddTemplate = () => {
        setSelectedTemplates([...selectedTemplates, { id: Date.now(), template: null }]);
    };

    const handleTemplateChange = (id, selectedTemplate) => {
        const updatedTemplates = selectedTemplates.map(item =>
            item.id === id ? { ...item, template: selectedTemplate } : item
        );
        setSelectedTemplates(updatedTemplates);
        onTemplatesChange(updatedTemplates.map(item => item.template).filter(Boolean));
    };

    const handleRemoveTemplate = (id) => {
        const updatedTemplates = selectedTemplates.filter(item => item.id !== id);
        setSelectedTemplates(updatedTemplates);
        onTemplatesChange(updatedTemplates.map(item => item.template).filter(Boolean));
    };

    const getSelectedCategories = (template) => {
        if (!template) return '';
        const categories = [
            templateData.templateCategories1.find(cat => cat.id === template.tc1Id)?.name,
            templateData.templateCategories2.find(cat => cat.id === template.tc2Id)?.name,
            templateData.templateCategories3.find(cat => cat.id === template.tc3Id)?.name
        ].filter(Boolean);
        return categories.join(', ');
    };

    return (
        <Card>
            <CardHeader
                style={{
                    backgroundColor: "#e6f2ff",
                    borderBottom: "1px solid #b8daff",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <h2 className="card-title mb-0">Risk Template</h2>
                <Button color="primary" size="sm" onClick={handleAddTemplate}>
                    <i className="fas fa-plus"></i>
                </Button>
            </CardHeader>
            <CardBody>
                <div>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="category1">Template Category 1</Label>
                                <Select
                                    id="category1"
                                    options={category1Options}
                                    value={category1}
                                    onChange={setCategory1}
                                    isClearable
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="category2">Template Category 2</Label>
                                <Select
                                    id="category2"
                                    options={category2Options}
                                    value={category2}
                                    onChange={setCategory2}
                                    isClearable
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="category3">Template Category 3</Label>
                                <Select
                                    id="category3"
                                    options={category3Options}
                                    value={category3}
                                    onChange={setCategory3}
                                    isClearable
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr/>

                    {selectedTemplates.map((item) => (
                        <Row key={item.id} className="mb-2">
                            <Col md={5}>
                                <Select
                                    options={filteredTemplates.map(t => ({ value: t.id, label: t.name, ...t }))}
                                    value={item.template}
                                    onChange={(selected) => handleTemplateChange(item.id, selected)}
                                    isClearable
                                />
                            </Col>
                            <Col md={5}>
                                <Input
                                    type="text"
                                    value={getSelectedCategories(item.template)}
                                    readOnly
                                    placeholder="Selected Categories"
                                />
                            </Col>
                            <Col md={2}>
                                <Button color="danger" onClick={() => handleRemoveTemplate(item.id)}>
                                    <DeleteOutlined />
                                </Button>
                            </Col>
                        </Row>
                    ))}
                </div>
            </CardBody>
        </Card>
    );
};


const CommentSection = ({ comments = [], onCommentsChange }) => {
    const [internalKeys, setInternalKeys] = useState({});

    useEffect(() => {
        const initialKeys = comments.reduce((acc, comment, index) => {
            acc[`comment_${index}`] = comment;
            return acc;
        }, {});
        setInternalKeys(initialKeys);
    }, [comments]); // Added comments as a dependency

    const generateKey = () =>
        `comment_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

    const addNewRow = () => {
        const newComment = { comment: "" }; // Changed from text to comment
        const newKey = generateKey();
        setInternalKeys((prev) => ({ ...prev, [newKey]: newComment }));
        onCommentsChange([...comments, newComment]);
    };

    const updateComment = (key, value) => {
        const updatedComments = Object.values(internalKeys).map((comment) =>
            comment === internalKeys[key] ? { ...comment, comment: value } : comment // Changed from text to comment
        );
        setInternalKeys((prev) => ({
            ...prev,
            [key]: { ...prev[key], comment: value }, // Changed from text to comment
        }));
        onCommentsChange(updatedComments);
    };

    const deleteComment = (key) => {
        const filteredComments = Object.values(internalKeys).filter(
            (comment) => comment !== internalKeys[key]
        );
        setInternalKeys((prev) => {
            const newKeys = { ...prev };
            delete newKeys[key];
            return newKeys;
        });
        onCommentsChange(filteredComments);
    };

    return (
        <Card>
            <CardHeader
                style={{
                    backgroundColor: "#e6f2ff",
                    borderBottom: "1px solid #b8daff",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <h2 className="card-title mb-0">Comments</h2>
                <Button color="primary" size="sm" onClick={addNewRow}>
                    <i className="fas fa-plus"></i>
                </Button>
            </CardHeader>
            <CardBody>
                <Table>
                    <thead>
                        <tr>
                            <th>Comment</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(internalKeys).map(([key, comment]) => (
                            <tr key={key}>
                                <td>
                                    <Input
                                        type="textarea"
                                        value={comment.comment} // Changed from text to comment
                                        onChange={(e) => updateComment(key, e.target.value)}
                                        rows={3}
                                    />
                                </td>
                                <td>
                                    <Button
                                        color="danger"
                                        size="sm"
                                        onClick={() => deleteComment(key)}
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
};


const AttachmentsSection = ({ attachments = [], onAttachmentsChange }) => {
    const [internalKeys, setInternalKeys] = useState({});

    useEffect(() => {
        // Initialize internalKeys with existing attachments
        const initialKeys = attachments.reduce((acc, attachment, index) => {
            acc[`attachment_${index}`] = attachment;
            return acc;
        }, {});
        setInternalKeys(initialKeys);
    }, []);

    const generateKey = () =>
        `attachment_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

    const addNewRow = () => {
        const newAttachment = {
            filename: "",
            name: "",
            description: "",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            file: null,
            path: null,
        };
        const newKey = generateKey();
        setInternalKeys((prev) => ({ ...prev, [newKey]: newAttachment }));
        onAttachmentsChange([...attachments, newAttachment]);
    };

    const updateAttachment = (key, field, value) => {
        const updatedAttachments = Object.values(internalKeys).map((att, index) =>
            att === internalKeys[key] ? { ...att, [field]: value } : att,
        );
        setInternalKeys((prev) => ({
            ...prev,
            [key]: { ...prev[key], [field]: value },
        }));
        onAttachmentsChange(updatedAttachments);
    };

    const handleFileChange = (key, file) => {
        if (file && file.size > 5 * 1024 * 1024) {
            alert("File size should not exceed 5MB");
            return;
        }

        const updatedAttachment = {
            ...internalKeys[key],
            filename: file.name,
            name: file.name,
            file: file,
            path: null,
        };
        setInternalKeys((prev) => ({
            ...prev,
            [key]: updatedAttachment,
        }));
        const updatedAttachments = Object.values(internalKeys).map((att) =>
            att === internalKeys[key] ? updatedAttachment : att,
        );
        onAttachmentsChange(updatedAttachments);
    };

    const deleteAttachment = (key) => {
        const filteredAttachments = Object.values(internalKeys).filter(
            (att) => att !== internalKeys[key],
        );
        setInternalKeys((prev) => {
            const newKeys = { ...prev };
            delete newKeys[key];
            return newKeys;
        });
        onAttachmentsChange(filteredAttachments);
    };

    const viewAttachment = (attachment) => {
        if (attachment.file instanceof File) {
            const url = URL.createObjectURL(attachment.file);
            window.open(url, "_blank");
        } else if (attachment.fullPath || attachment.path) {
            fetch(`${encodeURIComponent(attachment.fullPath || attachment.path)}`)
                .then((response) => response.blob())
                .then((blob) => {
                    fileDownload(
                        blob,
                        attachment.fileName || attachment.filename || "download",
                    );
                })
                .catch((error) => {
                    console.error("Error downloading file:", error);
                    alert("Error downloading file. Please try again.");
                });
        }
    };

    return (
        <Card
            style={{
                border: "2px solid #ced4da",
                borderRadius: "5px",
                boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            }}
        >
            <CardHeader
                style={{
                    backgroundColor: "#e6f2ff",
                    borderBottom: "1px solid #b8daff",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <h2 className="card-title mb-0">Attachments</h2>
                <Button color="primary" size="sm" onClick={addNewRow}>
                    <i className="fas fa-plus"></i>
                </Button>
            </CardHeader>
            <CardBody>
                <Table>
                    <thead>
                        <tr>
                            <th>File Name</th>
                            <th>Description</th>
                            <th>Upload File</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(internalKeys).map(([key, attachment]) => (
                            <tr key={key}>
                                <td>{attachment.filename || attachment.name}</td>
                                <td>
                                    <Input
                                        type="text"
                                        value={attachment.description}
                                        onChange={(e) =>
                                            updateAttachment(key, "description", e.target.value)
                                        }
                                        style={{ borderColor: "black" }}
                                    />
                                </td>
                                <td>
                                    <FormGroup>
                                        <Label for={`file-${key}`} className="btn btn-secondary">
                                            {attachment.file || attachment.path
                                                ? "Change File"
                                                : "Upload File"}
                                        </Label>
                                        <Input
                                            type="file"
                                            id={`file-${key}`}
                                            onChange={(e) => handleFileChange(key, e.target.files[0])}
                                            style={{ display: "none" }}
                                        />
                                    </FormGroup>
                                </td>
                                <td>
                                    <Button
                                        color="secondary"
                                        size="sm"
                                        className="mr-2"
                                        onClick={() => viewAttachment(attachment)}
                                        disabled={!attachment.file && !attachment.path}
                                    >
                                        {attachment.fullPath || attachment.path
                                            ? "Download"
                                            : "View"}
                                    </Button>
                                    <Button
                                        color="danger"
                                        size="sm"
                                        onClick={() => deleteAttachment(key)}
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
};

const Risk = () => {
    const dispatch = useDispatch();
    const risks = useSelector((state) => state.risk.list);
    const riskValues = useSelector((state) => state.risk.values);

    const [attachments, setAttachments] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [comments, setComments] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [editingRisk, setEditingRisk] = useState(null);

    const { control, handleSubmit, reset, formState: { errors } } = useForm();

    useEffect(() => {
        dispatch(getRisks());
        dispatch(getRiskValues());
    }, [dispatch]);

    useEffect(() => {
        console.log(riskValues)
    }, [riskValues]);

    const handleEdit = (risk) => {
        
        const formattedRisk = {
            title: risk.title,
            siteId: { value: risk.siteId, label: risk.site },
        };

        if (Array.isArray(risk.files)) {
            const formattedAttachments = risk.files.map((file) => ({
              id: file.id,
              filename: file.fileName,
              name: file.fileName,
              description: file.description,
              path: file.path,
              fullPath: file.fullPath,
              createdAt: file.createdAt,
              createdBy: file.createdBy,
              updatedAt: file.updatedAt,
              updatedBy: file.updatedBy,
            }));
            setAttachments(formattedAttachments);
          } else {
            setAttachments([]);
        }
        
        if (Array.isArray(risk.comments)) {
            const formattedComments = risk.comments.map((object) => ({
              id: object.id,
              comment: object.comment,
              createdAt: object.createdAt,
              createdBy: object.createdBy,
              updatedAt: object.updatedAt,
              updatedBy: object.updatedBy,
            }));
            setComments(formattedComments);
          } else {
            setComments([]);
        }

        if (Array.isArray(risk.templates)) {
            const formattedTemplates = (risk.templates || []).map(template => ({
                id: template.id,
                name: template.name,
                tc1Id: template.tc1Id,
                tc2Id: template.tc2Id,
                tc3Id: template.tc3Id,
                createdAt: template.createdAt,
                createdBy: template.createdBy,
                updatedAt: template.updatedAt,
                updatedBy: template.updatedBy,
                templateId: template.templateId
            }));
            setTemplates(formattedTemplates)
        }
        else{
            setTemplates([])
        }
    
    
        setTemplates(risk.templates || []);
        setEditingRisk(risk);
        reset(formattedRisk);
        setModalOpen(true);
    };

    const handleDelete = async (risk) => {
        if (window.confirm("Are you sure you want to delete this risk?")) {
            await dispatch(deleteRisk(risk.id));
            dispatch(getRisks());
        }
    };

    const onFormSubmit = async (data) => {
        const formData = new FormData();
    
        formData.append('title', data.title);
        formData.append('siteId', data.siteId.value);
    
        // Handle attachments
        if (Array.isArray(attachments)) {
            const filesMetadata = attachments.map((attachment) => ({
                id: attachment.id || null,
                fileName: attachment.filename || attachment.name || "",
                description: attachment.description || null,
                path: attachment.path || null,
                createdAt: attachment.createdAt || null,
                createdBy: attachment.createdBy || null,
                updatedBy: attachment.updatedBy || null,
                updatedAt: attachment.updatedAt || null,
            }));
            formData.append("files", JSON.stringify(filesMetadata));
    
            attachments.forEach((attachment, index) => {
                if (attachment.file instanceof File) {
                    formData.append(
                        `files[${index}]`,
                        attachment.file,
                        attachment.filename || attachment.name,
                    );
                }
            });
        } else {
            formData.append("files", JSON.stringify([]));
        }
    
        // Handle comments
        if (Array.isArray(comments)) {
            const commentsMetaData = comments.map((comment) => ({
                id: comment.id || null,
                comment: comment.comment || null,
                createdAt: comment.createdAt || null,
                createdBy: comment.createdBy || null,
                updatedBy: comment.updatedBy || null,
                updatedAt: comment.updatedAt || null,
            }));
            formData.append("comments", JSON.stringify(commentsMetaData));
        } else {
            formData.append("comments", JSON.stringify([]));
        }
    
        // Handle templates
        if (Array.isArray(templates)) {
            const templatesMetaData = templates.map((template) => ({
                id: null,
                templateId: template.value || null,
                name: template.label || null,
                tc1Id: template.tc1Id || null,
                tc2Id: template.tc2Id || null,
                tc3Id: template.tc3Id || null,
                createdAt: template.createdAt || null,
                createdBy: template.createdBy || null,
                updatedBy: template.updatedBy || null,
                updatedAt: template.updatedAt || null,
            }));
            formData.append("templates", JSON.stringify(templatesMetaData));
        } else {
            formData.append("templates", JSON.stringify([]));
        }

        //console.log(comments)
    
        console.log("FormData contents:");
        for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }
    
        let result;
        if (editingRisk) {
            formData.append('id', editingRisk.id);
            formData.append('fileId', editingRisk.fileId);
            formData.append('commentId', editingRisk.commentId);
            formData.append('templateId', editingRisk.templateId);
            result = await dispatch(updateRisk(formData));
        } else {
            result = await dispatch(addRisk(formData));
        }
    
        if (result) {
            handleCloseModal();
            dispatch(getRisks());
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setEditingRisk(null);
        reset();
        setAttachments([]);
        setComments([]);
    };

    const handleAddNew = () => {
        setEditingRisk(null);
        reset({ title: '', siteId: null, status: true });
        setAttachments([]);
        setComments([]);
        setModalOpen(true);
    };

    const columns = [
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: dateTimeSort("createdAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Risk",
            dataIndex: "title",
            key: "title",
            sorter: stringSorter("name"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Site",
            dataIndex: "site",
            key: "site",
            sorter: stringSorter("siteName"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (status) => status ? 'Active' : 'Inactive',
            sorter: (a, b) => a.status - b.status,
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
                <>
                    <Button color="link" onClick={() => handleEdit(record)}>
                        <EditOutlined />
                    </Button>
                    <Button color="link" onClick={() => handleDelete(record)}>
                        <DeleteOutlined />
                    </Button>
                </>
            ),
        },
    ];

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">Manage Risks</h5>
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" onClick={handleAddNew}>
                                <PlusCircleFilled /> Add New
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactStrapDataTable
                        dataSource={risks}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} size="xl" backdrop="static">
                <ModalHeader toggle={handleCloseModal}>
                    {editingRisk ? 'Edit Risk' : 'Add Risk'}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <ModalBody>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="title">Title</Label>
                                    <Controller
                                        name="title"
                                        control={control}
                                        rules={{ required: "Title is required" }}
                                        render={({ field }) => <Input {...field} id="title" />}
                                    />
                                    {errors.title && <span className="text-danger">{errors.title.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="siteId">Site</Label>
                                    <Controller
                                        name="siteId"
                                        control={control}
                                        rules={{ required: "Site is required" }}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                options={riskValues.sites.map(site => ({
                                                    value: site.id,
                                                    label: site.name
                                                }))}
                                                placeholder="Select Site"
                                                isClearable
                                            />
                                        )}
                                    />
                                    {errors.siteId && <span className="text-danger">{errors.siteId.message}</span>}
                                </FormGroup>
                            </Col>
                        </Row>

                        <AttachmentsSection
                            attachments={attachments}
                            onAttachmentsChange={setAttachments}
                        />
                        <CommentSection
                            comments={comments}
                            onCommentsChange={setComments}
                        />
                        <TemplateSection
                            templateData={riskValues}
                            onTemplatesChange={setTemplates}
                            initialTemplates={templates}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {editingRisk ? 'Update' : 'Save'}
                        </Button>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
};

export default Risk;