import React, {useEffect, useState, useMemo, useCallback} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
    Alert
} from "reactstrap";
import {
    DeleteOutlined,
    EditOutlined,
    PlusCircleFilled,
    SettingOutlined,
    DashboardOutlined,
} from "@ant-design/icons";
import ReactStrapDataTable from "../../CustomDatatable/ReactStrapDataTable";
import {
    getDashboards,
    addDashboard,
    updateDashboard,
    deleteDashboard,
    getDashboardValues,
    saveDashboardWidget
} from "../../../store/actions/userdashboard";
import { dateTimeSort, stringSorter } from "../../../utils/functions";

import Select from 'react-select'
import {showSuccessDialog} from "../../../utils/dialogs";
import ReactApexChart from 'react-apexcharts';
import {getIncidentReportCategoryValues} from "../../../store/actions/Incident/IncidentReport/incidentReportCategory";



const FilterModal = ({ isOpen, toggle, onApplyFilters, initialFilters = {} }) => {
    const [selectedItems, setSelectedItems] = useState({
        actualHazards: [],
        potentialHazards: [],
        countries: [],
        states: [],
        businessUnits: [],
        projectClients: [],
        projects: [],
        sites: [],
        l3: [],
    });

    const { control, handleSubmit, reset, watch } = useForm({
        defaultValues: {
            dateFrom: initialFilters.dateFrom || getFirstDayOfMonth(),
            dateTo: initialFilters.dateTo || getLastDayOfMonth(),
        },
    });

    const dateFrom = watch("dateFrom");

    useEffect(() => {
        // Reset selected items when the modal opens
        if (isOpen) {
            setSelectedItems(initialFilters.selectedItems || {
                actualHazards: [],
                potentialHazards: [],
                countries: [],
                states: [],
                businessUnits: [],
                projectClients: [],
                projects: [],
                sites: [],
                l3: [],
            });
        }
    }, [isOpen, initialFilters]);

    const handleResetFilters = () => {
        reset();
        setSelectedItems({
            actualHazards: [],
            potentialHazards: [],
            countries: [],
            states: [],
            businessUnits: [],
            projectClients: [],
            projects: [],
            sites: [],
            l3: [],
        });
    };

    const handleItemSelect = (field, selectedOptions) => {
        setSelectedItems((prevItems) => {
            const newItems = {
                ...prevItems,
                [field]: selectedOptions,
            };

            // Clear child selections based on parent-child relationships
            switch (field) {
                case 'countries':
                    if (selectedOptions.length === 0) {
                        newItems.businessUnits = [];
                        newItems.projectClients = [];
                        newItems.projects = [];
                        newItems.sites = [];
                        newItems.l3 = [];
                    }
                    break;
                case 'businessUnits':
                    if (selectedOptions.length === 0) {
                        newItems.projectClients = [];
                        newItems.projects = [];
                        newItems.sites = [];
                        newItems.l3 = [];
                    }
                    break;
                case 'projectClients':
                    if (selectedOptions.length === 0) {
                        newItems.projects = [];
                        newItems.sites = [];
                        newItems.l3 = [];
                    }
                    break;
                case 'projects':
                    if (selectedOptions.length === 0) {
                        newItems.sites = [];
                        newItems.l3 = [];
                    }
                    break;
                case 'sites':
                    if (selectedOptions.length === 0) {
                        newItems.l3 = [];
                    }
                    break;
            }

            return newItems;
        });
    };

    const isFieldEnabled = (field) => {
        switch (field) {
            case 'businessUnits':
                return selectedItems.countries.length > 0;
            case 'projectClients':
                return selectedItems.businessUnits.length > 0;
            case 'projects':
                return selectedItems.projectClients.length > 0;
            case 'sites':
                return selectedItems.projects.length > 0;
            case 'l3':
                return selectedItems.sites.length > 0;
            default:
                return true;
        }
    };

    const onSubmit = (data) => {
        const filters = {
            dateFrom: data.dateFrom,
            dateTo: data.dateTo,
            selectedItems,
        };
        onApplyFilters(filters);
        toggle();
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="xl" backdrop="static">
            <ModalHeader toggle={toggle}>Filter</ModalHeader>
            <ModalBody>
                <div className="d-flex justify-content-end mb-3">
                    <Button color="secondary" onClick={handleResetFilters}>
                        Reset Filters
                    </Button>
                </div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <h5>Date Range</h5>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="dateFrom">Date From</Label>
                                <Controller
                                    name="dateFrom"
                                    control={control}
                                    rules={{ required: "Date From is required" }}
                                    render={({ field }) => (
                                        <Input {...field} type="date" id="dateFrom" />
                                    )}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="dateTo">Date To</Label>
                                <Controller
                                    name="dateTo"
                                    control={control}
                                    rules={{
                                        required: "Date To is required",
                                        validate: (value) =>
                                            new Date(value) > new Date(dateFrom) ||
                                            "Date To must be greater than Date From",
                                    }}
                                    render={({ field }) => (
                                        <Input {...field} type="date" id="dateTo" />
                                    )}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <h5>Hazards</h5>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Actual Hazards</Label>
                                <Select
                                    options={[]} // Replace with your actual options
                                    value={selectedItems.actualHazards}
                                    onChange={(options) => handleItemSelect("actualHazards", options)}
                                    placeholder="Select Actual Hazards..."
                                    isMulti
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Potential Hazards</Label>
                                <Select
                                    options={[]} // Replace with your actual options
                                    value={selectedItems.potentialHazards}
                                    onChange={(options) => handleItemSelect("potentialHazards", options)}
                                    placeholder="Select Potential Hazards..."
                                    isMulti
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <h5>Location</h5>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Countries</Label>
                                <Select
                                    options={[]} // Replace with your actual options
                                    value={selectedItems.countries}
                                    onChange={(options) => handleItemSelect("countries", options)}
                                    placeholder="Select countries..."
                                    isMulti
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>States</Label>
                                <Select
                                    options={[]} // Replace with your actual options
                                    value={selectedItems.states}
                                    onChange={(options) => handleItemSelect("states", options)}
                                    placeholder="Select states..."
                                    isMulti
                                    isDisabled={selectedItems.countries.length === 0}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Business Units</Label>
                                <Select
                                    options={[]} // Replace with your actual options
                                    value={selectedItems.businessUnits}
                                    onChange={(options) => handleItemSelect("businessUnits", options)}
                                    placeholder="Select business units..."
                                    isMulti
                                    isDisabled={!isFieldEnabled('businessUnits')}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Project Clients</Label>
                                <Select
                                    options={[]} // Replace with your actual options
                                    value={selectedItems.projectClients}
                                    onChange={(options) => handleItemSelect("projectClients", options)}
                                    placeholder="Select project clients..."
                                    isMulti
                                    isDisabled={!isFieldEnabled('projectClients')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Projects</Label>
                                <Select
                                    options={[]} // Replace with your actual options
                                    value={selectedItems.projects}
                                    onChange={(options) => handleItemSelect("projects", options)}
                                    placeholder="Select projects..."
                                    isMulti
                                    isDisabled={!isFieldEnabled('projects')}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Sites</Label>
                                <Select
                                    options={[]} // Replace with your actual options
                                    value={selectedItems.sites}
                                    onChange={(options) => handleItemSelect("sites", options)}
                                    placeholder="Select sites..."
                                    isMulti
                                    isDisabled={!isFieldEnabled('sites')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>L3</Label>
                                <Select
                                    options={[]} // Replace with your actual options
                                    value={selectedItems.l3}
                                    onChange={(options) => handleItemSelect("l3", options)}
                                    placeholder="Select L3..."
                                    isMulti
                                    isDisabled={!isFieldEnabled('l3')}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSubmit(onSubmit)}>
                    Apply Filters
                </Button>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const getFirstDayOfMonth = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1).toISOString().split('T')[0];
};

const getLastDayOfMonth = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString().split('T')[0];
};


const DashboardView = ({ isOpen, toggle, dashboardData, dashboardValues }) => {
    const dispatch = useDispatch();
    const [charts, setCharts] = useState([]);
    const [error, setError] = useState(null);


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filters, setFilters] = useState({});

    const toggleModal = () => setIsModalOpen(!isModalOpen);

    const handleApplyFilters = (newFilters) => {
        setFilters(newFilters);
        // Apply the filters to your data or make an API call
    };

    useEffect(() => {
        dispatch(getIncidentReportCategoryValues());
    }, [dispatch]);


    const chartTypes = useMemo(() => [
        { value: 'line', label: 'Line' },
        { value: 'area', label: 'Area' },
        { value: 'bar', label: 'Bar' },
        { value: 'pie', label: 'Pie' },
        { value: 'donut', label: 'Donut' },
        { value: 'radialBar', label: 'Radial Bar' },
    ], []);

    const findChartInfo = useCallback((chartId) => {
        if (!dashboardValues || !dashboardValues.incidentDashboardCharts) {
            return null;
        }
        for (const category of dashboardValues.incidentDashboardCharts) {
            const chart = category.charts.find(c => c.id === chartId);
            if (chart) {
                return { ...chart, category: category.label };
            }
        }
        return null;
    }, [dashboardValues]);

    useEffect(() => {
        try {
            if (dashboardData && dashboardData.criteria) {
                const parsedCriteria = JSON.parse(dashboardData.criteria);
                if (Array.isArray(parsedCriteria)) {
                    const newCharts = parsedCriteria.map(criteriaItem => {
                        const chartInfo = findChartInfo(criteriaItem.chart);
                        return {
                            ...criteriaItem,
                            chartInfo,
                            chartType: chartTypes[Math.floor(Math.random() * chartTypes.length)].value,
                            filterCriteria: {}
                        };
                    }).filter(chart => chart.chartInfo !== null);
                    setCharts(newCharts);
                    setError(null);
                } else {
                    throw new Error('Invalid criteria format');
                }
            } else {
                setCharts([]);
                setError('No dashboard data available');
            }
        } catch (err) {
            console.error('Error processing dashboard data:', err);
            setCharts([]);
            setError('Error processing dashboard data');
        }
    }, [dashboardData, findChartInfo, chartTypes]);

    const handleChartTypeChange = useCallback((chartId, selectedOption) => {
        if (selectedOption) {
            setCharts(prevCharts => prevCharts.map(chart =>
                chart.chart === chartId ? { ...chart, chartType: selectedOption.value } : chart
            ));
        }
    }, []);

    const handleFilterChange = useCallback((chartId, newFilter) => {
        setCharts(prevCharts => prevCharts.map(chart =>
            chart.chart === chartId ? { ...chart, filterCriteria: newFilter } : chart
        ));

    }, []);

    const renderChart = useCallback((chart) => {
        if (!chart || !chart.chartInfo) {
            return <Alert color="warning">Unable to render chart: Missing chart information</Alert>;
        }

        try {
            const options = {
                chart: {
                    id: chart.chart,
                    type: chart.chartType
                },
                xaxis: {
                    categories: ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5']
                },
                title: {
                    text: chart.chartInfo.label,
                    align: 'center'
                },
                noData: {
                    text: 'No data available',
                    align: 'center',
                    verticalAlign: 'middle',
                    style: {
                        fontSize: '14px'
                    }
                }
            };

            let series = [{
                name: chart.chartInfo.label,
                data: [30, 40, 35, 50, 49]
            }];

            if (['pie', 'donut', 'radialBar', 'polarArea'].includes(chart.chartType)) {
                options.labels = options.xaxis.categories;
                delete options.xaxis;
                series = series[0].data;
            }

            return (
                <ReactApexChart
                    options={options}
                    series={series}
                    type={chart.chartType}
                    height={350}
                />
            );
        } catch (err) {
            console.error('Error rendering chart:', err);
            return (
                <Alert color="danger">
                    Error rendering chart. Please try a different chart type or check the data.
                </Alert>
            );
        }
    }, []);

    return (
        <>
        <Modal isOpen={isOpen} toggle={toggle} size="xl" fullscreen>
            <ModalHeader toggle={toggle}>
                Dashboard: {dashboardData?.name || 'Untitled'}
            </ModalHeader>
            <ModalBody>
                {error && <Alert color="danger">{error}</Alert>}
                {!error && charts.length === 0 && <Alert color="info">No charts available</Alert>}
                <Row>
                    {charts.map((chart) => (
                        <Col md={6} key={chart.chart}>
                            <Card className="mb-4">
                                <CardHeader>
                                    <Row className="align-items-center">
                                        <Col>
                                            <h5 className="mb-0">{chart.chartInfo?.label || 'Untitled Chart'} ({chart.chartInfo?.category || 'Uncategorized'})</h5>
                                        </Col>
                                        <Col xs="auto" style={{ minWidth: '200px' }}>
                                            <Select
                                                value={chartTypes.find(type => type.value === chart.chartType)}
                                                onChange={(selectedOption) => handleChartTypeChange(chart.chart, selectedOption)}
                                                options={chartTypes}
                                                isSearchable={true}
                                                placeholder="Select Chart Type"
                                            />
                                        </Col>
                                        <Col xs="auto">
                                            <Button color="link" onClick={toggleModal}>
                                                Filter
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {renderChart(chart)}
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Close</Button>
            </ModalFooter>
        </Modal>
            <FilterModal
                isOpen={isModalOpen}
                toggle={toggleModal}
                onApplyFilters={handleApplyFilters}
                initialFilters={filters}
            />
       </>
    );
};



const UserDashboard = () => {
    const dispatch = useDispatch();
    const dashboards = useSelector((state) => state.userdashboard.list);
    const dashboardValues = useSelector((state) => state.userdashboard.values);
    const loading = useSelector((state) => state.userdashboard.loading);

    const [modalOpen, setModalOpen] = useState(false);
    const [widgetModalOpen, setWidgetModalOpen] = useState(false);
    const [editingDashboard, setEditingDashboard] = useState(null);
    const [selectedCharts, setSelectedCharts] = useState({});

    const [openDashboardModal, setOpenDashboardModal] = useState(false);

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        dispatch(getDashboards());
        dispatch(getDashboardValues());
    }, [dispatch]);

    useEffect(()=>{
        console.log(dashboardValues);
    },[dashboardValues])



    const handleEdit = (dashboard) => {
        setEditingDashboard(dashboard);
        reset(dashboard);
        setModalOpen(true);
    };

    const handleDelete = async (dashboard) => {
        if (window.confirm("Are you sure you want to delete this dashboard?")) {
            await dispatch(deleteDashboard(dashboard.id));
            dispatch(getDashboards());
        }
    };

    const handleManageWidgets = (dashboard) => {
        setEditingDashboard(dashboard);

        const parsedCriteria = JSON.parse(dashboard.criteria);

        const initialSelection = {};
        dashboardValues?.incidentDashboardCharts?.forEach(category => {
            initialSelection[category.id] = [];
        });

        parsedCriteria.forEach(item => {
            const category = dashboardValues?.incidentDashboardCharts?.find(cat =>
                cat.charts.some(chart => chart.id === item.chart)
            );
            if (category) {
                initialSelection[category.id].push(item.chart);
            }
        });
        setSelectedCharts(initialSelection);
        setWidgetModalOpen(true);
        dispatch(getDashboards());
    };

    const onFormSubmit = (data) => {
        if (editingDashboard) {
            const payload = {
                id: editingDashboard.id,
                name: data.name,
                description: data.description,
            }
            dispatch(updateDashboard(payload));
        } else {
            dispatch(addDashboard(data));
        }
        handleCloseModal();
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setEditingDashboard(null);
        reset();
    };

    const handleCloseWidgetModal = () => {
        setWidgetModalOpen(false);
        setEditingDashboard(null);
        setSelectedCharts({});
    };

    const handleAddNew = () => {
        setEditingDashboard(null);
        reset({ name: '', description: '' });
        setModalOpen(true);
    };

    const handleChartSelect = (categoryId, selectedOptions) => {
        setSelectedCharts(prev => ({
            ...prev,
            [categoryId]: selectedOptions.map(option => option.value)
        }));
    };

    const handleWidgetSubmit = async () => {
        const transformedCharts = Object.values(selectedCharts)
            .flat()
            .map(chartId => ({
                chart: chartId,
                criteria: {}
            }));

        const finalObject = JSON.stringify(transformedCharts, null, 2)
        const object = {
            id: parseInt(editingDashboard.id),
            criteria: finalObject
        }
        const result = await dispatch(saveDashboardWidget(object));
        if(result.success)
        {
            await showSuccessDialog(result.message)
            handleCloseWidgetModal();
        }
        handleCloseWidgetModal();
    };
    const columns = [
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: dateTimeSort("createdAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: stringSorter("name"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            sorter: stringSorter("description"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
                <>
                    <Button color="link" onClick={() => handleEdit(record)}>
                        <EditOutlined />
                    </Button>
                    <Button color="link" onClick={() => handleDelete(record)}>
                        <DeleteOutlined />
                    </Button>
                    <Button color="link" onClick={() => handleManageWidgets(record)}>
                        <SettingOutlined /> Manage Widgets
                    </Button>
                    <Button color="link" onClick={() => handleViewDashboard(record)}>
                        <DashboardOutlined /> View Dashboard
                    </Button>
                </>
            )
        },
    ];

    const handleViewDashboard = (record) => {
        console.log(record)
        console.log(JSON.parse(record.criteria))
        setOpenDashboardModal(true)
        setEditingDashboard(record);

    }

    const handleCloseDashboardModal = () => {
        setOpenDashboardModal(false);
        setEditingDashboard(null);
        setSelectedCharts({});
    };

    const renderWidgetOptions = () => {
        return dashboardValues?.incidentDashboardCharts?.map(category => (
            <Row key={category.id} className="mb-3">
                <Col md={4}>
                    <strong>{category.label}</strong>
                </Col>
                <Col md={8}>
                    <Select
                        isMulti
                        value={category.charts
                            .filter(chart => selectedCharts[category.id]?.includes(chart.id))
                            .map(chart => ({ value: chart.id, label: chart.label }))}
                        onChange={(selectedOptions) => handleChartSelect(category.id, selectedOptions)}
                        options={category.charts.map(chart => ({
                            value: chart.id,
                            label: `${chart.label} - (${chart.info})`
                        }))}
                        placeholder={`Select ${category.label} charts`}
                    />
                </Col>
            </Row>
        ));
    };

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">Manage User Dashboard</h5>
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" onClick={handleAddNew}>
                                <PlusCircleFilled /> Add New
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactStrapDataTable
                        dataSource={dashboards ?? []}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} backdrop="static">
                <ModalHeader toggle={handleCloseModal}>
                    {editingDashboard ? 'Edit Dashboard' : 'Add Dashboard'}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="name">Dashboard Name</Label>
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: "Dashboard Name is required" }}
                                render={({ field }) => <Input {...field} id="name" />}
                            />
                            {errors.name && <span className="text-danger">{errors.name.message}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="description">Dashboard Description</Label>
                            <Controller
                                name="description"
                                control={control}
                                rules={{ required: "Dashboard Description is required" }}
                                render={({ field }) => <Input {...field} id="description" type="textarea" />}
                            />
                            {errors.description && <span className="text-danger">{errors.description.message}</span>}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {editingDashboard ? 'Update' : 'Save'}
                        </Button>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
            <Modal isOpen={widgetModalOpen} toggle={handleCloseWidgetModal} backdrop="static" size="xl" fullscreen>
                <ModalHeader toggle={handleCloseWidgetModal}>
                    Manage Widgets for {editingDashboard?.name}
                </ModalHeader>
                <ModalBody>
                    {loading ? (
                        <div className="text-center">
                            <Spinner color="primary" />
                            <p>Loading widget options...</p>
                        </div>
                    ) : dashboardValues?.incidentDashboardCharts ? (
                        <div>
                            <p>Please select the widgets you would like to add to the {editingDashboard?.name} dashboard.</p>
                            {renderWidgetOptions()}
                        </div>
                    ) : (
                        <p>No widget options available. Please try again later.</p>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleWidgetSubmit} disabled={loading || !dashboardValues?.incidentDashboardCharts}>
                        Save Widgets
                    </Button>
                    <Button color="secondary" onClick={handleCloseWidgetModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            {/*<Modal isOpen={openDashboardModal} toggle={handleCloseDashboardModal} backdrop="static" size="xl" fullscreen>*/}
            {/*    <ModalHeader toggle={handleCloseDashboardModal}>*/}
            {/*        Dashboard for {editingDashboard?.name}*/}
            {/*    </ModalHeader>*/}
            {/*    <ModalBody>*/}

            {/*    </ModalBody>*/}
            {/*    <ModalFooter>*/}

            {/*    </ModalFooter>*/}
            {/*</Modal>*/}
            <DashboardView
                isOpen={openDashboardModal}
                toggle={handleCloseDashboardModal}
                dashboardData={editingDashboard}
                dashboardValues={dashboardValues}
            />
        </div>
    );
};

export default UserDashboard;

