module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID:
      "23144678283-oek7ncjmmrgkgmi2i56sc411gp71a8sp.apps.googleusercontent.com",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  //API_URL: "http://172.16.5.52:8001/api",
  //API_URL: "http://172.16.5.56:8001/api",
  //API_URL: "http://172.20.10.14:8001/api",
  API_URL: "https://hsse.meinhardtepcm.com/hsseapi/api/",
  //API_URL:"http://192.168.1.12:8001/api"
};
