import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {dateTimeSort, stringSorter} from "../../utils/functions";
import DataTable from "../../components/CustomDatatable";
import {
    Card,
    Space,
    Typography,
    Breadcrumb,
    Row,
    Col,
    Image
} from 'antd';
import {HomeOutlined} from '@ant-design/icons';
import {getVisits} from "../../store/actions/visit";
import {enhanceColumnsWithFilters} from "../../components/ColumnFilters";

const {Title} = Typography;


const Visit = () => {
    document.title = "HSSE Meinhardt EPCM | Vist";
    const dispatch = useDispatch();
    const visits = useSelector((state) => state.visit.list);

    const [modalOpen, setModalOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);


    let columns = [
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            sorter: dateTimeSort("date"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Remarks",
            dataIndex: "remarks",
            key: "remarks",
            sorter: stringSorter("remarks"),
        },
        {
            title: "Salesman",
            dataIndex: "salesman",
            key: "salesman",
            sorter: stringSorter("salesman"),
        },
        {
            title: "Image",
            key: "image",
            render: (record) => (
                <Image
                    width={75}
                    src={record.imageFullUrl}
                    style={{ cursor: 'pointer' }}
                    preview={{
                        src: record.imageFullUrl,
                        modalProps: {
                            zIndex: 999999999999999999,
                        },
                    }}
                />
            ),
        },
    ];
    columns = enhanceColumnsWithFilters(columns);

    useEffect(() => {
        dispatch(getVisits());
    }, [dispatch]);

    return (
        <div className="page-content">
            <Breadcrumb style={{marginBottom: '16px'}}>
                <Breadcrumb.Item href="/">
                    <HomeOutlined/>
                </Breadcrumb.Item>
                <Breadcrumb.Item>HSSE Meinhardt EPCM</Breadcrumb.Item>
                <Breadcrumb.Item>Salesman Visits</Breadcrumb.Item>
            </Breadcrumb>

            <Card>
                <Space direction="vertical" size="middle" style={{width: '100%'}}>
                    <Row justify="space-between" align="middle">
                        <Col>
                            <Title level={2} style={{margin: 0}}>Salesman Visits</Title>
                        </Col>
                        <Col>
                            {/*<Button type="primary" icon={<PlusOutlined />} onClick={() => setModalOpen(true)}>
                                Add Salesman
                            </Button>*/}
                        </Col>
                    </Row>

                    <DataTable
                        dataSource={visits.map((item, index) => ({
                            ...item,
                            key: index,
                        }))}
                        columns={columns}
                    />
                </Space>
            </Card>
        </div>
    );
}

export default Visit;