import axios from "../../../../axios";
import { setAlert } from "../../alert";
import { ADD_INCR, GET_INCRS, GET_SINGLE_INCR, UPDATE_INCR, INCR_VALUES, DELETE_INCR, GET_USER_INCR, GENERATE_INCR } from "../../../types";

// Get all INCRValues
export const getINCRValues = () => async (dispatch) => {
    try {
        const res = await axios.get("/incr/values");
        if (res.data.code === 1) {
            dispatch({ type: INCR_VALUES, payload: res.data.data });
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

// Submit INCR
export const submitINCR = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(`/incr/submit`, formData);
        if (res.data.code === 1) {
            dispatch({ type: GENERATE_INCR, payload: res.data.data });
            return { message: "INCR submitted successfully", success: true };
        }
        return { message: res.data.message, success: false };
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return { message: err.message, success: false };
    }
};

// Submit Action for INCR
export const submitActionForINCR = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/incr/action/update", formData);
        if (res.data.code === 1) {
            return { message: res.data.message, success: true };
        } else {
            return { message: res.data.message, success: false };
        }
    } catch (err) {
        return { message: err.response?.data?.message || "Contact Admin", success: false };
    }
};

// Get all INCRs
export const getINCRs = (accessModuleId) => async (dispatch) => {
    try {
        const object = {
            moduleId: accessModuleId
        };
        const res = await axios.post("/incr/user", object);
        if (res.data.code === 1) {
            dispatch({ type: GET_USER_INCR, payload: res.data.data });
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

// Get a single INCR
export const getSingleINCR = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/incr/${id}`);
        if (res.data.code === 1) {
            dispatch({ type: GET_SINGLE_INCR, payload: res.data.data });
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

// Add a new INCR
export const addINCR = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/incr/add", formData, config);
        if (res.data.code === 1) {;
            return { success: true, message: res.data.message };
        } else {
            return { success: false, message: res.data.message };
        }
    } catch (err) {

        return { success: false, message: err.response?.data?.message || "Contact Admin" };
    }
};

// Update an INCR
export const updateINCR = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/incr/update", formData, config);
        if (res.data.code === 1) {
            return { success: true, message: res.data.message };
        } else {
            return { success: false, message: res.data.message };
        }
    } catch (err) {
        console.error("Error in updateINCR:", err);
        return { success: false, message: err.response?.data?.message || "Contact Admin" };
    }
};

// Delete an INCR
export const deleteINCR = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/incr/delete/${id}`);
        if (res.data.code === 1) {
            dispatch({ type: DELETE_INCR, payload: id });
            return { success: true, message: res.data.message };
        }
    } catch (err) {
        return { success: false, message: 'Contact Admin' };
    }
};