import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import { DeleteOutlined, EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import ReactStrapDataTable from "../../../CustomDatatable/ReactStrapDataTable";
import { getProjectClients, addProjectClient, updateProjectClient, deleteProjectClient, getProjectClient, getProjectClientValues } from "../../../../store/actions/business/projectclient";
import { dateTimeSort, stringSorter } from "../../../../utils/functions";
import Select from 'react-select';

const ProjectClient = () => {
    const dispatch = useDispatch();
    const projectClients = useSelector((state) => state.projectclient.list);
    const singleProjectClient = useSelector((state) => state.projectclient.single);
    const projectClientValues = useSelector((state) => state.projectclient.values);

    const [modalOpen, setModalOpen] = useState(false);
    const [editingProjectClient, setEditingProjectClient] = useState(null);

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        dispatch(getProjectClients());
        dispatch(getProjectClientValues());
    }, [dispatch]);

    useEffect(() => {
        console.log(projectClientValues)
    }, [projectClientValues]);

    const handleEdit = (projectclient) => {
        console.log(projectclient);
        dispatch(getProjectClient(projectclient.id));
    };

    useEffect(() => {
        console.log("singleProjectClient:", singleProjectClient);
        console.log("projectClientValues:", projectClientValues);

        if (singleProjectClient && projectClientValues.countries) {
            console.log("Preparing to open edit modal");

            const selectedCountries = projectClientValues.countries.filter(country =>
                singleProjectClient.coId.includes(country.id)
            ).map(country => ({
                value: country.id,
                label: country.name
            }));

            console.log("Selected countries:", selectedCountries);

            reset({
                name: singleProjectClient.name,
                code: singleProjectClient.code,
                countries: selectedCountries,
            });

            setEditingProjectClient(singleProjectClient);

            setModalOpen(true);
            console.log(modalOpen)
        }
        else {
            console.log("else")
        }

    }, [singleProjectClient, reset, projectClientValues.countries]);

    const handleDelete = async (projectclient) => {
        if (window.confirm("Are you sure you want to delete this Project Client?")) {
            await dispatch(deleteProjectClient(projectclient.id));
            dispatch(getProjectClients());
        }
    };

    const onFormSubmit = (data) => {
        const formData = {
            name:data.name,
            code:data.code,
            coId: JSON.stringify(data.countries.map(country => country.value)),
        };
        console.log(formData);
        if (editingProjectClient) {
            dispatch(updateProjectClient({ ...formData, id: editingProjectClient.id }));
        } else {
            dispatch(addProjectClient(formData));
        }
        handleCloseModal();
        dispatch(getProjectClients());
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setEditingProjectClient(null);
        reset();
    };

    const handleAddNew = () => {
        setEditingProjectClient(null);
        reset({ name: '', code: '', countries: [] });
        setModalOpen(true);
    };

    const columns = [
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: dateTimeSort("createdAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: stringSorter("name"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Code",
            dataIndex: "code",
            key: "code",
            sorter: stringSorter("code"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Countries",
            dataIndex: "countries",
            key: "countries",
            sorter: stringSorter("countries"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
                <>
                    <Button color="link" onClick={() => handleEdit(record)}>
                        <EditOutlined />
                    </Button>
                    <Button color="link" onClick={() => handleDelete(record)}>
                        <DeleteOutlined />
                    </Button>
                </>
            ),
        },
    ];

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">Manage Project Clients</h5>
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" onClick={handleAddNew}>
                                <PlusCircleFilled /> Add New
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactStrapDataTable
                        dataSource={projectClients}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} backdrop="static">
                <ModalHeader toggle={handleCloseModal}>
                    {editingProjectClient ? 'Edit Project Client' : 'Add Project Client'}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: "Name is required",
                                    pattern: {
                                        value: /\S/,
                                        message: "Blank space is not allowed.",
                                    },
                                }}
                                render={({ field }) => <Input {...field} id="name" />}
                            />
                            {errors.name && <span className="text-danger">{errors.name.message}</span>}
                        </FormGroup>
                        <FormGroup>
                            <Label for="code">Code</Label>
                            <Controller
                                name="code"
                                control={control}
                                rules={{
                                    required: "Code is required",
                                    pattern: {
                                        value: /\S/,
                                        message: "Blank space is not allowed.",
                                    },
                                }}
                                render={({ field }) => <Input {...field} id="code" />}
                            />
                            {errors.code && <span className="text-danger">{errors.code.message}</span>}
                        </FormGroup>
                        <FormGroup>
                            <Label for="countries">Countries</Label>
                            <Controller
                                name="countries"
                                control={control}
                                rules={{ required: "At least one country is required" }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        isMulti
                                        options={projectClientValues.countries ? projectClientValues.countries.map(country => ({
                                            value: country.id,
                                            label: country.name
                                        })) : []}
                                    />
                                )}
                            />
                            {errors.countries && <span className="text-danger">{errors.countries.message}</span>}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {editingProjectClient ? 'Update' : 'Save'}
                        </Button>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
};

export default ProjectClient;