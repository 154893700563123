import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import { DeleteOutlined, EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import { getAllAssignedCourses, assignCourse, getCourses } from "../../../store/actions/competancy/courses";
import Select from 'react-select';
import { dateTimeSort, stringSorter } from "../../../utils/functions";
import { getUsers } from "../../../store/actions/user";
import ReactStrapDataTable from "../../CustomDatatable/ReactStrapDataTable";

const AssignedCourses = () => {
    const dispatch = useDispatch();
    const assignedCourses = useSelector((state) => state.course.assignedCourses);
    const courses = useSelector((state) => state.course.list);
    const users = useSelector((state) => state.users.list);

    const [modalOpen, setModalOpen] = useState(false);
    const [editedAssignCourse, setEditedAssignCourse] = useState(null);

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        dispatch(getAllAssignedCourses());
        dispatch(getCourses());
        dispatch(getUsers());
    }, [dispatch]);

    const handleEdit = (object) => {
        setEditedAssignCourse(object);
        const selectedUsers = object.users.split(', ').map(name => {
            const userId = object.userId[object.users.split(', ').indexOf(name)];
            return { id: userId, name: name };
        });

        reset({
            courseId: { value: object.courseId, label: object.course },
            users: selectedUsers.map(user => ({ value: user.id, label: user.name }))
        });
        setModalOpen(true);
    };

    const handleDelete = async (object) => {
        if (window.confirm("Are you sure you want to delete the Assigned Course?")) {
            // Implement delete functionality here
            // await dispatch(deleteAssignedCourse(object.id));
            // dispatch(getAllAssignedCourses());
        }
    };

    const onFormSubmit = async (data) => {
        const formData = {
            courseId: parseInt(data.courseId.value),
            userId: JSON.stringify(data.users.map(user => parseInt(user.value))),
        };

        let result;
        if (editedAssignCourse) {
            result = await dispatch(assignCourse({...formData, id: parseInt(editedAssignCourse.id)}));
        } else {
            result = await dispatch(assignCourse(formData));
        }
        console.log(result)
        if (result.success) {
            handleCloseModal();
            await dispatch(getAllAssignedCourses());
        }

    };

    useEffect(()=>{
        console.log(assignedCourses);
    },[assignedCourses]);
    const handleCloseModal = () => {
        setModalOpen(false);
        setEditedAssignCourse(null);
        reset();
    };

    const handleAddNew = () => {
        setEditedAssignCourse(null);
        reset({ courseId: null, users: [] });
        setModalOpen(true);
    };

    const columns = [
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: dateTimeSort("createdAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Course Name",
            dataIndex: "course",
            key: "course",
            sorter: stringSorter("course"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Assigned Users",
            dataIndex: "users",
            key: "users",
        },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
                <>
                    <Button color="link" onClick={() => handleEdit(record)}>
                        <EditOutlined />
                    </Button>
                    <Button color="link" onClick={() => handleDelete(record)}>
                        <DeleteOutlined />
                    </Button>
                </>
            ),
        },
    ];

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">Manage Assigned Courses</h5>
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" onClick={handleAddNew}>
                                <PlusCircleFilled /> Add New
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactStrapDataTable
                        dataSource={assignedCourses}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} size="xl">
                <ModalHeader toggle={handleCloseModal}>
                    {editedAssignCourse ? 'Edit Assigned Course' : 'Add Assigned Course'}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="courseId">Course</Label>
                            <Controller
                                name="courseId"
                                control={control}
                                rules={{ required: "Course is required" }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={courses.map(course => ({
                                            value: course.id,
                                            label: course.name
                                        }))}
                                    />
                                )}
                            />
                            {errors.courseId && <span className="text-danger">{errors.courseId.message}</span>}
                        </FormGroup>
                        <FormGroup>
                            <Label for="users">Users</Label>
                            <Controller
                                name="users"
                                control={control}
                                rules={{ required: "At least one user is required" }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        isMulti
                                        options={
                                            (users || []).map(user => ({
                                                value: user.id,
                                                label: user.name
                                            }))
                                        }
                                    />
                                )}
                            />
                            {errors.users && <span className="text-danger">{errors.users.message}</span>}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {editedAssignCourse ? 'Update' : 'Save'}
                        </Button>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
};

export default AssignedCourses;