import axios from "../../../axios";
import {
    ADD_COMPLAINT,
    GET_COMPLAINT_VALUES,
    GET_SINGLE_USER_COMPLAINT,
    GET_USER_COMPLAINT,
    UPDATE_COMPLAINT,
    GENERATE_COMPLAINT,
    UPDATE_COMPLAINT_ACTION,
    DELETE_COMPLATIN_ACTION

} from "../../types";
import { setAlert } from "../alert";
import { message } from "antd";

export const getComplaints = () => async (dispatch) => {
    try {
        const res = await axios.get("/complaint/values");
        if (res.data.code === 1) {
            dispatch({ type: GET_COMPLAINT_VALUES, payload: res.data.data });
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const submitComplaint = (object) => async (dispatch) => {
    try {
        const res = await axios.post(`/complaint/submit/`,object);
        if (res.data.code === 1) {
            dispatch({ type: GENERATE_COMPLAINT, payload: res.data.data });
        }
        return res;
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const addComplaintForm = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/complaint/add", formData, config);
        if (res.data.code === 1) {
            dispatch({ type: ADD_COMPLAINT, payload: res.data.data });
            return { success: true, message: res.data.message };
        } else {
            return { success: false, message: res.data.message };
        }
    } catch (err) {
        console.error("Error in addComplaintForm:", err);
        return { success: false, message: err.response?.data?.message || "Contact Admin" };
    }
};

export const updateComplaintForm = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/complaint/update", formData, config);
        if (res.data.code === 1) {
            dispatch({ type: UPDATE_COMPLAINT, payload: res.data.data });
            return { success: true, message: res.data.message };
        } else {
            return { success: false, message: res.data.message };
        }
    } catch (err) {
        console.error("Error in updateComplaintForm:", err);
        return { success: false, message: err.response?.data?.message || "Contact Admin" };
    }
};

export const deleteComplaint = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/complaint/delete/${id}`);

        if (res.data.code === 1) {

            dispatch({ type: DELETE_COMPLATIN_ACTION, payload: id });
            return { success: true, message: res.data.message };

        }
        return res
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};


export const getUserComplaints = (accessModuleId) => async (dispatch) => {
    try {
        const object={
            moduleId:accessModuleId
        }
        const res = await axios.post(`/complaint/user`,object);
        if (res.data.code === 1) {
            dispatch({ type: GET_USER_COMPLAINT, payload: res.data.data });
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const getUserSingleComplaint = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/complaint/${id}`);
        if (res.data.code === 1) {
            dispatch({ type: GET_SINGLE_USER_COMPLAINT, payload: res.data.data });
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const submitActionForComplaint = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/complaint/action/update", formData);
        if (res.data.code === 1) {
            message.success(res.data.message);
            return { success: true, message: res.data.message };
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        console.error("Error in submitActionForComplaint:", err);
        return { success: false, message: err.response?.data?.message || "Contact Admin" };
    }
};