import {COMPETENCY_FILTER, COMPETENCY_FILTER_VALUES} from "../../types";

const initialState = {
    comFilters:[],comValues:[]
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case COMPETENCY_FILTER_VALUES:
            return { ...state, comValues: payload };
        case COMPETENCY_FILTER:
            return { ...state, comFilters: payload };
        default:
            return state;
    }
};

export default reducer;