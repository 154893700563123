import axios from "../../../../axios";
import {PERMIT_FILTER_VALUES} from "../../../types";
import {setAlert} from "../../alert";

export const getPermitToWorkFilterValues=()=>async(dispatch)=>{
    try {
        const res = await axios.get("permit/reports/values");
        if (res.data.code === 1) {
            dispatch({type: PERMIT_FILTER_VALUES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}