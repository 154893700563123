import axios from "../../../axios";
import {GET_CHILD_CABINET_BY_PARENT, GET_PARENT_CABINET, UPDATE_CABINET_HIERARCHY} from "../../types";
import {setAlert} from "../alert";

export const getParentCabinet = (object) => async (dispatch) => {
    try {
        console.log('Action: Fetching parent cabinet with:', object);
        const res = await axios.post("/cabinet/hierarchy", object);
        console.log('Action: Parent cabinet API response:', res.data);
        if (res.data.code === 1) {
            dispatch({type: GET_PARENT_CABINET, payload: res.data.data});
        }
        return res.data;
    } catch (err) {
        console.error('Action: Error fetching parent cabinet:', err);
        dispatch(setAlert(err.message, "danger"));
    }
};

export const getShareableLink = (object) => async (dispatch) => {
    try {

        const res = await axios.post("/public/link/generate",object);
        if (res.data.code === 1) {
            return {success:true,message:res.data.message,payload:res.data.data};
        }
        else {
            return {success:false,message:res.data.message}
        }

    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const addCabinetFolder = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/cabinet/folder/add",formData);
        if (res.data.code === 1) {
            dispatch({type: GET_PARENT_CABINET, payload: res.data.data});
            //dispatch({type: GET_PARENT_CABINET, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const uploadFileInCabinet = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/cabinet/file/upload",formData,config);
        if (res.data.code === 1) {
            dispatch({type: GET_PARENT_CABINET, payload: res.data.data});
            //dispatch({type: GET_PARENT_CABINET, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}
export const addFileInCabinet = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/cabinet/file/add",formData,config);
        if (res.data.code === 1) {

            dispatch({type: GET_PARENT_CABINET, payload: res.data.data});
            // dispatch({
            //     type: UPDATE_CABINET_HIERARCHY,
            //     payload: res.data.data
            // });
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}
export const updateFileName = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/cabinet/file/rename",formData,config);
        if (res.data.code === 1) {
            //dispatch({type: GET_PARENT_CABINET, payload: []});
            dispatch({type: GET_PARENT_CABINET, payload: res.data.data});
            return {success:true,message:res.data.message};
            //dispatch({type: GET_PARENT_CABINET, payload: res.data.data});
            // dispatch({
            //     type: UPDATE_CABINET_HIERARCHY,
            //     payload: res.data.data
            // });
        }
        else {
            return {success:false,message:res.data.message};
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const getChildCabinetByParent = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/cabinet/${id}`);
        
        if (res.data.code === 1) {
            dispatch({type: GET_CHILD_CABINET_BY_PARENT, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const deleteFile=(object)=>async(dispatch)=>{
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/cabinet/file/delete",object,config);
        console.log(res.data.data);
        if (res.data.code === 1) {
            dispatch({type: GET_PARENT_CABINET, payload: res.data.data});
            return {success:true,message:res.data.message};
        }
        else {
            return {success:false,message:res.data.message};
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}
