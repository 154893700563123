import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useForm, Controller } from "react-hook-form";
import { getINCRs, getINCRValues,submitActionForINCR, submitINCR, addINCR, updateINCR,deleteINCR} from '../../../../store/actions/projectMonitoring/INCR/index';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table
} from "reactstrap";


import { DeleteOutlined, EditOutlined, PlusCircleFilled, EyeFilled } from "@ant-design/icons";
import ReactStrapDataTable from "../../../CustomDatatable/ReactStrapDataTable";
import fileDownload from "react-file-download"
import Select from "react-select";
import { AccessFilter, dateTimeSort, stringSorter, dateSorter ,formatDateWithTime} from "../../../../utils/functions";
import { RedAsteriskComponent, LabelWrapper } from "../../../../components/RedAsterik";
import {
    closeProgressDialog,
    showConfirmDialog,
    showProgressDialog,
    showSuccessDialog,
    showWarningDialog
} from "../../../../utils/dialogs";

import { getTags } from "../../../../store/actions/tag";
import {addNCR, updateNCR} from "../../../../store/actions/projectMonitoring/NCR";
import {ViewINCRSection, ViewNCRSection} from "../../Views";



const AdminSection = ({ formData, handleInputChange, handleSelectChange, data }) => {
    const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format

    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardHeader style={{ backgroundColor: '#e6f2ff', borderBottom: '1px solid #b8daff' }}>
                <h2 className="card-title mb-0">Admin Details</h2>
            </CardHeader>
            <Row style={{ marginBottom: '1rem', marginTop: '1rem', padding: '20px' }}>
                <Col md="6" lg="6">
                    <FormGroup row>
                        <Col sm="4">
                            <LabelWrapper>
                                <RedAsteriskComponent />
                                <Label for="site" className="mb-0">Site</Label>
                            </LabelWrapper>
                        </Col>
                        <Col sm="8">
                            <Select
                                id="site"
                                value={formData.site}
                                onChange={(option) => handleSelectChange('site', option)}
                                options={data?.sites?.map(site => ({
                                    value: site.id.toString(),
                                    label: site.name
                                })) || []}
                                placeholder="--Select Site--"
                                aria-label="Site"
                            />
                        </Col>
                    </FormGroup>
                </Col>
                <Col md="6" lg="6">
                    <FormGroup row>
                        <Col sm="4">
                            <LabelWrapper>
                                <RedAsteriskComponent />
                                <Label for="l3Contractor" className="mb-0">L3 Contractor</Label>
                            </LabelWrapper>
                        </Col>
                        <Col sm="8">
                            <Select
                                id="l3Contractor"
                                value={formData.l3Contractor}
                                onChange={(option) => handleSelectChange('l3Contractor', option)}
                                options={data?.l3?.map(contractor => ({
                                    value: contractor.id.toString(),
                                    label: contractor.name
                                })) || []}
                                placeholder="--Select Company--"
                                aria-label="L3 Contractor"
                            />
                        </Col>
                    </FormGroup>
                </Col>
                <Col md="6" lg="6">
                    <FormGroup row>
                        <Col sm="4">
                            <Label for="locationPlace" className="mb-0">Location Place</Label>
                        </Col>
                        <Col sm="8">
                            <Input
                                type="text"
                                id="locationPlace"
                                name="locationPlace"
                                placeholder="For example: Hot works area of workshop"
                                value={formData.locationPlace}
                                onChange={handleInputChange}
                            />
                        </Col>
                    </FormGroup>
                </Col>

                <Col md="6" lg="6">
                    <FormGroup row>
                        <Col sm="4">
                            <LabelWrapper>
                                <RedAsteriskComponent />
                                <Label for="respondentBy" className="mb-0">Respondent</Label>
                            </LabelWrapper>
                        </Col>
                        <Col sm="8">
                            <Select
                                id="respondentBy"
                                value={formData.respondentBy}
                                onChange={(option) => handleSelectChange('respondentBy', option)}
                                options={data?.respondents?.map(user => ({
                                    value: user.id.toString(),
                                    label: user.name
                                })) || []}
                                placeholder="--Select User--"
                                aria-label="Respondent By"
                            />
                        </Col>
                    </FormGroup>
                </Col>

            </Row>
        </Card>
    );
};
const AttachmentsSection = ({ attachments = [], onAttachmentsChange }) => {
    const [internalKeys, setInternalKeys] = useState({});

    useEffect(() => {
        // Initialize internalKeys with existing attachments
        const initialKeys = attachments.reduce((acc, attachment, index) => {
            acc[`attachment_${index}`] = attachment;
            return acc;
        }, {});
        setInternalKeys(initialKeys);
    }, []);

    const generateKey = () =>
        `attachment_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

    const addNewRow = () => {
        const newAttachment = {
            filename: "",
            name: "",
            description: "",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            file: null,
            path: null,
        };
        const newKey = generateKey();
        setInternalKeys((prev) => ({ ...prev, [newKey]: newAttachment }));
        onAttachmentsChange([...attachments, newAttachment]);
    };

    const updateAttachment = (key, field, value) => {
        const updatedAttachments = Object.values(internalKeys).map((att, index) =>
            att === internalKeys[key] ? { ...att, [field]: value } : att,
        );
        setInternalKeys((prev) => ({
            ...prev,
            [key]: { ...prev[key], [field]: value },
        }));
        onAttachmentsChange(updatedAttachments);
    };

    const handleFileChange = (key, file) => {
        if (file && file.size > 5 * 1024 * 1024) {
            alert("File size should not exceed 5MB");
            return;
        }

        const updatedAttachment = {
            ...internalKeys[key],
            filename: file.name,
            name: file.name,
            file: file,
            path: null,
        };
        setInternalKeys((prev) => ({
            ...prev,
            [key]: updatedAttachment,
        }));
        const updatedAttachments = Object.values(internalKeys).map((att) =>
            att === internalKeys[key] ? updatedAttachment : att,
        );
        onAttachmentsChange(updatedAttachments);
    };

    const deleteAttachment = (key) => {
        const filteredAttachments = Object.values(internalKeys).filter(
            (att) => att !== internalKeys[key],
        );
        setInternalKeys((prev) => {
            const newKeys = { ...prev };
            delete newKeys[key];
            return newKeys;
        });
        onAttachmentsChange(filteredAttachments);
    };

    const viewAttachment = (attachment) => {
        if (attachment.file instanceof File) {
            const url = URL.createObjectURL(attachment.file);
            window.open(url, "_blank");
        } else if (attachment.fullPath || attachment.path) {
            fetch(`${encodeURIComponent(attachment.fullPath || attachment.path)}`)
                .then((response) => response.blob())
                .then((blob) => {
                    fileDownload(
                        blob,
                        attachment.fileName || attachment.filename || "download",
                    );
                })
                .catch((error) => {
                    console.error("Error downloading file:", error);
                    alert("Error downloading file. Please try again.");
                });
        }
    };

    return (
        <Card
            style={{
                border: "2px solid #ced4da",
                borderRadius: "5px",
                boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            }}
        >
            <CardHeader
                style={{
                    backgroundColor: "#e6f2ff",
                    borderBottom: "1px solid #b8daff",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <h2 className="card-title mb-0">Attachments</h2>
                <Button color="primary" size="sm" onClick={addNewRow}>
                    <i className="fas fa-plus"></i>
                </Button>
            </CardHeader>
            <CardBody>
                <Table>
                    <thead>
                        <tr>
                            <th>File Name</th>
                            <th>Description</th>
                            <th>Upload File</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(internalKeys).map(([key, attachment]) => (
                            <tr key={key}>
                                <td>{attachment.filename || attachment.name}</td>
                                <td>
                                    <Input
                                        type="text"
                                        value={attachment.description}
                                        onChange={(e) =>
                                            updateAttachment(key, "description", e.target.value)
                                        }
                                        style={{ borderColor: "black" }}
                                    />
                                </td>
                                <td>
                                    <FormGroup>
                                        <Label for={`file-${key}`} className="btn btn-secondary">
                                            {attachment.file || attachment.path
                                                ? "Change File"
                                                : "Upload File"}
                                        </Label>
                                        <Input
                                            type="file"
                                            id={`file-${key}`}
                                            onChange={(e) => handleFileChange(key, e.target.files[0])}
                                            style={{ display: "none" }}
                                        />
                                    </FormGroup>
                                </td>
                                <td>
                                    <Button
                                        color="secondary"
                                        size="sm"
                                        className="mr-2"
                                        onClick={() => viewAttachment(attachment)}
                                        disabled={!attachment.file && !attachment.path}
                                    >
                                        {attachment.fullPath || attachment.path
                                            ? "Download"
                                            : "View"}
                                    </Button>
                                    <Button
                                        color="danger"
                                        size="sm"
                                        onClick={() => deleteAttachment(key)}
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
};
const ActionSection = ({ formData, handleInputChange, actionUsers }) => {
    const [actions, setActions] = useState(formData.actions || []);

    useEffect(() => {
        setActions(formData.actions || []);
    }, [formData.actions]);

    useEffect(() => {
        if (formData.furtherActionsRequired === 'No') {
            setActions([]);
            handleInputChange({ target: { name: 'actions', value: [] } });
        }
    }, [formData.furtherActionsRequired]);

    const handleActionChange = (index, field, value) => {
        const newActions = [...actions];
        newActions[index][field] = value;
        setActions(newActions);
        handleInputChange({ target: { name: 'actions', value: newActions } });
    };

    const addAction = () => {
        const newActions = [...actions, { userId: '', description: '', type: 'N' }];
        setActions(newActions);
        handleInputChange({ target: { name: 'actions', value: newActions } });
    };

    const removeAction = (index) => {
        const newActions = actions.filter((_, i) => i !== index);
        setActions(newActions);
        handleInputChange({ target: { name: 'actions', value: newActions } });
    };

    const userOptions = actionUsers.map(user => ({
        value: user.id,
        label: `${user.name}`
    }));

    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardHeader style={{
                backgroundColor: '#e6f2ff',
                borderBottom: '1px solid #b8daff',
            }}>
                <h2 className="card-title mb-0">Actions</h2>
            </CardHeader>
            <CardBody>
                <>
                    <Button color="primary" size="sm" onClick={addAction}>
                        <i className="fas fa-plus"></i>
                    </Button>
                    <div className="mt-3">
                        <Table bordered>
                            <thead>
                                <tr>
                                    <th>To</th>
                                    <th>Description</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {actions.map((action, index) => (
                                    <tr key={index} style={action.type !== 'N' ? { backgroundColor: '#f0f0f0' } : {}}>
                                        <td>
                                            <Select
                                                value={userOptions.find(option => option.value === action.userId)}
                                                onChange={(selectedOption) => handleActionChange(index, 'userId', selectedOption.value)}
                                                options={userOptions}
                                                placeholder="Select User"
                                                isDisabled={action.type !== 'N'}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                type="textarea"
                                                value={action.description}
                                                onChange={(e) => handleActionChange(index, 'description', e.target.value)}
                                                placeholder="Type Description"
                                                disabled={action.type !== 'N'}
                                            />
                                        </td>
                                        <td>
                                            {action.type === 'N' ? (
                                                <Button color="danger" size="sm" onClick={() => removeAction(index)}>
                                                    <i className="fas fa-minus"></i>
                                                </Button>
                                            ) : (
                                                <span>-</span>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                    </div>

                </>
            </CardBody>
        </Card>
    );
};
const INCRSection = ({ control, errors, ncrValues, ncrDetails, handleChange }) => {
    useEffect(() => {
        if (ncrDetails.length === 0) {
            handleChange([{
                category: null,
                subcategory: null,
                findingDetails: '',
                actionsRecommending: '',
                findingImportance: null,
                ncrFor: null
            }]);
        }
    }, [ncrDetails, handleChange]);

    const addNcrDetailRow = () => {
        handleChange([...ncrDetails, {
            category: null,
            subcategory: null,
            findingDetails: '',
            actionsRecommending: '',
            findingImportance: null,
            ncrFor: null
        }]);
    };

    const handleNcrDetailChange = (index, field, value) => {
        const updatedDetails = [...ncrDetails];
        updatedDetails[index][field] = value;
        handleChange(updatedDetails);
    };

    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', marginTop: '20px' }}>
            <CardHeader style={{ backgroundColor: '#e6f2ff', borderBottom: '1px solid #b8daff' }}>
                <h2 className="card-title mb-0">NCR Details</h2>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="observationDate">Observation Date</Label>
                            <Controller
                                name="observationDate"
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        type="date"
                                        id="observationDate"
                                        {...field}
                                    />
                                )}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="mhtPmResponsible">MHT PM Responsible</Label>
                            <Controller
                                name="mhtPmResponsible"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={ncrValues.mhtUsers?.map(user => ({ value: user.id, label: user.name })) || []}
                                        isClearable
                                    />
                                )}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="personRaisingNcr">Name of Person Raising NCR</Label>
                            <Controller
                                name="personRaisingNcr"
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        type="text"
                                        id="personRaisingNcr"
                                        {...field}
                                    />
                                )}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="region">Region</Label>
                            <Controller
                                name="region"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={ncrValues.regions?.map(region => ({ value: region.id, label: region.name })) || []}
                                        isClearable
                                    />
                                )}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <LabelWrapper>
                                <RedAsteriskComponent />
                                <Label for="projectType" className="mb-0">Project Type</Label>
                            </LabelWrapper>
                            <Controller
                                name="projectType"
                                control={control}
                                rules={{ required: "Project Type is required" }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={ncrValues.projectTypes?.map(type => ({ value: type.id, label: type.name })) || []}
                                        isClearable
                                    />
                                )}
                            />
                            {errors.programType && <span className="text-danger">{errors.programType.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <LabelWrapper>
                                <RedAsteriskComponent />
                                <Label for="subProgram" className="mb-0">Project Scope</Label>
                            </LabelWrapper>
                            <Controller
                                name="projectScope"
                                control={control}
                                rules={{ required: "Project Scope is required" }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={ncrValues.projectScopes?.map(subProgram => ({ value: subProgram.id, label: subProgram.name })) || []}
                                        isClearable
                                    />
                                )}
                            />
                            {errors.subProgram && <span className="text-danger">{errors.subProgram.message}</span>}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <Label>NCR Details</Label>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>NCR Category*</th>
                                        <th>NCR Subcategory*</th>
                                        <th>Finding Details</th>
                                        <th>Actions Recommending</th>
                                        <th>Finding Importance</th>
                                        <th>NCR For</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ncrDetails.map((detail, index) => (
                                        <tr key={index}>
                                            <td>
                                                <Select
                                                    value={detail.category}
                                                    onChange={(option) => handleNcrDetailChange(index, 'category', option)}
                                                    options={ncrValues.categories?.map(category => ({ value: category.id, label: category.name })) || []}
                                                    isClearable
                                                />
                                            </td>
                                            <td>
                                                <Select
                                                    value={detail.subcategory}
                                                    onChange={(option) => handleNcrDetailChange(index, 'subcategory', option)}
                                                    options={ncrValues.subCategories?.map(subCategory => ({ value: subCategory.id, label: subCategory.name })) || []}
                                                    isClearable
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type="textarea"
                                                    value={detail.findingDetails}
                                                    onChange={(e) => handleNcrDetailChange(index, 'findingDetails', e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type="textarea"
                                                    value={detail.actionsRecommending}
                                                    onChange={(e) => handleNcrDetailChange(index, 'actionsRecommending', e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                <Select
                                                    value={detail.findingImportance}
                                                    onChange={(option) => handleNcrDetailChange(index, 'findingImportance', option)}
                                                    options={ncrValues.findingImportances?.map(importance => ({ value: importance, label: importance })) || []}

                                                />
                                            </td>
                                            <td>
                                                <Select
                                                    value={detail.ncrFor}
                                                    onChange={(option) => handleNcrDetailChange(index, 'ncrFor', option)}
                                                    options={ncrValues.subFors?.map(subFor => ({ value: subFor.id, label: subFor.name })) || []}
                                                    isClearable
                                                />
                                            </td>
                                            <td>
                                                {index === ncrDetails.length - 1 && (
                                                    <Button color="primary" size="sm" onClick={addNcrDetailRow}>
                                                        <i className="fas fa-plus"></i>
                                                    </Button>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </FormGroup>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};
const ActionModal = ({ isOpen, toggle, onInitiateAction, selectedHazard, message }) => {


    const dispatch = useDispatch();
    const tags = useSelector((state) => state.tag.list);
    const [initiateModalOpen, setInitiateModalOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const [actionFormData, setActionFormData] = useState({
        submitDate: new Date().toISOString().split('T')[0],
        targetAt: '',
        status: { value: 'open', label: 'Open' },
        description: '',
        tags: [],
        comments: []
    });

    const statusOptions = [
        { value: 'OPEN', label: 'Open' },
        { value: 'CLOSED', label: 'Close' }
    ];

    const resetForm = () => {
        setActionFormData({
            submitDate: new Date().toISOString().split('T')[0],
            targetAt: '',
            status: { value: 'open', label: 'Open' },
            description: '',
            tags: [],
            comments: []
        });
    };

    const toggleInitiateModal = (action = true) => {
        setInitiateModalOpen(!initiateModalOpen);
        setSelectedAction(action);
        if (action) {
            const selectedTags = action.tags
                ? action.tags.map(tagId => {
                    const matchingTag = tags.find(tag => tag.id.toString() === tagId.toString());
                    return matchingTag
                        ? { value: matchingTag.id.toString(), label: matchingTag.name }
                        : null;
                }).filter(tag => tag !== null)
                : [];
            setActionFormData({
                submittedAt: action.submittedAt,
                submitDate: formatDateWithTime(action.submittedAt, false) || new Date().toISOString().split('T')[0],
                targetAt: formatDateWithTime(action.targetAt, false) || '',
                description: action.description,
                reply: action.message || '',
                tags: selectedTags,
                comments: action.comments || [],
                type: action.type,
                targetDays: action.targetDays,
                status: action.status,
                id: action.id,
                userId: action.userId,
                createdBy: action.createdBy,
                updatedBy: action.updatedBy,
                createdAt: action.createdAt,
                updatedAt: action.updatedAt
            });
        } else {
            resetForm();
        }
    };

    const handleActionFormChange = (e) => {
        const { name, value } = e.target;
        setActionFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSelectChange = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        setActionFormData(prevState => ({
            ...prevState,
            [name]: name === 'status' ? selectedOption.value : selectedOption
        }));
    };
    const addCommentRow = () => {
        setActionFormData(prevState => ({
            ...prevState,
            comments: [...prevState.comments, {
                id: null,
                description: '',
                createdBy: null,
                updatedBy: null,
                createdAt: null,
                updatedAt: null
            }]
        }));
    };

    const removeCommentRow = (index) => {
        setActionFormData(prevState => ({
            ...prevState,
            comments: prevState.comments.filter((_, i) => i !== index)
        }));
    };

    const handleCommentChange = (index, value) => {
        setActionFormData(prevState => ({
            ...prevState,
            comments: prevState.comments.map((comment, i) =>
                i === index ? { ...comment, description: value } : comment
            )
        }));
    };
    const handleInitiateSubmit = (e) => {
        e.preventDefault();
        const action = {
            ...actionFormData,
            submitDate: new Date().toISOString().split('T')[0],
            status: actionFormData.status || 'PENDING',
            tags: Array.isArray(actionFormData.tags) && actionFormData.tags.length > 0
                ? actionFormData.tags
                    .filter(tag => tag && typeof tag.value !== 'undefined')
                    .map(tag => parseInt(tag.value))
                : []
        };
        onInitiateAction(action);
        toggleInitiateModal();
    };

    useEffect(() => {
        dispatch(getTags());
    }, [dispatch]);

    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} size="xl">
                <ModalHeader toggle={toggle}>
                    {message && <p>{message}</p>}
                    Number: {selectedHazard?.sequenceNo}
                </ModalHeader>
                <ModalBody>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Action Type</th>
                                <th>Submit Date</th>
                                <th>From</th>
                                <th>Message Description</th>
                                <th>To</th>
                                <th>Response Message</th>
                                <th>Response On</th>
                                <th>Response By</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedHazard?.actions?.map((action, index) => (
                                <tr key={index}>
                                    <td>
                                        <Button color="primary" size="sm" onClick={() => toggleInitiateModal(action)}>
                                            {action.status}
                                        </Button>
                                    </td>
                                    <td>{action.actionType}</td>
                                    <td>{action.submittedAt}</td>
                                    <td>{action.createdByUser}</td>
                                    <td>{action.description}</td>
                                    <td>{action.createdFor}</td>
                                    <td>{action.reply}</td>
                                    <td>{action.responseAt}</td>
                                    <td>{action.res}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={initiateModalOpen} toggle={toggleInitiateModal} size="lg">
                <Form onSubmit={handleInitiateSubmit}>
                    <ModalHeader toggle={toggleInitiateModal}>
                        {selectedAction ? 'Edit Action' : 'Initiate New Action'}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="submitDate">Submit Date</Label>
                                    <Input
                                        type="date"
                                        name="submitDate"
                                        id="submitDate"
                                        value={actionFormData.submitDate}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="targetAt">Target Date</Label>
                                    <Input
                                        type="date"
                                        name="targetAt"
                                        id="targetAt"
                                        value={actionFormData.targetAt}
                                        onChange={handleActionFormChange}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="status">Status</Label>
                                    <Select
                                        name="status"
                                        options={statusOptions}
                                        value={statusOptions.find(option => option.value === actionFormData.status) || null}
                                        onChange={handleSelectChange}
                                    // isDisabled={actionFormData.status === 'PENDING'}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <Label for="description">Description</Label>
                            <Input
                                type="textarea"
                                name="description"
                                id="description"
                                value={actionFormData.description}
                                onChange={handleActionFormChange}
                                disabled
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="message">Response Message</Label>
                            <Input
                                type="textarea"
                                name="message"
                                id="message"
                                value={actionFormData.message}
                                onChange={handleActionFormChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="tags">Tags</Label>
                            <Select
                                isMulti
                                name="tags"
                                options={tags?.map(tag => ({
                                    value: tag.id.toString(),
                                    label: tag.name
                                })) || []}
                                value={actionFormData.tags}
                                onChange={handleSelectChange}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Comments</Label>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {actionFormData.comments.map((comment, index) => (
                                        <tr key={index}>
                                            <td>
                                                <Input
                                                    type="textarea"
                                                    value={comment.description}
                                                    onChange={(e) => handleCommentChange(index, e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                <Button color="danger"
                                                    onClick={() => removeCommentRow(index)}><DeleteOutlined /></Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Button color="primary" onClick={addCommentRow}>Add Comment</Button>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleInitiateModal}>Cancel</Button>
                        <Button color="primary" type="submit">Submit</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};

const INCR = ({ access }) => {
    const dispatch = useDispatch();
    const ncrs = useSelector((state) => state.incr.list);
    const ncrValues = useSelector((state) => state.incr.values);

    const [modalOpen, setModalOpen] = useState(false);
    const [editingNCR, setEditingNCR] = useState(null);

    const [ncrDetails, setNcrDetails] = useState([{
        category: null,
        subcategory: null,
        findingDetails: '',
        actionsRecommending: '',
        findingImportance: null,
        ncrFor: null
    }]);

    //ACTION 
    const [actionModalOpen, setActionModalOpen] = useState(false);
    const [selectedHazard, setSelectedHazard] = useState(null);
    const [actionData, setActionData] = useState({})

    //VIEW MODAL
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedViewINCR, setSelectedViewINCR] = useState(null);


    const handleInitiate = (actionData) => {
        setActionData(actionData)
    };

    const handleInitiateAction = async (actionData) => {
        delete actionData.submitDate
        if (actionData.message) {
            actionData.reply = actionData.message;
            delete actionData.message;
        }

        const payload = {
            incrId: selectedHazard.id,
            id: selectedHazard.actionId,
            actions: JSON.stringify(actionData)
        }
        const response = await dispatch(submitActionForINCR(payload))
        if (response.success) {
            showSuccessDialog(response.message)
            setActionData([]);
            setActionModalOpen(false);
            setModalOpen(false)
            dispatch(getINCRs(access.moduleId))
        } else {
            showWarningDialog(response.message)
        }
    };

    const toggleActionModal = () => setActionModalOpen(!actionModalOpen);

    const handleActionClick = (hazard) => {
        //console.log(hazard)
        setSelectedHazard(hazard);
        setActionModalOpen(true);
    };


    //attachments
    const [attachments, setAttachments] = useState([]);

    const { control, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm();

    const [accessFilter, setAccessFilter] = useState('My');

    const handleAccessFilterChange = (selectedAccess) => {
        setAccessFilter(selectedAccess);
    };

    useEffect(() => {
        dispatch(getINCRValues());
        dispatch(getINCRs(access.moduleId));
    }, [dispatch, access.moduleId]);

    const handleInputChange = (e) => {
        setValue(e.target.name, e.target.value);
    };

    const handleSelectChange = (name, option) => {
        setValue(name, option);
    };

    const handleEdit = (ncr) => {
        setEditingNCR(ncr)
        reset({
            ...ncr,
            site: { value: ncr.siteId, label: ncr.site },
            l3Contractor: { value: ncr.l3Id, label: ncr.l3Contractor },
            respondentBy: { value: ncr.respondedBy, label: ncr.respondedByUser },
            observationDate: ncr.observedAt,
            mhtPmResponsible: { value: ncr.mhtId, label: ncr.mhtuser },
            personRaisingNcr: ncr.ncrRaiserName,
            region: { value: ncr.regionId, label: ncr.region },
            projectType: { value: ncr.ncrPtId, label: ncr.projectType },
            projectScope: { value: ncr.ncrPsId, label: ncr.projectScope },
        });

        // Set NCR details
        if (Array.isArray(ncr.findings)) {
            const formattedNcrDetails = ncr.findings.map(finding => {
                
                const category = ncrValues.categories.find(cat => cat.id === finding.categoryId);
                const subcategory = ncrValues.subCategories.find(subcat => subcat.id === finding.subcategoryId);
                const ncrFor = ncrValues.subFors.find(subfor => subfor.id === finding.ncrForId);
        
                return {
                    category: category ? { value: category.id, label: category.name } : null,
                    subcategory: subcategory ? { value: subcategory.id, label: subcategory.name } : null,
                    findingDetails: finding.findingDetails,
                    actionsRecommending: finding.actionsRecommending,
                    findingImportance: { value: finding.findingImportance, label: finding.findingImportance },
                    ncrFor: ncrFor ? { value: ncrFor.id, label: ncrFor.name } : null
                };
            });
            
            console.log(formattedNcrDetails);
            setNcrDetails(formattedNcrDetails);
        } else {
            setNcrDetails([]);
        }

        if (Array.isArray(ncr.files)) {
            const formattedAttachments = ncr.files.map((file) => ({
                id: file.id,
                filename: file.fileName,
                name: file.fileName,
                description: file.description,
                path: file.path,
                fullPath: file.fullPath,
                createdAt: file.createdAt,
                createdBy: file.createdBy,
                updatedAt: file.updatedAt,
                updatedBy: file.updateBy, // Note: Changed from updatedBy to updateBy to match the provided structure
            }));
            setValue('attachments', formattedAttachments);
        } else {
            setValue('attachments', []);
        }

        setModalOpen(true);
    };

    const handleDelete = async (ncr) => {
        if (window.confirm("Are you sure you want to delete this NCR?")) {
            await dispatch(deleteINCR(ncr.id));
            dispatch(getINCRs(access.moduleId));
        }
    };

    const onFormSubmit = async (data) => {
        const formData = new FormData();
        formData.append('moduleId', access.moduleId)

        // Append form fields
        Object.entries(data).forEach(([key, value]) => {
            switch (key) {
                case 'site':
                    formData.append('siteId', parseInt(value.value));
                    break;
                case 'l3Contractor':
                    formData.append('l3Id', parseInt(value.value));
                    break;
                case 'respondentBy':
                    formData.append('respondedBy', parseInt(value.value));
                    break;
                case 'mhtPmResponsible':
                    formData.append('mhtId', parseInt(value.value));
                    break;
                case 'region':
                    formData.append('regionId', parseInt(value.value));
                    break;
                case 'projectType':
                    formData.append('ncrPtId', parseInt(value.value));
                    break;
                case 'projectScope':
                    formData.append('ncrPsId', parseInt(value.value));
                    break;
                case 'ncrRaiserName':
                    formData.append('ncrRaiserName', value);
                    break;
                default:
                    break;
            }
        });

        // Append NCR details (findings)
        if (Array.isArray(ncrDetails) && ncrDetails.length > 0) {
            formData.append('findings', JSON.stringify(ncrDetails.map(detail => ({
                categoryId: detail.category.value,
                subcategoryId: detail.subcategory.value,
                findingDetails: detail.findingDetails,
                actionsRecommending: detail.actionsRecommending,
                findingImportance: detail.findingImportance.value,
                ncrForId: detail.ncrFor.value
            }))));
        }
        else {
            formData.append('findings', JSON.stringify([]))
        }

        // Handle actions
        if (Array.isArray(data.actions)) {
            formData.append('actions', JSON.stringify(data.actions.map(action => ({
                id: action.id ?? null,
                userId: action.userId ? parseInt(action.userId) : null,
                description: action.description ?? null,
                createdAt: action.createdAt ?? null,
                createdBy: action.createdBy ?? null,
                updatedAt: action.updatedAt ?? null,
                updatedBy: action.updatedBy ?? null,
                submittedAt: action.submittedAt ?? null,
                targetAt: action.targetAt ?? null,
                reply: action.reply ?? null,
                tags: action.tags ?? [],
                type: action.type ?? "N",
                status: action.status ?? "PENDING",
            }))));
        }
        else {
            formData.append('actions', JSON.stringify([]))
        }

        // Handle attachments
        if (Array.isArray(data.attachments)) {
            formData.append('files', JSON.stringify(data.attachments.map(attachment => ({
                id: attachment.id ?? null,
                fileName: attachment.filename ?? "",
                description: attachment.description ?? null,
                path: attachment.path ?? null,
                createdAt: attachment.createdAt ?? null,
                createdBy: attachment.createdBy ?? null,
                updateBy: attachment.updateBy ?? null,
                updatedAt: attachment.updatedAt ?? null,
            }))));

            data.attachments.forEach((attachment, index) => {
                if (attachment.file instanceof File) {
                    formData.append(`attachments[${index}]`, attachment.file, attachment.filename);
                }
            });
        }
        else {
            formData.append('files', JSON.stringify([]))
        }

        // Additional fields
        if (data.observedAt) {
            formData.append('observedAt', data.observationDate ?? null);
        }

        if (data.status !== undefined) {
            formData.append('status', data.status.toString());
        }


        console.log('FormData contents:');
        for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }


        try {
            let result;
            showProgressDialog("Uploading INCR","Please wait")
            if (editingNCR) {
                formData.append('id', editingNCR.id);
                formData.append('fileId', editingNCR.fileId);
                formData.append('findingId', editingNCR.findingId);
                formData.append('actionId', editingNCR.actionId);
                result = await dispatch(updateINCR(formData));
            } else {
                result = await dispatch(addINCR(formData));
            }
            if (result.success) {
                await showSuccessDialog(result.message)
                dispatch(getINCRs(access.moduleId));
                closeProgressDialog()
                handleCloseModal();
            }
            else {
                closeProgressDialog()
                await showWarningDialog(result.message)
            }
        } catch (error) {
            handleCloseModal();
        }
        finally {
            handleCloseModal();
            console.log("Inside catch")
        }




    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setEditingNCR(null);
        setNcrDetails([]);
        reset();
    };

    const handleAddNew = () => {
        setEditingNCR(null);
        setNcrDetails([]);
        reset();
        setModalOpen(true);
    };


    const handleGenerate = async (data) => {
        try {
            const result = await showConfirmDialog("Are you sure you want to submit this NCR?");
            if (result.isConfirmed) {
                const object={
                    moduleId:access.moduleId,
                    id:data.id
                }
                const ncrResult = await dispatch(submitINCR(object))
                if (ncrResult.success) {
                    showSuccessDialog(ncrResult.message)
                    dispatch(getINCRs(access.moduleId));
                }
                else {
                    showSuccessDialog(ncrResult.message)
                }
            }
        } catch (error) {
            console.error('Error in handleDelete:', error);
        }
    };

    const viewRecord = (record) => {
        setSelectedViewINCR(record)
        setViewModalOpen(true)
        // setSelectedRecord(record);
        // setViewModalOpen(true)
    }

    useEffect(() => {
        console.log("INCR VALUES")
        console.log(ncrValues)
    }, [ncrValues]);

    let columns = [
        {
            title: "Action",
            key: "action",
            width: '120px',
            render: (text, data) => (
                <div style={{ display: 'flex', gap: '8px' }}>
                    {data.submittedAt === null && (
                        <Button color="primary" size="sm" onClick={() => handleEdit(data)}>
                            <EditOutlined />
                        </Button>
                    )}
                    {data.submittedAt === null && (<>
                        <Button color="danger" size="sm" onClick={() => handleDelete(data)}>
                            <DeleteOutlined />
                        </Button>
                    </>)}
                    <Button color="info" size="sm" onClick={() => viewRecord(data)}>
                        <EyeFilled />
                    </Button>
                    {data.submittedAt !== null && (
                        <Button color="info" size="sm" onClick={() => handleActionClick(data)} disabled={accessFilter === 'Shared'}>
                            <PlusCircleFilled />
                        </Button>
                    )}

                </div>
            ),
        },
        {
            title: "CreatedAt",
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: stringSorter("createdAt"),
            sortDirections: ["ascend", "descend"],

        },
        {
            title: "Sequence Number",
            dataIndex: "submittedAt",
            key: "submittedAt",
            sorter: stringSorter("submittedAt"),
            sortDirections: ["ascend", "descend"],
            render: (text, data) => {
                if (data.submittedAt !== null) {
                    return data.sequenceNo; // Assuming the sequence string is stored in the 'sequence' property
                } else {
                    return (
                        <Button color="primary" size="sm" onClick={() => handleGenerate(data)}>
                            Generate
                        </Button>
                    );
                }
            }
        },
        {
            title: "L3 Contractor",
            dataIndex: "l3Contractor",
            key: "l3Contractor",
            sorter: stringSorter("l3Contractor"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
            sorter: stringSorter("country"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Business Unit",
            dataIndex: "businessUnit",
            key: "businessUnit",
            sorter: stringSorter("businessUnit"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Project/Program",
            dataIndex: "project",
            key: "project",
            sorter: stringSorter("project"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Site",
            dataIndex: "site",
            key: "site",
            sorter: stringSorter("site"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Location Place",
            dataIndex: "location",
            key: "location",
            sorter: stringSorter("location"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            sorter: stringSorter("description"),
            ellipsis: true,
        },
    ];



    const filteredNCRS = useMemo(() => {
        switch (accessFilter) {
            case 'My':
                return ncrs.user || []
            case 'Shared':
                return ncrs.shared || [];
            case 'Assigned':
                return ncrs.assigned || [];
            default:
                return [];
        }
    }, [ncrs, accessFilter]);


    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="align-items-center mb-3">
                        <Col md={3}>
                            <h5 className="mb-0">Manage INCR</h5>
                        </Col>
                        <Col md={9} className="d-flex justify-content-end align-items-center">
                            <AccessFilter
                                accessFilter={accessFilter}
                                handleAccessFilterChange={handleAccessFilterChange}
                                sharedAccess={ncrs?.sharedAccess || []}
                            />
                            <Button color="primary" onClick={() => setModalOpen(true)} className="ms-2">
                                <PlusCircleFilled size={18} /> New
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactStrapDataTable
                        dataSource={filteredNCRS}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} size="xl" backdrop="static" fullscreen>
                <ModalHeader toggle={handleCloseModal}>
                    {editingNCR ? 'Edit INCR' : 'Add INCR'}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <ModalBody style={{ maxHeight: 'calc(110vh - 210px)', overflowY: 'auto' }}>
                        <AdminSection
                            formData={watch()}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            data={ncrValues}
                        />
                        <INCRSection
                            control={control}
                            errors={errors}
                            ncrValues={ncrValues}
                            ncrDetails={ncrDetails}
                            handleChange={setNcrDetails}
                        />
                        <AttachmentsSection
                            attachments={watch('attachments') || []}
                            onAttachmentsChange={(attachments) => setValue('attachments', attachments)}
                        />
                        <ActionSection
                            formData={watch()}
                            handleInputChange={handleInputChange}
                            actionUsers={ncrValues.actionUsers}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {editingNCR ? 'Update' : 'Save'}
                        </Button>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
            <ViewINCRSection
                isOpen={viewModalOpen}
                toggle={() => setViewModalOpen(!viewModalOpen)}
                selectedRecord={selectedViewINCR ?? {}}
                ncrValues={ncrValues ?? []}
            />
            <ActionModal
                isOpen={actionModalOpen}
                toggle={toggleActionModal}
                selectedHazard={selectedHazard}
                message={"Action For INCR"}
                onInitiateAction={handleInitiateAction}
            />
        </div>
    );
};

export default INCR