import {
    Button,
    Card,
    CardBody,
    CardHeader, CardText,
    Col, Form, FormFeedback,
    FormGroup,
    Input,
    Label, Modal, ModalBody, ModalFooter, ModalHeader,
    Row, Table
} from "reactstrap";
import { LabelWrapper, RedAsteriskComponent } from "../../../components/RedAsterik";
import Select from "react-select";
import React, { useEffect, useMemo, useRef, useState } from "react";
import SignatureCanvas from 'react-signature-canvas';
import fileDownload from "react-file-download";
import { useDispatch, useSelector } from "react-redux";
import {formatDateWithTime, getCurrentDateString} from "../../../utils/functions";
import { getTags } from "../../../store/actions/tag";
import { DeleteOutlined } from "@ant-design/icons";
import { message, Image as AntImage } from "antd";

const InterventionPhotoComponent = ({ formData, setFormData, handleFileChange }) => {
    const renderImage = () => {
        if (formData.interventionPhoto) {
            return (
                <img
                    src={formData.interventionPhoto}
                    alt="Intervention"
                    style={{ maxWidth: '100%', maxHeight: '200px' }}
                />
            );
        } else if (formData.interventionFile) {
            return (
                <img
                    src={formData.interventionFile}
                    alt="Intervention"
                    style={{ maxWidth: '100%', maxHeight: '200px' }}
                />
            );
        }
        return null;
    };

    const handleRemoveFile = () => {
        setFormData(prevData => ({
            ...prevData,
            interventionFile: null,
            interventionPhoto: null,
            icFileName: ''
        }));
    };

    return (
        <Col md="12">
            <FormGroup>
                <Label>Capture Intervention Photo</Label>
                <Input
                    type="file"
                    id="interventionFile"
                    name="interventionFile"
                    onChange={handleFileChange}
                    accept="image/*"
                />
                {renderImage()}
                {(formData.interventionPhoto || formData.interventionFile) && (
                    <div>
                        <p>
                            {formData.icFileName
                                ? `Selected file: ${formData.icFileName}`
                                : 'Current photo'}
                        </p>
                        <Button color="danger" onClick={handleRemoveFile}>
                            Remove File
                        </Button>
                    </div>
                )}
            </FormGroup>
        </Col>
    );
};

const AdminSection = ({ formData, handleInputChange, handleSelectChange, data }) => {
    const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format

    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardHeader style={{ backgroundColor: '#e6f2ff', borderBottom: '1px solid #b8daff' }}>
                <h2 className="card-title mb-0">Admin Details</h2>
            </CardHeader>
            <Row style={{ marginBottom: '1rem', marginTop: '1rem', padding: '20px' }}>
                <Col md="6" lg="6">
                    <FormGroup row>
                        <Col sm="4">
                            <LabelWrapper>
                                <RedAsteriskComponent />
                                <Label for="site" className="mb-0">Site</Label>
                            </LabelWrapper>
                        </Col>
                        <Col sm="8">
                            <Select
                                id="site"
                                value={formData.site}
                                onChange={(option) => handleSelectChange('site', option)}
                                options={data?.sites?.map(site => ({
                                    value: site.id.toString(),
                                    label: site.name
                                })) || []}
                                placeholder="--Select Site--"
                                aria-label="Site"
                            />
                        </Col>
                    </FormGroup>
                </Col>
                <Col md="6" lg="6">
                    <FormGroup row>
                        <Col sm="4">
                            <LabelWrapper>
                                <RedAsteriskComponent />
                                <Label for="l3Contractor" className="mb-0">L3 Contractor</Label>
                            </LabelWrapper>
                        </Col>
                        <Col sm="8">
                            <Select
                                id="l3Contractor"
                                value={formData.l3Contractor}
                                onChange={(option) => handleSelectChange('l3Contractor', option)}
                                options={data?.l3?.map(contractor => ({
                                    value: contractor.id.toString(),
                                    label: contractor.name
                                })) || []}
                                placeholder="--Select Company--"
                                aria-label="L3 Contractor"
                            />
                        </Col>
                    </FormGroup>
                </Col>
                <Col md="6" lg="6">
                    <FormGroup row>
                        <Col sm="4">
                            <Label for="locationPlace" className="mb-0">Location Place</Label>
                        </Col>
                        <Col sm="8">
                            <Input
                                type="text"
                                id="locationPlace"
                                name="locationPlace"
                                placeholder="For example: Hot works area of workshop"
                                value={formData.locationPlace}
                                onChange={handleInputChange}
                            />
                        </Col>
                    </FormGroup>
                </Col>
                <Col md="6" lg="6">
                    <FormGroup row>
                        <Col sm="4">
                            <RedAsteriskComponent />
                            <Label for="reportedAt" className="mb-0">Reported at</Label>
                        </Col>
                        <Col sm="8">
                            <Input
                                type="date"
                                id="reportedAt"
                                name="reportedAt"
                                value={formData.reportedAt}
                                onChange={handleInputChange}
                                max={getCurrentDateString()}
                            />
                        </Col>
                    </FormGroup>
                </Col>
                <Col md="6" lg="6">
                    <FormGroup row>
                        <Col sm="4">
                            <LabelWrapper>
                                <RedAsteriskComponent />
                                <Label for="reportedByUser" className="mb-0">Reported By</Label>
                            </LabelWrapper>
                        </Col>
                        <Col sm="8">
                            <Select
                                id="reportedByUser"
                                value={formData.reportedByUser}
                                onChange={(option) => handleSelectChange('reportedByUser', option)}
                                options={data?.reporters?.map(user => ({
                                    value: user.id.toString(),
                                    label: user.name
                                })) || []}
                                placeholder="--Select User--"
                                aria-label="Reported By User"
                            />
                        </Col>
                    </FormGroup>
                </Col>

            </Row>
        </Card>
    );
};
const CustomSignatureCanvas = ({ width = 300, height = 150, onSignatureChange, initialValue }) => {
    const canvasRef = useRef(null);
    const [isDrawing, setIsDrawing] = useState(false);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.lineWidth = 2;
        context.lineCap = 'round';
        context.strokeStyle = 'black';

        if (initialValue) {
            const img = new Image();
            img.crossOrigin = 'anonymous'
            img.onload = () => {
                context.drawImage(img, 0, 0, width, height);
            };
            img.src = initialValue;
        }
    }, [initialValue, width, height]);

    const startDrawing = (e) => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const context = canvas.getContext('2d');
        setIsDrawing(true);
        context.beginPath();
        context.moveTo(e.clientX - rect.left, e.clientY - rect.top);
    };

    const draw = (e) => {
        if (!isDrawing) return;
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const context = canvas.getContext('2d');
        context.lineTo(e.clientX - rect.left, e.clientY - rect.top);
        context.stroke();
    };

    const stopDrawing = () => {
        setIsDrawing(false);
        const canvas = canvasRef.current;
        const base64String = canvas.toDataURL('image/png');
        onSignatureChange(base64String);
    };

    const clearCanvas = (e) => {
        e.preventDefault();
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
        onSignatureChange('');
    };

    return (
        <div className="custom-signature-canvas">
            <canvas
                ref={canvasRef}
                width={width}
                height={height}
                onMouseDown={startDrawing}
                onMouseMove={draw}
                onMouseUp={stopDrawing}
                onMouseOut={stopDrawing}
                style={{ border: '1px solid #000' }}
            />
            <button onClick={clearCanvas} className="clear-button">Clear</button>
        </div>
    );
};

const GoSeeProgramSection = ({
    formData,
    handleInputChange,
    handleSelectChange,
    data,
    signatures,
    setSignatures,
    setFormData
}) => {
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64String = event.target.result;
                setFormData(prevData => ({
                    ...prevData,
                    interventionFile: base64String,
                    icFileName: file.name
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const addSignatureRow = () => {
        const newId = Date.now();
        setSignatures(prevSignatures => [
            ...prevSignatures,
            {
                id: newId,
                isNew: true,
                submittedSign: "",
                reviewedSign: "",
                oldSubmittedSign: "",
                oldReviewedSign: "",
                createdAt: new Date().toISOString(),
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                submittedAt: null
            }
        ]);
    };

    const updateSignature = (id, field, base64String) => {
        setSignatures(prevSignatures =>
            prevSignatures.map(sig =>
                sig.id === id ? { ...sig, [field]: base64String, updatedAt: new Date().toISOString() } : sig
            )
        );
    };

    const removeSignatureRow = (id) => {
        setSignatures(signatures.filter(sig => sig.id !== id));
    };

    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardHeader style={{ backgroundColor: '#e6f2ff', borderBottom: '1px solid #b8daff' }}>
                <h2 className="card-title mb-0">Go See Program details</h2>
            </CardHeader>
            <Row style={{ marginBottom: '1rem', marginTop: '1rem', padding: '20px' }}>
                <Col md="4">
                    <FormGroup>
                        <RedAsteriskComponent />
                        <Label for="interventionType">Intervention Type</Label>
                        <Select
                            id="interventionType"
                            name="interventionType"
                            value={formData.interventionType}
                            onChange={(option) => handleSelectChange('interventionType', option)}
                            options={data?.interventionTypes?.map(type => ({
                                value: type.toString(),
                                label: type.toString()
                            })) || []}
                            placeholder="--Select Intervention Type--"
                        />
                    </FormGroup>
                </Col>
                {formData.interventionType && formData.interventionType.value === 'Other' && (
                    <Col md="8">
                        <FormGroup>
                            <Label for="otherIntervention">Other</Label>
                            <Input
                                type="text"
                                id="otherIntervention"
                                name="otherIntervention"
                                value={formData.otherIntervention}
                                onChange={handleInputChange}
                                placeholder="Enter other intervention type"
                            />
                        </FormGroup>
                    </Col>
                )}
                <Col md="12">
                    <Row>
                        <Col md="4">
                            <FormGroup>
                                <Label for="whatThree1">1. What three (3) have you made this week?</Label>
                                <Input
                                    type="text"
                                    id="whatThree1"
                                    name="whatThree1"
                                    value={formData.whatThree1}
                                    onChange={handleInputChange}
                                    placeholder="Enter first thing"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label for="whatThree2">2. What three (3) have you made this week?</Label>
                                <Input
                                    type="text"
                                    id="whatThree2"
                                    name="whatThree2"
                                    value={formData.whatThree2}
                                    onChange={handleInputChange}
                                    placeholder="Enter second thing"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label for="whatThree3">3. What three (3) have you made this week?</Label>
                                <Input
                                    type="text"
                                    id="whatThree3"
                                    name="whatThree3"
                                    value={formData.whatThree3}
                                    onChange={handleInputChange}
                                    placeholder="Enter third thing"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <InterventionPhotoComponent
                    formData={formData}
                    setFormData={setFormData}
                    handleFileChange={handleFileChange}
                />
                <Col md="6">
                    <FormGroup>
                        <Label for="mainDescription">Main Description</Label>
                        <Input
                            type="textarea"
                            id="mainDescription"
                            name="mainDescription"
                            value={formData.mainDescription}
                            onChange={handleInputChange}
                            placeholder="Enter main description"
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label for="description">Description</Label>
                        <Input
                            type="text"
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                            placeholder="Enter description"
                        />
                    </FormGroup>
                </Col>
                <Col md="12">
                    <FormGroup>
                        <Label>Signatures</Label>
                        {signatures.map((sig) => (
                            <Row key={sig.id} className="mb-3">
                                <Col md="5">
                                    <Label>Submitted By Sign</Label>
                                    <CustomSignatureCanvas
                                        width={300}
                                        height={150}
                                        onSignatureChange={(base64String) => updateSignature(sig.id, 'submittedSign', base64String)}
                                        initialValue={sig.submittedSign}
                                    />
                                </Col>
                                <Col md="5">
                                    <Label>Reviewed By Sign</Label>
                                    <CustomSignatureCanvas
                                        width={300}
                                        height={150}
                                        onSignatureChange={(base64String) => updateSignature(sig.id, 'reviewedSign', base64String)}
                                        initialValue={sig.reviewedSign}
                                    />
                                </Col>
                                <Col md="2">
                                    <Button color="danger" onClick={() => removeSignatureRow(sig.id)} className="mt-4">
                                        <i className="fas fa-minus"></i> Remove
                                    </Button>
                                </Col>
                            </Row>
                        ))}
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button color="primary" onClick={addSignatureRow}>
                                <i className="fas fa-plus"></i> Add Signature
                            </Button>
                        </div>
                    </FormGroup>
                </Col>
                <Col md="12">
                    <FormGroup>
                        <RedAsteriskComponent />
                        <Label for="dateOfGoSeeIntervention">Date of Go See Intervention</Label>
                        <Input
                            type="date"
                            id="dateOfGoSeeIntervention"
                            name="dateOfGoSeeIntervention"
                            value={formData.dateOfGoSeeIntervention}
                            onChange={handleInputChange}
                            max={getCurrentDateString()}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </Card>
    );
};
// const GoSeeProgramSection = ({
//     formData,
//     handleInputChange,
//     handleSelectChange,
//     data,
//     signatures,
//     setSignatures,
//     signatureRefs,
//     setFormData
// }) => {


//     const [signaturePoints, setSignaturePoints] = useState({});

//     const handleFileChange = (e) => {
//         const file = e.target.files[0];
//         if (file) {
//             const reader = new FileReader();
//             reader.onload = (event) => {
//                 const base64String = event.target.result;
//                 setFormData(prevData => ({
//                     ...prevData,
//                     interventionFile: base64String,
//                     icFileName: file.name
//                 }));
//             };
//             reader.readAsDataURL(file);
//         }
//     };

//     useEffect(() => {
//         // Load existing signatures onto the canvas
//         signatures.forEach((sig) => {
//             if (sig.submittedSign) {
//                 loadImageOnCanvas(`submitted_${sig.id}`, sig.submittedSign);
//             }
//             if (sig.reviewedSign) {
//                 loadImageOnCanvas(`reviewed_${sig.id}`, sig.reviewedSign);
//             }
//         });
//     }, [signatures]);

//     const loadImageOnCanvas = (refKey, imageUrl) => {
//         const canvas = signatureRefs.current[refKey];
//         if (canvas) {
//             const img = new Image();
//             img.onload = () => {
//                 canvas.fromDataURL(imageUrl);
//             };
//             img.src = imageUrl;
//         }
//     };
//     const addSignatureRow = () => {
//         const newId = Date.now();
//         setSignatures(prevSignatures => [
//             ...prevSignatures,
//             {
//                 id: newId,
//                 isNew: true,
//                 submittedSign: "",
//                 reviewedSign: "",
//                 oldSubmittedSign: "",
//                 oldReviewedSign: "",
//                 createdAt: new Date().toISOString(),
//                 createdBy: null,
//                 updatedAt: null,
//                 updatedBy: null,
//                 submittedAt: null
//             }
//         ]);
//     };


//     const updateSignature = (id, field) => {
//         setSignatures(prevSignatures =>
//             prevSignatures.map(sig =>
//                 sig.id === id ? { ...sig, [field]: 'modified', updatedAt: new Date().toISOString() } : sig
//             )
//         );
//     };


//     const removeSignatureRow = (id) => {
//         setSignatures(signatures.filter(sig => sig.id !== id));
//         setSignaturePoints(prev => {
//             const newPoints = { ...prev };
//             delete newPoints[id];
//             return newPoints;
//         });
//     };


//     const clearSignature = (id, field) => {
//         const canvas = signatureRefs.current[`${field}_${id}`];
//         if (canvas) {
//             canvas.clear();
//             updateSignature(id, field);
//         }
//     };
//     useEffect(() => {
//         signatures.forEach((sig) => {
//             const submittedCanvas = signatureRefs.current[`submitted_${sig.id}`];
//             const reviewedCanvas = signatureRefs.current[`reviewed_${sig.id}`];

//             if (submittedCanvas && sig.submittedSign && !sig.isNew && sig.submittedSign !== 'modified') {
//                 submittedCanvas.fromDataURL(sig.submittedSign);
//             }
//             if (reviewedCanvas && sig.reviewedSign && !sig.isNew && sig.reviewedSign !== 'modified') {
//                 reviewedCanvas.fromDataURL(sig.reviewedSign);
//             }
//         });
//     }, [signatures]);

//     return (
//         <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
//             <CardHeader style={{ backgroundColor: '#e6f2ff', borderBottom: '1px solid #b8daff' }}>
//                 <h2 className="card-title mb-0">Go See Program details</h2>
//             </CardHeader>
//             <Row style={{ marginBottom: '1rem', marginTop: '1rem', padding: '20px' }}>
//                 <Col md="4">
//                     <FormGroup>
//                         <RedAsteriskComponent />
//                         <Label for="interventionType">Intervention Type</Label>
//                         <Select
//                             id="interventionType"
//                             name="interventionType"
//                             value={formData.interventionType}
//                             onChange={(option) => handleSelectChange('interventionType', option)}
//                             options={data?.interventionTypes?.map(type => ({
//                                 value: type.toString(),
//                                 label: type.toString()
//                             })) || []}
//                             placeholder="--Select Intervention Type--"
//                         />
//                     </FormGroup>
//                 </Col>
//                 {formData.interventionType && formData.interventionType.value === 'Other' && (
//                     <Col md="8">
//                         <FormGroup>
//                             <Label for="otherIntervention">Other</Label>
//                             <Input
//                                 type="text"
//                                 id="otherIntervention"
//                                 name="otherIntervention"
//                                 value={formData.otherIntervention}
//                                 onChange={handleInputChange}
//                                 placeholder="Enter other intervention type"
//                             />
//                         </FormGroup>
//                     </Col>
//                 )}
//                 <Col md="12">
//                     <Row>
//                         <Col md="4">
//                             <FormGroup>
//                                 <Label for="whatThree1">1. What three (3) have you made this week?</Label>
//                                 <Input
//                                     type="text"
//                                     id="whatThree1"
//                                     name="whatThree1"
//                                     value={formData.whatThree1}
//                                     onChange={handleInputChange}
//                                     placeholder="Enter first thing"
//                                 />
//                             </FormGroup>
//                         </Col>
//                         <Col md="4">
//                             <FormGroup>
//                                 <Label for="whatThree2">2. What three (3) have you made this week?</Label>
//                                 <Input
//                                     type="text"
//                                     id="whatThree2"
//                                     name="whatThree2"
//                                     value={formData.whatThree2}
//                                     onChange={handleInputChange}
//                                     placeholder="Enter second thing"
//                                 />
//                             </FormGroup>
//                         </Col>
//                         <Col md="4">
//                             <FormGroup>
//                                 <Label for="whatThree3">3. What three (3) have you made this week?</Label>
//                                 <Input
//                                     type="text"
//                                     id="whatThree3"
//                                     name="whatThree3"
//                                     value={formData.whatThree3}
//                                     onChange={handleInputChange}
//                                     placeholder="Enter third thing"
//                                 />
//                             </FormGroup>
//                         </Col>
//                     </Row>
//                 </Col>
//                 <InterventionPhotoComponent
//                     formData={formData}
//                     setFormData={setFormData}
//                     handleFileChange={handleFileChange}
//                 />
//                 <Col md="6">
//                     <FormGroup>
//                         <Label for="mainDescription">Main Description</Label>
//                         <Input
//                             type="textarea"
//                             id="mainDescription"
//                             name="mainDescription"
//                             value={formData.mainDescription}
//                             onChange={handleInputChange}
//                             placeholder="Enter main description"
//                         />
//                     </FormGroup>
//                 </Col>
//                 <Col md="6">
//                     <FormGroup>
//                         <Label for="description">Description</Label>
//                         <Input
//                             type="text"
//                             id="description"
//                             name="description"
//                             value={formData.description}
//                             onChange={handleInputChange}
//                             placeholder="Enter description"
//                         />
//                     </FormGroup>
//                 </Col>
//                 <Col md="12">
//                     <FormGroup>
//                         <Label>Signatures</Label>
//                         {signatures.map((sig) => (
//                             <Row key={sig.id} className="mb-3">
//                                 <Col md="5">
//                                     <Label>Submitted By Sign</Label>
//                                     <SignatureCanvas
//                                         ref={(ref) => signatureRefs.current[`submitted_${sig.id}`] = ref}
//                                         penColor='black'
//                                         canvasProps={{ width: 300, height: 150, className: 'sigCanvas', style: { border: '1px solid #000' } }}
//                                         onEnd={() => updateSignature(sig.id, 'submittedSign')}
//                                     />
//                                     <Button onClick={() => clearSignature(sig.id, 'submittedSign')} className="mt-2">Clear</Button>
//                                 </Col>
//                                 <Col md="5">
//                                     <Label>Reviewed By Sign</Label>
//                                     <SignatureCanvas
//                                         ref={(ref) => signatureRefs.current[`reviewed_${sig.id}`] = ref}
//                                         penColor='black'
//                                         canvasProps={{ width: 300, height: 150, className: 'sigCanvas', style: { border: '1px solid #000' } }}
//                                         onEnd={() => updateSignature(sig.id, 'reviewedSign')}
//                                     />
//                                     <Button onClick={() => clearSignature(sig.id, 'reviewedSign')} className="mt-2">Clear</Button>
//                                 </Col>
//                                 <Col md="2">
//                                     <Button color="danger" onClick={() => removeSignatureRow(sig.id)} className="mt-4">
//                                         <i className="fas fa-minus"></i> Remove
//                                     </Button>
//                                 </Col>
//                             </Row>
//                         ))}
//                         <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
//                             <Button color="primary" onClick={addSignatureRow}>
//                                 <i className="fas fa-plus"></i> Add Signature
//                             </Button>
//                         </div>
//                     </FormGroup>
//                 </Col>
//                 <Col md="12">
//                     <FormGroup>
//                         <RedAsteriskComponent />
//                         <Label for="dateOfGoSeeIntervention">Date of Go See Intervention</Label>
//                         <Input
//                             type="date"
//                             id="dateOfGoSeeIntervention"
//                             name="dateOfGoSeeIntervention"
//                             value={formData.dateOfGoSeeIntervention}
//                             onChange={handleInputChange}
//                         />
//                     </FormGroup>
//                 </Col>
//             </Row>
//         </Card>
//     );
// };


const ActionModal = ({ isOpen, toggle, onInitiateAction, data, message }) => {
    const dispatch = useDispatch();
    const tags = useSelector((state) => state.tag.list);
    const [initiateModalOpen, setInitiateModalOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const [actionFormData, setActionFormData] = useState({
        submitDate: new Date().toISOString().split('T')[0],
        status: { value: 'open', label: 'Open' },
        description: '',
        tags: [],
        comments: []
    });

    const statusOptions = [
        { value: 'OPEN', label: 'Open' },
        { value: 'CLOSED', label: 'Close' }
    ];

    const resetForm = () => {
        setActionFormData({
            submitDate: new Date().toISOString().split('T')[0],
            targetAt: '',
            status: { value: 'open', label: 'Open' },
            description: '',
            tags: [],
            comments: []
        });
    };

    const toggleInitiateModal = (action = true) => {
        setInitiateModalOpen(!initiateModalOpen);
        setSelectedAction(action);
        if (action) {
            const selectedTags = action.tags
                ? action.tags.map(tagId => {
                    const matchingTag = tags.find(tag => tag.id.toString() === tagId.toString());
                    return matchingTag
                        ? { value: matchingTag.id.toString(), label: matchingTag.name }
                        : null;
                }).filter(tag => tag !== null)
                : [];
            setActionFormData({
                submittedAt: action.submittedAt,
                submitDate: formatDateWithTime(action.submittedAt, false) || new Date().toISOString().split('T')[0],
                targetAt: formatDateWithTime(action.targetAt, false) || '',
                description: action.description,
                reply: action.message || '',
                tags: selectedTags,
                comments: action.comments || [],
                type: action.type,
                targetDays: action.targetDays,
                status: action.status,
                id: action.id,
                userId: action.userId,
                createdBy: action.createdBy,
                updatedBy: action.updatedBy,
                createdAt: action.createdAt,
                updatedAt: action.updatedAt
            });
        } else {
            resetForm();
        }
    };

    const handleActionFormChange = (e) => {
        const { name, value } = e.target;
        setActionFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSelectChange = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        setActionFormData(prevState => ({
            ...prevState,
            [name]: name === 'status' ? selectedOption.value : selectedOption
        }));
    };
    const addCommentRow = () => {
        setActionFormData(prevState => ({
            ...prevState,
            comments: [...prevState.comments, {
                id: null,
                description: '',
                createdBy: null,
                updatedBy: null,
                createdAt: null,
                updatedAt: null
            }]
        }));
    };

    const removeCommentRow = (index) => {
        setActionFormData(prevState => ({
            ...prevState,
            comments: prevState.comments.filter((_, i) => i !== index)
        }));
    };

    const handleCommentChange = (index, value) => {
        setActionFormData(prevState => ({
            ...prevState,
            comments: prevState.comments.map((comment, i) =>
                i === index ? { ...comment, description: value } : comment
            )
        }));
    };
    const handleInitiateSubmit = (e) => {
        e.preventDefault();
        const action = {
            ...actionFormData,
            submitDate: new Date().toISOString().split('T')[0],
            status: actionFormData.status || 'PENDING',
            tags: Array.isArray(actionFormData.tags) && actionFormData.tags.length > 0
                ? actionFormData.tags
                    .filter(tag => tag && typeof tag.value !== 'undefined')
                    .map(tag => parseInt(tag.value))
                : []
        };
        onInitiateAction(action);
        toggleInitiateModal();
    };

    useEffect(() => {
        dispatch(getTags());
    }, [dispatch]);

    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} size="xl">
                <ModalHeader toggle={toggle}>
                    {message && <p>{message}</p>}
                    Number: {data?.sequenceNo}
                </ModalHeader>
                <ModalBody>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Action Type</th>
                                <th>Submit Date</th>
                                <th>From</th>
                                <th>Message Description</th>
                                <th>To</th>
                                <th>Response Message</th>
                                <th>Response On</th>
                                <th>Response By</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.actions?.map((action, index) => (
                                <tr key={index}>
                                    <td>
                                        <Button color="primary" size="sm" onClick={() => toggleInitiateModal(action)}>
                                            {action.status}
                                        </Button>
                                    </td>
                                    <td>{action.actionType}</td>
                                    <td>{action.submittedAt}</td>
                                    <td>{action.createdByUser}</td>
                                    <td>{action.description}</td>
                                    <td>{action.createdFor}</td>
                                    <td>{action.reply}</td>
                                    <td>{action.responseAt}</td>
                                    <td>{action.responseByUser}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={initiateModalOpen} toggle={toggleInitiateModal} size="lg">
                <Form onSubmit={handleInitiateSubmit}>
                    <ModalHeader toggle={toggleInitiateModal}>
                        {selectedAction ? 'Edit Action' : 'Initiate New Action'}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="submitDate">Submit Date</Label>
                                    <Input
                                        type="date"
                                        name="submitDate"
                                        id="submitDate"
                                        value={actionFormData.submitDate}

                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="targetAt">Target Date</Label>
                                    <Input
                                        type="date"
                                        name="targetAt"
                                        id="targetAt"
                                        value={actionFormData.targetAt}
                                        onChange={handleActionFormChange}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="status">Status</Label>
                                    <Select
                                        name="status"
                                        options={statusOptions}
                                        value={statusOptions.find(option => option.value === actionFormData.status) || null}
                                        onChange={handleSelectChange}
                                    // isDisabled={actionFormData.status === 'PENDING'}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <Label for="description">Description</Label>
                            <Input
                                type="textarea"
                                name="description"
                                id="description"
                                value={actionFormData.description}
                                onChange={handleActionFormChange}
                                disabled
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="message">Response Message</Label>
                            <Input
                                type="textarea"
                                name="message"
                                id="message"
                                value={actionFormData.message}
                                onChange={handleActionFormChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="tags">Tags</Label>
                            <Select
                                isMulti
                                name="tags"
                                options={tags?.map(tag => ({
                                    value: tag.id.toString(),
                                    label: tag.name
                                })) || []}
                                value={actionFormData.tags}
                                onChange={handleSelectChange}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Comments</Label>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {actionFormData.comments.map((comment, index) => (
                                        <tr key={index}>
                                            <td>
                                                <Input
                                                    type="textarea"
                                                    value={comment.description}
                                                    onChange={(e) => handleCommentChange(index, e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                <Button color="danger"
                                                    onClick={() => removeCommentRow(index)}><DeleteOutlined /></Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Button color="primary" onClick={addCommentRow}>Add Comment</Button>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleInitiateModal}>Cancel</Button>
                        <Button color="primary" type="submit">Submit</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};
const AttachmentsSection = ({ attachments = [], onAttachmentsChange }) => {
    const [internalKeys, setInternalKeys] = useState({});

    useEffect(() => {
        // Initialize internalKeys with existing attachments
        const initialKeys = attachments.reduce((acc, attachment, index) => {
            acc[`attachment_${index}`] = attachment;
            return acc;
        }, {});
        setInternalKeys(initialKeys);
    }, []);

    const generateKey = () => `attachment_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

    const addNewRow = () => {
        const newAttachment = {
            filename: '',
            name: '',
            description: '',
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            file: null,
            path: null
        };
        const newKey = generateKey();
        setInternalKeys(prev => ({ ...prev, [newKey]: newAttachment }));
        onAttachmentsChange([...attachments, newAttachment]);
    };

    const updateAttachment = (key, field, value) => {
        const updatedAttachments = Object.values(internalKeys).map((att, index) =>
            att === internalKeys[key] ? { ...att, [field]: value } : att
        );
        setInternalKeys(prev => ({
            ...prev,
            [key]: { ...prev[key], [field]: value }
        }));
        onAttachmentsChange(updatedAttachments);
    };

    const handleFileChange = (key, file) => {
        if (file && file.size > 5 * 1024 * 1024) {
            alert("File size should not exceed 5MB");
            return;
        }

        const updatedAttachment = {
            ...internalKeys[key],
            filename: file.name,
            name: file.name,
            file: file,
            path: null,
        };
        setInternalKeys(prev => ({
            ...prev,
            [key]: updatedAttachment
        }));
        const updatedAttachments = Object.values(internalKeys).map(att =>
            att === internalKeys[key] ? updatedAttachment : att
        );
        onAttachmentsChange(updatedAttachments);
    };

    const deleteAttachment = (key) => {
        const filteredAttachments = Object.values(internalKeys).filter(att => att !== internalKeys[key]);
        setInternalKeys(prev => {
            const newKeys = { ...prev };
            delete newKeys[key];
            return newKeys;
        });
        onAttachmentsChange(filteredAttachments);
    };

    const viewAttachment = (attachment) => {
        if (attachment.file instanceof File) {
            const url = URL.createObjectURL(attachment.file);
            window.open(url, '_blank');
        } else if (attachment.fullPath || attachment.path) {
            fetch(`${encodeURIComponent(attachment.fullPath || attachment.path)}`)
                .then(response => response.blob())
                .then(blob => {
                    fileDownload(blob, attachment.fileName || attachment.filename || 'download');
                })
                .catch(error => {
                    console.error('Error downloading file:', error);
                    alert('Error downloading file. Please try again.');
                });
        }
    };

    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardHeader style={{
                backgroundColor: '#e6f2ff',
                borderBottom: '1px solid #b8daff',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <h2 className="card-title mb-0">Attachments</h2>
                <Button color="primary" size="sm" onClick={addNewRow}>
                    <i className="fas fa-plus"></i>
                </Button>
            </CardHeader>
            <CardBody>
                <Table>
                    <thead>
                        <tr>
                            <th>File Name</th>
                            <th>Description</th>
                            <th>Upload File</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(internalKeys).map(([key, attachment]) => (
                            <tr key={key}>
                                <td>
                                    {attachment.filename || attachment.name}
                                </td>
                                <td>
                                    <Input
                                        type="text"
                                        value={attachment.description}
                                        onChange={(e) => updateAttachment(key, 'description', e.target.value)}
                                        style={{ borderColor: 'black' }}
                                    />
                                </td>
                                <td>
                                    <FormGroup>
                                        <Label for={`file-${key}`} className="btn btn-secondary">
                                            {attachment.file || attachment.path ? 'Change File' : 'Upload File'}
                                        </Label>
                                        <Input
                                            type="file"
                                            id={`file-${key}`}
                                            onChange={(e) => handleFileChange(key, e.target.files[0])}
                                            style={{ display: 'none' }}
                                        />
                                    </FormGroup>
                                </td>
                                <td>
                                    <Button
                                        color="secondary"
                                        size="sm"
                                        className="mr-2"
                                        onClick={() => viewAttachment(attachment)}
                                        disabled={!attachment.file && !attachment.path}
                                    >
                                        {attachment.fullPath || attachment.path ? 'Download' : 'View'}
                                    </Button>
                                    <Button
                                        color="danger"
                                        size="sm"
                                        onClick={() => deleteAttachment(key)}
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
};
const ActionSection = ({ formData, handleInputChange, actionUsers }) => {
    const [actions, setActions] = useState(formData.actions || []);

    useEffect(() => {
        setActions(formData.actions || []);
    }, [formData.actions]);

    useEffect(() => {
        if (formData.furtherActionsRequired === 'No') {
            setActions([]);
            handleInputChange({ target: { name: 'actions', value: [] } });
        }
    }, [formData.furtherActionsRequired]);

    const handleActionChange = (index, field, value) => {
        const newActions = [...actions];
        newActions[index][field] = value;
        setActions(newActions);
        handleInputChange({ target: { name: 'actions', value: newActions } });
    };

    const addAction = () => {
        const newActions = [...actions, { userId: '', description: '', type: 'N' }];
        setActions(newActions);
        handleInputChange({ target: { name: 'actions', value: newActions } });
    };

    const removeAction = (index) => {
        const newActions = actions.filter((_, i) => i !== index);
        setActions(newActions);
        handleInputChange({ target: { name: 'actions', value: newActions } });
    };

    const userOptions = actionUsers.map(user => ({
        value: user.id,
        label: `${user.name}`
    }));

    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardHeader style={{
                backgroundColor: '#e6f2ff',
                borderBottom: '1px solid #b8daff',
            }}>
                <h2 className="card-title mb-0">Actions</h2>
            </CardHeader>
            <CardBody>
                <>
                    <Button color="primary" size="sm" onClick={addAction}>
                        <i className="fas fa-plus"></i>
                    </Button>
                    <div className="mt-3">
                        <Table bordered>
                            <thead>
                                <tr>
                                    <th>To</th>
                                    <th>Description</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {actions.map((action, index) => (
                                    <tr key={index} style={action.type !== 'N' ? { backgroundColor: '#f0f0f0' } : {}}>
                                        <td>
                                            <Select
                                                value={userOptions.find(option => option.value === action.userId)}
                                                onChange={(selectedOption) => handleActionChange(index, 'userId', selectedOption.value)}
                                                options={userOptions}
                                                placeholder="Select User"
                                                isDisabled={action.type !== 'N'}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                type="textarea"
                                                value={action.description}
                                                onChange={(e) => handleActionChange(index, 'description', e.target.value)}
                                                placeholder="Type Description"
                                                disabled={action.type !== 'N'}
                                            />
                                        </td>
                                        <td>
                                            {action.type === 'N' ? (
                                                <Button color="danger" size="sm" onClick={() => removeAction(index)}>
                                                    <i className="fas fa-minus"></i>
                                                </Button>
                                            ) : (
                                                <span>-</span>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                    </div>

                </>
            </CardBody>
        </Card>
    );
};

const HSSESection = ({ formData, handleInputChange, handleSelectChange, data }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);

    const auditInstructions = {
        "audit_sections": [
            {
                "id": "Workplace",
                "label": "1.0 Workplace set-up & safety",
                "checked": false,
                "children": [
                    {
                        "id": "Workplace1",
                        "label": "1.1 Site Security must include quality fencing, lockable gates preventing unauthorized access",
                        "checked": false
                    },
                    {
                        "id": "Workplace2",
                        "label": "1.2 Safe access for workers. Safe access for public away from works area, adequately controlled works area, adequate signage",
                        "checked": false
                    },
                    {
                        "id": "Workplace3",
                        "label": "1.3 Workers & public protected from excavations, items dropped from height, crane & lifting operations",
                        "checked": false
                    },
                    {
                        "id": "Workplace4",
                        "label": "1.4 A GREEN and BLUE zone is established with rest facilities, noticeboard & all appropriate information displayed",
                        "checked": false
                    },
                    {
                        "id": "Workplace5",
                        "label": "1.5 Safety signage & traffic management in place (no smoking, First Aid, PPE, Contact information)",
                        "checked": false
                    },
                    {
                        "id": "Workplace6",
                        "label": "1.6 Visitor controls established (visitors recorded & hold Safety Passport)",
                        "checked": false
                    }
                ]
            },
            {
                "id": "ContractorGeneral",
                "label": "3.0 Contractor general HSSE conditions",
                "checked": false,
                "children": [
                    {
                        "id": "ContractorGeneral1",
                        "label": "3.1 Contractor has HSSE Policies, Safety Plans, Signage,access controls in place and visible",
                        "checked": false
                    },
                    {
                        "id": "ContractorGeneral2",
                        "label": "3.2 Designated smoking area outside hazardous area. Controls are in place to prevent drugs or alcohol use. Workers checked for fitness to work",
                        "checked": false
                    },
                    {
                        "id": "ContractorGeneral3",
                        "label": "3.3 Housekeeping is good with material storage areas, clear walking paths and waste removed in a timely manner",
                        "checked": false
                    },
                    {
                        "id": "ContractorGeneral4",
                        "label": "3.4 All plant and equipment is checked before use, electrical equipment tagged. All plant and equipment in good working order, all guards in place and safe to use",
                        "checked": false
                    },
                    {
                        "id": "ContractorGeneral5",
                        "label": "3.5 Drugs and alcohol are banned from site and all workers understand this",
                        "checked": false
                    },
                    {
                        "id": "ContractorGeneral6",
                        "label": "3.6 Hazardous waste is identified and removed as hazardous waste through licensed waste contractor. Waste disposal is tracked with records",
                        "checked": false
                    },
                    {
                        "id": "ContractorGeneral7",
                        "label": "3.7 Workers facilities are suitable, clean and hygienic. Fridges for food storage. No mosquito breeding areas",
                        "checked": false
                    }
                ]
            },
            {
                "id": "SafetyRegulations",
                "label": "4.0 Safety regulations, rules & requirements",
                "checked": false,
                "children": [
                    {
                        "id": "SafetyRegulations1",
                        "label": "4.1 Workers understand the controls required including LSR, JHA requirements and all these are in place",
                        "checked": false
                    },
                    {
                        "id": "SafetyRegulations2",
                        "label": "4.2 JHA, WCF, Permits, ER plans, posters,etc are in place and of good quality and relevant to the site-specific hazards. Toolbox discussion has occurred",
                        "checked": false
                    },
                    {
                        "id": "SafetyRegulations3",
                        "label": "4.3 Contractors should promote their own safety posters as well as Meinhardt's and Shell's. 9 LSR must be discussed and understood by all workers",
                        "checked": false
                    },
                    {
                        "id": "SafetyRegulations4",
                        "label": "4.4 Emergency Response plans are in place showing evacuation, assembly point, hospital, contacts, etc. Specific ER plans for high risk activities - CSE, Work at Height, excavation >1.2m",
                        "checked": false
                    },
                    {
                        "id": "SafetyRegulations5",
                        "label": "4.5 First Aid trained person is available at the work site and a fully stocked First Aid kit is available",
                        "checked": false
                    },
                    {
                        "id": "SafetyRegulations6",
                        "label": "4.6 Emergency response plans are in place and tested (including those for high-risk activities - CSE, WaH)",
                        "checked": false
                    }
                ]
            },
            {
                "id": "PPE",
                "label": "5.0 PPE",
                "checked": false,
                "children": [
                    {
                        "id": "PPE1",
                        "label": "5.1 PPE requirements are understood by all workers. PPE is worn properly and complies with the JHA, WCF, Permit",
                        "checked": false
                    },
                    {
                        "id": "PPE2",
                        "label": "5.2 Fall arrestor harnesses are inspected before use, are within date, are worn properly and connected to proper anchor point",
                        "checked": false
                    },
                    {
                        "id": "PPE3",
                        "label": "5.3 Specific PPE such FRC ia made available on site and in good condition with proper checking prior to use.",
                        "checked": false
                    },
                    {
                        "id": "PPE4",
                        "label": "5.4 Spare PPE is available at site for visitors and any worker who needs replacements.",
                        "checked": false
                    },
                    {
                        "id": "PPE5",
                        "label": "5.5 PPE is good quality and adequately maintained, site has a register of PPE issued",
                        "checked": false
                    }
                ]
            },
            {
                "id": "ProductSafety",
                "label": "6.0 Product Safety (fuels)",
                "checked": false,
                "children": [
                    {
                        "id": "ProductSafety1",
                        "label": "6.1 Hazardous zone map should be displayed for all work sites. Workers must be understand hazardous zones, product handling and flammable risks.",
                        "checked": false
                    },
                    {
                        "id": "ProductSafety2",
                        "label": "6.2 Works stop for tanker delivery. Work area is properly protected from public and vehicles. Signs are in place to warn public. Fuel vapours are managed for all hot works",
                        "checked": false
                    },
                    {
                        "id": "ProductSafety3",
                        "label": "6.3 All sources of ignition are controlled, signage in place, workers understand the risk and vapour sources, gas testing in place for Hot Work and CSE. Mobile phones must be kept in Green zone unless they meet the Shell requirements",
                        "checked": false
                    },
                    {
                        "id": "ProductSafety4",
                        "label": "6.4 Permits are in place for all high risk activities. Permit Issuer is on site during Hot Works, CSE and critical lifts",
                        "checked": false
                    },
                    {
                        "id": "ProductSafety5",
                        "label": "6.5 FRS clothes are worn where fire risk exists",
                        "checked": false
                    }
                ]
            },
            {
                "id": "HotWork",
                "label": "7.0 Hot Work",
                "checked": false,
                "children": [
                    {
                        "id": "HotWork1",
                        "label": "7.1 Good quality Hot work Permit is in place and Permit Issuer is on site during hot works. Gas testing is done and recorded",
                        "checked": false
                    },
                    {
                        "id": "HotWork2",
                        "label": "7.2 Workers understand the risks, vapour sources, controls and Permit requirements in place and comply fully.",
                        "checked": false
                    }
                ]
            },
            {
                "id": "ConfinedSpace",
                "label": "8.0 Confined Space Entry",
                "checked": false,
                "children": [
                    {
                        "id": "ConfinedSpace1",
                        "label": "8.1 Workers understand what confined spaces are and the PH has a Permit in place to control all CSE hazards.",
                        "checked": false
                    },
                    {
                        "id": "ConfinedSpace2",
                        "label": "8.2 Permit is good quality and discussed with workers. Rescue plan is in place and tested. Gas testing is in place and recorded",
                        "checked": false
                    },
                    {
                        "id": "ConfinedSpace3",
                        "label": "8.3 CSE checklist is in place and completed accurately",
                        "checked": false
                    }
                ]
            },
            {
                "id": "WorkHeight",
                "label": "9.0 Work at Height",
                "checked": false,
                "children": [
                    {
                        "id": "WorkHeight1",
                        "label": "9.1 Workers understand what Work at Height is - height above ground and height below ground. PH has identified all work at height activities and has these controlled properly",
                        "checked": false
                    },
                    {
                        "id": "WorkHeight2",
                        "label": "9.2 Ensure Permit is in place where required, controls must be understood and adhered to by all workers. Rescue plan must be in place and tested",
                        "checked": false
                    },
                    {
                        "id": "WorkHeight3",
                        "label": "9.3 All scaffold is inspected daily and tagged prior to use. Mobile scaffold is stable with wheel locks and outriggers",
                        "checked": false
                    },
                    {
                        "id": "WorkHeight4",
                        "label": "9.4 Harnesses are inspected before use by accredited person, are within expiry, inspections recorded. Connected to approved anchor point. and workers prevented from hitting the ground",
                        "checked": false
                    },
                    {
                        "id": "WorkHeight5",
                        "label": "9.5 Work at Height checklist is in place and complete",
                        "checked": false
                    }
                ]
            },
            {
                "id": "Excavation",
                "label": "10.0 Excavation",
                "checked": false,
                "children": [
                    {
                        "id": "Excavation1",
                        "label": "10.1 All underground services are identified and marked. Isolated if excavating or cutting nearby",
                        "checked": false
                    },
                    {
                        "id": "Excavation2",
                        "label": "10.2 Workers understand excavations >1.2m require a Permit. Permit is in place. May also be a CSE. Re",
                        "checked": false
                    },
                    {
                        "id": "Excavation3",
                        "label": "10.3 Permit is in place for all excavations >1.2m deep. Excavations >1.8 are properly barricaded",
                        "checked": false
                    },
                    {
                        "id": "Excavation4",
                        "label": "10.4 Two forms of properly constructed access and egress are in place*. Sides of excavation are battered, shored or sloped according to Shell requirements. soil and backfill >2m from the edge .",
                        "checked": false
                    },
                    {
                        "id": "Excavation5",
                        "label": "10.5 Excavation checklist is in place and complete",
                        "checked": false
                    }
                ]
            },
            {
                "id": "LOTOTO",
                "label": "11.0 LOTOTO (Isolation)",
                "checked": false,
                "children": [
                    {
                        "id": "LOTOTO1",
                        "label": "11.1 Overhead power lines within 3m of crane or works are identified and protected or isolated",
                        "checked": false
                    },
                    {
                        "id": "LOTOTO2",
                        "label": "11.2 Workers understand what situations require LOTOTO. They understand the controls recorded in the JHA, WCF, Permit. 2 forms of isolation are in place and effective",
                        "checked": false
                    },
                    {
                        "id": "LOTOTO3",
                        "label": "11.3 All risks of electrocution have been eliminated by applying effective LOTOTO",
                        "checked": false
                    },
                    {
                        "id": "LOTOTO4",
                        "label": "11.4 Mechanical LOTOTO is in place and effectively isolates energy sources and product.",
                        "checked": false
                    }
                ]
            },
            {
                "id": "Lifiting",
                "label": "12.0 Lifting and slinging",
                "checked": false,
                "children": [
                    {
                        "id": "Lifiting1",
                        "label": "12.1 Critical lift by cranes - Lifting document provided with sufficient information, site layout as per planned, certified crane operator",
                        "checked": false
                    },
                    {
                        "id": "Lifiting2",
                        "label": "12.2 Workers understand what situations require lifting documents. They understand the controls recorded in the JHA, WCF, Permit with effective toolbox conducted by PH & lifting supervisor.",
                        "checked": false
                    },
                    {
                        "id": "Lifiting3",
                        "label": "12.3 Mobile crane - simple lifting plan must be on site as per approved during CRS stage",
                        "checked": false
                    }
                ]
            }
        ]
    }
    formData.auditInstructions = auditInstructions

    const contractorOptions = [
        { value: '0', label: '--Select Company--' },
        { value: '3', label: 'Meinhardt EPCM India' },
        // Add more options as needed
    ];

    const programTypeOptions = [
        { value: '0', label: '--Select Program--' },
        { value: '1', label: '5-star toilet' },
        // Add more options as needed
    ];

    const auditorOptions = [
        { value: '0', label: '--Select Auditor--' },
        { value: '2', label: 'EMP1' },
        // Add more options as needed
    ];

    const regionOptions = [
        { value: '0', label: '--Select Region--' },
        { value: '1', label: 'Gujarat' },
        // Add more options as needed
    ];

    const handleMultiSelectChange = (sectionId, selectedItems) => {
        const newSelectedOptions = selectedOptions.filter(item => !item.startsWith(sectionId));
        selectedItems.forEach(item => {
            newSelectedOptions.push(`${sectionId}:${item.value}`);
        });
        setSelectedOptions(newSelectedOptions);
        handleInputChange({ target: { name: 'selectedHSSEOptions', value: newSelectedOptions } });
    };

    const handleSignatureEnd = (signatureType) => {
        if (signatureType === 'inspectedBy') {
            const signatureImage = formData.inspectedBySign.getTrimmedCanvas().toDataURL('image/png');
            handleInputChange('inspectedBySign', signatureImage);
        } else if (signatureType === 'checkedBy') {
            const signatureImage = formData.checkedBySign.getTrimmedCanvas().toDataURL('image/png');
            handleInputChange('checkedBySign', signatureImage);
        }
    };

    const clearSignature = (signatureType) => {
        if (signatureType === 'inspectedBy') {
            formData.inspectedBySign.clear();
            handleInputChange('inspectedBySign', null);
        } else if (signatureType === 'checkedBy') {
            formData.checkedBySign.clear();
            handleInputChange('checkedBySign', null);
        }
    };



    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardHeader style={{ backgroundColor: '#e6f2ff', borderBottom: '1px solid #b8daff' }}>
                <h2 className="card-title mb-0">HSSE Audit details Score [{formData.score || '0.00'} %]</h2>
            </CardHeader>
            <Row style={{ marginBottom: '1rem', marginTop: '1rem', padding: '20px' }}>
                <Col md="6">
                    <FormGroup>
                        <Label for="siteName">Site Name</Label>
                        <Input
                            type="text"
                            id="siteName"
                            name="siteName"
                            value={formData.siteName || ''}
                            onChange={(e) => handleInputChange('siteName', e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label for="contractor">Contractor</Label>
                        <Select
                            id="contractor"
                            name="contractor"
                            value={contractorOptions.find(option => option.value === formData.contractor)}
                            onChange={(option) => handleSelectChange('contractor', option.value)}
                            options={contractorOptions}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label for="programType">Program Type</Label>
                        <Select
                            id="programType"
                            name="programType"
                            value={programTypeOptions.find(option => option.value === formData.programType)}
                            onChange={(option) => handleSelectChange('programType', option.value)}
                            options={programTypeOptions}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label for="auditor">Auditor</Label>
                        <Select
                            id="auditor"
                            name="auditor"
                            value={auditorOptions.find(option => option.value === formData.auditor)}
                            onChange={(option) => handleSelectChange('auditor', option.value)}
                            options={auditorOptions}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label for="region">Region</Label>
                        <Select
                            id="region"
                            name="region"
                            value={regionOptions.find(option => option.value === formData.region)}
                            onChange={(option) => handleSelectChange('region', option.value)}
                            options={regionOptions}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label for="siteManagerName">Site Manager Name</Label>
                        <Input
                            type="text"
                            id="siteManagerName"
                            name="siteManagerName"
                            value={formData.siteManagerName || ''}
                            onChange={(e) => handleInputChange('siteManagerName', e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col md="12">
                    <FormGroup>
                        <Label>Signatures</Label>
                        <Row>
                            <Col md="6">
                                <Label>Inspected By Signature</Label>
                                <div style={{ border: '1px solid #ced4da', borderRadius: '5px', padding: '10px' }}>
                                    <SignatureCanvas
                                        penColor='black'
                                        canvasProps={{ width: 300, height: 150, className: 'sigCanvas' }}
                                        ref={(ref) => { formData.inspectedBySign = ref }}
                                        onEnd={() => handleSignatureEnd('inspectedBy')}
                                    />
                                </div>
                                <Button onClick={() => clearSignature('inspectedBy')}>Clear</Button>
                            </Col>
                            <Col md="6">
                                <Label>Checked By Signature</Label>
                                <div style={{ border: '1px solid #ced4da', borderRadius: '5px', padding: '10px' }}>
                                    <SignatureCanvas
                                        penColor='black'
                                        canvasProps={{ width: 300, height: 150, className: 'sigCanvas' }}
                                        ref={(ref) => { formData.checkedBySign = ref }}
                                        onEnd={() => handleSignatureEnd('checkedBy')}
                                    />
                                </div>
                                <Button onClick={() => clearSignature('checkedBy')}>Clear</Button>
                            </Col>
                        </Row>
                    </FormGroup>
                </Col>

                <Card className="mb-4">
                    <CardHeader>
                        <h5 className="mb-0">Instructions to auditor</h5>
                    </CardHeader>
                    <CardBody>
                        {auditInstructions.audit_sections.map((section) => (
                            <Row key={section.id} className="mb-3">
                                <Col md="4">
                                    <Label>{section.label}</Label>
                                </Col>
                                <Col md="8">
                                    <Select
                                        isMulti
                                        options={section.children.map(child => ({ value: child.id, label: child.label }))}
                                        onChange={(selectedItems) => handleMultiSelectChange(section.id, selectedItems)}
                                    />
                                </Col>
                            </Row>
                        ))}
                    </CardBody>
                </Card>


                <Col md="12">
                    <FormGroup>
                        <Label for="recommendations">Recommendations & comments</Label>
                        <Input
                            type="textarea"
                            id="recommendations"
                            name="recommendations"
                            value={formData.recommendations || ''}
                            onChange={(e) => handleInputChange('recommendations', e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </Card>
    );
};

const FAuditESection = ({ formData, handleInputChange, handleSelectChange, data }) => {
    const [selectedOptions, setSelectedOptions] = useState(() =>
        (formData.selectedHSSEOptions || []).map(option => ({
            parentId: option.parentId,
            childrenId: option.childrenId,
            label: option.label
        }))
    );

    const handleMultiSelectChange = (sectionId, selectedItems) => {
        const otherSectionsOptions = selectedOptions.filter(item => item.parentId !== sectionId);
        const newSectionOptions = selectedItems.map(item => ({
            parentId: sectionId,
            childrenId: item.value,
            label: item.label,
            value: item.value
        }));
        const newSelectedOptions = [...otherSectionsOptions, ...newSectionOptions];

        setSelectedOptions(newSelectedOptions);

        const updatedHSSEOptions = newSelectedOptions.map(option => option.childrenId);
        handleInputChange({
            target: {
                name: 'selectedHSSEOptions',
                value: updatedHSSEOptions
            }
        });
    };

    const handleSignatureChange = (signatureType, base64String) => {
        if (signatureType === 'inspectedBy') {
            handleInputChange('inspectedBySign', base64String);
            handleInputChange('oldInspectedBySign', null);
        } else if (signatureType === 'checkedBy') {
            handleInputChange('checkedBySign', base64String);
            handleInputChange('oldCheckedBySign', null);
        }
    };

    const clearSignature = (signatureType,e) => {
        e.preventDefault();
        if (signatureType === 'inspectedBy') {
            handleInputChange('inspectedBySign', null);
            handleInputChange('oldInspectedBySign', null);
        } else if (signatureType === 'checkedBy') {
            handleInputChange('checkedBySign', null);
            handleInputChange('oldCheckedBySign', null);
        }
    };

    const regions = data.regions.map(region => ({ value: region.id, label: region.name }));

    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardHeader style={{ backgroundColor: '#e6f2ff', borderBottom: '1px solid #b8daff' }}>
                <h2 className="card-title mb-0">HSSE Audit</h2>
            </CardHeader>
            <Row style={{ marginBottom: '1rem', marginTop: '1rem', padding: '20px' }}>
                <Col md="6">
                    <FormGroup>
                        <Label for="siteName">Site Name</Label>
                        <Input
                            type="text"
                            id="siteName"
                            name="siteName"
                            value={formData.siteName || ''}
                            onChange={(e) => handleInputChange('siteName', e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label for="region">Region</Label>
                        <Select
                            id="region"
                            name="region"
                            value={regions.find(option => option.value === formData.region)}
                            onChange={(option) => handleSelectChange('region', option.value)}
                            options={regions}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label for="auditor">Auditor</Label>
                        <Select
                            id="auditor"
                            name="auditor"
                            value={formData.auditor ? {
                                value: formData.auditor.value,
                                label: formData.auditor.label
                            } : null}
                            onChange={(option) => handleSelectChange('auditor', option)}
                            options={data.auditors.map(auditor => ({ value: auditor.id, label: auditor.name }))}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label for="auditedAt">Audited at</Label>
                        <Input
                            type="date"
                            id="auditedAt"
                            name="auditedAt"
                            value={formData.auditedAt || ''}
                            onChange={(e) => handleInputChange('auditedAt', e.target.value)}
                            max={getCurrentDateString()}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label for="siteManagerName">Site Manager Name</Label>
                        <Input
                            type="text"
                            id="siteManagerName"
                            name="siteManagerName"
                            value={formData.siteManagerName || ''}
                            onChange={(e) => handleInputChange('siteManagerName', e.target.value)}
                        />
                    </FormGroup>
                </Col>
                
                <Card className="mb-4">
                    <CardHeader>
                        <h5 className="mb-0">Instructions to auditor</h5>
                    </CardHeader>
                    <CardBody>
                        {data.fauditInstructions.map((section) => (
                            <Row key={section.id} className="mb-3">
                                <Col md="4">
                                    <Label>{section.label}</Label>
                                </Col>
                                <Col md="8">
                                    <Select
                                        isMulti
                                        options={section.children.map(child => ({ value: child.id, label: child.label }))}
                                        onChange={(selectedItems) => handleMultiSelectChange(section.id, selectedItems)}
                                        value={selectedOptions
                                            .filter(option => option.parentId === section.id)
                                            .map(option => ({
                                                value: option.childrenId,
                                                label: option.label
                                            }))
                                        }
                                    />
                                </Col>
                            </Row>
                        ))}
                    </CardBody>
                </Card>

                <Col md="12">
                    <FormGroup>
                        <Label>Signatures</Label>
                        <Row>
                            <Col md="6">
                                <Label>Inspected By Signature</Label>
                                <div style={{ border: '1px solid #ced4da', borderRadius: '5px', padding: '10px' }}>
                                    <CustomSignatureCanvas
                                        width={300}
                                        height={150}
                                        onSignatureChange={(base64String) => handleSignatureChange('inspectedBy', base64String)}
                                        initialValue={formData.inspectedBySign}
                                    />
                                </div>
                                
                            </Col>
                            <Col md="6">
                                <Label>Checked By Signature</Label>
                                <div style={{ border: '1px solid #ced4da', borderRadius: '5px', padding: '10px' }}>
                                    <CustomSignatureCanvas
                                        width={300}
                                        height={150}
                                        onSignatureChange={(base64String) => handleSignatureChange('checkedBy', base64String)}
                                        initialValue={formData.checkedBySign}
                                    />
                                </div>
                                
                            </Col>
                        </Row>
                    </FormGroup>
                </Col>

                <Col md="12">
                    <FormGroup>
                        <Label for="recommendations">Recommendations & comments</Label>
                        <Input
                            type="textarea"
                            id="recommendations"
                            name="recommendations"
                            value={formData.recommendations || ''}
                            onChange={(e) => handleInputChange('recommendations', e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </Card>
    );
};
const AuditFindingSection = ({ formData, handleInputChange, data }) => {
    const [findings, setFindings] = useState(formData.findings || []);

    const priorityOptions = useMemo(() =>
        data.priorities.map(priority => ({ value: priority, label: priority })),
        [data.priorities]
    );


    useEffect(() => {
        setFindings(formData.findings || []);
    }, [formData.findings]);

    const handleFindingChange = (index, field, value) => {
        const newFindings = [...findings];
        newFindings[index][field] = value;
        setFindings(newFindings);
        console.log(newFindings)
        handleInputChange({ target: { name: 'findings', value: newFindings } });
    };

    const handleImageUpload = (index, event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                handleFindingChange(index, 'image', reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const addFinding = () => {
        const newFindings = [...findings, {
            itemNo: '',
            findingCategoryId: '',
            priority: '',
            description: '',
            mhtRecommendation: '',
            image: null
        }];
        setFindings(newFindings);
        handleInputChange({ target: { name: 'findings', value: newFindings } });
    };

    const removeFinding = (index) => {
        const newFindings = findings.filter((_, i) => i !== index);
        setFindings(newFindings);
        handleInputChange({ target: { name: 'findings', value: newFindings } });
    };

    const findingCategoryOptions = useMemo(() =>
        data.findingCategories.map(category => ({
            value: category.id.toString(),
            label: category.name
        })),
        [data.findingCategories]
    );



    const itemNoOptions = useMemo(() => {

        if (!Array.isArray(formData.selectedHSSEOptions) || !Array.isArray(data.fauditInstructions)) {
            console.warn("formData.selectedHSSEOptions or data.fauditInstructions is not an array");
            return [];
        }

        return formData.selectedHSSEOptions.map((option, index) => {
            console.log(`Processing option ${index}:`, option);

            // If option is just an ID string
            if (typeof option === 'string') {
                for (let section of data.fauditInstructions) {
                    const child = section.children.find(c => c.id === option);
                    if (child) {
                        return {
                            value: child.id,
                            label: `${child.label}`
                        };
                    }
                }
            }
            // If option is an object
            else if (option && typeof option === 'object') {
                const section = data.fauditInstructions.find(s => s.id === option.parentId);
                if (section) {
                    return {
                        value: option.childrenId || option.id,
                        label: `${option.label}`
                    };
                }
            }

            console.warn(`Could not process option at index ${index}:`, option);
            return null;
        }).filter(Boolean); // Remove any null entries
    }, [formData.selectedHSSEOptions, data.fauditInstructions]);

    // After the useMemo, add this effect to log the result:
    useEffect(() => {
        console.log("itemNoOptions calculated:", itemNoOptions);
    }, [itemNoOptions]);



    useEffect(() => {
        console.log(formData.selectedHSSEOptions);
    }, [formData.selectedHSSEOptions]);



    useEffect(() => {
        if (formData.findings) {
            const updatedFindings = formData.findings.map(finding => {
                const matchingItemNo = itemNoOptions.find(option =>
                    option.value === finding.itemNo
                );

                const matchingCategory = finding.findingCategory
                    ? findingCategoryOptions.find(option =>
                        option.value === finding.findingCategory.toString()
                    )
                    : null;

                return {
                    ...finding,
                    itemNo: matchingItemNo ? matchingItemNo.value : null,
                    findingCategoryId: matchingCategory ? matchingCategory.value : null
                };
            });

            setFindings(updatedFindings);
        }
    }, [formData.findings]);  // Remove it


    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardHeader style={{
                backgroundColor: '#e6f2ff',
                borderBottom: '1px solid #b8daff',
            }}>
                <h2 className="card-title mb-0">Audit Findings</h2>
            </CardHeader>
            <CardBody>
                <Button color="primary" size="sm" onClick={addFinding}>
                    <i className="fas fa-plus"></i>
                </Button>
                <div className="mt-3">
                    {findings.map((finding, index) => (
                        <div key={index} className="mb-4 p-3"
                            style={{ border: '1px solid #ced4da', borderRadius: '5px' }}>
                            <div className="d-flex justify-content-end mb-2">
                                <Button color="danger" size="sm" onClick={() => removeFinding(index)}>
                                    <i className="fas fa-minus"></i>
                                </Button>
                            </div>
                            <Row>

                                <Col md="4">
                                    <FormGroup>
                                        <Label for={`itemNo-${index}`}>Item No</Label>
                                        <Select
                                            id={`itemNo-${index}`}
                                            value={itemNoOptions.find(option => option.value === finding.itemNo)}
                                            onChange={(selectedOption) => handleFindingChange(index, 'itemNo', selectedOption.value)}
                                            options={itemNoOptions}
                                            placeholder="Select Item No"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label for={`findingCategory-${index}`}>Finding Category</Label>
                                        <Select
                                            id={`findingCategory-${index}`}
                                            value={findingCategoryOptions.find(option => option.value === finding.findingCategoryId)}
                                            onChange={(selectedOption) => handleFindingChange(index, 'findingCategoryId', selectedOption.value)}
                                            options={findingCategoryOptions}
                                            placeholder="Select Finding Category"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label for={`priority-${index}`}>Priority</Label>
                                        <Select
                                            id={`priority-${index}`}
                                            value={priorityOptions.find(option => option.value === finding.priority)}
                                            onChange={(selectedOption) => handleFindingChange(index, 'priority', selectedOption.value)}
                                            options={priorityOptions}
                                            placeholder="Select Priority"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <Label for={`description-${index}`}>Description</Label>
                                        <Input
                                            id={`description-${index}`}
                                            type="textarea"
                                            value={finding.description}
                                            onChange={(e) => handleFindingChange(index, 'description', e.target.value)}
                                            placeholder="Provide details of the gap found"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label for={`mhtRecommendation-${index}`}>MHT Recommendation</Label>
                                        <Input
                                            id={`mhtRecommendation-${index}`}
                                            type="textarea"
                                            value={finding.mhtRecommendation}
                                            onChange={(e) => handleFindingChange(index, 'mhtRecommendation', e.target.value)}
                                            placeholder="Recommend actions for the findings captured"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label for={`image-${index}`}>Upload Image</Label>
                                        <Input
                                            type="file"
                                            id={`image-${index}`}
                                            accept="image/*"
                                            onChange={(e) => handleImageUpload(index, e)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    {finding.image && (
                                        <AntImage
                                            src={finding.image}
                                            alt="Uploaded"
                                            style={{ maxWidth: '100%', maxHeight: '200px' }}
                                            preview={{
                                                maskClassName: 'customize-mask',
                                                mask: <div>Click to view</div>,
                                                zIndex: 9999999
                                            }}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </div>
                    ))}
                </div>
            </CardBody>
        </Card>
    );
};


const LOD1Section = ({ formData, handleInputChange, handleSelectChange, data }) => {

    const [selectedOptions, setSelectedOptions] = useState(() =>
        (formData.selectedLDO1Options || []).map(option => ({
            parentId: option.parentId,
            childrenId: option.childrenId,
            label: option.label
        }))
    );

    const handleMultiSelectChange = (sectionId, selectedItems) => {
        console.log("handleMultiSelectChange called");
        console.log("sectionId:", sectionId);
        console.log("selectedItems:", selectedItems);

        const otherSectionsOptions = selectedOptions.filter(item => item.parentId !== sectionId);
        const newSectionOptions = selectedItems.map(item => ({
            parentId: sectionId,
            childrenId: item.value,
            label: item.label,
            value: item.value
        }));
        const newSelectedOptions = [...otherSectionsOptions, ...newSectionOptions];

        console.log("New selectedOptions:", newSelectedOptions);
        setSelectedOptions(newSelectedOptions);

        // Update formData.selectedHSSEOptions
        const updatedHSSEOptions = newSelectedOptions.map(option => option.childrenId);
        handleInputChange({
            target: {
                name: 'selectedLDO1Options',
                value: updatedHSSEOptions
            }
        });
    };

    const yesNoOptions = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
    ];


    const inspectedBySignRef = useRef(null);
    const checkedBySignRef = useRef(null);



    useEffect(() => {
        const loadSignatures = () => {
            if (formData.inspectedBySign && formData.inspectedBySign.startsWith('http') && inspectedBySignRef.current) {
                loadImageToCanvas(formData.inspectedBySign, inspectedBySignRef);
                handleInputChange('oldInspectedBySign', formData.inspectedBySign);
            }
            if (formData.checkedBySign && formData.checkedBySign.startsWith('http') && checkedBySignRef.current) {
                loadImageToCanvas(formData.checkedBySign, checkedBySignRef);
                handleInputChange('oldCheckedBySign', formData.checkedBySign);
            }
        };

        // Delay the execution to ensure the signature pad is ready
        const timer = setTimeout(loadSignatures, 100);

        return () => clearTimeout(timer);
    }, [formData.inspectedBySign, formData.checkedBySign]);


    const handleSignatureEnd = (signatureType) => {
        if (signatureType === 'inspectedBy' && inspectedBySignRef.current) {
            const signatureImage = inspectedBySignRef.current.toDataURL('image/png');
            handleInputChange('inspectedBySign', signatureImage);
            handleInputChange('oldInspectedBySign', null);
        } else if (signatureType === 'checkedBy' && checkedBySignRef.current) {
            const signatureImage = checkedBySignRef.current.toDataURL('image/png');
            handleInputChange('checkedBySign', signatureImage);
            handleInputChange('oldCheckedBySign', null);
        }
    };
    const clearSignature = (signatureType) => {
        if (signatureType === 'inspectedBy' && inspectedBySignRef.current) {
            inspectedBySignRef.current.clear();
            handleInputChange('inspectedBySign', null);
            handleInputChange('oldInspectedBySign', null);
        } else if (signatureType === 'checkedBy' && checkedBySignRef.current) {
            checkedBySignRef.current.clear();
            handleInputChange('checkedBySign', null);
            handleInputChange('oldCheckedBySign', null);
        }
    };

    const loadImageToCanvas = (url, signatureRef) => {
        if (!signatureRef.current) {
            console.error('Signature pad reference is not available');
            return;
        }

        const img = new Image();
        img.onload = () => {
            const signaturePad = signatureRef.current;
            if (signaturePad) {
                // Clear the existing signature
                signaturePad.clear();

                // Get the internal canvas context
                const ctx = signaturePad._ctx;
                const canvas = signaturePad._canvas;

                if (ctx && canvas) {
                    // Draw the image
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                    // Update the internal data of the signature pad
                    signaturePad._data = signaturePad.toData();
                } else {
                    console.error('Unable to get context or canvas from signature pad');
                }
            } else {
                console.error('Signature pad is not available');
            }
        };
        img.onerror = () => {
            console.error('Error loading image');
        };
        img.src = url;
    };

    useEffect(() => {
        if (formData.inspectedBySign && formData.inspectedBySign.startsWith('http')) {
            loadImageToCanvas(formData.inspectedBySign, inspectedBySignRef);
            handleInputChange('oldInspectedBySign', formData.inspectedBySign);
        }
        if (formData.checkedBySign && formData.checkedBySign.startsWith('http')) {
            loadImageToCanvas(formData.checkedBySign, checkedBySignRef);
            handleInputChange('oldCheckedBySign', formData.checkedBySign);
        }
    }, [formData.inspectedBySign, formData.checkedBySign]);



    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardHeader style={{ backgroundColor: '#e6f2ff', borderBottom: '1px solid #b8daff' }}>
                <h2 className="card-title mb-0">LOD1 Self Assurance Section</h2>
            </CardHeader>
            <Row style={{ marginBottom: '1rem', marginTop: '1rem', padding: '20px' }}>
                <Col md="6">
                    <FormGroup>
                        <RedAsteriskComponent />
                        <Label for="siteName">Site Name</Label>
                        <Input
                            type="text"
                            id="siteName"
                            name="siteName"
                            value={formData.siteName || ''}
                            onChange={(e) => handleInputChange('siteName', e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <RedAsteriskComponent />
                        <Label for="siteName">Permit Number</Label>
                        <Input
                            type="text"
                            id="permitNumber"
                            name="permitNumber"
                            value={formData.permitNumber || ''}
                            onChange={(e) => handleInputChange('permitNumber', e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <RedAsteriskComponent />
                        <Label for="siteName">Permit Issue Date</Label>
                        <Input
                            type="date"
                            id="permitDate"
                            name="permitDate"
                            value={formData.permitIssueData}
                            onChange={(e) => handleInputChange('permitIssueData', e.target.value)}
                            max={getCurrentDateString()}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <RedAsteriskComponent />
                    <FormGroup>
                        <Label for="siteName">Permit Issuer Name</Label>
                        <Input
                            type="text"
                            id="permitIssuer"
                            name="permitIssuer"
                            value={formData.permitIssuer || ''}
                            onChange={(e) => handleInputChange('permitIssuer', e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <RedAsteriskComponent />
                    <FormGroup>
                        <Label for="siteName">Permit Holder Name</Label>
                        <Input
                            type="text"
                            id="permitHolder"
                            name="permitHolder"
                            value={formData.permitHolder || ''}
                            onChange={(e) => handleInputChange('permitHolder', e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <RedAsteriskComponent />
                        <Label for="siteName">Assessor Name</Label>
                        <Input
                            type="text"
                            id="assesorName"
                            name="assesorName"
                            value={formData.assesorName || ''}
                            onChange={(e) => handleInputChange('assesorName', e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <RedAsteriskComponent />
                        <Label for="siteName">Site Supervisor</Label>
                        <Input
                            type="text"
                            id="siteSupervisior"
                            name="siteSupervisior"
                            value={formData.siteSupervisor || ''}
                            onChange={(e) => handleInputChange('siteSupervisor', e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col md="6" lg="6">
                    <FormGroup row>
                        <Label for="reportedAt">Audited at</Label>
                        <Input
                            type="date"
                            id="auditedAt"
                            name="auditedAt"
                            value={formData.auditedAt}
                            onChange={handleInputChange}
                            max={getCurrentDateString()}
                        />
                    </FormGroup>
                </Col>

                <Col md="12">
                    <FormGroup>
                        <Row>
                            <Col md="6">
                                <Label>Auditor Signature</Label>
                                <div style={{ border: '1px solid #ced4da', borderRadius: '5px', padding: '10px' }}>
                                    <SignatureCanvas
                                        penColor='black'
                                        canvasProps={{ width: 300, height: 150, className: 'sigCanvas' }}
                                        ref={inspectedBySignRef}
                                        onEnd={() => handleSignatureEnd('inspectedBy')}
                                    />
                                </div>
                                <Button onClick={() => clearSignature('inspectedBy')}>Clear</Button>
                            </Col>
                        </Row>
                    </FormGroup>
                </Col>
                <Card className="mb-4">
                    <CardHeader>
                        <h5 className="mb-0">Ratings: Yes- demonstrated with sufficient evidence; No- Activity deficiency; I- Improvement needed</h5>
                    </CardHeader>
                    <CardBody>
                        {data.lod1Ratings.map((section) => (
                            <Row key={section.id} className="mb-3">
                                <Col md="4">
                                    <Label>{section.label}</Label>
                                </Col>
                                <Col md="8">
                                    <Select
                                        isMulti
                                        options={section.children.map(child => ({ value: child.id, label: child.label }))}
                                        onChange={(selectedItems) => handleMultiSelectChange(section.id, selectedItems)}
                                        value={selectedOptions
                                            .filter(option => option.parentId === section.id)
                                            .map(option => ({
                                                value: option.childrenId,
                                                label: option.label
                                            }))
                                        }
                                    />
                                </Col>
                            </Row>
                        ))}
                    </CardBody>
                </Card>

                <Col md="6">
                    <FormGroup>
                        <Label for="siteManagerName">Copy of audit provided to client?</Label>
                        <Select
                            id="copyOptions"
                            name="copyOptions"
                            options={yesNoOptions}
                            value={formData.copyOptions}
                            onChange={(option) => handleSelectChange('copyOptions', option)}
                            placeholder="Select Options"
                        />
                    </FormGroup>
                </Col>


            </Row>
        </Card>
    );
};
const LOD1Findings = ({ formData, handleInputChange, data }) => {
    const [findings, setFindings] = useState(formData.findings || []);
    const severityOptions = useMemo(() =>
        data.severities.map(severity => ({ value: severity, label: severity })),
        [data.severities]
    );

    useEffect(() => {
        setFindings(formData.findings || []);
    }, [formData.findings]);

    const handleFindingChange = (index, field, value) => {
        const newFindings = [...findings];
        newFindings[index][field] = value;
        setFindings(newFindings);
        handleInputChange({ target: { name: 'findings', value: newFindings } });
    };

    const handleImageUpload = (index, event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                handleFindingChange(index, 'image', reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const addFinding = () => {
        const newFindings = [...findings, {
            itemNo: '',
            severity: '',
            description: '',
            comments: '',
            evidendce: '',
            image: null
        }];
        setFindings(newFindings);
        handleInputChange({ target: { name: 'findings', value: newFindings } });
    };

    const removeFinding = (index) => {
        const newFindings = findings.filter((_, i) => i !== index);
        setFindings(newFindings);
        handleInputChange({ target: { name: 'findings', value: newFindings } });
    };



    const itemNoOptions = useMemo(() => {
        if (formData.editForm) {
            if (!Array.isArray(formData.selectedLDO1Options) || !Array.isArray(data.lod1Ratings)) {
                console.warn("formData.selectedHSSEOptions or data.fauditInstructions is not an array");
                return [];
            }

            return formData.selectedLDO1Options.map((option, index) => {
                console.log(`Processing option ${index}:`, option);

                // If option is just an ID string
                if (typeof option === 'string') {
                    for (let section of data.lod1Ratings) {
                        const child = section.children.find(c => c.id === option);
                        if (child) {
                            return {
                                value: child.id,
                                label: `${section.label} - ${child.label}`
                            };
                        }
                    }
                }
                // If option is an object
                else if (option && typeof option === 'object') {
                    const section = data.lod1Ratings.find(s => s.id === option.parentId);
                    if (section) {
                        return {
                            value: option.childrenId || option.id,
                            label: `${section.label} - ${option.label}`
                        };
                    }
                }

                console.warn(`Could not process option at index ${index}:`, option);
                return null;
            }).filter(Boolean); // Remove any null entries
        } else {

            const filterNestedData = (data, selectedOptions) => {
                return data.lod1Ratings.flatMap(item =>
                    item.children.filter(child =>
                        selectedOptions.includes(child.id)
                    ).map(child => ({
                        value: child.id,
                        label: child.label
                    }))
                );
            };

            // Example usage:
            console.log("FILTER DATA");
            console.log("data.lod1Ratings:", data.lod1Ratings);
            console.log("formData.selectedLDO1Options:", formData.selectedLDO1Options);

            const filteredData = filterNestedData(data, formData.selectedLDO1Options);
            console.log("Filtered data:", filteredData);

            return filteredData;
        }
    }, [formData.editForm, formData.selectedLDO1Options, data.lod1Ratings]);



    useEffect(() => {
        console.log("ITEM OPTIONS")
        console.log(itemNoOptions)
    }, [itemNoOptions]);


    useEffect(() => {
        console.log(formData.LOD1Options);
    }, [formData.selectedLDO1Options]);



    useEffect(() => {
        if (formData.findings) {
            const updatedFindings = formData.findings.map(finding => {
                const matchingItemNo = itemNoOptions.find(option =>
                    option.value === finding.itemNo
                );

                return {
                    ...finding,
                    itemNo: matchingItemNo ? matchingItemNo.value : null,
                };
            });

            setFindings(updatedFindings);
        }
    }, [formData.findings]);  // Remove it

    useEffect(() => {
        console.log(data)
    }, [data])


    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardHeader style={{
                backgroundColor: '#e6f2ff',
                borderBottom: '1px solid #b8daff',
            }}>
                <h2 className="card-title mb-0">Audit Findings</h2>
            </CardHeader>
            <CardBody>
                <Button color="primary" size="sm" onClick={addFinding}>
                    <i className="fas fa-plus"></i>
                </Button>
                <div className="mt-3">
                    {findings.map((finding, index) => (
                        <div key={index} className="mb-4 p-3"
                            style={{ border: '1px solid #ced4da', borderRadius: '5px' }}>
                            <div className="d-flex justify-content-end mb-2">
                                <Button color="danger" size="sm" onClick={() => removeFinding(index)}>
                                    <i className="fas fa-minus"></i>
                                </Button>
                            </div>
                            <Row>

                                <Col md="4">
                                    <FormGroup>
                                        <Label for={`itemNo-${index}`}>Item No</Label>
                                        <Select
                                            id={`itemNo-${index}`}
                                            value={itemNoOptions.find(option => option.value === finding.itemNo)}
                                            onChange={(selectedOption) => handleFindingChange(index, 'itemNo', selectedOption.value)}
                                            options={itemNoOptions}
                                            placeholder="Select Item No"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label for={`severity-${index}`}>Severity</Label>
                                        <Select
                                            id={`priority-${index}`}
                                            value={severityOptions.find(option => option.value === finding.severity)}
                                            onChange={(selectedOption) => handleFindingChange(index, 'severity', selectedOption.value)}
                                            options={severityOptions}
                                            placeholder="Select Severity"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <Label for={`description-${index}`}>Description</Label>
                                        <Input
                                            id={`description-${index}`}
                                            type="textarea"
                                            value={finding.description}
                                            onChange={(e) => handleFindingChange(index, 'description', e.target.value)}
                                            placeholder="Provide details of the gap found"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label for={`Comments-${index}`}>Comments</Label>
                                        <Input
                                            id={`Comments-${index}`}
                                            type="textarea"
                                            value={finding.comments}
                                            onChange={(e) => handleFindingChange(index, 'comments', e.target.value)}
                                            placeholder="Comments"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label for={`Evidence-${index}`}>Evidence</Label>
                                        <Input
                                            id={`Evidence-${index}`}
                                            type="textarea"
                                            value={finding.evidence}
                                            onChange={(e) => handleFindingChange(index, 'evidence', e.target.value)}
                                            placeholder="Evidence"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label for={`image-${index}`}>Upload Image</Label>
                                        <Input
                                            type="file"
                                            id={`image-${index}`}
                                            accept="image/*"
                                            onChange={(e) => handleImageUpload(index, e)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    {finding.image && (
                                        <AntImage
                                            src={finding.image}
                                            alt="Uploaded"
                                            style={{ maxWidth: '100%', maxHeight: '200px' }}
                                            preview={{
                                                maskClassName: 'customize-mask',
                                                mask: <div>Click to view</div>,
                                                zIndex: 9999999
                                            }}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </div>
                    ))}
                </div>
            </CardBody>
        </Card>
    );
};


const ToolBoxTalkSection = ({ formData, handleInputChange, data }) => {
    const [effectiveness, setEffectiveness] = useState(formData.effectiveness || []);
    const [toolboxDiscussions, setToolboxDiscussions] = useState({});

    useEffect(() => {
        setEffectiveness(formData.effectiveness || []);

        // Initialize toolboxDiscussions with all items set to false
        const initialDiscussions = {};
        (data?.tbtDiscussions || []).forEach(item => {
            initialDiscussions[item.id] = false;
        });
        setToolboxDiscussions(Array.isArray(formData.toolboxDiscussions) ? formData.toolboxDiscussions : []);
    }, [formData.effectiveness, data?.tbtDiscussions]);

    const handleEffectivenessChange = (index, field, value) => {
        const newEffectiveness = [...effectiveness];
        newEffectiveness[index][field] = value;
        setEffectiveness(newEffectiveness);
        handleInputChange({ target: { name: 'effectiveness', value: newEffectiveness } });
    };

    const addEffectiveness = () => {
        const newEffectiveness = [...effectiveness, { effectiveness: '', areasForImprovement: '' }];
        setEffectiveness(newEffectiveness);
        handleInputChange({ target: { name: 'effectiveness', value: newEffectiveness } });
    };

    const removeEffectiveness = (index) => {
        const newEffectiveness = effectiveness.filter((_, i) => i !== index);
        setEffectiveness(newEffectiveness);
        handleInputChange({ target: { name: 'effectiveness', value: newEffectiveness } });
    };

    const handleCheckBoxChange = (id) => {
        const newToolboxDiscussions = {
            ...toolboxDiscussions,
            [id]: !toolboxDiscussions[id]
        };
        setToolboxDiscussions(newToolboxDiscussions);
        handleInputChange({
            target: {
                name: 'toolboxDiscussions',
                value: newToolboxDiscussions
            }
        });
    };

    const effectivenessOptions = [
        { value: 'NotEffective', label: 'Not Effective' },
        { value: 'RoomForImprovement', label: 'Room For Improvement' },
        { value: 'FullyEffective', label: 'Fully Effective' }
    ];

    const toolboxDiscussionItems = data?.tbtDiscussions ?? [];

    const handlePermitWorkChange = (selectedOption) => {
        handleInputChange({ target: { name: 'permitWork', value: selectedOption.value } });
    };

    const permitWorkOptions = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
    ];

    const handleToolboxDiscussionsChange = (selectedOptions) => {
        const selectedIds = selectedOptions.map(option => option.value);
        setToolboxDiscussions(selectedIds);
        handleInputChange({ target: { name: 'toolboxDiscussions', value: selectedIds } });
    };
    const toolboxDiscussionOptions = data?.tbtDiscussions?.map(item => ({
        value: item.id,
        label: item.label
    })) || [];

    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardHeader style={{
                backgroundColor: '#e6f2ff',
                borderBottom: '1px solid #b8daff',
            }}>
                <h2 className="card-title mb-0">Tool Box Talk Details 50%</h2>
            </CardHeader>
            <CardBody>
                <Row className="form-group mb-2">
                    <Label className="col-lg-3 col-md-4 form-label">Discussion Points</Label>
                    <Input
                        className="col-lg-9 col-md-8"
                        value={formData.discussionPoints || ''}
                        onChange={(e) => handleInputChange({ target: { name: 'discussionPoints', value: e.target.value } })}
                    />
                </Row>
                <Row className="form-group">
                    <Label className="col-lg-3 col-md-4 form-label">Permit Works</Label>
                    <Col lg={9} md={8}>
                        <Select
                            options={permitWorkOptions}
                            value={permitWorkOptions.find(option => option.value === formData.permitWork)}
                            onChange={handlePermitWorkChange}
                            isClearable={false}
                        />
                    </Col>
                </Row>
                <Row className="form-group mt-2 mb-2">
                    <Label className="col-lg-3 col-md-4 form-label">Tool box Discussions</Label>
                    <Col lg={9} md={8}>
                        <Select
                            options={toolboxDiscussionOptions}
                            value={toolboxDiscussionOptions.filter(option =>
                                Array.isArray(toolboxDiscussions) && toolboxDiscussions.includes(option.value)
                            )}
                            onChange={handleToolboxDiscussionsChange}
                            isMulti
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={3}>
                        <Label>Toolbox effectiveness</Label>
                    </Col>
                    <Col lg={9}>
                        <Table bordered>
                            <thead>
                                <tr>
                                    <th className="col-4">Effectiveness</th>
                                    <th>Areas for improvement</th>
                                    <th className="fit">
                                        <Button color="outline-primary" size="sm" onClick={addEffectiveness}>
                                            <i className="fas fa-plus"></i>
                                        </Button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {effectiveness.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <Select
                                                value={effectivenessOptions.find(option => option.value === item.effectiveness)}
                                                onChange={(selectedOption) => handleEffectivenessChange(index, 'effectiveness', selectedOption.value)}
                                                options={effectivenessOptions}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                value={item.areasForImprovement}
                                                onChange={(e) => handleEffectivenessChange(index, 'areasForImprovement', e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <Button color="outline-danger" size="sm" onClick={() => removeEffectiveness(index)}>
                                                <i className="fas fa-minus"></i>
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row className="form-group">
                    <Label className="col-lg-3 col-md-4 form-label">Site PH (Name)</Label>
                    <Input
                        className="col-lg-9 col-md-8"
                        value={formData.sitePHName || ''}
                        onChange={(e) => handleInputChange({ target: { name: 'sitePHName', value: e.target.value } })}
                    />
                </Row>
            </CardBody>
        </Card>
    );
};
const TalkNotTickSection = ({ formData, handleInputChange, data }) => {
    const tntObservations = data.tntObservations || [];

    const actionOptions = data.actionTakens.map(action => ({
        value: action,
        label: action
    }));

    const handleSelectChange = (name, selectedOption) => {
        handleInputChange({ target: { name, value: selectedOption.value } });
    };

    const renderSelect = (observation, index) => {
        const options = observation.children.map(child => ({
            value: child.id.toString(),
            label: <span>Score (<b>{child.id.toString()}</b>)-{child.label}</span>
        }));

        const fieldName = `option${index + 1}Selected`;

        // Find the matching selection from formData
        const selection = formData.selections?.find(s => s.parentId.toString() === observation.id.toString());
        const selectedValue = selection ? selection.childId.toString() : "0";

        return (
            <Row className="mb-3" key={observation.id}>
                <Col lg={6}>
                    <Label>{observation.label}</Label>
                </Col>
                <Col lg={6}>
                    <Select
                        options={options}
                        onChange={(selectedOption) => handleSelectChange(fieldName, selectedOption)}
                        value={options.find(option => option.value === selectedValue) || null}
                    />
                </Col>
            </Row>
        );
    };

    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardHeader style={{
                backgroundColor: '#e6f2ff',
                borderBottom: '1px solid #b8daff',
            }}>
                <h2 className="card-title mb-0">Tool Box Tick Details</h2>
            </CardHeader>
            <CardBody>
                {tntObservations.map((observation, index) => renderSelect(observation, index))}

                <Row className="mb-3">
                    <Col xl={2} md={3}>
                        <Label className="bg-danger p-1">Score less than 70:</Label>
                    </Col>
                    <Col xl={10} md={9}>
                        <Label>Stop the work and report results to L3/L4 contractor management. Work shall not resume until corrective actions are implemented.</Label>
                    </Col>
                    <Col xl={2} md={3}>
                        <Label className="bg-warning p-1">Score: 70 to 89:</Label>
                    </Col>
                    <Col xl={10} md={9}>
                        <Label>Provide coaching/training on improvement areas.</Label>
                    </Col>
                    <Col xl={2} md={3}>
                        <Label className="bg-success p-1">Score: 90 to 100:</Label>
                    </Col>
                    <Col xl={10} md={9}>
                        <Label>Compliment work crew and supervision.</Label>
                    </Col>
                </Row>
                <Row>
                    <Col xl={8} lg={7}>
                        <Row>
                            <Col xl={6}>
                                <FormGroup>
                                    <Label>WO Number</Label>
                                    <Input
                                        type="text"
                                        name="woNumber"
                                        value={formData.woNumber || ''}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xl={6}>
                                <FormGroup>
                                    <Label>Supervisor Name</Label>
                                    <Input
                                        type="text"
                                        name="supervisorName"
                                        value={formData.supervisorName || ''}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={4} lg={5}>
                        <FormGroup>
                            <Label>Action taken</Label>
                            <Select
                                options={actionOptions}
                                onChange={(selectedOption) => handleSelectChange('actionTaken', selectedOption)}
                                value={formData.actionTaken}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <Label>Comments</Label>
                            <Input
                                type="textarea"
                                name="comments"
                                value={formData.comments || ''}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};
const AuditChecklistSection = ({ auditInstructions }) => {
    const [checklistState, setChecklistState] = useState(() => {
        const initialState = {};
        auditInstructions.audit_sections.forEach(section => {
            initialState[section.id] = false;
            section.children.forEach(child => {
                initialState[child.id] = false;
            });
        });
        return initialState;
    });

    const handleCheckboxChange = (id) => {
        setChecklistState(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    return (
        <Card className="mt-4">
            <CardHeader>
                <h2 className="h4">Audit Checklist</h2>
            </CardHeader>
            <CardBody>
                {auditInstructions.audit_sections.map(section => (
                    <div key={section.id} className="mb-3">
                        <FormGroup check>
                            <Input
                                type="checkbox"
                                id={section.id}
                                checked={checklistState[section.id]}
                                onChange={() => handleCheckboxChange(section.id)}
                            />
                            <Label check for={section.id} className="font-weight-bold">
                                {section.label}
                            </Label>
                        </FormGroup>
                        <div className="ml-4 mt-2">
                            {section.children.map(child => (
                                <FormGroup check key={child.id} className="mb-2">
                                    <Input
                                        type="checkbox"
                                        id={child.id}
                                        checked={checklistState[child.id]}
                                        onChange={() => handleCheckboxChange(child.id)}
                                    />
                                    <Label check for={child.id}>
                                        {child.label}
                                    </Label>
                                </FormGroup>
                            ))}
                        </div>
                    </div>
                ))}
            </CardBody>
        </Card>
    );
};


const WeeklyHSSEReportDetail = ({ formData, handleInputChange, handleSelectChange, data }) => {
    const [reportDetails, setReportDetails] = useState({
        personCompletingReport: '',
        contractorCompany: null,
        workPeriods: [],
    });
    const [siteDetails, setSiteDetails] = useState(formData.siteDetails || []);
    const [errors, setErrors] = useState([]);
    const [isLastRowValid, setIsLastRowValid] = useState(true);
    const validateLastRow = () => {
        if (siteDetails.length === 0) return true;
        const lastRow = siteDetails[siteDetails.length - 1];
        return lastRow.programType && lastRow.region;
    };

    useEffect(() => {
        console.log("useEffect triggered. formData:", formData, "data:", data);
        if (formData && data) {
            setReportDetails({
                personCompletingReport: formData.personCompletingReport || '',
                contractorCompany: formData.contractorCompany || null,
                workPeriods: formData.workPeriods || [],
            });

            const programOptions = data?.programTypes?.map(program => ({
                value: program.id.toString(),
                label: program.name
            })) || [];

            const regionOptions = data?.regions?.map(region => ({
                value: region.id.toString(),
                label: region.name
            })) || [];

            const updatedSiteDetails = (formData.siteDetails || []).map(site => {
                const selectedProgram = programOptions.find(option => option.value === (site.programType?.value || site.programType?.toString())) || null;
                const selectedRegion = regionOptions.find(option => option.value === (site.region?.value || site.region?.toString())) || null;

                return {
                    ...site,
                    programType: selectedProgram ? { value: selectedProgram.value, label: selectedProgram.label } : null,
                    region: selectedRegion ? { value: selectedRegion.value, label: selectedRegion.label } : null,
                    siteName: site.siteName || '',
                    siteWeeklyProgress: site.siteWeeklyProgress || 0,
                    nmpisReported: site.nmpisReported || 0,
                    nmpisRaisedOnHighRiskActivities: site.nmpisRaisedOnHighRiskActivities || 0,
                    bbsqInterventions: site.bbsqInterventions || 0,
                    contractorHsseAudit: site.contractorHsseAudit || 0,
                    srManagementAudit: site.srManagementAudit || 0,
                    siteExposureHours: site.siteExposureHours || 0,
                    kmsTravelled: site.kmsTravelled || 0,
                    highRiskActivities: site.highRiskActivities || 0,
                };
            });

            console.log("Updated siteDetails:", updatedSiteDetails);
            setSiteDetails(updatedSiteDetails);
        }
    }, [formData, data]);

    const handleReportDetailChange = (field, value) => {
        console.log(`Changing report detail: ${field}`, value);
        const newReportDetails = { ...reportDetails, [field]: value };
        setReportDetails(newReportDetails);
        handleInputChange({ target: { name: field, value } });
    };

    const handleSiteDetailChange = (index, field, value) => {
        console.log(`Changing site detail: index=${index}, field=${field}`, value);
        const newSiteDetails = siteDetails.map((detail, i) => {
            if (i === index) {
                let newValue = value;
                if (field === 'programType' || field === 'region') {
                    newValue = value ? { value: value.value, label: value.label } : null;
                } else if (['siteWeeklyProgress', 'nmpisReported', 'nmpisRaisedOnHighRiskActivities',
                    'bbsqInterventions', 'contractorHsseAudit', 'srManagementAudit',
                    'siteExposureHours', 'kmsTravelled', 'highRiskActivities'].includes(field)) {
                    newValue = Number(value);
                }
                return { ...detail, [field]: newValue };
            }
            return detail;
        });

        console.log("Updated siteDetails:", newSiteDetails);
        setSiteDetails(newSiteDetails);
        handleInputChange({ target: { name: 'siteDetails', value: newSiteDetails } });

        if (errors[index] && errors[index][field]) {
            const newErrors = [...errors];
            newErrors[index] = { ...newErrors[index], [field]: false };
            setErrors(newErrors);
        }

        setIsLastRowValid(validateLastRow());
    };


    const addSiteDetail = () => {
        if (!validateLastRow()) {
            setIsLastRowValid(false);
            console.log("Cannot add new row: Program Type and Region are required for the current row.");
            message.error("Please select Program Type or Region")
            return;
        }
        const newSiteDetails = [...siteDetails, {
            programType: null,
            region: null,
            siteName: '',
            siteWeeklyProgress: 0,
            nmpisReported: 0,
            nmpisRaisedOnHighRiskActivities: 0,
            bbsqInterventions: 0,
            contractorHsseAudit: 0,
            srManagementAudit: 0,
            siteExposureHours: 0,
            kmsTravelled: 0,
            highRiskActivities: 0,
        }];
        setSiteDetails(newSiteDetails);
        handleInputChange({ target: { name: 'siteDetails', value: newSiteDetails } });
        setErrors([...errors, {}]);
        setIsLastRowValid(true);
    };

    const removeSiteDetail = (index) => {
        console.log(`Removing site detail at index ${index}`);
        const newSiteDetails = siteDetails.filter((_, i) => i !== index);
        setSiteDetails(newSiteDetails);
        handleInputChange({ target: { name: 'siteDetails', value: newSiteDetails } });
        const newErrors = errors.filter((_, i) => i !== index);
        setErrors(newErrors);
    };

    const contractorOptions = data?.crContractors?.map(contractor => ({
        value: contractor.id.toString(),
        label: contractor.name
    })) || [];

    console.log("Rendering component. Current state:", { reportDetails, siteDetails, errors });



    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardHeader style={{ backgroundColor: '#e6f2ff', borderBottom: '1px solid #b8daff' }}>
                <h2 className="card-title mb-0">Weekly Project HSSE Report details</h2>
            </CardHeader>
            <CardBody>
                <Row style={{ marginBottom: '1rem' }}>
                    <Col md="6" lg="6">
                        <FormGroup row>
                            <Col sm="4">
                                <Label for="personCompletingReport" className="mb-0">Name of person completing report</Label>
                            </Col>
                            <Col sm="8">
                                <Input
                                    type="text"
                                    id="personCompletingReport"
                                    name="personCompletingReport"
                                    value={reportDetails.personCompletingReport}
                                    onChange={(e) => handleReportDetailChange('personCompletingReport', e.target.value)}
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col md="6" lg="6">
                        <FormGroup row>
                            <Col sm="4">
                                <Label for="contractorCompany" className="mb-0">Contractor Company name completing report</Label>
                            </Col>
                            <Col sm="8">
                                <Select
                                    id="contractorCompany"
                                    value={reportDetails.contractorCompany}
                                    onChange={(option) => handleSelectChange('contractorCompany', option)}
                                    options={contractorOptions}
                                    placeholder="--Select Company--"
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col md="6" lg="6">
                        <FormGroup row>
                            <Col sm="4">
                                <Label for="workPeriods" className="mb-0">Work Done in Periods</Label>
                            </Col>
                            <Col sm="8">
                                <Select
                                    id="workPeriods"
                                    isMulti
                                    value={reportDetails.workPeriods}
                                    onChange={(options) => handleSelectChange('workPeriods', options)}
                                    options={Object.entries(data?.workDonePeriod || {}).map(([key, value]) => ({
                                        value: key,
                                        label: value
                                    }))}
                                    placeholder="Select work periods"
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>


                <h2 className="card-title mb-0">Site Details</h2>
                <Button color="primary" size="sm" onClick={addSiteDetail}>
                    <i className="fas fa-plus"></i>
                </Button>
                {!isLastRowValid && (
                    <FormFeedback style={{ display: 'block' }}>
                        Please select Program Type and Region for the current row before adding a new one.
                    </FormFeedback>
                )}
                <div className="mt-3">
                    <Table bordered>
                        <tbody>
                            {siteDetails.map((detail, index) => (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td>
                                            <Label for={`programType-${index}`}>Program Type *</Label>
                                            <Select
                                                id={`programType-${index}`}
                                                value={detail.programType ?
                                                    {
                                                        value: detail.programType.value || detail.programType,
                                                        label: detail.programType.label || data?.programTypes?.find(pt => pt.id.toString() === detail.programType.toString())?.name
                                                    } : null
                                                }
                                                onChange={(selectedOption) => handleSiteDetailChange(index, 'programType', selectedOption)}
                                                options={data?.programTypes?.map(program => ({
                                                    value: program.id.toString(),
                                                    label: program.name
                                                })) || []}
                                                placeholder="Select Program Type"
                                                isInvalid={errors[index]?.programType}
                                            />
                                            {errors[index]?.programType &&
                                                <FormFeedback>Program Type is required</FormFeedback>}
                                        </td>
                                        <td>
                                            <Label for={`siteName-${index}`}>Site Name</Label>
                                            <Input
                                                id={`siteName-${index}`}
                                                type="text"
                                                value={detail.siteName}
                                                onChange={(e) => handleSiteDetailChange(index, 'siteName', e.target.value)}
                                                placeholder="Enter Site Name"
                                            />
                                        </td>
                                        <td>
                                            <Label for={`siteWeeklyProgress-${index}`}>Site Weekly Progress %</Label>
                                            <Input
                                                id={`siteWeeklyProgress-${index}`}
                                                type="number"
                                                value={detail.siteWeeklyProgress}
                                                onChange={(e) => handleSiteDetailChange(index, 'siteWeeklyProgress', e.target.value)}
                                                placeholder="Enter Progress %"
                                            />
                                        </td>
                                        <td>
                                            <Label for={`nmpisReported-${index}`}>NMPI's Reported</Label>
                                            <Input
                                                id={`nmpisReported-${index}`}
                                                type="number"
                                                value={detail.nmpisReported}
                                                onChange={(e) => handleSiteDetailChange(index, 'nmpisReported', e.target.value)}
                                                placeholder="Enter NMPI's Reported"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Label for={`nmpisHighRisk-${index}`}>NMPI's on High Risk</Label>
                                            <Input
                                                id={`nmpisHighRisk-${index}`}
                                                type="number"
                                                value={detail.nmpisRaisedOnHighRiskActivities}
                                                onChange={(e) => handleSiteDetailChange(index, 'nmpisRaisedOnHighRiskActivities', e.target.value)}
                                                placeholder="NMPI's on High Risk"
                                            />
                                        </td>
                                        <td>
                                            <Label for={`bbsqInterventions-${index}`}>BBS(Q) Interventions</Label>
                                            <Input
                                                id={`bbsqInterventions-${index}`}
                                                type="number"
                                                value={detail.bbsqInterventions}
                                                onChange={(e) => handleSiteDetailChange(index, 'bbsqInterventions', e.target.value)}
                                                placeholder="BBS(Q) Interventions"
                                            />
                                        </td>
                                        <td>
                                            <Label for={`contractorHsseAudit-${index}`}>Contractor HSSE Audit</Label>
                                            <Input
                                                id={`contractorHsseAudit-${index}`}
                                                type="number"
                                                value={detail.contractorHsseAudit}
                                                onChange={(e) => handleSiteDetailChange(index, 'contractorHsseAudit', e.target.value)}
                                                placeholder="Contractor HSSE Audit"
                                            />
                                        </td>
                                        <td>
                                            <Label for={`srManagementAudit-${index}`}>Sr. Management Audit</Label>
                                            <Input
                                                id={`srManagementAudit-${index}`}
                                                type="number"
                                                value={detail.srManagementAudit}
                                                onChange={(e) => handleSiteDetailChange(index, 'srManagementAudit', e.target.value)}
                                                placeholder="Sr. Management Audit"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Label for={`siteExposureHours-${index}`}>Site Exposure Hours</Label>
                                            <Input
                                                id={`siteExposureHours-${index}`}
                                                type="number"
                                                value={detail.siteExposureHours}
                                                onChange={(e) => handleSiteDetailChange(index, 'siteExposureHours', e.target.value)}
                                                placeholder="Site Exposure Hours"
                                            />
                                        </td>
                                        <td>
                                            <Label for={`kmsTravelled-${index}`}>KMs travelled</Label>
                                            <Input
                                                id={`kmsTravelled-${index}`}
                                                type="number"
                                                value={detail.kmsTravelled}
                                                onChange={(e) => handleSiteDetailChange(index, 'kmsTravelled', e.target.value)}
                                                placeholder="KMs travelled"
                                            />
                                        </td>
                                        <td>
                                            <Label for={`highRiskActivities-${index}`}>High Risk Activities</Label>
                                            <Input
                                                id={`highRiskActivities-${index}`}
                                                type="number"
                                                value={detail.highRiskActivities}
                                                onChange={(e) => handleSiteDetailChange(index, 'highRiskActivities', e.target.value)}
                                                placeholder="High Risk Activities"
                                            />
                                        </td>
                                        <td>
                                            <Label for={`region-${index}`}>Region *</Label>
                                            <Select
                                                id={`region-${index}`}
                                                value={detail.region ?
                                                    {
                                                        value: detail.region.value || detail.region,
                                                        label: detail.region.label || data?.regions?.find(r => r.id.toString() === detail.region.toString())?.name
                                                    } : null
                                                }
                                                onChange={(selectedOption) => handleSiteDetailChange(index, 'region', selectedOption)}
                                                options={data?.regions?.map(region => ({
                                                    value: region.id.toString(),
                                                    label: region.name
                                                })) || []}
                                                placeholder="Select Region"
                                                isInvalid={errors[index]?.region}
                                            />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="4">
                                            <Button color="danger" size="sm" onClick={() => removeSiteDetail(index)}>
                                                <i className="fas fa-minus"></i> Remove
                                            </Button>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </Table>
                </div>

            </CardBody>
        </Card>
    );
};

const L2IndiaPMWeeklyReport = ({ formData, handleInputChange }) => {
    const [reportDetails, setReportDetails] = useState({
        R1_1A: 0,
        R1_1B: 0,
        R1_2: 0,
        R1_3A: 0,
        R1_4A: 0,
        R1_4B: 0,
        R1_4C: '',
        R1_5: 0,
        R1_6: 0,
        R2_A: 0,
        R2_B: 0,
        R2_C: 0,
        R2_D: 0,
        R2_E: 0,
        R2_F: 0,
        R2_G: 0,
        R2_H: 0
    });

    useEffect(() => {
        setReportDetails(prevState => ({
            ...prevState,
            ...formData
        }));
    }, [formData]);

    const handleReportDetailChange = (field, value) => {
        let newValue;
        if (formFields.find(f => f.field === field)?.type === 'text') {
            newValue = value;
        } else {
            newValue = value === '' ? null : Number(value);
        }

        setReportDetails(prevState => ({
            ...prevState,
            [field]: newValue
        }));

        handleInputChange({ target: { name: field, value: newValue } });
    };

    const formFields = [
        { label: '1A. Target Hours', field: 'R1_1A' },
        { label: '1B. Actual Hours', field: 'R1_1B' },
        { label: '2. KMS driven', field: 'R1_2' },
        { label: '3A. NMPI\'s Identified', field: 'R1_3A' },
        { label: '4A. LOD1 Audits Conducted Separately', field: 'R1_4A' },
        { label: '4B. LOD1 Audits Conducted Combined With Contractor', field: 'R1_4B' },
        { label: '4C. Who was Co-Auditor ?', field: 'R1_4C', type: 'text' },
        { label: '5.TBT Attended', field: 'R1_5' },
        { label: '6. Number Of BLUE ZONE GO SEE CARD Issued', field: 'R1_6' },
    ];

    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardHeader style={{ backgroundColor: '#e6f2ff', borderBottom: '1px solid #b8daff' }}>
                <h2 className="card-title mb-0">L2  Weekly Report details</h2>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col xs={12}>
                        <h3>1. Exposure Hours (EH)</h3>
                    </Col>
                    {formFields.map(({ label, field, type }) => (
                        <Col key={field} xl={4} md={6}>
                            <FormGroup row>
                                <Label className="col-sm-10 form-label">{label}</Label>
                                <Col sm={6}>
                                    {type === 'text' ? (
                                        <Input
                                            type="text"
                                            value={reportDetails[field] || ''}
                                            onChange={(e) => handleReportDetailChange(field, e.target.value)}
                                            className="form-control"
                                        />
                                    ) : (
                                        <Input
                                            type="number"
                                            value={reportDetails[field]}
                                            onChange={(e) => handleReportDetailChange(field, e.target.value)}
                                            className="form-control"
                                            step="any"
                                        />
                                    )}
                                </Col>
                            </FormGroup>
                        </Col>
                    ))}
                </Row>

                <hr className="my-4" />

                <Row>
                    <Col xs={12}>
                        <h3>2. Permits issued</h3>
                    </Col>
                    {[
                        { label: 'A. Excavation', field: 'R2_A' },
                        { label: 'B. Confined Space Entry', field: 'R2_B' },
                        { label: 'C. Working At Height', field: 'R2_C' },
                        { label: 'D. Hot Work', field: 'R2_D' },
                        { label: 'E. Excavation And Confined Space', field: 'R2_E' },
                        { label: 'F. Confined Space And Working At Height', field: 'R2_F' },
                        { label: 'G. Excavation Confined Space And Working At Height', field: 'R2_G' },
                        { label: 'H. Others', field: 'R2_H' },
                    ].map(({ label, field }) => (
                        <Col key={field} xl={4} md={6}>
                            <FormGroup row>
                                <Label className="col-sm-10 form-label">{label}</Label>
                                <Col sm={6}>
                                    <Input
                                        type="number"
                                        value={reportDetails[field]}
                                        onChange={(e) => handleReportDetailChange(field, e.target.value)}
                                        className="form-control"
                                        step="any"
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                    ))}
                </Row>
            </CardBody>
        </Card>
    );
};

export {
    AdminSection,
    GoSeeProgramSection,
    AttachmentsSection,
    ActionSection,
    HSSESection,
    AuditFindingSection,
    ToolBoxTalkSection,
    TalkNotTickSection,
    ActionModal,
    WeeklyHSSEReportDetail,
    L2IndiaPMWeeklyReport,
    FAuditESection,
    LOD1Section,
    LOD1Findings
}