import axios from "../../../../axios";
import {setAlert} from "../../alert";
import {message} from "antd";
import {ADD_RHPW, GENERATE_RHPW, GET_SINGLE_RHPW, GET_USER_RHPW, RHPW_VALUES, UPDATE_RHPW,DELETE_RHPW} from "../../../types";

export const getRHPWValues = () => async (dispatch) => {
    try {
        const res = await axios.get("/inform/rhpw/values");
        if (res.data.code === 1) {
            dispatch({type: RHPW_VALUES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};


export const addRHPWForm = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/inform/rhpw/add", formData, config);
        
        if (res.data.code === 1) {
            dispatch({type: ADD_RHPW, payload: res.data.data});
            return {success: true, message: res.data.message};
        } else {

            return {success: false, message: res.data.message};
        }
    } catch (err) {
        console.error("Error in addHazardForm:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
};
export const updateRHPWForm = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/inform/rhpw/update", formData, config);
        
        if (res.data.code === 1) {
            dispatch({type: UPDATE_RHPW, payload: res.data.data});
            return {success: true, message: res.data.message};
        } else {
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        console.error("Error in addHazardForm:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
};

export const deleteRHPW = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/inform/rhpw/delete/${id}`);
        if (res.data.code === 1) {
            dispatch({type: DELETE_RHPW, payload: id});
            return {success: true, message: res.data.message};
        }
        return {success: false, message: res.data.message};
    } catch (err) {
        return {success: false, message: err.message};
    }
};

export const getUserRHPW = (accessModuleId) => async (dispatch) => {
    try {
        const object={
            moduleId:accessModuleId
        }
        const res = await axios.post("/inform/rhpw/user",object);
        if (res.data.code === 1) {
            dispatch({type: GET_USER_RHPW, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const getSingleRHPW=(id)=> async (dispatch) => {
    try {
        const res = await axios.get(`/inform/rhpw/${id}`);
        if (res.data.code === 1) {
            dispatch({type: GET_SINGLE_RHPW, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const submitRHPWToGenerate = (object) => async (dispatch) => {
    try {
        const res = await axios.post(`/inform/rhpw/submit/`, object);
        if (res.data.code === 1) {
            dispatch({type: GENERATE_RHPW, payload: res.data.data});
        }
        return res
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const submitActionForRHPW = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/inform/rhpw/action/update", formData);
        if (res.data.code === 1) {
            message.success(res.data.message);
            //dispatch({type: GET_USER_HAZARD, payload: res.data.data});
            return {success: true, message: res.data.message};
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        console.error("Error in addHazardForm:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
}


