import {
    ADD_QUESTION,
    DELETE_QUESTION,
    GET_QUESTIONS,
    GET_QUESTION,
    UPDATE_QUESTION,
    GET_COURSE_QUESTIONS
} from "../../../types";
import {message} from "antd";
import axios from "../../../../axios";
import {setAlert} from "../../alert";

export const getQuestions = () => async (dispatch) => {
    try {
        const res = await axios.get("/question/all");
        if (res.data.code === 1) {
            dispatch({type: GET_QUESTIONS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const addQuestion = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/question/add", formData);
        if (res.data.code === 1) {
            dispatch({type: ADD_QUESTION, payload: res.data.data});
            message.success(res.data.message);
            return {success: true, message: res.data.message};
        } else {
            message.warning(res.data.message);
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const getQuestionsByCountry = (country) => async (dispatch) => {
    try {
        const res = await axios.get(`question/country/${country}`);
        if (res.data.code === 1) {
            let payload = Array.isArray(res?.data?.data) ?  res?.data?.data : [];
            dispatch({ type: GET_QUESTIONS, payload: payload });
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const getQuestion = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/question/${id}`);
        if (res.data.code === 1) {

            dispatch({type: GET_QUESTION, payload: res.data.data});
        }
    } catch (err) {
        // dispatch(setAlert(err.message, "danger"));
    }
};

export const updateQuestion = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(`/question/update`, formData);
        if (res.data.code === 1) {
            dispatch({type: UPDATE_QUESTION, payload: res.data.data});
            message.success(res.data.message);
            return {success: true, message: res.data.message};
        } else {
            message.warning(res.data.message);
            dispatch(setAlert(res.data.message, "warning"));
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        return {success: false, message: 'Contact to Admin'};
        // dispatch(setAlert(err.message, "danger"));
    }
};
export const deleteQuestion = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/question/delete/${id}`);
        if (res.data.code === 1) {
            message.success(res.data.message);
            dispatch({type: DELETE_QUESTION, payload: res.data.data});
        } else {
            message.warning(res.data.message);
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const getQuestionQuiz=(payload)=>async (dispatch) => {
    try {
        const res = await axios.post("/question/quiz", payload);
        if (res.data.code === 1) {
            dispatch({type: GET_COURSE_QUESTIONS, payload: res.data.data});
            return {success: true, message: res.data.message};
        } else {
            message.warning(res.data.message);
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}
export const submitQuiz=(payload)=>async (dispatch) => {
    try {
        const res = await axios.post("/quiz/submit", payload);
        ;
        if (res.data.code === 1) {
            return {success: true, message: res.data.message,data: res.data.data};
        } else {
            message.warning(res.data.message);
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const getGivenTests=(payload)=>async (dispatch) => {
    try {
        const res = await axios.post("/quiz/course/user", payload);
        if (res.data.code === 1) {
            return {success: true, message: res.data.message,data: res.data.data};
        } else {
            message.warning(res.data.message);
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

