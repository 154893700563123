import axios from "../../../../axios";
import { setAlert } from "../../alert";
import { ADD_NCR,GET_NCRS,GET_SINGLE_NCR,UPDATE_NCR,NCR_VALUES,DELETE_NCR,GET_USER_NCR, GENERATE_NCR} from "../../../types";

// Get all NCRValues
export const getNCRValues = () => async (dispatch) => {
    try {
        const res = await axios.get("/ncr/values");
        if (res.data.code === 1) {
            dispatch({ type: NCR_VALUES, payload: res.data.data });
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};


export const submitNCR = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(`/ncr/submit`,formData);
        if (res.data.code === 1) {
            dispatch({type: GENERATE_NCR, payload: res.data.data});
            return {message: "NCR submitted successfully", success: true};
        }
        return {message: res.data.message, success: false};
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return {message: err.message, success: false};
    }
};

export const submitActionForNCR = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/ncr/action/update", formData);
        if (res.data.code === 1) {
            return {message: res.data.message, success: true};
        } else {
            return {message: res.data.message, success: false};
        }
    } catch (err) {
        return {message: err.response?.data?.message || "Contact Admin", success: false};
    }
}



// Get all NCRs
export const getNCRs = (accessModuleId) => async (dispatch) => {
    try {
        const object={
            moduleId:accessModuleId
        }
        const res = await axios.post("/ncr/user",object);
        if (res.data.code === 1) {
            dispatch({ type: GET_USER_NCR, payload: res.data.data });
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

// Get a single NCR
export const getSingleNCR = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/ncr/${id}`);
        if (res.data.code === 1) {
            dispatch({ type: GET_SINGLE_NCR, payload: res.data.data });
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

// Add a new NCR
export const addNCR = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/ncr/add", formData, config);
        if (res.data.code === 1) {
            dispatch({ type: ADD_NCR, payload: res.data.data });
            return { success: true, message: res.data.message };
        } else {
            return { success: false, message: res.data.message };
        }
    } catch (err) {
        console.error("Error in addNCR:", err);
        return { success: false, message: err.response?.data?.message || "Contact Admin" };
    }
};

// Update an NCR
export const updateNCR = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/ncr/update", formData, config);
        if (res.data.code === 1) {
            dispatch({ type: UPDATE_NCR, payload: res.data.data });
            return { success: true, message: res.data.message };
        } else {
            return { success: false, message: res.data.message };
        }
    } catch (err) {
        console.error("Error in updateNCR:", err);
        return { success: false, message: err.response?.data?.message || "Contact Admin" };
    }
};

// Delete an NCR
export const deleteNCR = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/ncr/delete/${id}`);
        if (res.data.code === 1) {
            dispatch({ type: DELETE_NCR, payload: id });
            return { success: true, message: res.data.message };
        }
    } catch (err) {
        return { success: false, message: 'Contact Admin' };
    }
};