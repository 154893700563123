import axios from "../../../axios";
import {ADD_STATE, DELETE_STATE, GET_STATES, GET_STATE, UPDATE_STATE} from "../../types";
import {setAlert} from "../alert";
import {message} from "antd";

export const getStates = () => async (dispatch) => {
    try {
        const res = await axios.get("/state/all");
        if (res.data.code === 1) {
            dispatch({type: GET_STATES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const addState = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/state/add", formData);
        ;
        if (res.data.code === 1) {
            dispatch({type: ADD_STATE, payload: res.data.data});
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const getStatesByCountry = (country) => async (dispatch) => {
    try {
        const res = await axios.get(`state/country/${country}`);
        if (res.data.code === 1) {
            let payload = Array.isArray(res?.data?.data) ?  res?.data?.data : [];
            dispatch({ type: GET_STATES, payload: payload });
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const getState = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/state/${id}`);
        if (res.data.code === 1) {

            dispatch({type: GET_STATE, payload: res.data.data});
        }
    } catch (err) {
        // dispatch(setAlert(err.message, "danger"));
    }
};

export const updateState = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(`/state/update`, formData);
        if (res.data.code === 1) {
            dispatch({type: UPDATE_STATE, payload: res.data.data});
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        // dispatch(setAlert(err.message, "danger"));
    }
};
export const deleteState = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/state/delete/${id}`);
        if (res.data.code === 1) {
            message.success(res.data.message);
            dispatch({type: DELETE_STATE, payload: res.data.data});
        } else {
            message.warning(res.data.message);
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
