import React, {useState, useEffect, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    getParentCabinet,
    addCabinetFolder,
    uploadFileInCabinet,
    addFileInCabinet,
    getShareableLink,
    updateFileName,
    deleteFile,
} from "../../../store/actions/filecabinet/cabinet";
import {
    Folder,
    ChevronRight,
    ChevronDown,
    ArrowUp,
    ArrowDown,
} from "lucide-react";
import {
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Row,
    Col,
    Spinner,
    Table,
    Progress,
} from "reactstrap";
import {
    FolderOutlined,
    FileOutlined,
    DownloadOutlined,
    ShareAltOutlined,
    EditOutlined,
    DeleteOutlined,
    FileTextOutlined,
    FilePdfOutlined,
    FileExcelOutlined,
    FilePptOutlined,
    FileWordOutlined,
    FileImageOutlined,
    FileUnknownOutlined, SearchOutlined,
} from "@ant-design/icons";
import {message, Tooltip} from "antd";
import {closeProgressDialog, showInfoDialog, showProgressDialog, showWarningDialog} from "../../../utils/dialogs";
import Swal from "sweetalert2";
import {AutoSizer} from 'react-virtualized';

const CHUNK_SIZE = 1024 * 1024; // 1MB chunks


const TreeNode = React.memo(
    ({item, depth, onSelect, selectedItem, path = [], isExpanded, onToggleExpand}) => {
        const handleSelect = () => {
            onSelect(item, [...path, item]);
        };

        if (item.type !== "folder") {
            return null;
        }

        const Icon = isExpanded(item.id) ? ChevronDown : ChevronRight;
        const hasFolders = item.children && item.children.some(child => child.type === "folder");

        return (
            <div>
                <div
                    onClick={handleSelect}
                    className={`
            flex items-center py-1 cursor-pointer
            ${selectedItem && selectedItem.id === item.id ? "text-blue-500" : ""}
            ${hasFolders ? "mr-4" : ""}
          `}
                    style={{
                        paddingLeft: `${depth * 20}px`,
                    }}
                >
          <span
              onClick={(e) => {
                  e.stopPropagation();
                  onToggleExpand(item.id);
              }}
              className="mr-1"
          >
            <Icon size={16}/>
          </span>
                    <Folder size={16} className="text-warning mr-2"/>
                    <span>{item.name}</span>
                </div>
                {isExpanded(item.id) && item.children && (
                    <div>
                        {item.children.map((child) => (
                            <TreeNode
                                key={child.id}
                                item={child}
                                depth={depth + 1}
                                onSelect={onSelect}
                                selectedItem={selectedItem}
                                path={[...path, item]}
                                isExpanded={isExpanded}
                                onToggleExpand={onToggleExpand}
                            />
                        ))}
                    </div>
                )}
            </div>
        );
    }
);


const FileCabinet = ({access}) => {
    const dispatch = useDispatch();
    const parentFolders = useSelector((state) => state.filecabinet.parent);
    const [selectedItem, setSelectedItem] = useState(null);
    const [currentFolder, setCurrentFolder] = useState(null);
    const [folderPath, setFolderPath] = useState([]);
    const [currentFolderId, setCurrentFolderId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isCreateFolderOpen, setIsCreateFolderOpen] = useState(false);
    const [newFolderName, setNewFolderName] = useState("");
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [currentFolderContents, setCurrentFolderContents] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [globalSearchTerm, setGlobalSearchTerm] = useState("");
    const [sortColumn, setSortColumn] = useState("name");
    const [sortDirection, setSortDirection] = useState("asc");
    const [expandedFolders, setExpandedFolders] = useState({});
    const [globalSearchResults, setGlobalSearchResults] = useState([]);



    const searchFileSystem = useCallback((folder, term, currentPath = []) => {
        let results = [];

        folder.forEach(item => {
            const itemPath = [...currentPath, item.name];

            if (item.name.toLowerCase().includes(term.toLowerCase())) {
                results.push({
                    ...item,
                    fullPath: itemPath.join(' / ')
                });
            }

            if (item.children && item.children.length > 0) {
                results = [...results, ...searchFileSystem(item.children, term, itemPath)];
            }
        });

        return results;
    }, []);

    const handleGlobalSearch = useCallback(() => {
        if (globalSearchTerm) {
            const results = searchFileSystem(parentFolders, globalSearchTerm);
            setGlobalSearchResults(results);
        } else {
            setGlobalSearchResults([]);
        }
    }, [globalSearchTerm, parentFolders, searchFileSystem]);

    useEffect(() => {
        handleGlobalSearch();
    }, [handleGlobalSearch]);


    const toggleExpand = useCallback((folderId) => {
        setExpandedFolders(prev => ({
            ...prev,
            [folderId]: !prev[folderId]
        }));
    }, []);


    const isExpanded = useCallback((folderId) => {
        return expandedFolders[folderId] || false;
    }, [expandedFolders]);

    const expandPath = useCallback((path) => {
        const newExpanded = {...expandedFolders};
        path.forEach(folder => {
            newExpanded[folder.id] = true;
        });
        setExpandedFolders(newExpanded);
    }, [expandedFolders]);

    useEffect(() => {
        const fetchParentCabinet = async () => {
            try {
                setLoading(true);
                const object = {
                    moduleId: access.moduleId
                };
                console.log('Fetching parent cabinet with:', object);
                const result = await dispatch(getParentCabinet(object));
                console.log('Parent cabinet fetch result:', result);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching parent cabinet:', err);
                setError("Failed to load folders");
                setLoading(false);
            }
        };
        fetchParentCabinet();
    }, [dispatch, access.moduleId]);

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(column);
            setSortDirection("asc");
        }
    };

    const filteredAndSortedContents = useCallback(() => {
        let filtered = (currentFolderContents || []).filter((item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase())
        );

        return filtered.sort((a, b) => {
            if (a[sortColumn] < b[sortColumn])
                return sortDirection === "asc" ? -1 : 1;
            if (a[sortColumn] > b[sortColumn])
                return sortDirection === "asc" ? 1 : -1;
            return 0;
        });
    }, [currentFolderContents, searchTerm, sortColumn, sortDirection]);

    const renderSortIcon = (column) => {
        if (sortColumn === column) {
            return sortDirection === "asc" ? (
                <ArrowUp size={14}/>
            ) : (
                <ArrowDown size={14}/>
            );
        }
        return null;
    };

    const findFolderById = useCallback((folders, id) => {
        for (let folder of folders) {
            if (folder.id === id) return folder;
            if (folder.children) {
                const found = findFolderById(folder.children, id);
                if (found) return found;
            }
        }
        return null;
    }, []);

    useEffect(() => {
        console.log('Parent folders updated:', parentFolders);
        if (currentFolder) {
            const updatedFolder = findFolderById(parentFolders, currentFolder.id);
            console.log('Updated current folder:', updatedFolder);
            if (updatedFolder) {
                setCurrentFolder(updatedFolder);
                setCurrentFolderContents(updatedFolder.children || []);
            } else {
                setCurrentFolder(null);
                setCurrentFolderContents(parentFolders);
            }
        } else {
            setCurrentFolderContents(parentFolders);
        }
    }, [parentFolders, currentFolder, findFolderById]);

    const updateFolderPath = useCallback((folder) => {
        if (!folder) {
            setFolderPath([]);
            return;
        }
        const newPath = [];
        let currentNode = folder;
        while (currentNode) {
            newPath.unshift(currentNode);
            currentNode = findFolderById(parentFolders, currentNode.parentId);
        }
        setFolderPath(newPath);
    }, [parentFolders, findFolderById]);

    useEffect(() => {
        if (currentFolderId) {
            const folder = findFolderById(parentFolders, currentFolderId);
            if (folder) {
                setCurrentFolder(folder);
                setCurrentFolderContents(folder.children || []);
                updateFolderPath(folder);
            } else {
                setCurrentFolder(null);
                setCurrentFolderId(null);
                setCurrentFolderContents(parentFolders);
                setFolderPath([]);
            }
        } else {
            setCurrentFolderContents(parentFolders);
            setFolderPath([]);
        }
    }, [parentFolders, currentFolderId, findFolderById, updateFolderPath]);

    const findRootFolder = useCallback((folders) => {
        return folders.find(folder => folder.level === 0);
    }, []);


    const handleSelect = useCallback((item, path = null) => {
        setSelectedItem(item);
        if (item.type === "folder") {
            setCurrentFolder(item);
            if (path) {
                // If path is provided (from sidebar), use it directly
                setFolderPath(path);
            } else {
                // If path is not provided (from table), construct it
                setFolderPath((prevPath) => {
                    const itemIndex = prevPath.findIndex(
                        (folder) => folder.id === item.id,
                    );
                    if (itemIndex !== -1) {
                        // If item is already in the path, truncate the path to this item
                        return prevPath.slice(0, itemIndex + 1);
                    } else {
                        // If item is not in the path, add it to the end
                        return [...prevPath, item];
                    }
                });
            }
        }
    }, []);
    const handleBackNavigation = useCallback(() => {
        if (folderPath.length > 1) {
            const newPath = folderPath.slice(0, -1);
            setFolderPath(newPath);
            setCurrentFolder(newPath[newPath.length - 1]);
        } else {
            setFolderPath([]);
            setCurrentFolder(null);
        }
    }, [folderPath]);

    const renderBreadcrumb = useCallback(() => (
        <div className="d-flex align-items-center text-muted">
    <span
        className="cursor-pointer hover:text-primary"
        onClick={() => {
            setCurrentFolder(null);
            setFolderPath([]);
        }}
    >
      Root
    </span>
            {folderPath.map((folder, index) => (
                <React.Fragment key={folder.id}>
                    <span className="mx-2">/</span>
                    <span
                        className="cursor-pointer hover:text-primary"
                        onClick={() => {
                            const newPath = folderPath.slice(0, index + 1);
                            setFolderPath(newPath);
                            setCurrentFolder(newPath[newPath.length - 1]);
                        }}
                    >
          {folder.name}
        </span>
                </React.Fragment>
            ))}
        </div>
    ), [folderPath]);

    console.log('Folder path:', folderPath.map(folder => folder.name).join('/'));


    const showRenameFileDialog = (item) => {
        const lastDotIndex = item.name.lastIndexOf('.');
        const fileName = lastDotIndex > -1 ? item.name.slice(0, lastDotIndex) : item.name;
        const fileExtension = lastDotIndex > -1 ? item.name.slice(lastDotIndex) : '';

        Swal.fire({
            title: 'Rename File',
            text: `Current file name: ${fileName}`,
            icon: 'info',
            input: 'text',
            inputLabel: 'New file name',
            inputValue: fileName,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Rename',
            cancelButtonText: 'Cancel',
            inputValidator: (value) => {
                if (!value) {
                    return 'You need to enter a new file name';
                }
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                const newFileName = result.value + fileExtension;
                const object = {
                    folderId: parseInt(currentFolder.id),
                    id: parseInt(item.id),
                    name: newFileName,
                    moduleId: parseInt(access.moduleId),
                }
                showProgressDialog("Renaming File....", "Please wait")
                const fileResult = await dispatch(updateFileName(object))
                if (fileResult.success) {
                    await refreshCurrentFolder();
                    closeProgressDialog()
                }
            }
        });
    };

    const showDeleteFileDialog = (item) => {
        Swal.fire({
            title: 'Delete File',
            text: `Are you sure you want to delete "${item.name}"?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const object = {
                    id: parseInt(item.id),
                    moduleId: parseInt(access.moduleId),
                }
                showProgressDialog("Deleting File....", "Please wait")
                const deleteResult = await dispatch(deleteFile(object))

                if (deleteResult.success) {
                    await refreshCurrentFolder();
                    closeProgressDialog()
                }
            }
        });
    }

    const refreshCurrentFolder = useCallback(async () => {
        try {
            setLoading(true);
            const object = {
                moduleId: access.moduleId
            };

            console.log('Refreshing folder with:', object);
            const result = await dispatch(getParentCabinet(object));
            console.log('getParentCabinet result:', result);

            if (Array.isArray(result.data)) {
                const newFolderStructure = result.data;
                console.log('New folder structure:', newFolderStructure);

                if (folderPath.length > 0) {
                    let currentNode = newFolderStructure;
                    let updatedPath = [];

                    for (let folder of folderPath) {
                        const foundFolder = findFolderById(currentNode, folder.id);
                        if (foundFolder) {
                            updatedPath.push(foundFolder);
                            currentNode = foundFolder.children || [];
                        } else {
                            break;
                        }
                    }

                    if (updatedPath.length === folderPath.length) {
                        const lastFolder = updatedPath[updatedPath.length - 1];
                        setCurrentFolder(lastFolder);
                        setCurrentFolderId(lastFolder.id);
                        setCurrentFolderContents(lastFolder.children || []);
                        setFolderPath(updatedPath);

                        expandPath(updatedPath);

                        setTimeout(() => {
                            handleSelect(lastFolder, updatedPath);
                        }, 0);
                    } else {
                        setCurrentFolder(null);
                        setCurrentFolderId(null);
                        setCurrentFolderContents(newFolderStructure);
                        setFolderPath([]);

                    }
                } else {
                    // At root level
                    setCurrentFolderContents(newFolderStructure);
                }
            } else {
                console.error('Unexpected result from getParentCabinet:', result);
                throw new Error("Failed to fetch folder structure");
            }

            setLoading(false);
        } catch (err) {
            console.error('Error refreshing current folder:', err);
            setError("Failed to refresh folder contents: " + err.message);
            setLoading(false);
        }
    }, [dispatch, access.moduleId, folderPath, findFolderById, handleSelect, expandPath]);

    const toggleCreateFolderModal = useCallback(() => {
        setIsCreateFolderOpen(!isCreateFolderOpen);
        if (!isCreateFolderOpen) {
            setNewFolderName("");
        }
    }, [isCreateFolderOpen]);

    const handleCreateFolder = useCallback(
        async (e) => {
            e.preventDefault();
            if (newFolderName.trim() === "") {
                message.error("Please enter a folder name");
                return;
            }

            const formData = {
                parentId: currentFolder ? parseInt(currentFolder.id) : null,
                name: newFolderName.trim(),
            };

            try {
                showProgressDialog("Creating folder", "Please wait");
                await dispatch(addCabinetFolder(formData));
                toggleCreateFolderModal();
                message.success("Folder created successfully");
                closeProgressDialog();
                await refreshCurrentFolder();
            } catch (err) {
                message.error("Failed to create folder");
            }
        },
        [
            dispatch,
            currentFolder,
            newFolderName,
            toggleCreateFolderModal,
            refreshCurrentFolder,
        ]
    );

    const toggleUploadModal = useCallback(() => {
        setIsUploadModalOpen(!isUploadModalOpen);
        if (!isUploadModalOpen) {
            setSelectedFile(null);
            setUploadProgress(0);
        }
    }, [isUploadModalOpen]);

    const handleFileChange = useCallback((event) => {
        const file = event.target.files[0];
        const allowedTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'video/mp4',
        ];
        const maxSize = 100 * 1024 * 1024; // 100MB in bytes

        if (!allowedTypes.includes(file.type)) {
            showWarningDialog("Invalid file type. Please upload a PDF, MS Office document, or video file.", 'Warning');
            event.target.value = null; // Reset the input
            return;
        }

        if (file.size > maxSize) {
            showWarningDialog("File is too large. Maximum size is 100MB.", 'Warning');
            event.target.value = null; // Reset the input
            return;
        }

        setSelectedFile(file);
    }, []);

    const uploadCabinetFile = useCallback(
        async (file, start, folderId, uniqueFilename, chunkIndex = 0) => {
            const end = Math.min(start + CHUNK_SIZE, file.size);
            const chunk = file.slice(start, end);
            const formData = new FormData();
            formData.append("file", chunk, uniqueFilename);
            formData.append("fileName", uniqueFilename);
            formData.append("name", file.name);
            const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
            formData.append("chunkIndex", chunkIndex.toString());
            formData.append("totalChunks", totalChunks.toString());

            if (end < file.size) {
                await dispatch(uploadFileInCabinet(formData));
                setUploadProgress(Math.round((end / file.size) * 100));
                await uploadCabinetFile(
                    file,
                    end,
                    folderId,
                    uniqueFilename,
                    chunkIndex + 1
                );
            } else {
                setUploadProgress(100);
                const lastChunkFormData = new FormData();
                lastChunkFormData.append("file", chunk, uniqueFilename);
                lastChunkFormData.append("fileName", uniqueFilename);
                lastChunkFormData.append("chunkIndex", chunkIndex.toString());
                lastChunkFormData.append("totalChunks", totalChunks.toString());
                lastChunkFormData.append("folderId", folderId);
                lastChunkFormData.append("name", file.name);
                lastChunkFormData.append("moduleId", access.moduleId);
                await dispatch(addFileInCabinet(lastChunkFormData));
            }
        },
        [dispatch, access.moduleId]
    );

    const handleUpload = useCallback(
        async (event) => {
            event.preventDefault();
            if (!selectedFile) {
                message.error("Please select a file to upload");
                return;
            }

            const timestamp = Date.now();
            const uniqueFilename = `${timestamp}.${selectedFile.name.split(".").pop()}`;
            const folderId = currentFolder ? currentFolder.id : null;

            try {
                await uploadCabinetFile(selectedFile, 0, folderId, uniqueFilename);
                toggleUploadModal();
                message.success("File uploaded successfully");
                await refreshCurrentFolder();
            } catch (error) {
                console.error("Upload failed:", error);
                message.error("Failed to upload file");
            }
        },
        [dispatch, selectedFile, currentFolder, uploadCabinetFile, toggleUploadModal, refreshCurrentFolder]
    );

    const isWithinPublicFolder = useCallback(() => {
        if (!folderPath || folderPath.length === 0) return false; // Root level
        return folderPath[0].name.toLowerCase() === "public";
    }, [folderPath]);

    const handleDownload = (item) => {
        if (item.fullPath) {
            const link = document.createElement("a");
            link.href = item.fullPath;
            link.download = item.name || "download";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.error("Invalid item: missing fullPath");
            alert("Error: Cannot download this item");
        }
    };

    const shareFile = async (item) => {
        if (item.fullPath) {
            const object = {
                path: item.fullPath,
            };
            try {
                const result = await dispatch(getShareableLink(object));
                console.log(result);
                if (result.success) {
                    await navigator.clipboard.writeText(result.payload);
                    await showInfoDialog("Link copied to clipboard!", "Success");
                } else {
                    await showInfoDialog("Failed to create shareable link", "Error");
                }
            } catch (error) {
                console.error("Error sharing file:", error);
                await showInfoDialog("An error occurred while sharing the file", "Error");
            }
        } else {
            await showInfoDialog("File can't be shareable", "Warning");
        }
    };

    const getFileIcon = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        switch (extension) {
            case 'pdf':
                return <FilePdfOutlined style={{fontSize: '20px', padding: '8px'}} className="text-danger"/>;
            case 'doc':
            case 'docx':
                return <FileWordOutlined style={{fontSize: '20px', padding: '8px'}} className="text-primary"/>;
            case 'xls':
            case 'xlsx':
                return <FileExcelOutlined style={{fontSize: '20px', padding: '8px'}} className="text-success"/>;
            case 'ppt':
            case 'pptx':
                return <FilePptOutlined style={{fontSize: '20px', padding: '8px'}} className="text-warning"/>;
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                return <FileImageOutlined style={{fontSize: '20px', padding: '8px'}} className="text-info"/>;
            case 'txt':
                return <FileTextOutlined style={{fontSize: '20px', padding: '8px'}} className="text-secondary"/>;
            default:
                return <FileUnknownOutlined style={{fontSize: '20px', padding: '8px'}} className="text-muted"/>;
        }
    };

    return (
        <div className="d-flex h-100 bg-white">
            <div className="border-right" style={{width: "300px", minWidth: "300px", zIndex: 999}}>
                <div className="p-3 border-bottom bg-light">
                    <h5 className="m-0 text-primary">Document Vault</h5>
                    <Input
                        type="text"
                        placeholder="Global search..."
                        value={globalSearchTerm}
                        onChange={(e) => setGlobalSearchTerm(e.target.value)}
                        className="mb-3"
                    />
                    <Button color="primary" onClick={handleGlobalSearch} className="mb-3 w-100">
                        <SearchOutlined/> Search
                    </Button>
                </div>
                <div className="p-3" style={{overflowY: "auto", height: "calc(100% - 180px)"}}>
                    {globalSearchResults.length > 0 ? (
                        <div>
                            <h6 className="mb-2">Search Results:</h6>
                            {globalSearchResults.map((item, index) => (
                                <div key={index} className="mb-2">
                                    <div>{item.name}</div>
                                    <small className="text-muted">{item.fullPath}</small>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <>
                            <div className="font-weight-bold mb-2 text-muted">Filing Cabinet</div>
                            {(parentFolders || []).map((item) => (
                                <TreeNode
                                    key={item.id}
                                    item={item}
                                    depth={0}
                                    onSelect={handleSelect}
                                    selectedItem={selectedItem}
                                    isExpanded={isExpanded}
                                    onToggleExpand={toggleExpand}
                                />
                            ))}
                        </>
                    )}
                </div>
            </div>
            <div className="flex-grow-1 d-flex flex-column">
                <div className="bg-light p-3 border-bottom">
                    <h5 className="mb-0 text-primary">
                        {currentFolder ? currentFolder.name : "Root / Public"}
                    </h5>
                    {renderBreadcrumb()}
                </div>
                <div className="p-3 d-flex justify-content-between align-items-center border-bottom">
                    <Input
                        type="text"
                        placeholder="Search files and folders..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-50"
                    />
                    {isWithinPublicFolder() && (
                        <div className="py-3">
                            <Button
                                color="primary"
                                className="me-2"
                                onClick={toggleCreateFolderModal}
                            >
                                <FolderOutlined className="me-1"/> New Folder
                            </Button>
                            <Button color="success" onClick={toggleUploadModal}>
                                <FileOutlined className="me-1"/> Upload File
                            </Button>
                        </div>
                    )}
                </div>
                <div className="flex-grow-1 overflow-auto">
                    {loading ? (
                        <div className="text-center p-5">
                            <Spinner color="primary"/>
                        </div>
                    ) : error ? (
                        <div className="text-danger p-3">{error}</div>
                    ) : (

                        <AutoSizer>
                            {({height, width}) => (
                                <div style={{height, width}}>
                                    <Table hover responsive className="mb-0"
                                           key={`${JSON.stringify(currentFolderContents)}`}>
                                        <thead className="bg-light">
                                        <tr>
                                            <th onClick={() => handleSort("name")} style={{cursor: "pointer"}}>
                                                Name {renderSortIcon("name")}
                                            </th>
                                            <th onClick={() => handleSort("type")} style={{cursor: "pointer"}}>
                                                Type {renderSortIcon("type")}
                                            </th>
                                            <th onClick={() => handleSort("size")} style={{cursor: "pointer"}}>
                                                File size {renderSortIcon("size")}
                                            </th>
                                            <th onClick={() => handleSort("createdAt")} style={{cursor: "pointer"}}>
                                                Created At {renderSortIcon("createdAt")}
                                            </th>
                                            <th onClick={() => handleSort("createdByUser")} style={{cursor: "pointer"}}>
                                                Created By {renderSortIcon("createdAt")}
                                            </th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {filteredAndSortedContents().map((item) => (
                                            <tr
                                                key={`${JSON.stringify(item)}`}
                                                onClick={() => handleSelect(item)}
                                                className="cursor-pointer hover:bg-light"
                                            >
                                                <td style={{minWidth: '100px'}}>
                                                    <div className="d-flex align-items-center">
                                                        {item.type === "folder" ? (
                                                            <FolderOutlined style={{fontSize: '20px', padding: '8px'}} className="text-warning me-2"/>
                                                        ) : (
                                                            getFileIcon(item.name)
                                                        )}
                                                        <span className="ms-2">{item.name}</span>
                                                    </div>
                                                </td>

                                                <td>{item.type}</td>
                                                <td>{item.type === "folder" ? "-" : item.size || "-"}</td>
                                                <td>{item.createdAt || "-"}</td>
                                                <td>{item.createdByUser || "-"}</td>
                                                <td className="py-3" style={{minWidth: '100px'}}>
                                                    {item.type !== "folder" && (
                                                        <div
                                                            className="d-flex justify-content-around align-items-center">
                                                            <Tooltip title="Share">
                                                                <ShareAltOutlined
                                                                    className="text-primary cursor-pointer"
                                                                    style={{fontSize: '20px', padding: '8px'}}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        shareFile(item);
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                            <Tooltip title="Download">
                                                                <DownloadOutlined
                                                                    className="text-success cursor-pointer"
                                                                    style={{fontSize: '20px', padding: '8px'}}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        handleDownload(item);
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                            <Tooltip title="Rename">
                                                                <EditOutlined
                                                                    className="text-warning cursor-pointer"
                                                                    style={{fontSize: '20px', padding: '8px'}}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        showRenameFileDialog(item);
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                            <Tooltip title="Delete">
                                                                <DeleteOutlined
                                                                    className="text-danger cursor-pointer"
                                                                    style={{fontSize: '20px', padding: '8px'}}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        showDeleteFileDialog(item);
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                </div>
                            )}
                        </AutoSizer>
                    )}
                </div>
            </div>

            <Modal isOpen={isCreateFolderOpen} toggle={toggleCreateFolderModal}>
                <ModalHeader toggle={toggleCreateFolderModal}>
                    Create New Folder
                </ModalHeader>
                <Form onSubmit={handleCreateFolder}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="folderName">Folder Name</Label>
                            <Input
                                id="folderName"
                                value={newFolderName}
                                onChange={(e) => setNewFolderName(e.target.value)}
                                placeholder="Enter folder name"
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            Create
                        </Button>{" "}
                        <Button color="secondary" onClick={toggleCreateFolderModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>

            <Modal isOpen={isUploadModalOpen} toggle={toggleUploadModal} style={{zIndex: 9999999}}>
                <ModalHeader toggle={toggleUploadModal}>Upload File</ModalHeader>
                <Form onSubmit={handleUpload}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="fileUpload">Select File</Label>
                            <Input
                                type="file"
                                name="file"
                                id="fileUpload"
                                accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp4"
                                onChange={handleFileChange}
                                max="104857600"
                            />
                        </FormGroup>
                        {uploadProgress > 0 && (
                            <Progress value={uploadProgress}>{uploadProgress}%</Progress>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            Upload
                        </Button>{" "}
                        <Button color="secondary" onClick={toggleUploadModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
};

export default FileCabinet;