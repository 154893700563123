import {message} from "antd";
import axios from "../../../../axios";
import {setAlert} from "../../alert";
import {GET_INFORM_ACTION_COUNTS, GET_INFORM_ACTION_FILTER, GET_INFORM_ACTIONS} from "../../../types";

export const getActions = (accessModuleId) => async (dispatch) => {
    try {
        const object={
            moduleId:parseInt(accessModuleId)
        }
        const res = await axios.post(`inform/action/user`,object);
        
        if (res.data.code === 1) {
            dispatch({type: GET_INFORM_ACTIONS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const submitGlobalAction=(route,formData)=>async (dispatch) => {
    try {
        const res = await axios.post(`${route}/action/update`, formData);
        
        if (res.data.code === 1) {
            message.success(res.data.message);
            return {success: true, message: res.data.message};
        } else {
            return {success: false, message: res.data.message}
        }
    } catch (err) {
        console.error("Error in submitActionForComplaint:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
}
export const getGlobalActionCount=()=>async (dispatch) => {
    try {
        const res = await axios.get("inform/action/count/user");
        if (res.data.code === 1) {
            dispatch({type: GET_INFORM_ACTION_COUNTS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const getActionFilterData = (route, formData) => async (dispatch) => {
    try {
        const baseUrl = route === "inform" ? "inform" : `inform/${route}`;
        const res = await axios.post(`${baseUrl}/action/filter`, formData);

        if (res.data.code === 1) {
            dispatch({ type: GET_INFORM_ACTION_FILTER, payload: res.data.data });
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const globalActionUpdate=(route,formData)=>async (dispatch) => {
    try {
        
        const res = await axios.post(`inform/${route}/action/update`, formData);
        if (res.data.code === 1) {
            message.success(res.data.message);
            return {success: true, message: res.data.message};
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        console.error("Error in addHazardForm:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
}
export const globalActionAdd=(route,formData)=>async (dispatch) => {
    try {
        const res = await axios.post(`inform/${route}/action/add`, formData);
        if (res.data.code === 1) {
            message.success(res.data.message);
            return {success: true, message: res.data.message};
        } else {
            message.warning(res.data.message);
            return {success: false, message: res.data.message};

        }
    } catch (err) {
        console.error("Error in inform:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
}


export const globalInformModuleObject=(route,id)=>async (dispatch) => {
    try {

        // console.log(`${route}/${id}`)
        
        const res = await axios.get(`${route}/${id}`);
        if (res.data.code === 1) {
            return {success: true, message: res.data.message,payload:res.data.data};
        } else {
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        console.error("Error in inform:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
}


