import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector, } from "react-redux";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import Select from "react-select";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table
} from "reactstrap";
import { DeleteOutlined, EditOutlined, PlusCircleFilled, EyeFilled, FilePdfOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { getQCCheckListValues, getQCFormValues, getQCData, addQCForm, updateQCForm, clearQCFormValues, clearQCData, submitQCToGenerate, submitActionForQC } from "../../../../store/actions/projectMonitoring";
import { AccessFilter, dateTimeSort, formatDateWithTime, stringSorter } from "../../../../utils/functions";
import ReactStrapDataTable from "../../../CustomDatatable/ReactStrapDataTable";
import fileDownload from "react-file-download";
import { message } from "antd";
import { getTags } from "../../../../store/actions/tag";
import { GET_QC_DATA } from "../../../../store/types";

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';



const QCViewSection = ({ isOpen, toggle, data }) => {
    const contentRef = useRef(null);

    const generatePDF = () => {
        const input = contentRef.current;
        html2canvas(input, {
            scale: 2,
            useCORS: true,
            onclone: (clonedDoc) => {
                clonedDoc.querySelectorAll('img').forEach(img => {
                    img.crossOrigin = "anonymous";
                    img.src = img.src;
                });
            }
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
            const imgX = (pdfWidth - imgWidth * ratio) / 2;
            const pageHeight = pdfHeight - 20;

            let heightLeft = imgHeight * ratio;
            let position = 0;
            let page = 1;

            pdf.addImage(imgData, 'JPEG', imgX, 10, imgWidth * ratio, imgHeight * ratio);
            heightLeft -= pageHeight;

            while (heightLeft >= 1) {
                position = -(pageHeight * page);
                pdf.addPage();
                pdf.addImage(imgData, 'JPEG', imgX, position, imgWidth * ratio, imgHeight * ratio);
                heightLeft -= pageHeight;
                page++;
            }

            pdf.save(`QC_Report_${data.sequenceNo || "Not_Generated"}.pdf`);
        });
    };

    if (!data) return null;

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="xl">
            <ModalHeader toggle={toggle}>
                <div className="d-flex justify-content-between align-items-center w-100">
                    <span style={{ marginRight: '10px' }}>View QC Report</span>
                    <Button color="primary" onClick={generatePDF} style={{ marginLeft: 'auto' }}>
                        <FilePdfOutlined /> Download PDF
                    </Button>
                </div>
            </ModalHeader>
            <ModalBody>
                <div ref={contentRef} id="details">
                    <h3>QC Report No. : {data.sequenceNo || 'Not Generated'}</h3>

                    {/* Admin Section */}
                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Admin Details</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={3}><strong>Site:</strong></Col>
                                <Col md={9}>{data.site}</Col>
                                <Col md={3}><strong>L3 Contractor:</strong></Col>
                                <Col md={9}>{data.l3Contractor}</Col>
                                <Col md={3}><strong>Project:</strong></Col>
                                <Col md={9}>{data.project}</Col>
                                <Col md={3}><strong>Location:</strong></Col>
                                <Col md={9}>{`${data.city}, ${data.state}, ${data.country}`}</Col>
                                <Col md={3}><strong>Business Unit:</strong></Col>
                                <Col md={9}>{data.businessUnit}</Col>
                                <Col md={3}><strong>Audited At:</strong></Col>
                                <Col md={9}>{new Date(data.auditedAt).toLocaleString()}</Col>
                                <Col md={3}><strong>Responded By:</strong></Col>
                                <Col md={9}>{data.respondedByUser}</Col>
                            </Row>
                        </CardBody>
                    </Card>

                    {/* Checklist Section */}
                    <Card className="mb-3">
                        <CardHeader>
                            <h4>QC Checklist</h4>
                        </CardHeader>
                        <CardBody>
                            <Table responsive striped>
                                <thead>
                                    <tr>
                                        <th>Clause</th>
                                        <th>Status</th>
                                        <th>Remarks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.checklist.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.id}</td>
                                            <td>{item.status || 'Not Checked'}</td>
                                            <td>{item.remarks || 'No remarks'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>

                    {/* Signatures Section */}
                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Signatures</h4>
                        </CardHeader>
                        <CardBody>
                            {data.signs.map((sign, index) => (
                                <Row key={index} className="mb-3">
                                    <Col md={6}>
                                        <strong>L2 Signature:</strong>
                                        <img
                                            src={sign.l2FullPath}
                                            alt="L2 Signature"
                                            style={{ maxWidth: '100%', border: '1px solid #ddd', marginTop: '5px' }}
                                            crossOrigin="anonymous"
                                        />
                                        <p>Submitted at: {new Date(sign.createdAt).toLocaleString()}</p>
                                    </Col>
                                    <Col md={6}>
                                        <strong>L3 Signature:</strong>
                                        <img
                                            src={sign.l3FullPath}
                                            alt="L3 Signature"
                                            style={{ maxWidth: '100%', border: '1px solid #ddd', marginTop: '5px' }}
                                            crossOrigin="anonymous"
                                        />
                                        <p>Updated at: {sign.updatedAt ? new Date(sign.updatedAt).toLocaleString() : 'Not updated yet'}</p>
                                    </Col>
                                </Row>
                            ))}
                        </CardBody>
                    </Card>

                    {/* Actions Section */}
                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Actions</h4>
                        </CardHeader>
                        <CardBody>
                            <Table responsive striped>
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Created By</th>
                                        <th>Created For</th>
                                        <th>Description</th>
                                        <th>Status</th>
                                        <th>Target Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.actions.map((action, index) => (
                                        <tr key={index}>
                                            <td>{action.type}</td>
                                            <td>{action.createdByUser}</td>
                                            <td>{action.createdFor}</td>
                                            <td>{action.description}</td>
                                            <td>{action.status}</td>
                                            <td>{new Date(action.targetAt).toLocaleDateString()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <h5 className="mt-3">Comments</h5>
                            {data.actions[0].comments.map((comment, index) => (
                                <div key={index} className="mb-2">
                                    <strong>{new Date(comment.createdAt).toLocaleString()}</strong>: {comment.description}
                                </div>
                            ))}
                        </CardBody>
                    </Card>

                    {/* Attachments Section */}
                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Attachments</h4>
                        </CardHeader>
                        <CardBody>
                            {data.files.map((file, index) => (
                                <div key={index} className="mb-2">
                                    <a href={file.fullPath} target="_blank" rel="noopener noreferrer">{file.fileName}</a>
                                    <p>{file.description}</p>
                                </div>
                            ))}
                        </CardBody>
                    </Card>
                </div>
            </ModalBody>
        </Modal>
    );
};


const ActionModal = ({ isOpen, toggle, onInitiateAction, data, message }) => {
    const dispatch = useDispatch();
    const tags = useSelector((state) => state.tag.list);
    const [initiateModalOpen, setInitiateModalOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const [actionFormData, setActionFormData] = useState({
        submitDate: new Date().toISOString().split('T')[0],
        status: { value: 'open', label: 'Open' },
        description: '',
        tags: [],
        comments: []
    });

    const statusOptions = [
        { value: 'OPEN', label: 'Open' },
        { value: 'CLOSED', label: 'Close' }
    ];

    const resetForm = () => {
        setActionFormData({
            submitDate: new Date().toISOString().split('T')[0],
            targetAt: '',
            status: { value: 'open', label: 'Open' },
            description: '',
            tags: [],
            comments: []
        });
    };

    const toggleInitiateModal = (action = true) => {
        setInitiateModalOpen(!initiateModalOpen);
        setSelectedAction(action);
        if (action) {
            const selectedTags = action.tags
                ? action.tags.map(tagId => {
                    const matchingTag = tags.find(tag => tag.id.toString() === tagId.toString());
                    return matchingTag
                        ? { value: matchingTag.id.toString(), label: matchingTag.name }
                        : null;
                }).filter(tag => tag !== null)
                : [];
            setActionFormData({
                submittedAt: action.submittedAt,
                submitDate: formatDateWithTime(action.submittedAt, false) || new Date().toISOString().split('T')[0],
                targetAt: formatDateWithTime(action.targetAt, false) || '',
                description: action.description,
                reply: action.message || '',
                tags: selectedTags,
                comments: action.comments || [],
                type: action.type,
                targetDays: action.targetDays,
                status: action.status,
                id: action.id,
                userId: action.userId,
                createdBy: action.createdBy,
                updatedBy: action.updatedBy,
                createdAt: action.createdAt,
                updatedAt: action.updatedAt
            });
        } else {
            resetForm();
        }
    };

    const handleActionFormChange = (e) => {
        const { name, value } = e.target;
        setActionFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSelectChange = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        setActionFormData(prevState => ({
            ...prevState,
            [name]: name === 'status' ? selectedOption.value : selectedOption
        }));
    };
    const addCommentRow = () => {
        setActionFormData(prevState => ({
            ...prevState,
            comments: [...prevState.comments, {
                id: null,
                description: '',
                createdBy: null,
                updatedBy: null,
                createdAt: null,
                updatedAt: null
            }]
        }));
    };

    const removeCommentRow = (index) => {
        setActionFormData(prevState => ({
            ...prevState,
            comments: prevState.comments.filter((_, i) => i !== index)
        }));
    };

    const handleCommentChange = (index, value) => {
        setActionFormData(prevState => ({
            ...prevState,
            comments: prevState.comments.map((comment, i) =>
                i === index ? { ...comment, description: value } : comment
            )
        }));
    };
    const handleInitiateSubmit = (e) => {
        e.preventDefault();
        const action = {
            ...actionFormData,
            submitDate: new Date().toISOString().split('T')[0],
            status: actionFormData.status || 'PENDING',
            tags: Array.isArray(actionFormData.tags) && actionFormData.tags.length > 0
                ? actionFormData.tags
                    .filter(tag => tag && typeof tag.value !== 'undefined')
                    .map(tag => parseInt(tag.value))
                : []
        };
        onInitiateAction(action);
        toggleInitiateModal();
    };

    useEffect(() => {
        dispatch(getTags());
    }, [dispatch]);

    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} size="xl">
                <ModalHeader toggle={toggle}>
                    {message && <p>{message}</p>}
                    Number: {data?.sequenceNo}
                </ModalHeader>
                <ModalBody>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Action Type</th>
                                <th>Submit Date</th>
                                <th>From</th>
                                <th>Message Description</th>
                                <th>To</th>
                                <th>Response Message</th>
                                <th>Response On</th>
                                <th>Response By</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.actions?.map((action, index) => (
                                <tr key={index}>
                                    <td>
                                        <Button color="primary" size="sm" onClick={() => toggleInitiateModal(action)}>
                                            {action.status}
                                        </Button>
                                    </td>
                                    <td>{action.actionType}</td>
                                    <td>{action.submittedAt}</td>
                                    <td>{action.createdByUser}</td>
                                    <td>{action.description}</td>
                                    <td>{action.createdFor}</td>
                                    <td>{action.reply}</td>
                                    <td>{action.responseAt}</td>
                                    <td>{action.responseByUser}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={initiateModalOpen} toggle={toggleInitiateModal} size="lg">
                <Form onSubmit={handleInitiateSubmit}>
                    <ModalHeader toggle={toggleInitiateModal}>
                        {selectedAction ? 'Edit Action' : 'Initiate New Action'}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="submitDate">Submit Date</Label>
                                    <Input
                                        type="date"
                                        name="submitDate"
                                        id="submitDate"
                                        value={actionFormData.submitDate}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="targetAt">Target Date</Label>
                                    <Input
                                        type="date"
                                        name="targetAt"
                                        id="targetAt"
                                        value={actionFormData.targetAt}
                                        onChange={handleActionFormChange}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="status">Status</Label>
                                    <Select
                                        name="status"
                                        options={statusOptions}
                                        value={statusOptions.find(option => option.value === actionFormData.status) || null}
                                        onChange={handleSelectChange}
                                    // isDisabled={actionFormData.status === 'PENDING'}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <Label for="description">Description</Label>
                            <Input
                                type="textarea"
                                name="description"
                                id="description"
                                value={actionFormData.description}
                                onChange={handleActionFormChange}
                                disabled
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="message">Response Message</Label>
                            <Input
                                type="textarea"
                                name="message"
                                id="message"
                                value={actionFormData.message}
                                onChange={handleActionFormChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="tags">Tags</Label>
                            <Select
                                isMulti
                                name="tags"
                                options={tags?.map(tag => ({
                                    value: tag.id.toString(),
                                    label: tag.name
                                })) || []}
                                value={actionFormData.tags}
                                onChange={handleSelectChange}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Comments</Label>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {actionFormData.comments.map((comment, index) => (
                                        <tr key={index}>
                                            <td>
                                                <Input
                                                    type="textarea"
                                                    value={comment.description}
                                                    onChange={(e) => handleCommentChange(index, e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                <Button color="danger"
                                                    onClick={() => removeCommentRow(index)}><DeleteOutlined /></Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Button color="primary" onClick={addCommentRow}>Add Comment</Button>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleInitiateModal}>Cancel</Button>
                        <Button color="primary" type="submit">Submit</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};
const CustomSignatureCanvas = ({ width = 300, height = 150, onSignatureChange, initialValue }) => {

    console.log(initialValue)
    const canvasRef = useRef(null);
    const [isDrawing, setIsDrawing] = useState(false);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.lineWidth = 2;
        context.lineCap = 'round';
        context.strokeStyle = 'black';

        if (initialValue) {
            const img = new Image();
            img.crossOrigin = 'anonymous'
            img.onload = () => {
                context.drawImage(img, 0, 0, width, height);
            };
            img.src = initialValue;
        }
    }, [initialValue, width, height]);

    const startDrawing = (e) => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const context = canvas.getContext('2d');
        setIsDrawing(true);
        context.beginPath();
        context.moveTo(e.clientX - rect.left, e.clientY - rect.top);
    };

    const draw = (e) => {
        if (!isDrawing) return;
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const context = canvas.getContext('2d');
        context.lineTo(e.clientX - rect.left, e.clientY - rect.top);
        context.stroke();
    };

    const stopDrawing = () => {
        setIsDrawing(false);
        const canvas = canvasRef.current;
        const base64String = canvas.toDataURL('image/png');
        onSignatureChange(base64String);
    };

    const clearCanvas = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
        onSignatureChange('');
    };

    return (
        <div className="custom-signature-canvas">
            <canvas
                ref={canvasRef}
                width={width}
                height={height}
                onMouseDown={startDrawing}
                onMouseMove={draw}
                onMouseUp={stopDrawing}
                onMouseOut={stopDrawing}
                style={{ border: '1px solid #000' }}
            />
            <button onClick={clearCanvas} className="clear-button">Clear</button>
        </div>
    );
};

const ActionSection = ({ actions: initialActions = [], onActionsChange, actionUsers }) => {
    const [internalKeys, setInternalKeys] = useState({});

    useEffect(() => {
        // Initialize internalKeys with existing actions
        const initialKeys = initialActions.reduce((acc, action, index) => {
            acc[`action_${index}`] = action;
            return acc;
        }, {});
        setInternalKeys(initialKeys);
    }, []);

    const generateKey = () =>
        `action_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

    const addNewRow = () => {
        const newAction = {
            userId: '',
            description: '',
            type: 'N',
            status: 'PENDING',
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
        };
        const newKey = generateKey();
        setInternalKeys((prev) => ({ ...prev, [newKey]: newAction }));
        onActionsChange([...Object.values(internalKeys), newAction]);
    };

    const updateAction = (key, field, value) => {
        const updatedActions = Object.values(internalKeys).map((act) =>
            act === internalKeys[key] ? { ...act, [field]: value, updatedAt: new Date().toISOString() } : act
        );
        setInternalKeys((prev) => ({
            ...prev,
            [key]: { ...prev[key], [field]: value, updatedAt: new Date().toISOString() },
        }));
        onActionsChange(updatedActions);
    };

    const deleteAction = (key) => {
        const filteredActions = Object.values(internalKeys).filter(
            (act) => act !== internalKeys[key]
        );
        setInternalKeys((prev) => {
            const newKeys = { ...prev };
            delete newKeys[key];
            return newKeys;
        });
        onActionsChange(filteredActions);
    };

    const userOptions = actionUsers.map(user => ({
        value: user.id,
        label: `${user.name}`
    }));

    const statusOptions = [
        { value: 'PENDING', label: 'Pending' },
        { value: 'IN_PROGRESS', label: 'In Progress' },
        { value: 'COMPLETED', label: 'Completed' }
    ];

    return (
        <Card style={{ border: '2px solid #ced4da', borderRadius: '5px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <CardHeader style={{
                backgroundColor: '#e6f2ff',
                borderBottom: '1px solid #b8daff',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
                <h2 className="card-title mb-0">Actions</h2>
                <Button color="primary" size="sm" onClick={addNewRow}>
                    <i className="fas fa-plus"></i>
                </Button>
            </CardHeader>
            <CardBody>
                <Table>
                    <thead>
                        <tr>
                            <th>To</th>
                            <th>Description</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(internalKeys).map(([key, action]) => (
                            <tr key={key} style={action.type !== 'N' ? { backgroundColor: '#f0f0f0' } : {}}>
                                <td>
                                    <Select
                                        value={userOptions.find(option => option.value === action.userId)}
                                        onChange={(selectedOption) => updateAction(key, 'userId', selectedOption.value)}
                                        options={userOptions}
                                        placeholder="Select User"
                                        isDisabled={action.type !== 'N'}
                                    />
                                </td>
                                <td>
                                    <Input
                                        type="textarea"
                                        value={action.description}
                                        onChange={(e) => updateAction(key, 'description', e.target.value)}
                                        placeholder="Type Description"
                                        disabled={action.type !== 'N'}
                                    />
                                </td>
                                <td>
                                    {action.type === 'N' && (
                                        <Button color="danger" size="sm" onClick={() => deleteAction(key)}>
                                            <i className="fas fa-minus"></i>
                                        </Button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
};

const AttachmentsSection = ({ attachments = [], onAttachmentsChange }) => {
    const [internalKeys, setInternalKeys] = useState({});

    useEffect(() => {
        // Initialize internalKeys with existing attachments
        const initialKeys = attachments.reduce((acc, attachment, index) => {
            acc[`attachment_${index}`] = attachment;
            return acc;
        }, {});
        setInternalKeys(initialKeys);
    }, []);

    const generateKey = () =>
        `attachment_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

    const addNewRow = () => {
        const newAttachment = {
            filename: "",
            name: "",
            description: "",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            file: null,
            path: null,
        };
        const newKey = generateKey();
        setInternalKeys((prev) => ({ ...prev, [newKey]: newAttachment }));
        onAttachmentsChange([...attachments, newAttachment]);
    };

    const updateAttachment = (key, field, value) => {
        const updatedAttachments = Object.values(internalKeys).map((att, index) =>
            att === internalKeys[key] ? { ...att, [field]: value } : att,
        );
        setInternalKeys((prev) => ({
            ...prev,
            [key]: { ...prev[key], [field]: value },
        }));
        onAttachmentsChange(updatedAttachments);
    };

    const handleFileChange = (key, file) => {
        if (file && file.size > 5 * 1024 * 1024) {
            alert("File size should not exceed 5MB");
            return;
        }

        const updatedAttachment = {
            ...internalKeys[key],
            filename: file.name,
            name: file.name,
            file: file,
            path: null,
        };
        setInternalKeys((prev) => ({
            ...prev,
            [key]: updatedAttachment,
        }));
        const updatedAttachments = Object.values(internalKeys).map((att) =>
            att === internalKeys[key] ? updatedAttachment : att,
        );
        onAttachmentsChange(updatedAttachments);
    };

    const deleteAttachment = (key) => {
        const filteredAttachments = Object.values(internalKeys).filter(
            (att) => att !== internalKeys[key],
        );
        setInternalKeys((prev) => {
            const newKeys = { ...prev };
            delete newKeys[key];
            return newKeys;
        });
        onAttachmentsChange(filteredAttachments);
    };

    const viewAttachment = (attachment) => {
        if (attachment.file instanceof File) {
            const url = URL.createObjectURL(attachment.file);
            window.open(url, "_blank");
        } else if (attachment.fullPath || attachment.path) {
            fetch(`${encodeURIComponent(attachment.fullPath || attachment.path)}`)
                .then((response) => response.blob())
                .then((blob) => {
                    fileDownload(
                        blob,
                        attachment.fileName || attachment.filename || "download",
                    );
                })
                .catch((error) => {
                    console.error("Error downloading file:", error);
                    alert("Error downloading file. Please try again.");
                });
        }
    };

    return (
        <Card
            style={{
                border: "2px solid #ced4da",
                borderRadius: "5px",
                boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            }}
        >
            <CardHeader
                style={{
                    backgroundColor: "#e6f2ff",
                    borderBottom: "1px solid #b8daff",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <h2 className="card-title mb-0">Attachments</h2>
                <Button color="primary" size="sm" onClick={addNewRow}>
                    <i className="fas fa-plus"></i>
                </Button>
            </CardHeader>
            <CardBody>
                <Table>
                    <thead>
                        <tr>
                            <th>File Name</th>
                            <th>Description</th>
                            <th>Upload File</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(internalKeys).map(([key, attachment]) => (
                            <tr key={key}>
                                <td>{attachment.filename || attachment.name}</td>
                                <td>
                                    <Input
                                        type="text"
                                        value={attachment.description}
                                        onChange={(e) =>
                                            updateAttachment(key, "description", e.target.value)
                                        }
                                        style={{ borderColor: "black" }}
                                    />
                                </td>
                                <td>
                                    <FormGroup>
                                        <Label for={`file-${key}`} className="btn btn-secondary">
                                            {attachment.file || attachment.path
                                                ? "Change File"
                                                : "Upload File"}
                                        </Label>
                                        <Input
                                            type="file"
                                            id={`file-${key}`}
                                            onChange={(e) => handleFileChange(key, e.target.files[0])}
                                            style={{ display: "none" }}
                                        />
                                    </FormGroup>
                                </td>
                                <td>
                                    <Button
                                        color="secondary"
                                        size="sm"
                                        className="mr-2"
                                        onClick={() => viewAttachment(attachment)}
                                        disabled={!attachment.file && !attachment.path}
                                    >
                                        {attachment.fullPath || attachment.path
                                            ? "Download"
                                            : "View"}
                                    </Button>
                                    <Button
                                        color="danger"
                                        size="sm"
                                        onClick={() => deleteAttachment(key)}
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
};

const QCFormComponent = ({
    control,
    setValue,
    watch,
    sites,
    l3,
    respondents,
    checklists,
    signatures,
    setSignatures
}) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name: "signatures"
    });

    const updateSignature = (id, field, base64String) => {
        setSignatures(prevSignatures =>
            prevSignatures.map(sig =>
                sig.id === id ? { ...sig, [field]: base64String, updatedAt: new Date().toISOString() } : sig
            )
        );
    };

    const addSignatureRow = () => {
        const newId = Date.now();
        setSignatures(prevSignatures => [
            ...prevSignatures,
            {
                id: newId,
                isNew: true,
                submittedSign: "",
                reviewedSign: "",
                oldSubmittedSign: "",
                oldReviewedSign: "",
                createdAt: new Date().toISOString(),
                createdBy: null,
                updatedAt: null,
                updatedBy: null,
                submittedAt: null
            }
        ]);
    };

    const removeSignatureRow = (id) => {
        setSignatures(signatures.filter(sig => sig.id !== id));
    };

    return (
        <Form>
            <section className="mb-4">
                <h4>Details</h4>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="respondent">
                                Respondent <span className="text-danger">*</span>
                            </Label>
                            <Controller
                                name="respondent"
                                control={control}
                                rules={{ required: 'Respondent is required' }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={respondents.map(r => ({ value: r.id, label: r.name }))}
                                        placeholder="Select Respondent"
                                    />
                                )}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="site">
                                Site <span className="text-danger">*</span>
                            </Label>
                            <Controller
                                name="site"
                                control={control}
                                rules={{ required: 'Site is required' }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={sites.map(s => ({ value: s.id, label: s.name }))}
                                        placeholder="Select Site"
                                    />
                                )}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="l3">
                                L3 <span className="text-danger">*</span>
                            </Label>
                            <Controller
                                name="l3"
                                control={control}
                                rules={{ required: 'L3 is required' }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={l3.map(l => ({ value: l.id, label: l.name }))}
                                        placeholder="Select L3"
                                    />
                                )}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </section>

            {/* <section>
                <h4>Form</h4>
                <Table responsive>
                    <thead>
                        <tr>

                            <th>Clause</th>
                            <th>Action</th>
                            <th>Remarks</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(checklists??[]).map((checklist) => (
                            <tr key={checklist.id}>
                                
                                <td>{`${checklist.id}  ${checklist.label}`}</td>
                                <td>
                                    <Controller
                                        name={`checklist.${checklist.id}.status`}
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                options={[
                                                    { value: 'compliant', label: 'Compliant' },
                                                    { value: 'non-compliant', label: 'Non-Compliant' },
                                                    { value: 'not-applicable', label: 'Not Applicable' }
                                                ]}
                                            />
                                        )}
                                    />
                                </td>
                                <td>
                                    <Controller
                                        name={`checklist.${checklist.id}.remarks`}
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input {...field} type="text" placeholder="Remarks" />
                                        )}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </section> */}
            <section>
                <h4>Form</h4>
                <Table responsive>
                    <thead>
                        <tr>

                            <th>Clause</th>
                            <th>Action</th>
                            <th>Remarks</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(checklists ?? []).map((checklist) => (

                            <React.Fragment key={checklist.id}>
                                {checklist.title && (
                                    <tr>
                                        <td colSpan="3">
                                            <h5>{checklist.title}</h5>
                                            <hr />
                                        </td>
                                    </tr>
                                )}
                                <tr>

                                    <td>
                                        {(checklist.show === undefined || checklist.show === true) && `${checklist.id}  `}
                                        {checklist.label}
                                        {checklist.remarks && (
                                            <div style={{ fontSize: '0.9em', color: '#666' }}>
                                                {checklist.remarks}
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        <Controller
                                            name={`checklist.${checklist.id}.status`}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    options={[
                                                        { value: 'compliant', label: 'Compliant' },
                                                        { value: 'non-compliant', label: 'Non-Compliant' },
                                                        { value: 'not-applicable', label: 'Not Applicable' }
                                                    ]}
                                                />
                                            )}
                                        />
                                    </td>
                                    <td>
                                        <Controller
                                            name={`checklist.${checklist.id}.remarks`}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <Input {...field} type="text" placeholder="Remarks" />
                                            )}
                                        />
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </Table>
            </section>


            <section>
                <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '20px' }}>
                    <span style={{ marginRight: '10px' }}>L2 Auditor</span>
                    <Button color="primary" onClick={addSignatureRow}>
                        <i className="fas fa-plus"></i> Add
                    </Button>
                </div>
                {signatures.map((sig) => (
                    <Row key={sig.id} className="mb-3">
                        <Col md="5">
                            <Label>L2 Signature </Label>
                            <CustomSignatureCanvas
                                width={300}
                                height={150}
                                onSignatureChange={(base64String) => updateSignature(sig.id, 'submittedSign', base64String)}
                                initialValue={sig.submittedSign}
                            />
                        </Col>
                        <Col md="5">
                            <Label>L3 Acceptance</Label>
                            <CustomSignatureCanvas
                                width={300}
                                height={150}
                                onSignatureChange={(base64String) => updateSignature(sig.id, 'reviewedSign', base64String)}
                                initialValue={sig.reviewedSign}
                            />
                        </Col>
                        <Col md="2">
                            <Button color="danger" onClick={() => removeSignatureRow(sig.id)} className="mt-4">
                                <i className="fas fa-minus"></i> Remove
                            </Button>
                        </Col>
                    </Row>
                ))}
            </section>

            <Col md={12}>
                <FormGroup>
                    <Label for="dateOfAudit">Date of Audit</Label>
                    <Controller
                        name="dateOfAudit"
                        control={control}
                        render={({ field }) => (
                            <Input
                                {...field}
                                type="date"
                                id="dateOfAudit"
                            />
                        )}
                    />
                </FormGroup>
            </Col>
        </Form>
    );
};




const QualityCheck = ({ access }) => {

    const dispatch = useDispatch();
    const qcValues = useSelector((state) => state.qc.qcValues);
    const qcFormValues = useSelector((state) => state.qc.qcFormValues);
    const qcData = useSelector((state) => state.qc.qcData);

    const [attachments, setAttachments] = useState([]);
    const [actions, setActions] = useState([])


    const [signatures, setSignatures] = useState([]);
    const signatureRefs = useRef({});


    const [modalOpen, setModalOpen] = useState(false);
    const [editingQC, setEditingQC] = useState(null);
    const [selectedQC, setSelectedQC] = useState(null);

    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [qcToGenerate, setqcToGenerate] = useState(null);

    const [actionModalOpen, setActionModalOpen] = useState(false);
    const toggleActionModal = () => setActionModalOpen(!actionModalOpen);
    const [actionData, setActionData] = useState({});

    //EYEBUTTON MODAL
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedViewRecord, setSelectedRecord] = useState(null);

    //Shared Access
    const [accessFilter, setAccessFilter] = useState('My');

    const handleAccessFilterChange = (selectedAccess) => {
        setAccessFilter(selectedAccess);
    };






    const { control, handleSubmit, reset, setValue, watch } = useForm({
        defaultValues: {
            respondent: null,
            site: null,
            l3: null,
            dateOfAudit: '',
            checklist: {},
            signatures: []
        }
    });

    const resetForm = () => {
        reset({
            respondent: null,
            site: null,
            l3: null,
            dateOfAudit: '',
            checklist: {},
            signatures: []
        });
        setAttachments([]);
        setActions([]);
        setSignatures([]);
    };

    useEffect(() => {
        dispatch(getQCCheckListValues());
    }, [dispatch]);

    useEffect(() => {
        if (selectedQC !== null) {
            dispatch({ type: GET_QC_DATA, payload: [] });
            dispatch(getQCFormValues(selectedQC.value));
            dispatch(getQCData(selectedQC.value, access.moduleId));
        }
    }, [selectedQC, dispatch]);

    const handleEdit = (qc) => {
        resetForm();

        const formattedQC = {
            id: qc.id,
            respondent: { value: qc.respondedBy, label: qc.respondedByUser },
            site: { value: qc.siteId, label: qc.site },
            l3: { value: qc.l3Id, label: qc.l3Contractor },
            dateOfAudit: formatDateWithTime(qc.auditedAt, false),
            status: qc.status,
            checklist: {},
        };

        // Format checklist
        if (Array.isArray(qc.checklist)) {
            qc.checklist.forEach(item => {
                const [mainId, subId] = item.id.split('.');
                if (!formattedQC.checklist[mainId]) {
                    formattedQC.checklist[mainId] = {};
                }
                formattedQC.checklist[mainId][subId] = {
                    status: item.status ? { value: item.status, label: item.status } : null,
                    remarks: item.remarks
                };
            });
        }

        let editSignatures = [];
        // Format signatures
        if (Array.isArray(qc.signs)) {
            editSignatures = (qc.signs || []).map((sign, index) => ({
                id: sign.id ?? null,
                oldReviewedSign: sign.l3Sign,
                oldSubmittedSign: sign.l2Sign,
                submittedSign: sign.l2FullPath,
                reviewedSign: sign.l3FullPath,
                createdAt: sign.createdAt,
                createdBy: sign.createdBy,
                updatedAt: sign.updatedAt ?? null,
                updatedBy: sign.updatedBy ?? null,
                submittedAt: sign.submittedAt,
                isNew: false,
            }))
        } else {
            editSignatures = [];
        }

        setSignatures(editSignatures)

        // Set actions
        if (Array.isArray(qc.actions)) {
            const formattedActions = qc.actions.map(action => ({
                id: action.id,
                userId: action.userId,
                description: action.description,
                createdAt: action.createdAt,
                createdBy: action.createdBy,
                updatedAt: action.updatedAt,
                updatedBy: action.updatedBy,
                submittedAt: action.submittedAt,
                targetAt: action.targetAt,
                reply: action.reply,
                tags: action.tags,
                type: action.type,
                status: action.status,
            }));
            setActions(formattedActions);
        } else {
            setActions([]);
        }

        // Set attachments
        if (Array.isArray(qc.files)) {
            const formattedAttachments = qc.files.map(file => ({
                id: file.id,
                filename: file.fileName,
                name: file.fileName,
                description: file.description,
                path: file.path,
                fullPath: file.fullPath,
                createdAt: file.createdAt,
                createdBy: file.createdBy,
                updatedAt: file.updatedAt,
                updatedBy: file.updatedBy,
            }));
            setAttachments(formattedAttachments);
        } else {
            setAttachments([]);
        }

        setEditingQC(qc);
        reset(formattedQC);
        setModalOpen(true);
    };

    const handleDelete = async (qc) => {
        if (window.confirm("Are you sure you want to delete this QC item?")) {
            // Implement delete functionality here
            console.log("Deleting QC item:", qc);
        }
    };

    const onFormSubmit = async (data) => {
        const formDataObj = new FormData();
        formDataObj.append("moduleId", parseInt(access.moduleId))

        // Append basic form data
        if (data.id) {
            formDataObj.append("id", parseInt(data.id.toString()));
        }

        formDataObj.append("siteId", parseInt(data.site.value));
        formDataObj.append("l3Id", parseInt(data.l3.value));
        formDataObj.append("respondedBy", parseInt(data.respondent.value));
        if (data.dateOfAudit !== "" && data.dateOfAudit !== null) {
            formDataObj.append("auditedAt", data.dateOfAudit || null);
        }

        formDataObj.append("status", data.status || false);

        // Process checklist
        const checklist = data.checklist ? Object.entries(data.checklist).flatMap(([mainId, subItems]) =>
            Object.entries(subItems).map(([subId, value]) => ({
                id: `${mainId}.${subId}`,
                status: value?.status?.value ?? null,
                remarks: value?.remarks ?? null
            }))
        ) : [];
        formDataObj.append("checklist", JSON.stringify(checklist));

        // Process signatures
        const signatureData = signatures.map(sig => ({
            id: sig.id,
            l2Sign: sig.submittedSign, // This should already be a base64 string
            l3Sign: sig.reviewedSign, // This should already be a base64 string
            oldL2Sign: sig.oldSubmittedSign || "",
            oldL3Sign: sig.oldReviewedSign || "",
            createdAt: sig.createdAt,
            createdBy: sig.createdBy,
            updatedAt: sig.updatedAt || new Date().toISOString(),
            updatedBy: sig.updatedBy,
            submittedAt: sig.submittedAt
        }));
        formDataObj.append("signs", JSON.stringify(signatureData));

        // Process actions
        if (Array.isArray(actions)) {
            formDataObj.append(
                "actions",
                JSON.stringify(
                    actions.map((action) => ({
                        id: action.id || null,
                        userId: action.userId ? parseInt(action.userId) : null,
                        description: action.description || null,
                        createdAt: action.createdAt || null,
                        createdBy: action.createdBy || null,
                        updatedAt: action.updatedAt || null,
                        updatedBy: action.updatedBy || null,
                        submittedAt: action.submittedAt || null,
                        targetAt: action.targetAt || null,
                        reply: action.reply || null,
                        tags: action.tags || [],
                        type: action.type || "N",
                        status: action.status || "PENDING",
                    }))
                )
            );
        } else {
            formDataObj.append("actions", JSON.stringify([]));
        }

        // Process attachments
        if (Array.isArray(attachments)) {
            const filesMetadata = attachments.map((attachment) => ({
                id: attachment.id || null,
                fileName: attachment.filename || attachment.name || "",
                description: attachment.description || null,
                path: attachment.path || null,
                createdAt: attachment.createdAt || null,
                createdBy: attachment.createdBy || null,
                updatedBy: attachment.updatedBy || null,
                updatedAt: attachment.updatedAt || null,
            }));
            formDataObj.append("files", JSON.stringify(filesMetadata));

            attachments.forEach((attachment, index) => {
                if (attachment.file instanceof File) {
                    formDataObj.append(
                        `attachments[${index}]`,
                        attachment.file,
                        attachment.filename || attachment.name
                    );
                }
            });
        } else {
            formDataObj.append("files", JSON.stringify([]));
        }

        // Uncomment the following block when ready to submit the form

        try {
            let result;
            if (data.id) {
                // formDataObj.append('id', parseInt(editingQC.id));
                formDataObj.append('fileId', parseInt(editingQC.fileId));
                formDataObj.append('actionId', parseInt(editingQC.actionId));
                result = await dispatch(updateQCForm(selectedQC.value, formDataObj));
            } else {
                result = await dispatch(addQCForm(selectedQC.value, formDataObj));
            }
            if (result.success) {
                message.success(result.message);
                setModalOpen(false);
                setEditingQC(null);
                setSignatures([])
                reset();
                if (selectedQC !== null) {
                    dispatch(getQCFormValues(selectedQC.value));
                    dispatch(getQCData(selectedQC.value, access.moduleId));
                }
            } else {
                message.error(result.message || 'An error occurred');
            }
        } catch (error) {
            console.error(error);
            message.error('An unexpected error occurred');
        }

    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setEditingQC(null);
        reset();
    };

    const handleAddNew = () => {
        setEditingQC(null);
        resetForm();
        setModalOpen(true);
    };

    const handleQCSelect = (selectedOption) => {
        setSelectedQC(selectedOption);
        if (!selectedOption) {
            dispatch(clearQCFormValues());
            dispatch(clearQCData());
            setEditingQC(null);
            setModalOpen(false);
            reset();
            setAttachments([]);
            setActions([]);
        }
    };

    const confirmQCGenerate = async () => {
        if (qcToGenerate) {
            const response = await dispatch(submitQCToGenerate(selectedQC.value, qcToGenerate.id),);
            if (response.success) {
                message.success("QC Sequence generated successfully");
                setConfirmModalOpen(false);
                setqcToGenerate(null);
                resetForm();
                if (selectedQC !== null) {
                    dispatch(getQCFormValues(selectedQC.value));
                    dispatch(getQCData(selectedQC.value, access.moduleId));
                }

            } else {
                message.error("Failed to generate QC Sequence");
            }

        }
    };

    const handleGenerate = async (data) => {
        setqcToGenerate(data)
        setConfirmModalOpen(true);
    };
    const handleActionClick = (data) => {
        setActionData(data);
        setActionModalOpen(true);
    };

    const handleInitiateAction = async (data) => {
        delete data.submitDate;
        if (data.message) {
            data.reply = actionData.message;
            delete data.message;
        }

        const payload = {
            informQcId: parseInt(actionData.id),
            id: parseInt(actionData.actionId),
            actions: JSON.stringify(data),
        };




        const response = await dispatch(submitActionForQC(selectedQC.value, payload));
        if (response.success) {
            setActionData([]);
            setActionModalOpen(false);
            setModalOpen(false);
            if (selectedQC !== null) {
                dispatch(getQCFormValues(selectedQC.value));
                dispatch(getQCData(selectedQC.value, access.moduleId));
                resetForm();
            }
        } else {
            message.error(response.message);
        }
    };


    const viewRecord = (record) => {

        console.log(record)

        setSelectedRecord(record);
        setViewModalOpen(true);
    };


    const columns = [
        {
            title: "Actions",
            key: "actions",
            render: (text, data) => (
                <div style={{ display: "flex", gap: "8px" }}>
                    {data.submittedAt === null && (
                        <Button color="primary" size="sm" onClick={() => handleEdit(data)}>
                            <EditOutlined />
                        </Button>
                    )}
                    {data.submittedAt === null && (<>
                        <Button color="danger" size="sm" onClick={() => handleDelete(data)}>
                            <DeleteOutlined />
                        </Button>
                    </>)}
                    <Button color="info" size="sm" onClick={() => viewRecord(data)}>
                        <EyeFilled />
                    </Button>
                    {data.submittedAt !== null && (
                        <Button
                            color="info"
                            size="sm"
                            onClick={() => handleActionClick(data)}
                            disabled={accessFilter === 'Shared'}
                        >
                            <PlusCircleFilled />
                        </Button>
                    )}
                </div>
            ),
        },
        {
            title: "Sequence Number",
            dataIndex: "submittedAt",
            key: "submittedAt",
            sorter: stringSorter("submittedAt"),
            sortDirections: ["ascend", "descend"],
            render: (text, data) => {
                if (data.submittedAt !== null) {
                    return data.sequenceNo; // Assuming the sequence string is stored in the 'sequence' property
                } else {
                    return (
                        <Button
                            color="primary"
                            size="sm"
                            onClick={() => handleGenerate(data)}
                        >
                            Generate
                        </Button>
                    );
                }
            },
        },
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: dateTimeSort("createdAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Respondent",
            dataIndex: "respondedByUser",
            key: "respondedByUser",
            sorter: stringSorter("respondedByUser"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
            sorter: stringSorter("country"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Business Unit",
            dataIndex: "businessUnit",
            key: "businessUnit",
            sorter: stringSorter("businessUnit"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Project Client",
            dataIndex: "project",
            key: "project",
            sorter: stringSorter("project"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            sorter: stringSorter("status"),
            sortDirections: ["ascend", "descend"],
            render: (status) => (
                <>
                    {status ? <b>Started</b> : <span>Not Started</span>}
                </>
            )
        },

    ];


    const formData = watch();


    const customStyles = {
        control: (provided) => ({
            ...provided,
            minHeight: "38px",
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,
        }),
        menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999,
        }),
    };

    const filteredQCList = useMemo(() => {
        switch (accessFilter) {
            case 'My':
                return [
                    ...(qcData.user || []),
                    ...(qcData.shared || [])
                ];
            case 'Shared':
                return qcData.shared || [];
            case 'Assigned':
                return qcData.assigned || [];
            default:
                return [];
        }
    }, [qcData, accessFilter]);



    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">Manage Quality Check</h5>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <FormGroup>
                        <Label for="qcSelect">Select QC Form</Label>
                        <Select
                            id="qcSelect"
                            options={qcValues.map(qc => ({ value: qc.route, label: qc.name }))}
                            onChange={handleQCSelect}
                            value={selectedQC}
                            isClearable={true}
                            styles={customStyles}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                        />
                    </FormGroup>
                    {selectedQC && (
                        <>
                            <Row className="align-items-right mb-3">
                                <Col>
                                    <AccessFilter
                                        accessFilter={accessFilter}
                                        handleAccessFilterChange={handleAccessFilterChange}
                                    />
                                </Col>
                                <Col xs="auto">
                                    <Button color="primary" onClick={handleAddNew}>
                                        <PlusCircleOutlined size={18} className="me-2" /> New
                                    </Button>
                                </Col>
                            </Row>
                            <ReactStrapDataTable
                                dataSource={filteredQCList ?? []}
                                columns={columns}
                                rowKey="id"
                            />
                        </>
                    )}
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} size="xl" backdrop={"static"} >
                <ModalHeader toggle={handleCloseModal}>
                    {editingQC ? `Edit ${selectedQC?.label}` : `Add ${selectedQC?.label}`}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <ModalBody>
                        <QCFormComponent
                            signatures={signatures}
                            setSignatures={setSignatures}
                            signatureRefs={signatureRefs}
                            control={control}
                            setValue={setValue}
                            watch={watch}
                            sites={qcFormValues.sites ?? []}
                            l3={qcFormValues.l3 ?? []}
                            respondents={qcFormValues.respondents ?? []}
                            checklists={qcFormValues.checklists ?? []}
                        />
                        <ActionSection
                            actions={actions}
                            onActionsChange={setActions}
                            actionUsers={qcFormValues.actionUsers ?? []}
                        />
                        <AttachmentsSection
                            attachments={attachments}
                            onAttachmentsChange={setAttachments}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {editingQC ? 'Update' : 'Save'}
                        </Button>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
            <Modal
                isOpen={confirmModalOpen}
                toggle={() => setConfirmModalOpen(false)}
            >
                <ModalHeader toggle={() => setConfirmModalOpen(false)}>
                    Confirm Submission
                </ModalHeader>
                <ModalBody>
                    <p>Are you sure you want to submit this QC Form?</p>
                    <p style={{ color: "red" }}>
                        Note: Once the sequence is generated is submitted it cannot be edited.
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setConfirmModalOpen(false)}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={confirmQCGenerate}>
                        Yes, Submit
                    </Button>
                </ModalFooter>
            </Modal>
            <ActionModal
                isOpen={actionModalOpen}
                toggle={toggleActionModal}
                data={actionData}
                message={`Action For ${selectedQC ? selectedQC.label : ""}`}
                onInitiateAction={handleInitiateAction}
            />
            <QCViewSection
                isOpen={viewModalOpen}
                toggle={() => setViewModalOpen(!viewModalOpen)}
                data={selectedViewRecord}
            />
        </div>
    );
};
export default QualityCheck;