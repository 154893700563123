import axios from "../../../axios";
import {
    ADD_BUSINESS_GROUP,
    DELETE_BUSINESS_GROUP,
    GET_BUSINESS_GROUP,
    UPDATE_BUSINESS_GROUP,
    GET_BUSINESS_GROUPS
} from "../../types";
import {setAlert} from "../alert";
import {message} from "antd";

export const getBusinessGroups = () => async (dispatch) => {
    try {
        const res = await axios.get("/business/group/all");
        if (res.data.code === 1) {
            dispatch({type: GET_BUSINESS_GROUPS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const addBusinessGroup = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/business/group/add", formData);
        ;
        if (res.data.code === 1) {
            dispatch({type: ADD_BUSINESS_GROUP, payload: res.data.data});
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const getBusinessGroup = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/business/group/${id}`);
        if (res.data.code === 1) {
            dispatch({type: GET_BUSINESS_GROUP, payload: res.data.data});
        }
    } catch (err) {
        // dispatch(setAlert(err.message, "danger"));
    }
};

export const updateBusinessGroup = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(`/business/group/update`, formData);
        if (res.data.code === 1) {
            dispatch({type: UPDATE_BUSINESS_GROUP, payload: res.data.data});
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        // dispatch(setAlert(err.message, "danger"));
    }
};
export const deleteBusinessGroup = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/business/group/delete/${id}`);
        if (res.data.code === 1) {
            message.success(res.data.message);
            dispatch({type: DELETE_BUSINESS_GROUP, payload: res.data.data});
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
