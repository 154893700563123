import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import Select from "react-select";
import { FilterOutlined } from "@ant-design/icons";
import ReactStrapDataTable from "../../../CustomDatatable/ReactStrapDataTable";
import { stringSorter, formatDateWithTime, numberSort, dateTimeSort } from '../../../../utils/functions';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getIssueActionReportValues } from "../../../../store/actions/Incident/IncidentReport/incidentReportCategory";



const IssueActionReport = () => {
    const dispatch = useDispatch();
    const iacValues = useSelector((state) => state.incidentReportCategory.iacValues);
    const iacFilters = useSelector((state) => state.incidentReportCategory.iacFilters);

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState({
        actionee: [],
        module: [],
        createdBys: [],
        status: [],
        tags: [],
        createdAt: null,
        dateClosed: null,
        dueDate: null,
        isRepeating: [],
        expiryStatus: [],
        countries: [],
        businessUnits: [],
        projects:[],
        projectClients: [],
        sites: [],
        l3ContractorCompany: [],
        recordType: [],
        issueType: [],
        dateOfIssue: null
    });

    const { control, handleSubmit, reset, watch } = useForm();

    useEffect(() => {
        dispatch(getIssueActionReportValues());
    }, [dispatch]);

    useEffect(() => {
        // console.log("IAR VALUES")
        // console.log(iacValues)
    }, [iacValues]);

    let columns = [
        {
            title: "Actionee",
            dataIndex: "actionee",
            key: "actionee",
            sorter: stringSorter("actionee"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Module",
            dataIndex: "module",
            key: "module",
            sorter: stringSorter("module"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Created By",
            dataIndex: "createdBy",
            key: "createdBy",
            sorter: stringSorter("createdBy"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            sorter: stringSorter("status"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Due Date",
            dataIndex: "dueDate",
            key: "dueDate",
            sorter: dateTimeSort("dueDate"),
            sortDirections: ["ascend", "descend"],
        },
        // Add more columns as needed
    ];

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        reset();
    };

    const handleResetFilters = () => {
        reset();
        setSelectedItems({
            actionee: [],
            module: [],
            createdBys: [],
            status: [],
            tags: [],
            createdAt: null,
            dateClosed: null,
            dueDate: null,
            isRepeating: [],
            expiryStatus: [],
            country: [],
            businessUnit: [],
            projects:[],
            projectClient: [],
            site: [],
            office: [],
            l3ContractorCompany: [],
            recordType: [],
            issueType: [],
            dateOfIssue: null
        });
    };

    // const handleItemSelect = (field, selectedOptions) => {
    //     setSelectedItems(prevItems => ({
    //         ...prevItems,
    //         [field]: selectedOptions
    //     }));
    // };
   

    const isFieldEnabled = (field) => {
        switch (field) {
            case 'businessUnits':
                return selectedItems.countries.length > 0;
            case 'projectClients':
                return selectedItems.businessUnits.length > 0;
            case 'projects':
                return selectedItems.projectClients.length > 0;
            case 'sites':
                return selectedItems.projects.length > 0;
            case 'l3':
                return selectedItems.sites.length > 0;
            default:
                return true;
        }
    };

    const handleItemSelect = (field, selectedOptions) => {
        setSelectedItems((prevItems) => {
            const newItems = {
                ...prevItems,
                [field]: selectedOptions,
            };

            // Clear child selections based on parent-child relationships
            switch (field) {
                case 'countries':
                    if (selectedOptions.length === 0) {
                        newItems.businessUnits = [];
                        newItems.projectClients = [];
                        newItems.projects = [];
                        newItems.sites = [];
                        newItems.l3 = [];
                    }
                    break;
                case 'businessUnits':
                    if (selectedOptions.length === 0) {
                        newItems.projectClients = [];
                        newItems.projects = [];
                        newItems.sites = [];
                        newItems.l3 = [];
                    }
                    break;
                case 'projectClients':
                    if (selectedOptions.length === 0) {
                        newItems.projects = [];
                        newItems.sites = [];
                        newItems.l3 = [];
                    }
                    break;
                case 'projects':
                    if (selectedOptions.length === 0) {
                        newItems.sites = [];
                        newItems.l3 = [];
                    }
                    break;
                case 'sites':
                    if (selectedOptions.length === 0) {
                        newItems.l3 = [];
                    }
                    break;
            }

            return newItems;
        });
    };

    const getFilteredStates = () => {
        if (selectedItems.countries.length === 0) {
            return iacValues.states || [];
        }

        return (iacValues.states || []).filter((state) =>
            selectedItems.countries.some((country) => state.coId === country.value)
        );
    };

    const getFilteredBusinessUnits = () => {
        if (selectedItems.countries.length === 0) {
            return iacValues.businessUnits || [];
        }

        return (iacValues.businessUnits || []).filter((bu) =>
            selectedItems.countries.some((country) => bu.coId === country.value)
        );
    };

    const getFilteredProjectClients = () => {
        if (selectedItems.countries.length === 0) {
            return iacValues.projectClients || [];
        }

        return (iacValues.projectClients || []).filter((pc) =>
            pc.coId.some((coId) =>
                selectedItems.countries.some((country) => coId === country.value)
            )
        );
    };

    const getFilteredProjects = () => {
        if (selectedItems.businessUnits.length === 0 && selectedItems.projectClients.length === 0) {
            return iacValues.projects || [];
        }

        return (iacValues.projects || []).filter((project) => {
            const matchesBusinessUnit = selectedItems.businessUnits.length === 0 ||
                selectedItems.businessUnits.some((bu) => bu.value === project.buId);

            const matchesProjectClient = selectedItems.projectClients.length === 0 ||
                selectedItems.projectClients.some((pc) => pc.value === project.pcId);

            return matchesBusinessUnit && matchesProjectClient;
        });
    };

    const getFilteredSites = () => {
        if (selectedItems.projectClients.length === 0) {
            return iacValues.sites || [];
        }

        const relevantProjectIds = (iacValues.projects || [])
            .filter((project) =>
                selectedItems.projectClients.some((pc) => project.pcId === pc.value)
            )
            .map((project) => project.id);

        return (iacValues.sites || []).filter((site) => relevantProjectIds.includes(site.prId));
    };
    const onSubmit = (data) => {
        const payload = {
            actionee: JSON.stringify(selectedItems.actionee.map(item => item.value)),
            createdBys: JSON.stringify(selectedItems.createdBys.map(item => item.value)),
            status: JSON.stringify(selectedItems.status.map(item => item.value)),
            tags: JSON.stringify(selectedItems.tags.map(item => item.value)),
            createdAt: selectedItems.createdAt ? formatDateWithTime(selectedItems.createdAt, false) : null,
            dateClosed: selectedItems.dateClosed ? formatDateWithTime(selectedItems.dateClosed, false) : null,
            dueDate: selectedItems.dueDate ? formatDateWithTime(selectedItems.dueDate, false) : null,
            isRepeating: JSON.stringify(selectedItems.isRepeating.map(item => item.value)),
            expiryStatus: JSON.stringify(selectedItems.expiryStatus.map(item => item.value)),
            countries: JSON.stringify(selectedItems.countries.map(item => item.value)),
            businessUnits: JSON.stringify(selectedItems.businessUnits.map(item => item.value)),
            projectClients: JSON.stringify(selectedItems.projectClients.map(item => item.value)),
            projects: JSON.stringify(selectedItems.projects.map(item => item.value)),
            sites: JSON.stringify(selectedItems.sites.map(item => item.value)),
            l3: JSON.stringify(selectedItems.l3.map(item => item.value)),
            recordType: JSON.stringify(selectedItems.recordType.map(item => item.value)),
            issueType: JSON.stringify(selectedItems.issueType.map(item => item.value)),
            dateOfIssue: selectedItems.dateOfIssue ? formatDateWithTime(selectedItems.dateOfIssue, false) : null,
        };
    
        // TODO: Dispatch action to update issue action report filter
        // dispatch(getIssueActionReportFilter(JSON.stringify(payload)));
    
        setModalOpen(false);
    };

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">Issue Action Report</h5>
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" onClick={handleOpenModal}>
                                <FilterOutlined />
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactStrapDataTable
                        dataSource={iacFilters ?? []}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} size="xl" backdrop="static">
                <ModalHeader toggle={handleCloseModal}>
                    Filter Issue Action Report
                </ModalHeader>
                <ModalBody>
                    <div className="d-flex justify-content-end mb-3">
                        <Button color="secondary" onClick={handleResetFilters}>
                            Reset Filters
                        </Button>
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <h5>Issue Action Filters</h5>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Actionee</Label>
                                    <Select
                                        isMulti
                                        options={(iacValues.actionees || []).map(item => ({
                                            value: item.id,
                                            label: item.name
                                        }))}
                                        value={selectedItems.actionee}
                                        onChange={(options) => handleItemSelect("actionee", options)}
                                        placeholder="Select Actionee..."
                                    />
                                </FormGroup>
                            </Col>
                        
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Created By</Label>
                                    <Select
                                        isMulti
                                        options={(iacValues.createdBys || []).map(person => ({
                                            value: person.id,
                                            label: person.name
                                        }))}
                                        value={selectedItems.createdBys}
                                        onChange={(options) => handleItemSelect("createdBys", options)}
                                        placeholder="Select Creator..."
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Status</Label>
                                    <Select
                                        isMulti
                                        options={(iacValues.statuses || []).map(status => ({
                                            value: status,
                                            label: status
                                        }))}
                                        value={selectedItems.status}
                                        onChange={(options) => handleItemSelect("status", options)}
                                        placeholder="Select Status..."
                                    />
                                </FormGroup>
                            </Col>
                        
                        <Col md={6}>
                        <FormGroup>
                            <Label for="tags">Tags (Maximum 50 tags)</Label>
                            <Select
                                isMulti
                                options={(iacValues.tags || []).map(tag => ({
                                    value: tag.id,
                                    label: tag.name,
                                }))}
                                value={selectedItems.tags}
                                onChange={(options) => handleItemSelect("tags", options)}
                                placeholder="Select Tags..."
                            />
                        </FormGroup>
                        </Col>
                        
                        </Row>
                        <hr/>
                        <h5>Issue Action Date Filters</h5>
                        <hr/>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Created At</Label>
                                    <DatePicker
                                        selected={selectedItems.createdAt}
                                        onChange={(date) => handleItemSelect("createdAt", date)}
                                        className="form-control"
                                        placeholderText="Select date"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Date Closed</Label>
                                    <DatePicker
                                        selected={selectedItems.dateClosed}
                                        onChange={(date) => handleItemSelect("dateClosed", date)}
                                        className="form-control"
                                        placeholderText="Select date"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Due Date</Label>
                                    <DatePicker
                                        selected={selectedItems.dueDate}
                                        onChange={(date) => handleItemSelect("dueDate", date)}
                                        className="form-control"
                                        placeholderText="Select date"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Is Repeating?</Label>
                                    <Select
                                        isMulti
                                        options={[
                                            { value: 'yes', label: 'Yes' },
                                            { value: 'no', label: 'No' }
                                        ]}
                                        value={selectedItems.isRepeating}
                                        onChange={(options) => handleItemSelect("isRepeating", options)}
                                        placeholder="Select..."
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Expiry Status</Label>
                                    <Select
                                        isMulti
                                        options={(iacValues.expiryStatuses || []).map(expiry => ({
                                            value: expiry,
                                            label: expiry,
                                        }))}
                                        value={selectedItems.expiryStatus}
                                        onChange={(options) => handleItemSelect("expiryStatus", options)}
                                        placeholder="Select status..."
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <hr/>
                        <h5>Issue Filters</h5>
                        <hr/>

                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Countries</Label>
                                    <Select
                                        options={(iacValues.countries || []).map((country) => ({
                                            value: country.id,
                                            label: country.name,
                                        }))}
                                        value={selectedItems.countries}
                                        onChange={(options) => handleItemSelect("countries", options)}
                                        placeholder="Select countries..."
                                        isMulti
                                    />
                                </FormGroup>
                            </Col>
                            
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Business Units</Label>
                                    <Select
                                        options={getFilteredBusinessUnits().map((bu) => ({
                                            value: bu.id,
                                            label: bu.name,
                                        }))}
                                        value={selectedItems.businessUnits}
                                        onChange={(options) => handleItemSelect("businessUnits", options)}
                                        placeholder="Select business units..."
                                        isMulti
                                        isDisabled={!isFieldEnabled('businessUnits')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Project Clients</Label>
                                    <Select
                                        options={getFilteredProjectClients().map((pc) => ({
                                            value: pc.id,
                                            label: pc.name,
                                        }))}
                                        value={selectedItems.projectClients}
                                        onChange={(options) => handleItemSelect("projectClients", options)}
                                        placeholder="Select project clients..."
                                        isMulti
                                        isDisabled={!isFieldEnabled('projectClients')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Projects</Label>
                                    <Select
                                        options={getFilteredProjects().map((project) => ({
                                            value: project.id,
                                            label: project.name,
                                        }))}
                                        value={selectedItems.projects}
                                        onChange={(options) => handleItemSelect("projects", options)}
                                        placeholder="Select projects..."
                                        isMulti
                                        isDisabled={!isFieldEnabled('projects')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Sites</Label>
                                    <Select
                                        options={getFilteredSites().map((site) => ({
                                            value: site.id,
                                            label: site.name,
                                        }))}
                                        value={selectedItems.sites}
                                        onChange={(options) => handleItemSelect("sites", options)}
                                        placeholder="Select sites..."
                                        isMulti
                                        isDisabled={!isFieldEnabled('sites')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>L3</Label>
                                    <Select
                                        options={(iacValues.l3 || []).map((item) => ({
                                            value: item.id,
                                            label: item.name,
                                        }))}
                                        value={selectedItems.l3}
                                        onChange={(options) => handleItemSelect("l3", options)}
                                        placeholder="Select L3..."
                                        isMulti
                                        isDisabled={!isFieldEnabled('l3')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                       
                        <Row>
                            
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Record Type</Label>
                                    <Select
                                        isMulti
                                        options={(iacValues.recordTypes || []).map(type => ({
                                            value: type,
                                            label: type
                                        }))}
                                        value={selectedItems.recordType}
                                        onChange={(options) => handleItemSelect("recordType", options)}
                                        placeholder="Select record type..."
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Issue Type</Label>
                                    <Select
                                        isMulti
                                        options={(iacValues.issueTypes || []).map(type => ({
                                            value: type,
                                            label: type
                                        }))}
                                        value={selectedItems.issueType}
                                        onChange={(options) => handleItemSelect("issueType", options)}
                                        placeholder="Select issue type..."
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label>Date of Issue</Label>
                                    <DatePicker
                                        selected={selectedItems.dateOfIssue}
                                        onChange={(date) => handleItemSelect("dateOfIssue", date)}
                                        className="form-control"
                                        placeholderText="Select date of issue"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>


                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit(onSubmit)}>
                        Apply Filters
                    </Button>
                    <Button color="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default IssueActionReport;