import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState, useMemo } from "react";
import { getActions, globalActionObject, submitGlobalAction } from "../../../store/actions/Incident/action";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup, Input,
    Label,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    Row, Table
} from "reactstrap";

import ReactStrapDataTable from "../../CustomDatatable/ReactStrapDataTable";
import { formatDateWithTime, getActionUserType } from "../../../utils/functions";
import { getTags } from "../../../store/actions/tag";
import Select from "react-select";
import { DeleteOutlined } from "@ant-design/icons";
import { GET_ACTIONS } from "../../../store/types";
import { showSuccessDialog, showWarningDialog } from "../../../utils/dialogs";
import { ViewComplaintModel, ViewHazardModal, ViewImprovementOpportunityModal, ViewIncidentModal, ViewNonComformityModel } from "../Views";



const AccessFilter = ({ accessFilter, handleAccessFilterChange, sharedAccess }) => {
    const options = [
        { value: 'My', label: 'My' },
        ...(sharedAccess ? [{ value: 'All', label: 'All' }] : [])
    ];

    return (
        <Row className="mb-3">
            <Col>
                <Label>Data Visibility</Label>
                <Select
                    options={options}
                    value={options.find(option => option.value === accessFilter)}
                    onChange={(selectedOption) => handleAccessFilterChange(selectedOption.value)}
                    placeholder="Select access..."
                />
            </Col>
        </Row>

    );
};

const ModuleFilter = ({ uniqueModules, moduleFilter, handleModuleFilterChange }) => {
    const options = uniqueModules.map(module => ({
        value: module,
        label: module
    }));

    const customStyles = {
        control: (provided) => ({
            ...provided,
            minWidth: 200,
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,
        }),
    };

    return (
        <Row className="mb-3">
            <Col>
                <Label>Select Module</Label>
                <Select
                    options={options}
                    value={options.find(option => option.value === moduleFilter)}
                    onChange={(selectedOption) => handleModuleFilterChange(selectedOption ? selectedOption.value : 'All')}
                    placeholder="Select a module..."
                    isClearable
                    styles={customStyles}
                />
            </Col>
        </Row>
    );
};



const Action = ({ access }) => {
    const dispatch = useDispatch();
    const actions = useSelector((state) => state.action.list);
    const tags = useSelector((state) => state.tag.list);
    const [initiateModalOpen, setInitiateModalOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);


    //View Modal
    const [hazardData, setHazardData] = useState(null);
    const [incidentData, setIncidentData] = useState(null);
    const [complaintData, setComplaintData] = useState(null);
    const [improvementOpportunityData, setImprovementOpportunityData] = useState(null);
    const [nonConformityData, setNonConformityData] = useState(null);

    const [hazardModalOpen, setHazardModalOpen] = useState(false);
    const [incidentModalOpen, setIncidentModalOpen] = useState(false);
    const [complaintModalOpen, setComplaintModalOpen] = useState(false);
    const [improvementOpportunityModalOpen, setImprovementOpportunityModalOpen] = useState(false);
    const [nonConformityModalOpen, setNonConformityModalOpen] = useState(false);








    const [moduleFilter, setModuleFilter] = useState('All');
    const [accessFilter, setAccessFilter] = useState('My');

    const uniqueModules = useMemo(() => {
        const modules = new Set([
            ...(actions?.user?.map(action => action?.module) || []),
            ...(actions?.sharedAccess && actions?.shared?.map(action => action?.module) || [])
        ].filter(Boolean)); // Remove any undefined or null values
        return ['All', ...Array.from(modules)];
    }, [actions]);


    const filteredActions = useMemo(() => {
        const userActions = actions?.user || [];
        const sharedActions = actions?.shared || [];

        if (accessFilter === 'All' && actions?.sharedAccess) {
            return moduleFilter === 'All'
                ? sharedActions
                : sharedActions.filter(action => action?.module === moduleFilter);
        }

        return moduleFilter === 'All'
            ? userActions
            : userActions.filter(action => action?.module === moduleFilter);

    }, [actions, moduleFilter, accessFilter]);


    useEffect(() => {
        dispatch(getActions(access.moduleId))
        dispatch(getTags())
    }, [dispatch])

    const [actionFormData, setActionFormData] = useState({
        submitDate: new Date().toISOString().split('T')[0],
        targetAt: '',
        status: { value: 'open', label: 'Open' },
        description: '',
        tags: [],
        comments: [],
        parentId: '',
        key: '',
        route: '',
    });
    const statusOptions = [
        { value: 'OPEN', label: 'Open' },
        { value: 'CLOSED', label: 'Close' }
    ];
    const resetForm = () => {
        setActionFormData({
            submitDate: new Date().toISOString().split('T')[0],
            targetAt: '',
            status: { value: 'open', label: 'Open' },
            description: '',
            tags: [],
            comments: []
        });
    };

    const handleActionFormChange = (e) => {
        const { name, value } = e.target;
        setActionFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSelectChange = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        setActionFormData(prevState => ({
            ...prevState,
            [name]: name === 'status' ? selectedOption.value : selectedOption
        }));
    };
    const addCommentRow = () => {
        setActionFormData(prevState => ({
            ...prevState,
            comments: [...prevState.comments, {
                id: null,
                description: '',
                createdBy: null,
                updatedBy: null,
                createdAt: null,
                updatedAt: null
            }]
        }));
    };
    const removeCommentRow = (index) => {
        setActionFormData(prevState => ({
            ...prevState,
            comments: prevState.comments.filter((_, i) => i !== index)
        }));
    };
    const handleCommentChange = (index, value) => {
        setActionFormData(prevState => ({
            ...prevState,
            comments: prevState.comments.map((comment, i) =>
                i === index ? { ...comment, description: value } : comment
            )
        }));
    };
    const handleInitiateSubmit = async (e) => {
        e.preventDefault();
        const actionData = {
            ...actionFormData,
            submitDate: new Date().toISOString().split('T')[0],
            status: actionFormData.status || 'PENDING',
            tags: Array.isArray(actionFormData.tags) && actionFormData.tags.length > 0
                ? actionFormData.tags
                    .filter(tag => tag && typeof tag.value !== 'undefined')
                    .map(tag => parseInt(tag.value))
                : []
        };
        delete actionData.submitDate
        if (actionData.message) {
            actionData.reply = actionData.message;
            delete actionData.message;
        }

        const route = actionData.route;


        let payload = {
            [actionData.key]: actionData.parentId,
            id: actionData.id,
        }


        delete actionData.key;
        delete actionData.parentId
        delete actionData.route


        if (actionData.status && actionData.status.hasOwnProperty('value')) {
            actionData.status = actionData.status.value;
        }

        payload.actions = JSON.stringify(actionData)

        const response = await dispatch(submitGlobalAction(route, payload))
        if (response.success) {
            showSuccessDialog(response.message)
            dispatch({ type: GET_ACTIONS, payload: [] });
            await dispatch(getActions(access.moduleId))
        } else {
            showWarningDialog(response.message)
        }
        toggleInitiateModal();
    };

    useEffect(() => {
        dispatch(getTags());
    }, [dispatch]);




    const toggleInitiateModal = (record = null, parentId, key, route) => {
        // console.log('Toggle Initiate Modal called with:', record);
        setInitiateModalOpen(prevState => !prevState);

        if (record) {
            setSelectedAction(record);
            const action = record.action;
            console.log(action);

            if (action) {
                const selectedTags = action.tags
                    ? action.tags.map(tagId => {
                        const matchingTag = tags.find(tag => tag.id.toString() === tagId.toString());
                        return matchingTag
                            ? { value: matchingTag.id.toString(), label: matchingTag.name }
                            : null;
                    }).filter(tag => tag !== null)
                    : [];

                setActionFormData({
                    submittedAt: action.submittedAt || '',
                    submitDate: formatDateWithTime(action.submittedAt, false) || new Date().toISOString().split('T')[0],
                    targetAt: formatDateWithTime(action.targetAt, false) || '',
                    description: action.description || '',
                    reply: action.reply || '',
                    tags: selectedTags,
                    comments: action.comments || [],
                    type: action.type || '',
                    targetDays: action.targetDays || '',
                    status: { value: action.status || 'OPEN', label: (action.status === 'OPEN' ? 'Open' : 'Close') || 'Open' },
                    id: action.id || '',
                    userId: action.userId || '',
                    createdBy: action.createdBy || '',
                    updatedBy: action.updatedBy || '',
                    createdAt: action.createdAt || '',
                    updatedAt: action.updatedAt || '',
                    parentId: parentId,
                    key: key,
                    route: route
                });
            }
        } else {
            setSelectedAction(null);
            resetForm();
        }
    };


    const handleModuleFilterChange = (selectedModule) => {
        setModuleFilter(selectedModule || 'All');
    };

    const handleAccessFilterChange = (selectedAccess) => {
        setAccessFilter(selectedAccess);
    };


    const getModuleObject = async (record = null, parentId, key, route) => {
        
        
        let object = await dispatch(globalActionObject(route, parentId))
        object=object.payload

        const routeLower = route.toLowerCase();
        console.log(routeLower)


        switch (routeLower) {
            case 'hazard':
                setHazardData(object);
                setHazardModalOpen(true);
                break;
            case 'incident/report':
                setIncidentData(object);
                setIncidentModalOpen(true);
                break;
            case 'complaint':
                setComplaintData(object);
                setComplaintModalOpen(true);
                break;
            case 'improvement':
                setImprovementOpportunityData(object);
                setImprovementOpportunityModalOpen(true);
                break;
            case 'nonconformity':
                setNonConformityData(object);
                setNonConformityModalOpen(true);
                break;
            default:
                console.error('Unknown route:', route);
        }
    }

    let columns = [
        {
            title: "Status",
            dataIndex: ["action", "parentId", "key", "route"],
            key: "status",
            render: (_, record, index) => {
                const status = record.action?.status;
                return (
                    <Button
                        color="primary"
                        size="sm"
                        onClick={() => toggleInitiateModal(record, record.parentId, record.key, record.route)}
                        disabled={accessFilter === 'All'}
                    >
                        {typeof status === 'object' ? status.label : status || 'N/A'}
                    </Button>
                );
            }
        },
        {
            title: "Module",
            dataIndex: "module",
            key: "module",
        },
        {
            title: "Action Type",
            dataIndex: "action",
            key: "actionType",
            render: (action) => getActionUserType(action?.type || "-")
        },
        {
            title: "Sequence No.",
            dataIndex: "sequenceNo",
            key: "sequenceNo",
            render: (text, record) => (
                <Button
                    color="primary"
                    size="sm"
                    onClick={() => {
                        getModuleObject(record, record.parentId, record.key, record.route);
                    }}

                >
                    {text || 'N/A'}
                </Button>
            )
        },
        {
            title: "Submit Date",
            dataIndex: "action",
            key: "submittedAt",
            render: (action) => action?.submittedAt || "-"
        },
        {
            title: "From",
            dataIndex: "action",
            key: "from",
            render: (action) => action?.createdByUser || "-"
        },
        {
            title: "Message Description",
            dataIndex: "action",
            key: "messageDescription",
            render: (action) => action?.description || "-"
        },
        {
            title: "To",
            dataIndex: "action",
            key: "to",
            render: (action) => action?.createdFor || "-"
        },
        {
            title: "Response Message",
            dataIndex: "module",
            key: "responseMessage",
            render: (action) => action?.reply || "-"
        },
        {
            title: "Response On",
            dataIndex: "action",
            key: "responseOn",
            render: (action) => action?.responseAt || "-"
        },
        {
            title: "Response By",
            dataIndex: "action",
            key: "responseBy",
            render: (action) => action?.responseByUser || "-"
        }
    ];
    return (
        <>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">Manage Actions</h5>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    {/* <Row className="mb-3">
                    <Col>
                        <div className="d-flex flex-wrap">
                            {uniqueModules.map(module => (
                                <Button
                                    key={module}
                                    color={moduleFilter === module ? "primary" : "secondary"}
                                    onClick={() => handleModuleFilterChange(module)}
                                    className="me-2 mb-2"
                                >
                                    {module}
                                </Button>
                            ))}
                        </div>
                    </Col>
                </Row> */}
                    <Row>
                        <Col md={6}>
                            <ModuleFilter
                                uniqueModules={uniqueModules}
                                moduleFilter={moduleFilter}
                                handleModuleFilterChange={handleModuleFilterChange}
                            />
                        </Col>
                        <Col md={6}>
                            <AccessFilter
                                accessFilter={accessFilter}
                                handleAccessFilterChange={handleAccessFilterChange}
                                sharedAccess={actions.sharedAccess}
                            />
                        </Col>
                    </Row>
                    <ReactStrapDataTable
                        dataSource={filteredActions}
                        columns={columns}
                        rowKey={(record) => `${record.module}-${record.id}-${record.action?.id || 'noAction'}`}
                    />
                </CardBody>
            </Card>
            <Modal isOpen={initiateModalOpen} toggle={toggleInitiateModal} size="lg">
                <Form onSubmit={handleInitiateSubmit}>
                    <ModalHeader toggle={toggleInitiateModal}>
                        {selectedAction ? 'Edit Action' : 'Initiate New Action'}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="submitDate">Submit Date</Label>
                                    <Input
                                        type="date"
                                        name="submitDate"
                                        id="submitDate"
                                        value={actionFormData.submitDate}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="targetAt">Target Date</Label>
                                    <Input
                                        type="date"
                                        name="targetAt"
                                        id="targetAt"
                                        value={actionFormData.targetAt}
                                        onChange={handleActionFormChange}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="status">Status</Label>
                                    <Select
                                        name="status"
                                        options={statusOptions}
                                        value={statusOptions.find(option => option.value === actionFormData.status) || null}
                                        onChange={handleSelectChange}
                                    // isDisabled={actionFormData.status === 'PENDING'}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <Label for="description">Description</Label>
                            <Input
                                type="textarea"
                                name="description"
                                id="description"
                                value={actionFormData.description}
                                onChange={handleActionFormChange}
                                disabled
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="message">Response Message</Label>
                            <Input
                                type="textarea"
                                name="message"
                                id="message"
                                value={actionFormData.message}
                                onChange={handleActionFormChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="tags">Tags</Label>
                            <Select
                                isMulti
                                name="tags"
                                options={tags?.map(tag => ({
                                    value: tag.id.toString(),
                                    label: tag.name
                                })) || []}
                                value={actionFormData.tags}
                                onChange={handleSelectChange}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Comments</Label>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {actionFormData.comments.map((comment, index) => (
                                        <tr key={index}>
                                            <td>
                                                <Input
                                                    type="textarea"
                                                    value={comment.description}
                                                    onChange={(e) => handleCommentChange(index, e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                <Button color="danger"
                                                    onClick={() => removeCommentRow(index)}><DeleteOutlined /></Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Button color="primary" onClick={addCommentRow}>Add Comment</Button>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleInitiateModal}>Cancel</Button>
                        <Button color="primary" type="submit">Submit</Button>
                    </ModalFooter>
                </Form>
            </Modal>
            <ViewHazardModal
                isOpen={hazardModalOpen}
                toggle={() => setHazardModalOpen(false)}
                hazard={hazardData}
            />
            <ViewIncidentModal
                isOpen={incidentModalOpen}
                toggle={() => setIncidentModalOpen(false)}
                incident={incidentData}
            />
            <ViewComplaintModel
                isOpen={complaintModalOpen}
                toggle={() => setComplaintModalOpen(false)}
                hazard={complaintData}
            />
            <ViewImprovementOpportunityModal
                isOpen={improvementOpportunityModalOpen}
                toggle={() => setImprovementOpportunityModalOpen(false)}
                improvementOpportunity={improvementOpportunityData}
            />
            <ViewNonComformityModel
                isOpen={nonConformityModalOpen}
                toggle={() => setNonConformityModalOpen(false)}
                hazard={nonConformityData}
            />
        </>
    )
}

export default Action