import {GET_QC_DATA, GET_QCFORMVALUES, GET_QCVALUES} from "../../../types"
const initialState = { list: [], single: null, userL2PM: [],qcValues:[],qcFormValues:[],qcData:{}};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_QCVALUES:
            return { ...state, qcValues: payload }
        case GET_QCFORMVALUES:
            return { ...state, qcFormValues: payload }
        case GET_QC_DATA:
                return { ...state, qcData: payload }
        default:
            return state;
    }
};

export default reducer;