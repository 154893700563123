import axios from "../../../axios";
import {
    GET_NONCONFORMITIES,
    GET_USER_NONCONFORMITIES,
    GET_SINGLE_NONCONFORMITY,
    ADD_NONCONFORMITY,
    UPDATE_NONCONFORMITY,
    DELETE_NONCONFORMITY,
    SUBMIT_NONCONFORMITY,
    SUBMIT_ACTION_FOR_NONCONFORMITY
} from "../../types";
import { setAlert } from "../alert";
import { formDataToObject } from "../../../utils/functions";

export const getNonconformityValues = () => async (dispatch) => {
    try {
        const res = await axios.get("/nonconformity/values");
        if (res.data.code === 1) {
            dispatch({ type: GET_NONCONFORMITIES, payload: res.data.data });
            return { message: "Nonconformity values fetched successfully", success: true };
        }
        return { message: "Failed to fetch nonconformity values", success: false };
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return { message: err.message, success: false };
    }
};

export const getUserNonconformities = (acccessModuleId) => async (dispatch) => {
    try {
        
        const object={
            moduleId:parseInt(acccessModuleId)
        }

        const res = await axios.post("/nonconformity/user",object);
        if (res.data.code === 1) {
            dispatch({ type: GET_USER_NONCONFORMITIES, payload: res.data.data });
            return { message: "User nonconformities fetched successfully", success: true };
        }
        return { message: "Failed to fetch user nonconformities", success: false };
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return { message: err.message, success: false };
    }
};

export const getUserSingleNonconformity = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/nonconformity/${id}`);
        if (res.data.code === 1) {
            dispatch({ type: GET_SINGLE_NONCONFORMITY, payload: res.data.data });
            return { message: "Single nonconformity fetched successfully", success: true, data: res.data.data };
        }
        return { message: "Failed to fetch single nonconformity", success: false };
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return { message: err.message, success: false };
    }
};

export const addNonconformityReport = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/nonconformity/add", formData, config);
        if (res.data.code === 1) {
            dispatch({ type: ADD_NONCONFORMITY, payload: res.data.data });
            return { message: res.data.message, success: true };
        } else {
            return { message: res.data.message, success: false };
        }
    } catch (err) {
        console.error("Error in addNonconformityReport:", err);
        return { message: err.response?.data?.message || "An error occurred", success: false };
    }
};

export const updateNonconformityReport = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/nonconformity/update", formData, config);
        if (res.data.code === 1) {
            dispatch({ type: UPDATE_NONCONFORMITY, payload: res.data.data });
            return { message: res.data.message, success: true };
        } else {
            return { message: res.data.message, success: false };
        }
    } catch (err) {
        console.error("Error in updateNonconformityReport:", err);
        return { message: err.response?.data?.message || "An error occurred", success: false };
    }
};

export const deleteNonconformity = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/nonconformity/delete/${id}`);
        if (res.data.code === 1) {
            dispatch({ type: DELETE_NONCONFORMITY, payload: id });
            return { message: res.data.message, success: true };
        } else {
            return { message: res.data.message, success: false };
        }
    } catch (err) {
        console.error("Error in deleteNonconformity:", err);
        return { message: err.response?.data?.message || "An error occurred", success: false };
    }
};

export const submitNonconformity = (object) => async (dispatch) => {
    try {
        const res = await axios.post(`/nonconformity/submit/`,object);
        console.log("SUBMIT NONCONFIRMITY RESPONSE")
        console.log(res.data)
        if (res.data.code === 1) {
            console.log("INSIDE IF")
            dispatch({ type: SUBMIT_NONCONFORMITY, payload: res.data.data });
            return { message: "Nonconformity submitted successfully", success: true, data: res.data.data };
        } else {
            console.log("INSIDE ELSE")
            dispatch(setAlert(res.data.message, "danger"));
            return { message: res.data.message, success: false };
        }
    } catch (err) {
        console.log("INSIDE ERR")
        console.log(err)
        // dispatch(setAlert(err.message, "danger"));
        // return { message: err.message, success: false };
    }
};

export const submitActionForNonconformity = (payload) => async (dispatch) => {
    try {
        const res = await axios.post("/nonconformity/action/update", payload);
        if (res.data.code === 1) {
            dispatch({ type: SUBMIT_ACTION_FOR_NONCONFORMITY, payload: res.data.data });
            return { message: "Action updated successfully", success: true };
        } else {
            return { message: res.data.message, success: false };
        }
    } catch (err) {
        console.error("Error in submitActionForNonconformity:", err);
        return { message: err.response?.data?.message || "An error occurred", success: false };
    }
};