import axios from "../../../axios";
import { ADD_TEMPLATE, GET_TEMPLATE, GET_TEMPLATES, UPDATE_TEMPLATE, DELETE_TEMPLATE, GET_TEMPLATE_VALUES } from "../../types";
import { setAlert } from "../alert";
import { message } from "antd";



// Add Template
export const addTemplate = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        const res = await axios.post("template/add", formData, config);
        if (res.data.code === 1) {
            dispatch({ type: ADD_TEMPLATE, payload: res.data.data });
            message.success(res.data.message);
            return true;
        } else {
            dispatch(setAlert(res.data.message, "warning"));
            return false;
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

//Template Values
export const getTemplateValues = () => async (dispatch) => {
    const res = await axios.get(`template/values`);
    if (res.data.code === 1) {
        dispatch({ type: GET_TEMPLATE_VALUES, payload: res.data.data });
        message.success(res.data.message);
    } else {
        message.warning(res.data.message);
    }

}

export const updateTemplateStatus = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("template/status", formData);
        if (res.data.code === 1) {
            dispatch({ type: UPDATE_TEMPLATE, payload: res.data.data });
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
};


export const getTemplates = () => async (dispatch) => {
    try {
        const res = await axios.get("template/all");
        if (res.data.code === 1) {
            dispatch({ type: GET_TEMPLATES, payload: res.data.data });
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

// Get Single TEMPLATE
export const getTemplate = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`template/${id}`);
        if (res.data.code === 1) {
            dispatch({ type: GET_TEMPLATE, payload: res.data.data });

        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
};

// Clear Single TEMPLATE
export const clearSingleTemplate = () => async (dispatch) => {
    try {
        dispatch({ type: GET_TEMPLATE, payload: '' });
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
};

// Update TEMPLATE
export const updateTemplate = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        const res = await axios.post("template/update", formData, config);
        if (res.data.code === 1) {
            dispatch({ type: UPDATE_TEMPLATE, payload: res.data.data });
            message.success(res.data.message);
            return true;

        } else {
            return false;
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
};

// Delete TEMPLATE
export const deleteTemplate = (id) => async (dispatch) => {
    try {
        if (!id) {
            dispatch(setAlert('Please select any record', 'warning'));
            return false;
        }
        const res = await axios.get(`template/delete/${id}`);
        if (res.data.code === 1) {
            dispatch({ type: DELETE_TEMPLATE, payload: { id } });
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
};



