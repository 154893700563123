import React, { useRef, useEffect, useState } from 'react';
import {
    Row, Col,Label,Button
} from 'reactstrap';
import { User, Share2, Clipboard } from 'lucide-react';

import ReactStrapDataTable from '../Pages/CustomDatatable/ReactStrapDataTable';


export const dateSorter = (columnName) => (a, b) => {
    if (a[columnName] === null || b[columnName] === null) {
        return 0; // Treat nulls as equal
    }
    const dateA = new Date(a[columnName]);
    const dateB = new Date(b[columnName]);
    return dateA - dateB;
};
export const stringSorter = (columnName) => (a, b) => {
    if (a[columnName] === null || b[columnName] === null) {
        return 0; // Treat nulls as equal
    }
    return a[columnName].localeCompare(b[columnName]);
};
export const numberSort = (columnName) => (a, b) => {
    const valueA = getNumberValue(a, columnName);
    const valueB = getNumberValue(b, columnName);

    return valueA - valueB;
};
const getNumberValue = (obj, columnName) => {
    const value = obj[columnName];
    if (typeof value === 'number') {
        return value;
    } else if (typeof value === 'string') {
        const parsedValue = parseFloat(value.replace(/[^0-9.-]+/g, ''));
        return isNaN(parsedValue) ? 0 : parsedValue;
    }
    return 0;
};
export const dateTimeSort = (columnName) => (a, b) => {
    if (a[columnName] === null || b[columnName] === null) {
        return 0; // Treat nulls as equal
    }

    if (a[columnName] !== null && b[columnName] !== null) {
        const dateA = new Date(a[columnName]);
        const dateB = new Date(b[columnName]);

        return dateA - dateB;
    }
};
export const firstDayOfMonth = () => {
    const date = new Date();
    date.setDate(1);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = '01'; // The first day of the month
    return `${year}-${month}-${day}`;
};
export function formatDate(dateString) {
    // Check if dateString is null or undefined
    if (dateString === null || dateString === undefined) {
        return ''; // Return an empty string or any other default value
    }

    // Check if dateString contains time information
    const timeRegex = /\d{2}:\d{2}:\d{2}/;
    const hasTime = timeRegex.test(dateString);

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');

    if (hasTime) {
        const hour = date.getHours().toString().padStart(2, '0');
        const minute = date.getMinutes().toString().padStart(2, '0');
        const second = date.getSeconds().toString().padStart(2, '0');
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    } else {
        return `${year}-${month}-${day}`;
    }
}
export function formatDateWithTime(dateString,wantTime) {
    // Check if dateString is null or undefined
    if (dateString === null || dateString === undefined) {
        return ''; // Return an empty string or any other default value
    }

    // Check if dateString contains time information
    const timeRegex = /\d{2}:\d{2}:\d{2}/;
    const hasTime = timeRegex.test(dateString);

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');

    if (hasTime && wantTime) {
        const hour = date.getHours().toString().padStart(2, '0');
        const minute = date.getMinutes().toString().padStart(2, '0');
        const second = date.getSeconds().toString().padStart(2, '0');
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    } else {
        return `${year}-${month}-${day}`;
    }
}
export const formDataToObject = (formData) => {
    const object = {};
    formData.forEach((value, key) => {
        // Attempt to parse JSON strings
        try {
            object[key] = JSON.parse(value);
        } catch (e) {
            object[key] = value;
        }
    });
    return object;
};
export const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
};


export function getActionUserType(type){
    return type==="C" ? "Closeout Manager" : type==="R" ? "Responsible Manager" : type==="S" ? "Supervisor" : "User"
}

export const returnRoute = (route) => {
    let finalRoute = "";
    switch (route) {
        case "hazards":
            finalRoute = "hazard";
            break;
        case "incidentReport":
            finalRoute = "incidentreport";
            break;
        case "nonConformities":
            finalRoute = "nonconformity";
            break;
        case "improvementOpportunities":
            finalRoute = "improvement";
            break;
        case "complaints":
            finalRoute = "complaint";
            break;
        default:
            finalRoute = "incident";
    }
    return finalRoute;
};
export const returnInformRoute = (route) => {
    let finalRoute = "";
    switch (route) {
        case "informGsps":
            finalRoute = "gsp";
            break;
        case "informLod1s":
            finalRoute = "lod1";
            break;
        case "informRhpws":
            finalRoute = "rhpw";
            break;
        case "informRpmws":
            finalRoute = "rpmw";
            break;
        case "informTbts":
            finalRoute = "tbt";
            break;

        case "informTnts":
            finalRoute = "tnt";
            break;
        default:
            finalRoute = "inform";
    }
    return finalRoute;
};


// export const AccessFilter = ({ accessFilter, handleAccessFilterChange, sharedAccess }) => {
//     const options = [
//         { value: 'My', label: 'My' },
//         ...(sharedAccess ? [{ value: 'All', label: 'Shared' }] : [])
//     ];

//     return (
//         <Row className="mb-3">
//             <Col>   
//                 <Select
//                     options={options}
//                     value={options.find(option => option.value === accessFilter)}
//                     onChange={(selectedOption) => handleAccessFilterChange(selectedOption.value)}
//                     placeholder="Select access..."
//                 />
//             </Col>
//         </Row>

//     );
// };

export const AccessFilter = ({ accessFilter, handleAccessFilterChange }) => {
    const options = [
      { value: 'My', icon: User },
      { value: 'Shared', icon: Share2 },
      { value: 'Assigned', icon: Clipboard }
    ];
  
    return (
      <div className="d-flex">
        {options.map(({ value, icon: Icon }) => (
          <Button
            key={value}
            color={accessFilter === value ? 'primary' : 'secondary'}
            onClick={() => handleAccessFilterChange(value)}
            className="me-2 d-flex align-items-center"
          >
            <Icon size={18} className="me-2" />
            {value}
          </Button>
        ))}
      </div>
    );
  };




export const ResponsiveTable = ({ data, columns }) => {
  const tableRef = useRef(null);
  const [scrollWidth, setScrollWidth] = useState('100%');

  const wrapperStyle = {
    width: '100%',
    overflowX: 'auto'
  };

  const scrollStyle = {
    width: '300%'
  };
  useEffect(() => {
    const updateScrollWidth = () => {
      if (tableRef.current) {
        const tableWidth = tableRef.current.getBoundingClientRect().width;
        const containerWidth = tableRef.current.parentElement.getBoundingClientRect().width;
        if (tableWidth > containerWidth) {
          setScrollWidth(`${(tableWidth / containerWidth) * 100}%`);
        } else {
          setScrollWidth('100%');
        }
      }
    };

    updateScrollWidth();
    window.addEventListener('resize', updateScrollWidth);

    return () => {
      window.removeEventListener('resize', updateScrollWidth);
    };
  }, [data, columns]);

  return (
    <div style={wrapperStyle}>
      <div style={scrollStyle}>
        <div ref={tableRef}>
          <ReactStrapDataTable
            dataSource={data ?? []}
            columns={columns}
            rowKey="id"
            scroll={{ x: true }}
          />
        </div>
      </div>
    </div>
  );
};


export const manageCURDAccess = (access) => {
    const result = {};
    for (const [key, value] of Object.entries(access)) {
        result[key] = value === 1;
    }
    return result;
};

export const isImageFile = (fileName) => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
    const extension = fileName.split('.').pop().toLowerCase();
    return imageExtensions.includes(extension);
};

export const getCurrentDateString = () => {
    return new Date().toISOString().split('T')[0];
};
export const renderAttachment = (file, index) => {
    const renderValue = (value) => {
        if (value === undefined || value === null) return 'N/A';
        if (typeof value === 'boolean') return value ? '✅' : '❌';
        return value;
    };
    if (isImageFile(file.fileName)) {
        return (
            <div key={index} className="mb-2">
                <p>Description: {renderValue(file.description)}</p>
                <img
                    src={file.fullPath}
                    alt={file.fileName}
                    style={{width: '50%', height: 'auto'}}
                    className="max-w-full h-auto mb-2"
                    crossOrigin="anonymous"
                />
                <hr/>
            </div>
        );
    } else {
        return (
            <div key={index} className="mb-2">
                <a href={file.fullPath} target="_blank" rel="noopener noreferrer">
                    {file.fileName}
                </a>
                <p>Description: {renderValue(file.description)}</p>
            </div>
        );
    }
};