import axios from "../../../../axios";
import {setAlert} from "../../alert";
import {message} from "antd";
import {ADD_L2PM, GENERATE_L2PM, GET_SINGLE_L2PM, GET_USER_L2PM, L2PM_VALUES, UPDATE_L2PM,DELETE_L2PM} from "../../../types";

export const getL2PMValues = () => async (dispatch) => {
    try {
        const res = await axios.get("/inform/rpmw/values");
        if (res.data.code === 1) {
            dispatch({type: L2PM_VALUES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};


export const addL2PMForm = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/inform/rpmw/add", formData, config);
        
        if (res.data.code === 1) {
            dispatch({type: ADD_L2PM, payload: res.data.data});
            return {success: true, message: res.data.message};
        } else {

            return {success: false, message: res.data.message};
        }
    } catch (err) {
        console.error("Error in addHazardForm:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
};
export const updateL2PMForm = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/inform/rpmw/update", formData, config);
        
        if (res.data.code === 1) {
            dispatch({type: UPDATE_L2PM, payload: res.data.data});
            return {success: true, message: res.data.message};
        } else {
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        console.error("Error in addHazardForm:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
};

export const deleteL2PM = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/inform/rpmw/delete/${id}`);
        if (res.data.code === 1) {
            dispatch({type: DELETE_L2PM, payload: id});
            return {success:true,message:res.data.message}
        }
        
    } catch (err) {
        return {result:false,message:'Contact to Admin'}
        dispatch(setAlert(err.message, "danger"));
    }
};

export const getUserL2PM = (accessModuleId) => async (dispatch) => {
    try {
        const object={
            moduleId:accessModuleId
        }
        const res = await axios.post("/inform/rpmw/user",object);
        
        if (res.data.code === 1) {
            dispatch({type: GET_USER_L2PM, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const getSingleL2PM=(id)=> async (dispatch) => {
    try {
        const res = await axios.get(`/inform/rpmw/${id}`);
        
        if (res.data.code === 1) {
            dispatch({type: GET_SINGLE_L2PM, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const submitL2PMToGenerate = (object) => async (dispatch) => {
    try {
        const res = await axios.post(`/inform/rpmw/submit/`,object);
        if (res.data.code === 1) {
            dispatch({type: GENERATE_L2PM, payload: res.data.data});
        }
        return res
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const submitActionForL2PM = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/inform/rpmw/action/update", formData);
        if (res.data.code === 1) {
            message.success(res.data.message);
            //dispatch({type: GET_USER_HAZARD, payload: res.data.data});
            return {success: true, message: res.data.message};
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        console.error("Error in addHazardForm:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
}


