import {
    GET_INCIDENTS,
    GET_USER_INCIDENT,
    GET_SINGLE_INCIDENT,
    UPDATE_INCIDENT,
    SUBMIT_INCIDENT,
    SUBMIT_ACTION_FOR_INCIDENT,
    DELETE_INCIDENT
} from "../../types";

const initialState = {
    list: [],
    single: null,
    userIncident: {}
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_INCIDENTS:
            return { ...state, list: payload };

        case GET_USER_INCIDENT:
            return { ...state, userIncident: payload };

        case GET_SINGLE_INCIDENT:
            return { ...state, single: payload };

        case UPDATE_INCIDENT:
            return {
                ...state,
                userIncident: payload,
            };

        case SUBMIT_INCIDENT:
            return {
                ...state,
                userIncident: payload
            };

        case SUBMIT_ACTION_FOR_INCIDENT:
            return {
                ...state,
                userIncident: state.userIncident.map(incident =>
                    incident.id === payload.incidentId
                        ? { ...incident, actions: [...incident.actions, payload.action] }
                        : incident
                ),
                single: state.single && state.single.id === payload.incidentId
                    ? { ...state.single, actions: [...state.single.actions, payload.action] }
                    : state.single
            };
            case DELETE_INCIDENT:
                return {
                    ...state,
                    userIncident: state.userIncident.user.filter(object => object.id !== payload)
                };

        default:
            return state;
    }
};

export default reducer;