import axios from '../../axios';
import {setAlert} from "./alert";
import {ADD_SALESMAN, DELETE_SALESMAN, GET_SALESMAN, GET_SALESMANS, UPDATE_SALESMAN} from "../types";
import {message} from "antd";
import {propTypes as res} from "react-csv/src/metaProps";

export const getSalesmans = () => async (dispatch) => {
    try {
        const res = await axios.get("/salesman/all");
        if (res.data.code === 1) {
            dispatch({type: GET_SALESMANS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const getSalesman = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/salesman/${id}`);
        if (res.data.code === 1) {
            dispatch({type: GET_SALESMAN, payload: res.data.data});
        }
    } catch (err) {
        // dispatch(setAlert(err.message, "danger"));
    }
};

export const addSalesman = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/salesman/add", formData);
        if (res.data.code === 1) {
            dispatch({type: ADD_SALESMAN, payload: res.data.data});
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const updateSalesMan = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(`/salesman/update`, formData);
        if (res.data.code === 1) {
            dispatch({type: UPDATE_SALESMAN, payload: res.data.data});
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        message.warning(res.data.message);
    }
};
export const deleteSalesman = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/salesman/delete/${id}`);
        if (res.data.code === 1) {
            message.success(res.data.message);
            dispatch({type: DELETE_SALESMAN, payload: res.data.data});
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        message.warning(res.data.message);
    }
};
