import axios from "../../../../axios";
import {ADD_TOPIC, DELETE_TOPIC, GET_TOPICS, GET_TOPIC, UPDATE_TOPIC} from "../../../types";
import {setAlert} from "../../alert";
import {message} from "antd";

export const getTopics = () => async (dispatch) => {
    try {
        const res = await axios.get("/topic/all");

        if (res.data.code === 1) {
            dispatch({type: GET_TOPICS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const getTopicsByChapterId = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/topic/chapter/${id}`);
        
        if (res.data.code === 1) {
            dispatch({type: GET_TOPICS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const getTopicsByCourseId = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/topic/course/${id}`);
        if (res.data.code === 1) {
            dispatch({type: GET_TOPICS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};


export const addTopic = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/topic/add", formData,config);
        ;
        if (res.data.code === 1) {
            dispatch({type: ADD_TOPIC, payload: res.data.data});
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const uploadFileForTopic = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        await axios.post("/topic/file/upload", formData,config);
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const getTopic = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/topic/${id}`);
        if (res.data.code === 1) {
            dispatch({type: GET_TOPIC, payload: res.data.data});
        }
    } catch (err) {
        // dispatch(setAlert(err.message, "danger"));
    }
};

export const updateTopic = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(`/topic/update`, formData);
        ;
        if (res.data.code === 1) {
            dispatch({type: UPDATE_TOPIC, payload: res.data.data});
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        // dispatch(setAlert(err.message, "danger"));
    }
};
export const deleteTopic = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/topic/delete/${id}`);
        if (res.data.code === 1) {
            message.success(res.data.message);
            dispatch({type: DELETE_TOPIC, payload: res.data.data});
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
