import {combineReducers} from "redux";
// Front
import Layout from "./layout/reducer";
// Calendar
import calendar from "./calendar/reducer";
// Authentication
import forgetPassword from "./auth/forgetpwd/reducer";
import login from "./auth/login/reducer";
import profile from "./auth/profile/reducer";
import account from "./auth/register/reducer";

//HSSE
import item from "./reducers/item";
import salesman from "./reducers/salesman";
import visit from "./reducers/visit";
import roles from "./reducers/roles";
import organizations from "./reducers/organization";
import navigation from "./reducers/navigation";
import hazard from "./reducers/Incident/hazard";
import incidentreport from "./reducers/Incident/incidentreport"
import users from "./reducers/users";
import country from "./reducers/region/country";
import sysroles from "./reducers/sysroles";
import tag from "./reducers/tag";
import nonconformity from "./reducers/Incident/nonconformity";
import improvementOpportunity from "./reducers/Incident/improvementoppourtionity";
import complaint from "./reducers/Incident/complaint";
import master from "./reducers/master";
import goseeprogram from "./reducers/inform/Audit/goseeprogram";
import tbt from "./reducers/inform/Audit/tbt";
import tnt from "./reducers/inform/Audit/tnt";
import L2PM from "./reducers/inform/HSSEReport/L2PM";
import RHPW from "./reducers/inform/HSSEReport/RHPW";
import hsseaudit from "./reducers/inform/Audit/hsseaudit";
import ldo1 from "./reducers/inform/Audit/ldo1";
import action from "./reducers/Incident/action";
import state from "./reducers/region/state";
import city from "./reducers/region/city";
import businessgroup from "./reducers/business/businessgroup";
import businessunit from "./reducers/business/businessunit";
import projectclient from "./reducers/business/projectclient";
import project from "./reducers/business/project";
import site from "./reducers/business/site";
import course from "./reducers/competancy/course";
import competencyFilter from "./reducers/competancy/report";
import chapter from "./reducers/competancy/chapter";
import topic from "./reducers/competancy/topic";
import question from "./reducers/competancy/question";
import news from "./reducers/news";
import informaction from "./reducers/inform/Action/informaction";
import filecabinet from "./reducers/filecabinet/filecabinet";
import permit from "./reducers/PermitToWork/index";
import risk from "./reducers/Risk/risk"
import template from "./reducers/Template/template"
import incidentReportCategory from "./reducers/Incident/IncidentReport/incidentReportCategory"
import globalQuickRoutes from './reducers/getGlobalQuickRoutes/index'
import qc from "./reducers/inform/projectmonitoring/index"
import userdashboard from  "./reducers/userdashboard/userdashboard"
import ncr  from  "./reducers/inform/projectmonitoring/NCR/index"
import incr from "./reducers/inform/projectmonitoring/INCR/index"

//INFORM REPORT
import informReportFilter from "./reducers/inform/HSSEReport/informFilter";

//PERMIT REPORT
import permitToWorkFilter from "./reducers/PermitToWork/report/index";

const rootReducer = combineReducers({
    Layout,
    calendar,
    forgetPassword,
    login,
    profile,
    account,
    item,
    salesman,
    visit,
    roles,
    organizations,
    navigation,
    hazard,
    incidentreport,
    users,
    country,
    state,
    city,
    sysroles,
    tag,
    nonconformity,
    improvementOpportunity,
    complaint,
    master,
    goseeprogram,
    tbt,
    tnt,
    L2PM,
    RHPW,
    hsseaudit,
    ldo1,
    action,
    businessgroup,
    businessunit,
    projectclient,
    project,
    site,
    course,
    chapter,
    topic,
    question,
    news,
    informaction,
    filecabinet,
    permit,
    risk,
    template,
    incidentReportCategory,
    globalQuickRoutes,
    qc,
    userdashboard,
    ncr,
    incr,
    informReportFilter,
    competencyFilter,
    permitToWorkFilter
});

export default rootReducer;
