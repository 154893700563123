import {
    INCIDENT_CATEGORY_VALUES, INCIDENT_FILTER_CATEGORY,
    CFR_VALUES, CFR_FILTER, CIR_FILTER, CIR_VALUES, IS_VALUES, IS_FILTER, IO_VALUES, IO_FILTER,
    PDR_FILTER, PDR_VALUES, CSR_VALUES, CSR_FILTERS, IER_VALUES, IAC_FILTERS, IAC_VALUES, INCIDENT_FILTER_VALUES,INCIDENT_FILTER_FILTER
} from "../../../types";

const initialState = {
    icrValues: [], ircFilters: [], cfrValues: [],
    cfrFilters: [], cirValues: [], cirFilters: [], ioValues: [], ioFilters: [],
    isValues: [], isFilters: [],
    pdrValues:[],pdrFilters:[],
    csrValues:[],csrFilters:[],
    ierValues:[],ierFilters:[],
    iacValues:[],iacFilters:[],
    irValues:[],irFilters:[],
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case INCIDENT_FILTER_VALUES:
            return { ...state, irValues: payload };
        case INCIDENT_FILTER_FILTER:
            return { ...state, irFilters: payload };
        case INCIDENT_CATEGORY_VALUES:
            return { ...state, icrValues: payload };
        case INCIDENT_FILTER_CATEGORY:
            return { ...state, ircFilters: payload };
        case CFR_VALUES:
            return { ...state, cfrValues: payload };
        case CFR_FILTER:
            return { ...state, cfrFilters: payload };
        case CIR_VALUES:
            return { ...state, cirValues: payload };
        case CIR_FILTER:
            return { ...state, cirFilters: payload };
        case IS_VALUES:
            return { ...state, isValues: payload };
        case IS_FILTER:
            return { ...state, isFilters: payload };
        case IO_VALUES:
            return { ...state, ioValues: payload };
        case IO_FILTER:
            return { ...state, ioFilters: payload };
        case PDR_VALUES:
            return { ...state, pdrValues: payload };
        case CSR_VALUES:
            return { ...state, csrValues: payload };
        case IER_VALUES:
            return { ...state, ierValues: payload };
        case IAC_VALUES:
            return { ...state, iacValues: payload };
        default:
            return state;
    }
};

export default reducer;