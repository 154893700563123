import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Container,
} from "reactstrap";
import { DeleteOutlined, EditOutlined, PlusCircleFilled, BookOutlined } from "@ant-design/icons";
import ReactStrapDataTable from "../../CustomDatatable/ReactStrapDataTable";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Chapter from "../Chapter";
import { getCourses, addCourse, updateCourse, deleteCourse } from "../../../store/actions/competancy/courses";
import { dateTimeSort, stringSorter } from "../../../utils/functions";

const Course = () => {
    const dispatch = useDispatch();
    const courses = useSelector((state) => state.course.list);

    const [modalOpen, setModalOpen] = useState(false);
    const [chapterModalOpen, setChapterModalOpen] = useState(false);
    const [editingCourse, setEditingCourse] = useState(null);
    const [selectedCourseId, setSelectedCourseId] = useState(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [examInstructionsEditorState, setExamInstructionsEditorState] = useState(EditorState.createEmpty());

    const [hasAssessment, setHasAssessment] = useState(false);
    const [hasTotalLimit, setHasTotalLimit] = useState(false);

    const {
        control,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            hasAssessment: false,
            totalQuestions: '',
            totalMarks: '',
            totalLimit: false,
            totalLimitValue: '',
        }
    });

    const watchHasAssessment = watch("hasAssessment");
    const watchTotalLimit = watch("totalLimit");

    useEffect(() => {
        dispatch(getCourses());
    }, [dispatch]);

    useEffect(() => {
        setHasAssessment(watchHasAssessment);
    }, [watchHasAssessment]);

    useEffect(() => {
        setHasTotalLimit(watchTotalLimit);
    }, [watchTotalLimit]);

    const handleEdit = (course) => {
        setEditingCourse(course);
        const contentBlock = htmlToDraft(course.description);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
        }
        const examInstructionsBlock = htmlToDraft(course.examDescription || '');
        if (examInstructionsBlock) {
            const contentState = ContentState.createFromBlockArray(examInstructionsBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setExamInstructionsEditorState(editorState);
        }
        reset({
            name: course.name,
            hasAssessment: course.hasAssessment || false,
            totalQuestions: course.totalQuestions || '',
            totalMarks: course.totalMarks || '',
            totalLimit: course.totalLimit !== null && course.totalLimit > 0 ? true : false,
            totalLimitValue: course.totalLimit || '',
        });
        setModalOpen(true);
    };

    const handleDelete = async (course) => {
        if (window.confirm("Are you sure you want to delete this course?")) {
            await dispatch(deleteCourse(course.id));
            dispatch(getCourses());
        }
    };

    const handleChapters = (courseId) => {
        setSelectedCourseId(courseId.id);
        setChapterModalOpen(true);
    };

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    };
    const onExamInstructionsEditorStateChange = (editorState) => {
        setExamInstructionsEditorState(editorState);
    };

    const onFormSubmit = async (data) => {
        const description = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        const examDescription = draftToHtml(convertToRaw(examInstructionsEditorState.getCurrentContent()));

        let payload = {
            name: data.name,
            description: description,
            examDescription: examDescription,
            hasAssessment: data.hasAssessment,
            totalQuestions: data.hasAssessment ? parseInt(data.totalQuestions) : null,
            totalMarks: data.hasAssessment ? parseInt(data.totalMarks) : null,
            totalLimit: data.totalLimit ? parseInt(data.totalLimitValue) : null,
        };

        if(!payload.hasAssessment) {

            delete payload.totalQuestions
            delete payload.totalMarks
            delete payload.totalLimit
        }

        let result
        if (editingCourse) {
            result=await dispatch(updateCourse({id: editingCourse.id, ...payload}));
        } else {
            result=await dispatch(addCourse(payload));
        }
        if(result.success) {
            reset()
            handleCloseModal();
        }

    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setEditingCourse(null);
        setEditorState(EditorState.createEmpty());
        setExamInstructionsEditorState(EditorState.createEmpty());
        reset({
            name: '',
            hasAssessment: false,
            totalQuestions: '',
            totalMarks: '',
            totalLimit: false,
            totalLimitValue: '',
        });
    };

    const handleCloseChapterModal = () => {
        setChapterModalOpen(false);
        setSelectedCourseId(null);
    };

    const handleAddNew = () => {
        setEditingCourse(null);
        setEditorState(EditorState.createEmpty());
        setExamInstructionsEditorState(EditorState.createEmpty());
        reset({
            name: '',
            hasAssessment: false,
            totalQuestions: '',
            totalMarks: '',
            totalLimit: false,
            totalLimitValue: '',
        });
        setModalOpen(true);
    };

    const columns = [
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: dateTimeSort("createdAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: stringSorter("name"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Has Assessment",
            dataIndex: "hasAssessment",
            key: "hasAssessment",
            render: (hasAssessment) => (hasAssessment ? "Yes" : "No"),
        },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
                <>
                    <Button color="link" onClick={() => handleEdit(record)}>
                        <EditOutlined />
                    </Button>
                    <Button color="link" onClick={() => handleDelete(record)}>
                        <DeleteOutlined />
                    </Button>
                    <Button color="link" onClick={() => handleChapters(record)}>
                        <BookOutlined />
                    </Button>
                </>
            ),
        },
    ];

    return (
        <Container fluid={true}>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">Manage Courses</h5>
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" onClick={handleAddNew}>
                                <PlusCircleFilled /> Add New Course
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactStrapDataTable
                        dataSource={courses}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} size="xl" backdrop={"static"}>
                <ModalHeader toggle={handleCloseModal}>
                    {editingCourse ? 'Edit Course' : 'Add Course'}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: "Name is required" }}
                                render={({ field }) => <Input {...field} id="name" />}
                            />
                            {errors.name && <span className="text-danger">{errors.name.message}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="description">Description</Label>
                            <Editor
                                editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={onEditorStateChange}
                                editorStyle={{ height: '300px', border: '1px solid #F1F1F1', padding: '0 10px' }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="examInstructions">Exam Instructions</Label>
                            <Editor
                                editorState={examInstructionsEditorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={onExamInstructionsEditorStateChange}
                                editorStyle={{ height: '300px', border: '1px solid #F1F1F1', padding: '0 10px' }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="hasAssessment">Has Assessment</Label>
                            <Controller
                                name="hasAssessment"
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        type="select"
                                        {...field}
                                        onChange={(e) => field.onChange(e.target.value === 'true')}
                                    >
                                        <option value={false}>No</option>
                                        <option value={true}>Yes</option>
                                    </Input>
                                )}
                            />
                        </FormGroup>

                        {hasAssessment && (
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="totalQuestions">Total Questions</Label>
                                        <Controller
                                            name="totalQuestions"
                                            control={control}
                                            rules={{
                                                required: "Total Questions is required",
                                                min: { value: 1, message: "Value must be greater than 0" }
                                            }}
                                            render={({ field }) => <Input type="number" {...field} id="totalQuestions" min="1" />}
                                        />
                                        {errors.totalQuestions && <span className="text-danger">{errors.totalQuestions.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="totalMarks">Passing Criteria</Label>
                                        <Controller
                                            name="totalMarks"
                                            control={control}
                                            rules={{
                                                required: "Total Marks is required",
                                                min: { value: 1, message: "Value must be greater than 0" }
                                            }}
                                            render={({ field }) => <Input type="number" {...field} id="totalMarks" min="1" />}
                                        />
                                        {errors.totalMarks && <span className="text-danger">{errors.totalMarks.message}</span>}
                                    </FormGroup>
                                </Col>
                            </Row>
                        )}

                        {hasAssessment && (
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="totalLimit">Total Limit</Label>
                                        <Controller
                                            name="totalLimit"
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    type="select"
                                                    {...field}
                                                    onChange={(e) => field.onChange(e.target.value === 'true')}
                                                >
                                                    <option value={false}>No</option>
                                                    <option value={true}>Yes</option>
                                                </Input>
                                            )}
                                        />
                                    </FormGroup>
                                </Col>
                                {hasTotalLimit && (
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="totalLimitValue">Total Limit Value</Label>
                                            <Controller
                                                name="totalLimitValue"
                                                control={control}
                                                rules={{
                                                    required: "Total Limit Value is required",
                                                    min: { value: 1, message: "Value must be greater than 0" }
                                                }}
                                                render={({ field }) => <Input type="number" {...field} id="totalLimitValue" min="1" />}
                                            />
                                            {errors.totalLimitValue && <span className="text-danger">{errors.totalLimitValue.message}</span>}
                                        </FormGroup>
                                    </Col>
                                )}
                            </Row>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {editingCourse ? 'Update' : 'Save'}
                        </Button>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>

            <Modal isOpen={chapterModalOpen} toggle={handleCloseChapterModal} size="xl" backdrop={"static"}>
                <ModalHeader toggle={handleCloseChapterModal}>
                    Manage Chapters
                </ModalHeader>
                <ModalBody>
                    <Chapter courseId={selectedCourseId} />
                </ModalBody>
            </Modal>
        </Container>
    );
};

export default Course;