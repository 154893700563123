import React, {useEffect, useState} from 'react';
import Hazard from "../../Pages/Incident/Hazard";
import IncidentReport from "../../Pages/Incident/IncidentReport";
import Roles from "../../Pages/System-admin/Roles";
import PermissionAccess from "../../Pages/System-admin/SystemRoles/PermissionAccess";
import SystemRoles from "../../Pages/System-admin/SystemRoles/SystemRoles";
import NonConformity from "../../Pages/Incident/NonConformity";
import ImprovementOpportunities from "../../Pages/Incident/ImprovementOppourtionity";
import Complaints from "../../Pages/Incident/Complaint/Complaint";
import {Alert, Card, CardBody, CardHeader, CardText, CardTitle} from "reactstrap";
import { FiAlertTriangle } from 'react-icons/fi';
import Master from "../../Pages/System-admin/Master";
import UserRolesAccess from "../../Pages/System-admin/SystemRoles/UserRolesAccess";
import GoSeeProgram from "../../Pages/Inform/Audits/GoSeeProgram";
import HSSEAudit from "../../Pages/Inform/Audits/HSSEAudit";
import ToolBoxTalk from "../../Pages/Inform/Audits/ToolBoxTalk";
import ProjectWeekly from "../../Pages/Inform/HSSEReports/ProjectWeekly";
import LDO1 from "../../Pages/Inform/Audits/LDO1";
import Action from "../../Pages/Incident/Action";
import IncidentGlobalModule from "../../Pages/Incident/IncidentGlobalModule";
import Country from "../../Pages/Region/Country";
import State from "../../Pages/Region/State";
import City from "../../Pages/Region/City";
import BusinessGroup from "../../Pages/System-admin/Business/BusinessGroups";
import BusinessUnit from "../../Pages/System-admin/Business/BusinessUnit";
import Project from "../../Pages/System-admin/Business/Project";
import Site from "../../Pages/System-admin/Business/Site";
import ProjectClient from "../../Pages/System-admin/Business/ProjectClient";
import Course from "../../Pages/Competancy/Courses";
import QuestionManagement from "../../Pages/Competancy/Question";
import News from "../../Pages/System-admin/News";
import AssignedCourses from "../../Pages/Competancy/AssignedCourses";
import Enrolled from "../../Pages/Competancy/Enrolled";
import InformAction from "../../Pages/Inform/Action";
import InformGlobalModule from "../../Pages/Inform/InformGlobalModule";
import User from "../../Pages/System-admin/User";
import L2PMWeeklyReporting from "../../Pages/Inform/HSSEReports/L2PMWeeklyReporting";
import {trim} from "lodash";
import CompetencyDashboard from "../../Pages/Competancy/Dashboard";
import FileCabinet from "../../Pages/System-admin/FileCabinet";
import PermitToWork from "../../Pages/PermitToWork";
import Template from "../../Pages/System-admin/Template/index"
import Risk from '../../Pages/System-admin/Risk';
import IncidentReportCategory from '../../Pages/Incident/Reports/IncidentReportCategory';
import ContributingFactorReport from '../../Pages/Incident/Reports/ContributingFactorReport';
import ContractorInvolvmentReport from '../../Pages/Incident/Reports/ContractorInvolvmentReport';
import IssueSummary from '../../Pages/Incident/Reports/IssueSummary';
import IssueOverview from '../../Pages/Incident/Reports/IssueOverview/index';
import PlantDamageReport from '../../Pages/Incident/Reports/PlantDamageReport';
import CostSummaryReport from '../../Pages/Incident/Reports/CostSummaryReport';
import IncidentEnvironmentReport from '../../Pages/Incident/Reports/IncidentEnvironmentReport';
import IssueActionReport from '../../Pages/Incident/Reports/IssueActionReport';
import Organization from '../../Pages/System-admin/Business/Organization';
import GlobalDashboard from '../../Pages/System-admin/Dashboard/Global';
import ReceiverPermitToWork from '../../Pages/PermitToWork/Receiver';
import IssuerPermitToWork from '../../Pages/PermitToWork/Issuer';
import QualityCheck from '../../Pages/Inform/ProjectMonitoring/QualityCheck';
import CountryGlobalDashboard from '../../Pages/System-admin/Dashboard/Country';
import UserDashboard from "../../Pages/System-admin/UserDashboard"
import NCR from '../../Pages/Inform/ProjectMonitoring/NCR';
import INCR from '../../Pages/Inform/ProjectMonitoring/INCR';
import IncidentFilter from "../../Pages/Incident/Reports/IncidentFilter";
import InformFilter from "../../Pages/Inform/HSSEReports/InformFilter";
import CompetencyFilter from "../../Pages/Competancy/CompetencyFilter";
import PermitToWorkFilter from "../../Pages/PermitToWork/Report";



const ModuleNotFoundCard = () => {
    const [showComingSoon, setShowComingSoon] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowComingSoon(true);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh', padding: '20px' }}>
            <Card className="text-center shadow-lg" style={{ width: '100%', maxWidth: '600px' }}>
                <CardHeader className="bg-warning py-4">
                    <CardTitle tag="h2" className="text-dark mb-0 d-flex align-items-center justify-content-center">
                        <FiAlertTriangle className="mr-3" size={36} />
                        <span>Module Not Found</span>
                    </CardTitle>
                </CardHeader>
                <CardBody className="py-5">
                    <CardText className="text-muted mb-4" style={{ fontSize: '1.2rem' }}>
                        Oops! The requested module couldn't be located.
                    </CardText>
                    <div style={{ height: '50px', position: 'relative' }}>
                        <Alert
                            color="info"
                            className="mb-0"
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                opacity: showComingSoon ? 1 : 0,
                                transition: 'opacity 0.5s ease-in-out',
                                fontSize: '1.1rem',
                                padding: '10px',
                            }}
                        >
                            <strong className="coming-soon">Coming Soon!</strong>
                        </Alert>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};

export const componentMap = {
    'hazard': Hazard,
    'incident/report': IncidentReport,
    'systemroles/access': Roles,
    'permission/access': PermissionAccess,
    'systemroles': SystemRoles,
    'nonconformity': NonConformity,
    'improvement-opportunity': ImprovementOpportunities,
    'complaint': Complaints,
    'manage': Master,
    'user/access': UserRolesAccess,
    'gsp': GoSeeProgram,
    //'gsp': IntegratedFormBuilder,
    'audit': HSSEAudit,
    'tbt': ToolBoxTalk,
    // 'tnt': ToolNotTick,
    'rhpw': ProjectWeekly,
    'rpmw': L2PMWeeklyReporting,
    'in/filter': InformFilter,
    'permit/filter': PermitToWorkFilter,
    'competency/filter':CompetencyFilter,
    'ldo': LDO1,
    'list': Action,
    'status': IncidentGlobalModule,
    'country': Country,
    'state': State,
    'city': City,
    'business/group': BusinessGroup,
    'business/unit': BusinessUnit,
    'project': Project,
    'project/client': ProjectClient,
    'site': Site,
    'course': Course,
    'question': QuestionManagement,
    'news/my': News,
    'assign': AssignedCourses,
    'enrolled': Enrolled,
    'inform/list': InformAction,
    'inform/status': InformGlobalModule,
    'user': User,
    'dashboard':CompetencyDashboard,
    'cabinet':FileCabinet,
    'permit':PermitToWork,
    'templates':Template,
    'register':Risk,
    'irc':IncidentReportCategory,
    'ir':IncidentFilter,
    'cfr':ContributingFactorReport,
    'cir':ContractorInvolvmentReport,
    'is':IssueSummary,
    'io':IssueOverview,
    'pdr':PlantDamageReport,
    'csr':CostSummaryReport,
    'ier':IncidentEnvironmentReport,
    'iar':IssueActionReport,
    'organization':Organization,
    'dashboard/global':GlobalDashboard,
    'dashboard/country':CountryGlobalDashboard,
    'user/review':ReceiverPermitToWork,
    'user/issue':IssuerPermitToWork,
    'inform/qc':QualityCheck,
    'dashboard/my':UserDashboard,
    'ncr':NCR,
    'incr':INCR
};


export const GlobalComponent = ({ submenuRoute,navigation }) => {
    console.log(submenuRoute)
    
    const moduleRouteToId = (submenuRoute) => {
        const module = navigation.find(item => item.module_route === submenuRoute);
        return module ? module.role_json : null;
    };

    const selectedModule=moduleRouteToId(submenuRoute)
    const Component = componentMap[trim(submenuRoute)];
    return Component ? <Component access={selectedModule} /> : <ModuleNotFoundCard />;

};
export default GlobalComponent;