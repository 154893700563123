import axios from "../../../axios";
import {
    GET_ACTION_COUNTS,
    GET_ACTIONS,
    GET_ACTION_FILTER
} from "../../types";
import {setAlert} from "../alert";
import {message} from "antd";

export const getActions = (accessModuleId) => async (dispatch) => {
    try {
        const object={
            moduleId:parseInt(accessModuleId)
        }
        const res = await axios.post("incident/action/user",object);
        
        
        if (res.data.code === 1) {
            dispatch({type: GET_ACTIONS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const submitGlobalAction=(route,formData)=>async (dispatch) => {
    try {
        const res = await axios.post(`${route}/action/update`, formData); 
        if (res.data.code === 1) {
            return {success: true, message: res.data.message};
        } else {
            return {success: false, message: res.data.message}
        }
    } catch (err) {
        console.error("Error in submitActionForComplaint:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
}
export const getGlobalActionCount=()=>async (dispatch) => {
    try {
        const res = await axios.get("incident/action/count/user");
        if (res.data.code === 1) {
            dispatch({type: GET_ACTION_COUNTS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const getActionFilterData=(route,formData)=>async (dispatch) => {
    try {
        
        const res = await axios.post(`${route}/action/filter`, formData);
        if (res.data.code === 1) {
            dispatch({type: GET_ACTION_FILTER, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const globalActionUpdate=(route,formData)=>async (dispatch) => {
    try {
        const res = await axios.post(`${route}/action/update`, formData);
        if (res.data.code === 1) {
            return {success: true, message: res.data.message};
        } else {
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        console.error("Error in addHazardForm:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
}
export const globalActionAdd=(route,formData)=>async (dispatch) => {
    try {
        
        const res = await axios.post(`${route}/action/add`, formData);
        if (res.data.code === 1) {
            message.success(res.data.message);
            return {success: true, message: res.data.message};
        } else {
            message.warning(res.data.message);
            return {success: false, message: res.data.message};

        }
    } catch (err) {
        console.error("Error in addHazardForm:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
}

export const globalActionObject=(route,id)=>async (dispatch) => {
    try {
        const res = await axios.get(`${route}/${id}`);
        if (res.data.code === 1) { 
            return {success: true, message: res.data.message,payload:res.data.data};
        } else {
            return {success: false, message: res.data.message}
        }
    } catch (err) {
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
}


