import axios from "../../../axios";
import {
    ADD_PROJECT_CLIENT,
    DELETE_PROJECT_CLIENT,
    GET_PROJECT_CLIENTS,
    UPDATE_PROJECT_CLIENT,
    GET_PROJECT_CLIENT_VALUES, GET_PROJECT_CLIENT
} from "../../types";
import {setAlert} from "../alert";
import {message} from "antd";

export const getProjectClients = () => async (dispatch) => {
    try {
        const res = await axios.get("/project/client/all");
        if (res.data.code === 1) {
            dispatch({type: GET_PROJECT_CLIENTS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const addProjectClient = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/project/client/add", formData);
        ;
        if (res.data.code === 1) {
            dispatch({type: ADD_PROJECT_CLIENT, payload: res.data.data});
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const getProjectClientValues= () => async (dispatch) => {
    try {
        const res = await axios.get("project/client/values");
        ;
        if (res.data.code === 1) {
            dispatch({ type: GET_PROJECT_CLIENT_VALUES, payload: res.data.data });
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const getProjectClient = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/project/client/${id}`);
        if (res.data.code === 1) {
            dispatch({type: GET_PROJECT_CLIENT, payload: res.data.data});
        }
    } catch (err) {
        // dispatch(setAlert(err.message, "danger"));
    }
};

export const updateProjectClient = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(`/project/client/update`, formData);
        if (res.data.code === 1) {
            dispatch({type: UPDATE_PROJECT_CLIENT, payload: res.data.data});
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        // dispatch(setAlert(err.message, "danger"));
    }
};
export const deleteProjectClient = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/project/client/delete/${id}`);
        if (res.data.code === 1) {
            message.success(res.data.message);
            dispatch({type: DELETE_PROJECT_CLIENT, payload: res.data.data});
        } else {
            message.warning(res.data.message);
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
