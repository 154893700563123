import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import { DeleteOutlined, EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import ReactStrapDataTable from "../../CustomDatatable/ReactStrapDataTable";
import { getCountries, addCountry, updateCountry, deleteCountry } from "../../../store/actions/region/country";
import { dateTimeSort, stringSorter } from "../../../utils/functions";

const Country = () => {
    const dispatch = useDispatch();
    const countries = useSelector((state) => state.country.list);

    const [modalOpen, setModalOpen] = useState(false);
    const [editingCountry, setEditingCountry] = useState(null);

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        dispatch(getCountries());
    }, [dispatch]);

    const handleEdit = (country) => {
        setEditingCountry(country);
        reset(country);
        setModalOpen(true);
    };

    const handleDelete = async (country) => {
        if (window.confirm("Are you sure you want to delete this country?")) {
            await dispatch(deleteCountry(country.id));
            dispatch(getCountries());
        }
    };

    const onFormSubmit = (data) => {
        if (editingCountry) {
            const payload={
                id:editingCountry.id,
                name: data.name,
                iso1: data.iso1,
                iso2: data.iso2,
            }
            dispatch(updateCountry(payload));
        } else {
            dispatch(addCountry(data));
        }
        handleCloseModal();
        //dispatch(getCountries());
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setEditingCountry(null);
        reset();
    };

    const handleAddNew = () => {
        setEditingCountry(null);
        reset({ name: '', iso1: '', iso2: '' });
        setModalOpen(true);
    };

    const columns = [
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: dateTimeSort("createdAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: stringSorter("name"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "ISO 1",
            dataIndex: "iso1",
            key: "iso1",
            sorter: stringSorter("iso1"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "ISO 2",
            dataIndex: "iso2",
            key: "iso2",
            sorter: stringSorter("iso2"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
                <>
                    <Button color="link" onClick={() => handleEdit(record)}>
                        <EditOutlined />
                    </Button>
                    <Button color="link" onClick={() => handleDelete(record)}>
                        <DeleteOutlined />
                    </Button>
                </>
            ),
        },
    ];

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">Manage Country</h5>
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" onClick={handleAddNew}>
                                <PlusCircleFilled /> Add New
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactStrapDataTable
                        dataSource={countries}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} backdrop="static">
                <ModalHeader toggle={handleCloseModal}>
                    {editingCountry ? 'Edit Country' : 'Add Country'}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: "Name is required" }}
                                render={({ field }) => <Input {...field} id="name" />}
                            />
                            {errors.name && <span className="text-danger">{errors.name.message}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="iso1">ISO 1</Label>
                            <Controller
                                name="iso1"
                                control={control}
                                rules={{
                                    required: "ISO 1 is required",
                                    minLength: { value: 2, message: "ISO 1 must be 2 characters" },
                                    maxLength: { value: 2, message: "ISO 1 must be 2 characters" },
                                }}
                                render={({ field }) => <Input {...field} id="iso1" />}
                            />
                            {errors.iso1 && <span className="text-danger">{errors.iso1.message}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="iso2">ISO 2</Label>
                            <Controller
                                name="iso2"
                                control={control}
                                rules={{
                                    required: "ISO 2 is required",
                                    minLength: { value: 3, message: "ISO 2 must be 3 characters" },
                                    maxLength: { value: 3, message: "ISO 2 must be 3 characters" },
                                }}
                                render={({ field }) => <Input {...field} id="iso2" />}
                            />
                            {errors.iso2 && <span className="text-danger">{errors.iso2.message}</span>}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {editingCountry ? 'Update' : 'Save'}
                        </Button>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
};

export default Country;