import axios from "../../../axios";
import {ADD_SITE, DELETE_SITE, GET_SITES, GET_SITE, UPDATE_SITE, GET_SITE_VALUES} from "../../types";
import {setAlert} from "../alert";
import {message} from "antd";

export const getSites = () => async (dispatch) => {
    try {
        const res = await axios.get("/site/all");
        
        if (res.data.code === 1) {
            dispatch({type: GET_SITES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const addSite = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/site/add", formData);
        ;
        if (res.data.code === 1) {
            dispatch({type: ADD_SITE, payload: res.data.data});
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};


export const getSiteValues= () => async (dispatch) => {
    try {
        const res = await axios.get("site/values");
        ;
        if (res.data.code === 1) {
            dispatch({ type: GET_SITE_VALUES, payload: res.data.data });
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}


export const getSite = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/site/${id}`);
        if (res.data.code === 1) {
            dispatch({type: GET_SITE, payload: res.data.data});
        }
    } catch (err) {
        // dispatch(setAlert(err.message, "danger"));
    }
};

export const updateSite = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(`/site/update`, formData);
        if (res.data.code === 1) {
            dispatch({type: UPDATE_SITE, payload: res.data.data});
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        // dispatch(setAlert(err.message, "danger"));
    }
};
export const deleteSite = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/site/delete/${id}`);
        if (res.data.code === 1) {
            message.success(res.data.message);
            dispatch({type: DELETE_SITE, payload: res.data.data});
        } else {
            message.warning(res.data.message);
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
