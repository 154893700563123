import axios from '../../axios';
import {setAlert} from "./alert";
import {ADD_SALESMAN, DELETE_SALESMAN, GET_SALESMAN, GET_SALESMANS, GET_VISITS, UPDATE_SALESMAN} from "../types";
import {message} from "antd";
import {propTypes as res} from "react-csv/src/metaProps";

export const getVisits = () => async (dispatch) => {
    try {
        const res = await axios.get("/visit/all");
        
        if (res.data.code === 1) {
            dispatch({type: GET_VISITS, payload: res.data.data});
            message.success(res.data.message)
        }
    } catch (err) {
        message.warning(res.data.message)

    }
};
