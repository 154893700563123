import {
    GET_ACTION_COUNTS, GET_ACTION_FILTER,
    GET_ACTIONS,
} from "../../types";

const initialState = { list: {}, single: null, userActions: [] ,userFilterData:[]};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_ACTIONS:
            return { ...state, list: payload };
        case GET_ACTION_COUNTS:
            return { ...state, userActions: payload };
        case GET_ACTION_FILTER:
            return { ...state, userFilterData: payload };
        default:
            return state;
    }
};

export default reducer;