import {ADD_SITE, GET_SITE, GET_SITE_VALUES, GET_SITES, UPDATE_SITE} from "../../types";


const initialState = {list: [], single: null,values:[]};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_SITES:
            return {...state, list: payload};
        case GET_SITE_VALUES:
            return {...state, values: payload};

        case GET_SITE:
            return {
                ...state,
                list: [payload, ...state.list],
            };
        case ADD_SITE:
            return {
                ...state,
                list: [payload, ...state.list],
            };
        case UPDATE_SITE:
            return {
                ...state,
                list: state.list.map((item) =>
                    item.id === payload.id ? payload : item
                ),
            };


        default:
            return state;
    }
};

export default reducer;
