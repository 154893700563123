import axios from "../../../axios";
import { ADD_RISK, GET_RISK, GET_RISKS, UPDATE_RISK, DELETE_RISK, GET_RISK_VALUES } from "../../types";
import { setAlert } from "../alert";
import { message } from "antd";



// Add Risk
export const addRisk = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        const res = await axios.post("risk/add", formData, config);
        if (res.data.code === 1) {
            dispatch({ type: ADD_RISK, payload: res.data.data });
            message.success(res.data.message);
            return true;
        } else {
            dispatch(setAlert(res.data.message, "warning"));
            return false;
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

//Risk Values
export const getRiskValues = () => async (dispatch) => {
    const res = await axios.get(`risk/values`);
    if (res.data.code === 1) {
        dispatch({ type: GET_RISK_VALUES, payload: res.data.data });
        message.success(res.data.message);
    } else {
        message.warning(res.data.message);
    }

}

export const updateRiskStatus = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("risk/status", formData);
        if (res.data.code === 1) {
            dispatch({ type: UPDATE_RISK, payload: res.data.data });
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
};


export const getRisks = () => async (dispatch) => {
    try {
        const res = await axios.get("risk/user");
        if (res.data.code === 1) {
            dispatch({ type: GET_RISKS, payload: res.data.data });
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

// Get Single RISK
export const getRisk = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`risk/${id}`);
        if (res.data.code === 1) {
            dispatch({ type: GET_RISK, payload: res.data.data });

        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
};

// Clear Single RISK
export const clearSingleRisk = () => async (dispatch) => {
    try {
        dispatch({ type: GET_RISK, payload: '' });
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
};

// Update RISK
export const updateRisk = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        const res = await axios.post("risk/update", formData, config);
        if (res.data.code === 1) {
            dispatch({ type: UPDATE_RISK, payload: res.data.data });
            message.success(res.data.message);
            return true;

        } else {
            return false;
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
};

// Delete RISK
export const deleteRisk = (id) => async (dispatch) => {
    try {
        if (!id) {
            dispatch(setAlert('Please select any record', 'warning'));
            return false;
        }
        const res = await axios.get(`risk/delete/${id}`);
        if (res.data.code === 1) {
            dispatch({ type: DELETE_RISK, payload: { id } });
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
};



