import React from 'react';
import { Table, Typography, Card } from 'antd';
import styled from 'styled-components';

const { Title } = Typography;

const StyledCard = styled(Card)`
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
  margin: 20px;
`;

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: #f0f2f5;
    color: #1890ff;
    font-weight: 600;
    text-align: center;
    padding: 12px 8px;
    &::before {
      display: none;
    }
  }

  .ant-table-tbody > tr > td {
    padding: 12px 8px;
    text-align: center;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    white-space: nowrap;
  }

  .ant-table-thead > tr:first-child > th:first-child {
    border-top-left-radius: 8px;
  }

  .ant-table-thead > tr:first-child > th:last-child {
    border-top-right-radius: 8px;
  }

  .ant-table-tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 8px;
  }

  .ant-table-tbody > tr:last-child > td:last-child {
    border-bottom-right-radius: 8px;
  }
`;

const CellWrapper = styled.span`
  display: block;
  text-align: center;
  padding: 6px 4px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: 500;

  &:hover {
    opacity: 0.8;
  }
`;

const DisciplineTable = () => {
  const disciplines = [
    { code: 'CS', name: 'Civil & Structure' },
    { code: 'AR', name: 'Architect' },
    { code: 'PC', name: 'Project Control' },
    { code: 'ME', name: 'Mechanical' },
    { code: 'EL', name: 'Electrical' },
  ];

  const data = [
    {
      key: '1',
      id: 1,
      code: 'CS',
      name: 'Civil & Structure',
      total: 2,
      disciplines: {
        CS: { B: null, D: null, C: null, A: null },
        AR: { B: 1, D: 1, C: null, A: null },
        PC: { B: null, D: null, C: null, A: 2 },
        ME: { B: null, D: null, C: 1, A: null },
        EL: { B: null, D: null, C: null, A: null },
      }
    },
    {
      key: '2',
      id: 9,
      code: 'ME',
      name: 'Mechanical',
      total: 8,
      disciplines: {
        CS: { B: null, D: null, C: null, A: 1 },
        AR: { B: 5, D: 5, C: 1, A: 2 },
        PC: { B: null, D: null, C: null, A: 6 },
        ME: { B: null, D: null, C: null, A: null },
        EL: { B: null, D: null, C: 7, A: null },
      }
    },
  ];

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: 80,
      fixed: 'left',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      fixed: 'left',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: 80,
      fixed: 'left',
      render: (text, record) => (
        <CellWrapper
          style={{ backgroundColor: '#f0f0f0', color: '#1890ff' }}
          onClick={() => handleClick(record.id, 0, 'B')}
        >
          {text}
        </CellWrapper>
      ),
    },
    ...disciplines.flatMap(discipline => [
      {
        title: discipline.code,
        children: [
          {
            title: 'B',
            dataIndex: ['disciplines', discipline.code, 'B'],
            key: `${discipline.code}-B`,
            width: 60,
            render: (text, record) => renderCell(text, record, discipline.code, 'B'),
          },
          {
            title: 'D',
            dataIndex: ['disciplines', discipline.code, 'D'],
            key: `${discipline.code}-D`,
            width: 60,
            render: (text, record) => renderCell(text, record, discipline.code, 'D'),
          },
          {
            title: 'C',
            dataIndex: ['disciplines', discipline.code, 'C'],
            key: `${discipline.code}-C`,
            width: 60,
            render: (text, record) => renderCell(text, record, discipline.code, 'C'),
          },
          {
            title: 'A',
            dataIndex: ['disciplines', discipline.code, 'A'],
            key: `${discipline.code}-A`,
            width: 60,
            render: (text, record) => renderCell(text, record, discipline.code, 'A'),
          },
        ],
      },
    ]),
  ];

  const renderCell = (text, record, disciplineCode, type) => {
    if (!text) return <CellWrapper></CellWrapper>;
    
    const backgroundColor = getBackgroundColor(type);
    return (
      <CellWrapper
        style={{ backgroundColor, color: '#fff' }}
        onClick={() => handleClick(record.id, disciplines.findIndex(d => d.code === disciplineCode) + 1, type)}
      >
        {text}
      </CellWrapper>
    );
  };

  const getBackgroundColor = (type) => {
    switch (type) {
      case 'B': return '#1890ff'; // primary blue
      case 'D': return '#ff4d4f'; // danger red
      case 'C': return '#faad14'; // warning yellow
      case 'A': return '#52c41a'; // success green
      default: return '';
    }
  };

  const handleClick = (fromId, toId, type) => {
    //console.log(`ShowDetail(${fromId}, ${toId}, '${type}');`);
    // Implement your click handler here
  };

  return (
    <StyledCard>
      <Title level={3}>Discipline Overview</Title>
      <StyledTable 
        columns={columns} 
        dataSource={data} 
        bordered 
        size="middle"
        pagination={false}
        scroll={{ x: 'max-content' }}
      />
    </StyledCard>
  );
};

export default DisciplineTable;