import React, {useEffect, useRef, useState} from 'react';
import {Modal, ModalHeader, ModalBody, Card, CardHeader, CardBody, Row, Col, Table, Button, Spinner} from 'reactstrap';
import { FilePdfOutlined, CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import meinhardtlogo from "../../../assets/images/meinhardt.png"
import {isImageFile, renderAttachment} from "../../../utils/functions";

const ViewGoSeeProgramSection = ({ isOpen, toggle, data }) => {
    const contentRef = useRef(null);

    const generatePDF = async () => {
        const input = contentRef.current;

        const logo = input.querySelector('#meinhardtLogo');
        const originalStyle = logo.style.cssText;
        logo.style.maxWidth = '250px';
        logo.style.height = '250px';

        const canvas = await html2canvas(input, {
            scale: 2,
            useCORS: true,
            logging: false,
            windowWidth: input.scrollWidth,
            windowHeight: input.scrollHeight,
            onclone: (clonedDoc) => {
                clonedDoc.querySelectorAll('img').forEach(img => {
                    img.crossOrigin = "anonymous";
                    img.style.maxWidth = 'none';
                });
            }
        });

        // Restore original logo style
        logo.style.cssText = originalStyle;

        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);

        let heightLeft = imgHeight * ratio;
        let position = 2;

        pdf.addImage(imgData, 'JPEG', 0, position, imgWidth * ratio, imgHeight * ratio);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
            position = -pageHeight * pdf.internal.getNumberOfPages();
            pdf.addPage();
            pdf.addImage(imgData, 'JPEG', 0, position, imgWidth * ratio, imgHeight * ratio);
            heightLeft -= pageHeight;
        }

        pdf.save(`GoSeeProgram_${data.sequenceNo || "Not_Generated"}.pdf`);
    };

    if (!data) return null;

    const renderValue = (value) => {
        if (value === undefined || value === null) return 'N/A';
        if (typeof value === 'boolean') return value ? '✅' : '❌';
        return value;
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="xl" fullscreen>
            <ModalHeader toggle={toggle}>
                <div className="d-flex justify-content-between align-items-center w-100">
                    <span style={{ marginRight: '10px' }}>View Go See Program</span>
                    <Button color="primary" onClick={generatePDF} style={{ marginLeft: 'auto' }}>
                        <FilePdfOutlined /> Download PDF
                    </Button>
                </div>
            </ModalHeader>
            <ModalBody>
                <div ref={contentRef} id="details">
                    <div className="d-flex justify-content-between align-items-start mb-4">
                        <h3>Go See Program No. : {renderValue(data.sequenceNo)}</h3>
                        <div style={{
                            borderRadius: '5px'
                        }}>
                            <img
                                id="meinhardtLogo"
                                src={meinhardtlogo}
                                alt="Meinhardt Logo"
                                style={{maxWidth: '150px', height: 'auto'}}
                                crossOrigin="anonymous"
                            />
                        </div>
                    </div>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Admin Details</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={3}><strong>Site:</strong></Col>
                                <Col md={9}>{renderValue(data.site)}</Col>
                                <Col md={3}><strong>L3 Contractor:</strong></Col>
                                <Col md={9}>{renderValue(data.l3Contractor)}</Col>
                                <Col md={3}><strong>Location Place:</strong></Col>
                                <Col md={9}>{renderValue(data.location)}</Col>
                                <Col md={3}><strong>Reported At:</strong></Col>
                                <Col md={9}>{renderValue(new Date(data.reportedAt).toLocaleString())}</Col>
                                <Col md={3}><strong>Reported By:</strong></Col>
                                <Col md={9}>{renderValue(data.reportedByUser)}</Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Go See Program Details</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={3}><strong>Intervention Type:</strong></Col>
                                <Col md={9}>{renderValue(data.interventionType)}</Col>
                                {data.interventionTypeOther && (
                                    <>
                                        <Col md={3}><strong>Other Intervention:</strong></Col>
                                        <Col md={9}>{renderValue(data.interventionTypeOther)}</Col>
                                    </>
                                )}
                                <Col md={3}><strong>Main Description:</strong></Col>
                                <Col md={9}>{renderValue(data.icMainDescription)}</Col>
                                <Col md={3}><strong>Description:</strong></Col>
                                <Col md={9}>{renderValue(data.icDescription)}</Col>
                                <Col md={12}><strong>What three (3) have you made this week?</strong></Col>
                                <Col md={4}>1. {renderValue(data.made1)}</Col>
                                <Col md={4}>2. {renderValue(data.made2)}</Col>
                                <Col md={4}>3. {renderValue(data.made3)}</Col>
                                <Col md={3}><strong>Date of Go See Intervention:</strong></Col>
                                <Col md={9}>{renderValue(new Date(data.gsiAt).toLocaleDateString())}</Col>
                                <Col md={12}>
                                    <strong>Intervention Photo:</strong>
                                    <img
                                        src={data.icFullPath}
                                        alt="Intervention"
                                        style={{ maxWidth: '100%', marginTop: '10px' }}
                                        crossOrigin="anonymous"
                                    />
                                </Col>

                                <Col md={12} className="mt-4">
                                    <strong>Signatures:</strong>
                                    {(data.signs || []).map((sign, index) => (
                                        <Row key={index} className="mt-3">
                                            <Col md={6}>
                                                <strong>Submitted By Sign:</strong>
                                                <img
                                                    src={sign.submittedFullPath}
                                                    alt="Submitted Signature"
                                                    style={{ maxWidth: '100%', border: '1px solid #ddd', marginTop: '5px' }}
                                                    crossOrigin="anonymous"
                                                />
                                                <p>Submitted at: {renderValue(new Date(sign.createdAt).toLocaleString())}</p>
                                            </Col>
                                            <Col md={6}>
                                                <strong>Reviewed By Sign:</strong>
                                                <img
                                                    src={sign.reviewedFullPath}
                                                    alt="Reviewed Signature"
                                                    style={{ maxWidth: '100%', border: '1px solid #ddd', marginTop: '5px' }}
                                                    crossOrigin="anonymous"
                                                />
                                                <p>Reviewed at: {sign.updatedAt ? renderValue(new Date(sign.updatedAt).toLocaleString()) : 'Not reviewed yet'}</p>
                                            </Col>
                                        </Row>
                                    ))}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Actions</h4>
                        </CardHeader>
                        <CardBody>
                            <Table striped>
                                <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Created By</th>
                                    <th>Created For</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {(data.actions || []).map((action, index) => (
                                    <tr key={index}>
                                        <td>{renderValue(action.type)}</td>
                                        <td>{renderValue(action.createdByUser)}</td>
                                        <td>{renderValue(action.createdFor)}</td>
                                        <td>{renderValue(action.description)}</td>
                                        <td>{renderValue(action.status)}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Attachments</h4>
                        </CardHeader>
                        <CardBody>
                            {(data.files || []).map((file, index) => renderAttachment(file, index))}
                        </CardBody>
                    </Card>
                </div>
            </ModalBody>
        </Modal>
    );
};
const ViewHSSEAudit = ({ isOpen, toggle, data, instructions }) => {
    const contentRef = useRef(null);

    const generatePDF = async () => {
        const input = contentRef.current;

        const logo = input.querySelector('#meinhardtLogo');
        const originalStyle = logo.style.cssText;
        logo.style.maxWidth = '250px';
        logo.style.height = '250px';

        const canvas = await html2canvas(input, {
            scale: 2,
            useCORS: true,
            logging: false,
            windowWidth: input.scrollWidth,
            windowHeight: input.scrollHeight,
            onclone: (clonedDoc) => {
                clonedDoc.querySelectorAll('img').forEach(img => {
                    img.crossOrigin = "anonymous";
                    img.style.maxWidth = 'none';
                });
            }
        });

        // Restore original logo style
        logo.style.cssText = originalStyle;

        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);

        let heightLeft = imgHeight * ratio;
        let position = 2;

        pdf.addImage(imgData, 'JPEG', 0, position, imgWidth * ratio, imgHeight * ratio);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
            position = -pageHeight * pdf.internal.getNumberOfPages();
            pdf.addPage();
            pdf.addImage(imgData, 'JPEG', 0, position, imgWidth * ratio, imgHeight * ratio);
            heightLeft -= pageHeight;
        }

        pdf.save(`HSSEAudit_${data.sequenceNo || "Not_Generated"}.pdf`);
    };

    if (!data) return null;

    const renderValue = (value) => {
        if (value === undefined || value === null) return 'N/A';
        if (typeof value === 'boolean') return value ? '✅' : '❌';
        return value;
    };

    const renderFindings = () => {
        const findingsMap = new Map();

        instructions.forEach(instruction => {
            instruction.children.forEach(child => {
                findingsMap.set(child.id, { parent: instruction.label, child: child.label });
            });
        });

        const groupedFindings = (data.findings || []).reduce((acc, finding) => {
            const parentInfo = findingsMap.get(finding.itemNo);
            if (parentInfo) {
                if (!acc[parentInfo.parent]) {
                    acc[parentInfo.parent] = [];
                }
                acc[parentInfo.parent].push({ ...finding, childLabel: parentInfo.child });
            }
            return acc;
        }, {});

        return Object.entries(groupedFindings).map(([parent, findings]) => (
            <Card key={parent} className="mb-3">
                <CardHeader>
                    <h4>{parent}</h4>
                </CardHeader>
                <CardBody>
                    {(findings || []).map((finding, index) => (
                        <div key={index} className="mb-3">
                            <h5>{finding.childLabel}</h5>
                            <p><strong>Description:</strong> {renderValue(finding.description)}</p>
                            <p><strong>Priority:</strong> {renderValue(finding.priority)}</p>
                            {finding.recommendation && <p><strong>Recommendation:</strong> {renderValue(finding.recommendation)}</p>}
                        </div>
                    ))}
                </CardBody>
            </Card>
        ));
    };

    const renderInstructions = () => {
        return instructions.map((instruction) => {
            const relevantChildren = instruction.children.filter(child => (data.audits || []).includes(child.id));

            if (relevantChildren.length === 0) {
                return null;
            }

            return (
                <Card key={instruction.id} className="mb-3">
                    <CardHeader>
                        <h5>{instruction.label}</h5>
                    </CardHeader>
                    <CardBody>
                        <ul className="list-unstyled">
                            {relevantChildren.map((child) => (
                                <li key={child.id} className="d-flex align-items-center mb-2">
                                    <span className="mr-2">
                                        <CheckCircleFilled style={{ color: 'green' }} />
                                    </span>
                                    {child.label}
                                </li>
                            ))}
                        </ul>
                    </CardBody>
                </Card>
            );
        }).filter(Boolean);
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="xl" fullscreen>
            <ModalHeader toggle={toggle}>
                <div className="d-flex justify-content-between align-items-center w-100">
                    <span style={{ marginRight: '10px' }}>View HSSE Audit</span>
                    <Button color="primary" onClick={generatePDF} style={{ marginLeft: 'auto' }}>
                        <FilePdfOutlined /> Download PDF
                    </Button>
                </div>
            </ModalHeader>
            <ModalBody>
                <div ref={contentRef} id="details">
                    <div className="d-flex justify-content-between align-items-start mb-4">
                        <h3>HSSE Audit No. : {renderValue(data.sequenceNo)}</h3>
                        <div style={{
                            borderRadius: '5px'
                        }}>
                            <img
                                id="meinhardtLogo"
                                src={meinhardtlogo}
                                alt="Meinhardt Logo"
                                style={{maxWidth: '150px', height: 'auto'}}
                                crossOrigin="anonymous"
                            />
                        </div>
                    </div>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Admin Details</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={3}><strong>Site:</strong></Col>
                                <Col md={9}>{renderValue(data.site)}</Col>
                                <Col md={3}><strong>L3 Contractor:</strong></Col>
                                <Col md={9}>{renderValue(data.l3Contractor)}</Col>
                                <Col md={3}><strong>Location:</strong></Col>
                                <Col md={9}>{renderValue(data.location)}</Col>
                                <Col md={3}><strong>Reported At:</strong></Col>
                                <Col md={9}>{renderValue(new Date(data.reportedAt).toLocaleString())}</Col>
                                <Col md={3}><strong>Reported By:</strong></Col>
                                <Col md={9}>{renderValue(data.reportedByUser)}</Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>HSSE Audit Details</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={3}><strong>Region:</strong></Col>
                                <Col md={9}>{renderValue(data.region)}</Col>
                                <Col md={3}><strong>Business Unit:</strong></Col>
                                <Col md={9}>{renderValue(data.businessUnit)}</Col>
                                <Col md={3}><strong>Auditor:</strong></Col>
                                <Col md={9}>{renderValue(data.auditor)}</Col>
                                <Col md={3}><strong>Audited At:</strong></Col>
                                <Col md={9}>{renderValue(new Date(data.auditedAt).toLocaleString())}</Col>
                                <Col md={3}><strong>Site Manager:</strong></Col>
                                <Col md={9}>{renderValue(data.siteManagerName)}</Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Instructions to Auditor</h4>
                        </CardHeader>
                        <CardBody>
                            {renderInstructions()}
                        </CardBody>
                    </Card>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Findings</h4>
                        </CardHeader>
                        <CardBody>
                            {renderFindings()}
                        </CardBody>
                    </Card>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Actions</h4>
                        </CardHeader>
                        <CardBody>
                            <Table striped>
                                <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Created By</th>
                                    <th>Created For</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {(data.actions || []).map((action, index) => (
                                    <tr key={index}>
                                        <td>{renderValue(action.type)}</td>
                                        <td>{renderValue(action.createdByUser)}</td>
                                        <td>{renderValue(action.createdFor)}</td>
                                        <td>{renderValue(action.description)}</td>
                                        <td>{renderValue(action.status)}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Signatures</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    <strong>Site Manager Sign:</strong>
                                    <img
                                        src={data.siteManagerFullSign}
                                        alt="Site Manager Signature"
                                        style={{ maxWidth: '100%', border: '1px solid #ddd', marginTop: '5px' }}
                                        crossOrigin="anonymous"
                                    />
                                </Col>
                                <Col md={6}>
                                    <strong>Auditor Sign:</strong>
                                    <img
                                        src={data.auditorFullSign}
                                        alt="Auditor Signature"
                                        style={{ maxWidth: '100%', border: '1px solid #ddd', marginTop: '5px' }}
                                        crossOrigin="anonymous"
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Attachments</h4>
                        </CardHeader>
                        <CardBody>
                            {(data.files || []).map((file, index) => renderAttachment(file, index))}
                        </CardBody>
                    </Card>
                </div>
            </ModalBody>
        </Modal>
    );
};
const LOD1AssuranceView = ({ isOpen, toggle, data, instructions }) => {
    const contentRef = useRef(null);

    const generatePDF = async () => {
        const input = contentRef.current;

        const logo = input.querySelector('#meinhardtLogo');
        const originalStyle = logo.style.cssText;
        logo.style.maxWidth = '250px';
        logo.style.height = '250px';

        const canvas = await html2canvas(input, {
            scale: 2,
            useCORS: true,
            logging: false,
            windowWidth: input.scrollWidth,
            windowHeight: input.scrollHeight,
            onclone: (clonedDoc) => {
                clonedDoc.querySelectorAll('img').forEach(img => {
                    img.crossOrigin = "anonymous";
                    img.style.maxWidth = 'none';
                });
            }
        });

        // Restore original logo style
        logo.style.cssText = originalStyle;

        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);

        let heightLeft = imgHeight * ratio;
        let position = 2;

        pdf.addImage(imgData, 'JPEG', 0, position, imgWidth * ratio, imgHeight * ratio);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
            position = -pageHeight * pdf.internal.getNumberOfPages();
            pdf.addPage();
            pdf.addImage(imgData, 'JPEG', 0, position, imgWidth * ratio, imgHeight * ratio);
            heightLeft -= pageHeight;
        }

        pdf.save(`LOD1Assurance_${data.sequenceNo || "Not_Generated"}.pdf`);
    };

    if (!data) return null;

    const renderValue = (value) => {
        if (value === undefined || value === null) return 'N/A';
        if (typeof value === 'boolean') return value ? 'Yes' : 'No';
        return value;
    };


    const renderRatings = () => {
        const ratingsMap = new Map();

        instructions.forEach(instruction => {
            instruction.children.forEach(child => {
                ratingsMap.set(child.id, { parent: instruction.label, child: child.label });
            });
        });

        const groupedRatings = data.ratings.reduce((acc, rating) => {
            const [itemNo, ratingValue] = rating.split(':').map(item => item.trim());
            const parentInfo = ratingsMap.get(itemNo);
            if (parentInfo) {
                if (!acc[parentInfo.parent]) {
                    acc[parentInfo.parent] = [];
                }
                acc[parentInfo.parent].push({ itemNo, ratingValue, childLabel: parentInfo.child });
            }
            return acc;
        }, {});

        return Object.entries(groupedRatings).map(([parent, ratings]) => (
            <Card key={parent} className="mb-3">
                <CardHeader>
                    <h4>{parent}</h4>
                </CardHeader>
                <CardBody>
                    {(ratings || []).map((rating, index) => (
                        <div key={index} className="mb-2">
                            <CheckCircleFilled style={{ color: 'green' }} />
                            <strong>{rating.childLabel}:</strong> {renderValue(rating.ratingValue)}
                        </div>
                    ))}
                </CardBody>
            </Card>
        ));

    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="xl" fullscreen>
            <ModalHeader toggle={toggle}>
                <div className="d-flex justify-content-between align-items-center w-100">
                    <span style={{ marginRight: '10px' }}>View LOD1 Assurance</span>
                    <Button color="primary" onClick={generatePDF} style={{ marginLeft: 'auto' }}>
                        <FilePdfOutlined /> Download PDF
                    </Button>
                </div>
            </ModalHeader>
            <ModalBody>
                <div ref={contentRef} id="details">
                    <div className="d-flex justify-content-between align-items-start mb-4">
                        <h3>LOD1 Assurance No. : {renderValue(data.sequenceNo)}</h3>
                        <div style={{
                            borderRadius: '5px'
                        }}>
                            <img
                                id="meinhardtLogo"
                                src={meinhardtlogo}
                                alt="Meinhardt Logo"
                                style={{maxWidth: '150px', height: 'auto'}}
                                crossOrigin="anonymous"
                            />
                        </div>
                    </div>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Admin Details</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={3}><strong>Site:</strong></Col>
                                <Col md={9}>{renderValue(data.site)}</Col>
                                <Col md={3}><strong>L3 Contractor:</strong></Col>
                                <Col md={9}>{renderValue(data.l3Contractor)}</Col>
                                <Col md={3}><strong>Location:</strong></Col>
                                <Col md={9}>{renderValue(data.location)}</Col>
                                <Col md={3}><strong>Reported At:</strong></Col>
                                <Col md={9}>{renderValue(new Date(data.reportedAt).toLocaleString())}</Col>
                                <Col md={3}><strong>Reported By:</strong></Col>
                                <Col md={9}>{renderValue(data.reportedByUser)}</Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Assurance Details</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={3}><strong>Business Unit:</strong></Col>
                                <Col md={9}>{renderValue(data.businessUnit)}</Col>
                                <Col md={3}><strong>Permit Number:</strong></Col>
                                <Col md={9}>{renderValue(data.permitNumber)}</Col>
                                <Col md={3}><strong>Permit Issued At:</strong></Col>
                                <Col md={9}>{renderValue(new Date(data.permitIssuedAt).toLocaleString())}</Col>
                                <Col md={3}><strong>Permit Issuer:</strong></Col>
                                <Col md={9}>{renderValue(data.permitIssuer)}</Col>
                                <Col md={3}><strong>Permit Holder:</strong></Col>
                                <Col md={9}>{renderValue(data.permitHolder)}</Col>
                                <Col md={3}><strong>Assessor Name:</strong></Col>
                                <Col md={9}>{renderValue(data.accessorName)}</Col>
                                <Col md={3}><strong>Site Supervisor:</strong></Col>
                                <Col md={9}>{renderValue(data.siteSupervisor)}</Col>
                                <Col md={3}><strong>Copy To Client:</strong></Col>
                                <Col md={9}>{renderValue(data.copyToClient)}</Col>
                                <Col md={3}><strong>Audited At:</strong></Col>
                                <Col md={9}>{renderValue(new Date(data.auditedAt).toLocaleString())}</Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Ratings</h4>
                        </CardHeader>
                        <CardBody>
                            {renderRatings()}
                        </CardBody>
                    </Card>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Findings</h4>
                        </CardHeader>
                        <CardBody>
                            {(data.findings || []).map((finding, index) => (
                                <div key={index} className="mb-3">
                                    <h5>Finding {index + 1}</h5>
                                    <p><strong>Description:</strong> {renderValue(finding.description)}</p>
                                    <p><strong>Evidence:</strong> {renderValue(finding.evidence)}</p>
                                    {finding.severity && <p><strong>Severity:</strong> {renderValue(finding.severity)}</p>}
                                </div>
                            ))}
                        </CardBody>
                    </Card>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Actions</h4>
                        </CardHeader>
                        <CardBody>
                            <Table striped>
                                <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Created By</th>
                                    <th>Created For</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {(data.actions || []).map((action, index) => (
                                    <tr key={index}>
                                        <td>{renderValue(action.type)}</td>
                                        <td>{renderValue(action.createdByUser)}</td>
                                        <td>{renderValue(action.createdFor)}</td>
                                        <td>{renderValue(action.description)}</td>
                                        <td>{renderValue(action.status)}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Signature</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    <strong>Auditor Sign:</strong>
                                    <img
                                        src={data.auditorFullSign}
                                        alt="Auditor Signature"
                                        style={{ maxWidth: '100%', border: '1px solid #ddd', marginTop: '5px' }}
                                        crossOrigin="anonymous"
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Attachments</h4>
                        </CardHeader>
                        <CardBody>
                            {(data.files || []).map((file, index) => renderAttachment(file, index))}
                        </CardBody>
                    </Card>
                </div>
            </ModalBody>
        </Modal>
    );
};
const L2WeeklyViewModal = ({ isOpen, toggle, data }) => {
    const contentRef = useRef(null);

    const generatePDF = async () => {
        const input = contentRef.current;

        const logo = input.querySelector('#meinhardtLogo');
        const originalStyle = logo.style.cssText;
        logo.style.maxWidth = '250px';
        logo.style.height = '250px';

        const canvas = await html2canvas(input, {
            scale: 2,
            useCORS: true,
            logging: false,
            windowWidth: input.scrollWidth,
            windowHeight: input.scrollHeight,
            onclone: (clonedDoc) => {
                clonedDoc.querySelectorAll('img').forEach(img => {
                    img.crossOrigin = "anonymous";
                    img.style.maxWidth = 'none';
                });
            }
        });

        // Restore original logo style
        logo.style.cssText = originalStyle;

        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);

        let heightLeft = imgHeight * ratio;
        let position = 2;

        pdf.addImage(imgData, 'JPEG', 0, position, imgWidth * ratio, imgHeight * ratio);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
            position = -pageHeight * pdf.internal.getNumberOfPages();
            pdf.addPage();
            pdf.addImage(imgData, 'JPEG', 0, position, imgWidth * ratio, imgHeight * ratio);
            heightLeft -= pageHeight;
        }

        pdf.save(`L2WeeklyReport_${data.sequenceNo || "Not_Generated"}.pdf`);
    };

    if (!data) return null;

    const renderValue = (value) => {
        if (value === undefined || value === null) return 'N/A';
        if (typeof value === 'boolean') return value ? 'Yes' : 'No';
        return value;
    };

    const renderSection = (title, items) => (
        <Card className="mb-3">
            <CardHeader>
                <h4>{title}</h4>
            </CardHeader>
            <CardBody>
                <Row>
                    {items.map(({ label, field }) => (
                        <Col key={field} md={4} className="mb-3">
                            <strong>{label}:</strong> {renderValue(data[field])}
                        </Col>
                    ))}
                </Row>
            </CardBody>
        </Card>
    );

    const exposureHoursItems = [
        { label: '1A. Target Hours', field: 'R1_1A' },
        { label: '1B. Actual Hours', field: 'R1_1B' },
        { label: '2. KMS driven', field: 'R1_2' },
        { label: "3A. NMPI's Identified", field: 'R1_3A' },
        { label: '4A. LOD1 Audits Conducted Separately', field: 'R1_4A' },
        { label: '4B. LOD1 Audits Conducted Combined With Contractor', field: 'R1_4B' },
        { label: '4C. Who was Co-Auditor ?', field: 'R1_4C' },
        { label: '5.TBT Attended', field: 'R1_5' },
        { label: '6. Number Of BLUE ZONE GO SEE CARD Issued', field: 'R1_6' },
    ];

    const permitsIssuedItems = [
        { label: 'A. Excavation', field: 'R2_A' },
        { label: 'B. Confined Space Entry', field: 'R2_B' },
        { label: 'C. Working At Height', field: 'R2_C' },
        { label: 'D. Hot Work', field: 'R2_D' },
        { label: 'E. Excavation And Confined Space', field: 'R2_E' },
        { label: 'F. Confined Space And Working At Height', field: 'R2_F' },
        { label: 'G. Excavation Confined Space And Working At Height', field: 'R2_G' },
        { label: 'H. Others', field: 'R2_H' },
    ];

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="xl" fullscreen>
            <ModalHeader toggle={toggle}>
                <div className="d-flex justify-content-between align-items-center w-100">
                    <span style={{ marginRight: '10px' }}>View L2 Weekly Report</span>
                    <Button color="primary" onClick={generatePDF} style={{ marginLeft: 'auto' }}>
                        <FilePdfOutlined /> Download PDF
                    </Button>
                </div>
            </ModalHeader>
            <ModalBody>
                <div ref={contentRef} id="details">
                    <div className="d-flex justify-content-between align-items-start mb-4">
                        <h3>L2 Weekly Report No. : {renderValue(data.sequenceNo)}</h3>
                        <div style={{
                            borderRadius: '5px'
                        }}>
                            <img
                                id="meinhardtLogo"
                                src={meinhardtlogo}
                                alt="Meinhardt Logo"
                                style={{maxWidth: '150px', height: 'auto'}}
                                crossOrigin="anonymous"
                            />
                        </div>
                    </div>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Admin Details</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={4} className="mb-3"><strong>Sequence No:</strong> {renderValue(data.sequenceNo)}</Col>
                                <Col md={4} className="mb-3"><strong>Site:</strong> {renderValue(data.site)}</Col>
                                <Col md={4} className="mb-3"><strong>L3 Contractor:</strong> {renderValue(data.l3Contractor)}</Col>
                                <Col md={4} className="mb-3"><strong>Project:</strong> {renderValue(data.project)}</Col>
                                <Col md={4} className="mb-3"><strong>Business Unit:</strong> {renderValue(data.businessUnit)}</Col>
                                <Col md={4} className="mb-3"><strong>Location:</strong> {renderValue(`${data.city}, ${data.state}, ${data.country}`)}</Col>
                                <Col md={4} className="mb-3"><strong>Reported At:</strong> {renderValue(new Date(data.reportedAt).toLocaleString())}</Col>
                                <Col md={4} className="mb-3"><strong>Reported By:</strong> {renderValue(data.reportedByUser)}</Col>
                                <Col md={4} className="mb-3"><strong>Status:</strong> {renderValue(data.status ? 'Active' : 'Inactive')}</Col>
                            </Row>
                        </CardBody>
                    </Card>

                    {renderSection('1. Exposure Hours (EH)', exposureHoursItems)}
                    {renderSection('2. Permits issued', permitsIssuedItems)}

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Actions</h4>
                        </CardHeader>
                        <CardBody>
                            <Table striped responsive>
                                <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Status</th>
                                    <th>Created For</th>
                                    <th>Description</th>
                                    <th>Target Date</th>
                                    <th>Created By</th>
                                    <th>Created At</th>
                                </tr>
                                </thead>
                                <tbody>
                                {(data.actions || []).map((action, index) => (
                                    <tr key={index}>
                                        <td>{renderValue(action.type)}</td>
                                        <td>{renderValue(action.status)}</td>
                                        <td>{renderValue(action.createdFor)}</td>
                                        <td>{renderValue(action.description)}</td>
                                        <td>{renderValue(new Date(action.targetAt).toLocaleDateString())}</td>
                                        <td>{renderValue(action.createdByUser)}</td>
                                        <td>{renderValue(new Date(action.createdAt).toLocaleString())}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Attachments</h4>
                        </CardHeader>
                        <CardBody>
                            {(data.files || []).map((file, index) => renderAttachment(file, index))}
                        </CardBody>
                    </Card>
                </div>
            </ModalBody>
        </Modal>
    );
};
const WeeklyReportModal = ({ isOpen, toggle, data }) => {
    const contentRef = useRef(null);

    const generatePDF = async () => {
        const input = contentRef.current;

        const logo = input.querySelector('#meinhardtLogo');
        const originalStyle = logo.style.cssText;
        logo.style.maxWidth = '250px';
        logo.style.height = '250px';

        const canvas = await html2canvas(input, {
            scale: 2,
            useCORS: true,
            logging: false,
            windowWidth: input.scrollWidth,
            windowHeight: input.scrollHeight,
            onclone: (clonedDoc) => {
                clonedDoc.querySelectorAll('img').forEach(img => {
                    img.crossOrigin = "anonymous";
                    img.style.maxWidth = 'none';
                });
            }
        });

        // Restore original logo style
        logo.style.cssText = originalStyle;

        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);

        let heightLeft = imgHeight * ratio;
        let position = 2;

        pdf.addImage(imgData, 'JPEG', 0, position, imgWidth * ratio, imgHeight * ratio);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
            position = -pageHeight * pdf.internal.getNumberOfPages();
            pdf.addPage();
            pdf.addImage(imgData, 'JPEG', 0, position, imgWidth * ratio, imgHeight * ratio);
            heightLeft -= pageHeight;
        }

        pdf.save(`WeeklyReport_${data.sequenceNo || "Not_Generated"}.pdf`);
    };

    if (!data) return null;

    const renderValue = (value) => {
        if (value === undefined || value === null) return 'N/A';
        if (typeof value === 'boolean') return value ? 'Yes' : 'No';
        return value;
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="xl" fullscreen>
            <ModalHeader toggle={toggle}>
                <div className="d-flex justify-content-between align-items-center w-100">
                    <span style={{ marginRight: '10px' }}>View Weekly Report</span>
                    <Button color="primary" onClick={generatePDF} style={{ marginLeft: 'auto' }}>
                        <FilePdfOutlined /> Download PDF
                    </Button>
                </div>
            </ModalHeader>
            <ModalBody>
                <div ref={contentRef} id="details">
                    <div className="d-flex justify-content-between align-items-start mb-4">
                        <h3>Weekly Report No. : {renderValue(data.sequenceNo)}</h3>
                        <div style={{
                            borderRadius: '5px'
                        }}>
                            <img
                                id="meinhardtLogo"
                                src={meinhardtlogo}
                                alt="Meinhardt Logo"
                                style={{maxWidth: '150px', height: 'auto'}}
                                crossOrigin="anonymous"
                            />
                        </div>
                    </div>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Admin Details</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={4} className="mb-3"><strong>Sequence No:</strong> {renderValue(data.sequenceNo)}</Col>
                                <Col md={4} className="mb-3"><strong>Site:</strong> {renderValue(data.site)}</Col>
                                <Col md={4} className="mb-3"><strong>L3 Contractor:</strong> {renderValue(data.l3Contractor)}</Col>
                                <Col md={4} className="mb-3"><strong>Project:</strong> {renderValue(data.project)}</Col>
                                <Col md={4} className="mb-3"><strong>Business Unit:</strong> {renderValue(data.businessUnit)}</Col>
                                <Col md={4} className="mb-3"><strong>Location:</strong> {renderValue(`${data.city}, ${data.state}, ${data.country}`)}</Col>
                                <Col md={4} className="mb-3"><strong>Reported At:</strong> {renderValue(new Date(data.reportedAt).toLocaleString())}</Col>
                                <Col md={4} className="mb-3"><strong>Reported By:</strong> {renderValue(data.reportedByUser)}</Col>
                                <Col md={4} className="mb-3"><strong>Status:</strong> {renderValue(data.status ? 'Active' : 'Inactive')}</Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Weekly Report Details</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={6} className="mb-3"><strong>CR Person Name:</strong> {renderValue(data.crPersonName)}</Col>
                                <Col md={6} className="mb-3"><strong>CR Contractor:</strong> {renderValue(data.l3Contractor)}</Col>
                                <Col md={6} className="mb-3"><strong>Work Done Period:</strong> {renderValue(data.workDonePeriod.join(', '))}</Col>
                            </Row>

                            <h5 className="mt-4">Sites</h5>
                            <Table striped responsive>
                                <thead>
                                <tr>
                                    <th>Region</th>
                                    <th>Site Name</th>
                                    <th>Program Type</th>
                                    <th>KMs Travelled</th>
                                    <th>NMPIs Reported</th>
                                    <th>BBSQ Interventions</th>
                                    <th>Site Exposure Hours</th>
                                    <th>SR Management Audit</th>
                                    <th>High Risk Activities</th>
                                    <th>Site Weekly Progress</th>
                                    <th>Contractor HSSE Audit</th>
                                    <th>NMPIs Raised on High Risk Activities</th>
                                </tr>
                                </thead>
                                <tbody>
                                {(data.sites || []).map((site, index) => (
                                    <tr key={index}>
                                        <td>{renderValue(site.region)}</td>
                                        <td>{renderValue(site.siteNeme)}</td>
                                        <td>{renderValue(site.programType)}</td>
                                        <td>{renderValue(site.kmsTravelled)}</td>
                                        <td>{renderValue(site.nmpisReported)}</td>
                                        <td>{renderValue(site.bbsqInterventions)}</td>
                                        <td>{renderValue(site.siteExposureHours)}</td>
                                        <td>{renderValue(site.srManagementAudit)}</td>
                                        <td>{renderValue(site.highRiskActivities)}</td>
                                        <td>{renderValue(site.siteWeeklyProgress)}</td>
                                        <td>{renderValue(site.contractorHsseAudit)}</td>
                                        <td>{renderValue(site.nmpisRaisedOnHighRiskActivities)}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Actions</h4>
                        </CardHeader>
                        <CardBody>
                            <Table striped responsive>
                                <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Status</th>
                                    <th>Created For</th>
                                    <th>Description</th>
                                    <th>Target Date</th>
                                    <th>Created By</th>
                                    <th>Created At</th>
                                </tr>
                                </thead>
                                <tbody>
                                {(data.actions || []).map((action, index) => (
                                    <tr key={index}>
                                        <td>{renderValue(action.type)}</td>
                                        <td>{renderValue(action.status)}</td>
                                        <td>{renderValue(action.createdFor)}</td>
                                        <td>{renderValue(action.description)}</td>
                                        <td>{renderValue(new Date(action.targetAt).toLocaleDateString())}</td>
                                        <td>{renderValue(action.createdByUser)}</td>
                                        <td>{renderValue(new Date(action.createdAt).toLocaleString())}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </div>
                <Card className="mb-3">
                    <CardHeader>
                        <h4>Attachments</h4>
                    </CardHeader>
                    <CardBody>
                        {(data.files || []).map((file, index) => renderAttachment(file, index))}
                    </CardBody>
                </Card>
            </ModalBody>
        </Modal>
    );
};
const ToolBoxTalkView = ({ isOpen, toggle, data, tbDiscussionOptions }) => {
    const contentRef = useRef(null);

    const generatePDF = async () => {
        const input = contentRef.current;

        const logo = input.querySelector('#meinhardtLogo');
        const originalStyle = logo.style.cssText;
        logo.style.maxWidth = '250px';
        logo.style.height = '250px';

        const canvas = await html2canvas(input, {
            scale: 2,
            useCORS: true,
            logging: false,
            windowWidth: input.scrollWidth,
            windowHeight: input.scrollHeight,
            onclone: (clonedDoc) => {
                clonedDoc.querySelectorAll('img').forEach(img => {
                    img.crossOrigin = "anonymous";
                    img.style.maxWidth = 'none';
                });
            }
        });

        // Restore original logo style
        logo.style.cssText = originalStyle;

        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);

        let heightLeft = imgHeight * ratio;
        let position = 2;

        pdf.addImage(imgData, 'JPEG', 0, position, imgWidth * ratio, imgHeight * ratio);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
            position = -pageHeight * pdf.internal.getNumberOfPages();
            pdf.addPage();
            pdf.addImage(imgData, 'JPEG', 0, position, imgWidth * ratio, imgHeight * ratio);
            heightLeft -= pageHeight;
        }

        pdf.save(`ToolBoxTalk_${data.sequenceNo || "Not_Generated"}.pdf`);
    };

    if (!data) return null;

    const renderValue = (value) => {
        if (value === undefined || value === null) return 'N/A';
        if (typeof value === 'boolean') return value ? 'Yes' : 'No';
        return value;
    };



    return (
        <Modal isOpen={isOpen} toggle={toggle} size="xl" fullscreen>
            <ModalHeader toggle={toggle}>
                <div className="d-flex justify-content-between align-items-center w-100">
                    <span style={{ marginRight: '10px' }}>View ToolBox Talk</span>
                    <Button color="primary" onClick={generatePDF} style={{ marginLeft: 'auto' }}>
                        <FilePdfOutlined /> Download PDF
                    </Button>
                </div>
            </ModalHeader>
            <ModalBody>
                <div ref={contentRef} id="details">
                    <div className="d-flex justify-content-between align-items-start mb-4">
                        <h3>ToolBox Talk No. : {renderValue(data.sequenceNo)}</h3>
                        <div style={{
                            borderRadius: '5px'
                        }}>
                            <img
                                id="meinhardtLogo"
                                src={meinhardtlogo}
                                alt="Meinhardt Logo"
                                style={{maxWidth: '150px', height: 'auto'}}
                                crossOrigin="anonymous"
                            />
                        </div>
                    </div>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Admin Details</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={4} className="mb-3"><strong>Sequence No:</strong> {renderValue(data.sequenceNo)}</Col>
                                <Col md={4} className="mb-3"><strong>Site:</strong> {renderValue(data.site)}</Col>
                                <Col md={4} className="mb-3"><strong>L3 Contractor:</strong> {renderValue(data.l3Contractor)}</Col>
                                <Col md={4} className="mb-3"><strong>Project:</strong> {renderValue(data.project)}</Col>
                                <Col md={4} className="mb-3"><strong>Business Unit:</strong> {renderValue(data.businessUnit)}</Col>
                                <Col md={4} className="mb-3"><strong>Location:</strong> {renderValue(`${data.city}, ${data.state}, ${data.country}`)}</Col>
                                <Col md={4} className="mb-3"><strong>Reported At:</strong> {renderValue(new Date(data.reportedAt).toLocaleString())}</Col>
                                <Col md={4} className="mb-3"><strong>Reported By:</strong> {renderValue(data.reportedByUser)}</Col>
                                <Col md={4} className="mb-3"><strong>Status:</strong> {renderValue(data.status ? 'Active' : 'Inactive')}</Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>ToolBox Details</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={12} className="mb-3"><strong>Discussion Points:</strong> {renderValue(data.discussionPoints)}</Col>
                                <Col md={6} className="mb-3"><strong>Permit Work:</strong> {renderValue(data.permitWork)}</Col>
                                <Col md={6} className="mb-3"><strong>Site PH Name:</strong> {renderValue(data.sitePHName)}</Col>
                            </Row>

                            <h5>ToolBox Discussions</h5>
                            {(data.tbDiscussions || []).map((id) => {
                                const discussion = tbDiscussionOptions.find(option => option.id === id);
                                return discussion && (
                                    <div key={id} className="mb-2">
                                        {renderValue(discussion.label)}
                                    </div>
                                );
                            })}

                            <h5 className="mt-4">ToolBox Effectiveness</h5>
                            <Table striped responsive>
                                <thead>
                                <tr>
                                    <th>Effectiveness</th>
                                    <th>Improvement Area</th>
                                </tr>
                                </thead>
                                <tbody>
                                {(data.effectives || []).map((effective, index) => (
                                    <tr key={index}>
                                        <td>{renderValue(effective.effectiveness)}</td>
                                        <td>{renderValue(effective.improvementArea)}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>

                            <h5 className="mt-4">Attached Files</h5>
                            {(data.files || []).map((file, index) => (
                                <div key={index} className="mb-2">
                                    <a href={file.fullPath} target="_blank" rel="noopener noreferrer">{renderValue(file.fileName)}</a>
                                    {file.description && <span> - {renderValue(file.description)}</span>}
                                </div>
                            ))}
                        </CardBody>
                    </Card>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Actions</h4>
                        </CardHeader>
                        <CardBody>
                            <Table striped responsive>
                                <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Status</th>
                                    <th>Created For</th>
                                    <th>Description</th>
                                    <th>Target Date</th>
                                    <th>Created By</th>
                                    <th>Created At</th>
                                </tr>
                                </thead>
                                <tbody>
                                {(data.actions || []).map((action, index) => (
                                    <tr key={index}>
                                        <td>{renderValue(action.type)}</td>
                                        <td>{renderValue(action.status)}</td>
                                        <td>{renderValue(action.createdFor)}</td>
                                        <td>{renderValue(action.description)}</td>
                                        <td>{renderValue(new Date(action.targetAt).toLocaleDateString())}</td>
                                        <td>{renderValue(action.createdByUser)}</td>
                                        <td>{renderValue(new Date(action.createdAt).toLocaleString())}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Attachments</h4>
                        </CardHeader>
                        <CardBody>
                            {(data.files || []).map((file, index) => renderAttachment(file, index))}
                        </CardBody>
                    </Card>
                </div>
            </ModalBody>
        </Modal>
    );
};

const ViewNCRSection = ({ isOpen, toggle, selectedRecord, ncrValues }) => {
    console.log("selectedRecord")
    console.log(selectedRecord)
    const contentRef = useRef(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (selectedRecord && ncrValues) {
            setLoading(false);
        }
    }, [selectedRecord, ncrValues]);

    const generatePDF = async () => {
        const input = contentRef.current;

        const logo = input.querySelector('#meinhardtLogo');
        const originalStyle = logo.style.cssText;
        logo.style.maxWidth = '250px';
        logo.style.height = '250px';

        const canvas = await html2canvas(input, {
            scale: 2,
            useCORS: true,
            logging: false,
            windowWidth: input.scrollWidth,
            windowHeight: input.scrollHeight,
            onclone: (clonedDoc) => {
                clonedDoc.querySelectorAll('img').forEach(img => {
                    img.crossOrigin = "anonymous";
                    img.style.maxWidth = 'none';
                });
            }
        });

        // Restore original logo style
        logo.style.cssText = originalStyle;

        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);

        let heightLeft = imgHeight * ratio;
        let position = 2;

        pdf.addImage(imgData, 'JPEG', 0, position, imgWidth * ratio, imgHeight * ratio);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
            position = -pageHeight * pdf.internal.getNumberOfPages();
            pdf.addPage();
            pdf.addImage(imgData, 'JPEG', 0, position, imgWidth * ratio, imgHeight * ratio);
            heightLeft -= pageHeight;
        }

        pdf.save(`NCR_${selectedRecord.sequenceNo || "Not_Generated"}.pdf`);
    };

    const renderValue = (value) => {
        if (value === undefined || value === null) return 'N/A';
        if (typeof value === 'boolean') return value ? '✅' : '❌';
        return value;
    };


    const getCategoryLabel = (categoryId) => {
        const category = ncrValues.categories.find(cat => cat.id === categoryId);
        return category ? category.name : 'N/A';
    };

    const getSubcategoryLabel = (subcategoryId) => {
        const subcategory = ncrValues.subCategories.find(subcat => subcat.id === subcategoryId);
        return subcategory ? subcategory.name : 'N/A';
    };


    return (
        <Modal isOpen={isOpen} toggle={toggle} size="xl" fullscreen>
            <ModalHeader toggle={toggle}>
                <div className="d-flex justify-content-between align-items-center w-100">
                    <span style={{ marginRight: '10px' }}>View NCR</span>
                    <Button color="primary" onClick={generatePDF} style={{ marginLeft: 'auto' }}>
                        <FilePdfOutlined /> Download PDF
                    </Button>
                </div>
            </ModalHeader>
            <ModalBody>
                <div ref={contentRef} id="details">
                    <div className="d-flex justify-content-between align-items-start mb-4">
                        <h3>NCR No. : {renderValue(selectedRecord.sequenceNo)}</h3>
                        <div style={{ borderRadius: '5px' }}>
                            <img
                                id="meinhardtLogo"
                                src={meinhardtlogo}
                                alt="Meinhardt Logo"
                                style={{maxWidth: '150px', height: 'auto'}}
                                crossOrigin="anonymous"
                            />
                        </div>
                    </div>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Admin Details</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={3}><strong>Site:</strong></Col>
                                <Col md={9}>{renderValue(selectedRecord.site)}</Col>
                                <Col md={3}><strong>L3 Contractor:</strong></Col>
                                <Col md={9}>{renderValue(selectedRecord.l3Contractor)}</Col>
                                <Col md={3}><strong>Responded By:</strong></Col>
                                <Col md={9}>{renderValue(selectedRecord.respondedByUser)}</Col>
                                <Col md={3}><strong>Observed At:</strong></Col>
                                <Col md={9}>{renderValue(selectedRecord.observedAt ? new Date(selectedRecord.observedAt).toLocaleString() : 'N/A')}</Col>

                            </Row>
                        </CardBody>
                    </Card>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>NCR Details</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={3}><strong>MHT PM RESPONSIBLE:</strong></Col>
                                <Col md={9}>{renderValue(selectedRecord.mhtuser)}</Col>
                                <Col md={3}><strong>Region:</strong></Col>
                                <Col md={9}>{renderValue(selectedRecord.region)}</Col>
                                <Col md={3}><strong>Program Type :</strong></Col>
                                <Col md={9}>{renderValue(selectedRecord.programType)}</Col>
                                <Col md={3}><strong>Sub Program Type:</strong></Col>
                                <Col md={9}>{renderValue(selectedRecord.subProgram)}</Col>
                            </Row>
                            {loading ? (
                                <div className="text-center">
                                    <Spinner color="primary" /> Loading NCR Findings...
                                </div>
                            ) : selectedRecord.findings && selectedRecord.findings.length > 0 ? (
                                <Table striped>
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Category</th>
                                        <th>Subcategory</th>
                                        <th>Finding Details</th>
                                        <th>Actions Recommending</th>
                                        <th>Finding Importance</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {selectedRecord.findings.map((finding, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{renderValue(getCategoryLabel(finding.categoryId))}</td>
                                            <td>{renderValue(getSubcategoryLabel(finding.subcategoryId))}</td>
                                            <td>{renderValue(finding.findingDetails)}</td>
                                            <td>{renderValue(finding.actionsRecommending)}</td>
                                            <td>{renderValue(finding.findingImportance)}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <p>No NCR details available.</p>
                            )}
                        </CardBody>
                    </Card>



                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Actions</h4>
                        </CardHeader>
                        <CardBody>
                            <Table striped>
                                <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Created By</th>
                                    <th>Created For</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {selectedRecord.actions && selectedRecord.actions.map((action, index) => (
                                    <tr key={index}>
                                        <td>{renderValue(action.type)}</td>
                                        <td>{renderValue(action.createdByUser)}</td>
                                        <td>{renderValue(action.createdFor)}</td>
                                        <td>{renderValue(action.description)}</td>
                                        <td>{renderValue(action.status)}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Attachments</h4>
                        </CardHeader>
                        <CardBody>
                            {selectedRecord.files && selectedRecord.files.map((file, index) => renderAttachment(file, index))}
                        </CardBody>
                    </Card>
                </div>
            </ModalBody>
        </Modal>
    );
};
const ViewINCRSection = ({ isOpen, toggle, selectedRecord, ncrValues }) => {
    console.log("selectedRecord")
    console.log(selectedRecord)
    const contentRef = useRef(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (selectedRecord && ncrValues) {
            setLoading(false);
        }
    }, [selectedRecord, ncrValues]);

    const generatePDF = async () => {
        const input = contentRef.current;

        const logo = input.querySelector('#meinhardtLogo');
        const originalStyle = logo.style.cssText;
        logo.style.maxWidth = '250px';
        logo.style.height = '250px';

        const canvas = await html2canvas(input, {
            scale: 2,
            useCORS: true,
            logging: false,
            windowWidth: input.scrollWidth,
            windowHeight: input.scrollHeight,
            onclone: (clonedDoc) => {
                clonedDoc.querySelectorAll('img').forEach(img => {
                    img.crossOrigin = "anonymous";
                    img.style.maxWidth = 'none';
                });
            }
        });

        // Restore original logo style
        logo.style.cssText = originalStyle;

        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);

        let heightLeft = imgHeight * ratio;
        let position = 2;

        pdf.addImage(imgData, 'JPEG', 0, position, imgWidth * ratio, imgHeight * ratio);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
            position = -pageHeight * pdf.internal.getNumberOfPages();
            pdf.addPage();
            pdf.addImage(imgData, 'JPEG', 0, position, imgWidth * ratio, imgHeight * ratio);
            heightLeft -= pageHeight;
        }

        pdf.save(`NCR_${selectedRecord.sequenceNo || "Not_Generated"}.pdf`);
    };

    const renderValue = (value) => {
        if (value === undefined || value === null) return 'N/A';
        if (typeof value === 'boolean') return value ? '✅' : '❌';
        return value;
    };


    const getCategoryLabel = (categoryId) => {
        const category = ncrValues.categories.find(cat => cat.id === categoryId);
        return category ? category.name : 'N/A';
    };

    const getSubcategoryLabel = (subcategoryId) => {
        const subcategory = ncrValues.subCategories.find(subcat => subcat.id === subcategoryId);
        return subcategory ? subcategory.name : 'N/A';
    };


    return (
        <Modal isOpen={isOpen} toggle={toggle} size="xl" fullscreen>
            <ModalHeader toggle={toggle}>
                <div className="d-flex justify-content-between align-items-center w-100">
                    <span style={{ marginRight: '10px' }}>View INCR</span>
                    <Button color="primary" onClick={generatePDF} style={{ marginLeft: 'auto' }}>
                        <FilePdfOutlined /> Download PDF
                    </Button>
                </div>
            </ModalHeader>
            <ModalBody>
                <div ref={contentRef} id="details">
                    <div className="d-flex justify-content-between align-items-start mb-4">
                        <h3>INCR No. : {renderValue(selectedRecord.sequenceNo)}</h3>
                        <div style={{ borderRadius: '5px' }}>
                            <img
                                id="meinhardtLogo"
                                src={meinhardtlogo}
                                alt="Meinhardt Logo"
                                style={{maxWidth: '150px', height: 'auto'}}
                                crossOrigin="anonymous"
                            />
                        </div>
                    </div>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Admin Details</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={3}><strong>Site:</strong></Col>
                                <Col md={9}>{renderValue(selectedRecord.site)}</Col>
                                <Col md={3}><strong>L3 Contractor:</strong></Col>
                                <Col md={9}>{renderValue(selectedRecord.l3Contractor)}</Col>
                                <Col md={3}><strong>Responded By:</strong></Col>
                                <Col md={9}>{renderValue(selectedRecord.respondedByUser)}</Col>
                                <Col md={3}><strong>Observed At:</strong></Col>
                                <Col md={9}>{renderValue(selectedRecord.observedAt ? new Date(selectedRecord.observedAt).toLocaleString() : 'N/A')}</Col>

                            </Row>
                        </CardBody>
                    </Card>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>INCR Details</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={3}><strong>MHT PM RESPONSIBLE:</strong></Col>
                                <Col md={9}>{renderValue(selectedRecord.mhtuser)}</Col>
                                <Col md={3}><strong>Region:</strong></Col>
                                <Col md={9}>{renderValue(selectedRecord.region)}</Col>
                                <Col md={3}><strong>Project Type :</strong></Col>
                                <Col md={9}>{renderValue(selectedRecord.projectType)}</Col>
                                <Col md={3}><strong>Project Scope :</strong></Col>
                                <Col md={9}>{renderValue(selectedRecord.projectScope)}</Col>

                            </Row>
                            {loading ? (
                                <div className="text-center">
                                    <Spinner color="primary" /> Loading NCR Findings...
                                </div>
                            ) : selectedRecord.findings && selectedRecord.findings.length > 0 ? (
                                <Table striped>
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Category</th>
                                        <th>Subcategory</th>
                                        <th>Finding Details</th>
                                        <th>Actions Recommending</th>
                                        <th>Finding Importance</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {selectedRecord.findings.map((finding, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{renderValue(getCategoryLabel(finding.categoryId))}</td>
                                            <td>{renderValue(getSubcategoryLabel(finding.subcategoryId))}</td>
                                            <td>{renderValue(finding.findingDetails)}</td>
                                            <td>{renderValue(finding.actionsRecommending)}</td>
                                            <td>{renderValue(finding.findingImportance)}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <p>No NCR details available.</p>
                            )}
                        </CardBody>
                    </Card>



                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Actions</h4>
                        </CardHeader>
                        <CardBody>
                            <Table striped>
                                <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Created By</th>
                                    <th>Created For</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {selectedRecord.actions && selectedRecord.actions.map((action, index) => (
                                    <tr key={index}>
                                        <td>{renderValue(action.type)}</td>
                                        <td>{renderValue(action.createdByUser)}</td>
                                        <td>{renderValue(action.createdFor)}</td>
                                        <td>{renderValue(action.description)}</td>
                                        <td>{renderValue(action.status)}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>

                    <Card className="mb-3">
                        <CardHeader>
                            <h4>Attachments</h4>
                        </CardHeader>
                        <CardBody>
                            {selectedRecord.files && selectedRecord.files.map((file, index) => renderAttachment(file, index))}
                        </CardBody>
                    </Card>
                </div>
            </ModalBody>
        </Modal>
    );
};



export { ViewGoSeeProgramSection, ViewHSSEAudit, LOD1AssuranceView, L2WeeklyViewModal, WeeklyReportModal, ToolBoxTalkView,ViewNCRSection,ViewINCRSection};