import {
    GENERATE_TBT,
    TBT_VALUES,
    GET_USER_TBT,
    GET_SINGLE_TBT,
    ADD_TBT,
    UPDATE_TBT,
    DELETE_TBT
} from "../../../types";

const initialState = { list: [], single: null, userTBT: [],values:[] };

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GENERATE_TBT:
            return {
                ...state,
                userTBT: payload
            };
        case TBT_VALUES:
            return { ...state, values: payload };
        case GET_USER_TBT:
            return { ...state, userTBT: payload };
        case GET_SINGLE_TBT:
            return { ...state, single: payload };
        case ADD_TBT:
            return { ...state, userTBT: payload };
        case UPDATE_TBT:
            return {
                ...state,
                userTBT: payload
            };
        case DELETE_TBT:
                return {
                    ...state,
                    userTBT: state.userTBT.user.filter(object => object.id !== payload)
                };
        default:
            return state;
    }
};

export default reducer;