import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button, Card, CardHeader, CardBody, Row, Col, Form,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import {
    AdminSection,
    AttachmentsSection,
    ContributingFactorsSection,
    GeneralSection,
    IncidentReportDetailsSection,
    InvolvedPersonsCard,
    ActionModal,
} from "../Common/Forms";
import { message } from "antd";
import { DeleteOutlined, EditOutlined, PlusCircleFilled, EyeFilled } from "@ant-design/icons";
import {
    AccessFilter,
    dateSorter,
    formatDateWithTime,
    formDataToObject,
    manageCURDAccess,
    stringSorter
} from "../../../utils/functions";
import {
    addIncidentReport,
    getIncidentValues,
    getUserIncidents,
    getUserSingleIncident,
    updateIncidentReport,
    submitIncident,
    submitActionForIncident,
    deleteIncident
} from "../../../store/actions/Incident/incidentreport";
import ReactStrapDataTable from "../../CustomDatatable/ReactStrapDataTable";
import { ViewIncidentModal } from "../Views";
import DebouncedSaveButton from '../../../components/Buttons/DebounceSaveButton';
import { showConfirmDialog, showSuccessDialog, showWarningDialog } from "../../../utils/dialogs";
import Swal from "sweetalert2";


const IncidentReport = ({ access }) => {
    const accessRights = manageCURDAccess(access);
    const dispatch = useDispatch();
    const userIncidents = useSelector((state) => state.incidentreport.userIncident);
    const incidentData = useSelector((state) => state.incidentreport.list);
    const getSingleIncident = useSelector((state) => state.incidentreport.single);

    const [modalOpen, setModalOpen] = useState(false);
    const [currentIncident, setCurrentIncident] = useState(null);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [incidentToDelete, setIncidentToDelete] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [errors, setErrors] = useState({});
    const [involvedPersons, setInvolvedPersons] = useState([]);
    const [witnesses, setWitnesses] = useState([]);
    const [actionModalOpen, setActionModalOpen] = useState(false);
    const [selectedIncident, setSelectedIncident] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [incidentToGenerate, setIncidentToGenerate] = useState(null);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedViewIncident, setSelectedViewIncident] = useState(null);


    //SaveButton
    const [isProcessing, setIsProcessing] = useState(false);

    //SharedAccessFilter
    const [accessFilter, setAccessFilter] = useState('My');

    const handleAccessFilterChange = (selectedAccess) => {
        setAccessFilter(selectedAccess);
    };

    const updateInvolvedPersons = (newPersons) => {
        setInvolvedPersons(newPersons);
    };

    const updateWitnesses = (newWitnesses) => {
        setWitnesses(newWitnesses);
    };

    const [formData, setFormData] = useState({
        responsibleManager: null,
        closeoutManager: null,
        supervisor: null,
        site: null,
        l3Contractor: null,
        locationPlace: '',
        identifiedBy: null,
        identifiedByOther: '',
        dhsIncident: false,
        classification: null,
        description: '',
        workRelatedIncident: false,
        regulatoryNotifiableIncident: false,
        contractorIncident: false,
        noticesIssued: false,
        incidentCategories: [],
        workActivity: null,
        hazardSource: null,
        actualHazard: null,
        potentialHazard: null,
        primaryHazard: null,
        secondaryHazards: [],
        detectedOn: new Date().toISOString().split('T')[0],
        furtherActionsRequired: 'No',
        isImprovementOpportunity: false,
        actions: [],
        attachments: [],
        icatId: [],
        factors: []

    });

    const resetForm = () => {
        setFormData({
            responsibleManager: null,
            closeoutManager: null,
            supervisor: null,
            site: null,
            l3Contractor: null,
            locationPlace: '',
            detectedOn: new Date().toISOString().split('T')[0],
            furtherActionsRequired: 'No',
            identifiedBy: null,
            identifiedByOther: '',
            dhsIncident: '',
            classification: null,
            description: '',
            workRelatedIncident: '',
            regulatoryNotifiableIncident: '',
            contractorIncident: '',
            noticesIssued: '',
            incidentCategories: [],
            workActivity: null,
            hazardSource: null,
            primaryHazard: null,
            secondaryHazards: [],
            isImprovementOpportunity: false,
            actions: [],
            attachments: [],
            actualHazard: null,
            potentialHazard: null,
            factors: []

        });
        setErrors({});
    };


    const validateForm = () => {
        let isValid = true;
        let newErrors = {};

        const validateField = (fieldName, value) => {
            switch (fieldName) {
                case 'responsibleManager':
                    if (!value) {
                        newErrors[fieldName] = "Responsible Manager is required.";
                        message.error("Responsible Manager is required.");
                        return false;
                    }
                    break;
                case 'closeoutManager':
                    if (!value) {
                        newErrors[fieldName] = "Closeout Manager is required.";
                        message.error("Closeout Manager is required.");
                        return false;
                    }
                    break;
                case 'site':
                    if (!value) {
                        newErrors[fieldName] = "Site is required.";
                        message.error("Site is required.");
                        return false;
                    }
                    break;
                case 'l3Contractor':
                    if (!value) {
                        newErrors[fieldName] = "L3 Contractor is required.";
                        message.error("L3 Contractor is required.");
                        return false;
                    }
                    break;
                case 'detectedOn':
                    if (!value) {
                        newErrors[fieldName] = "Detected On date is required.";
                        message.error("Detected On date is required.");
                        return false;
                    }
                    break;

                // case 'identifiedBy':
                //     if (!value && !formData.identifiedByOther) {
                //         newErrors[fieldName] = "Either Identified By or Identified By Other is required.";
                //         message.error("Either Identified By or Identified By Other is required.");
                //         return false;
                //     }
                //     break;
                case 'description':
                    if (!value) {
                        newErrors[fieldName] = "Description is required.";
                        message.error("Description is required.");
                        return false;
                    }
                    break;
                case 'identifiedBy':
                    if (value === "user") {
                        newErrors[fieldName] = "Identified By User is required.";
                        message.error("dentified By User is required.");
                        return false;
                    }
                    break
                case 'incidentCategories':
                    // console.log(fieldName)
                    if (value.length === 0) {
                        newErrors[fieldName] = "At least one Incident Category is required.";
                        message.error("At least one Incident Category is required.");
                        return false;
                    }
                    break;
                case 'classification':
                    // console.log(formData.dhsIncident)
                    if (formData.dhsIncident === "Yes" && !value) {
                        newErrors[fieldName] = 'Classification is required for DHS Incidents';
                        message.error('Classification is required for DHS Incidents');
                        return false;
                    }
                    break;
                case 'actions':
                    if (formData.furtherActionsRequired === 'Yes') {
                        if (value.length === 0) {
                            newErrors[fieldName] = "At least one action is required when Further Actions Required is Yes.";
                            message.error("At least one action is required when Further Actions Required is Yes.");
                            return false;
                        } else {
                            for (let i = 0; i < value.length; i++) {
                                const action = value[i];
                                if (!action.userId || !action.description || !action.targetDays) {
                                    newErrors[`action${i}`] = "All fields in Contributing Factors and Actions are required.";
                                    message.error(`All fields in Action ${i + 1} are required.`);
                                    return false;
                                }
                            }
                        }
                    }
                    break;
                default:
                    break;
            }
            return true;
        };

        // Validate each field
        for (const key of Object.keys(formData)) {
            if (!validateField(key, formData[key])) {
                isValid = false;
                break; // Stop at the first error
            }
        }

        setErrors(newErrors);

        if (!isValid) {
            // Scroll to the first error
            const firstErrorKey = Object.keys(newErrors)[0];
            const firstErrorElement = document.getElementById(firstErrorKey);
            if (firstErrorElement) {
                firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }

        return isValid;
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSelectChange = (name, selectedOption) => {
        setFormData(prevState => ({
            ...prevState,
            [name]: selectedOption // This will be either a single option object, an array of option objects, or null
        }));
    };



    const handleAttachmentChange = (newAttachments) => {
        setFormData({ ...formData, attachments: newAttachments });
    };

    const handleSubmit = async (e) => {
        setErrorMessage("");
        if (!validateForm()) {
            throw new Error("Form validation failed");
        }
        setIsProcessing(true)

        const formDataToSend = new FormData();
        
        const appendToFormData = (key, value) => {

            if (value === null || value === undefined || value === '') {
                return;
            }

            if (typeof value === 'boolean') {
                formDataToSend.append(key, value ? 'true' : 'false');
            } else if (Array.isArray(value)) {
                const filteredValue = value.filter(item => item !== null && item !== undefined);
                if (filteredValue.length > 0) {
                    formDataToSend.append(key, JSON.stringify(filteredValue));
                }
            } else if (typeof value === 'object') {
                if (value.value !== undefined) {
                    formDataToSend.append(key, value.value.toString());
                } else {
                    formDataToSend.append(key, JSON.stringify(value));
                }
            } else {
                formDataToSend.append(key, value.toString());
            }
        };


        
        // Append form data
        appendToFormData('moduleId',parseInt(access.moduleId))
        appendToFormData('rmId', formData.responsibleManager?.value);
        appendToFormData('cmId', formData.closeoutManager?.value);
        appendToFormData('siteId', formData.site?.value);
        appendToFormData('l3Id', formData.l3Contractor?.value);
        appendToFormData('location', formData.locationPlace);
        appendToFormData('detectedAt', formData.detectedOn);
        if (formData.identifiedBy) {
            appendToFormData('identifiedBy', parseInt(formData.identifiedBy.value))
        }
        appendToFormData('identifiedOther', formData.identifiedByOther);
        appendToFormData('dhsIncident', formData.dhsIncident === "" ? false : formData.dhsIncident);
        appendToFormData('iclassId', formData.classification?.value);
        appendToFormData('waId', formData.workActivity?.value);
        appendToFormData('hsId', formData.hazardSource?.value);
        appendToFormData('primaryHazardId', formData.primaryHazard?.value);
        appendToFormData('description', formData.description);
        appendToFormData('wri', formData.workRelatedIncident === true);
        appendToFormData('rni', formData.regulatoryNotifiableIncident === true);
        appendToFormData('ci', formData.contractorIncident === true);
        appendToFormData('ni', formData.noticesIssued === true);
        if (formData.incidentCategories.length > 0) {
            appendToFormData('icatId', JSON.stringify(formData.incidentCategories?.map(cat => parseInt(cat.value)).filter(Boolean)));
        }
        if (involvedPersons !== null && involvedPersons !== undefined && involvedPersons.length > 0) {
            appendToFormData('involved', JSON.stringify(involvedPersons));
        }
        else {
            appendToFormData('involved', JSON.stringify([]));
        }

        if (witnesses !== null && witnesses !== undefined && witnesses.length > 0) {
            appendToFormData('witnesses', JSON.stringify(witnesses));
        }
        else {
            appendToFormData('witnesses', JSON.stringify([]));
        }

        appendToFormData('secondaryHazards', formData.secondaryHazards?.map(hazard => hazard.value).filter(Boolean));
        appendToFormData('actionRequired', formData.furtherActionsRequired === 'Yes');

        if (formData.id) {
            appendToFormData('actionId', parseInt(formData.actionId));
            appendToFormData('costId', parseInt(formData.costId));
            appendToFormData('fileId', parseInt(formData.fileId));
            appendToFormData('personId', parseInt(formData.personId));
        }

        if (formData.actions && formData.actions.length > 0) {
            const actions = formData.actions.map(action => ({
                id: action.id ?? null,
                userId: parseInt(action.userId),
                description: action.description ?? null,
                createdAt: action.createdAt ?? null,
                createdBy: action.createdBy ?? null,
                updatedAt: action.updatedAt ?? null,
                updatedBy: action.updatedBy ?? null,
                submittedAt: action.submittedAt ?? null,
                targetAt: action.targetAt ?? null,
                reply: action.reply ?? null,
                tags: action.tags ?? [],
                comments: action.comments ?? null,
                targetDays: parseInt(action.targetDays, 10),
                type: action.type ?? "N",
                status: action.status ?? "PENDING",
            }));
            appendToFormData('actions', JSON.stringify(actions));
        }
        else {
            appendToFormData('actions', JSON.stringify([]));
        }

        formData.attachments.forEach((attachment, index) => {
            if (attachment.file instanceof File) {
                formDataToSend.append(`attachments[${index}]`, attachment.file, attachment.filename);
            }
        });

        if (formData.attachments && formData.attachments.length > 0) {
            const files = formData.attachments.map(attachment => ({
                id: attachment.id ?? null,
                fileName: attachment.filename,
                description: attachment.description ?? null,
                path: attachment.path ?? null,
                createdAt: attachment.createdAt ?? null,
                createdBy: attachment.createdBy ?? null,
                updateBy: attachment.updateBy ?? null,
                updatedAt: attachment.updatedAt ?? null,
            }));
            appendToFormData('files', JSON.stringify(files));
        }
        else {
            appendToFormData('files', JSON.stringify([]));
        }

        if (formData.costImplications !== undefined) {
            appendToFormData('costs', JSON.stringify(formData.costImplications.map(action => ({
                id: action.id ?? null,
                description: action.description ?? null,
                amount: parseFloat(action.amount),
                allocation: action.allocation ?? null,
                comment: action.comment ?? null,
                createdAt: action.createdAt ?? null,
                createdBy: action.createdBy ?? null,
                updatedAt: action.updatedAt ?? null,
                updatedBy: action.updatedBy ?? null,
            }))));
        }
        else {
            appendToFormData('costs', JSON.stringify([]))
        }
        if (formData.actualHazard !== null) {
            appendToFormData('aCategory', formData.actualHazard?.value);
        }
        if (formData.potentialHazard !== null) {
            appendToFormData('pCategory', formData.potentialHazard?.value);
        }

        appendToFormData('factors', JSON.stringify(formData.factors))

        try {
            let result;
            if (currentIncident) {
                formDataToSend.append('id', currentIncident.id.toString());
                formDataToSend.append('factorId', currentIncident.factorId.toString());
                result = await dispatch(updateIncidentReport(formDataToSend));
            } else {
                result = await dispatch(addIncidentReport(formDataToSend));
            }
            if (result.success) {
                showSuccessDialog(currentIncident ? "Incident Updated Successfully" : "Incident Added Successfully")
                resetForm();
                setInvolvedPersons([])
                setWitnesses([])
                handleCloseModal();
                dispatch(getUserIncidents(access.moduleId));
            } else {
                showWarningDialog(result.message)

            }
        } catch (error) {
            console.error("Error processing incident:", error);
            setErrorMessage();
            showWarningDialog("An error occurred while processing the incident. Please try again.")
        }
        finally {
            setIsProcessing(false)
        }
    };

    const handleEdit = async (incident) => {
        setCurrentIncident(incident);
        await dispatch(getUserSingleIncident(incident.id));
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        resetForm();
        setCurrentIncident(null);
        setModalOpen(false);
    };

    const handleDelete = async (incident) => {
        try {
            const result = await showConfirmDialog("Are you sure you want to delete this Incident?");
            if (result.isConfirmed) {
                const object = await dispatch(deleteIncident(incident.id))
                if (object.success) {
                    Swal.fire('Deleted!', object.message, 'success');
                    dispatch(getUserIncidents(access.moduleId));
                }
                else {
                    Swal.fire('Error!', object.message, 'error');
                }
            }
        } catch (error) {
            console.error('Error in handleDelete:', error);
        }
    };


    const handleGenerate = async (incident) => {
        setIncidentToGenerate(incident);
        setConfirmModalOpen(true);
    };

    const confirmGenerate = async () => {
        if (incidentToGenerate) {
            const object={
                moduleId:parseInt(access.moduleId),
                id:incidentToGenerate.id
            }
            const response = await dispatch(submitIncident(object));
            if (response.success) {
                showSuccessDialog("Incident generated successfully")
            } else {
                showWarningDialog("Failed to generate incident")
            }
            setConfirmModalOpen(false);
            setIncidentToGenerate(null);
            dispatch(getUserIncidents(access.moduleId));
        }
    };

    const handleActionClick = (incident) => {
        setSelectedIncident(incident);
        setActionModalOpen(true);
    };

    const toggleActionModal = () => setActionModalOpen(!actionModalOpen);

    const handleInitiateAction = async (actionData) => {
        delete actionData.submitDate;
        if (actionData.message) {
            actionData.reply = actionData.message;
            delete actionData.message;
        }

        const payload = {
            incidentReportId: selectedIncident.id,
            id: selectedIncident.actionId,
            actions: JSON.stringify(actionData)
        };

        const response = await dispatch(submitActionForIncident(payload));
        if (response.success) {
            showSuccessDialog(response.message)
            //setActionData([]);
            setActionModalOpen(false);
            setModalOpen(false)
            dispatch(getUserIncidents(access.moduleId))
        } else {
            showWarningDialog(response.message)
        }
    };

    const viewIncident = (incident) => {
        setSelectedViewIncident(incident);
        setViewModalOpen(true);
    };

    useEffect(() => {
        if (getSingleIncident && currentIncident && incidentData) {

            const singleIncident = getSingleIncident;

            let categoryOptions = incidentData.categories?.map(category => ({
                value: category.id.toString(),
                label: category.name
            })) || [];

            let workActivityOptions = incidentData.workActivities?.map(activity => ({
                value: activity.id.toString(),
                label: activity.name
            })) || [];

            let hazardSourceOptions = incidentData.hazardSources?.map(source => ({
                value: source.id.toString(),
                label: source.name
            })) || [];

            let standardHazardOptions = incidentData.standardHazards?.map(hazard => ({
                value: hazard.id.toString(),
                label: hazard.name
            })) || [];

            const selectedWorkActivity = workActivityOptions.find(work => work.value === singleIncident.waId?.toString()) || null;
            const selectedPrimaryHazard = standardHazardOptions.find(hazard => hazard.value === singleIncident.primaryHazardId?.toString()) || null;
            const selectedHazardSource = hazardSourceOptions.find(source => source.value === singleIncident.hsId?.toString()) || null;

            const selectedCategories = singleIncident.icatId?.map(catId =>
                categoryOptions.find(cat => cat.value === catId.toString())
            ).filter(Boolean) || [];

            const selectedSecondaryHazards = singleIncident.secondaryHazards?.map(hazardId =>
                standardHazardOptions.find(hazard => hazard.value === hazardId.toString())
            ).filter(Boolean) || [];

            const formatDateForInput = (dateString) => {
                if (!dateString) return '';
                const date = new Date(dateString);
                return date.toISOString().slice(0, 16); // Format: YYYY-MM-DDTHH:mm
            };

            let newFormData = {
                id: singleIncident.id,
                actionId: singleIncident.actionId,
                fileId: singleIncident.fileId,
                factorId: singleIncident.factorId,
                costId: singleIncident.costId,
                personId: singleIncident.personId,
                responsibleManager: { value: singleIncident.rmId?.toString(), label: singleIncident.responsibleManager },
                closeoutManager: { value: singleIncident.cmId?.toString(), label: singleIncident.closeoutManager },
                site: { value: singleIncident.siteId?.toString(), label: singleIncident.site },
                l3Contractor: { value: singleIncident.l3Id?.toString(), label: singleIncident.l3Contractor },
                locationPlace: singleIncident.location || '',
                detectedOn: formatDateForInput(singleIncident.detectedAt),
                identifiedBy: singleIncident.identifiedBy ? {
                    value: singleIncident.identifiedBy.toString(),
                    label: singleIncident.createdByUser
                } : null,
                identifiedByOther: singleIncident.identifiedOther || '',
                dhsIncident: singleIncident.dhsIncident,
                classification: singleIncident.iclassId ? {
                    value: singleIncident.iclassId.toString(),
                    label: incidentData.classifications?.find(c => c.id.toString() === singleIncident.iclassId.toString())?.name || ''
                } : null,
                description: singleIncident.description || '',
                furtherActionsRequired: singleIncident.actionRequired ? 'Yes' : 'No',
                actions: (singleIncident.actions || []).map(action => ({
                    id: action.id,
                    userId: action.userId,
                    description: action.description,
                    targetDays: action.targetDays,
                    createdAt: action.createdAt,
                    createdBy: action.createdBy,
                    updatedAt: action.updatedAt,
                    updatedBy: action.updatedBy,
                    submittedAt: action.submittedAt,
                    targetAt: action.targetAt,
                    reply: action.reply,
                    tags: action.tags,
                    comments: action.comments,
                    type: action.type,
                    status: action.status
                })) || [],
                attachments: (singleIncident.files || []).map(file => ({
                    id: file.id,
                    path: file.path,
                    filename: file.fileName,
                    description: file.description,
                    createdAt: file.createdAt,
                    createdBy: file.createdBy,
                    updatedAt: file.updatedAt,
                    updatedBy: file.updatedBy,
                    fullPath: file.fullPath,
                })) || [],
                costImplications: (singleIncident.costs || []).map(cost => ({
                    id: cost.id,
                    description: cost.description,
                    amount: cost.amount,
                    allocation: cost.allocation,
                    comment: cost.comment,
                    createdAt: cost.createdAt,
                    createdBy: cost.createdBy,
                    updatedAt: cost.updatedAt,
                    updatedBy: cost.updatedBy,
                })),
                workRelatedIncident: singleIncident.wri === true,
                regulatoryNotifiableIncident: singleIncident.rni === true,
                contractorIncident: singleIncident.ci === true,
                noticesIssued: singleIncident.ni === true,
                incidentCategories: selectedCategories,
                workActivity: selectedWorkActivity,
                hazardSource: selectedHazardSource,
                primaryHazard: selectedPrimaryHazard,
                secondaryHazards: selectedSecondaryHazards,
                factors: singleIncident.factors ?? []
            };

            if (singleIncident.aCategory !== null) {
                newFormData.actualHazard = { label: singleIncident.aCategory, value: singleIncident.aCategory }
            }
            if (singleIncident.pCategory !== null) {
                newFormData.potentialHazard = { label: singleIncident.pCategory, value: singleIncident.pCategory }
            }

            // console.log(singleIncident.involved)
            // console.log('New Form Data:', newFormData);
            setFormData(newFormData);
            setInvolvedPersons(singleIncident.involved || []);
            setWitnesses(singleIncident.witnesses || []);
        }
    }, [getSingleIncident, currentIncident, incidentData]);
    let columns = [
        {
            title: "Action",
            key: "action",
            width: '120px',
            render: (text, incident) => (
                <div style={{ display: 'flex', gap: '8px' }}>
                    {incident.submittedAt === null && (
                        <>
                            <Button color="primary" size="sm" onClick={() => handleEdit(incident)} disabled={!accessRights.U}>
                                <EditOutlined />
                            </Button>
                            <Button color="danger" size="sm" onClick={() => handleDelete(incident)} disabled={!accessRights.D}>
                                <DeleteOutlined />
                            </Button>
                        </>
                    )}
                    <Button color="info" size="sm" onClick={() => viewIncident(incident)} disabled={!accessRights.R}>
                        <EyeFilled />
                    </Button>
                    {incident.submittedAt !== null && (
                        <Button color="info" size="sm" onClick={() => handleActionClick(incident)} disabled={accessFilter === 'Shared'}>
                            <PlusCircleFilled />
                        </Button>
                    )}

                </div>
            ),
        },
        {
            title: "Sequence Number",
            dataIndex: "submittedAt",
            key: "submittedAt",
            sorter: stringSorter("submittedAt"),
            sortDirections: ["ascend", "descend"],
            render: (text, incident) => {
                if (incident.submittedAt !== null) {
                    return incident.sequenceNo;
                } else {
                    return (
                        <Button color="primary" size="sm" onClick={() => handleGenerate(incident)}>
                            Generate
                        </Button>
                    );
                }
            }
        },
        {
            title: "Detected On",
            dataIndex: "detectedAt",
            key: "detectedAt",
            sorter: dateSorter("detectedAt"),
            sortDirections: ["ascend", "descend"],
            render: (text) => (
                <>{formatDateWithTime(text, false)}</>
            )  
        },
        {
            title: "Entered By",
            dataIndex: "createdByUser",
            key: "createdByUser",
            sorter: stringSorter("createdByUser"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "L3 Contractor",
            dataIndex: "l3Contractor",
            key: "l3Contractor",
            sorter: stringSorter("l3Contractor"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
            sorter: stringSorter("country"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Business Unit",
            dataIndex: "businessUnit",
            key: "businessUnit",
            sorter: stringSorter("businessUnit"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Project/Program",
            dataIndex: "project",
            key: "project",
            sorter: stringSorter("project"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Site",
            dataIndex: "site",
            key: "site",
            sorter: stringSorter("site"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Location Place",
            dataIndex: "location",
            key: "location",
            sorter: stringSorter("location"),
            sortDirections: ["ascend", "descend"],
        },
    ];


    useEffect(() => {
        dispatch(getIncidentValues());
        dispatch(getUserIncidents(access.moduleId));
    }, [dispatch]);

    useEffect(() => {
        console.log("USER INCIDENTS")
        console.log(userIncidents)
    }, [userIncidents]);

    const filteredIncidents = useMemo(() => {
        switch (accessFilter) {
            case 'My':
                return [
                    ...(userIncidents.user || []),
                ];
            case 'Shared':
                return userIncidents.shared || [];
            case 'Assigned':
                return userIncidents.assigned || [];
            default:
                return [];
        }
    }, [userIncidents, accessFilter]);


    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="align-items-center mb-3">
                        <Col md={3}>
                            <h5 className="mb-0">Manage Incidents</h5>
                        </Col>
                        <Col md={9} className="d-flex justify-content-end align-items-center">
                            <AccessFilter
                                accessFilter={accessFilter}
                                handleAccessFilterChange={handleAccessFilterChange}
                                sharedAccess={userIncidents?.sharedAccess || []}
                            />
                            <Button color="primary" onClick={() => setModalOpen(true)} className="ms-2" disabled={!accessRights.C}>
                                <PlusCircleFilled size={18} /> New
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactStrapDataTable
                        dataSource={filteredIncidents ?? []}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} size="xl" backdrop="static">
                <Form onSubmit={handleSubmit}>
                    <ModalHeader toggle={handleCloseModal}>
                        {currentIncident ? 'Edit Incident' : 'Add New Incident'}
                    </ModalHeader>
                    <ModalBody>
                        <GeneralSection
                            formData={formData}
                            handleSelectChange={handleSelectChange}
                            hazardData={incidentData}
                            errors={errors}
                            showSupervisor={false}
                        />
                        <AdminSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            hazardData={incidentData}
                            errors={errors}
                        />
                        <IncidentReportDetailsSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            incidentData={incidentData}
                            errors={errors}
                            setErrors={setErrors}
                        />
                        <ContributingFactorsSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            hazardData={incidentData}
                            actionUsers={incidentData.actionUsers}
                            errors={errors}
                            showFactors={true}
                        />
                        <AttachmentsSection
                            attachments={formData.attachments}
                            onAttachmentsChange={handleAttachmentChange}
                        />
                        <InvolvedPersonsCard
                            headerText={"Persons"}
                            users={incidentData.involvedUsers}
                            involvedPersons={involvedPersons}
                            updateInvolvedPersons={updateInvolvedPersons}
                            userTypes={incidentData.userTypes}
                        />
                        <InvolvedPersonsCard
                            headerText={"Witnesses"}
                            users={incidentData.witnesses}
                            involvedPersons={witnesses}
                            updateInvolvedPersons={updateWitnesses}
                            userTypes={incidentData.userTypes}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleCloseModal}>Cancel</Button>
                        <DebouncedSaveButton
                            onSave={handleSubmit}
                            isProcessing={isProcessing}
                        />
                    </ModalFooter>
                </Form>
            </Modal>


            <Modal isOpen={confirmModalOpen} toggle={() => setConfirmModalOpen(false)}>
                <ModalHeader toggle={() => setConfirmModalOpen(false)}>Confirm Submission</ModalHeader>
                <ModalBody>
                    <p>Are you sure you want to submit this incident?</p>
                    <p style={{ color: 'red' }}>
                        Note: Once the Incident is submitted it cannot be edited.
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setConfirmModalOpen(false)}>Cancel</Button>
                    <Button color="primary" onClick={confirmGenerate}>Yes, Submit</Button>
                </ModalFooter>
            </Modal>

            <ActionModal
                isOpen={actionModalOpen}
                toggle={toggleActionModal}
                selectedHazard={selectedIncident}
                message={"Action For Incident"}
                onInitiateAction={handleInitiateAction}
            />

            <ViewIncidentModal
                isOpen={viewModalOpen}
                toggle={() => setViewModalOpen(!viewModalOpen)}
                incident={selectedViewIncident}
            />
        </div>
    );
};

export default IncidentReport;