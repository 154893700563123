import {ADD_NEWS, GET_ALL_NEWS, GET_LATEST_NEWS, GET_SINGLE_NEWS, GET_USER_NEWS, UPDATE_NEWS} from "../types";


const initialState = {list: [], single: null,userNews:[]};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_ALL_NEWS:
        return {...state, list: payload};
        case GET_LATEST_NEWS:
            return {...state, list: payload};
        case GET_USER_NEWS:
            return {...state, userNews: payload};

        case GET_SINGLE_NEWS:
            return {
                ...state,
                list: [payload, ...state.list],
            };
        case ADD_NEWS:
            return {
                ...state,
                list: [payload, ...state.list],
            };

        case UPDATE_NEWS:
            return {
                ...state,
                list: state.list.map((item) =>
                    item.id === payload.id ? payload : item
                ),
            };

        default:
            return state;
    }
};

export default reducer;
