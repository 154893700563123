import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import {Button, Card, Col, Row, Checkbox, Typography, Divider, Modal} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
    getSysRoles,
    getSysRolesModules,
    getSysRolesModulesByRoleId,
    manageSysRoleModules
} from "../../../store/actions/sysroles";
import Select from 'react-select';

const { Title } = Typography;

// Memoized checkbox component to prevent unnecessary re-renders
// const MemoizedCheckbox = React.memo(({ label, checked, onChange, style }) => (
//     <Checkbox checked={checked} onChange={onChange} style={style}>
//         {label}
//     </Checkbox>
// ));

const MemoizedCheckbox = React.memo(({ label, checked, onChange, style, disabled }) => (
    <Checkbox checked={checked} onChange={onChange} style={style} disabled={disabled}>
        {label}
    </Checkbox>
));

const Roles = () => {
    document.title = "Meinhardt EPCM | System Roles";

    const dispatch = useDispatch();
    const roles = useSelector((state) => state.roles.list);
    const modules = useSelector((state) => state.roles.modules);
    const roleModules = useSelector((state) => state.roles.specificModules);
    const { control, handleSubmit, formState: { errors } } = useForm();
    const [moduleStructure, setModuleStructure] = useState({});
    const [permissionsState, setPermissionsState] = useState({});
    const [currentRoleId, setCurrentRoleId] = useState(null);

    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [formData, setFormData] = useState(null);

    //SUPERADMIN FILTER
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    const showConfirmModal = (data) => {
        setFormData(data);
        setIsConfirmModalVisible(true);
    };
    useEffect(() => {
        dispatch(getSysRoles());
        dispatch(getSysRolesModules());
    }, [dispatch]);

    const createModuleStructure = useCallback((modules) => {
        return modules.reduce((acc, module) => {
            if (!acc[module.menu]) acc[module.menu] = {};
            if (!acc[module.menu][module.submenu]) acc[module.menu][module.submenu] = [];
            acc[module.menu][module.submenu].push(module);
            return acc;
        }, {});
    }, []);

    useEffect(() => {
        if (modules.length > 0) {
            setModuleStructure(createModuleStructure(modules));
        }
    }, [modules, createModuleStructure]);

    const roleModulesToPermissionsState = useCallback((roleModules, allModules) => {
        const newState = {};
        allModules.forEach(module => {
            if (!newState[module.menu]) newState[module.menu] = { checked: false };
            if (!newState[module.menu][module.submenu]) newState[module.menu][module.submenu] = { checked: false };
            newState[module.menu][module.submenu][module.name] = {
                CREATE: false,
                READ: false,
                UPDATE: false,
                DELETE: false,
                'SHARED READ': false,
                'SHARED UPDATE': false,
                'SHARED DELETE': false
            };
        });

        roleModules.forEach(module => {
            if (newState[module.menu_name]?.[module.submenu_name]?.[module.module_name]) {
                newState[module.menu_name][module.submenu_name][module.module_name] = {
                    CREATE: module.role_json.C === 1,
                    READ: module.role_json.R === 1,
                    UPDATE: module.role_json.U === 1,
                    DELETE: module.role_json.D === 1,
                    'SHARED READ': module.role_json.SR === 1,
                    'SHARED UPDATE': module.role_json.SU === 1,
                    'SHARED DELETE': module.role_json.SD === 1
                };
            }
        });

        return updateParentStates(newState);
    }, []);

    useEffect(() => {
        if (modules.length > 0) {
            setPermissionsState(roleModulesToPermissionsState(roleModules, modules));
        }
    }, [roleModules, modules, roleModulesToPermissionsState]);

    const handleRoleChange = useCallback((selectedRole) => {
        if (selectedRole?.value !== undefined) {
            console.log(selectedRole)
            setCurrentRoleId(selectedRole.value);
            setIsSuperAdmin(selectedRole.label === "SUPER ADMIN");
            dispatch(getSysRolesModulesByRoleId(selectedRole.value));
        }
    }, [dispatch]);
    const isDisabledItem = useCallback((menu, submenu, moduleName) => {
        return isSuperAdmin && 
               menu === "System Admin" && 
               submenu === "Manage Roles" && 
               (moduleName === "System Role Access" || moduleName === "System Roles");
    }, [isSuperAdmin]);

    const updateParentStates = useCallback((state) => {
        const newState = { ...state };
        Object.keys(newState).forEach(menu => {
            let menuChecked = true;
            Object.keys(newState[menu]).forEach(submenu => {
                if (submenu !== 'checked') {
                    let submenuChecked = true;
                    Object.keys(newState[menu][submenu]).forEach(module => {
                        if (module !== 'checked') {
                            const moduleChecked = Object.values(newState[menu][submenu][module]).every(v => v);
                            if (!moduleChecked) submenuChecked = false;
                        }
                    });
                    newState[menu][submenu].checked = submenuChecked;
                    if (!submenuChecked) menuChecked = false;
                }
            });
            newState[menu].checked = menuChecked;
        });
        return newState;
    }, []);

   /* const handlePermissionChange = useCallback((type, menu, submenu, module, action, checked) => {
        setPermissionsState(prevState => {
            const newState = { ...prevState };
            const allPermissions = { CREATE: checked, READ: checked, UPDATE: checked, DELETE: checked, 'SHARED READ': checked, 'SHARED UPDATE': checked, 'SHARED DELETE': checked };

            switch (type) {
                case 'menu':
                    Object.keys(newState[menu]).forEach(sub => {
                        if (sub !== 'checked') {
                            Object.keys(newState[menu][sub]).forEach(mod => {
                                if (mod !== 'checked' && !isDisabledItem(menu, sub, mod)) {
                                    newState[menu][sub][mod] = { ...allPermissions };
                                }
                            });
                            newState[menu][sub].checked = checked;
                        }
                    });
                    newState[menu].checked = checked;
                    break;
                case 'submenu':
                    Object.keys(newState[menu][submenu]).forEach(mod => {
                        if (mod !== 'checked' && !isDisabledItem(menu, submenu, mod)) {
                            newState[menu][submenu][mod] = { ...allPermissions };
                        }
                    });
                    newState[menu][submenu].checked = checked;
                    break;
                case 'module':
                    if (!isDisabledItem(menu, submenu, module)) {
                        newState[menu][submenu][module] = { ...allPermissions };
                    }
                    break;
                case 'crud':
                    if (!isDisabledItem(menu, submenu, module)) {
                        newState[menu][submenu][module][action] = checked;
                    }
                    break;
            }
            return updateParentStates(newState);
        });
    }, [isDisabledItem]);*/


    const handlePermissionChange = useCallback((type, menu, submenu, module, action, checked) => {
        setPermissionsState(prevState => {
            const newState = { ...prevState };
            const allPermissions = { CREATE: checked, READ: checked, UPDATE: checked, DELETE: checked, 'SHARED READ': checked, 'SHARED UPDATE': checked, 'SHARED DELETE': checked };

            const updateAllChildren = (menuObj) => {
                Object.keys(menuObj).forEach(key => {
                    if (key === 'checked') {
                        menuObj[key] = checked;
                    } else if (typeof menuObj[key] === 'object') {
                        updateAllChildren(menuObj[key]);
                    } else {
                        menuObj[key] = checked;
                    }
                });
            };

            switch (type) {
                case 'superParent':
                    Object.keys(newState).forEach(menuKey => {
                        updateAllChildren(newState[menuKey]);
                    });
                    break;
                case 'menu':
                    updateAllChildren(newState[menu]);
                    break;
                case 'submenu':
                    updateAllChildren(newState[menu][submenu]);
                    break;
                case 'module':
                    if (!isDisabledItem(menu, submenu, module)) {
                        newState[menu][submenu][module] = { ...allPermissions };
                    }
                    break;
                case 'crud':
                    if (!isDisabledItem(menu, submenu, module)) {
                        newState[menu][submenu][module][action] = checked;
                    }
                    break;
            }
            return updateParentStates(newState);
        });
    }, [isDisabledItem, updateParentStates]);
   

    const renderCRUDCheckboxes = useCallback((menu, submenu, module) => (
        <div style={{ display: 'flex', gap: '20px' }}>
            {['CREATE', 'READ', 'UPDATE', 'DELETE', 'SHARED READ', 'SHARED UPDATE', 'SHARED DELETE'].map((action) => (
                <MemoizedCheckbox
                    key={action}
                    label={action}
                    style={{ marginRight: 0, fontSize: '12px' }}
                    checked={permissionsState[menu]?.[submenu]?.[module]?.[action] || false}
                    onChange={(e) => handlePermissionChange('crud', menu, submenu, module, action, e.target.checked)}
                    
                />
            ))}
        </div>
    ), [permissionsState, handlePermissionChange]);

    const renderSubmenus = useCallback((menu, submenus) => (
        Object.entries(submenus).map(([submenu, modules]) => {
            const isAnyModuleDisabled = modules.some(module => isDisabledItem(menu, submenu, module.name));
            return (
                <div key={`${menu}-${submenu}`} style={{ marginLeft: '24px', marginBottom: '16px' }}>
                    <MemoizedCheckbox
                        label={submenu}
                        style={{ fontWeight: 500, marginBottom: '8px' }}
                        checked={permissionsState[menu]?.[submenu]?.checked || false}
                        onChange={(e) => {
                            const newChecked = e.target.checked;
                            handlePermissionChange('submenu', menu, submenu, null, null, newChecked);
                            // If unchecking, don't uncheck disabled items
                            if (!newChecked) {
                                modules.forEach(module => {
                                    if (!isDisabledItem(menu, submenu, module.name)) {
                                        handlePermissionChange('module', menu, submenu, module.name, null, false);
                                    }
                                });
                            }
                        }}
                        disabled={isAnyModuleDisabled && permissionsState[menu]?.[submenu]?.checked}
                    />
                    {modules.map(module => (
                        <div key={module.id} style={{ marginLeft: '24px', display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                            <MemoizedCheckbox
                                label={module.name}
                                style={{ width: '200px', fontSize: '14px' }}
                                checked={Object.values(permissionsState[menu]?.[submenu]?.[module.name] || {}).every(v => v)}
                                onChange={(e) => handlePermissionChange('module', menu, submenu, module.name, null, e.target.checked)}
                                disabled={isDisabledItem(menu, submenu, module.name)}
                            />
                            {renderCRUDCheckboxes(menu, submenu, module.name)}
                        </div>
                    ))}
                </div>
            );
        })
    ), [permissionsState, handlePermissionChange, renderCRUDCheckboxes, isDisabledItem]);

    const generatePermissionsJSON = useCallback(() => {
        const permissions = [];
        Object.entries(permissionsState).forEach(([menu, submenus]) => {
            Object.entries(submenus).forEach(([submenu, modulePermissions]) => {
                Object.entries(modulePermissions).forEach(([moduleName, crud]) => {
                    if (moduleName !== 'checked') {
                        const moduleId = modules.find(m =>
                            m.menu === menu &&
                            m.submenu === submenu &&
                            m.name === moduleName
                        )?.id;

                        if (moduleId) {
                            const crudValues = {
                                C: crud.CREATE ? 1 : 0,
                                R: crud.READ ? 1 : 0,
                                U: crud.UPDATE ? 1 : 0,
                                D: crud.DELETE ? 1 : 0,
                                SR: crud['SHARED READ'] ? 1 : 0,
                                SU: crud['SHARED UPDATE'] ? 1 : 0,
                                SD: crud['SHARED DELETE'] ? 1 : 0
                            };

                            if (Object.values(crudValues).some(value => value === 1)) {
                                permissions.push({
                                    moduleId,
                                    ...crudValues
                                });
                            }
                        }
                    }
                });
            });
        });
        return permissions;
    }, [permissionsState, modules]);

    const handleConfirm = () => {
        setIsConfirmModalVisible(false);
        if (formData) {
            const permissionsJSON = generatePermissionsJSON();
            const payload = {
                sRoleId: currentRoleId,
                roles: JSON.stringify(permissionsJSON)
            };

            dispatch(manageSysRoleModules(payload));
        }
    };

    const handleCancel = () => {
        setIsConfirmModalVisible(false);
        setFormData(null);
    };
    const onSubmit = useCallback((data) => {
        showConfirmModal(data);
    }, []);

    const memoizedModulePermissions = useMemo(() => (
        <div style={{ maxHeight: '600px', overflowY: 'auto', padding: '0 16px' }}>
            {Object.entries(moduleStructure).map(([menu, submenus]) => (
                <div key={menu} style={{ marginBottom: '24px' }}>
                    <MemoizedCheckbox
                        label={menu}
                        style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '12px' }}
                        checked={permissionsState[menu]?.checked || false}
                        onChange={(e) => handlePermissionChange('menu', menu, null, null, null, e.target.checked)}
                    />
                    {renderSubmenus(menu, submenus)}
                </div>
            ))}
        </div>
    ), [moduleStructure, permissionsState, handlePermissionChange, renderSubmenus]);

    // useEffect(()=>{
    //
    //     if(currentRoleId===null){
    //         setPermissionsState({});
    //     }
    // },[currentRoleId]);

    useEffect(() => {
        console.log(modules)
    }, [modules]);

    return (
        <>
            <div className="page-content" style={{ padding: '24px' }}>
                <Card style={{ boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)' }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row gutter={16}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                                <Controller
                                    name="type"
                                    control={control}
                                    rules={{required: 'Type is required'}}
                                    render={({field}) => (
                                        <div style={{marginBottom: 24}}>
                                            <label style={{display: 'block', marginBottom: '8px', fontWeight: 500}}>System
                                                Role</label>
                                            <Select
                                                {...field}
                                                options={roles.map(role => ({
                                                    value: role.id,
                                                    label: role.name
                                                }))}
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        width: '100%',
                                                        borderColor: '#d9d9d9',
                                                        boxShadow: 'none',
                                                        '&:hover': {
                                                            borderColor: '#40a9ff'
                                                        }
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        maxHeight: '300px',
                                                        zIndex: 9999
                                                    }),
                                                    menuPortal: (provided) => ({
                                                        ...provided,
                                                        zIndex: 9999
                                                    })
                                                }}
                                                menuPlacement="auto"
                                                isSearchable={true}
                                                placeholder="Select a role..."
                                                onChange={(selectedOption) => {
                                                    field.onChange(selectedOption);
                                                    handleRoleChange(selectedOption);
                                                }}
                                            />
                                            {errors.type && <span
                                                style={{color: '#ff4d4f', fontSize: '12px'}}>{errors.type.message}</span>}
                                        </div>
                                    )}
                                />
                            </Col>
                        </Row>

                        <Divider/>
                        <div style={{textAlign: 'right'}}>
                            <Button type="primary" onClick={handleSubmit(onSubmit)} size="large">
                                Save Permissions
                            </Button>
                        </div>
                        <MemoizedCheckbox
                            label="Select All"
                            style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '16px' }}
                            checked={Object.values(permissionsState).every(menu => menu.checked)}
                            onChange={(e) => handlePermissionChange('superParent', null, null, null, null, e.target.checked)}
                        />
                        <Title level={4} style={{marginBottom: '24px'}}>Module Permissions</Title>
                            {memoizedModulePermissions}
                        <Divider/>
                    </form>
                </Card>
            </div>
            <Modal
                title="Confirm Save Permissions"
                visible={isConfirmModalVisible}
                onOk={handleConfirm}
                onCancel={handleCancel}
                okText="Confirm"
                cancelText="Cancel"
                zIndex={9999}
            >
                <p>Are you sure you want to save the current permissions?</p>
            </Modal>
        </>
    );
};

export default React.memo(Roles);