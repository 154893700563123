import {
    ADD_TBT, GENERATE_TBT,
    GET_SINGLE_TBT,
    GET_USER_TBT,
    TBT_VALUES,
    UPDATE_TBT,
    DELETE_TBT,
} from "../../../types";
import axios from "../../../../axios";
import {setAlert} from "../../alert";
import {message} from "antd";

export const getTBTValues = () => async (dispatch) => {
    try {
        const res = await axios.get("/inform/tbt/values");
        if (res.data.code === 1) {
            dispatch({type: TBT_VALUES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};


export const addTBTForm = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/inform/tbt/add", formData, config);
        
        if (res.data.code === 1) {
            dispatch({type: ADD_TBT, payload: res.data.data});
            return {success: true, message: res.data.message};
        } else {
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        console.error("Error in addHazardForm:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
};
export const updateTBTForm = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/inform/tbt/update", formData, config);
        
        if (res.data.code === 1) {
            dispatch({type: UPDATE_TBT, payload: res.data.data});
            return {success: true, message: res.data.message};
        } else {
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        console.error("Error in addHazardForm:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
};

export const deleteTBTForm = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/inform/tbt/delete/${id}`);
        if (res.data.code === 1) {
            dispatch({type: DELETE_TBT, payload: id});
        }
        return res
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const getUserTBT = (accessModuleId) => async (dispatch) => {
    try {
        const object={
            moduleId:accessModuleId
        }
        const res = await axios.post("/inform/tbt/user",object);
        if (res.data.code === 1) {
            dispatch({type: GET_USER_TBT, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const getSingleTBT=(id)=> async (dispatch) => {
    try {
        const res = await axios.get(`/inform/tbt/${id}`);
        
        if (res.data.code === 1) {
            dispatch({type: GET_SINGLE_TBT, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const submitTBTToGenerate = (object) => async (dispatch) => {
    try {
        const res = await axios.post(`/inform/tbt/submit/`,object);
        if (res.data.code === 1) {
            dispatch({type: GENERATE_TBT, payload: res.data.data});
        }
        return res
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const submitActionForTBT = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/inform/tbt/action/update", formData);
        if (res.data.code === 1) {
            message.success(res.data.message);
            //dispatch({type: GET_USER_HAZARD, payload: res.data.data});
            return {success: true, message: res.data.message};
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        console.error("Error in addHazardForm:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
}


