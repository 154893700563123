import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import { DeleteOutlined, EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import ReactStrapDataTable from "../../../CustomDatatable/ReactStrapDataTable";
import { getBusinessUnits, addBusinessUnit, updateBusinessUnit, deleteBusinessUnit } from "../../../../store/actions/business/businessunit";
import { getCountries } from "../../../../store/actions/region/country";
import { getBusinessGroups } from "../../../../store/actions/business/businessgroup";
import { dateTimeSort, stringSorter } from "../../../../utils/functions";
import Select from 'react-select';

const BusinessUnit = () => {
    const dispatch = useDispatch();
    const businessUnits = useSelector((state) => state.businessunit.list);
    const countries = useSelector((state) => state.country.list);
    const businessGroups = useSelector((state) => state.businessgroup.list);

    const [modalOpen, setModalOpen] = useState(false);
    const [editingBusinessUnit, setEditingBusinessUnit] = useState(null);

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();

    useEffect(() => {
        dispatch(getBusinessUnits());
        dispatch(getCountries());
        dispatch(getBusinessGroups());
    }, [dispatch]);

    const handleEdit = (businessUnit) => {
        const countryOption = countries.find(country => country.id === businessUnit.coId);
        const businessGroupOption = businessGroups.find(bg => bg.id === businessUnit.bgId);

        setEditingBusinessUnit(businessUnit);
        reset({
            name: businessUnit.name,
            code: businessUnit.code,
            coId: countryOption ? { value: countryOption.id, label: countryOption.name } : null,
            bg_id: businessGroupOption ? { value: businessGroupOption.id, label: businessGroupOption.name } : null,
        });
        setModalOpen(true);
    };

    const handleDelete = async (businessUnit) => {
        if (window.confirm("Are you sure you want to delete this Business Unit?")) {
            await dispatch(deleteBusinessUnit(businessUnit.id));
            dispatch(getBusinessUnits());
        }
    };

    const onFormSubmit = (data) => {
        const formData = {
            name: data.name,
            code: data.code,
            bgId: data.bg_id.value,
            coId: data.coId.value,
        };
        if (editingBusinessUnit) {
            dispatch(updateBusinessUnit({ ...formData, id: editingBusinessUnit.id }));
        } else {
            dispatch(addBusinessUnit(formData));
        }
        handleCloseModal();
        dispatch(getBusinessUnits());
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setEditingBusinessUnit(null);
        reset();
    };

    const handleAddNew = () => {
        setEditingBusinessUnit(null);
        reset({ name: '', code: '', coId: null, bg_id: null });
        setModalOpen(true);
    };

    const columns = [
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: dateTimeSort("createdAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
            sorter: stringSorter("country"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Business Group",
            dataIndex: "businessgroupname",
            key: "businessgroupname",
            sorter: stringSorter("businessgroupname"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: stringSorter("name"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Code",
            dataIndex: "displayCode",
            key: "displayCode",
            sorter: stringSorter("displayCode"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
                <>
                    <Button color="link" onClick={() => handleEdit(record)}>
                        <EditOutlined />
                    </Button>
                    <Button color="link" onClick={() => handleDelete(record)}>
                        <DeleteOutlined />
                    </Button>
                </>
            ),
        },
    ];

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">Manage Business Unit</h5>
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" onClick={handleAddNew}>
                                <PlusCircleFilled /> Add New
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactStrapDataTable
                        dataSource={businessUnits}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} backdrop="static">
                <ModalHeader toggle={handleCloseModal}>
                    {editingBusinessUnit ? 'Edit Business Unit' : 'Add Business Unit'}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="coId">Country</Label>
                            <Controller
                                name="coId"
                                control={control}
                                rules={{ required: "Country is required" }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={countries.map(country => ({
                                            value: country.id,
                                            label: country.name
                                        }))}
                                    />
                                )}
                            />
                            {errors.coId && <span className="text-danger">{errors.coId.message}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="bg_id">Business Group</Label>
                            <Controller
                                name="bg_id"
                                control={control}
                                rules={{ required: "Business Group is required" }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={businessGroups.map(businessGroup => ({
                                            value: businessGroup.id,
                                            label: businessGroup.name
                                        }))}
                                    />
                                )}
                            />
                            {errors.bg_id && <span className="text-danger">{errors.bg_id.message}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: "Name is required",
                                    pattern: {
                                        value: /\S/,
                                        message: "Blank space is not allowed.",
                                    },
                                }}
                                render={({ field }) => <Input {...field} id="name" />}
                            />
                            {errors.name && <span className="text-danger">{errors.name.message}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="code">Code</Label>
                            <Controller
                                name="code"
                                control={control}
                                rules={{
                                    required: "Code is required",
                                    pattern: {
                                        value: /\S/,
                                        message: "Blank space is not allowed.",
                                    },
                                }}
                                render={({ field }) => <Input {...field} id="code" />}
                            />
                            {errors.code && <span className="text-danger">{errors.code.message}</span>}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {editingBusinessUnit ? 'Update' : 'Save'}
                        </Button>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
};

export default BusinessUnit;