import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getActionFilterData,
    getGlobalActionCount,
    globalActionAdd,
    globalActionUpdate
} from '../../../store/actions/Inform/Action/action';
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Row,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
    FormFeedback, Table,
} from 'reactstrap';
import {
    ExclamationCircleOutlined,
    SyncOutlined,
    CheckCircleOutlined,
    ClockCircleOutlined,
    PlusCircleFilled,
    DeleteOutlined
} from '@ant-design/icons';
import {formatDateWithTime, returnRoute, stringSorter, dateSorter, returnInformRoute} from "../../../utils/functions";
import ReactStrapDataTable from "../../CustomDatatable/ReactStrapDataTable";
import { GET_ACTION_FILTER } from "../../../store/types";
import { message } from "antd";
import Select from "react-select";
import { getTags } from "../../../store/actions/tag";
import { getActiveUsers } from "../../../store/actions/user";

const STATUS_TYPES = ['pending', 'open', 'delayed', 'closed'];

const INFORM_TYPES = {
    informFaudits: 'HSSE Retail Audit (Fuel Only)',
    informGsps: 'Go See Program',
    informLod1s: 'LOD1s',
    informLopcs: 'LOPCs',
    informRhpws: 'Weekly Project HSSE Report',
    informRpmws: 'L2 PM Weekly Reporting',
    informSsucs: 'SSUCs',
    informTbts: 'ToolBox Talk',
    informTnts: 'Tool Not Tick'
};

const ACTION_TYPES = {
    C: 'Closeout',
    R: 'Responsible',
    S: 'Supervisor',
    N: 'New',
    CREATOR: 'Creator'
};

const STATUS_COLORS = {
    pending: 'warning',
    open: 'primary',
    delayed: 'danger',
    closed: 'success'
};
const STATUS_ICONS = {
    pending: ExclamationCircleOutlined,
    open: SyncOutlined,
    delayed: ClockCircleOutlined,
    closed: CheckCircleOutlined
};

const calculateProgressValue = (status, data) => {
    const total = STATUS_TYPES.reduce((sum, type) => {
        if (type !== 'delayed') {
            return sum + (data[type] || 0);
        }
        return sum;
    }, 0);
    return total === 0 ? 0 : ((data[status] || 0) * 100 / total);
};



const ActionModal = ({isOpen, toggle, onInitiateAction, data, message,actionAdd}) => {
    const dispatch = useDispatch();
    const tags = useSelector((state) => state.tag.list);
    const activeUsers=useSelector((state) => state.users.active);
    const [initiateModalOpen, setInitiateModalOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const [actionFormData, setActionFormData] = useState({
        submitDate: new Date().toISOString().split('T')[0],
        status: {value: 'open', label: 'Open'},
        description: '',
        tags: [],
        comments: []
    });

    const statusOptions = [
        {value: 'OPEN', label: 'Open'},
        {value: 'CLOSED', label: 'Close'}
    ];

    const resetForm = () => {
        setActionFormData({
            submitDate: new Date().toISOString().split('T')[0],
            targetAt: '',
            status: {value: 'open', label: 'Open'},
            description: '',
            tags: [],
            comments: []
        });
    };

    const toggleInitiateModal = (action = true) => {
        setInitiateModalOpen(!initiateModalOpen);
        setSelectedAction(action);
        if (action) {
            const selectedTags = action.tags
                ? action.tags.map(tagId => {
                    const matchingTag = tags.find(tag => tag.id.toString() === tagId.toString());
                    return matchingTag
                        ? { value: matchingTag.id.toString(), label: matchingTag.name }
                        : null;
                }).filter(tag => tag !== null)
                : [];
            setActionFormData({
                submittedAt: action.submittedAt,
                submitDate: formatDateWithTime(action.submittedAt, false) || new Date().toISOString().split('T')[0],
                targetAt: formatDateWithTime(action.targetAt, false) || '',
                description: action.description,
                reply: action.message || '',
                tags: selectedTags,
                comments: action.comments || [],
                type: action.type,
                targetDays: action.targetDays,
                status: action.status,
                id: action.id,
                userId: action.userId,
                createdBy: action.createdBy,
                updatedBy: action.updatedBy,
                createdAt: action.createdAt,
                updatedAt: action.updatedAt
            });
        } else {
            resetForm();
        }
    };

    const handleActionFormChange = (e) => {
        const {name, value} = e.target;
        setActionFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSelectChange = (selectedOption, actionMeta) => {
        const {name} = actionMeta;
        setActionFormData(prevState => ({
            ...prevState,
            [name]: name === 'status' ? selectedOption.value : selectedOption
        }));
    };
    const addCommentRow = () => {
        setActionFormData(prevState => ({
            ...prevState,
            comments: [...prevState.comments, {
                id: null,
                description: '',
                createdBy: null,
                updatedBy: null,
                createdAt: null,
                updatedAt: null
            }]
        }));
    };

    const removeCommentRow = (index) => {
        setActionFormData(prevState => ({
            ...prevState,
            comments: prevState.comments.filter((_, i) => i !== index)
        }));
    };

    const handleCommentChange = (index, value) => {
        setActionFormData(prevState => ({
            ...prevState,
            comments: prevState.comments.map((comment, i) =>
                i === index ? {...comment, description: value} : comment
            )
        }));
    };
    const handleInitiateSubmit = (e) => {
        e.preventDefault();
        const action = {
            ...actionFormData,
            submitDate: new Date().toISOString().split('T')[0],
            status: actionFormData.status || 'PENDING',
            tags: Array.isArray(actionFormData.tags) && actionFormData.tags.length > 0
                ? actionFormData.tags
                    .filter(tag => tag && typeof tag.value !== 'undefined')
                    .map(tag => parseInt(tag.value))
                : []
        };
        onInitiateAction(action);
        toggleInitiateModal();
    };

    useEffect(() => {
        dispatch(getActiveUsers())
        dispatch(getTags());
    }, [dispatch]);


    const [addActionModalOpen, setAddActionModalOpen] = useState(false);
    const [newActionData, setNewActionData] = useState({
        userId: null,
        description: '',
        targetDays: ''
    });
    const [errors, setErrors] = useState({});

    const toggleAddActionModal = () => {
        setAddActionModalOpen(!addActionModalOpen);
        setErrors({});
    };

    // Handle change for the new action form
    const handleNewActionChange = (e) => {
        const { name, value } = e.target;
        setNewActionData(prevState => ({
            ...prevState,
            [name]: value
        }));
        // Clear the error for this field when the user makes a change
        setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    };


    const handleUserSelect = (selectedOption) => {
        setNewActionData(prevState => ({
            ...prevState,
            userId: selectedOption ? selectedOption.value : null
        }));
        setErrors(prevErrors => ({ ...prevErrors, userId: '' }));
    };
    const validateForm = () => {
        const newErrors = {};
        if (!newActionData.userId) newErrors.userId = 'User is required';
        if (!newActionData.description.trim()) newErrors.description = 'Description is required';
        if (!newActionData.targetDays) newErrors.targetDays = 'Target Days is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSaveNewAction = () => {
        if (validateForm()) {
            const payload = {
                id: parseInt(data.actionId),
                [data.actionKey]: parseInt(data.id),
                actions: JSON.stringify({
                    ...newActionData,
                    createdAt: null,
                    createdBy: null,
                    updatedAt: null,
                    updatedBy: null,
                    submittedAt: null,
                    targetAt: null,
                    reply: null,
                    tags: [],
                    comments: [],
                    type: "N",
                    status: "PENDING"
                })
            };
            actionAdd(payload);
            toggleAddActionModal();
            newActionData.userId = null;
            newActionData.description = '';
            newActionData.targetDays = '';
        }
    };


    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} size="xl">
                <ModalHeader toggle={toggle} className="d-flex justify-content-between align-items-center">
                    <div>
                        {message && <p>{message}</p>}
                        Number: {data?.sequenceNo}
                    </div>
                    <Button color="primary" onClick={toggleAddActionModal}>
                        <PlusCircleFilled/> Manage Action
                    </Button>
                </ModalHeader>
                <ModalBody>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Status</th>
                            <th>Action Type</th>
                            <th>Submit Date</th>
                            <th>From</th>
                            <th>Message Description</th>
                            <th>To</th>
                            <th>Response Message</th>
                            <th>Response On</th>
                            <th>Response By</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data?.actions?.map((action, index) => (
                            <tr key={index}>
                                <td>

                                    <Button color="primary" size="sm" onClick={() => toggleInitiateModal(action)} disabled={!action.allowed}>
                                        {action.status}
                                    </Button>
                                </td>
                                <td>{action.actionType}</td>
                                <td>{action.submittedAt}</td>
                                <td>{action.createdByUser}</td>
                                <td>{action.description}</td>
                                <td>{action.createdFor}</td>
                                <td>{action.reply}</td>
                                <td>{action.responseAt}</td>
                                <td>{action.responseByUser}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={initiateModalOpen} toggle={toggleInitiateModal} size="lg">
                <Form onSubmit={handleInitiateSubmit}>
                    <ModalHeader toggle={toggleInitiateModal}>
                        {selectedAction ? 'Edit Action' : 'Initiate New Action'}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="submitDate">Submit Date</Label>
                                    <Input
                                        type="date"
                                        name="submitDate"
                                        id="submitDate"
                                        value={actionFormData.submitDate}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="targetAt">Target Date</Label>
                                    <Input
                                        type="date"
                                        name="targetAt"
                                        id="targetAt"
                                        value={actionFormData.targetAt}
                                        onChange={handleActionFormChange}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="status">Status</Label>
                                    <Select
                                        name="status"
                                        options={statusOptions}
                                        value={statusOptions.find(option => option.value === actionFormData.status) || null}
                                        onChange={handleSelectChange}
                                        // isDisabled={actionFormData.status === 'PENDING'}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <Label for="description">Description</Label>
                            <Input
                                type="textarea"
                                name="description"
                                id="description"
                                value={actionFormData.description}
                                onChange={handleActionFormChange}
                                disabled
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="message">Response Message</Label>
                            <Input
                                type="textarea"
                                name="message"
                                id="message"
                                value={actionFormData.message}
                                onChange={handleActionFormChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="tags">Tags</Label>
                            <Select
                                isMulti
                                name="tags"
                                options={tags?.map(tag => ({
                                    value: tag.id.toString(),
                                    label: tag.name
                                })) || []}
                                value={actionFormData.tags}
                                onChange={handleSelectChange}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Comments</Label>
                            <Table>
                                <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {actionFormData.comments.map((comment, index) => (
                                    <tr key={index}>
                                        <td>
                                            <Input
                                                type="textarea"
                                                value={comment.description}
                                                onChange={(e) => handleCommentChange(index, e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <Button color="danger"
                                                    onClick={() => removeCommentRow(index)}><DeleteOutlined/></Button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                            <Button color="primary" onClick={addCommentRow}>Add Comment</Button>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggleInitiateModal}>Cancel</Button>
                        <Button color="primary" type="submit">Submit</Button>
                    </ModalFooter>
                </Form>
            </Modal>

            <Modal isOpen={addActionModalOpen} toggle={toggleAddActionModal}>
                <ModalHeader toggle={toggleAddActionModal}>Add New Action</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="userId">User</Label>
                            <Select
                                options={activeUsers.map(user => ({value: user.id, label: user.name}))}
                                onChange={handleUserSelect}
                                isInvalid={!!errors.userId}
                            />
                            {errors.userId && <div className="invalid-feedback d-block">{errors.userId}</div>}
                        </FormGroup>
                        <FormGroup>
                            <Label for="description">Description</Label>
                            <Input
                                type="textarea"
                                name="description"
                                id="description"
                                value={newActionData.description}
                                onChange={handleNewActionChange}
                            />
                            {errors.description && <div className="invalid-feedback d-block">{errors.description}</div>}
                        </FormGroup>
                        <FormGroup>
                            <Label for="targetDays">Target Days</Label>
                            <Input
                                type="number"
                                name="targetDays"
                                id="targetDays"
                                value={newActionData.targetDays}
                                onChange={handleNewActionChange}
                                invalid={!!errors.targetDays}
                            />
                            <FormFeedback>{errors.targetDays}</FormFeedback>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleAddActionModal}>Cancel</Button>
                    <Button color="primary" onClick={handleSaveNewAction}>Save</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
const ActionTypeCard = ({ actionType, actionTypeName, data, onStatusClick }) => (
    <Card className="mb-4" style={{
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        borderRadius: '10px',
        border: 'none',
        overflow: 'hidden'
    }}>
        <CardBody>
            <CardTitle className="mb-4">{actionTypeName}</CardTitle>
            <div>
                <ul className="list-unstyled">
                    {STATUS_TYPES.map((status, key) => {
                        const IconComponent = STATUS_ICONS[status];
                        const statusCount = data && data[status] ? data[status] : 0;
                        const progressValue = calculateProgressValue(status, data);
                        return (
                            <li key={key} className="py-3" style={{
                                borderBottom: key !== STATUS_TYPES.length - 1 ? '1px solid #e0e0e0' : 'none'
                            }}>
                                <div className="d-flex">
                                    <div className="avatar-xs align-self-center me-3">
                                        <div className="avatar-title rounded-circle bg-light text-primary font-size-18">
                                            <IconComponent/>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <p className="text-muted mb-2">{status.charAt(0).toUpperCase() + status.slice(1)}</p>
                                        <div className="progress progress-sm animated-progess">
                                            <div
                                                className={`progress-bar bg-${STATUS_COLORS[status]}`}
                                                role="progressbar"
                                                style={{width: `${progressValue}%`}}
                                                aria-valuenow={progressValue}
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <hr/>
            <div className="text-center">
                <Row className="g-2">
                    {STATUS_TYPES.map((status, index) => {
                        const IconComponent = STATUS_ICONS[status];
                        return (
                            <Col xs={12} sm={6} lg={3} key={status}>
                                <div className="d-flex flex-column h-100">
                                    <Button
                                        color={STATUS_COLORS[status]}
                                        size="sm"
                                        className="w-100 d-flex align-items-center justify-content-center flex-grow-1"
                                        style={{
                                            minHeight: '38px',
                                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                            fontWeight: 'bold',
                                            whiteSpace: 'normal',
                                            height: 'auto',
                                            padding: '0.5rem'
                                        }}
                                        onClick={() => onStatusClick(actionType, status)}
                                    >
                                        <Row className="w-100 g-0 align-items-center">
                                            <Col>
                                                <span>{status.charAt(0).toUpperCase() + status.slice(1).replace('-', ' ')}</span>
                                            </Col>
                                        </Row>
                                    </Button>
                                    <div className="mt-2">
                                        <h5 className="font-size-16 mb-0" style={{fontWeight: 'bold'}}>
                                            {data[status] || 0}
                                        </h5>
                                    </div>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            </div>
        </CardBody>
    </Card>
);
const calculateSumValue = (data) => {
    return Object.values(data).reduce((acc, val) => acc + val, 0);
};
const ActionTypeCards = ({data, ACTION_TYPES, handleStatusClick}) => {
    return (
        <Row>
            {Object.entries(ACTION_TYPES).map(([actionTypeKey, actionTypeName]) => {

                let key = actionTypeKey.toUpperCase() === 'U' ? 'N' : actionTypeKey.toUpperCase();
                const actionTypeData = data[key] || {};
                const sum = calculateSumValue(actionTypeData);

                if (sum > 0) {
                    return (
                        <Col key={actionTypeKey} md={6} lg={4}>
                            <ActionTypeCard
                                actionType={actionTypeKey}
                                actionTypeName={actionTypeName}
                                data={actionTypeData}
                                onStatusClick={handleStatusClick}
                            />
                        </Col>
                    );
                }
                return null;
            })}
        </Row>
    );
};
const InformDashboard = () => {
    const dispatch = useDispatch();
    const actionCounts = useSelector((state) => state.informaction.userActions);
    const userFilterData = useSelector((state) => state.informaction.userFilterData);
    const [selectedInformType, setSelectedInformType] = useState('all');
    const [isLoading, setIsLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);

    const [actionModalOpen, setActionModalOpen] = useState(false);
    const toggleActionModal = () => setActionModalOpen(!actionModalOpen);
    const [actionData, setActionData] = useState({});

    useEffect(() => {
        dispatch(getGlobalActionCount()).then(() => setIsLoading(false));
    }, [dispatch]);

    useEffect(() => {
        if (userFilterData.length > 0) {
            setModalOpen(true);
        }
    }, [userFilterData]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const handleActionClick = (data) => {
        console.log(data);
        setActionData(data);
        setActionModalOpen(true);
    };

    const handleInitiateAction = async (data) => {
        delete data.submitDate;
        if (data.message) {
            data.reply = actionData.message;
            delete data.message;
        }

        const payload = {
            [actionData.actionKey]: parseInt(actionData.id),
            id: parseInt(actionData.actionId),
            actions: JSON.stringify(data)
        };
        const response = await dispatch(globalActionUpdate(actionData.route, payload));
        if (response.success) {
            setActionModalOpen(false);
            setActionData({});
            setModalOpen(false);
        } else {
            message.error(response.message);
        }
    };

    const calculateSums = (informType) => {
        if (!actionCounts) return {};

        if (informType === 'all') {
            return Object.keys(INFORM_TYPES).reduce((sums, type) => {
                if (Array.isArray(actionCounts[type])) {
                    actionCounts[type].forEach(item => {
                        if (item && item.type) {
                            STATUS_TYPES.forEach(status => {
                                sums[item.type] = sums[item.type] || {};
                                sums[item.type][status] = (sums[item.type][status] || 0) + parseInt(item[status] || 0);
                            });
                        }
                    });
                }
                return sums;
            }, {});
        } else {
            if (!Array.isArray(actionCounts[informType])) return {};

            return actionCounts[informType].reduce((sums, item) => {
                if (item && item.type) {
                    sums[item.type] = sums[item.type] || {};
                    STATUS_TYPES.forEach(status => {
                        sums[item.type][status] = parseInt(item[status] || 0);
                    });
                }
                return sums;
            }, {});
        }
    };

    const handleStatusClick = (actionType, status) => {
        console.log('Clicked:', { actionType, status, selectedInformType });
        let payload = {
            type: actionType,
            status: status.toUpperCase()
        };
        payload.type=payload.type==="U"?payload.type="N":payload.type
        dispatch(getActionFilterData(returnInformRoute(selectedInformType), payload));
        setModalOpen(true);
    };

    const data = calculateSums(selectedInformType);

    const handleActionAdd = async (newActionData) => {
        const response = await dispatch(globalActionAdd(actionData.route, newActionData));
        if (response.success) {
            setActionModalOpen(false);
            setActionData({});
            setModalOpen(false);
        } else {
            message.error(response.message);
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        dispatch({type: GET_ACTION_FILTER, payload: []});
    };

    const modalTableColumns = [
        {
            title: "Action",
            key: "action",
            width: '120px',
            render: (text, object) => (
                <div style={{display: 'flex', gap: '8px'}}>
                    {object.submittedAt !== null && (
                        <Button color="info" size="sm" onClick={() => handleActionClick(object)}>
                            <PlusCircleFilled/>
                        </Button>
                    )}
                </div>
            ),
        },
        {
            title: "Sequence Number",
            dataIndex: "submittedAt",
            key: "submittedAt",
            sorter: stringSorter("submittedAt"),
            sortDirections: ["ascend", "descend"],
            render: (text, object) => {
                if (object.submittedAt !== null) {
                    return object.sequenceNo;
                }
            }
        },
        {
            title: "Detected On",
            dataIndex: "detectedAt",
            key: "detectedAt",
            sorter: dateSorter("detectedAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "L3 Contractor",
            dataIndex: "l3Contractor",
            key: "l3Contractor",
            sorter: stringSorter("l3Contractor"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
            sorter: stringSorter("country"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Business Unit",
            dataIndex: "businessUnit",
            key: "businessUnit",
            sorter: stringSorter("businessUnit"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Project/Program",
            dataIndex: "project",
            key: "project",
            sorter: stringSorter("project"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Site",
            dataIndex: "site",
            key: "site",
            sorter: stringSorter("site"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Location Place",
            dataIndex: "location",
            key: "location",
            sorter: stringSorter("location"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            sorter: stringSorter("description"),
            ellipsis: true,
        },
    ];

    return (
        <React.Fragment>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-4">Inform Dashboard</CardTitle>
                        <div className="mb-4">
                            <Button
                                color={selectedInformType === 'all' ? 'primary' : 'secondary'}
                                onClick={() => setSelectedInformType('all')}
                                className="me-2 mb-2"
                            >
                                All
                            </Button>
                            {Object.entries(INFORM_TYPES).map(([key, label]) => (
                                <Button
                                    key={key}
                                    color={selectedInformType === key ? 'primary' : 'secondary'}
                                    onClick={() => setSelectedInformType(key)}
                                    className="me-2 mb-2"
                                >
                                    {label}
                                </Button>
                            ))}
                        </div>
                        <ActionTypeCards
                            data={data}
                            ACTION_TYPES={ACTION_TYPES}
                            handleStatusClick={handleStatusClick}
                        />
                    </CardBody>
                </Card>
            </Col>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} size="xl">
                <ModalHeader toggle={handleCloseModal}>
                    {selectedInformType.replace('inform', '').replace(/([A-Z])/g, ' $1').trim()}
                </ModalHeader>
                <ModalBody className="modal-body-large">
                    <ReactStrapDataTable
                        dataSource={userFilterData}
                        columns={modalTableColumns}
                        rowKey="id"
                    />
                </ModalBody>
            </Modal>

            <ActionModal
                isOpen={actionModalOpen}
                toggle={toggleActionModal}
                data={actionData}
                message={`Action For ${selectedInformType}`}
                actionAdd={handleActionAdd}
                onInitiateAction={handleInitiateAction}
                size="lg"
            />
        </React.Fragment>
    );
};

export default InformDashboard;