import {GET_NAVIGATION} from "../types";


const initialState = {list: [], single: null};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_NAVIGATION:
            //console.log(...state)
            return {...state, list: payload};

        default:
            return state;
    }
};

export default reducer;
