import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
  Container, Row, Col, Card, CardBody, Table, Nav, NavItem, NavLink,
  TabContent, TabPane, ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody, Label
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from 'classnames';
import { getNavigation } from "../../store/actions/navigation";
import styled from "styled-components";
import {
  FaExclamationTriangle,
  FaCog,
  FaProjectDiagram,
  FaUserGraduate,
  FaInfoCircle,
  FaQuestion,
  FaShieldAlt,
  FaHardHat,
  FaFile,
  FaUserShield, FaDochub,
} from "react-icons/fa";
import { getLatestNews } from "../../store/actions/news";
import CustomNewsCarousel from "./NewsCarouselModel";
import { getUserLinks, getUserQuickRoutes } from "../../store/actions/user";
import { componentMap } from '../../components/GlobalComponents/index';

import {FcFilingCabinet} from "react-icons/fc";


const DashboardWrapper = styled.div`
  --primary-color: #f8f9fa;
  --text-color: #013e7d;
  --hover-color: #e2e6ea;
  --accent-color: #013e7d;

  .dashboard-card {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: none;
  }

  .menu-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
    padding: 15px;
  }

  .menu-item {
    background-color: var(--primary-color);
    border-radius: 8px;
    transition: all 0.3s ease;
    text-decoration: none;
    color: var(--text-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    height: 140px;

    &:hover {
      background-color: var(--hover-color);
      transform: translateY(-3px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }

    .icon {
      font-size: 38px;
      margin-bottom: 10px;
    }

    .text {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .nav-tabs {
    border-bottom: 2px solid var(--accent-color);
    .nav-link {
      color: var(--text-color);
      &.active {
        color: var(--accent-color);
        font-weight: bold;
        border-bottom: 2px solid var(--accent-color);
      }
    }
  }

  .list-group-item {
    border: none;
    padding: 12px 20px;
    &:hover {
      background-color: var(--hover-color);
    }
    a {
      color: var(--text-color);
      text-decoration: none;
      &:hover {
        color: var(--accent-color);
      }
    }
  }

  @media (max-width: 992px) {
    .menu-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (max-width: 768px) {
    .menu-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 480px) {
    .menu-container {
      grid-template-columns: 1fr;
    }
  }
`;

const ModalComponent = ({ isOpen, toggle, SelectedComponent, title }) => (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        {SelectedComponent && <SelectedComponent />}
      </ModalBody>
    </Modal>
);

const CommonlyUsedForms = ({ modules, navigationData }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [modalTitle, setModalTitle] = useState('');

  const handleModuleSelect = (object) => {
    const module = navigationData.find(item => item.role_json.moduleId === object.moduleId);
    if (module) {
      const Component = componentMap[module.module_route];
      if (Component) {
        setSelectedComponent(() => Component);
        setModalTitle(object.name);
        setModalOpen(true);
      } else {
        console.error(`No component found for module_route: ${module.module_route}`);
      }
    }
  };

  const toggleModal = () => setModalOpen(!modalOpen);

  return (
      <>
        <ListGroup style={{ maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden' }}>
          {modules && modules.map((module) => (
              <ListGroupItem
                  key={module.id}
                  tag="button"
                  action
                  onClick={() => handleModuleSelect(module)}
              >
                {module.name}
              </ListGroupItem>
          ))}
        </ListGroup>
        <ModalComponent
            isOpen={modalOpen}
            toggle={toggleModal}
            SelectedComponent={selectedComponent}
            title={modalTitle}
        />
      </>
  );
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigation = useSelector((state) => state.navigation.list);
  const news = useSelector((state) => state.news.list);
  const quickLinks = useSelector((state) => state.users.userLinks);
  const modules = useSelector((state) => state.users.userModules);

  const [uniqueMenuNames, setUniqueMenuNames] = useState([]);
  const [activeTab, setActiveTab] = useState('1');

  useEffect(() => {
    dispatch(getUserLinks());
    dispatch(getUserQuickRoutes());
    dispatch(getNavigation());
    dispatch(getLatestNews());
  }, [dispatch]);

  useEffect(() => {
    if (navigation) {
      const uniqueNames = [...new Set(navigation.map((item) => item.menu_name))];
      setUniqueMenuNames(uniqueNames);
    }
  }, [navigation]);

  const menuIcons = {
    Incident: { icon: <FaExclamationTriangle />, color: "#e74c3c" },
    Inform: { icon: <FaInfoCircle />, color: "#3498db" },
    Competency: { icon: <FaUserGraduate />, color: "#f39c12" },
    "System Admin": { icon: <FaUserShield />, color: "#069f5d" },
    Project: { icon: <FaProjectDiagram />, color: "rgba(195,182,232,0.96)" },
    "Permit To Work": { icon: <FaHardHat />, color: "#e67e22" },
    Risk: { icon: <FaShieldAlt />, color: "#9b59b6" },
    "Filing Cabinet": { icon: <FcFilingCabinet />, color: "#1abc9c" }
  };

  const menuItems = uniqueMenuNames.map((name) => ({
    name: name,
    ...menuIcons[name] || { icon: <FaQuestion />, color: "#95a5a6" },
  }));

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  return (
      <DashboardWrapper>
        <div className="page-content">
          <Container fluid>
            <Card className="dashboard-card mb-4">
              <CardBody>
                <h4 className="card-title mb-4">Home</h4>
                <div className="menu-container">
                  {menuItems.map((item, index) => (
                      <Link
                          key={index}
                          to={`/${item.name.toLowerCase().replace(/\s+/g, "-")}`}
                          className="menu-item"
                      >
                        <div className="icon" style={{ color: item.color }}>{item.icon}</div>
                        <div className="text">{item.name}</div>
                      </Link>
                  ))}
                </div>
              </CardBody>
            </Card>

            <Row>
              <Col xl={8}>
                <CustomNewsCarousel news={news} />
              </Col>
              <Col xl={4}>
                <Card className="dashboard-card">
                  <CardBody>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => { toggle('1'); }}
                        >
                          Commonly Used Forms
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => { toggle('2'); }}
                        >
                          Bulletins
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab} className="mt-3">
                      <TabPane tabId="1">
                        <CommonlyUsedForms modules={modules} navigationData={navigation} />
                      </TabPane>
                      <TabPane tabId="2">
                        <ListGroup style={{ maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden' }}>
                          {quickLinks && quickLinks.map((link) => (
                              <ListGroupItem key={link.id}>
                                <a href={link.link} target="_blank" rel="noopener noreferrer">
                                  {link.name}
                                </a>
                              </ListGroupItem>
                          ))}
                        </ListGroup>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </DashboardWrapper>
  );
};


export default Dashboard;