import {
    ADD_FAUDIT, GENERATE_FAUDIT,
    GET_SINGLE_FAUDIT,
    GET_USER_FAUDIT,
    FAUDIT_VALUES,
    UPDATE_FAUDIT,
    DELETE_FAUDIT
} from "../../../types";
import axios from "../../../../axios";
import {setAlert} from "../../alert";
import {message} from "antd";

export const getFAUDITValues = () => async (dispatch) => {
    try {
        const res = await axios.get("/inform/faudit/values");
        if (res.data.code === 1) {
            dispatch({type: FAUDIT_VALUES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};


export const addFAUDITForm = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/inform/faudit/add", formData, config);
        
        if (res.data.code === 1) {
            dispatch({type: ADD_FAUDIT, payload: res.data.data});
            return {success: true, message: res.data.message};
        } else {
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        console.error("Error in addHazardForm:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
};
export const updateFAUDITForm = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/inform/faudit/update", formData, config);
        
        if (res.data.code === 1) {
            return {success: true, message: res.data.message};
        } else {
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
};

export const getUserFAUDIT = (accessModuleId) => async (dispatch) => {
    try {
        const object={
            moduleId:accessModuleId
        }
        const res = await axios.post("/inform/faudit/user",object);
        if (res.data.code === 1) {
            dispatch({type: GET_USER_FAUDIT, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const deleteFAudit = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/inform/faudit/delete/${id}`);
        if (res.data.code === 1) {
            dispatch({type: DELETE_FAUDIT, payload: id});
            return{success:true,message:res.data.message}
        }
        return res
    } catch (err) {
        return{result:false,message:'Contact to Admin'}
        dispatch(setAlert(err.message, "danger"));
    }
};

export const getSingleFAUDIT=(id)=> async (dispatch) => {
    try {
        const res = await axios.get(`/inform/faudit/${id}`);
        
        if (res.data.code === 1) {
            dispatch({type: GET_SINGLE_FAUDIT, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const submitFAUDITToGenerate = (object) => async (dispatch) => {
    try {
        const res = await axios.post(`/inform/faudit/submit/`,object);
        if (res.data.code === 1) {
            dispatch({type: GENERATE_FAUDIT, payload: res.data.data});
        }
        return res
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const submitActionForFAUDIT = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/inform/faudit/action/update", formData);
        if (res.data.code === 1) {       
            //dispatch({type: GET_USER_HAZARD, payload: res.data.data});
            return {success: true, message: res.data.message};
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        console.error("Error in addHazardForm:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
}


