import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import { DeleteOutlined, EditOutlined, EyeFilled, PlusCircleFilled } from "@ant-design/icons";
import {
    AccessFilter,
    dateSorter,
    formatDateWithTime,
    manageCURDAccess,
    stringSorter
} from "../../../../utils/functions";
import React, { useEffect, useState,useMemo } from "react";
import ReactStrapDataTable from "../../../CustomDatatable/ReactStrapDataTable";
import {
    AdminSection,
    AttachmentsSection,
    ActionSection,
    ActionModal,
    AuditFindingSection, FAuditESection
} from "../../Common/Forms"
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import {
    addFAUDITForm,
    getSingleFAUDIT,
    getFAUDITValues, getUserFAUDIT, submitActionForFAUDIT,
    submitFAUDITToGenerate,
    updateFAUDITForm,
    deleteFAudit
} from "../../../../store/actions/Inform/Audits/hsseaudit";
import { ViewHSSEAudit } from "../../Views";
import DebouncedSaveButton from "../../../../components/Buttons/DebounceSaveButton";
import { showConfirmDialog, showSuccessDialog, showWarningDialog } from "../../../../utils/dialogs";
import Swal from "sweetalert2";


const HSSEAudit = ({ access }) => {
    const accessRights = manageCURDAccess(access);
    const dispatch = useDispatch();
    const FAUDITValues = useSelector((state) => state.hsseaudit.values);
    const userFAUDIT = useSelector((state) => state.hsseaudit.userFAUDIT);
    const singleFAUDIT = useSelector((state) => state.hsseaudit.single);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentFAUDIT, setCurrentFAUDIT] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const [fauditToDelete, setfauditToDelete] = useState(null);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);


    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [FAUDITGenerate, setFAUDITGenerate] = useState(null);

    //SaveButton
    const [isProcessing, setIsProcessing] = useState(false);

    //EYE BUTTON
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedViewRecord, setSelectedRecord] = useState(null);

    const viewRecord = (record) => {
        setSelectedRecord(record);
        setViewModalOpen(true)
    }

    //ActionModal
    const [actionModalOpen, setActionModalOpen] = useState(false);
    const toggleActionModal = () => setActionModalOpen(!actionModalOpen);


    const [actionData, setActionData] = useState({})

    const handleCloseModal = () => {
        resetForm();
        setModalOpen(false);
        setCurrentFAUDIT(null);
    };

    const [accessFilter, setAccessFilter] = useState('My');

    const handleAccessFilterChange = (selectedAccess) => {
        setAccessFilter(selectedAccess);
    };

    const [formData, setFormData] = useState({
        editForm: false,
        site: null,
        siteName: null,
        siteManagerName: null,
        l3Contractor: null,
        interventionType: null,
        auditor: null,
        region: null,
        locationPlace: '',
        reportedAt: new Date().toISOString().split('T')[0],
        selectedHSSEOptions: [],
        reportedByUser: null,
        actions: [],
        attachments: [],
        inspectedBySign: null,
        checkedBySign: null,
        auditedAt: new Date().toISOString().split('T')[0],
        recommendations: null,
        findings: []
    });

    const resetForm = () => {
        setFormData({
            editForm: false, // Ensure this is set to false when resetting
            site: null,
            siteName: null,
            siteManagerName: null,
            l3Contractor: null,
            interventionType: null,
            region: null,
            auditor: null,
            selectedHSSEOptions: [],
            locationPlace: '',
            reportedAt: new Date().toISOString().split('T')[0],
            reportedByUser: null,
            actions: [],
            attachments: [],
            inspectedBySign: null,
            checkedBySign: null,
            auditedAt: new Date().toISOString().split('T')[0],
            recommendations: null,
            findings: []
        });
    };
    const validateForm = () => {
        if (!formData.site) {
            message.error("Site Required *");
            return false;
        }
        if (!formData.l3Contractor) {
            message.error("L3 Contractor is required *");
            return false;
        }
        if (!formData.reportedByUser) {
            message.error("Reported By User required *");
            return false;
        }
        if (!formData.siteName) {
            message.error("Site Name Required *");
            return false;
        }
        return true;
    };
    //
    const handleInputChange = (nameOrEvent, value) => {
        // console.log(nameOrEvent)
        // console.log(value)

        let updatedName, updatedValue;
        if (typeof nameOrEvent === 'string') {

            updatedName = nameOrEvent;
            updatedValue = value;
        } else if (nameOrEvent && nameOrEvent.target) {
            updatedName = nameOrEvent.target.name;
            updatedValue = nameOrEvent.target.value;

        } else {
            console.error('Invalid input to handleInputChange');
            return;
        }


        setFormData(prevData => ({
            ...prevData,
            [updatedName]: updatedValue
        }));
    };
    const handleSubmit = async () => {
        if (!validateForm()) {
            throw new Error("Form validation failed");
        }

        setIsProcessing(true);
        const formDataObj = new FormData();
        formDataObj.append("moduleId",parseInt(access.moduleId))

        if (formData.id) {
            formDataObj.append('id', parseInt(formData.id.toString()));
            formDataObj.append('actionId', parseInt(formData.actionId));
            formDataObj.append('fileId', parseInt(formData.fileId));
            formDataObj.append('findingId', parseInt(formData.findingId));
        }

        if (formData.site?.value) formDataObj.append('siteId', parseInt(formData.site.value));
        if (formData.l3Contractor?.value) formDataObj.append('l3Id', parseInt(formData.l3Contractor.value));
        if (formData.auditor?.value) formDataObj.append('auditorId', parseInt(formData.auditor.value));
        if (formData.region) formDataObj.append('regionId', parseInt(formData.region));

        if (formData.checkedBySign !== "" && formData.checkedBySign !== null && formData.checkedBySign !== undefined) {
            formDataObj.append('siteManagerSign', formData.checkedBySign);
        }
        if (formData.inspectedBySign !== "" && formData.inspectedBySign !== null && formData.inspectedBySign !== undefined) {
            formDataObj.append('auditorSign', formData.inspectedBySign);
        }

        formDataObj.append('recommendation', formData.recommendations || "");
        formDataObj.append('location', formData.locationPlace || "");
        formDataObj.append('reportedAt', formData.reportedAt || "");
        formDataObj.append('auditedAt', formData.auditedAt || "");
        formDataObj.append('siteManagerName', formData.siteManagerName || "");

        if (formData.reportedByUser) formDataObj.append('reportedBy', parseInt(formData.reportedByUser.value));

        if (formData.interventionType?.value) formDataObj.append('interventionType', formData.interventionType.value);

        if (formData.selectedHSSEOptions !== null && formData.selectedHSSEOptions !== undefined) {
            if (formData.editForm === false) {
                if (formData.selectedHSSEOptions.length > 0) {
                    formDataObj.append('audits', JSON.stringify(formData.selectedHSSEOptions));
                } else {
                    formDataObj.append('audits', JSON.stringify([]));
                }
            } else {
                if (formData.selectedHSSEOptions.length > 0) {
                    const isArrayOfStrings = arr => arr.every(item => typeof item === 'string');
                    const isStringArray = isArrayOfStrings(formData.selectedHSSEOptions);
                    if (!isStringArray) {
                        formDataObj.append('audits', JSON.stringify(formData.selectedHSSEOptions.map((element) => element.childrenId)));
                    } else {
                        formDataObj.append('audits', JSON.stringify(formData.selectedHSSEOptions));
                    }
                } else {
                    formDataObj.append('audits', JSON.stringify([]));
                }
            }
        } else {
            formDataObj.append('audits', JSON.stringify([]));
        }

        if (formData.findings !== null && formData.findings !== undefined) {
            if (Array.isArray(formData.findings)) {
                formDataObj.append('findings', JSON.stringify(formData.findings.map(finding => ({
                    id: finding.id || null,
                    path: finding.image || null,
                    description: finding.description || null,
                    itemNo: finding.itemNo || null,
                    findingCategoryId: parseInt(finding.findingCategoryId) || null,
                    recommendation: finding.mhtRecommendation || null,
                    priority: finding.priority || null,
                    createdAt: finding.createdAt || null,
                    createdBy: finding.createdBy || null,
                    updateBy: finding.updateBy || null,
                    updatedAt: finding.updatedAt || null,
                }))));
            } else {
                formDataObj.append('findings', JSON.stringify([]));
            }
        } else {
            formDataObj.append('findings', JSON.stringify([]));
        }

        if (Array.isArray(formData.actions)) {
            formDataObj.append('actions', JSON.stringify(formData.actions.map(action => ({
                id: action.id || null,
                userId: action.userId ? parseInt(action.userId) : null,
                description: action.description || null,
                createdAt: action.createdAt || null,
                createdBy: action.createdBy || null,
                updatedAt: action.updatedAt || null,
                updatedBy: action.updatedBy || null,
                submittedAt: action.submittedAt || null,
                targetAt: action.targetAt || null,
                reply: action.reply || null,
                tags: action.tags || [],
                type: action.type || "N",
                status: action.status || "PENDING",
            }))));
        }

        if (Array.isArray(formData.attachments)) {
            formDataObj.append('files', JSON.stringify(formData.attachments.map(attachment => ({
                id: attachment.id || null,
                fileName: attachment.filename || "",
                description: attachment.description || null,
                path: attachment.path || null,
                createdAt: attachment.createdAt || null,
                createdBy: attachment.createdBy || null,
                updateBy: attachment.updateBy || null,
                updatedAt: attachment.updatedAt || null,
            }))));

            formData.attachments.forEach((attachment, index) => {
                if (attachment.file instanceof File) {
                    formDataObj.append(`attachments[${index}]`, attachment.file, attachment.filename);
                }
            });
        }

        try {
            let result;
            if (formData.id) {
                result = await dispatch(updateFAUDITForm(formDataObj));
            } else {
                result = await dispatch(addFAUDITForm(formDataObj));
            }

            if (result.success) {

                if(formData.id) {
                    showSuccessDialog("HSSE  Updated Successfully")
                }
                else {
                    showSuccessDialog("HSSE Added Successfully")
                }

                //showSuccessDialog(result.message)
                resetForm();
                setModalOpen(false);
                setCurrentFAUDIT(null);
            }
            else {
                showWarningDialog(result.message)
            }
        } catch (ex) {
            console.log("exception", ex);
        } finally {
            setIsProcessing(false);
        }
    };


    useEffect(() => {
        if (singleFAUDIT !== null && FAUDITValues) {
            console.log(singleFAUDIT)
            setCurrentFAUDIT(singleFAUDIT);

            const sitesOptions = FAUDITValues?.sites?.map(site => ({
                value: site.id.toString(),
                label: site.name
            })) || []

            const l3 = FAUDITValues?.l3?.map(site => ({
                value: site.id.toString(),
                label: site.name
            })) || []

            const reporters = FAUDITValues?.reporters?.map(user => ({
                value: user.id.toString(),
                label: user.name
            }))

            const auditors = FAUDITValues?.auditors?.map(user => ({
                value: user.id.toString(),
                label: user.name
            }))


            const selectedSite = sitesOptions.find(source => source.value === singleFAUDIT.siteId?.toString()) || null;
            const selectedL3 = l3.find(source => source.value === singleFAUDIT.l3Id?.toString()) || null;
            const selectedReporter = reporters.find(source => source.value === singleFAUDIT.reportedBy?.toString()) || null;
            const selectedAuditor = auditors.find(source => source.value === singleFAUDIT.auditorId?.toString()) || null;

            let selectedHSSEOptions = []
            if (singleFAUDIT.audits !== null && singleFAUDIT.audits !== undefined) {
                selectedHSSEOptions = FAUDITValues.fauditInstructions.flatMap(section =>
                    section.children
                        .filter(child => singleFAUDIT.audits.includes(child.id))
                        .map(child => ({
                            parentId: section.id,
                            childrenId: child.id,
                            label: child.label
                        }))
                );
            }

            let findings
            if (singleFAUDIT.findings !== undefined && singleFAUDIT.findings !== null) {
                findings = singleFAUDIT.findings.map(finding => ({
                    id: finding.id ?? null,
                    itemNo: finding.itemNo ?? null,
                    findingCategoryId: finding?.findingCategoryId?.toString() ?? null,
                    priority: finding.priority ?? null,
                    description: finding.description ?? null,
                    mhtRecommendation: finding.recommendation ?? null,
                    image: finding.fullPath ?? null
                }))
            } else {
                findings = []
            }


            const editFormData = {
                editForm: true,
                id: parseInt(singleFAUDIT.id),
                actionId: parseInt(singleFAUDIT.actionId),
                fileId: parseInt(singleFAUDIT.fileId),
                findingId: parseInt(singleFAUDIT.findingId),
                auditor: selectedAuditor,
                site: selectedSite,
                l3Contractor: selectedL3,
                locationPlace: singleFAUDIT.location,
                reportedAt: formatDateWithTime(singleFAUDIT.reportedAt, false),
                auditedAt: formatDateWithTime(singleFAUDIT.auditedAt, false),
                reportedByUser: selectedReporter,
                siteName: singleFAUDIT.site,
                siteManagerName: singleFAUDIT.siteManager,
                region: singleFAUDIT.regionId,
                actions: (singleFAUDIT.actions || []).map(action => ({
                    id: action.id,
                    userId: action.userId,
                    description: action.description,
                    targetDays: action.targetDays,
                    createdAt: action.createdAt,
                    createdBy: action.createdBy,
                    updatedAt: action.updatedAt,
                    updatedBy: action.updatedBy,
                    submittedAt: action.submittedAt,
                    targetAt: action.targetAt,
                    reply: action.reply,
                    tags: action.tags,
                    comments: action.comments,
                    type: action.type,
                    status: action.status
                })),
                attachments: (singleFAUDIT.files || []).map(file => ({
                    id: file.id,
                    path: file.path,
                    filename: file.fileName,
                    description: file.description,
                    createdAt: file.createdAt,
                    createdBy: file.createdBy,
                    updatedAt: file.updatedAt,
                    updatedBy: file.updatedBy,
                    fullPath: file.fullPath,
                })),
                selectedHSSEOptions: selectedHSSEOptions,
                findings: findings,
                recommendations: singleFAUDIT.recommendation,
                inspectedBySign: singleFAUDIT.siteManagerFullSign,
                checkedBySign: singleFAUDIT.auditorFullSign
            }
            setFormData(editFormData)
        }
    }, [singleFAUDIT]);

    const handleSelectChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };
    const handleAttachmentChange = (newAttachments) => {
        setFormData({ ...formData, attachments: newAttachments });
    };

    useEffect(() => {
        dispatch(getFAUDITValues());
        dispatch(getUserFAUDIT(access.moduleId));
    }, [dispatch]);


    const handleEdit = async (data) => {
        await dispatch(getSingleFAUDIT(data.id));
        setModalOpen(true);
    };

    useEffect(() => {
        console.log(FAUDITValues);
    }, [FAUDITValues]);


    // const handleDelete = (object) => {
    //     setfauditToDelete(object);
    //     setDeleteModalVisible(true);
    // };

    const handleDelete = async (hsse) => {
        try {
            const result = await showConfirmDialog("Are you sure you want to delete this HSSE Audit?");
            if (result.isConfirmed) {
                const object = await dispatch(deleteFAudit(hsse.id))
                if (object.success) {
                    Swal.fire('Deleted!', object.message, 'success');
                    dispatch(getUserFAUDIT(access.moduleId));
                }
                else {
                    Swal.fire('Error!', object.message, 'error');
                }
            }
        } catch (error) {
            console.error('Error in handleDelete:', error);
        }
    };

    let columns = [
        {
            title: "Action",
            key: "action",
            width: '120px',
            render: (text, data) => (
                <div style={{ display: 'flex', gap: '8px' }}>
                    {data.submittedAt === null && (
                        <Button color="primary" size="sm" onClick={() => handleEdit(data)} disabled={!accessRights.U}>
                            <EditOutlined />
                        </Button>
                    )}
                    {data.submittedAt === null && (<>
                        <Button color="danger" size="sm" onClick={() => handleDelete(data)} disabled={!accessRights.D}>
                            <DeleteOutlined />
                        </Button>
                    </>)}
                    <Button color="info" size="sm" onClick={() => viewRecord(data)} disabled={!accessRights.R}>
                        <EyeFilled />
                    </Button>
                    {data.submittedAt !== null && (
                        <Button color="info" size="sm" onClick={() => handleActionClick(data)} disabled={accessFilter === 'Shared'}>
                            <PlusCircleFilled />
                        </Button>
                    )}

                </div>
            ),
        },
        {
            title: "Sequence Number",
            dataIndex: "submittedAt",
            key: "submittedAt",
            sorter: stringSorter("submittedAt"),
            sortDirections: ["ascend", "descend"],
            render: (text, data) => {
                if (data.submittedAt !== null) {
                    return data.sequenceNo; // Assuming the sequence string is stored in the 'sequence' property
                } else {
                    return (
                        <Button color="primary" size="sm" onClick={() => handleGenerate(data)}>
                            Generate
                        </Button>
                    );
                }
            }
        },
        {
            title: "Reported At",
            dataIndex: "reportedAt",
            key: "reportedAt",
            sorter: dateSorter("reportedAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "L3 Contractor",
            dataIndex: "l3Contractor",
            key: "l3Contractor",
            sorter: stringSorter("l3Contractor"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
            sorter: stringSorter("country"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Business Unit",
            dataIndex: "businessUnit",
            key: "businessUnit",
            sorter: stringSorter("businessUnit"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Project/Program",
            dataIndex: "project",
            key: "project",
            sorter: stringSorter("project"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Site",
            dataIndex: "site",
            key: "site",
            sorter: stringSorter("site"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Location Place",
            dataIndex: "location",
            key: "location",
            sorter: stringSorter("location"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            sorter: stringSorter("description"),
            ellipsis: true,
        },
    ];


    const handleGenerate = async (data) => {
        setFAUDITGenerate(data);
        setConfirmModalOpen(true);
        console.log(confirmModalOpen)
    };
    const confirmFAUDITGenerate = async () => {
        if (FAUDITGenerate) {
            const object={
                moduleId:access.moduleId,
                id:FAUDITGenerate.id
            }
            const response = await dispatch(submitFAUDITToGenerate(object));
            if (response && response.data.code === 1) {
                showSuccessDialog("HSSE Sequence Generated Successfully")
            } else {
                showWarningDialog("Failed to generate HSSE Audit")
            }
            setConfirmModalOpen(false);
            setFAUDITGenerate(null);
        }
    };
    const handleActionClick = (data) => {
        setActionData(data);
        setActionModalOpen(true);
    };
    const handleInitiateAction = async (data) => {
        delete data.submitDate;
        if (data.message) {
            data.reply = actionData.message;
            delete data.message;
        }

        const payload = {
            informTbtId: parseInt(actionData.id),
            id: parseInt(actionData.actionId),
            actions: JSON.stringify(data)
        };

        const response = await dispatch(submitActionForFAUDIT(payload));
        if (response.success) {
            showSuccessDialog(response.message)
            setActionData([]);
            setActionModalOpen(false);
            setModalOpen(false)
            dispatch(getUserFAUDIT(access.moduleId))
        } else {
            showWarningDialog(response.message)
        }

    };
    
    useEffect(() => {
        console.log(userFAUDIT)
    }, [userFAUDIT]);

    const filteredFAudits = useMemo(() => {
        switch (accessFilter) {
            case 'My':
                return [
                    ...(userFAUDIT.user || [])  
                ];
            case 'Shared':
                return userFAUDIT.shared || [];
            case 'Assigned':
                return userFAUDIT.assigned || [];
            default:
                return [];
        }
    }, [userFAUDIT, accessFilter]);


    return (
        <Card>
            <CardHeader>
                <Row className="align-items-center mb-3">
                    <Col md={3}>
                        <h5 className="mb-0">Manage HSSE Audit</h5>
                    </Col>
                    <Col md={9} className="d-flex justify-content-end align-items-center">
                        <AccessFilter
                            accessFilter={accessFilter}
                            handleAccessFilterChange={handleAccessFilterChange}
                            sharedAccess={userFAUDIT?.sharedAccess || []}
                        />
                        <Button color="primary" onClick={() => setModalOpen(true)} className="ms-2" disabled={!accessRights.C}>
                            <PlusCircleFilled size={18} /> New
                        </Button>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <ReactStrapDataTable
                    dataSource={filteredFAudits ?? []}
                    columns={columns}
                    rowKey="id"
                />
            </CardBody>
            <Modal isOpen={modalOpen} toggle={handleCloseModal} size="xl" backdrop="static">
                <Form onSubmit={handleSubmit}>
                    <ModalHeader toggle={handleCloseModal}>
                        {currentFAUDIT ? 'Edit HSSE Audit' : 'Add HSSE Audit'}
                    </ModalHeader>
                    <ModalBody>
                        <AdminSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            data={FAUDITValues}
                        />
                        <FAuditESection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            data={FAUDITValues}
                        />
                        <AuditFindingSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            data={FAUDITValues}
                        />
                        <ActionSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            actionUsers={FAUDITValues?.actionUsers ?? []}
                        />
                        <AttachmentsSection
                            attachments={formData.attachments}
                            onAttachmentsChange={handleAttachmentChange}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleCloseModal}>Back</Button>
                        <DebouncedSaveButton
                            onSave={handleSubmit}
                            isProcessing={isProcessing}
                        />
                    </ModalFooter>
                </Form>
            </Modal>

            <Modal isOpen={confirmModalOpen} toggle={() => setConfirmModalOpen(false)}>
                <ModalHeader toggle={() => setConfirmModalOpen(false)}>Confirm Submission</ModalHeader>
                <ModalBody>
                    <p>Are you sure you want to submit this HSSE Audit?</p>
                    <p style={{ color: 'red' }}>
                        Note: Once the HSSE Audit is submitted it cannot be edited.
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setConfirmModalOpen(false)}>Cancel</Button>
                    <Button color="primary" onClick={confirmFAUDITGenerate}>Yes, Submit</Button>
                </ModalFooter>
            </Modal>

            <ActionModal
                isOpen={actionModalOpen}
                toggle={toggleActionModal}
                data={actionData}
                message={"Action For HSSE Audit"}
                onInitiateAction={handleInitiateAction}
            />
            <ViewHSSEAudit
                isOpen={viewModalOpen}
                toggle={() => setViewModalOpen(!viewModalOpen)}
                data={selectedViewRecord}
                instructions={FAUDITValues?.fauditInstructions ?? []}
            />
        </Card>

    )
}

export default HSSEAudit