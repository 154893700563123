import axios from "../../../axios";
import {ADD_BUSINESS_UNIT, DELETE_BUSINESS_UNIT, GET_BUSINESS_UNITS, GET_BUSINESS_UNIT, UPDATE_BUSINESS_UNIT} from "../../types";
import {setAlert} from "../alert";
import {message} from "antd";

export const getBusinessUnits = () => async (dispatch) => {
    try {
        const res = await axios.get("/business/unit/all");
        
        if (res.data.code === 1) {
            dispatch({type: GET_BUSINESS_UNITS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const addBusinessUnit = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/business/unit/add", formData);
        ;
        if (res.data.code === 1) {
            dispatch({type: ADD_BUSINESS_UNIT, payload: res.data.data});
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const getbusinessUnitsByBusinessGroup = (country) => async (dispatch) => {
    try {
        const res = await axios.post("business/unit/country", {co_id:country});
        if (res.data.code === 1) {
            message.success(res.data.message);
            let payload = Array.isArray(res?.data?.data) ?  res?.data?.data : [];
            dispatch({ type: GET_BUSINESS_UNITS, payload: payload });
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const getBusinessUnit = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/business/unit/${id}`);
        if (res.data.code === 1) {

            dispatch({type: GET_BUSINESS_UNIT, payload: res.data.data});
        }
    } catch (err) {
        // dispatch(setAlert(err.message, "danger"));
    }
};

export const updateBusinessUnit = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(`/business/unit/update`, formData);
        if (res.data.code === 1) {
            dispatch({type: UPDATE_BUSINESS_UNIT, payload: res.data.data});
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        // dispatch(setAlert(err.message, "danger"));
    }
};
export const deleteBusinessUnit = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/business/unit/delete/${id}`);
        if (res.data.code === 1) {
            message.success(res.data.message);
            dispatch({type: DELETE_BUSINESS_UNIT, payload: res.data.data});
        } else {
            message.warning(res.data.message);
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
