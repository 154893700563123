import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from 'classnames';

// i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import withRouter from "../withRouter";
import { getUserLinks, getUserModules, getUserProfile } from "../../../store/actions/user";
import QuickLinks from "../../../Pages/QuickLinks";
import QuickRoutes from "../../../Pages/QuickRoutes";
import UserProfile from "../../../Pages/Authentication/user-profile";
import { Card, CardBody, Row, Col } from 'reactstrap';
import { User, Mail, Briefcase, Calendar, Shield, CheckCircle } from 'lucide-react';
import GlobalQuickRoutes from '../../../Pages/System-admin/GlobalQuickRoutes/index';
import { getNavigation } from "../../../store/actions/navigation";





const ProfileModalContent = ({ userProfile }) => {
  return (
    <Card>
      <CardBody>
        <div className="text-center mb-4">
          <div className="d-flex flex-column align-items-center">
            <div className="avatar-lg rounded-circle bg-light d-flex justify-content-center align-items-center mb-3">
              <User className="text-primary" size={40} />
            </div>
            <div>
              <h5 className="font-size-16 mb-1">{userProfile.name}</h5>
              <p className="text-muted mb-2">{userProfile.userType}</p>
              <p className="text-muted mb-0">{userProfile.organization}</p>
            </div>
          </div>
        </div>

        <h5 className="font-size-15 mb-3">Profile Details</h5>

        <Row>
          <Col md={6}>
            <div className="mb-3">
              <div className="d-flex align-items-center mb-2">
                <User className="text-primary me-2" size={18} />
                <strong>Full Name</strong>
              </div>
              <p className="text-muted">{`${userProfile.fName} ${userProfile.mName} ${userProfile.lName}`}</p>
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <div className="d-flex align-items-center mb-2">
                <Mail className="text-primary me-2" size={18} />
                <strong>Email</strong>
              </div>
              <p className="text-muted">{userProfile.email}</p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <div className="mb-3">
              <div className="d-flex align-items-center mb-2">
                <Briefcase className="text-primary me-2" size={18} />
                <strong>Organization</strong>
              </div>
              <p className="text-muted">{userProfile.organization}</p>
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <div className="d-flex align-items-center mb-2">
                <Shield className="text-primary me-2" size={18} />
                <strong>User Type</strong>
              </div>
              <p className="text-muted">{userProfile.userType}</p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <div className="mb-3">
              <div className="d-flex align-items-center mb-2">
                <Calendar className="text-primary me-2" size={18} />
                <strong>Joined At</strong>
              </div>
              <p className="text-muted">{new Date(userProfile.createdAt).toLocaleDateString()}</p>
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <div className="d-flex align-items-center mb-2">
                <CheckCircle className="text-primary me-2" size={18} />
                <strong>Verification Status</strong>
              </div>
              <p className="text-muted">{userProfile.isVerified ? 'Verified' : 'Not Verified'}</p>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};



const ProfileMenu = props => {
  const dispatch = useDispatch();
  const userLinks = useSelector((state) => state.users.userLinks)
  const userModules = useSelector((state) => state.users.userModules)
  const userProfile = useSelector((state) => state.users.userProfile)
  const [profileName, setProfileName] = useState("User")

  const navigation = useSelector((state) => state.navigation.list);

  const [menu, setMenu] = useState(false);
  const [username, setUsername] = useState("Admin");
  const [quickLinksModal, setQuickLinksModal] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const [activeTab, setActiveTab] = useState('1');

  const [hasQuickModule, setQuickModule] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setUsername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setUsername(obj.username);
      }
    }
  }, [props.success]);

  const toggleQuickLinksModal = () => setQuickLinksModal(!quickLinksModal);
  const toggleProfileModal = () => setProfileModal(!profileModal);
  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    dispatch(getUserLinks())
    dispatch(getUserModules())
    dispatch(getUserProfile())
    dispatch(getNavigation())
  }, [dispatch]);

  useEffect(() => {
    if (userProfile != null) {
      setProfileName(userProfile.name)
    }
  }, [userProfile]);

  
  
  const hasPermission = (data) => data.some(item => item.module_route === "quick/module");
 

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item"
          id="page-header-user-dropdown"
          tag="button"
        >
          <i className="ri-user-line header-profile-user" />
          <span className="d-none d-xl-inline-block ms-2 me-2">{profileName}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem onClick={toggleProfileModal}>
            {" "}
            <i className="ri-user-line align-middle me-2" />
            {props.t("Profile")}{" "}
          </DropdownItem>
          <div className="dropdown-divider" />
          <DropdownItem onClick={toggleQuickLinksModal}>
            <i className="ri-link align-middle me-2" />
            Quick Links
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="ri-shut-down-line align-middle me-2 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>

      <Modal isOpen={quickLinksModal} toggle={toggleQuickLinksModal} size="xl">
        <ModalHeader toggle={toggleQuickLinksModal}></ModalHeader>
        <ModalBody>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => { toggleTab('1'); }}
              >
                Commonly Used Forms
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => { toggleTab('2'); }}
              >
                Bulletins
              </NavLink>
            </NavItem>
            {hasPermission(navigation ?? []) && (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => { toggleTab('3'); }}
                >
                  Global Commonly Used Forms
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <QuickRoutes />
            </TabPane>
            <TabPane tabId="2">
              <QuickLinks />
            </TabPane>
            <TabPane tabId="3">
              <GlobalQuickRoutes />
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>

      <Modal isOpen={profileModal} toggle={toggleProfileModal} size="lg">
        <ModalHeader toggle={toggleProfileModal}>Profile</ModalHeader>
        <ModalBody>
          <ProfileModalContent userProfile={userProfile} />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);