import axios from "../../../../axios";
import {COMPETENCY_FILTER_VALUES} from "../../../types";
import {setAlert} from "../../alert";

export const getCompetencyReportValues=()=>async(dispatch)=>{
    try {
        const res = await axios.get("competency/reports/values");
        if (res.data.code === 1) {
            dispatch({type: COMPETENCY_FILTER_VALUES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}