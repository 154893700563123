import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import { DeleteOutlined, EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import ReactStrapDataTable from "../../../CustomDatatable/ReactStrapDataTable";
import { getSites, addSite, updateSite, deleteSite, getSiteValues } from "../../../../store/actions/business/site";
import { dateTimeSort, stringSorter } from "../../../../utils/functions";
import Select from 'react-select';

const Site = () => {
    const dispatch = useDispatch();
    const sites = useSelector((state) => state.site.list);
    const siteValues = useSelector((state) => state.site.values);

    const [modalOpen, setModalOpen] = useState(false);
    const [editingSite, setEditingSite] = useState(null);

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        dispatch(getSites());
        dispatch(getSiteValues());
    }, [dispatch]);

    useEffect(()=>{
        console.log("sites")
        console.log(sites)
    },[sites])

    const handleEdit = (site) => {
        const cityOption = siteValues.cities.find(city => city.id === site.ciId);
        const projectOption = siteValues.projects.find(project => project.id === site.prId);

        setEditingSite(site);
        reset({
            name: site.name,
            city: cityOption ? { value: cityOption.id, label: cityOption.name } : null,
            project: projectOption ? { value: projectOption.id, label: projectOption.name } : null,
            // Add other fields as necessary
        });
        setModalOpen(true);
    };

    const handleDelete = async (site) => {
        if (window.confirm("Are you sure you want to delete this Site?")) {
            await dispatch(deleteSite(site.id));
            dispatch(getSites());
        }
    };

    const onFormSubmit = (data) => {
        const formData = {
            name: data.name,
            ciId: data.city.value,
            prId: data.project.value,
            // Add other fields as necessary
        };
        if (editingSite) {
            dispatch(updateSite({ ...formData, id: parseInt(editingSite.id) }));
        } else {
            dispatch(addSite(formData));
        }
        handleCloseModal();
        dispatch(getSites());
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setEditingSite(null);
        reset();
    };

    const handleAddNew = () => {
        setEditingSite(null);
        reset({ name: '', code: '', city: null, project: null });
        setModalOpen(true);
    };

    const columns = [
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: dateTimeSort("createdAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: stringSorter("name"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "City",
            dataIndex: "city",
            key: "city",
            sorter: stringSorter("city"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Project",
            dataIndex: "projectname",
            key: "projectname",
            sorter: stringSorter("projectName"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
                <>
                    <Button color="link" onClick={() => handleEdit(record)}>
                        <EditOutlined />
                    </Button>
                    <Button color="link" onClick={() => handleDelete(record)}>
                        <DeleteOutlined />
                    </Button>
                </>
            ),
        },
    ];

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">Manage Sites</h5>
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" onClick={handleAddNew}>
                                <PlusCircleFilled /> Add New
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactStrapDataTable
                        dataSource={sites}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} backdrop="static"> 
                <ModalHeader toggle={handleCloseModal}>
                    {editingSite ? 'Edit Site' : 'Add Site'}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: "Name is required",
                                    pattern: {
                                        value: /\S/,
                                        message: "Blank space is not allowed.",
                                    },
                                }}
                                render={({ field }) => <Input {...field} id="name" />}
                            />
                            {errors.name && <span className="text-danger">{errors.name.message}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="city">City</Label>
                            <Controller
                                name="city"
                                control={control}
                                rules={{ required: "City is required" }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={siteValues.cities.map(city => ({
                                            value: city.id,
                                            label: city.name
                                        }))}
                                    />
                                )}
                            />
                            {errors.city && <span className="text-danger">{errors.city.message}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="project">Project</Label>
                            <Controller
                                name="project"
                                control={control}
                                rules={{ required: "Project is required" }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={siteValues.projects.map(project => ({
                                            value: project.id,
                                            label: project.name
                                        }))}
                                    />
                                )}
                            />
                            {errors.project && <span className="text-danger">{errors.project.message}</span>}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {editingSite ? 'Update' : 'Save'}
                        </Button>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
};

export default Site;