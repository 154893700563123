import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import { DeleteOutlined, EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import ReactStrapDataTable from "../../../CustomDatatable/ReactStrapDataTable";
import { getOrganizations, addOrganization, updateOrganization, getOrganizationValues } from "../../../../store/actions/organization";
import { dateTimeSort, stringSorter } from "../../../../utils/functions";
import Select from 'react-select';

const Organization = () => {
    const dispatch = useDispatch();
    const organizations = useSelector((state) => state.organizations.list);
    const orgValues = useSelector((state) => state.organizations.values);

    const [modalOpen, setModalOpen] = useState(false);
    const [editingOrganization, setEditingOrganization] = useState(null);

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
        setValue,
    } = useForm();

    useEffect(() => {
        dispatch(getOrganizations());
        dispatch(getOrganizationValues());
    }, [dispatch]);

    const handleEdit = (organization) => {
        const companyTypeOptions = orgValues.companyTypes.filter(ct => 
            organization.companyTypeId.includes(ct.id)
        ).map(ct => ({ value: ct.id, label: ct.name }));

        setEditingOrganization(organization);
        reset({
            name: organization.name,
            code: organization.code,
            companyTypes: companyTypeOptions,
        });
        setModalOpen(true);
    };

    const handleDelete = async (organization) => {
        if (window.confirm("Are you sure you want to delete this Organization?")) {
           // await dispatch(deleteOrganization(organization.id));
            dispatch(getOrganizations());
        }
    };

    const onFormSubmit = (data) => {
        const formData = {
            name: data.name,
            code: data.code,
            companyTypeId: JSON.stringify(data.companyTypes.map(ct => parseInt(ct.value))),
        };
        if (editingOrganization) {
            dispatch(updateOrganization({ ...formData, id: editingOrganization.id }));
        } else {
            dispatch(addOrganization(formData));
        }
        handleCloseModal();
        dispatch(getOrganizations());
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setEditingOrganization(null);
        reset();
    };

    const handleAddNew = () => {
        setEditingOrganization(null);
        reset({ name: '', code: '', companyTypes: [] });
        setModalOpen(true);
    };

    const columns = [
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: dateTimeSort("createdAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: stringSorter("name"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Code",
            dataIndex: "code",
            key: "code",
            sorter: stringSorter("code"),
            sortDirections: ["ascend", "descend"],
        },
        // {
        //     title: "Company Types",
        //     dataIndex: "companyTypeId",
        //     key: "companyTypeId",
        //     render: (companyTypeIds) => companyTypeIds.map(id => 
        //         orgValues.companyTypes.find(ct => ct.id === id)?.name
        //     ).join(", "),
        // },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
                <>
                    <Button color="link" onClick={() => handleEdit(record)}>
                        <EditOutlined />
                    </Button>
                    <Button color="link" onClick={() => handleDelete(record)}>
                        <DeleteOutlined />
                    </Button>
                </>
            ),
        },
    ];

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">Manage Organizations</h5>
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" onClick={handleAddNew}>
                                <PlusCircleFilled /> Add New
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactStrapDataTable
                        dataSource={organizations}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} backdrop="static">
                <ModalHeader toggle={handleCloseModal}>
                    {editingOrganization ? 'Edit Organization' : 'Add Organization'}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: "Name is required",
                                    pattern: {
                                        value: /\S/,
                                        message: "Blank space is not allowed.",
                                    },
                                }}
                                render={({ field }) => <Input {...field} id="name" />}
                            />
                            {errors.name && <span className="text-danger">{errors.name.message}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="code">Code</Label>
                            <Controller
                                name="code"
                                control={control}
                                rules={{
                                    required: "Code is required",
                                    pattern: {
                                        value: /\S/,
                                        message: "Blank space is not allowed.",
                                    },
                                }}
                                render={({ field }) => <Input {...field} id="code" />}
                            />
                            {errors.code && <span className="text-danger">{errors.code.message}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="companyTypes">Company Types</Label>
                            <Controller
                                name="companyTypes"
                                control={control}
                                rules={{ required: "Company Types are required" }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        isMulti
                                        options={orgValues.companyTypes.map(ct => ({
                                            value: ct.id,
                                            label: ct.name
                                        }))}
                                    />
                                )}
                            />
                            {errors.companyTypes && <span className="text-danger">{errors.companyTypes.message}</span>}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {editingOrganization ? 'Update' : 'Save'}
                        </Button>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
};

export default Organization;