import {ADD_ORGANIZATION, GET_ORGANIZATION_VALUES, GET_ORGANIZATIONS, UPDATE_ORGANIZATION} from "../types";


const initialState = {list: [], single: null,values:[]};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_ORGANIZATIONS:
            return {...state, list: payload};
        case GET_ORGANIZATION_VALUES:
            return {...state, values: payload};
        case ADD_ORGANIZATION:
            return {
                ...state,
                list: [payload, ...state.list],
            };
        case UPDATE_ORGANIZATION:
            return {
                ...state,
                list: state.list.map((item) =>
                    item.id === payload.id ? payload : item
                ),
            };
        default:
            return state;
    }
};

export default reducer;
