import {GET_PARENT_CABINET, GET_CHILD_CABINET_BY_PARENT, UPDATE_CABINET_HIERARCHY} from "../../types";


const initialState = {parent: [], child: []};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_PARENT_CABINET:
            console.log('Reducer: Updating parent cabinet with:', action.payload);
            return {...state, parent: Array.isArray(payload) ? payload : []};

        case GET_CHILD_CABINET_BY_PARENT:
            return {...state, child: payload};

        case UPDATE_CABINET_HIERARCHY:
            return { ...state, parent: [...payload] }

        default:
            return state;
    }
};

export default reducer;
