import React from 'react';
import styled from 'styled-components';

// Define a styled component for the red asterisk
const RedAsterisk = styled.span`
    color: red;
`;

// Example usage within a functional component
const RedAsteriskComponent = () => {
    return <RedAsterisk>*</RedAsterisk>;
};

const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export { RedAsteriskComponent, LabelWrapper };