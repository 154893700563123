import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    completeCourse,
    getUserEnrolledCourses,
    updateCourseHistoryTopic,
} from "../../../store/actions/competancy/courses";
import {
    Card,
    CardBody,
    CardText,
    CardTitle,
    Badge,
    Container,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    Progress,
    ListGroup,
    ListGroupItem,
    Button,
    ModalFooter,
} from "reactstrap";
import {getTopicsByCourseId} from "../../../store/actions/competancy/topics";
import {
    UpOutlined,
    DownOutlined,
    CheckCircleOutlined,
    PlayCircleOutlined,
    LockOutlined,
    PlayCircleFilled,
    PauseCircleFilled,
    SoundFilled,
    SoundOutlined,
    FullscreenExitOutlined,
    FullscreenOutlined,
    CalendarOutlined,
    ExclamationCircleOutlined,
    FileTextOutlined,
    DownloadOutlined,
    CloseCircleFilled,
    CheckCircleFilled,
} from "@ant-design/icons";
import {ZoomIn,ZoomOut } from 'lucide-react';
import ReactPlayer from "react-player";
import {
    getGivenTests,
    getQuestionQuiz,
    submitQuiz,
} from "../../../store/actions/competancy/question";
import {buttonCardStyle, cardStyle} from "../../../constants/layout";
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import Certificate from "../Certificate";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();


const PDFViewer = ({ pdfUrl, currentTopicId, updateCourseHistory, moveToNextTopic,chapterState,topicChanged,previousTopic}) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(1);
    const [pdfFile, setPdfFile] = useState(null);
    const [error, setError] = useState(null);



    useEffect(() => {
        if (chapterState.currentChapter) {
            if (chapterState.isNewChapter) {
                setPageNumber(1)
            } 
        }
    }, [chapterState]);

    useEffect(() => {
        if (topicChanged) {
            setPageNumber(1);
        }
      }, [topicChanged, previousTopic, currentTopicId]);



    useEffect(() => {
        const fetchPdf = async () => {
            try {
                const response = await fetch(pdfUrl);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const blob = await response.blob();
                const file = new File([blob], "document.pdf", { type: "application/pdf" });
                setPdfFile(file);
                setError(null);
            } catch (error) {
                setError("Failed to load PDF. Please try again later.");
            }
        };

        fetchPdf();
    }, [pdfUrl]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        //console.log('PDF loaded successfully. Number of pages:', numPages);
        setNumPages(numPages);
        if (numPages === 1) {
            handleLastPage();
        }
    };

    const onDocumentLoadError = (error) => {
    
        setError("Failed to load PDF. The file might be corrupted or in an unsupported format.");
    };

    const goToPrevPage = () =>
        setPageNumber(prevPageNumber => Math.max(prevPageNumber - 1, 1));

    const goToNextPage = () => {
        setPageNumber(prevPageNumber => {
            const nextPage = Math.min(prevPageNumber + 1, numPages);
            if (nextPage === numPages) {
                handleLastPage();
            }
            return nextPage;
        });
    };

    const handleLastPage = async () => {
        if (currentTopicId) {
            
            // moveToNextTopic();
        }
    };

    const handleComplete = async () => {
        await updateCourseHistory(currentTopicId);
        moveToNextTopic();
    };

    const zoomIn = () => setScale(prevScale => Math.min(prevScale + 0.1, 2));
    const zoomOut = () => setScale(prevScale => Math.max(prevScale - 0.1, 0.5));

    if (error) {
        return <div className="text-red-500">{error}</div>;
    }

    if (!pdfFile) {
        return <div>Loading PDF...</div>;
    }



    return (
        <div className="flex flex-col h-full w-full">
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px',
                backgroundColor: '#f2f2f2'
            }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Button
                        style={{padding: '4px', border: '1px solid #ccc', marginRight: '8px'}}
                        onClick={goToPrevPage}
                        disabled={pageNumber <= 1}
                    >
                        Previous
                    </Button>
                    <Button
                        style={{padding: '4px', border: '1px solid #ccc', marginRight: '8px'}}
                        onClick={goToNextPage}
                        disabled={pageNumber >= numPages}
                    >
                        Next
                    </Button>
                    <center><p style={{margin: '0', fontSize: '14px', color: '#666'}}>
                        Page {pageNumber} of {numPages}
                    </p></center>
                    <Button
                        color="primary"
                        onClick={handleComplete}
                        disabled={pageNumber !== numPages}
                    >
                        Complete
                    </Button>
                </div>
                <div style={{display: 'flex', gap: '8px'}}>
                    <Button style={{padding: '4px', border: '1px solid #ccc'}} onClick={zoomOut}>
                        <ZoomOut style={{height: '16px', width: '16px'}}/>
                    </Button>
                    <Button style={{padding: '4px', border: '1px solid #ccc'}} onClick={zoomIn}>
                        <ZoomIn style={{height: '16px', width: '16px'}}/>
                    </Button>
                </div>
            </div>
            <div className="flex-grow overflow-auto w-full bg-gray-200 flex justify-center items-center">
                <Document
                    file={pdfFile}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={onDocumentLoadError}
                    className="flex justify-center items-center"
                >
                    <Page
                        pageNumber={pageNumber}
                        scale={scale}
                        className="shadow-lg"
                    />
                </Document>
            </div>
        </div>
    );
};
const ResultModal = ({isOpen, toggle, examData}) => {
    const [selectedExamIndex, setSelectedExamIndex] = useState(0);

    if (!examData || examData.length === 0) {
        return null;
    }

    const renderDateList = () => {
        console.log('ResultModal: Rendering date list');
        return (
            <Card className="mb-3">
                <CardBody>
                    <h5 className="mb-3">Exam Dates</h5>
                    {examData.map((exam, index) => {
                        return (
                            <Button
                                key={exam.id}
                                color={index === selectedExamIndex ? "primary" : "secondary"}
                                className="mb-2 d-flex align-items-center"
                                onClick={() => {
                                    setSelectedExamIndex(index);
                                }}
                            >
                                <CalendarOutlined className="mr-2"/>
                                {new Date(exam.createdAt).toLocaleDateString()}
                            </Button>
                        );
                    })}
                </CardBody>
            </Card>
        );
    };

    const renderQuestionNavigator = () => {
        const currentExam = examData[selectedExamIndex];
        return (
            <Card className="mb-3">
                <CardBody>
                    <h5 className="mb-3">Question Navigator</h5>
                    <div className="d-flex flex-wrap">
                        {currentExam.quiz.map((question, index) => {
                            return (
                                <Button
                                    key={index}
                                    color={
                                        question.answer === question.myAnswer ? "success" : "danger"
                                    }
                                    className="m-1"
                                >
                                    {index + 1}
                                </Button>
                            );
                        })}
                    </div>
                </CardBody>
            </Card>
        );
    };

    const renderExamSummary = () => {
        const currentExam = examData[selectedExamIndex];
        return (
            <Card>
                <CardBody>
                    <h5 className="mb-3">Exam Summary</h5>
                    <p>Total Questions: {currentExam.totalMarks}</p>
                    <p>Correct Answers: {currentExam.myMarks}</p>
                    <p>
                        Score:{" "}
                        {((currentExam.myMarks / currentExam.totalMarks) * 100).toFixed(2)}%
                    </p>
                </CardBody>
            </Card>
        );
    };

    const renderQuestions = () => {
        const currentExam = examData[selectedExamIndex];
        return currentExam.quiz.map((question, index) => {
            if (question.answer === question.myAnswer) {
                return null; // Skip correct attempts
            }
            return (
                <Card key={index} className="mb-3">
                    <CardBody>
                        <h5 className="mb-3 mr-3">Question {index + 1}<CloseCircleFilled className="ml-2 text-danger"/></h5>
                        <p>{question.question}</p>
                        {["A", "B", "C", "D"].map((option) => (
                            <div
                                key={option}
                                className={`mb-2 ${question.myAnswer === option ? "font-weight-bold" : ""}`}
                            >
                                {option}: {question[option]}

                            </div>
                        ))}
                    </CardBody>
                </Card>
            );
        }).filter(Boolean); // Remove null elements (correct attempts)
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="xl" fullscreen={true}>
            <ModalHeader toggle={toggle}>Exam Results</ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={3}>
                        {renderDateList()}
                        {/*{renderQuestionNavigator()}*/}
                        {renderExamSummary()}
                    </Col>

                    <Col md={9}>{renderQuestions()}</Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};
const ExamModal = ({
                       isOpen,
                       toggle,
                       questions,
                       courseId,
                       courseName,
                       dispatch,
                       setResult,
                   }) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState({});
    const [isCloseDialogOpen, setIsCloseDialogOpen] = useState(false);
    const [isSubmitDialogOpen, setIsSubmitDialogOpen] = useState(false);
    const [showResultModal, setShowResultModal] = useState(false);
    const [examResult, setExamResult] = useState(null);

    if (!questions || questions.length === 0) {
        return null;
    }

    const handleAnswerSelect = (questionIndex, answer) => {
        setAnswers({...answers, [questionIndex]: answer});
    };

    const renderQuestion = (question, index) => (
        <Card className="mb-4 p-3" style={cardStyle}>
            <h4 className="mb-4">{`Question ${index + 1}: ${question.question_text}`}</h4>
            {["A", "B", "C", "D"].map((option) => (
                <Button
                    key={option}
                    color={answers[index] === option ? "primary" : "secondary"}
                    className="mb-2 d-flex align-items-center"
                    style={{
                        width: "100%",
                        justifyContent: "flex-start",
                        textAlign: "left",
                    }}
                    onClick={() => handleAnswerSelect(index, option)}
                >
                    <span style={{minWidth: "30px"}}>{option}:</span>
                    <span>{question[`option_${option.toLowerCase()}`]}</span>
                </Button>
            ))}
        </Card>
    );
    const renderQuestionGrid = () => (
        <Card className="p-3">
            <div className="d-flex flex-wrap justify-content-center">
                {questions.map((_, index) => (
                    <Button
                        key={index}
                        color={answers[index] ? "success" : "danger"}
                        className="m-1"
                        onClick={() => setCurrentQuestionIndex(index)}
                    >
                        {index + 1}
                    </Button>
                ))}
            </div>
        </Card>
    );
    const handleSubmit = async () => {
        const totalQuestions = questions.length;
        const attemptedQuestions = Object.keys(answers).length;
        const correctAnswers = questions.reduce((count, question, index) => {
            return count + (answers[index] === question.correct_answer ? 1 : 0);
        }, 0);

        const payload = {
            type: "COURSE",
            courseId: courseId,
            quiz: JSON.stringify(
                questions.map((question, index) => ({
                    id: Date.now() + index,
                    question: question.question_text,
                    A: question.option_a,
                    B: question.option_b,
                    C: question.option_c,
                    D: question.option_d,
                    answer: question.correct_answer,
                    myAnswer: answers[index] || "",
                    mandatory: question.is_mandatory,
                })),
            ),
            totalMarks: totalQuestions,
            myMarks: correctAnswers,
        };

        const result = await dispatch(submitQuiz(payload));
        if (result.success) {
            setResult(result.data);
            setExamResult({
                totalQuestions,
                attemptedQuestions,
                correctAnswers,
            });
            setShowResultModal(true);
        }
    };
    const handleClose = () => {
        setIsCloseDialogOpen(false);
        toggle();
    };
    const closeResultModal = () => {
        setShowResultModal(false);
        setCurrentQuestionIndex(0);
        setAnswers({});
        setIsSubmitDialogOpen(false);
        setIsCloseDialogOpen(false);
        toggle();
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={() => setIsCloseDialogOpen(true)}
                size="xl"
                fullscreen={true}
            >
                <ModalHeader
                    toggle={() => setIsCloseDialogOpen(true)}
                    style={{
                        borderBottom: "none",
                        padding: "20px",
                        backgroundColor: "#f8f9fa", // Light gray background
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <h4 style={{marginBottom: "0", fontWeight: "bold"}}>
                        Assesment of: {courseName || "Exam"}
                    </h4>
                </ModalHeader>
                <div className="p-4">
                    <Row>
                        <Col md={8}>
                            {renderQuestion(
                                questions[currentQuestionIndex],
                                currentQuestionIndex,
                            )}
                            <div className="d-flex justify-content-between">
                                <Button
                                    color="primary"
                                    onClick={() =>
                                        setCurrentQuestionIndex((prev) => Math.max(0, prev - 1))
                                    }
                                    disabled={currentQuestionIndex === 0}
                                >
                                    Previous
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={() =>
                                        setCurrentQuestionIndex((prev) =>
                                            Math.min(questions.length - 1, prev + 1),
                                        )
                                    }
                                    disabled={currentQuestionIndex === questions.length - 1}
                                >
                                    Next
                                </Button>
                            </div>
                        </Col>
                        <Col md={4}>
                            <Card className="p-3 mb-4 mt-4" style={cardStyle}>
                                <h4>Exam Summary</h4>
                                <p>Total Questions: {questions.length}</p>
                                <p>Attempted: {Object.keys(answers).length}</p>
                                <p>
                                    Remaining: {questions.length - Object.keys(answers).length}
                                </p>
                            </Card>
                            <Card className="p-3 mb-4" style={cardStyle}>
                                <h4>Question Navigator</h4>
                                {renderQuestionGrid()}
                            </Card>
                            <Button
                                style={buttonCardStyle}
                                color="success"
                                block
                                onClick={() => setIsSubmitDialogOpen(true)}
                            >
                                Submit Exam
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Modal>

            {/* Close Confirmation Dialog */}
            <Modal
                isOpen={isCloseDialogOpen}
                toggle={() => setIsCloseDialogOpen(false)}
            >
                <ModalHeader toggle={() => setIsCloseDialogOpen(false)}>
                    Confirm Close
                </ModalHeader>
                <ModalBody>Are you sure you want to close the exam?</ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setIsCloseDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleClose}>
                        Close Exam
                    </Button>
                </ModalFooter>
            </Modal>

            {/* Submit Confirmation Dialog */}
            <Modal
                isOpen={isSubmitDialogOpen}
                toggle={() => setIsSubmitDialogOpen(false)}
            >
                <ModalHeader toggle={() => setIsSubmitDialogOpen(false)}>
                    Confirm Submission
                </ModalHeader>
                <ModalBody>Are you sure you want to submit this exam?</ModalBody>
                <ModalFooter>
                    <Button
                        color="secondary"
                        onClick={() => setIsSubmitDialogOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleSubmit}>
                        Submit Exam
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={showResultModal} toggle={closeResultModal}>
                <ModalHeader toggle={closeResultModal}>Exam Results</ModalHeader>
                <ModalBody>
                    <Card body style={cardStyle}>
                        <h4 className="mb-3">Exam Summary</h4>
                        <div className="d-flex justify-content-between">
                            <p>Total Questions:</p>
                            <p style={{color: "#3498db"}}>{examResult?.totalQuestions}</p>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p>Attempted Questions:</p>
                            <p style={{color: "#2ecc71"}}>
                                {examResult?.attemptedQuestions}
                            </p>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p>Correct Answers:</p>
                            <p style={{color: "#f1c40f"}}>{examResult?.correctAnswers}</p>
                        </div>
                    </Card>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={closeResultModal}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
const CourseContentModal = ({
                                title,
                                isOpen,
                                toggle,
                                courseData,
                                topics,
                                updateCourseHistory,
}) => {


    const [expandedChapters, setExpandedChapters] = useState({});
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [progress, setProgress] = useState(0);
    const [currentTopicId, setCurrentTopicId] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const playerRef = useRef(null);

    const [chapterState, setChapterState] = useState({
        currentChapter: null,
        isNewChapter: false,
        visitedChapters: new Set()
    });

    
    const [previousTopic, setPreviousTopic] = useState(null);
    const [topicChanged, setTopicChanged] = useState(false);
    

    useEffect(() => {
        if (selectedTopic !== previousTopic) {
            setPreviousTopic(selectedTopic);
            setTopicChanged(true);
        } else {
            setTopicChanged(false);
        }
    }, [selectedTopic, previousTopic]);

    

    const renderContent = () => {
        if (!selectedTopic) {
            return null;
        }

        if (selectedTopic.path.endsWith('.pdf')) {
            return (
                <PDFViewer
                    pdfUrl={selectedTopic.fullpath}
                    currentTopicId={currentTopicId}
                    updateCourseHistory={updateCourseHistory}
                    moveToNextTopic={moveToNextVideo}
                    chapterState={chapterState}
                    topicChanged={topicChanged}
                    previousTopic={previousTopic}
                    
                />
            );
        } else {

            return renderVideoPlayer();
        }
    };

    useEffect(() => {
        if (!isOpen) {
            setProgress(0);
            setIsPlaying(false);
        }
    }, [isOpen]);

    const toggleChapter = (chapterId) => {
 
        setExpandedChapters((prev) => ({
            ...prev,
            [chapterId]: !prev[chapterId],
        }));

        setChapterState(prevState => {
            const isNewChapter = !prevState.visitedChapters.has(chapterId);
            return {
                currentChapter: chapterId,
                isNewChapter: isNewChapter,
                visitedChapters: new Set([...prevState.visitedChapters, chapterId])
            };
        });
    };

    const groupTopicsByChapter = () => {
        return topics.reduce((acc, topic) => {
            if (!acc[topic.chapterId]) {
                acc[topic.chapterId] = {
                    chapterName: topic.chapter,
                    chapterNo: topic.chapterNo,
                    topics: [],
                };
            }
            acc[topic.chapterId].topics.push(topic);
            return acc;
        }, {});
    };

    const chaptersWithTopics = groupTopicsByChapter();

    const isTopicEnabled = (topic) => {
        const topicIndex = topics.findIndex((t) => t.id === topic.id);
        if (topicIndex === 0) return true;
        const previousTopics = topics.slice(0, topicIndex);
        const lastWatchedIndex = previousTopics.findLastIndex((t) => t.watched);
        return (
            topicIndex === lastWatchedIndex + 1 ||
            previousTopics.every((t) => t.watched)
        );
    };

    const handleVideoSelect = (topic) => {
        if (isTopicEnabled(topic)) {
            setSelectedVideo(topic.fullpath);
            setSelectedTopic(topic);
            setCurrentTopicId(topic.id);
            setIsPlaying(true);
            setCurrentTime(0);
        }
    };

    const handlePlayPause = () => {
        setIsPlaying(!isPlaying);
    };

    const handleMuteUnmute = () => {
        setIsMuted(!isMuted);
    };

    const handleFullscreenToggle = () => {
        if (playerRef.current) {
            setCurrentTime(playerRef.current.getCurrentTime());
        }
        setIsFullscreen(!isFullscreen);
    };

    const handleVideoProgress = (state) => {
        setCurrentTime(state.playedSeconds);
    };

    useEffect(() => {
        const watchedCount = topics.filter((topic) => topic.watched).length;
        const newProgress = (watchedCount / topics.length) * 100;
        setProgress(newProgress);
    }, [topics]);

    const handleVideoEnd = async () => {
        if (currentTopicId) {
            await updateCourseHistory(currentTopicId);
            moveToNextVideo();
        }
    };


    const moveToNextVideo = () => {
        const currentIndex = topics.findIndex(
            (topic) => topic.id === currentTopicId,
        );
        if (currentIndex < topics.length - 1) {
            const nextTopic = topics[currentIndex + 1];
            setSelectedVideo(nextTopic.fullpath);
            setSelectedTopic(nextTopic);
            setCurrentTopicId(nextTopic.id);
            setCurrentTime(0);
        }
    };


    const renderVideoPlayer = () => (
        <ReactPlayer
            ref={playerRef}
            url={selectedVideo}
            width="100%"
            height="100%"
            playing={isPlaying}
            volume={isMuted ? 0 : 0.5}
            controls={selectedTopic?.watched}
            onEnded={handleVideoEnd}
            onProgress={handleVideoProgress}
            progressInterval={1000}
            config={{
                file: {
                    attributes: {
                        controlsList: "nodownload",
                        playsInline: true,
                    },
                },
            }}
            key={`${selectedVideo}-${isFullscreen}`}
            onStart={() => playerRef.current && playerRef.current.seekTo(currentTime)}
        />
    );

    const renderCustomControls = () => (
        <div className="custom-controls mb-3 d-flex justify-content-center">
            <Button
                className={`control-button ${isPlaying ? "pause" : "play"}`}
                onClick={handlePlayPause}
            >
                {isPlaying ? <PauseCircleFilled/> : <PlayCircleFilled/>}
            </Button>
            <Button
                className={`control-button ms-3 ${isMuted ? "unmute" : "mute"}`}
                onClick={handleMuteUnmute}
            >
                {isMuted ? <SoundOutlined/> : <SoundFilled/>}
            </Button>
            <Button onClick={handleFullscreenToggle} className="ms-3">
                {isFullscreen ? <FullscreenExitOutlined/> : <FullscreenOutlined/>}
            </Button>
        </div>
    );

    return (
        <>
            <Modal
                isOpen={isOpen && !isFullscreen}
                toggle={toggle}
                size="xl"
                className="course-content-modal"
                fullscreen={true}
            >
                <ModalHeader
                    toggle={toggle}
                    style={{
                        borderBottom: "none",
                        padding: "20px",
                        backgroundColor: "#f8f9fa", // Light gray background
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <h4 style={{marginBottom: "0", fontWeight: "bold"}}>
                        Course: {title || "Course Content"}
                    </h4>
                </ModalHeader>
                <ModalBody style={{padding: "20px"}}>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <Card style={cardStyle}>
                                <CardBody>
                                    <h6 style={{marginBottom: "10px"}}>Chapter Progress</h6>
                                    <Progress
                                        value={progress}
                                        style={{
                                            height: "12px",
                                            marginBottom: "10px",
                                            striped: true,
                                        }}
                                    />
                                    <small style={{color: "#888"}}>
                                        {progress.toFixed(0)}% Complete
                                    </small>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                    <div className="row h-[calc(100vh-200px)]">
                        <div className="col-lg-8 h-full">
                            <Card className="w-full h-full flex flex-col" style={cardStyle}>
                                <CardBody className="flex-grow flex flex-col p-0">
                                    <div
                                        className="relative flex-grow w-full h-full"
                                        style={{
                                            backgroundColor: "rgba(0, 0, 0, 0.2)",
                                        }}
                                    >
                                        {selectedTopic && renderContent()}
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-lg-4">
                        <div
                                style={{
                                    maxHeight: "600px",
                                    overflowY: "auto",
                                    backgroundColor: "#f1f3f4",
                                }}
                            >
                                <ListGroup flush>
                                    {" "}
                                    {Object.entries(chaptersWithTopics).map(
                                        ([chapterId, chapter]) => (
                                            <React.Fragment key={chapterId}>
                                                <ListGroupItem
                                                    className="d-flex justify-content-between align-items-center chapter-header"
                                                    onClick={() => toggleChapter(chapterId)}
                                                    style={{
                                                        cursor: "pointer",
                                                        fontWeight:
                                                            "bold" /* Bold font for chapter headers */,
                                                        backgroundColor: expandedChapters[chapterId]
                                                            ? "#e0e8ef"
                                                            : "#fff" /* Background color change on expansion */,
                                                        padding:
                                                            "10px 15px" /* Adjust padding for spacing */,
                                                    }}
                                                >
                                                    <span>{`${chapter.chapterNo}. ${chapter.chapterName}`}</span>
                                                    {expandedChapters[chapterId] ? (
                                                        <UpOutlined/>
                                                    ) : (
                                                        <DownOutlined/>
                                                    )}
                                                </ListGroupItem>
                                                {expandedChapters[chapterId] &&
                                                    chapter.topics.map((topic) => (
                                                        <ListGroupItem
                                                            key={topic.id}
                                                            className={`pl-4 topic-item ${isTopicEnabled(topic) ? "cursor-pointer" : "disabled"}`}
                                                            onClick={() => handleVideoSelect(topic)}
                                                            style={{
                                                                cursor: isTopicEnabled(topic)
                                                                    ? "pointer"
                                                                    : "default",
                                                                backgroundColor:
                                                                    "#fff" /* White background for topics */,
                                                                padding:
                                                                    "10px 15px" /* Consistent padding for spacing */,
                                                                borderBottom:
                                                                    "1px solid #eee" /* Subtle border for separation */,
                                                            }}
                                                        >
                                                            <div
                                                                className="d-flex align-items-center justify-content-between">
                                                                <div style={{marginRight: "10px"}}>
                                                                    {topic.watched ? (
                                                                        <CheckCircleOutlined
                                                                            style={{color: "green"}}
                                                                        />
                                                                    ) : isTopicEnabled(topic) ? (
                                                                        <PlayCircleOutlined
                                                                            style={{color: "blue"}}
                                                                        />
                                                                    ) : (
                                                                        <LockOutlined style={{color: "#ccc"}}/>
                                                                    )}
                                                                </div>
                                                                <span>{topic.name}</span>
                                                            </div>
                                                        </ListGroupItem>
                                                    ))}
                                            </React.Fragment>
                                        ),
                                    )}
                                </ListGroup>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <Modal
                isOpen={isFullscreen}
                toggle={handleFullscreenToggle}
                size="xl"
                fullscreen={true}
            >
                <ModalBody className="p-0">
                    <div style={{height: "50vh", position: "relative"}}>
                        {selectedTopic && renderContent()}
                        {selectedTopic &&
                            !selectedTopic.watched &&
                            !selectedTopic.path.endsWith('.pdf') && (
                                <div
                                    style={{
                                        position: "absolute",
                                        bottom: "20px",
                                        left: "50%",
                                        transform: "translateX(-50%)",
                                    }}
                                >
                                    {renderCustomControls()}
                                </div>
                            )}
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

const CourseProgressBar = ({ totalTopics, completedTopics }) => {
    const progressPercentage = Math.round((completedTopics / totalTopics) * 100);

    return (
        <Card className="w-full mt-4">
            <CardBody className="pt-6">
                <div className="flex justify-between mb-2">
                    <span className="text-sm font-semibold text-gray-700">Course Progress</span>

                </div>
                <Progress value={progressPercentage} className="w-full h-2"/>
                <div className="flex justify-between mt-2">
                    <span className="text-xs font-semibold text-primary">{progressPercentage}% Complete</span>
                </div>
            </CardBody>
        </Card>
    );
};

const EnrolledCourseCard = ({
                                course,
                                onPlayCourse,
                                onStartExam,
                                onContactAdmin,
                                onViewResults,
                                onDownloadCertificate,

                            }) => {
    const attemptsLeft = course.totalLimit - course.attempts;
    const isExamAvailable = course.hasAssessment && course.completed;
    const canTakeExam = attemptsLeft > 0;
    const shouldContactAdmin = course.attempts === course.totalLimit;

    // console.log(`Attempts left ${attemptsLeft}`)
    // console.log(`isExamAvail ${isExamAvailable}`)
    // console.log(`canTakeExam  ${canTakeExam}`)
    // console.log(`shouldContactAdmin  ${shouldContactAdmin}`)




    return (
        <Card className="h-100 hover:shadow-lg transition-shadow duration-300">
            <CardBody className="d-flex flex-column justify-content-between">
                <div>
                    <CardTitle tag="h5" className="text-primary font-weight-bold mb-2">
                        {course.name}
                    </CardTitle>
                    <CardText className="text-muted small mb-2 d-flex align-items-center">
                        <CalendarOutlined className="mr-1"/>
                        {new Date(course.createdAt).toLocaleDateString()}
                    </CardText>
                    <CardText style={{lineHeight: "1.5"}}>
                        {course && course.description && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: course.description.slice(0, 150) + "...",
                                }}
                            />
                        )}
                    </CardText>
                    <CourseProgressBar
                        totalTopics={course.totaltopics}
                        completedTopics={course.completedtopics}
                    />
                </div>

                <div>
                    <Row className="g-2 mb-3">
                        {course.hasAssessment && course.passed === false && (
                            <Col xs={12}>
                                <Badge
                                    color="primary"
                                    className="d-flex align-items-center justify-content-center p-2 w-100"
                                    style={{fontSize: "1rem"}}
                                >
                                    <CheckCircleOutlined className="me-2"/>
                                    <span>Assessment Available</span>
                                </Badge>
                            </Col>
                        )}
                        {isExamAvailable && !course.passed && (
                            <Col xs={12}>
                                <Badge
                                    color={canTakeExam ? "success" : "warning"}
                                    className="d-flex align-items-center justify-content-center p-2 w-100"
                                    style={{fontSize: "1rem"}}
                                >
                                    <ExclamationCircleOutlined className="me-2"/>
                                    <span>
                    {canTakeExam
                        ? `${attemptsLeft} attempt${attemptsLeft !== 1 ? "s" : ""} left`
                        : "No attempts left"}
                  </span>
                                </Badge>
                            </Col>
                        )}
                    </Row>

                    <Row className="g-2">
                        {course.passed ? (
                            <>
                                <Col xs={12}>
                                    <Button
                                        color="primary"
                                        onClick={() => onViewResults(course)}
                                        className="w-100 d-flex align-items-center justify-content-center"
                                    >
                                        <FileTextOutlined className="mr-2"/>
                                        View Results
                                    </Button>
                                </Col>
                                <Col xs={12}>
                                    <Button
                                        color="success"
                                        onClick={() => onDownloadCertificate(course)}
                                        className="w-100 d-flex align-items-center justify-content-center"
                                    >
                                        <DownloadOutlined className="mr-2"/>
                                        Download Certificate
                                    </Button>
                                </Col>
                            </>
                        ) : (
                            <>
                                <Col xs={shouldContactAdmin || (isExamAvailable && canTakeExam) ? 6 : 12}>
                                    <Button
                                        color="primary"
                                        onClick={() => onPlayCourse(course)}
                                        className="w-100 d-flex align-items-center justify-content-center"
                                    >
                                        <PlayCircleOutlined className="mr-2"/>
                                        Play Course
                                    </Button>
                                </Col>
                                <Col xs={6}>
                                    {isExamAvailable && canTakeExam ? (
                                        <Button
                                            color="success"
                                            onClick={() => onStartExam(course)}
                                            className="w-100 d-flex align-items-center justify-content-center"
                                        >
                                            <ExclamationCircleOutlined className="me-2"/>
                                            Start Exam
                                        </Button>
                                    ) : shouldContactAdmin ? (
                                        <Button
                                            color="warning"
                                            onClick={() => onContactAdmin(course)}
                                            className="w-100 d-flex align-items-center justify-content-center"
                                        >
                                            <ExclamationCircleOutlined className="mr-2"/>
                                            Contact Admin
                                        </Button>
                                    ) : null}
                                </Col>
                            </>
                        )}
                    </Row>
                </div>
            </CardBody>
        </Card>
    );
};
const EnrolledCourses = () => {
    const dispatch = useDispatch();
    const enrolledCourses = useSelector((state) => state.course.enrolledCourses);
    const questions = useSelector((state) => state.question.questions);
    const topics = useSelector((state) => state.topic.list);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [title, setTitle] = useState("");
    const [examModalOpen, setExamModalOpen] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [result, setResult] = useState(null);
    const [resultModalOpen, setResultModalOpen] = useState(false);
    const [examData, setExamData] = useState([]);
    

    const [certificateModalOpen, setCertificateModalOpen] = useState(false);
    const [selectedCertificateCourse, setSelectedCertificateCourse] = useState(null);
    

    const handleCertificateDownload = () => {
        // Implement the actual download logic here
        // console.log("Downloading certificate for course:", selectedCertificateCourse.id);
        // After download logic, you may want to close the modal
        setCertificateModalOpen(false);
    };

    


    useEffect(() => {
        dispatch(getUserEnrolledCourses());
    }, [dispatch]);

    useEffect(() => {
        // console.log(enrolledCourses)
    }, [enrolledCourses]);

    useEffect(() => {
        if (questions && questions.length > 0 && selectedCourse) {
            setExamModalOpen(true);
        }
    }, [questions, selectedCourse]);

    useEffect(() => {
        async function fetchUserEnrolledCourses() {
            if (result !== null) {
                await dispatch(getUserEnrolledCourses());
                setExamModalOpen(false);
                setConfirmDialogOpen(false);
            }
        }

        fetchUserEnrolledCourses();
    }, [result, dispatch]);

    const handlePlayCourse = async (course) => {
        setSelectedCourse(course);
        await dispatch(getTopicsByCourseId(course.id));
        setTitle(course.name || "");
        setModalOpen(true);
    };

    const handleStartExam = async (course) => {
        setSelectedCourse(course);
        setConfirmDialogOpen(true);
    };

    const handleContactAdmin = (course) => {
        console.log("Contact admin for course:", course.id);
    };

    const toggleModal = () => setModalOpen(!modalOpen);

    const confirmStartExam = async () => {
        setConfirmDialogOpen(false);
        const payload = {
            id: selectedCourse.id,
            type: "COURSE",
        };
        await dispatch(getQuestionQuiz(payload));
    };

    const viewResult = async (course) => {
        const payload = {
            id: course.id,
        };
        const result = await dispatch(getGivenTests(payload));
        if (result.success && Array.isArray(result.data)) {
            setExamData(result.data);
            setResultModalOpen(true);
        } else {
            console.error("Invalid exam data received:", result.data);
            // Optionally, show an error message to the user
        }
    };
    const downloadCertificate = async (course) => {
        setSelectedCertificateCourse(course);
        setCertificateModalOpen(true);
    };

    const updateCourseHistory = async (topicId) => {
        console.log("INSIDE UPDATE COURSE HISTORY");
        console.log(topicId);

        // Find the topic in the topics list
        const topic = topics.find(t => t.id === topicId);
        if (!topic) {
            console.error("Topic not found");
            return;
        }

        const courseId = topic.courseId;
        const isFirstTopic = topics.findIndex(t => t.id === topicId) === 0;
        const isLastTopic = topics.findIndex(t => t.id === topicId) === topics.length - 1;

        let object = {
            topicId: parseInt(topicId)
        };

        if (isFirstTopic && !topic.watched) {
            object.started = true;
        }

        if (isLastTopic) {
            await dispatch(completeCourse({ courseId, topicId: parseInt(topicId) }));
            setModalOpen(!modalOpen)
            await dispatch(getUserEnrolledCourses());

        } else {
            // Dispatch updateCourseHistoryTopic action
            await dispatch(updateCourseHistoryTopic(object));
        }
    };

    useEffect(() => {
        // console.log("TOPICS REFLECTED")
    }, [topics]);





    return (
        <Container className="page-content py-5">
            <h1 className="h4 mb-4" style={{color: "#013e7d"}}>
                My Enrolled Courses
            </h1>
            <Row>
                {enrolledCourses &&
                    enrolledCourses.map((course) => (
                        <Col key={course.id} sm="12" md="6" lg="4" className="mb-4">
                            <EnrolledCourseCard
                                course={course}
                                onPlayCourse={handlePlayCourse}
                                onStartExam={handleStartExam}
                                onContactAdmin={handleContactAdmin}
                                onViewResults={viewResult}
                                onDownloadCertificate={downloadCertificate}
                            />
                        </Col>
                    ))}
            </Row>
            <CourseContentModal
                title={title}
                isOpen={modalOpen}
                toggle={toggleModal}
                courseData={selectedCourse}
                topics={topics}
                updateCourseHistory={updateCourseHistory}
            />
            <ExamModal
                isOpen={examModalOpen}
                toggle={() => setExamModalOpen(false)}
                questions={questions ?? []}
                courseId={selectedCourse?.id}
                courseName={selectedCourse?.name}
                dispatch={dispatch}
                setResult={setResult}
            />
            <ResultModal
                isOpen={resultModalOpen}
                toggle={() => setResultModalOpen(false)}
                examData={examData ?? []}
            />
            <Modal
                isOpen={confirmDialogOpen}
                toggle={() => setConfirmDialogOpen(false)}
                size="xl"
                fullscreen={true}
            >
                <ModalHeader toggle={() => setConfirmDialogOpen(false)}>
                    Start Exam
                </ModalHeader>
                <ModalBody>
                    <div
                        dangerouslySetInnerHTML={{
                            __html:
                                selectedCourse?.examDescription ||
                                "Do you agree to start the exam?",
                        }}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setConfirmDialogOpen(false)}>
                        Cancel
                    </Button>{" "}
                    <Button color="primary" onClick={confirmStartExam}>
                        I Accept
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal
                isOpen={certificateModalOpen}
                toggle={() => setCertificateModalOpen(false)}
                size="xl"
            >
                <ModalHeader toggle={() => setCertificateModalOpen(false)}>
                    Course Certificate
                </ModalHeader>
                <ModalBody>
                    <Certificate course={selectedCertificateCourse} />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleCertificateDownload}>
                        Download Certificate
                    </Button>
                    <Button color="secondary" onClick={() => setCertificateModalOpen(false)}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </Container>
    );
};

export default EnrolledCourses;
/*
const ResultModal = ({isOpen, toggle, examData}) => {
    const [selectedExamIndex, setSelectedExamIndex] = useState(0);

    if (!examData || examData.length === 0) {
        return null;
    }

    const renderDateList = () => {
        console.log('ResultModal: Rendering date list');
        return (
            <Card className="mb-3">
                <CardBody>
                    <h5 className="mb-3">Exam Dates</h5>
                    {examData.map((exam, index) => {
                        return (
                            <Button
                                key={exam.id}
                                color={index === selectedExamIndex ? "primary" : "secondary"}
                                className="mb-2 d-flex align-items-center"
                                onClick={() => {
                                    setSelectedExamIndex(index);
                                }}
                            >
                                <CalendarOutlined className="mr-2"/>
                                {new Date(exam.createdAt).toLocaleDateString()}
                            </Button>
                        );
                    })}
                </CardBody>
            </Card>
        );
    };

    const renderQuestionNavigator = () => {
        const currentExam = examData[selectedExamIndex];
        return (
            <Card className="mb-3">
                <CardBody>
                    <h5 className="mb-3">Question Navigator</h5>
                    <div className="d-flex flex-wrap">
                        {currentExam.quiz.map((question, index) => {
                            return (
                                <Button
                                    key={index}
                                    color={
                                        question.answer === question.myAnswer ? "success" : "danger"
                                    }
                                    className="m-1"
                                >
                                    {index + 1}
                                </Button>
                            );
                        })}
                    </div>
                </CardBody>
            </Card>
        );
    };

    const renderExamSummary = () => {
        const currentExam = examData[selectedExamIndex];
        return (
            <Card>
                <CardBody>
                    <h5 className="mb-3">Exam Summary</h5>
                    <p>Total Questions: {currentExam.totalMarks}</p>
                    <p>Correct Answers: {currentExam.myMarks}</p>
                    <p>
                        Score:{" "}
                        {((currentExam.myMarks / currentExam.totalMarks) * 100).toFixed(2)}%
                    </p>
                </CardBody>
            </Card>
        );
    };

    const renderQuestions = () => {
        const currentExam = examData[selectedExamIndex];
        return currentExam.quiz.map((question, index) => {
            return (
                <Card key={index} className="mb-3">
                    <CardBody>
                        <h5 className="mb-3">Question {index + 1}</h5>
                        <p>{question.question}</p>
                        {["A", "B", "C", "D"].map((option) => (
                            <div
                                key={option}
                                className={`mb-2 ${question.myAnswer === option ? "font-weight-bold" : ""}`}
                            >
                                {option}: {question[option]}
                                {question.myAnswer === option && question.answer !== option && (
                                    <CloseCircleFilled className="ml-2 text-danger"/>
                                )}
                                {question.answer === option && (
                                    <CheckCircleFilled className="ml-2 text-success"/>
                                )}
                            </div>
                        ))}
                    </CardBody>
                </Card>
            );
        });
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="xl" fullscreen={true}>
            <ModalHeader toggle={toggle}>Exam Results</ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={3}>
                        {renderDateList()}
                        {renderQuestionNavigator()}
                        {renderExamSummary()}
                    </Col>
                    <Col md={9}>{renderQuestions()}</Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};*/
