import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";

import Select from "react-select";
import { FilterOutlined } from "@ant-design/icons";
import ReactStrapDataTable from "../../../CustomDatatable/ReactStrapDataTable";
import { dateSorter, stringSorter, formatDateWithTime } from '../../../../utils/functions';
import {getInformFilterValues} from "../../../../store/actions/Inform/HSSEReports/informFilter";

const InformFilter = () => {
    const dispatch = useDispatch();
    const icrValues = useSelector((state) => state.informReportFilter.inValues);
    const ircFilters = useSelector((state) => state.informReportFilter.inFilters)

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState({
        createdBys: [],
        formGroups: [],
        formTypes: [],
        status:[],
        countries: [],
        states: [],
        businessUnits: [],
        projectClients: [],
        projects: [],
        sites: [],
        l3: [],
    });

    const { control, handleSubmit, reset, watch } = useForm();
    const dateFrom = watch("dateFrom");

    useEffect(() => {
        //dispatch(getIncidentReportCategoryValues());
        dispatch(getInformFilterValues());
    }, [dispatch]);

    useEffect(() => {
        console.log("in values")
        console.log(icrValues)
    }, [icrValues]);

    useEffect(() => {
        console.log("IRC FILTERS")
        console.log(ircFilters)
    }, [ircFilters])

    let columns = [
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
            sorter: stringSorter("country"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Business Unit",
            dataIndex: "businessUnit",
            key: "businessUnit",
            sorter: stringSorter("businessUnit"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Actual Consequence",
            dataIndex: "aCategory",
            key: "aCategory",
            sorter: stringSorter("aCategory"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Potential Consequence",
            dataIndex: "pCategory",
            key: "aCategory",
            sorter: stringSorter("aCategory"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Injury Severity",
            dataIndex: "injurySeverity",
            key: "injurySeverity",
            sorter: stringSorter("injurySeverity"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "EnteredBy",
            dataIndex: "createdByUser",
            key: "createdByUser",
            sorter: stringSorter("createdByUser"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            sorter: stringSorter("description"),
            ellipsis: true,
        },
        {
            title: "Date of Issue",
            dataIndex: "detectedAt",
            key: "detectedAt",
            sorter: dateSorter("detectedAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Closed At",
            dataIndex: "closedAt",
            key: "closedAt",
            sorter: dateSorter("closedAt"),
            sortDirections: ["ascend", "descend"],
        },
    ];

    const handleCloseModal = () => {
        setModalOpen(false);
        reset();
    };

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleResetFilters = () => {
        reset();
        setSelectedItems({
            createdBys: [],
            formGroups: [],
            formTypes: [],
            status:[],
            countries: [],
            states: [],
            businessUnits: [],
            projectClients: [],
            projects: [],
            sites: [],
            l3: [],
        });
    };

    const isFieldEnabled = (field) => {
        switch (field) {
            case 'businessUnits':
                return selectedItems.countries.length > 0;
            case 'projectClients':
                return selectedItems.businessUnits.length > 0;
            case 'projects':
                return selectedItems.projectClients.length > 0;
            case 'sites':
                return selectedItems.projects.length > 0;
            case 'l3':
                return selectedItems.sites.length > 0;
            default:
                return true;
        }
    };

    const handleItemSelect = (field, selectedOptions) => {
        setSelectedItems((prevItems) => {
            const newItems = {
                ...prevItems,
                [field]: selectedOptions,
            };

            // Clear child selections based on parent-child relationships
            switch (field) {
                case 'countries':
                    if (selectedOptions.length === 0) {
                        newItems.businessUnits = [];
                        newItems.projectClients = [];
                        newItems.projects = [];
                        newItems.sites = [];
                        newItems.l3 = [];
                    }
                    break;
                case 'businessUnits':
                    if (selectedOptions.length === 0) {
                        newItems.projectClients = [];
                        newItems.projects = [];
                        newItems.sites = [];
                        newItems.l3 = [];
                    }
                    break;
                case 'projectClients':
                    if (selectedOptions.length === 0) {
                        newItems.projects = [];
                        newItems.sites = [];
                        newItems.l3 = [];
                    }
                    break;
                case 'projects':
                    if (selectedOptions.length === 0) {
                        newItems.sites = [];
                        newItems.l3 = [];
                    }
                    break;
                case 'sites':
                    if (selectedOptions.length === 0) {
                        newItems.l3 = [];
                    }
                    break;
            }

            return newItems;
        });
    };

    const getFilteredStates = () => {
        if (selectedItems.countries.length === 0) {
            return icrValues.states || [];
        }

        return (icrValues.states || []).filter((state) =>
            selectedItems.countries.some((country) => state.coId === country.value)
        );
    };

    const getFilteredBusinessUnits = () => {
        if (selectedItems.countries.length === 0) {
            return icrValues.businessUnits || [];
        }

        return (icrValues.businessUnits || []).filter((bu) =>
            selectedItems.countries.some((country) => bu.coId === country.value)
        );
    };

    const getFilteredProjectClients = () => {
        if (selectedItems.countries.length === 0) {
            return icrValues.projectClients || [];
        }

        return (icrValues.projectClients || []).filter((pc) =>
            pc.coId.some((coId) =>
                selectedItems.countries.some((country) => coId === country.value)
            )
        );
    };

    const getFilteredProjects = () => {
        if (selectedItems.businessUnits.length === 0 && selectedItems.projectClients.length === 0) {
            return icrValues.projects || [];
        }

        return (icrValues.projects || []).filter((project) => {
            const matchesBusinessUnit = selectedItems.businessUnits.length === 0 ||
                selectedItems.businessUnits.some((bu) => bu.value === project.buId);

            const matchesProjectClient = selectedItems.projectClients.length === 0 ||
                selectedItems.projectClients.some((pc) => pc.value === project.pcId);

            return matchesBusinessUnit && matchesProjectClient;
        });
    };

    const getFilteredSites = () => {
        if (selectedItems.projectClients.length === 0) {
            return icrValues.sites || [];
        }

        const relevantProjectIds = (icrValues.projects || [])
            .filter((project) =>
                selectedItems.projectClients.some((pc) => project.pcId === pc.value)
            )
            .map((project) => project.id);

        return (icrValues.sites || []).filter((site) => relevantProjectIds.includes(site.prId));
    };

    const onSubmit = (data) => {

        const payload = {
            issueStartDate: formatDateWithTime(data.dateFrom, false),
            issueEndDate: formatDateWithTime(data.dateTo, false),
            countries: JSON.stringify(selectedItems.countries.map(item => item.value)),
            businessUnits: JSON.stringify(selectedItems.businessUnits.map(item => item.value)),
            projectClients: JSON.stringify(selectedItems.projectClients.map(item => item.value)),
            projects: JSON.stringify(selectedItems.projects.map(item => item.value)),
            sites: JSON.stringify(selectedItems.sites.map(item => parseInt(item.value))),
            l3s: JSON.stringify(selectedItems.l3.map(item => item.value)),
            states: JSON.stringify(selectedItems.states.map(item => item.value)),
        };
        // dispatch(getFilterIncidentReportCategory(JSON.stringify(payload)))
        // setModalOpen(false);
    };

    const now = new Date();
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    const formatDate = (date) => date.toISOString().slice(0, 10);


    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">Inform Filter</h5>
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" onClick={handleOpenModal}>
                                <FilterOutlined />
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactStrapDataTable
                        dataSource={ircFilters ??[]}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} size="xl" backdrop="static">
                <ModalHeader toggle={handleCloseModal}>
                    Inform Filter
                </ModalHeader>
                <ModalBody>
                    <div className="d-flex justify-content-end mb-3">
                        <Button color="secondary" onClick={handleResetFilters}>
                            Reset Filters
                        </Button>
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="dateFrom">Date From</Label>
                                    <Controller
                                        name="dateFrom"
                                        control={control}
                                        rules={{ required: "Date From is required" }}
                                        defaultValue={formatDate(firstDayOfMonth)}x
                                        render={({ field }) => (
                                            <Input {...field} type="date" id="dateFrom" />
                                        )}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="dateTo">Date To</Label>
                                    <Controller
                                        name="dateTo"
                                        control={control}
                                        rules={{
                                            required: "Date To is required",
                                            validate: (value) =>
                                                new Date(value) > new Date(dateFrom) ||
                                                "Date To must be greater than Date From",
                                        }}
                                        defaultValue={formatDate(lastDayOfMonth)}
                                        render={({ field }) => (
                                            <Input {...field} type="date" id="dateTo" />
                                        )}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Entered By</Label>
                                    <Select
                                        options={(icrValues.createdBys || []).map((createdBy) => ({
                                            value: createdBy.id,
                                            label: createdBy.name,
                                        }))}
                                        value={selectedItems.createdBys}
                                        onChange={(options) => handleItemSelect("createdBys", options)}
                                        placeholder="Select Entered By..."
                                        isMulti
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Form</Label>
                                    <Select
                                        options={(icrValues.forms || []).map((type) => ({
                                            value: type,
                                            label: type,
                                        }))}
                                        value={selectedItems.forms}
                                        onChange={(options) => handleItemSelect("forms", options)}
                                        placeholder="Select Forms..."
                                        isMulti
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Form Group</Label>
                                    <Select
                                        options={(icrValues.formGroups || []).map((type) => ({
                                            value: type,
                                            label: type,
                                        }))}
                                        value={selectedItems.formGroups}
                                        onChange={(options) => handleItemSelect("formGroups", options)}
                                        placeholder="Select Form Group..."
                                        isMulti
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Status</Label>
                                    <Select
                                        options={(icrValues.statuses || []).map((status) => ({
                                            value: status,
                                            label: status,
                                        }))}
                                        value={selectedItems.status}
                                        onChange={(options) => handleItemSelect("status", options)}
                                        placeholder="Select Issue Type Status..."
                                        isMulti
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr />
                        <h5>Location</h5>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Countries</Label>
                                    <Select
                                        options={(icrValues.countries || []).map((country) => ({
                                            value: country.id,
                                            label: country.name,
                                        }))}
                                        value={selectedItems.countries}
                                        onChange={(options) => handleItemSelect("countries", options)}
                                        placeholder="Select countries..."
                                        isMulti
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>State</Label>
                                    <Select
                                        options={getFilteredStates().map((state) => ({
                                            value: state.id,
                                            label: state.name,
                                        }))}
                                        value={selectedItems.states}
                                        onChange={(options) => handleItemSelect("states", options)}
                                        placeholder="Select States..."
                                        isMulti
                                        isDisabled={selectedItems.countries.length === 0}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Business Units</Label>
                                    <Select
                                        options={getFilteredBusinessUnits().map((bu) => ({
                                            value: bu.id,
                                            label: bu.name,
                                        }))}
                                        value={selectedItems.businessUnits}
                                        onChange={(options) => handleItemSelect("businessUnits", options)}
                                        placeholder="Select business units..."
                                        isMulti
                                        isDisabled={!isFieldEnabled('businessUnits')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Project Clients</Label>
                                    <Select
                                        options={getFilteredProjectClients().map((pc) => ({
                                            value: pc.id,
                                            label: pc.name,
                                        }))}
                                        value={selectedItems.projectClients}
                                        onChange={(options) => handleItemSelect("projectClients", options)}
                                        placeholder="Select project clients..."
                                        isMulti
                                        isDisabled={!isFieldEnabled('projectClients')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Projects</Label>
                                    <Select
                                        options={getFilteredProjects().map((project) => ({
                                            value: project.id,
                                            label: project.name,
                                        }))}
                                        value={selectedItems.projects}
                                        onChange={(options) => handleItemSelect("projects", options)}
                                        placeholder="Select projects..."
                                        isMulti
                                        isDisabled={!isFieldEnabled('projects')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Sites</Label>
                                    <Select
                                        options={getFilteredSites().map((site) => ({
                                            value: site.id,
                                            label: site.name,
                                        }))}
                                        value={selectedItems.sites}
                                        onChange={(options) => handleItemSelect("sites", options)}
                                        placeholder="Select sites..."
                                        isMulti
                                        isDisabled={!isFieldEnabled('sites')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>L3</Label>
                                    <Select
                                        options={(icrValues.l3 || []).map((item) => ({
                                            value: item.id,
                                            label: item.name,
                                        }))}
                                        value={selectedItems.l3}
                                        onChange={(options) => handleItemSelect("l3", options)}
                                        placeholder="Select L3..."
                                        isMulti
                                        isDisabled={!isFieldEnabled('l3')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit(onSubmit)}>
                        Apply Filters
                    </Button>
                    <Button color="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default InformFilter;