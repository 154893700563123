import axios from '../../axios';
import {ADD_MASTER, DELETE_MASTER, GET_MASTERS_TABLES, GET_TABLE_VALUES, UPDATE_MASTER} from '../types';
import { setAlert } from "./alert";

export const getMasterTables = () => async (dispatch) => {
    try {
        const res = await axios.get("/master/values");
        if (res.data.code === 1) {
            dispatch({ type: GET_MASTERS_TABLES, payload: res.data.data });
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const getTableValues = (table) => async (dispatch) => {
    try {
        const res = await axios.post("/master/all", { tableName: table });

        if (res.data.code === 1) {
            dispatch({ type: GET_TABLE_VALUES, payload: res.data.data });
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const addMasterForTables = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/master/add", formData);
        if (res.data.code === 1) {
            dispatch({ type: ADD_MASTER, payload: res.data.data });
            dispatch(setAlert("Master added successfully", "success"));
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const updateMaster = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/master/update", formData);
        
        if (res.data.code === 1) {
            dispatch({ type: UPDATE_MASTER, payload: res.data.data });
            dispatch(setAlert("Master updated successfully", "success"));
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const deleteMaster = (tableName, id) => async (dispatch) => {
    try {
        
        const res = await axios.post("/master/delete", { tableName, id });
        
        if (res.data.code === 1) {
            dispatch({ type: DELETE_MASTER, payload: id });
            dispatch(setAlert("Master deleted successfully", "success"));
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};