import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import GlobalComponent from "../GlobalComponents";
import { FaHome, FaUserShield, FaExclamationTriangle, FaCog, FaArrowLeft, FaChevronDown, FaChevronRight } from 'react-icons/fa';
import styled from "styled-components";
import { useNavigation } from "../../Navigation";

import {
    FaFileAlt,FaKey, FaUsers,
    FaClipboardCheck, FaLightbulb, FaComments, FaCogs, FaUserLock,
    FaEye, FaChalkboardTeacher, FaToolbox, FaCalendarAlt, FaList,
    FaGlobeAmericas, FaCity, FaBuilding, FaProjectDiagram, FaUserTie,
    FaMapMarkerAlt, FaGraduationCap, FaQuestionCircle, FaNewspaper,
    FaBookOpen, FaInfoCircle, FaUser, FaTachometerAlt, FaArchive,
    FaFileSignature, FaClipboard, FaExclamationCircle, FaChartBar,
    FaLeaf, FaListAlt, FaSitemap, FaGlobe, FaCheckSquare, FaUserCog
} from 'react-icons/fa';


const componentMap = {
    'hazard': { icon: FaExclamationTriangle },
    'incident/report': { icon: FaFileAlt },
    'systemroles/access': { icon: FaUserShield },
    'permission/access': { icon: FaKey },
    'systemroles': { icon: FaUsers },
    'nonconformity': { icon: FaClipboardCheck },
    'improvement-opportunity': { icon: FaLightbulb },
    'complaint': { icon: FaComments },
    'manage': { icon: FaCogs },
    'user/access': { icon: FaUserLock },
    'gsp': { icon: FaEye },
    'audit': { icon: FaClipboardCheck },
    'tbt': { icon: FaChalkboardTeacher },
    'rhpw': { icon: FaCalendarAlt },
    'rpmw': { icon: FaCalendarAlt },
    'ldo': { icon: FaFileAlt },
    'list': { icon: FaList },
    'status': { icon: FaExclamationCircle },
    'country': { icon: FaGlobeAmericas },
    'state': { icon: FaGlobeAmericas },
    'city': { icon: FaCity },
    'business/group': { icon: FaBuilding },
    'business/unit': { icon: FaBuilding },
    'project': { icon: FaProjectDiagram },
    'project/client': { icon: FaUserTie },
    'site': { icon: FaMapMarkerAlt },
    'course': { icon: FaGraduationCap },
    'question': { icon: FaQuestionCircle },
    'news/my': { icon: FaNewspaper },
    'assign': { icon: FaBookOpen },
    'enrolled': { icon: FaGraduationCap },
    'inform/list': { icon: FaInfoCircle },
    'inform/status': { icon: FaInfoCircle },
    'user': { icon: FaUser },
    'dashboard': { icon: FaTachometerAlt },
    'cabinet': { icon: FaArchive },
    'permit': { icon: FaFileSignature },
    'templates': { icon: FaClipboard },
    'register': { icon: FaExclamationTriangle },
    'irc': { icon: FaFileAlt },
    'cfr': { icon: FaChartBar },
    'cir': { icon: FaUserTie },
    'is': { icon: FaClipboardCheck },
    'io': { icon: FaClipboardCheck },
    'pdr': { icon: FaExclamationTriangle },
    'csr': { icon: FaChartBar },
    'ier': { icon: FaLeaf },
    'iar': { icon: FaListAlt },
    'organization': { icon: FaSitemap },
    'dashboard/global': { icon: FaGlobe },
    'dashboard/country': { icon: FaGlobeAmericas },
    'user/review': { icon: FaCheckSquare },
    'user/issue': { icon: FaFileSignature },
    'inform/qc': { icon: FaClipboardCheck },
    'dashboard/my': { icon: FaUserCog },
    'ncr': { icon: FaExclamationCircle },
    'incr': { icon: FaExclamationTriangle }
};

const Sidebar = ({ children, isSidebarCondensed }) => {
    const sidebarStyle = {
        height: '100vh',
        overflowY: 'auto',
        width: isSidebarCondensed ? '50px' : '250px',
        transition: 'width 0.3s ease-in-out',
    };

    const contentStyle = {
        padding: '1rem',
    };

    return (
        <div style={sidebarStyle}>
            <div>
                <Nav vertical>
                    {children}
                </Nav>
            </div>
        </div>
    );
};

const MenuLink = styled(NavLink)`
    display: flex;
    align-items: center;
    padding: 10px 15px;
    color: #6c757d;
    text-decoration: none;
    transition: all 0.3s ease;
    font-weight: ${props => props.ismainmenu ? 'bold' : 'normal'};
    justify-content: space-between; // This will push the icon to the right

    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
        color: #007bff;
    }

    svg {
        margin-right: 8px;
        font-size: 1.2em;
    }
`;

const SubMenuLink = styled(MenuLink)`
    padding-left: 40px;
    font-weight: normal;
`;

const DropdownIcon = styled.span`
    margin-left: auto; // This ensures the icon stays at the extreme right
`;

const BackLink = styled(MenuLink)`
    color: #007bff;
    font-weight: bold;
    margin-bottom: 10px;
`;

const MenuContent = styled.div`
    display: flex;
    align-items: center;
`;


const DynamicPageLayout = () => {
    const { menuName } = useParams();
    const { navigation, isLoading } = useNavigation();
    const { leftSideBarType } = useSelector((state) => state.Layout);
    const [selectedModule, setSelectedModule] = useState(null);
    const [selectedSubmenu, setSelectedSubmenu] = useState(null);
    const [selectedSubmenuRoute, setSelectedSubmenuRoute] = useState(null);
    const [openMenus, setOpenMenus] = useState({});
    const navigate = useNavigate();

    const isSidebarCondensed = leftSideBarType === "condensed";
    const groupedNavigation = useMemo(() => {
        return navigation.reduce((acc, item) => {
            if (!acc[item.menu_name]) {
                acc[item.menu_name] = {};
            }
            if (!acc[item.menu_name][item.submenu_name]) {
                acc[item.menu_name][item.submenu_name] = [];
            }
            acc[item.menu_name][item.submenu_name].push({
                module_name: item.module_name,
                module_route: item.module_route,
                submenu_route: item.submenu_route
            });
            return acc;
        }, {});
    }, [navigation]);

    const uniqueMenus = useMemo(() => [...new Set(navigation.map(item => item.menu_name))], [navigation]);

    const currentMenu = useMemo(() => {
        if (!menuName) return null;
        const formattedMenuName = menuName.replace(/-/g, ' ');
        return Object.keys(groupedNavigation).find(
            menu => menu.toLowerCase() === formattedMenuName.toLowerCase()
        );
    }, [menuName, groupedNavigation]);

    useEffect(() => {
        if (currentMenu && groupedNavigation[currentMenu]) {
            const firstSubmenu = Object.keys(groupedNavigation[currentMenu])[0];
            const firstModule = groupedNavigation[currentMenu][firstSubmenu][0];
        
            setSelectedSubmenu(firstSubmenu);
            setSelectedModule(firstModule.module_name);
            setSelectedSubmenuRoute(firstModule.module_route);
            setOpenMenus(prevState => ({ ...prevState, [currentMenu]: true }));
        }
    }, [currentMenu, groupedNavigation]);

    const toggleMenu = (menuName) => {
        setOpenMenus(prevState => {
            const newState = Object.keys(prevState).reduce((acc, key) => {
                acc[key] = false;
                return acc;
            }, {});
            newState[menuName] = !prevState[menuName];
            return newState;
        });
    };

    const handleModuleSelect = (submenuName, moduleName, moduleRoute) => {
        setSelectedSubmenu(submenuName);
        setSelectedModule(moduleName);
        setSelectedSubmenuRoute(moduleRoute);
    };

    const handleMenuSelect = (selectedMenuName) => {
        navigate(`/${selectedMenuName.toLowerCase().replace(/ /g, '-')}`);
        toggleMenu(selectedMenuName);
    };

    const getIcon = (menuName) => {
        switch (menuName.toLowerCase()) {
            case 'dashboard':
                return <FaHome />;
            case 'human resources':
                return <FaUserShield />;
            case 'incident':
                return <FaExclamationTriangle />;
            case 'reports':
                return <FaChartBar />;
            default:
                return <FaCog />;
        }
    };

    const getSubMenuIcon = (menuName) => {
        const key = Object.keys(componentMap).find(k => k.toLowerCase().includes(menuName.toLowerCase()));
        const IconComponent = key ? componentMap[key].icon : FaCog;
        return <IconComponent />;
    };

    // const getSubMenuIcon = (menuName) => {
    //     const key = Object.keys(componentMap).find(k => k.toLowerCase().includes(menuName.toLowerCase()));
    //     return key ? componentMap[key].icon : <FaCog />;
    // };

    const sidebarStyle = {
        left: 0,
        bottom: 0,
        width: isSidebarCondensed ? '70px' : '250px',
        transition: 'width 0.3s ease-in-out',
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

   



    return (
        <React.Fragment>
            <div className={`vertical-menu ${isSidebarCondensed ? 'sidebar-enable vertical-collpsed' : ''}`}
                 style={sidebarStyle}>
                <Sidebar isSidebarCondensed={isSidebarCondensed}>
                    <div id="sidebar-menu">
                        <Nav vertical>
                            <NavItem>
                                <BackLink tag={Link} to="/dashboard">
                                    <FaArrowLeft/>
                                    <span>Back to Home</span>
                                </BackLink>
                            </NavItem>
                            <NavItem>
                                <MenuLink href="#" onClick={() => toggleMenu('common')} ismainmenu="true">
                                    <i className="mdi mdi-menu"></i>
                                    <span>Common</span>
                                    <DropdownIcon style={{ position: 'absolute', right: '0' }}>  {/* Inline CSS */}
                                        {openMenus['common'] ? <FaChevronDown /> : <FaChevronRight />}
                                    </DropdownIcon>
                                </MenuLink>
                                <Collapse isOpen={openMenus['common']}>
                                    <Nav vertical style={{ paddingLeft: '2rem' }}>
                                        {uniqueMenus.map((menu) => (
                                            <NavItem key={menu}>
                                                <SubMenuLink href="#" onClick={() => handleMenuSelect(menu)}>
                                                    {getIcon(menu)}
                                                    <span>{menu}</span>
                                                </SubMenuLink>
                                            </NavItem>
                                        ))}
                                    </Nav>
                                </Collapse>
                            </NavItem>
                            {currentMenu && groupedNavigation[currentMenu] &&
                                Object.entries(groupedNavigation[currentMenu]).map(([submenuName, modules]) => (
                                    <NavItem key={submenuName}>
                                        <MenuLink href="#" onClick={() => toggleMenu(submenuName)} ismainmenu="true">
                                            <i className="mdi mdi-file-document-outline"></i>
                                            <span>{submenuName}</span>
                                            <DropdownIcon style={{ position: 'absolute', right: '0' }}>  {/* Inline CSS */}
                                                {openMenus[submenuName] ? <FaChevronDown /> : <FaChevronRight />}
                                            </DropdownIcon>
                                        </MenuLink>
                                        <Collapse isOpen={openMenus[submenuName]}>
                                            <Nav vertical style={{ paddingLeft: '2rem' }}>
                                                {Array.from(new Set(modules.map(JSON.stringify)), JSON.parse).map((module) => (
                                                    <NavItem key={module.module_name}>
                                                        <SubMenuLink
                                                            href="#"
                                                            onClick={() => handleModuleSelect(submenuName, module.module_name, module.module_route)}
                                                            active={selectedModule === module.module_name}
                                                        >
                                                            {getSubMenuIcon(module.module_route)}
                                                            {module.module_name.trim()}
                                                        </SubMenuLink>
                                                    </NavItem>
                                                ))}
                                            </Nav>
                                        </Collapse>
                                    </NavItem>
                                ))}
                        </Nav>
                    </div>
                </Sidebar>
            </div>
            <div className="main-content">
                <div className="page-content">
                    <Breadcrumb className="mb-3">
                        <BreadcrumbItem>HSSE Meinhardt EPCM</BreadcrumbItem>
                        <BreadcrumbItem>{currentMenu || (menuName && menuName.replace(/-/g, ' '))}</BreadcrumbItem>
                        {selectedSubmenu && <BreadcrumbItem>{selectedSubmenu}</BreadcrumbItem>}
                        {selectedModule && <BreadcrumbItem active>{selectedModule}</BreadcrumbItem>}
                    </Breadcrumb>
                    <GlobalComponent submenuRoute={selectedSubmenuRoute} navigation={navigation}/>
                </div>
            </div>
        </React.Fragment>
    );
}

export default DynamicPageLayout;