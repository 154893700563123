import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import { DeleteOutlined, EditOutlined, EyeFilled, PlusCircleFilled } from "@ant-design/icons";
import {
    AccessFilter,
    dateSorter,
    formatDateWithTime,
    manageCURDAccess,
    stringSorter
} from "../../../../utils/functions";
import React, { useEffect, useState,useMemo } from "react";
import ReactStrapDataTable from "../../../CustomDatatable/ReactStrapDataTable";
import {
    AdminSection,
    AttachmentsSection,
    ActionSection,
    ActionModal,
    WeeklyHSSEReportDetail
} from "../../Common/Forms"
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import {
    addRHPWForm,
    getSingleRHPW,
    getRHPWValues, getUserRHPW, submitActionForRHPW,
    submitRHPWToGenerate,
    updateRHPWForm,
    deleteRHPW
} from "../../../../store/actions/Inform/HSSEReports/RHPW";
import { WeeklyReportModal } from "../../Views";
import DebouncedSaveButton from "../../../../components/Buttons/DebounceSaveButton";
import { showConfirmDialog, showSuccessDialog, showWarningDialog } from "../../../../utils/dialogs";
import Swal from "sweetalert2";

const RHPWWeeklyReporting = ({access}) => {
    const accessRights = manageCURDAccess(access);
    const dispatch = useDispatch();
    const RHPWValues = useSelector((state) => state.RHPW.values);
    const userRHPW = useSelector((state) => state.RHPW.userRHPW);
    const singleRHPW = useSelector((state) => state.RHPW.single);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentRHPW, setCurrentRHPW] = useState(null);


    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [RHPWGenerate, setRHPWGenerate] = useState(null);

    const [RHPWToDelete, setRHPWToDelete] = useState(null);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    //EYEBUTTON MODAL
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedViewRecord, setSelectedRecord] = useState(null);

    const viewRecord = (record) => {
        setSelectedRecord(record);
        setViewModalOpen(true)
    }

    //Save
    const [isProcessing, setIsProcessing] = useState(false);


    //Shared Access
    const [accessFilter, setAccessFilter] = useState('My');

    const handleAccessFilterChange = (selectedAccess) => {
        setAccessFilter(selectedAccess);
    };


    //ActionModal
    const [actionModalOpen, setActionModalOpen] = useState(false);
    const toggleActionModal = () => setActionModalOpen(!actionModalOpen);

    const [actionData, setActionData] = useState({})

    const handleCloseModal = () => {
        resetForm();
        setModalOpen(false);
        setCurrentRHPW(null);
    };

    const [formData, setFormData] = useState({
        site: null,
        l3Contractor: null,
        locationPlace: '',
        reportedAt: new Date().toISOString().split('T')[0],
        reportedByUser: null,
        permitWork: false,
        actions: [],
        attachments: [],
        contractorCompany: null
    });

    const resetForm = () => {
        setFormData({
            site: '',
            l3Contractor: '',
            locationPlace: '',
            reportedAt: new Date().toISOString().split('T')[0],
            reportedByUser: null,
            actions: [],
            attachments: [],
            contractorCompany: null
        });
    };
    const validateForm = () => {

        if (!formData.site) {
            message.error("Site Required *");
            return false;
        }
        if (!formData.l3Contractor) {
            message.error("L3 Contractor is required *");
            return false;
        }
        if (!formData.reportedByUser) {
            message.error("Reported By User required *");
            return false;
        }

        return true;
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = async () => {

        if (!validateForm()) {
            throw new Error("Form validation failed");
        }

        setIsProcessing(true)
        const formDataObj = new FormData();
        formDataObj.append("moduleId",parseInt(access.moduleId))

        if (formData.id) {
            formDataObj.append('id', parseInt(formData.id.toString()));
            formDataObj.append('actionId', parseInt(formData.actionId));
            formDataObj.append('fileId', parseInt(formData.fileId));
        }

        if (formData.site?.value) formDataObj.append('siteId', parseInt(formData.site.value));
        if (formData.l3Contractor?.value) formDataObj.append('l3Id', parseInt(formData.l3Contractor.value));
        formDataObj.append('location', formData.locationPlace || "");
        formDataObj.append('reportedAt', formData.reportedAt || "");
        if (formData.reportedByUser) formDataObj.append('reportedBy', parseInt(formData.reportedByUser.value));

        if (formData.personCompletingReport) {
            formDataObj.append('crPersonName', formData.personCompletingReport);
        }
        if (formData.contractorCompany) {
            formDataObj.append('crContractorId', parseInt(formData.contractorCompany.value));
        }
        if (formData.workPeriods && formData.workPeriods.length > 0) {
            const workPeriodValues = formData.workPeriods.map(element => element.value);
            formDataObj.append('workDonePeriod', JSON.stringify(workPeriodValues));
        }
        else
        {
            formDataObj.append('workDonePeriod', JSON.stringify([])); 
        }

        if (formData.siteDetails) {
            if (formData.siteDetails.length > 0) {

                formDataObj.append('sites', JSON.stringify(formData.siteDetails.map(site => ({
                    id: site.id || null,
                    bbsqInterventions: parseInt(site.bbsqInterventions),
                    contractorHsseAudit: parseInt(site.contractorHsseAudit),
                    highRiskActivities: parseInt(site.highRiskActivities),
                    kmsTravelled: parseInt(site.kmsTravelled),
                    nmpisRaisedOnHighRiskActivities: parseInt(site.nmpisRaisedOnHighRiskActivities),
                    nmpisReported: parseInt(site.nmpisReported),
                    regionId: parseInt(site.region.value ?? site.region),
                    ptId: parseInt(site.programType.value ?? site.programType),
                    siteExposureHours: parseInt(site.siteExposureHours),
                    siteWeeklyProgress: parseInt(site.siteWeeklyProgress),
                    srManagementAudit: parseInt(site.srManagementAudit),
                    siteNeme: site.siteName,
                    createdAt: site.createdAt || null,
                    createdBy: site.createdBy || null,
                    updateBy: site.updateBy || null,
                    updatedAt: site.updatedAt || null,
                }))));
            }
            else{
                formDataObj.append('sites', JSON.stringify([])); 
            }
        }
        else{
            formDataObj.append('sites', JSON.stringify([])); 
        }


        if (Array.isArray(formData.actions)) {
            formDataObj.append('actions', JSON.stringify(formData.actions.map(action => ({
                id: action.id || null,
                userId: action.userId ? parseInt(action.userId) : null,
                description: action.description || null,
                createdAt: action.createdAt || null,
                createdBy: action.createdBy || null,
                updatedAt: action.updatedAt || null,
                updatedBy: action.updatedBy || null,
                submittedAt: action.submittedAt || null,
                targetAt: action.targetAt || null,
                reply: action.reply || null,
                tags: action.tags || [],
                type: action.type || "N",
                status: action.status || "PENDING",
            }))));
        }

        if (Array.isArray(formData.attachments)) {
            formDataObj.append('files', JSON.stringify(formData.attachments.map(attachment => ({
                id: attachment.id || null,
                fileName: attachment.filename || "",
                description: attachment.description || null,
                path: attachment.path || null,
                createdAt: attachment.createdAt || null,
                createdBy: attachment.createdBy || null,
                updateBy: attachment.updateBy || null,
                updatedAt: attachment.updatedAt || null,
            }))));

            formData.attachments.forEach((attachment, index) => {
                if (attachment.file instanceof File) {
                    formDataObj.append(`attachments[${index}]`, attachment.file, attachment.filename);
                }
            });
        }
        try {
            let result;
            if (formData.id) {
                result = await dispatch(updateRHPWForm(formDataObj));
                if (result.success) {
                    showSuccessDialog("HSSE Weekly Report  Updated Successfully")
                    resetForm()
                    handleCloseModal()
                } else {
                    showWarningDialog(result.message)
                }
            }
            else {
                const result = await dispatch(addRHPWForm(formDataObj));
                if (result.success) {
                    showSuccessDialog("HSSE Weekly Report Added Successfully")
                    resetForm()
                    handleCloseModal()
                } else {
                    showWarningDialog(result.message)
                }
            }
        }
        catch (err) {
            console.log("exception")
        }
        finally {
            setIsProcessing(false)
        }

    };

    useEffect(() => {
        if (singleRHPW && RHPWValues) {

            setCurrentRHPW(singleRHPW);

            const sitesOptions = RHPWValues?.sites?.map(site => ({
                value: site.id.toString(),
                label: site.name
            })) || []

            const l3 = RHPWValues?.l3?.map(site => ({
                value: site.id.toString(),
                label: site.name
            })) || []

            const reporters = RHPWValues?.reporters?.map(user => ({
                value: user.id.toString(),
                label: user.name
            }))

            const selectedSite = sitesOptions.find(source => source.value === singleRHPW.siteId?.toString()) || null;
            const selectedL3 = l3.find(source => source.value === singleRHPW.l3Id?.toString()) || null;
            const selectedReporter = reporters.find(source => source.value === singleRHPW.reportedBy?.toString()) || null;

            const editFormData = {
                id: parseInt(singleRHPW.id),
                actionId: parseInt(singleRHPW.actionId),
                fileId: parseInt(singleRHPW.fileId),
                site: selectedSite,
                l3Contractor: selectedL3,
                locationPlace: singleRHPW.location,
                reportedAt: formatDateWithTime(singleRHPW.reportedAt, false),
                reportedByUser: selectedReporter,
                personCompletingReport: singleRHPW.crPersonName,
                contractorCompany: singleRHPW.crContractorId ? {
                    value: singleRHPW.crContractorId.toString(),
                    label: RHPWValues.crContractors.find(c => c.id === singleRHPW.crContractorId)?.name
                } : null,
                workPeriods: singleRHPW.workDonePeriod ? singleRHPW.workDonePeriod.map(wp => ({
                    value: wp,
                    label: RHPWValues.workDonePeriod[wp]
                })) : [],
                siteDetails: (singleRHPW.sites||[]).map(site => ({
                    id: site.id,
                    programType: site.ptId.toString(),
                    siteName: site.siteNeme,
                    siteWeeklyProgress: site.siteWeeklyProgress,
                    nmpisReported: site.nmpisReported,
                    nmpisRaisedOnHighRiskActivities: site.nmpisRaisedOnHighRiskActivities,
                    bbsqInterventions: site.bbsqInterventions,
                    contractorHsseAudit: site.contractorHsseAudit,
                    srManagementAudit: site.srManagementAudit,
                    siteExposureHours: site.siteExposureHours,
                    kmsTravelled: site.kmsTravelled,
                    highRiskActivities: site.highRiskActivities,
                    region: site.regionId.toString(),
                })),
                actions: (singleRHPW.actions || []).map(action => ({
                    id: action.id,
                    userId: action.userId,
                    description: action.description,
                    targetDays: action.targetDays,
                    createdAt: action.createdAt,
                    createdBy: action.createdBy,
                    updatedAt: action.updatedAt,
                    updatedBy: action.updatedBy,
                    submittedAt: action.submittedAt,
                    targetAt: action.targetAt,
                    reply: action.reply,
                    tags: action.tags,
                    comments: action.comments,
                    type: action.type,
                    status: action.status
                })),
                attachments: (singleRHPW.files || []).map(file => ({
                    id: file.id,
                    path: file.path,
                    filename: file.fileName,
                    description: file.description,
                    createdAt: file.createdAt,
                    createdBy: file.createdBy,
                    updatedAt: file.updatedAt,
                    updatedBy: file.updatedBy,
                    fullPath: file.fullPath,
                })),
            }
            setFormData(editFormData)
        }
    }, [singleRHPW]);

    const handleSelectChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };
    const handleAttachmentChange = (newAttachments) => {
        setFormData({ ...formData, attachments: newAttachments });
    };

    useEffect(() => {
        dispatch(getRHPWValues());
        dispatch(getUserRHPW(access.moduleId));
    }, [dispatch]);


    const handleEdit = async (data) => {
        await dispatch(getSingleRHPW(data.id));
        setModalOpen(true);
    };

    // const handleDelete = (object) => {
    //     setRHPWToDelete(object);
    //     setDeleteModalVisible(true);
    // };


    const handleDelete = async (hwr) => {
        try {
          const result = await showConfirmDialog("Are you sure you want to delete this HSSE Weekly Reporting ?");
          if (result.isConfirmed) {
            const object = await dispatch(deleteRHPW(hwr.id))
            if (object.success) {
              Swal.fire('Deleted!', 'Weekly HSSE Report Deleted', 'success');
              dispatch(getUserRHPW(access.moduleId));
            }
            else {
              Swal.fire('Error!', object.message, 'error');
            }
          }
        } catch (error) {
          console.error('Error in handleDelete:', error);
        }
    };


    // const confirmDelete = async () => {
    //     try {
    //         const object=dispatch(deleteRHPW(RHPWToDelete.id))
    //         if(object.result)
    //         {
    //             message.success(object.message)
    //         }
    //         setDeleteModalVisible(false);
    //         setRHPWToDelete(null)
    //     } catch (error) {
    //         alert('An error occurred while deleting the hazard');
    //     }
    // };

    let columns = [
        {
            title: "Action",
            key: "action",
            width: '120px',
            render: (text, data) => (
                <div style={{ display: 'flex', gap: '8px' }}>
                    {data.submittedAt === null && (
                        <Button color="primary" size="sm" onClick={() => handleEdit(data)} disabled={!accessRights.U}>
                            <EditOutlined />
                        </Button>
                    )}
                    {data.submittedAt === null && (<>
                        <Button color="danger" size="sm" onClick={() => handleDelete(data)} disabled={!accessRights.D}>
                            <DeleteOutlined />
                        </Button>
                    </>)}
                    <Button color="info" size="sm" onClick={() => viewRecord(data)} disabled={!accessRights.R}>
                        <EyeFilled />
                    </Button>
                    {data.submittedAt !== null && (
                        <Button color="info" size="sm" onClick={() => handleActionClick(data)} disabled={accessFilter === 'Shared'}>
                            <PlusCircleFilled />
                        </Button>
                    )}
                </div>
            ),
        },
        {
            title: "Sequence Number",
            dataIndex: "submittedAt",
            key: "submittedAt",
            sorter: stringSorter("submittedAt"),
            sortDirections: ["ascend", "descend"],
            render: (text, data) => {
                if (data.submittedAt !== null) {
                    return data.sequenceNo; // Assuming the sequence string is stored in the 'sequence' property
                } else {
                    return (
                        <Button color="primary" size="sm" onClick={() => handleGenerate(data)}>
                            Generate
                        </Button>
                    );
                }
            }
        },
        {
            title: "Reported At",
            dataIndex: "reportedAt",
            key: "reportedAt",
            sorter: dateSorter("reportedAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "L3 Contractor",
            dataIndex: "l3Contractor",
            key: "l3Contractor",
            sorter: stringSorter("l3Contractor"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
            sorter: stringSorter("country"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Business Unit",
            dataIndex: "businessUnit",
            key: "businessUnit",
            sorter: stringSorter("businessUnit"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Project/Program",
            dataIndex: "project",
            key: "project",
            sorter: stringSorter("project"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Site",
            dataIndex: "site",
            key: "site",
            sorter: stringSorter("site"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Location Place",
            dataIndex: "location",
            key: "location",
            sorter: stringSorter("location"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            sorter: stringSorter("description"),
            ellipsis: true,
        },
    ];


    const handleGenerate = async (data) => {
        setRHPWGenerate(data);
        setConfirmModalOpen(true);
    };

    const confirmRHPWGenerate = async () => {
        if (RHPWGenerate) {
            const object={
                moduleId:parseInt(access.moduleId),
                id:RHPWGenerate.id
            }
            const response = await dispatch(submitRHPWToGenerate(object));
            if (response && response.data.code === 1) {
                showSuccessDialog("HSSE Weekly Report Generated successfully")
            } else {
                showWarningDialog("Failed to generate HSSE Weekly Report")
            }
            setConfirmModalOpen(false);
            setRHPWGenerate(null);
        }
    };

    const handleActionClick = (data) => {
        setActionData(data);
        setActionModalOpen(true);
    };

    const handleInitiateAction = async (data) => {
        delete data.submitDate;
        if (data.message) {
            data.reply = actionData.message;
            delete data.message;
        }

        const payload = {
            informRhpwId: parseInt(actionData.id),
            id: parseInt(actionData.actionId),
            actions: JSON.stringify(data)
        };

        const response = await dispatch(submitActionForRHPW(payload));
        if (response.success) {
            showSuccessDialog(response.message)
            setActionData([]);
            setActionModalOpen(false);
            setModalOpen(false)
            dispatch(getUserRHPW(access.moduleId))
        } else {
            showWarningDialog(response.message)
        }
    };


    const filteredRHPW = useMemo(() => {
        switch (accessFilter) {
            case 'My':
                return [
                    ...(userRHPW.user || [])
                ];
            case 'Shared':
                return userRHPW.shared || [];
            case 'Assigned':
                return userRHPW.assigned || [];
            default:
                return [];
        }
    }, [userRHPW, accessFilter]);

    return (
        <Card>
            <CardHeader>
                <Row className="align-items-center mb-3">
                        <Col md={3}>
                            <h5 className="mb-0">Manage HSSE Weekly Reporting</h5>
                        </Col>
                        <Col md={9} className="d-flex justify-content-end align-items-center">
                            <AccessFilter
                                accessFilter={accessFilter}
                                handleAccessFilterChange={handleAccessFilterChange}
                                sharedAccess={userRHPW?.sharedAccess || []}
                            />
                            <Button color="primary" onClick={() => setModalOpen(true)} className="ms-2" disabled={!accessRights.C}>
                                <PlusCircleFilled size={18} /> New
                            </Button>
                        </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <ReactStrapDataTable
                    dataSource={filteredRHPW ?? []}
                    columns={columns}
                    rowKey="id"
                />
            </CardBody>
            <Modal isOpen={modalOpen} toggle={handleCloseModal} size="xl" backdrop="static">
                <Form onSubmit={handleSubmit}>
                    <ModalHeader toggle={handleCloseModal}>
                        {currentRHPW ? 'Edit HSSE Weekly Reporting' : 'Add HSSE Weekly Reporting'}
                    </ModalHeader>
                    <ModalBody>

                        <AdminSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            data={RHPWValues}
                        />
                        <WeeklyHSSEReportDetail
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            data={RHPWValues}
                        />
                        <ActionSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            actionUsers={RHPWValues?.actionUsers ?? []}
                        />
                        <AttachmentsSection
                            attachments={formData.attachments}
                            onAttachmentsChange={handleAttachmentChange}
                        />

                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleCloseModal}>Back</Button>
                        <DebouncedSaveButton
                            onSave={handleSubmit}
                            isProcessing={isProcessing}
                        />
                    </ModalFooter>
                </Form>
            </Modal>
            <Modal isOpen={confirmModalOpen} toggle={() => setConfirmModalOpen(false)}>
                <ModalHeader toggle={() => setConfirmModalOpen(false)}>Confirm Submission</ModalHeader>
                <ModalBody>
                    <p>Are you sure you want to submit this HSSE Weekly Reporting?</p>
                    <p style={{ color: 'red' }}>
                        Note: Once the HSSE Weekly Reporting is submitted it cannot be edited.
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setConfirmModalOpen(false)}>Cancel</Button>
                    <Button color="primary" onClick={confirmRHPWGenerate}>Yes, Submit</Button>
                </ModalFooter>
            </Modal>

            <ActionModal
                isOpen={actionModalOpen}
                toggle={toggleActionModal}
                data={actionData}
                message={"Action For HSSE Weekly Reporting"}
                onInitiateAction={handleInitiateAction}
            />
            <WeeklyReportModal
                isOpen={viewModalOpen}
                toggle={() => setViewModalOpen(!viewModalOpen)}
                data={selectedViewRecord}
            />
        </Card>

    )
}

export default RHPWWeeklyReporting