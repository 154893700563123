import React from 'react';
import { Card, CardBody, CardHeader, CardFooter, Row, Col, Container } from 'reactstrap';
import { AlertTriangle } from 'lucide-react';
import logodark from "../../../assets/images/logo-dark.png";



const Certificate = ({
                         username,
                         company,
                         description,
                         date,
                         leftSignerName,
                         leftSignerTitle,
                         rightSignerName,
                         rightSignerTitle,
                         courseName,
                     }) => {
    return (
        <Container className="my-5">
            <Card className="border-warning" style={{ maxWidth: '800px', margin: 'auto' }}>
                <CardHeader className="bg-white border-bottom-0">
                    <Row className="align-items-center">
                        <Col>
                            <img src={logodark} alt="Company Logo" style={{ width: 'auto', height: '30px' }} />
                        </Col>
                        <Col className="text-end">
                            <small className="fw-bold">{date}</small>
                            <br />
                            <small>India</small>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody className="text-center">
                    <h1 className="display-6 mb-4" style={{ color: '#013e7d' }}>Certificate of Recognition</h1>
                    <p className="mb-2">This certificate is awarded to</p>
                    <h2 className="display-5 mb-3" style={{ color: '#013e7d' }}>{username}</h2>
                    <p className="mb-2">{company}</p>
                    <p className="mb-4">for completing the course:</p>
                    <h3 className="h4 mb-4" style={{ color: '#013e7d' }}>{courseName}</h3>
                    <p className="mb-5">{description}</p>
                    <Row className="mt-5">
                        <Col className="text-center">
                            <div style={{ borderBottom: '1px solid black', width: '200px', margin: 'auto' }}></div>
                            <p className="mt-2 mb-0 fw-bold">{leftSignerName}</p>
                            <small>{leftSignerTitle}</small>
                        </Col>
                        <Col className="text-center">
                            <div style={{ borderBottom: '1px solid black', width: '200px', margin: 'auto' }}></div>
                            <p className="mt-2 mb-0 fw-bold">{rightSignerName}</p>
                            <small>{rightSignerTitle}</small>
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter className="bg-white text-center">
                    <small className="text-muted">
                        <AlertTriangle size={12} className="me-1" />
                        This is a digital representation. The actual certificate may vary.
                    </small>
                </CardFooter>
            </Card>
        </Container>
    );
};

export default Certificate;