import {
    ADD_MASTER,
    GET_MASTER,
    GET_MASTERS_TABLES,
    GET_TABLE_VALUES,
    UPDATE_MASTER,
    DELETE_MASTER
} from "../types";

const initialState = {
    list: [],
    tableValues: [],
    single: null
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_MASTERS_TABLES:
            return { ...state, list: payload };

        case GET_TABLE_VALUES:
            return { ...state, tableValues: payload };

        case GET_MASTER:
            return {
                ...state,
                list: [payload, ...state.list],
            };

        case ADD_MASTER:
            return {
                ...state,
                tableValues: [payload, ...state.tableValues],
            };

        case UPDATE_MASTER:
            return {
                ...state,
                tableValues: state.tableValues.map((item) =>
                    item.id === payload.id ? payload : item
                ),
            };

        case DELETE_MASTER:
            return {
                ...state,
                tableValues: state.tableValues.filter((item) => item.id !== payload),
            };

        default:
            return state;
    }
};

export default reducer;