import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import {DeleteOutlined, EditOutlined, EyeFilled, PlusCircleFilled} from "@ant-design/icons";
import {
    AccessFilter,
    dateSorter,
    formatDateWithTime,
    manageCURDAccess,
    stringSorter
} from "../../../../utils/functions";
import React, {useEffect, useState,useMemo} from "react";
import ReactStrapDataTable from "../../../CustomDatatable/ReactStrapDataTable";
import {
    AdminSection,
    AttachmentsSection,
    ActionSection,
    ActionModal,
    LOD1Section, LOD1Findings
} from "../../Common/Forms"
import {useDispatch, useSelector} from "react-redux";
import {message} from "antd";
import {
    addLDO1Form,
    getSingleLDO1,
    getLDO1Values, getUserLDO1, submitActionForLDO1,
    submitLDO1ToGenerate,
    updateLDO1Form,
    deleteLDO1
} from "../../../../store/actions/Inform/Audits/ldo1";
import {LOD1AssuranceView} from "../../Views";
import DebouncedSaveButton from "../../../../components/Buttons/DebounceSaveButton";
import Swal from "sweetalert2";
import { showConfirmDialog, showSuccessDialog, showWarningDialog } from "../../../../utils/dialogs";

const LDO1 = ({access}) => {
    const accessRights = manageCURDAccess(access);
    const dispatch = useDispatch();
    const LDO1Values = useSelector((state) => state.ldo1.values);
    const userLDO1 = useSelector((state) => state.ldo1.userLDO1);
    const singleLDO1 = useSelector((state) => state.ldo1.single);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentLDO1, setCurrentLDO1] = useState(null);

    //SaveButton
    const [isProcessing, setIsProcessing] = useState(false);


    //SharedAccessFilter
    const [accessFilter, setAccessFilter] = useState('My');

    const handleAccessFilterChange = (selectedAccess) => {
        setAccessFilter(selectedAccess);
    };


    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [LDO1Generate, setLDO1Generate] = useState(null);

    const [ldo1todelete, setldo1todelete] = useState(null);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    //EYE BUTTON
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedViewRecord, setSelectedRecord] = useState(null);

    const viewRecord = (record) => {
        console.log(record);
        setSelectedRecord(record);
        setViewModalOpen(true)
    }

    //ActionModal
    const [actionModalOpen, setActionModalOpen] = useState(false);
    const toggleActionModal = () => setActionModalOpen(!actionModalOpen);


    const [actionData, setActionData] = useState({})

    const handleCloseModal = () => {
        resetForm();
        setModalOpen(false);
        setCurrentLDO1(null);
    };

    const [formData, setFormData] = useState({
        editForm: false,
        site: null,
        siteName: null,
        siteManagerName: null,
        l3Contractor: null,
        interventionType: null,
        auditor: null,
        region: null,
        severity: null,
        permitNumber: '',
        permitIssueData: new Date().toISOString().split('T')[0],
        permitIssuer: '',
        locationPlace: '',
        reportedAt: new Date().toISOString().split('T')[0],
        selectedLDO1Options: [],
        copyOptions: {value: false, label: 'No'},
        assesorName: '',
        siteSupervisor: '',
        permitHolder: '',
        reportedByUser: null,
        actions: [],
        attachments: [],
        inspectedBySign: null,
        auditedAt: new Date().toISOString().split('T')[0],

    });

    const resetForm = () => {
        setFormData({
            editForm: false,
            site: null,
            siteName: null,
            siteManagerName: null,
            l3Contractor: null,
            permitNumber: '',
            permitIssueData: new Date().toISOString().split('T')[0],
            permitIssuer: '',
            copyOptions: {value: false, label: 'No'},
            assesorName: '',
            auditor: null,
            selectedLDO1Options: [],
            locationPlace: '',
            reportedAt: new Date().toISOString().split('T')[0],
            reportedByUser: null,
            actions: [],
            attachments: [],
            inspectedBySign: null,
            auditedAt: new Date().toISOString().split('T')[0],
        });
    };
    const validateForm = () => {

        if (!formData.site) {
            message.error("Site Required *");
            return false;
        }
        if (!formData.l3Contractor) {
            message.error("L3 Contractor is required *");
            return false;
        }
        if (!formData.reportedByUser) {
            message.error("Reported By User required *");
            return false;
        }
        if (!formData.siteName) {
            message.error("Site Name Required *");
            return false;
        }
        if (formData.permitNumber === "") {
            message.error("Permit Number Required *");
            return false;
        }
        if (formData.permitIssueData === "") {
            message.error("Permit Issue Date Required *");
            return false;
        }
        if (formData.permitIssuer === "") {
            message.error("Permit Issuer Required *");
            return false;
        }
        if (formData.permitHolder === "") {
            message.error("Permit Holder Required *");
            return false;
        }
        if (formData.assesorName === "") {
            message.error("Assesor Name Required *");
            return false;
        }
        if (formData.siteSupervisor === "") {
            message.error("Site Supervisior Name Required *");
            return false;
        }

        return true;
    };
    //
    const handleInputChange = (nameOrEvent, value) => {
        let updatedName, updatedValue;

        if (typeof nameOrEvent === 'string') {
            // If called with (name, value)
            updatedName = nameOrEvent;
            updatedValue = value;
        } else if (nameOrEvent && nameOrEvent.target) {
            // If called with event object
            updatedName = nameOrEvent.target.name;
            updatedValue = nameOrEvent.target.value;
        } else {
            // Invalid input
            console.error('Invalid input to handleInputChange');
            return;
        }

        setFormData(prevData => ({
            ...prevData,
            [updatedName]: updatedValue
        }));
    };
    const handleSubmit = async () => {
        
        if (!validateForm()) {
            throw new Error("Form validation failed");
        }
        
        setIsProcessing(true)
        const formDataObj = new FormData();
        formDataObj.append("moduleId",parseInt(access.moduleId))

        if (formData.id) {
            formDataObj.append('id', parseInt(formData.id.toString()));
            formDataObj.append('actionId', parseInt(formData.actionId));
            formDataObj.append('fileId', parseInt(formData.fileId));
            formDataObj.append('findingId', parseInt(formData.findingId));
        }

        if (formData.site?.value) formDataObj.append('siteId', parseInt(formData.site.value));
        if (formData.l3Contractor?.value) formDataObj.append('l3Id', parseInt(formData.l3Contractor.value));
        if (formData.auditor?.value) formDataObj.append('auditorId', parseInt(formData.auditor.value));
        if (formData.region) formDataObj.append('regionId', parseInt(formData.region));
        if (formData.reportedByUser) formDataObj.append('reportedBy', parseInt(formData.reportedByUser.value));
        formDataObj.append('location', formData.locationPlace || "");
        formDataObj.append('reportedAt', formData.reportedAt || "");
        formDataObj.append('siteName', formData.siteName);
        formDataObj.append('permitNumber', formData.permitNumber);
        formDataObj.append('permitIssuedAt', formData.permitIssueData);
        formDataObj.append('permitIssuer', formData.permitIssuer);
        formDataObj.append('permitHolder', formData.permitHolder);
        formDataObj.append('accessorName', formData.assesorName);
        formDataObj.append('siteSupervisor', formData.siteSupervisor);
        formDataObj.append('copyToClient', formData.copyOptions.value);
        formDataObj.append('auditedAt', formData.auditedAt);

        if (formData.inspectedBySign !== "" && formData.inspectedBySign !== null && formData.inspectedBySign !== undefined) {
        }
        {
            formDataObj.append('auditorSign', formData.inspectedBySign);
        }

        if (formData.selectedLDO1Options !== null && formData.selectedLDO1Options !== undefined) {
            if (formData.editForm === false) {
                if (formData.selectedLDO1Options.length > 0) {
                    formDataObj.append('ratings', JSON.stringify(formData.selectedLDO1Options));
                } else {
                    formDataObj.append('ratings', JSON.stringify([]));
                }
            } else {
                if (formData.selectedLDO1Options.length > 0) {
                    const isArrayOfStrings = arr => arr.every(item => typeof item === 'string');
                    const isStringArray = isArrayOfStrings(formData.selectedLDO1Options);
                    if (!isStringArray) {
                        formDataObj.append('ratings', JSON.stringify(formData.selectedLDO1Options.map((element) => element.childrenId)));
                    } else {
                        formDataObj.append('ratings', JSON.stringify(formData.selectedLDO1Options));
                    }
                } else {
                    formDataObj.append('ratings', JSON.stringify([]));
                }
            }
        } else {
            formDataObj.append('ratings', JSON.stringify([]));
        }

        if (formData.findings !== null && formData.findings !== undefined) {
            if (Array.isArray(formData.findings)) {
                formDataObj.append('findings', JSON.stringify(formData.findings.map(finding => ({
                    id: finding.id || null,
                    path: finding.image || null,
                    description: finding.description || null,
                    itemNo: finding.itemNo || null,
                    evidence: finding.evidence || null,
                    comments:finding.comments||null,
                    severity: finding.severity || null,
                    createdAt: finding.createdAt || null,
                    createdBy: finding.createdBy || null,
                    updateBy: finding.updateBy || null,
                    updatedAt: finding.updatedAt || null,
                }))));
            } else {
                formDataObj.append('findings', JSON.stringify([]));
            }

        } else {
            formDataObj.append('findings', JSON.stringify([]));
        }


        if (Array.isArray(formData.actions)) {
            formDataObj.append('actions', JSON.stringify(formData.actions.map(action => ({
                id: action.id || null,
                userId: action.userId ? parseInt(action.userId) : null,
                description: action.description || null,
                createdAt: action.createdAt || null,
                createdBy: action.createdBy || null,
                updatedAt: action.updatedAt || null,
                updatedBy: action.updatedBy || null,
                submittedAt: action.submittedAt || null,
                targetAt: action.targetAt || null,
                reply: action.reply || null,
                tags: action.tags || [],
                type: action.type || "N",
                status: action.status || "PENDING",
            }))));
        }

        if (Array.isArray(formData.attachments)) {
            formDataObj.append('files', JSON.stringify(formData.attachments.map(attachment => ({
                id: attachment.id || null,
                fileName: attachment.filename || "",
                description: attachment.description || null,
                path: attachment.path || null,
                createdAt: attachment.createdAt || null,
                createdBy: attachment.createdBy || null,
                updateBy: attachment.updateBy || null,
                updatedAt: attachment.updatedAt || null,
            }))));

            formData.attachments.forEach((attachment, index) => {
                if (attachment.file instanceof File) {
                    formDataObj.append(`attachments[${index}]`, attachment.file, attachment.filename);
                }
            });
        }

        // console.log("FormData contents:");
        // for (let [key, value] of formDataObj.entries()) {
        //     if (value instanceof File) {
        //         console.log(key, value.name, value.type, value.size + " bytes");
        //     } else {
        //         console.log(key, value);
        //     }
        // }

        try
        {
            let result
            if (formData.id) {
                result=await dispatch(updateLDO1Form(formDataObj));
            } else {
                result=await dispatch(addLDO1Form(formDataObj));
            }
            if(result.success)
            {
                if(formData.id) {
                    showSuccessDialog("LOD1  Updated Successfully")
                }
                else {
                    showSuccessDialog("LOD1 Added Successfully")
                }
                //showSuccessDialog(result.message)
                resetForm()
                setModalOpen(false)
                dispatch(getUserLDO1(access.moduleId))
            }
            else
            {
                showWarningDialog(result.message)
            }
        }
        catch(ex){
            // console.log("Exception")
        }
        finally{
            setIsProcessing(false)
        }
        

    };

    useEffect(() => {
        if (singleLDO1 && LDO1Values) {
            // console.log(singleLDO1)
            setCurrentLDO1(singleLDO1);

            const sitesOptions = LDO1Values?.sites?.map(site => ({
                value: site.id.toString(),
                label: site.name
            })) || []

            const l3 = LDO1Values?.l3?.map(site => ({
                value: site.id.toString(),
                label: site.name
            })) || []

            const reporters = LDO1Values?.reporters?.map(user => ({
                value: user.id.toString(),
                label: user.name
            }))


            const selectedSite = sitesOptions.find(source => source.value === singleLDO1.siteId?.toString()) || null;
            const selectedL3 = l3.find(source => source.value === singleLDO1.l3Id?.toString()) || null;
            const selectedReporter = reporters.find(source => source.value === singleLDO1.reportedBy?.toString()) || null;


            const selectedLDO1Options = LDO1Values.lod1Ratings.flatMap(section =>
                section.children
                    .filter(child => singleLDO1.ratings.includes(child.id))
                    .map(child => ({
                        parentId: section.id,
                        childrenId: child.id,
                        label: child.label
                    }))
            );


            const selectedCopyOption = singleLDO1.copyToClient === true ? {value: true, label: 'Yes'} : {
                value: false,
                label: 'No'
            }

            let findings
            if (singleLDO1.findings !== undefined && singleLDO1.findings !== null) {
                findings = singleLDO1.findings.map(finding => ({
                    id: finding.id,
                    itemNo: finding.itemNo,
                    evidence: finding.evidence,
                    comments: finding.comments,
                    severity: finding.severity,
                    description: finding.description,
                    image: finding.fullPath
                }))
            } else {
                findings = []
            }


            const editFormData = {
                editForm: true,
                copyOptions: selectedCopyOption,
                id: parseInt(singleLDO1.id),
                actionId: parseInt(singleLDO1.actionId),
                fileId: parseInt(singleLDO1.fileId),
                findingId: parseInt(singleLDO1.findingId),
                site: selectedSite,
                l3Contractor: selectedL3,
                locationPlace: singleLDO1.location,
                reportedAt: formatDateWithTime(singleLDO1.reportedAt, false),
                auditedAt: formatDateWithTime(singleLDO1.auditedAt, false),
                reportedByUser: selectedReporter,
                siteName: singleLDO1.site,
                siteManagerName: singleLDO1.siteManager,
                permitNumber: singleLDO1.permitNumber,
                permitIssuer: singleLDO1.permitIssuer,
                permitHolder: singleLDO1.permitHolder,
                permitIssueData: formatDateWithTime(singleLDO1.permitIssuedAt, false),
                assesorName: singleLDO1.accessorName,
                siteSupervisor: singleLDO1.siteSupervisor,
                actions: (singleLDO1.actions || []).map(action => ({
                    id: action.id,
                    userId: action.userId,
                    description: action.description,
                    targetDays: action.targetDays,
                    createdAt: action.createdAt,
                    createdBy: action.createdBy,
                    updatedAt: action.updatedAt,
                    updatedBy: action.updatedBy,
                    submittedAt: action.submittedAt,
                    targetAt: action.targetAt,
                    reply: action.reply,
                    tags: action.tags,
                    comments: action.comments,
                    type: action.type,
                    status: action.status
                })),
                attachments: (singleLDO1.files || []).map(file => ({
                    id: file.id,
                    path: file.path,
                    filename: file.fileName,
                    description: file.description,
                    createdAt: file.createdAt,
                    createdBy: file.createdBy,
                    updatedAt: file.updatedAt,
                    updatedBy: file.updatedBy,
                    fullPath: file.fullPath,
                })),
                selectedLDO1Options: selectedLDO1Options,
                findings: findings,
                inspectedBySign: singleLDO1.auditorSign,
            }

            setFormData(editFormData)

            // console.log(formData.auditor)

        }
    }, [singleLDO1]);

    const handleSelectChange = (name, value) => {
        // console.log(value);
        setFormData({...formData, [name]: value});
    };
    const handleAttachmentChange = (newAttachments) => {
        setFormData({...formData, attachments: newAttachments});
    };

    useEffect(() => {
        dispatch(getLDO1Values());
        dispatch(getUserLDO1(access.moduleId));
    }, [dispatch]);


    const handleEdit = async (data) => {
        await dispatch(getSingleLDO1(data.id));
        setModalOpen(true);
    };

    // const handleDelete = (object) => {
    //     setldo1todelete(object);
    //     setDeleteModalVisible(true);
    // };

    const handleDelete = async (lod1) => {
        try {
          const result = await showConfirmDialog("Are you sure you want to delete this LOD1 ?");
          if (result.isConfirmed) {
            const object = await dispatch(deleteLDO1(lod1.id))
            if (object.success) {
              Swal.fire('Deleted!', object.message, 'success');
              dispatch(getUserLDO1(access.moduleId));
            }
            else {
              Swal.fire('Error!', object.message, 'error');
            }
          }
        } catch (error) {
          console.error('Error in handleDelete:', error);
        }
    };

    

    let columns = [
        {
            title: "Action",
            key: "action",
            width: '120px',
            render: (text, data) => (
                <div style={{display: 'flex', gap: '8px'}}>
                    {data.submittedAt === null && (
                        <Button color="primary" size="sm" onClick={() => handleEdit(data)} disabled={!accessRights.U}>
                            <EditOutlined/>
                        </Button>
                    )}
                   {data.submittedAt === null && (<>
                        <Button color="danger" size="sm" onClick={() => handleDelete(data)} disabled={!accessRights.D}>
                            <DeleteOutlined />
                        </Button>
                    </>)}
                    <Button color="info" size="sm" onClick={() => viewRecord(data)} disabled={!accessRights.U}>
                        <EyeFilled/>
                    </Button>
                    {data.submittedAt !== null && (
                        <Button color="info" size="sm" onClick={() => handleActionClick(data)} disabled={accessFilter === 'Shared'}>
                            <PlusCircleFilled/>
                        </Button>
                    )}

                </div>
            ),
        },
        {
            title: "Sequence Number",
            dataIndex: "submittedAt",
            key: "submittedAt",
            sorter: stringSorter("submittedAt"),
            sortDirections: ["ascend", "descend"],
            render: (text, data) => {
                if (data.submittedAt !== null) {
                    return data.sequenceNo; // Assuming the sequence string is stored in the 'sequence' property
                } else {
                    return (
                        <Button color="primary" size="sm" onClick={() => handleGenerate(data)}>
                            Generate
                        </Button>
                    );
                }
            }
        },
        {
            title: "Reported At",
            dataIndex: "reportedAt",
            key: "reportedAt",
            sorter: dateSorter("reportedAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "L3 Contractor",
            dataIndex: "l3Contractor",
            key: "l3Contractor",
            sorter: stringSorter("l3Contractor"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
            sorter: stringSorter("country"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Business Unit",
            dataIndex: "businessUnit",
            key: "businessUnit",
            sorter: stringSorter("businessUnit"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Project/Program",
            dataIndex: "project",
            key: "project",
            sorter: stringSorter("project"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Site",
            dataIndex: "site",
            key: "site",
            sorter: stringSorter("site"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Location Place",
            dataIndex: "location",
            key: "location",
            sorter: stringSorter("location"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            sorter: stringSorter("description"),
            ellipsis: true,
        },
    ];


    const handleGenerate = async (data) => {
        setLDO1Generate(data);
        setConfirmModalOpen(true);
    };
    const confirmLDO1Generate = async () => {
        if (LDO1Generate) {
            const object={
                moduleId:access.moduleId,
                id:LDO1Generate.id
            }
            const response = await dispatch(submitLDO1ToGenerate(object));
            if (response && response.data.code === 1) {
                showSuccessDialog("LOD1 Sequence Generated Successfully")
            } else {
                showWarningDialog("Contact to Admin")
            }
            setConfirmModalOpen(false);
            setLDO1Generate(null);
        }
    };
    const handleActionClick = (data) => {
        setActionData(data);
        setActionModalOpen(true);
    };
    const handleInitiateAction = async (data) => {
        delete data.submitDate;
        if (data.message) {
            data.reply = actionData.message;
            delete data.message;
        }

        const payload = {
            informTbtId: parseInt(actionData.id),
            id: parseInt(actionData.actionId),
            actions: JSON.stringify(data)
        };

        const response = await dispatch(submitActionForLDO1(payload));
        if (response.success) {
            showSuccessDialog(response.message)
            setActionData([]);
            setActionModalOpen(false);
            setModalOpen(false)
            dispatch(getUserLDO1(access.moduleId))
        } else {
            showWarningDialog(response.message)
        }
    };


    const filteredLDO1 = useMemo(() => {
        switch (accessFilter) {
            case 'My':
                return [
                    ...(userLDO1.user || [])
                ];
            case 'Shared':
                return userLDO1.shared || [];
            case 'Assigned':
                return userLDO1.assigned || [];
            default:
                return [];
        }
      }, [userLDO1, accessFilter]);
    




    return (
        <Card>
            <CardHeader>
                <Row className="align-items-center mb-3">
                        <Col md={3}>
                            <h5 className="mb-0">Manage LOD1 Audit</h5>
                        </Col>
                        <Col md={9} className="d-flex justify-content-end align-items-center">
                            <AccessFilter
                                accessFilter={accessFilter}
                                handleAccessFilterChange={handleAccessFilterChange}
                                sharedAccess={userLDO1?.sharedAccess || []}
                            />
                            <Button color="primary" onClick={() => setModalOpen(true)} className="ms-2" disabled={!accessRights.C}>
                                <PlusCircleFilled size={18} /> New
                            </Button>
                        </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <ReactStrapDataTable
                    dataSource={filteredLDO1 ?? []}
                    columns={columns}
                    rowKey="id"
                />
            </CardBody>
            <Modal isOpen={modalOpen} toggle={handleCloseModal} size="xl" backdrop="static">
                <Form onSubmit={handleSubmit}>
                    <ModalHeader toggle={handleCloseModal}>
                        {currentLDO1 ? 'Edit LOD1 Audit' : 'Add LOD1 Audit'}
                    </ModalHeader>
                    <ModalBody>
                        <AdminSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            data={LDO1Values}
                        />
                        <LOD1Section
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            data={LDO1Values}

                        />
                        <LOD1Findings
                            formData={formData}
                            handleInputChange={handleInputChange}
                            data={LDO1Values}
                        />
                        <ActionSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            actionUsers={LDO1Values?.actionUsers ?? []}
                        />
                        <AttachmentsSection
                            attachments={formData.attachments}
                            onAttachmentsChange={handleAttachmentChange}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleCloseModal}>Back</Button>
                        <DebouncedSaveButton
                            onSave={handleSubmit}
                            isProcessing={isProcessing}
                        />
                    </ModalFooter>
                </Form>
            </Modal>
           
            <Modal isOpen={confirmModalOpen} toggle={() => setConfirmModalOpen(false)}>
                <ModalHeader toggle={() => setConfirmModalOpen(false)}>Confirm Submission</ModalHeader>
                <ModalBody>
                    <p>Are you sure you want to submit this LDO1 Audit?</p>
                    <p style={{color: 'red'}}>
                        Note: Once the LDO1 Audit is submitted it cannot be edited.
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setConfirmModalOpen(false)}>Cancel</Button>
                    <Button color="primary" onClick={confirmLDO1Generate}>Yes, Submit</Button>
                </ModalFooter>
            </Modal>

            <ActionModal
                isOpen={actionModalOpen}
                toggle={toggleActionModal}
                data={actionData}
                message={"Action For LOD1 SELF ASSURANCE Audit"}
                onInitiateAction={handleInitiateAction}
            />
            <LOD1AssuranceView
                isOpen={viewModalOpen}
                toggle={() => setViewModalOpen(!viewModalOpen)}
                data={selectedViewRecord}
                instructions={LDO1Values?.lod1Ratings ?? []}
            />
        </Card>

    )
}

export default LDO1