import React from "react";
import {Navigate, Route} from "react-router-dom";
import {isLogin} from "../utils/auth";

const AuthProtected = (props) => {
    if (!isLogin()) {
        return <Navigate to="/login" replace/>;
    }
    return <>{props.children}</>;
};

const AccessRoute = ({component: Component, ...rest}) => {
    return (
        <Route
            {...rest}
            render={props => {
                return (<> <Component {...props} /> </>);
            }}
        />
    );
};

export {AuthProtected, AccessRoute};
