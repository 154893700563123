import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';

const DebouncedSaveButton = ({ onSave, isUploading }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    let timer;
    if (isUploading) {
      setIsDisabled(true);
    } else {
      timer = setTimeout(() => {
        setIsDisabled(false);
      }, 1000); // 1 second debounce
    }

    return () => clearTimeout(timer);
  }, [isUploading]);

  const handleClick = () => {
    if (!isDisabled) {
      onSave();
    }
  };

  return (
    <Button 
      color="primary" 
      onClick={handleClick} 
      disabled={isDisabled}
    >
      {isDisabled ? 'Uploading...' : 'Save'}
    </Button>
  );
};

export default DebouncedSaveButton;