import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import {DeleteOutlined, EditOutlined, EyeFilled, PlusCircleFilled} from "@ant-design/icons";
import {
    AccessFilter,
    dateSorter,
    formatDateWithTime,
    manageCURDAccess,
    stringSorter
} from "../../../../utils/functions";
import React, {useEffect, useState,useMemo} from "react";
import ReactStrapDataTable from "../../../CustomDatatable/ReactStrapDataTable";
import {AdminSection, AttachmentsSection, ActionSection, ActionModal, ToolBoxTalkSection} from "../../Common/Forms"
import {useDispatch, useSelector} from "react-redux";
import {message} from "antd";
import {
    addTBTForm,
    getSingleTBT,
    getTBTValues, getUserTBT, submitActionForTBT,
    submitTBTToGenerate,
    updateTBTForm
} from "../../../../store/actions/Inform/Audits/tbt";
import {ToolBoxTalkView} from "../../Views";
import DebouncedSaveButton from "../../../../components/Buttons/DebounceSaveButton";
import Swal from "sweetalert2";
import { showConfirmDialog, showSuccessDialog, showWarningDialog } from "../../../../utils/dialogs";



const ToolBoxTalk=({access})=>{
    const accessRights = manageCURDAccess(access);
    const dispatch = useDispatch();
    const TBTValues = useSelector((state) => state.tbt.values);
    const userTBT = useSelector((state) => state.tbt.userTBT);
    const singleTBT = useSelector((state) => state.tbt.single);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentTBT, setCurrentTBT] = useState(null);

    const [deleteTBT, setDeleteTBT] = useState(null);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);


    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [TBTGenerate, setTBTGenerate] = useState(null);

    //ActionModal
    const [actionModalOpen, setActionModalOpen] = useState(false);
    const toggleActionModal = () => setActionModalOpen(!actionModalOpen);


    //EYEBUTTON
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedViewRecord, setSelectedRecord] = useState(null);

    //SaveButton
     const [isProcessing, setIsProcessing] = useState(false);

    const viewRecord = (record) => {
        console.log(record)
        setSelectedRecord(record);
        setViewModalOpen(true)
    }

    //Shared Access
    const [accessFilter, setAccessFilter] = useState('My');

    const handleAccessFilterChange = (selectedAccess) => {
        setAccessFilter(selectedAccess);
    };


    const [actionData, setActionData] = useState({})

    const handleCloseModal = () => {
        resetForm();
        setModalOpen(false);
        setCurrentTBT(null);
    };

    const [formData, setFormData] = useState({
        site: null,
        l3Contractor: null,
        locationPlace: '',
        reportedAt: new Date().toISOString().split('T')[0],
        reportedByUser: null,
        permitWork:false,
        actions: [],
        attachments: [],
        effectives:[],
        toolboxDiscussions:[],
        discussionPoints:'',
        sitePHName:''

    });

    const resetForm = () => {
        setFormData({
            site: '',
            l3Contractor: '',
            locationPlace: '',
            reportedAt: new Date().toISOString().split('T')[0],
            reportedByUser: null,
            actions: [],
            attachments: [],
            permitWork:false,
            toolboxDiscussions:[],
            discussionPoints:'',
            sitePHName:''
        });
    };
    const validateForm = () => {

        if (!formData.site) {
            message.error("Site Required *");
            return false;
        }
        if (!formData.l3Contractor) {
            message.error("L3 Contractor is required *");
            return false;
        }
        if (!formData.reportedByUser) {
            message.error("Reported By User required *");
            return false;
        }

        return true;
    };
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };
    const handleSubmit = async () => {
        
        if (!validateForm()) {
            throw new Error("Form validation failed");
        }
        

        setIsProcessing(true)
        const formDataObj = new FormData();
        formDataObj.append("moduleId",parseInt(access.moduleId))

        if (formData.id) {
            formDataObj.append('id', parseInt(formData.id.toString()));
            formDataObj.append('actionId', parseInt(formData.actionId));
            formDataObj.append('fileId', parseInt(formData.fileId));
            formDataObj.append('effectiveId', parseInt(formData.effectiveId));
        }

        if (formData.site?.value) formDataObj.append('siteId', parseInt(formData.site.value));
        if (formData.l3Contractor?.value) formDataObj.append('l3Id', parseInt(formData.l3Contractor.value));
        formDataObj.append('location', formData.locationPlace || "");
        formDataObj.append('reportedAt', formData.reportedAt || "");
        if (formData.reportedByUser) formDataObj.append('reportedBy', parseInt(formData.reportedByUser.value));
        formDataObj.append('permitWork', formData.permitWork)
        formDataObj.append('discussionPoints', formData.discussionPoints)
        formDataObj.append('sitePHName', formData.sitePHName)

        if (Array.isArray(formData.toolboxDiscussions)) {
            formDataObj.append('tbDiscussions', JSON.stringify(formData.toolboxDiscussions));
        }

        if (Array.isArray(formData.effectiveness)) {
            formDataObj.append('effectives', JSON.stringify(formData.effectiveness.map(effective => ({
                id: effective.id || null,
                effectiveness: effective.effectiveness || null,
                improvementArea: effective.areasForImprovement || null,
                createdAt: effective.createdAt || null,
                createdBy: effective.createdBy || null,
                updatedAt: effective.updatedAt || null,
                updatedBy: effective.updatedBy || null,
            }))));
        }
        else{
            formDataObj.append('effectives',JSON.stringify([]))
        }


        if (Array.isArray(formData.actions)) {
            formDataObj.append('actions', JSON.stringify(formData.actions.map(action => ({
                id: action.id || null,
                userId: action.userId ? parseInt(action.userId) : null,
                description: action.description || null,
                createdAt: action.createdAt || null,
                createdBy: action.createdBy || null,
                updatedAt: action.updatedAt || null,
                updatedBy: action.updatedBy || null,
                submittedAt: action.submittedAt || null,
                targetAt: action.targetAt || null,
                reply: action.reply || null,
                tags: action.tags || [],
                type: action.type || "N",
                status: action.status || "PENDING",
            }))));
        }

        if (Array.isArray(formData.attachments)) {
            formDataObj.append('files', JSON.stringify(formData.attachments.map(attachment => ({
                id: attachment.id || null,
                fileName: attachment.filename || "",
                description: attachment.description || null,
                path: attachment.path || null,
                createdAt: attachment.createdAt || null,
                createdBy: attachment.createdBy || null,
                updateBy: attachment.updateBy || null,
                updatedAt: attachment.updatedAt || null,
            }))));

            formData.attachments.forEach((attachment, index) => {
                if (attachment.file instanceof File) {
                    formDataObj.append(`attachments[${index}]`, attachment.file, attachment.filename);
                }
            });
        }

        // console.log("FormData contents:");
        // for (let [key, value] of formDataObj.entries()) {
        //     if (value instanceof File) {
        //         console.log(key, value.name, value.type, value.size + " bytes");
        //     } else {
        //         console.log(key, value);
        //     }
        // }
        let result

        try
        {
            if(formData.id)
                {
                    result=await dispatch(updateTBTForm(formDataObj));
                }
                else {
                    result=await dispatch(addTBTForm(formDataObj));
                }
                if(result.success)
                {
                    if(formData.id) {
                        showSuccessDialog("ToolBox Talk  Updated Successfully")
                    }
                    else {
                        showSuccessDialog("ToolBox Talk Added Successfully")
                    }
                    // showSuccessDialog(result.message)
                    resetForm() 
                    setModalOpen(false)
                }
                else{
                    showWarningDialog(result.message)
                }
        }
        catch(ex){
            console.log("exception")
        }
        finally{
            setIsProcessing(false)
        }

        

    };

    useEffect(() => {
        if(singleTBT && TBTValues){
            console.log(singleTBT)
            setCurrentTBT(singleTBT);

            const sitesOptions=TBTValues?.sites?.map(site => ({
                value: site.id.toString(),
                label: site.name
            })) || []

            const l3=TBTValues?.l3?.map(site => ({
                value: site.id.toString(),
                label: site.name
            })) || []

            const reporters=TBTValues?.reporters?.map(user => ({
                value: user.id.toString(),
                label: user.name
            }))

            const selectedSite = sitesOptions.find(source => source.value === singleTBT.siteId?.toString()) || null;
            const selectedL3 = l3.find(source => source.value === singleTBT.l3Id?.toString()) || null;
            const selectedReporter= reporters.find(source => source.value === singleTBT.reportedBy?.toString()) || null;


            const editFormData={
                id:parseInt(singleTBT.id),
                actionId: parseInt(singleTBT.actionId),
                fileId: parseInt(singleTBT.fileId),
                effectiveId:parseInt(singleTBT.effectiveId),
                site: selectedSite,
                l3Contractor: selectedL3,
                locationPlace: singleTBT.location,
                reportedAt: formatDateWithTime(singleTBT.reportedAt,false),
                reportedByUser: selectedReporter,
                discussionPoints:singleTBT.discussionPoints,
                sitePHName:singleTBT.sitePHName,
                permitWork:singleTBT.permitWork,
                actions: (singleTBT.actions || []).map(action => ({
                    id: action.id,
                    userId: action.userId,
                    description: action.description,
                    targetDays: action.targetDays,
                    createdAt: action.createdAt,
                    createdBy: action.createdBy,
                    updatedAt: action.updatedAt,
                    updatedBy: action.updatedBy,
                    submittedAt: action.submittedAt,
                    targetAt: action.targetAt,
                    reply: action.reply,
                    tags: action.tags,
                    comments: action.comments,
                    type: action.type,
                    status: action.status
                })),
                attachments: (singleTBT.files || []).map(file => ({
                    id: file.id,
                    path: file.path,
                    filename: file.fileName,
                    description: file.description,
                    createdAt: file.createdAt,
                    createdBy: file.createdBy,
                    updatedAt: file.updatedAt,
                    updatedBy: file.updatedBy,
                    fullPath: file.fullPath,
                })),
                effectives: (singleTBT.effectives || []).map(effective => ({
                    id: effective.id,
                    effectiveness: effective.effectiveness,
                    areasForImprovement: effective.improvementArea,
                    createdAt: effective.createdAt,
                    createdBy: effective.createdBy,
                    updatedAt: effective.updatedAt,
                    updatedBy: effective.updatedBy
                })),
                // Set toolbox discussions
                toolboxDiscussions: singleTBT.tbDiscussions || []
            }
            setFormData(editFormData)
        }
    }, [singleTBT]);

    const handleSelectChange = (name, value) => {
        setFormData({...formData, [name]: value});
    };
    const handleAttachmentChange = (newAttachments) => {
        setFormData({...formData, attachments: newAttachments});
    };

    useEffect(() => {
        dispatch(getTBTValues());
        dispatch(getUserTBT(access.moduleId));
    }, [dispatch]);


    const handleEdit = async (data) => {
        await dispatch(getSingleTBT(data.id));
        setModalOpen(true);
    };


    const handleDelete = async (tbt) => {
        try {
          const result = await showConfirmDialog("Are you sure you want to delete this ToolBox Talk ?");
          if (result.isConfirmed) {
            const object = await dispatch(deleteTBT(tbt.id))
            if (object.success) {
              Swal.fire('Deleted!', object.message, 'success');
              dispatch(getUserTBT(access.moduleId));
            }
            else {
              Swal.fire('Error!', object.message, 'error');
            }
          }
        } catch (error) {
          console.error('Error in handleDelete:', error);
        }
    };

    // const confirmDelete = async () => {
    //     try {
    //         dispatch(deleteTBT(deleteTBT.id))
    //         setDeleteModalVisible(false);
    //         setDeleteTBT(null)
    //     } catch (error) {
    //         alert('An error occurred while deleting the hazard');
    //     }
    // };

   


    let columns = [
        {
            title: "Action",
            key: "action",
            width: '120px',
            render: (text, data) => (
                <div style={{display: 'flex', gap: '8px'}}>
                    {data.submittedAt === null && (
                        <Button color="primary" size="sm" onClick={() => handleEdit(data)} disabled={!accessRights.U}>
                            <EditOutlined/>
                        </Button>
                    )}
                    {data.submittedAt === null && (<>
                        <Button color="danger" size="sm" onClick={() => handleDelete(data)} disabled={!accessRights.D}>
                            <DeleteOutlined />
                        </Button>
                    </>)}
                    <Button color="info" size="sm" onClick={() => viewRecord(data)} disabled={!accessRights.R}>
                        <EyeFilled/>
                    </Button>
                    {data.submittedAt !== null && (
                        <Button color="info" size="sm" onClick={() => handleActionClick(data)} disabled={accessFilter === 'Shared'}>
                            <PlusCircleFilled/>
                        </Button>
                    )}

                </div>
            ),
        },
        {
            title: "Sequence Number",
            dataIndex: "submittedAt",
            key: "submittedAt",
            sorter: stringSorter("submittedAt"),
            sortDirections: ["ascend", "descend"],
            render: (text, data) => {
                if (data.submittedAt !== null) {
                    return data.sequenceNo; // Assuming the sequence string is stored in the 'sequence' property
                } else {
                    return (
                        <Button color="primary" size="sm" onClick={() => handleGenerate(data)}>
                            Generate
                        </Button>
                    );
                }
            }
        },
        {
            title: "Reported At",
            dataIndex: "reportedAt",
            key: "reportedAt",
            sorter: dateSorter("reportedAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "L3 Contractor",
            dataIndex: "l3Contractor",
            key: "l3Contractor",
            sorter: stringSorter("l3Contractor"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
            sorter: stringSorter("country"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Business Unit",
            dataIndex: "businessUnit",
            key: "businessUnit",
            sorter: stringSorter("businessUnit"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Project/Program",
            dataIndex: "project",
            key: "project",
            sorter: stringSorter("project"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Site",
            dataIndex: "site",
            key: "site",
            sorter: stringSorter("site"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Location Place",
            dataIndex: "location",
            key: "location",
            sorter: stringSorter("location"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            sorter: stringSorter("description"),
            ellipsis: true,
        },
    ];


    const handleGenerate = async (data) => {
        setTBTGenerate(data);
        setConfirmModalOpen(true);
        console.log(confirmModalOpen)
    };


    const confirmTBTGenerate = async () => {
        if (TBTGenerate) {
            const object={
                moduleId:parseInt(access.moduleId),
                id:TBTGenerate.id
            }
            const response = await dispatch(submitTBTToGenerate(object));
            if (response && response.data.code === 1) {
                showSuccessDialog("ToolBox Talk generated successfully")
            } else {
                showWarningDialog("Failed to generate TBT")
            }
            setConfirmModalOpen(false);
            setTBTGenerate(null);
        }
    };

    const handleActionClick = (data) => {
        setActionData(data);
        setActionModalOpen(true);
    };

    const handleInitiateAction = async (data) => {
        delete data.submitDate;
        if (data.message) {
            data.reply = actionData.message;
            delete data.message;
        }

        const payload = {
            informTbtId: parseInt(actionData.id),
            id: parseInt(actionData.actionId),
            actions: JSON.stringify(data)
        };

        const response = await dispatch(submitActionForTBT(payload));
        if (response.success) {

            showSuccessDialog(response.message)
            setActionData([]);
            setActionModalOpen(false);
            setModalOpen(false)
            dispatch(getUserTBT(access.moduleId))
        } else {
            showWarningDialog(response.message)
            message.error(response.message);
        }
    };


    const filteredTBT = useMemo(() => {
        switch (accessFilter) {
            case 'My':
                return [
                    ...(userTBT.user || [])
                ];
            case 'Shared':
                return userTBT.shared || [];
            case 'Assigned':
                return userTBT.assigned || [];
            default:
                return [];
        }
      }, [userTBT, accessFilter]);




    return(
        <Card>
            <CardHeader>
                <Row className="align-items-center mb-3">
                        <Col md={3}>
                            <h5 className="mb-0">Manage Tool Box Talk</h5>
                        </Col>
                        <Col md={9} className="d-flex justify-content-end align-items-center">
                            <AccessFilter
                                accessFilter={accessFilter}
                                handleAccessFilterChange={handleAccessFilterChange}
                                sharedAccess={userTBT?.sharedAccess || []}
                            />
                            <Button color="primary" onClick={() => setModalOpen(true)} className="ms-2" disabled={!accessRights.C}>
                                <PlusCircleFilled size={18} /> New
                            </Button>
                        </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <ReactStrapDataTable
                    dataSource={filteredTBT??[]}
                    columns={columns}
                    rowKey="id"
                />
            </CardBody>
            <Modal isOpen={modalOpen} toggle={handleCloseModal} size="xl" backdrop="static">
                <Form onSubmit={handleSubmit}>
                    <ModalHeader toggle={handleCloseModal}>
                        {currentTBT ? 'Edit Tool Box Talk' : 'Add Tool Box Talk'}
                    </ModalHeader>
                    <ModalBody>

                        <AdminSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            data={TBTValues}
                        />
                        <ToolBoxTalkSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            data={TBTValues}
                        />
                        <ActionSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            actionUsers={TBTValues?.actionUsers ?? []}
                        />
                        <AttachmentsSection
                            attachments={formData.attachments}
                            onAttachmentsChange={handleAttachmentChange}
                        />

                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleCloseModal}>Back</Button>
                        <DebouncedSaveButton
                            onSave={handleSubmit}
                            isProcessing={isProcessing}
                        />
                    </ModalFooter>



                </Form>
            </Modal>
          
            <Modal isOpen={confirmModalOpen} toggle={() => setConfirmModalOpen(false)}>
                <ModalHeader toggle={() => setConfirmModalOpen(false)}>Confirm Submission</ModalHeader>
                <ModalBody>
                    <p>Are you sure you want to submit this Tool Box Talk?</p>
                    <p style={{color: 'red'}}>
                        Note: Once the ToolBox Talk is submitted it cannot be edited.
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setConfirmModalOpen(false)}>Cancel</Button>
                    <Button color="primary" onClick={confirmTBTGenerate}>Yes, Submit</Button>
                </ModalFooter>
            </Modal>

            <ActionModal
                isOpen={actionModalOpen}
                toggle={toggleActionModal}
                data={actionData}
                message={"Action For ToolBoxTalk"}
                onInitiateAction={handleInitiateAction}
            />

            <ToolBoxTalkView
                isOpen={viewModalOpen}
                toggle={() => setViewModalOpen(!viewModalOpen)}
                data={selectedViewRecord}
                tbDiscussionOptions={TBTValues?.tbtDiscussions ?? []}

            />

        </Card>

    )
}

export default ToolBoxTalk