import {
    ADD_LDO1, DELETE_LDO1, GENERATE_LDO1,
    GET_SINGLE_LDO1,
    GET_USER_LDO1,
    LDO1_VALUES,
    UPDATE_LDO1
} from "../../../types";
import axios from "../../../../axios";
import {setAlert} from "../../alert";
import {message} from "antd";

export const getLDO1Values = () => async (dispatch) => {
    try {
        const res = await axios.get("/inform/lod1/values");
        if (res.data.code === 1) {
            dispatch({type: LDO1_VALUES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};


export const addLDO1Form = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/inform/lod1/add", formData, config);        
        if (res.data.code === 1) {            
            return {success: true, message: res.data.message};
        } else {
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        console.error("Error in addHazardForm:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
};
export const updateLDO1Form = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/inform/lod1/update", formData, config);
        
        if (res.data.code === 1) {
            dispatch({type: UPDATE_LDO1, payload: res.data.data});
            return {success: true, message: res.data.message};
        } else {
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        console.error("Error in addHazardForm:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
};

export const deleteLDO1 = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/inform/lod1/delete/${id}`);
        if (res.data.code === 1) {
            dispatch({type: DELETE_LDO1, payload: id});
            return {result:true,message:res.data.message}
        }
    } catch (err) {
        return {result:false,message:'Contact to Admin'}
        dispatch(setAlert(err.message, "danger"));
    }
};

export const getUserLDO1 = (accessModuleId) => async (dispatch) => {
    try {
       
        const object={
            moduleId:accessModuleId
        }

        const res = await axios.post("/inform/lod1/user",object);        
        if (res.data.code === 1) {
            dispatch({type: GET_USER_LDO1, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const getSingleLDO1=(id)=> async (dispatch) => {
    try {
        const res = await axios.get(`/inform/lod1/${id}`);
        
        if (res.data.code === 1) {
            dispatch({type: GET_SINGLE_LDO1, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const submitLDO1ToGenerate = (object) => async (dispatch) => {
    try {
        const res = await axios.post(`/inform/lod1/submit/`,object);
        if (res.data.code === 1) {
            dispatch({type: GENERATE_LDO1, payload: res.data.data});
        }
        return res
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const submitActionForLDO1 = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/inform/lod1/action/update", formData);
        if (res.data.code === 1) {
            //dispatch({type: GET_USER_HAZARD, payload: res.data.data});
            return {success: true, message: res.data.message};
        } else {
            
        }
    } catch (err) {
        console.error("Error in addHazardForm:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
}


