import {
    GENERATE_NCR,
    NCR_VALUES,
    GET_USER_NCR,
    GET_SINGLE_NCR,
    ADD_NCR,
    UPDATE_NCR,
    DELETE_NCR
} from "../../../../types";


const initialState = { list: [], single: null, userNCR: {}, values: [] };

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GENERATE_NCR:
            return {
                ...state,
                userNCR: payload
            };
        case NCR_VALUES:
            return { ...state, values: payload };
        case GET_USER_NCR:
            return { ...state, list: payload };
        case GET_SINGLE_NCR:
            return { ...state, single: payload };
        case ADD_NCR:
            return { ...state, userNCR: payload };
        case UPDATE_NCR:
            return {
                ...state,
                userNCR: payload
            };
        case DELETE_NCR:
            return {
                ...state,
                userNCR: state.userNCR.user.filter(object => object.id !== payload)
            };
        default:
            return state;
    }
};

export default reducer;