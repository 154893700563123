import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button, Card, Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup, Label, Input, Row, Col, CardHeader, CardBody
} from 'reactstrap';
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select';
import { dateTimeSort, stringSorter } from "../../../../utils/functions";
import { PlusCircleFilled, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { addSystemRole, getSysRoles, updateSystemRole, deleteSystemRole } from "../../../../store/actions/sysroles";
import ReactStrapDataTable from "../../../CustomDatatable/ReactStrapDataTable";

const SystemRoles = () => {
    document.title = "HSSE Meinhardt EPCM | Roles";
    const dispatch = useDispatch();
    const roles = useSelector((state) => state.roles.list);

    const [modalOpen, setModalOpen] = useState(false);
    const [currentRole, setCurrentRole] = useState(null);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [roleToDelete, setRoleToDelete] = useState(null);

    const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        defaultValues: {
            roleName: "",
            roleFor: null
        }
    });

    const roleForOptions = [
        { value: 'E', label: 'EMPLOYEE' },
        { value: 'O', label: 'OTHER' }
    ];

    const handleStatus = async (id, status) => {
        // Implement status update logic if needed
        await dispatch(getSysRoles());
    };

    const onFormSubmit = async (formData) => {
        try {
            const roleData = {
                ...formData,
                roleFor: formData.roleFor.value
            };
            if (currentRole) {
                const data={
                    name:roleData.roleName,
                    type:roleData.roleFor,
                    id:currentRole.id
                }
                await dispatch(updateSystemRole(data));
            } else {
                const payload = {
                    name: roleData.roleName,
                    type: roleData.roleFor
                };
                await dispatch(addSystemRole(payload));
            }
            handleCloseModal();
            dispatch(getSysRoles());
        } catch (error) {
            alert('An error occurred. Please try again.');
        }
    };

    const handleEdit = (role) => {
        setCurrentRole(role);
        setValue('roleName', role.name);
        setValue('roleFor', roleForOptions.find(option => option.value === role.type));
        setModalOpen(true);
    };

    const handleCloseModal = useCallback(() => {
        reset();
        setCurrentRole(null);
        setModalOpen(false);
    }, [reset]);

    const handleDelete = (role) => {
        setRoleToDelete(role);
        setDeleteModalVisible(true);
    };

    const confirmDelete = async () => {
        try {
            await dispatch(deleteSystemRole(roleToDelete.id));
            setDeleteModalVisible(false);
            dispatch(getSysRoles());
        } catch (error) {
            alert('An error occurred while deleting the role');
        }
    };

    const columns = [
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: dateTimeSort("createdAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Role Name",
            dataIndex: "name",
            key: "name",
            sorter: stringSorter("name"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Role For",
            dataIndex: "type",
            key: "type",
            render: (text) => text === 'E' ? 'EMPLOYEE' : 'OTHER',
        },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
                <>
                    <Button 
                        color="link" 
                        onClick={() => handleEdit(record)} 
                        disabled={record.isProtected}
                    > 
                        <EditOutlined />
                    </Button>
                    <Button 
                        color="link" 
                        onClick={() => handleDelete(record)}
                        disabled={record.isProtected}
                    >
                        <DeleteOutlined />
                    </Button>
                </>
            ),
        },
    ];

    useEffect(() => {
        dispatch(getSysRoles());
    }, [dispatch]);

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">Manage Roles</h5>
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" onClick={() => setModalOpen(true)}>
                                <PlusCircleFilled />
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactStrapDataTable
                        dataSource={roles.map((role, index) => ({
                            ...role,
                            key: index,
                        }))}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} backdrop={"static"}>
                <ModalHeader toggle={handleCloseModal}>
                    {currentRole ? 'Edit Role' : 'Add Role'}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="roleName">Role Name</Label>
                            <Controller
                                name="roleName"
                                control={control}
                                rules={{ required: "Role Name is required" }}
                                render={({ field }) => <Input {...field} id="roleName" />}
                            />
                            {errors.roleName && <span className="text-danger">{errors.roleName.message}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="roleFor">Role For</Label>
                            <Controller
                                name="roleFor"
                                control={control}
                                rules={{ required: "Role For is required" }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={roleForOptions}
                                        id="roleFor"
                                    />
                                )}
                            />
                            {errors.roleFor && <span className="text-danger">{errors.roleFor.message}</span>}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {currentRole ? "Update" : "Save"}
                        </Button>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>

            <Modal isOpen={deleteModalVisible} toggle={() => setDeleteModalVisible(false)}>
                <ModalHeader toggle={() => setDeleteModalVisible(false)}>Confirm Delete</ModalHeader>
                <ModalBody>
                    <p>Are you sure you want to delete this role?</p>
                    {roleToDelete && (
                        <p>
                            <strong>Role Name:</strong> {roleToDelete.name}
                        </p>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={confirmDelete}>Delete</Button>
                    <Button color="secondary" onClick={() => setDeleteModalVisible(false)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default SystemRoles;