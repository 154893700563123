import axios from "../../axios";
import {ADD_PERMISSION} from "../types";
import {setAlert} from "./alert";
import {message} from "antd";

export const savePermission = (payload) => async (dispatch) => {
    try {
        const res = await axios.post("/permission/binding/save", payload);
        ;
        if (res.data.code === 1) {
            dispatch({type: ADD_PERMISSION, payload: res.data.data});
            dispatch(setAlert(res.data.message, 'success'));
            message.success(res.data.message);

        } else {
            dispatch(setAlert(res.data.message, "warning"));
            message.error(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};