import React, {useEffect, useState,useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    Button, Card, CardHeader, CardBody, Row, Col, Form,
    Modal, ModalHeader, ModalBody, ModalFooter, Table,
} from 'reactstrap';
import {
    addComplaintForm,
    getComplaints,
    getUserComplaints,
    submitComplaint,
    getUserSingleComplaint,
    updateComplaintForm,
    submitActionForComplaint,
    deleteComplaint,
} from "../../../store/actions/Incident/complaint";
import {
    ActionModal,
    AdminSection,
    AttachmentsSection,
    ContributingFactorsSection,
    GeneralSection,
    ComplaintDetailsSection,
} from "../Common/Forms";
import {message} from "antd";
import {DeleteOutlined, EditOutlined, EyeFilled, PlusCircleFilled} from "@ant-design/icons";
import {AccessFilter, dateSorter, formatDateWithTime, manageCURDAccess, stringSorter} from "../../../utils/functions";
import ReactStrapDataTable from "../../CustomDatatable/ReactStrapDataTable";
import {ViewComplaintModel} from "../Views";
import DebouncedSaveButton from '../../../components/Buttons/DebounceSaveButton';
import Swal from "sweetalert2";
import { showConfirmDialog, showSuccessDialog, showWarningDialog } from "../../../utils/dialogs";


const Complaints = ({access}) => {
    const accessRights = manageCURDAccess(access);
    const dispatch = useDispatch();
    const complaints = useSelector((state) => state.complaint.list);
    const userComplaints = useSelector((state) => state.complaint.userComplaint);
    const getSingleComplaint = useSelector((state) => state.complaint.single);

    const [modalOpen, setModalOpen] = useState(false);
    const [currentHazard, setCurrentHazard] = useState(null);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [hazardToDelete, setHazardToDelete] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [actionModalOpen, setActionModalOpen] = useState(false);
    const [selectedHazard, setSelectedHazard] = useState(null);
    const [actionData, setActionData] = useState({})

    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [hazardToGenerate, setHazardToGenerate] = useState(null);

    //View Hazard
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedViewHazard, setSelectedViewHazard] = useState(null);

    //SaveButton
    const [isProcessing, setIsProcessing] = useState(false);

     //SharedAccessFilter
     const [accessFilter, setAccessFilter] = useState('My');

     const handleAccessFilterChange = (selectedAccess) => {
         setAccessFilter(selectedAccess);
     };



    const toggleActionModal = () => setActionModalOpen(!actionModalOpen);

    const handleActionClick = (hazard) => {
        //console.log(hazard)
        setSelectedHazard(hazard);
        setActionModalOpen(true);
    };

    const viewHazard = (hazard) => {
        setSelectedViewHazard(hazard);
        setViewModalOpen(true);
    }

    const handleInitiate = (actionData) => {
        setActionData(actionData)
    };
    const handleInitiateAction = async (actionData) => {
        delete actionData.submitDate
        if (actionData.message) {
            actionData.reply = actionData.message;
            delete actionData.message;
        }

        const payload = {
            complaintId: selectedHazard.id,
            id: selectedHazard.actionId,
            actions: JSON.stringify(actionData)
        }

        const response = await dispatch(submitActionForComplaint(payload))
        if (response.success) {
            setActionData([]);
            setActionModalOpen(false);
            setModalOpen(false)
            dispatch(getUserComplaints(access.moduleId))
        } else {
            message.error(response.message);
        }
    };

    const [formData, setFormData] = useState({
        responsibleManager: '',
        closeoutManager: '',
        site: '',
        l3Contractor: '',
        locationPlace: '',
        identifiedBy:'user',
        detectedAt: new Date().toISOString().split('T')[0],
        issuedAt: new Date().toISOString().split('T')[0],
        furtherActionsRequired: 'No',
        actions: [],
        attachments: [],
        sourceId:null,
        receivedBy:null,
        stId:null,
    });

    const resetForm = () => {
        setFormData({
            responsibleManager: '',
            closeoutManager: '',
            site: '',
            l3Contractor: '',
            locationPlace: '',
            furtherActionsRequired: 'No',
            actions: [],
            detectedAt: new Date().toISOString().split('T')[0],
            issuedAt: new Date().toISOString().split('T')[0],
            attachments: [],
            sourceId:null,
            receivedBy:null,
            identifiedBy:'user',
            stId:null,
        });
    };

    //Validation
    const validateForm = () => {
        if (!formData.responsibleManager) {
            message.error("Responsible Manager is required.");
            return false;
        }
        if (!formData.closeoutManager) {
            message.error("Closeout Manager is required.");
            return false;
        }
        if (!formData.site) {
            message.error("Site is required.");
            return false;
        }
        if (!formData.l3Contractor) {
            message.error("L3 Contractor is required.");
            return false;
        }
        // console.log()
        // if (!formData.detectedOn) {
        //     console.log(formData.detectedOn)
        //     message.error("Detected On date is required.");
        //     return false;
        // }
        // if (formData.identifiedBy.length === 0 && !formData.identifiedByOther) {
        //     message.error("At least one Identified By option or Identified By Other is required.");
        //     return false;
        // }


        // if(formData.identifiedBy==='user'){
        //     message.error("Please Select Identified By User");
        //     return false
        // }


        if (formData.furtherActionsRequired === 'Yes' && formData.actions.length === 0) {
            message.error("At least one action is required when Further Actions Required is Yes.");
            return false;
        }
        if (formData.furtherActionsRequired === 'Yes') {
            for (let action of formData.actions) {
                if (!action.userId || !action.description || !action.targetDays) {
                    message.error("All fields in Contributing Factors and Actions are required.");
                    return false;
                }
            }
        }
        return true;
    };


    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSelectChange = (name, value) => {
        setFormData({...formData, [name]: value});
    };

    const addHazard = async (hazardData) => {
        try {
            
            const formdata = transformFormDataForSubmission(hazardData)
        
            setIsProcessing(true)

            if (formData.id) {
                const result = await dispatch(updateComplaintForm(formdata))
                if (result.success) {
                    showSuccessDialog(result.message)
                    resetForm()
                    handleCloseModal()
                    dispatch(getUserComplaints(access.moduleId))

                } else {
                    showWarningDialog(result.message)
                }
            } else {
                const result = await dispatch(addComplaintForm(formdata))
                if (result.success) {
                    showSuccessDialog("Complaint Added Successfully")
                    resetForm()
                    handleCloseModal()
                    dispatch(getUserComplaints(access.moduleId))
                } else {
                    showWarningDialog(result.message)
                }
            }

        } catch (error) {
            // console.log(error)
            showWarningDialog("An error occurred while adding the hazard. Please try again.")
        }
        finally{
            setIsProcessing(false)
        }
    };

    const transformFormDataForSubmission = (formData) => {

        console.log(formData)
        const formDataObj = new FormData();
        formDataObj.append('moduleId',parseInt(access.moduleId))

        if (formData.id) {
            formDataObj.append('id', parseInt(formData.id));
            formDataObj.append('actionId', parseInt(formData.actionId));
            formDataObj.append('fileId', parseInt(formData.fileId));
            formDataObj.append('costId', parseInt(formData.costId));
        }

        if (formData.responsibleManager?.value) formDataObj.append('rmId', parseInt(formData.responsibleManager.value));
        if (formData.closeoutManager?.value) formDataObj.append('cmId', parseInt(formData.closeoutManager.value));
        if (formData.site?.value) formDataObj.append('siteId', parseInt(formData.site.value));
        if (formData.l3Contractor?.value) formDataObj.append('l3Id', parseInt(formData.l3Contractor.value));
        if (formData.locationPlace) formDataObj.append('location', formData.locationPlace);
        if (formData.detectedAt) formDataObj.append('detectedAt', formData.detectedAt);
        if (formData.significance) formDataObj.append('significance', formData.significance);
        if (formData.receiveType) formDataObj.append('receiveType', formData.receiveType);
        if (formData.receivedBy?.value) formDataObj.append('receivedBy', parseInt(formData.receivedBy.value));
        if (formData.issuedAt) formDataObj.append('issuedAt', formData.issuedAt);
        if (formData.summary) formDataObj.append('summary', formData.summary);
        if (formData.clientName) formDataObj.append('clientName', formData.clientName);
        if (formData.complainantName) formDataObj.append('complainantName', formData.complainantName);
        if (formData.address1) formDataObj.append('address1', formData.address1);
        if (formData.address2) formDataObj.append('address2', formData.address2);
        if (formData.suburb) formDataObj.append('suburb', formData.suburb);
        if (formData.stId?.value) formDataObj.append('stId', parseInt(formData.stId.value));
        if (formData.postalCode) formDataObj.append('postalCode', formData.postalCode);
        if (formData.contact1) formDataObj.append('contact1', formData.contact1);
        if (formData.contact2) formDataObj.append('contact2', formData.contact2);
        if (formData.sourceId?.value) formDataObj.append('sourceId', parseInt(formData.sourceId.value));
        if(formData.identifiedBy!=='user' && formData.identifiedBy!==null)
        {
            formDataObj.append('identifiedBy', parseInt(formData.identifiedBy.value))
        }

        formDataObj.append('identifiedOther', formData.identifiedByOther || '');
        formDataObj.append('actionRequired', formData.furtherActionsRequired === 'Yes');
        formDataObj.append('actions', JSON.stringify(formData.actions.map(action => ({
            id: action.id ?? null,
            userId: parseInt(action.userId),
            description: action.description ?? null,
            createdAt: action.createdAt ?? null,
            createdBy: action.createdBy ?? null,
            updatedAt: action.updatedAt ?? null,
            updatedBy: action.updatedBy ?? null,
            submittedAt: action.submittedAt ?? null,
            targetAt: action.targetAt ?? null,
            reply: action.reply ?? null,
            tags: action.tags ?? [],
            comments: action.comments ?? null,
            targetDays: parseInt(action.targetDays, 10),
            type: action.type ?? "N",
            status: action.status ?? "PENDING",
        }))));

        // Handle file attachments
        formData.attachments.forEach((attachment, index) => {
            if (attachment.file instanceof File) {
                formDataObj.append(`attachments[${index}]`, attachment.file, attachment.filename);
            }
        });

        formDataObj.append('files', JSON.stringify(formData.attachments.map(attachment => ({
            id: attachment.id ?? null,
            fileName: attachment.filename,
            description: attachment.description ?? null,
            path: attachment.path ?? null,
            createdAt: attachment.createdAt ?? null,
            createdBy: attachment.createdBy ?? null,
            updateBy: attachment.updateBy ?? null,
            updatedAt: attachment.updatedAt ?? null,
        }))));


        if(formData.costImplications!==undefined)
        {
            formDataObj.append('costs', JSON.stringify(formData.costImplications.map(action => ({
                id: action.id ?? null,
                description: action.description ?? null,
                amount: parseFloat(action.amount),
                allocation: action.allocation ?? null,
                comment: action.comment ?? null,
                createdAt: action.createdAt ?? null,
                createdBy: action.createdBy ?? null,
                updatedAt: action.updatedAt ?? null,
                updatedBy: action.updatedBy ?? null,
            }))));
        }
        else{
            formDataObj.append('costs', JSON.stringify([]))
        }

        return formDataObj;
    };

    const handleSubmit = async (e) => {
        
        setErrorMessage("");

        if (!validateForm()) {
            throw new Error("Form validation failed");
        }
        

        const contributingFactorsAndActions = formData.furtherActionsRequired === 'Yes'
            ? formData.actions.map(action => ({
                // id: Date.now(), // This should be replaced with a proper ID generation method
                userId: action.userId,
                description: action.description,
                // createdAt: null,
                createdBy: null, // This should be set to the current user's ID
                updatedAt: null,
                updatedBy: null,
                submittedAt: null,
                targetDays: action.targetDays,
                targetAt: null,
                reply: null,
                tags: [],
                comments: []
            }))
            : null;

        const hazardPayload = {
            ...formData,
            contributingFactorsAndActions
        };

        await addHazard(hazardPayload);
    };

    const handleEdit = async (hazard) => {
        setCurrentHazard(hazard);
        await dispatch(getUserSingleComplaint(hazard.id));
        setModalOpen(true);
    };

    useEffect(() => {
        if (getSingleComplaint && currentHazard && complaints) {
            const formatDateForInput = (dateString) => {
                if (!dateString) return '';
                const date = new Date(dateString);
                return date.toISOString().slice(0, 16); // Format: YYYY-MM-DDTHH:mm
            };

            const hazardData = getSingleComplaint;
            // console.log(hazardData)
            // console.log(hazardData.sourceId)

            const complaintSourceOptions = complaints?.complaintSources?.map(source => ({
                value: source.id.toString(),
                label: source.name
            })) || [];

            const complaintSourceIdentifiers = complaints?.identifiers?.map(source => ({
                value: source.id.toString(),
                label: source.name
            })) || [];

            const receiveByOptions = complaints?.receivedUsers?.map(source => ({
                value: source.id.toString(),
                label: source.name
            })) || [];

            const stateOptions = complaints?.states?.map(source => ({
                value: source.id.toString(),
                label: source.name
            })) || [];

            const selectedSource = complaintSourceOptions.find(source => source.value === hazardData.sourceId?.toString()) || null;
            const selectedIdentifiedBy = complaintSourceIdentifiers.find(source => source.value === hazardData.identifiedBy?.toString()) || null;
            const selectedReceivedBy = receiveByOptions.find(source => source.value === hazardData.receivedBy?.toString()) || null;
            const selectedState = stateOptions.find(source => source.value === hazardData.stId?.toString()) || null;
            //const selectedSource = complaintSourceOptions.find(source => source.value === hazardData.sourceId?.toString()) || null;

            //console.log(selectedIdentifiedBy)


            const newFormData = {
                actionId: hazardData.actionId,
                fileId: hazardData.fileId,
                costId: hazardData.costId,
                id: hazardData.id,
                responsibleManager: {value: hazardData.rmId.toString(), label: hazardData.responsibleManager},
                closeoutManager: {value: hazardData.cmId.toString(), label: hazardData.closeoutManager},
                site: {value: hazardData.siteId.toString(), label: hazardData.site},
                l3Contractor: {value: hazardData.l3Id.toString(), label: hazardData.l3Contractor},
                locationPlace: hazardData.location || '',
                detectedAt: hazardData.detectedAt ? hazardData.detectedAt.split(' ')[0] : '',
                identifiedBy: selectedIdentifiedBy,
                identifiedByOther: hazardData.identifiedOther || '',
                significance: hazardData.significance || '',
                receiveType: hazardData.receiveType || '',
                receivedBy: selectedReceivedBy,
                issuedAt: hazardData.issuedAt ? hazardData.issuedAt.split(' ')[0] : '',
                summary: hazardData.summary || '',
                clientName: hazardData.clientName || '',
                complainantName: hazardData.complainantName || '',
                address1: hazardData.address1 || '',
                address2: hazardData.address2 || '',
                suburb: hazardData.suburb || '',
                stId: selectedState,
                postalCode: hazardData.postalCode || '',
                contact1: hazardData.contact1 || '',
                contact2: hazardData.contact2 || '',
                sourceId: selectedSource ?? null,
                furtherActionsRequired: hazardData.actionRequired ? 'Yes' : 'No',
                actions: (hazardData.actions || []).map(action => ({
                    id: action.id,
                    userId: action.userId,
                    description: action.description,
                    targetDays: action.targetDays,
                    createdAt: action.createdAt,
                    createdBy: action.createdBy,
                    updatedAt: action.updatedAt,
                    updatedBy: action.updatedBy,
                    submittedAt: action.submittedAt,
                    targetAt: action.targetAt,
                    reply: action.reply,
                    tags: action.tags,
                    comments: action.comments,
                    type: action.type,
                    status: action.status
                })),
                attachments: (hazardData.files || []).map(file => ({
                    id: file.id,
                    path: file.path,
                    filename: file.fileName,
                    description: file.description,
                    createdAt: file.createdAt,
                    createdBy: file.createdBy,
                    updatedAt: file.updatedAt,
                    updatedBy: file.updatedBy,
                    fullPath: file.fullPath,
                })),
                costImplications: (hazardData.costs || []).map(cost => ({
                    id: cost.id,
                    description: cost.description,
                    amount: cost.amount,
                    allocation: cost.allocation,
                    comment: cost.comment,
                    createdAt: cost.createdAt,
                    createdBy: cost.createdBy,
                    updatedAt: cost.updatedAt,
                    updatedBy: cost.updatedBy,
                }))
            };

            setFormData(newFormData);
        }
    }, [getSingleComplaint, currentHazard]);

    const handleCloseModal = () => {
        resetForm();
        setCurrentHazard(null);
        setModalOpen(false);
    };

    const handleDelete = async (record) => {
        try {
            const result = await showConfirmDialog("Are you sure you want to delete this Complaint?");
            if (result.isConfirmed) {
                const object = await dispatch(deleteComplaint(record.id))
                if (object.success) {
                    Swal.fire('Deleted!', object.message, 'success');
                    dispatch(getComplaints());
                }
                else {
                    Swal.fire('Error!', object.message, 'error');
                }
            }
        } catch (error) {
            console.error('Error in handleDelete:', error);
        }
    };


    const confirmDelete = async () => {
        try {
            dispatch(deleteComplaint(hazardToDelete.id))
            setDeleteModalVisible(false);
            dispatch(getComplaints());
        } catch (error) {
            alert('An error occurred while deleting the hazard');
        }
    };

    const handleGenerate = async (hazard) => {
        setHazardToGenerate(hazard);
        setConfirmModalOpen(true);
    };

    const confirmGenerate = async () => {
        if (hazardToGenerate) {
            const object={
                moduleId:access.moduleId,
                id:hazardToGenerate.id
            }
            const response = await dispatch(submitComplaint(object));
            if (response && response.data.code === 1) {
                showSuccessDialog("Sequnce No generated successfully")
            } else {
                showWarningDialog("Failed to generate Sequence")
            }
            setConfirmModalOpen(false);
            setHazardToGenerate(null);
            // You might want to refresh the hazard list here
            //dispatch(getUserHazards());
        }
    };

    let columns = [
        {
            title: "Action",
            key: "action",
            width: '120px',
            render: (text, hazard) => (
                <div style={{display: 'flex', gap: '8px'}}>
                    {hazard.submittedAt === null && (
                        <Button color="primary" size="sm" onClick={() => handleEdit(hazard)} disabled={!accessRights.U}>
                            <EditOutlined/>
                        </Button>
                    )}
                    {hazard.submittedAt === null && (<>
                        <Button color="danger" size="sm" onClick={() => handleDelete(hazard)} disabled={!accessRights.D}>
                            <DeleteOutlined />
                        </Button>
                    </>)}
                    <Button color="info" size="sm" onClick={() => viewHazard(hazard)} disabled={!accessRights.R}>
                        <EyeFilled/>
                    </Button>
                    {hazard.submittedAt !== null && (
                        <Button color="info" size="sm" onClick={() => handleActionClick(hazard)}>
                            <PlusCircleFilled/>
                        </Button>
                    )}

                </div>
            ),
        },
        {
            title: "Sequence Number",
            dataIndex: "submittedAt",
            key: "submittedAt",
            sorter: stringSorter("submittedAt"),
            sortDirections: ["ascend", "descend"],
            render: (text, hazard) => {
                if (hazard.submittedAt !== null) {

                    return hazard.sequenceNo; // Assuming the sequence string is stored in the 'sequence' property
                } else {
                    return (
                        <Button color="primary" size="sm" onClick={() => handleGenerate(hazard)}>
                            Generate
                        </Button>
                    );
                }
            }
        },
        {
            title: "Detected On",
            dataIndex: "detectedAt",
            key: "detectedAt",
            sorter: dateSorter("detectedAt"),
            sortDirections: ["ascend", "descend"],
            render: (text, hazard) => (
                <>{formatDateWithTime(text, false)}</>
            )  
        },
        {
            title: "Entered By",
            dataIndex: "createdByUser",
            key: "createdByUser",
            sorter: stringSorter("createdByUser"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "L3 Contractor",
            dataIndex: "l3Contractor",
            key: "l3Contractor",
            sorter: stringSorter("l3Contractor"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
            sorter: stringSorter("country"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Business Unit",
            dataIndex: "businessUnit",
            key: "businessUnit",
            sorter: stringSorter("businessUnit"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Project/Program",
            dataIndex: "project",
            key: "project",
            sorter: stringSorter("project"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Site",
            dataIndex: "site",
            key: "site",
            sorter: stringSorter("site"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Location Place",
            dataIndex: "location",
            key: "location",
            sorter: stringSorter("location"),
            sortDirections: ["ascend", "descend"],
        },
    ];

    // columns = enhanceColumnsWithFilters(columns)

    useEffect(() => {
        dispatch(getComplaints());
        dispatch(getUserComplaints(access.moduleId))
    }, [dispatch]);

    useEffect(() => {
        // console.log("USER COMPLAINTS")
        // console.log(userComplaints)
    }, [userComplaints]);

    // useEffect(() => {
    //     console.log("COMPLAINTS SET")
    //     console.log(complaints)
    // }, [complaints]);

    const handleAttachmentChange = (newAttachments) => {
        setFormData({...formData, attachments: newAttachments});
    };

    const filteredComplaints = useMemo(() => {
        switch (accessFilter) {
            case 'My':
                return [
                    ...(userComplaints.user || []),
                ];
            case 'Shared':
                return userComplaints.shared || [];
            case 'Assigned':
                return userComplaints.assigned || [];
            default:
                return [];
        }
    }, [userComplaints, accessFilter]);


    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="align-items-center mb-3">
                        <Col md={3}>
                            <h5 className="mb-0">Manage Complaints</h5>
                        </Col>
                        <Col md={9} className="d-flex justify-content-end align-items-center">
                            <AccessFilter
                                accessFilter={accessFilter}
                                handleAccessFilterChange={handleAccessFilterChange}
                                sharedAccess={userComplaints?.sharedAccess || []}
                            />
                            <Button color="primary" onClick={() => setModalOpen(true)} className="ms-2" disabled={!accessRights.C}>
                                <PlusCircleFilled size={18} /> New
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactStrapDataTable
                        dataSource={filteredComplaints ?? []} 
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} size="xl" backdrop="static">
                <Form onSubmit={handleSubmit}>
                    <ModalHeader toggle={handleCloseModal}>
                        {currentHazard ? 'Edit Complaint' : 'Add Complaint'}
                    </ModalHeader>
                    <ModalBody>
                        <GeneralSection
                            formData={formData}
                            handleSelectChange={handleSelectChange}
                            hazardData={complaints}
                            showSupervisor={false}
                        />
                        <AdminSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            hazardData={complaints}
                        />
                        <ComplaintDetailsSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            complaints={complaints}
                        />
                        <ContributingFactorsSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            actionUsers={complaints.actionUsers}
                            showIsImprovement={false}
                        />
                        {/*<CostImplicationTableSection*/}
                        {/*    formData={formData}*/}
                        {/*    handleInputChange={handleInputChange}*/}
                        {/*    handleSelectChange={handleSelectChange}*/}
                        {/*/>*/}
                        <AttachmentsSection
                            attachments={formData.attachments}
                            onAttachmentsChange={handleAttachmentChange}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleCloseModal}>Back</Button>
                        <DebouncedSaveButton 
                            onSave={handleSubmit} 
                            isProcessing={isProcessing}
                        />
                    </ModalFooter>
                </Form>
            </Modal>

            <ViewComplaintModel
                isOpen={viewModalOpen}
                toggle={() => setViewModalOpen(!viewModalOpen)}
                hazard={selectedViewHazard}
            />

            <Modal isOpen={deleteModalVisible} toggle={() => setDeleteModalVisible(false)}>
                <ModalHeader toggle={() => setDeleteModalVisible(false)}>Confirm Delete</ModalHeader>
                <ModalBody>
                    <p>Are you sure you want to delete this complaint?</p>
                    
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setDeleteModalVisible(false)}>Cancel</Button>
                    <Button color="danger" onClick={confirmDelete}>Delete</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={confirmModalOpen} toggle={() => setConfirmModalOpen(false)}>
                <ModalHeader toggle={() => setConfirmModalOpen(false)}>Confirm Submission</ModalHeader>
                <ModalBody>
                    <p>Are you sure you want to submit this complaint?</p>
                    <p style={{color: 'red'}}>
                        Note: Once the Complaint is submitted it cannot be edited.
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setConfirmModalOpen(false)}>Cancel</Button>
                    <Button color="primary" onClick={confirmGenerate}>Yes, Submit</Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={actionModalOpen}
                toggle={toggleActionModal}
                size="xl"
            >
                <ModalHeader toggle={toggleActionModal}>
                    Complaint Number: {selectedHazard?.sequenceNo}
                </ModalHeader>
                <ModalBody>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Status</th>
                            <th>Action Type</th>
                            <th>Submit Date</th>
                            <th>From</th>
                            <th>Message Description</th>
                            <th>To</th>
                            <th>Response Message</th>
                            <th>Response On</th>
                            <th>Response By</th>
                        </tr>
                        </thead>
                        <tbody>
                        {selectedHazard?.actions?.map((action, index) => (
                            <tr key={index}>
                                <td>
                                    <Button color="primary" size="sm" onClick={() => handleInitiate(action)}>
                                        Initiate
                                    </Button>
                                </td>
                                <td>{action.actionType}</td>
                                <td>{action.submittedAt}</td>
                                <td>{action.createdByUser}</td>
                                <td>{action.description}</td>
                                <td>{action.createdFor}</td>
                                <td>{action.reply}</td>
                                <td>{action.responseAt ?? "-"}</td>
                                <td>{action.responseByUser ?? "-"}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleActionModal}>Close</Button>
                </ModalFooter>
            </Modal>

            <ActionModal
                isOpen={actionModalOpen}
                toggle={toggleActionModal}
                selectedHazard={selectedHazard}
                message={"Action For Complaints"}
                onInitiateAction={handleInitiateAction}
            />
        </div>
    );
}

export default Complaints;