import {
    GENERATE_INCR,
    INCR_VALUES,
    GET_USER_INCR,
    GET_SINGLE_INCR,
    ADD_INCR,
    UPDATE_INCR,
    DELETE_INCR
} from "../../../../types";

const initialState = { list: [], single: null, userINCR: {}, values: [] };

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GENERATE_INCR:
            return {
                ...state,
                userINCR: payload
            };
        case INCR_VALUES:
            return { ...state, values: payload };
        case GET_USER_INCR:
            return { ...state, list: payload };
        case GET_SINGLE_INCR:
            return { ...state, single: payload };
        case ADD_INCR:
            return { ...state, userINCR: payload };
        case UPDATE_INCR:
            return {
                ...state,
                userINCR: payload
            };
        case DELETE_INCR:
            return {
                ...state,
                userINCR: state.userINCR.user.filter(object => object.id !== payload)
            };
        default:
            return state;
    }
};

export default reducer;