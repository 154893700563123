import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  DeleteOutlined,
  EditOutlined,
  EyeFilled,
  PlusCircleFilled,
} from "@ant-design/icons";
import {
  AccessFilter,
  dateSorter,
  formatDateWithTime, manageCURDAccess,
  stringSorter,
} from "../../../../utils/functions";
import React, { useEffect, useRef, useState,useMemo } from "react";
import ReactStrapDataTable from "../../../CustomDatatable/ReactStrapDataTable";
import {
  AdminSection,
  GoSeeProgramSection,
  AttachmentsSection,
  ActionSection,
  ActionModal,
} from "../../Common/Forms";
import { useDispatch, useSelector } from "react-redux";
import {
  addGSPForm,
  getGoSeeProgramValues,
  getUserGSP,
  getSingleGSP,
  updateGSPForm,
  submitGoSeeProgram,
  submitActionForGSP,
  deleteGoSeeProgram,
} from "../../../../store/actions/Inform/Audits/goseeprogram";
import { message } from "antd";
import { ViewGoSeeProgramSection } from "../../Views";
import DebouncedSaveButton from "../../../../components/Buttons/DebounceSaveButton";
import { showConfirmDialog, showSuccessDialog, showWarningDialog } from "../../../../utils/dialogs";
import Swal from "sweetalert2";

const GoSeeProgram = ({ access }) => {
  const accessRights = manageCURDAccess(access);

  const dispatch = useDispatch();
  const GSPValues = useSelector((state) => state.goseeprogram.values);
  const userGSP = useSelector((state) => state.goseeprogram.userGSP);
  const singleGSP = useSelector((state) => state.goseeprogram.single);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentGSP, setCurrentGSP] = useState(null);
  const [signatures, setSignatures] = useState([]);
  const signatureRefs = useRef({});
  const [gspToDelete, setGSPToDelete] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);


  //ViewRecord
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [goSeeProgramToGenerate, setGoSeeProgramToGenerate] = useState(null);

  //ActionModal
  const [actionModalOpen, setActionModalOpen] = useState(false);
  const toggleActionModal = () => setActionModalOpen(!actionModalOpen);
  const [actionData, setActionData] = useState({});

  //SaveButton
  const [isProcessing, setIsProcessing] = useState(false);


  const [accessFilter, setAccessFilter] = useState('My');

  const handleAccessFilterChange = (selectedAccess) => {
      setAccessFilter(selectedAccess);
  };


  const handleCloseModal = () => {
    resetForm();
    setModalOpen(false);
    setCurrentGSP(null);
    setSignatures([]);
  };
  const [formData, setFormData] = useState({
    site: null,
    l3Contractor: null,
    locationPlace: "",
    reportedAt: new Date().toISOString().split("T")[0],
    dateOfGoSeeIntervention: null,
    reportedByUser: null,
    interventionType: null,
    otherIntervention: null,
    whatThree1: "",
    whatThree2: "",
    whatThree3: "",
    mainDescription: "",
    description: "",
    actions: [],
    attachments: [],
    intervetionPhoto: null,
  });

  //EYEBUTTON MODAL
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedViewRecord, setSelectedRecord] = useState(null);

  const viewRecord = (record) => {
    setSelectedRecord(record);
    setViewModalOpen(true);
  };

  const resetForm = () => {
    setFormData({
      site: "",
      l3Contractor: "",
      locationPlace: "",
      reportedDate: null,
      reportedByUser: null,
      interventionType: null,
      whatThree1: "",
      whatThree2: "",
      whatThree3: "",
      mainDescription: "",
      fileDescription: "",
      actions: [],
      attachments: [],
    });
  };
  const validateForm = () => {
    if (!formData.site) {
      message.error("Site Required *");
      return false;
    }
    if (!formData.l3Contractor) {
      message.error("L3 Contractor is required *");
      return false;
    }
    if (!formData.reportedAt) {
      message.error("Reported At is Required *");
      return false;
    }
    if (!formData.reportedByUser) {
      message.error("Reported By User required *");
      return false;
    }
    if (!formData.interventionType) {
      message.error("Intervention Type Required *");
      return false;
    }
    if (!formData.dateOfGoSeeIntervention) {
      message.error("Please Select Date of Go See Intervention *");
      return false;
    }

    return true;
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async () => {

    if (!validateForm()) {
      throw new Error("Form validation failed");
    }


    const formDataObj = new FormData();
    formDataObj.append("moduleId",parseInt(access.moduleId))

    if (formData.id) {
      formDataObj.append("id", parseInt(formData.id.toString()));
      formDataObj.append("actionId", parseInt(formData.actionId));
      formDataObj.append("fileId", parseInt(formData.fileId));
    }

    if (formData.site?.value)
      formDataObj.append("siteId", parseInt(formData.site.value));
    if (formData.l3Contractor?.value)
      formDataObj.append("l3Id", parseInt(formData.l3Contractor.value));
    formDataObj.append("location", formData.locationPlace || "");
    formDataObj.append("reportedAt", formData.reportedAt || "");
    if (formData.reportedByUser)
      formDataObj.append("reportedBy", parseInt(formData.reportedByUser.value));
    if (formData.interventionType?.value)
      formDataObj.append("interventionType", formData.interventionType.value);
    formDataObj.append(
      "interventionTypeOther",
      formData.otherIntervention || "",
    );
    formDataObj.append("made1", formData.whatThree1 || "");
    formDataObj.append("made2", formData.whatThree2 || "");
    formDataObj.append("made3", formData.whatThree3 || "");
    formDataObj.append("gsiAt", formData.dateOfGoSeeIntervention || "");

    // New fields as per your request
    formDataObj.append("icMainDescription", formData.mainDescription || "");
    formDataObj.append(
      "icFileName",
      formData.interventionFile ? formData.interventionFile.name : "",
    );
    formDataObj.append("icDescription", formData.description || "");

    if (formData.interventionFile) {
      formDataObj.append("interventionFile", formData.interventionFile);
    }
    const signatureData = signatures.map(sig => ({
      id: sig.id,
      submittedSign: sig.submittedSign, // This should already be a base64 string
      reviewedSign: sig.reviewedSign, // This should already be a base64 string
      oldSubmittedSign: sig.oldSubmittedSign || "",
      oldReviewedSign: sig.oldReviewedSign || "",
      createdAt: sig.createdAt,
      createdBy: sig.createdBy,
      updatedAt: sig.updatedAt || new Date().toISOString(),
      updatedBy: sig.updatedBy,
      submittedAt: sig.submittedAt
    }));

    formDataObj.append("signs", JSON.stringify(signatureData));

    if (Array.isArray(formData.actions)) {
      formDataObj.append(
        "actions",
        JSON.stringify(
          formData.actions.map((action) => ({
            id: action.id || null,
            userId: action.userId ? parseInt(action.userId) : null,
            description: action.description || null,
            createdAt: action.createdAt || null,
            createdBy: action.createdBy || null,
            updatedAt: action.updatedAt || null,
            updatedBy: action.updatedBy || null,
            submittedAt: action.submittedAt || null,
            targetAt: action.targetAt || null,
            reply: action.reply || null,
            tags: action.tags || [],
            type: action.type || "N",
            status: action.status || "PENDING",
          })),
        ),
      );
    }

    if (Array.isArray(formData.attachments)) {
      formDataObj.append(
        "files",
        JSON.stringify(
          formData.attachments.map((attachment) => ({
            id: attachment.id || null,
            fileName: attachment.filename || "",
            description: attachment.description || null,
            path: attachment.path || null,
            createdAt: attachment.createdAt || null,
            createdBy: attachment.createdBy || null,
            updateBy: attachment.updateBy || null,
            updatedAt: attachment.updatedAt || null,
          })),
        ),
      );

      formData.attachments.forEach((attachment, index) => {
        if (attachment.file instanceof File) {
          formDataObj.append(
            `attachments[${index}]`,
            attachment.file,
            attachment.filename,
          );
        }
      });
    }

    let result;
    try {
      setIsProcessing(false)
      if (formData.id) {
        result = await dispatch(updateGSPForm(formDataObj));
      } else {
        result = await dispatch(addGSPForm(formDataObj));
      }
      if (result.success) {
        if(formData.id) {
          showSuccessDialog("Go See Program Updated Successfully")
        }
        else {
          showSuccessDialog("Go See Program Added Successfully")
        }

        //message.success(result.message);
        resetForm();
        setSignatures([]);
        setModalOpen(false);
      }
      else {
        showWarningDialog(result.message)
      }
    }
    catch (er) {
      // console.log(er)
    }
    finally {
      setIsProcessing(false)
    }
  };

  useEffect(() => {
    if (singleGSP && GSPValues) {
      setCurrentGSP(singleGSP);

      const sitesOptions =
        GSPValues?.sites?.map((site) => ({
          value: site.id.toString(),
          label: site.name,
        })) || [];

      const l3 =
        GSPValues?.l3?.map((site) => ({
          value: site.id.toString(),
          label: site.name,
        })) || [];

      const reporters = GSPValues?.reporters?.map((user) => ({
        value: user.id.toString(),
        label: user.name,
      }));

      const interventionTypes = GSPValues?.interventionTypes?.map((type) => ({
        value: type.toString(),
        label: type.toString(),
      }));

      const selectedSite =
        sitesOptions.find(
          (source) => source.value === singleGSP.siteId?.toString(),
        ) || null;
      const selectedL3 =
        l3.find((source) => source.value === singleGSP.l3Id?.toString()) ||
        null;
      const selectedReporter =
        reporters.find(
          (source) => source.value === singleGSP.reportedBy?.toString(),
        ) || null;
      const selectedInterventionType =
        interventionTypes.find(
          (source) => source.value === singleGSP.interventionType?.toString(),
        ) || null;

      const editFormData = {
        id: parseInt(singleGSP.id),
        actionId: parseInt(singleGSP.actionId),
        fileId: parseInt(singleGSP.fileId),
        site: selectedSite,
        l3Contractor: selectedL3,
        locationPlace: singleGSP.location,
        reportedAt: formatDateWithTime(singleGSP.reportedAt, false),
        dateOfGoSeeIntervention: formatDateWithTime(singleGSP.gsiAt, false),
        reportedByUser: selectedReporter,
        interventionType: selectedInterventionType,
        otherIntervention: null,
        whatThree1: singleGSP.made1,
        whatThree2: singleGSP.made2,
        whatThree3: singleGSP.made3,
        mainDescription: singleGSP.icMainDescription,
        description: singleGSP.icDescription,
        interventionPhoto: singleGSP.icFullPath,
        actions: (singleGSP.actions || []).map((action) => ({
          id: action.id,
          userId: action.userId,
          description: action.description,
          targetDays: action.targetDays,
          createdAt: action.createdAt,
          createdBy: action.createdBy,
          updatedAt: action.updatedAt,
          updatedBy: action.updatedBy,
          submittedAt: action.submittedAt,
          targetAt: action.targetAt,
          reply: action.reply,
          tags: action.tags,
          comments: action.comments,
          type: action.type,
          status: action.status,
        })),
        attachments: (singleGSP.files || []).map((file) => ({
          id: file.id,
          path: file.path,
          filename: file.fileName,
          description: file.description,
          createdAt: file.createdAt,
          createdBy: file.createdBy,
          updatedAt: file.updatedAt,
          updatedBy: file.updatedBy,
          fullPath: file.fullPath,
        })),
        signatures: (singleGSP.signs || []).map((sign, index) => ({
          id: sign.id ?? null,
          oldReviewedSign: sign.reviewedSign,
          oldSubmittedSign: sign.submittedSign,
          submittedSign: sign.submittedFullPath,
          reviewedSign: sign.reviewedFullPath,
          createdAt: sign.createdAt,
          createdBy: sign.createdBy,
          updatedAt: sign.updatedAt ?? null,
          updatedBy: sign.updatedBy ?? null,
          submittedAt: sign.submittedAt,
          isNew: false,
        })),
      };

      setFormData(editFormData);
      setSignatures(editFormData.signatures);
    }
  }, [singleGSP]);

  const handleSelectChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };
  const handleAttachmentChange = (newAttachments) => {
    setFormData({ ...formData, attachments: newAttachments });
  };

  useEffect(() => {
    dispatch(getGoSeeProgramValues());
    dispatch(getUserGSP(access.moduleId));
  }, [dispatch]);

  const handleEdit = async (data) => {
    await dispatch(getSingleGSP(data.id));
    setModalOpen(true);
  };

  // const handleDelete = (object) => {
  //   setGSPToDelete(object);
  //   setDeleteModalVisible(true);
  // };

  const handleDelete = async (gsp) => {
    try {
      const result = await showConfirmDialog("Are you sure you want to delete this Go See Program?");
      if (result.isConfirmed) {
        const object = await dispatch(deleteGoSeeProgram(gsp.id))
        if (object.success) {
          Swal.fire('Deleted!', object.message, 'success');
          dispatch(getUserGSP(access.moduleId));
        }
        else {
          Swal.fire('Error!', object.message, 'error');
        }
      }
    } catch (error) {
      console.error('Error in handleDelete:', error);
    }
  };

  const confirmDelete = async () => {
    try {
      const object = await dispatch(deleteGoSeeProgram(gspToDelete.id))
      if (object.result) {
        message.success(object.message)
        dispatch(getUserGSP(access.moduleId))
      }
      setDeleteModalVisible(false);
      setGSPToDelete(null)
    } catch (error) {
      alert('An error occurred while deleting the hazard');
    }
  };

  let columns = [
    {
      title: "Action",
      key: "action",
      width: "120px",
      render: (text, data) => (
        <div style={{ display: "flex", gap: "8px" }}>
          {data.submittedAt === null && (
            <Button color="primary" size="sm" onClick={() => handleEdit(data)} disabled={!accessRights.U}>
              <EditOutlined />
            </Button>
          )}
          {data.submittedAt === null && (<>
            <Button color="danger" size="sm" onClick={() => handleDelete(data)} disabled={!accessRights.D}>
              <DeleteOutlined />
            </Button>
          </>)}
          <Button color="info" size="sm" onClick={() => viewRecord(data)} disabled={!accessRights.U}>
            <EyeFilled />
          </Button>
          {data.submittedAt !== null && (
            <Button
              color="info"
              size="sm"
              onClick={() => handleActionClick(data)}
            >
              <PlusCircleFilled />
            </Button>
          )}
        </div>
      ),
    },
    {
      title: "Sequence Number",
      dataIndex: "submittedAt",
      key: "submittedAt",
      sorter: stringSorter("submittedAt"),
      sortDirections: ["ascend", "descend"],
      render: (text, data) => {
        if (data.submittedAt !== null) {
          return data.sequenceNo; // Assuming the sequence string is stored in the 'sequence' property
        } else {
          return (
            <Button
              color="primary"
              size="sm"
              onClick={() => handleGenerate(data)}
            >
              Generate
            </Button>
          );
        }
      },
    },
    {
      title: "Reported At",
      dataIndex: "reportedAt",
      key: "reportedAt",
      sorter: dateSorter("reportedAt"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "L3 Contractor",
      dataIndex: "l3Contractor",
      key: "l3Contractor",
      sorter: stringSorter("l3Contractor"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      sorter: stringSorter("country"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Business Unit",
      dataIndex: "businessUnit",
      key: "businessUnit",
      sorter: stringSorter("businessUnit"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Project/Program",
      dataIndex: "project",
      key: "project",
      sorter: stringSorter("project"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Site",
      dataIndex: "site",
      key: "site",
      sorter: stringSorter("site"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Location Place",
      dataIndex: "location",
      key: "location",
      sorter: stringSorter("location"),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: stringSorter("description"),
      ellipsis: true,
    },
  ];

  const handleGenerate = async (data) => {
    setGoSeeProgramToGenerate(data);
    setConfirmModalOpen(true);
  };

  const confirmGoSeeProgram = async () => {
    if (goSeeProgramToGenerate) {
      const object={
        moduleId:access.moduleId,
        id:goSeeProgramToGenerate.id
    }
      const response = await dispatch(
        submitGoSeeProgram(object),
      );
      if (response && response.data.code === 1) {
        showSuccessDialog("Go See Program generated successfully")
      } else {
        showWarningDialog("Failed to generate Go See Program")
      }
      setConfirmModalOpen(false);
      setGoSeeProgramToGenerate(null);
    }
  };

  const handleActionClick = (data) => {
    setActionData(data);
    setActionModalOpen(true);
  };

  const handleInitiateAction = async (data) => {
    delete data.submitDate;
    if (data.message) {
      data.reply = actionData.message;
      delete data.message;
    }

    const payload = {
      informGspId: parseInt(actionData.id),
      id: parseInt(actionData.actionId),
      actions: JSON.stringify(data),
    };
    const response = await dispatch(submitActionForGSP(payload));
    if (response.success) {
      showSuccessDialog(response.message)
      setActionData([]);
      setActionModalOpen(false);
      setModalOpen(false);
      dispatch(getUserGSP(access.moduleId));
    } else {
      showWarningDialog(response.message)
    }
  };


    const filteredGSP = useMemo(() => {
      switch (accessFilter) {
          case 'My':
              return [
                  ...(userGSP.user || [])
              ];
          case 'Shared':
              return userGSP.shared || [];
          case 'Assigned':
              return userGSP.assigned || [];
          default:
              return [];
      }
    }, [userGSP, accessFilter]);


    useEffect(() => {
      console.log("USER GSP")
      console.log(userGSP)
    }, [userGSP]);




  return (
    <Card>
      <CardHeader>
            <Row className="align-items-center mb-3">
                    <Col md={3}>
                        <h5 className="mb-0">Manage Go See Program</h5>
                    </Col>
                    <Col md={9} className="d-flex justify-content-end align-items-center">
                        <AccessFilter
                            accessFilter={accessFilter}
                            handleAccessFilterChange={handleAccessFilterChange}
                            sharedAccess={userGSP?.sharedAccess || []}
                        />
                        <Button color="primary" onClick={() => setModalOpen(true)} className="ms-2" disabled={!accessRights.C}>
                            <PlusCircleFilled size={18} /> New
                        </Button>
                    </Col>
            </Row>
      </CardHeader>
      <CardBody>
        <ReactStrapDataTable
          dataSource={filteredGSP ?? []}
          columns={columns}
          rowKey="id"
        />
      </CardBody>
      <Modal isOpen={modalOpen} toggle={handleCloseModal} size="xl" backdrop="static">
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={handleCloseModal}>
            {currentGSP ? "Edit Go See Program" : "Add Go See Program"}
          </ModalHeader>
          <ModalBody>
            <AdminSection
              formData={formData}
              handleInputChange={handleInputChange}
              handleSelectChange={handleSelectChange}
              data={GSPValues}
            />
            <GoSeeProgramSection
              formData={formData}
              handleInputChange={handleInputChange}
              handleSelectChange={handleSelectChange}
              data={GSPValues}
              signatures={signatures}
              setSignatures={setSignatures}
              signatureRefs={signatureRefs}
              setFormData={setFormData}
            />
            <ActionSection
              formData={formData}
              handleInputChange={handleInputChange}
              handleSelectChange={handleSelectChange}
              actionUsers={GSPValues?.actionUsers ?? []}
            />
            <AttachmentsSection
              attachments={formData.attachments}
              onAttachmentsChange={handleAttachmentChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={handleCloseModal}>
              Back
            </Button>
            <DebouncedSaveButton
              onSave={handleSubmit}
              isProcessing={isProcessing}
            />
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={confirmModalOpen}
        toggle={() => setConfirmModalOpen(false)}
      >
        <ModalHeader toggle={() => setConfirmModalOpen(false)}>
          Confirm Submission
        </ModalHeader>
        <ModalBody>
          <p>Are you sure you want to submit this go see program?</p>
          <p style={{ color: "red" }}>
            Note: Once the Go See Program is submitted it cannot be edited.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setConfirmModalOpen(false)}>
            Cancel
          </Button>
          <Button color="primary" onClick={confirmGoSeeProgram}>
            Yes, Submit
          </Button>
        </ModalFooter>
      </Modal>

      <ActionModal
        isOpen={actionModalOpen}
        toggle={toggleActionModal}
        data={actionData}
        message={"Action For Go See Program"}
        onInitiateAction={handleInitiateAction}
      />
      <ViewGoSeeProgramSection
        isOpen={viewModalOpen}
        toggle={() => setViewModalOpen(!viewModalOpen)}
        data={selectedViewRecord}
      />
    </Card>
  );
};

export default GoSeeProgram;
