import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import {DeleteOutlined, EditOutlined, EyeFilled, PlusCircleFilled} from "@ant-design/icons";
import {
    AccessFilter,
    dateSorter,
    formatDateWithTime,
    manageCURDAccess,
    stringSorter
} from "../../../../utils/functions";
import React, {useEffect, useState,useMemo} from "react";
import ReactStrapDataTable from "../../../CustomDatatable/ReactStrapDataTable";
import {
    AdminSection,
    AttachmentsSection,
    ActionSection,
    ActionModal,
    L2IndiaPMWeeklyReport
} from "../../Common/Forms"
import {useDispatch, useSelector} from "react-redux";
import {message} from "antd";
import {
    addL2PMForm,
    getSingleL2PM,
    getL2PMValues, getUserL2PM, submitActionForL2PM,
    submitL2PMToGenerate,
    updateL2PMForm,
    deleteL2PM
} from "../../../../store/actions/Inform/HSSEReports/L2PM";
import {L2WeeklyViewModal, ViewGoSeeProgramSection} from "../../Views";
import DebouncedSaveButton from "../../../../components/Buttons/DebounceSaveButton";
import { showConfirmDialog, showSuccessDialog, showWarningDialog } from "../../../../utils/dialogs";
import Swal from "sweetalert2";


const L2PMWeeklyReporting = ({access}) => {
    const accessRights = manageCURDAccess(access);
    const dispatch = useDispatch();
    const L2PMValues = useSelector((state) => state.L2PM.values);
    const userL2PM = useSelector((state) => state.L2PM.userL2PM);
    const singleL2PM = useSelector((state) => state.L2PM.single);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentL2PM, setCurrentL2PM] = useState(null);

    
    const [l2pmtoDelete, setl2pmtoDelete] = useState(null);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);


    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [L2PMGenerate, setL2PMGenerate] = useState(null);

    //ActionModal
    const [actionModalOpen, setActionModalOpen] = useState(false);
    const toggleActionModal = () => setActionModalOpen(!actionModalOpen);

    const [actionData, setActionData] = useState({})

    const handleCloseModal = () => {
        resetForm();
        setModalOpen(false);
        setCurrentL2PM(null);
    };

    //ViewRecord
    //EYEBUTTON MODAL
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedViewRecord, setSelectedRecord] = useState(null);

    //Save
    const [isProcessing, setIsProcessing] = useState(false);


    //Shared Access
    const [accessFilter, setAccessFilter] = useState('My');

    const handleAccessFilterChange = (selectedAccess) => {
        setAccessFilter(selectedAccess);
    };

    const viewRecord = (record) => {
        setSelectedRecord(record);
        setViewModalOpen(true)
    }

    const [formData, setFormData] = useState({
        site: null,
        l3Contractor: null,
        locationPlace: '',
        reportedAt: new Date().toISOString().split('T')[0],
        reportedByUser: null,
        permitWork: false,
        actions: [],
        attachments: [],
        R1_1A: 0,
        R1_1B: 0,
        R1_2: 0,
        R1_3A: 0,
        R1_4A: 0,
        R1_4B: 0,
        R1_4C: '',
        R1_5: 0,
        R1_6: 0,
        R2_A: 0,
        R2_B: 0,
        R2_C: 0,
        R2_D: 0,
        R2_E: 0,
        R2_F: 0,
        R2_G: 0,
        R2_H: 0
    });


    const resetForm = () => {
        setFormData({
            site: '',
            l3Contractor: '',
            locationPlace: '',
            reportedAt: new Date().toISOString().split('T')[0],
            reportedByUser: null,
            actions: [],
            attachments: [],
            R1_1A: 0,
            R1_1B: 0,
            R1_2: 0,
            R1_3A: 0,
            R1_4A: 0,
            R1_4B: 0,
            R1_4C: '',
            R1_5: 0,
            R1_6: 0,
            R2_A: 0,
            R2_B: 0,
            R2_C: 0,
            R2_D: 0,
            R2_E: 0,
            R2_F: 0,
            R2_G: 0,
            R2_H: 0
        });
    };
    const validateForm = () => {

        if (!formData.site) {
            message.error("Site Required *");
            return false;
        }
        if (!formData.l3Contractor) {
            message.error("L3 Contractor is required *");
            return false;
        }
        if (!formData.reportedByUser) {
            message.error("Reported By User required *");
            return false;
        }

        return true;
    };
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };
    const handleSubmit = async () => {
        
        if (!validateForm()) {
            throw new Error("Form validation failed");
        }

        setIsProcessing(true)

        const formDataObj = new FormData();
        formDataObj.append("moduleId",parseInt(access.moduleId))

        if (formData.id) {
            formDataObj.append('id', parseInt(formData.id.toString()));
            formDataObj.append('actionId', parseInt(formData.actionId));
            formDataObj.append('fileId', parseInt(formData.fileId));
        }

        if (formData.site?.value) formDataObj.append('siteId', parseInt(formData.site.value));
        if (formData.l3Contractor?.value) formDataObj.append('l3Id', parseInt(formData.l3Contractor.value));
        formDataObj.append('location', formData.locationPlace || "");
        formDataObj.append('reportedAt', formData.reportedAt || "");
        if (formData.reportedByUser) formDataObj.append('reportedBy', parseInt(formData.reportedByUser.value));

        // Add new keys
        formDataObj.append('R1_1A', parseInt(formData.R1_1A || 0));
        formDataObj.append('R1_1B', parseInt(formData.R1_1B || 0));
        formDataObj.append('R1_2', parseInt(formData.R1_2 || 0));
        formDataObj.append('R1_3A', parseInt(formData.R1_3A || 0));
        formDataObj.append('R1_4A', parseInt(formData.R1_4A || 0));
        formDataObj.append('R1_4B', parseInt(formData.R1_4B || 0));
        formDataObj.append('R1_4C', formData.R1_4C || '');
        formDataObj.append('R1_5', parseInt(formData.R1_5 || 0));
        formDataObj.append('R1_6', parseInt(formData.R1_6 || 0));
        formDataObj.append('R2_A', parseInt(formData.R2_A || 0));
        formDataObj.append('R2_B', parseInt(formData.R2_B || 0));
        formDataObj.append('R2_C', parseInt(formData.R2_C || 0));
        formDataObj.append('R2_D', parseInt(formData.R2_D || 0));
        formDataObj.append('R2_E', parseInt(formData.R2_E || 0));
        formDataObj.append('R2_F', parseInt(formData.R2_F || 0));
        formDataObj.append('R2_G', parseInt(formData.R2_G || 0));
        formDataObj.append('R2_H', parseInt(formData.R2_H || 0));

        if (Array.isArray(formData.actions)) {
            formDataObj.append('actions', JSON.stringify(formData.actions.map(action => ({
                id: action.id || null,
                userId: action.userId ? parseInt(action.userId) : null,
                description: action.description || null,
                createdAt: action.createdAt || null,
                createdBy: action.createdBy || null,
                updatedAt: action.updatedAt || null,
                updatedBy: action.updatedBy || null,
                submittedAt: action.submittedAt || null,
                targetAt: action.targetAt || null,
                reply: action.reply || null,
                tags: action.tags || [],
                type: action.type || "N",
                status: action.status || "PENDING",
            }))));
        }

        if (Array.isArray(formData.attachments)) {
            formDataObj.append('files', JSON.stringify(formData.attachments.map(attachment => ({
                id: attachment.id || null,
                fileName: attachment.filename || "",
                description: attachment.description || null,
                path: attachment.path || null,
                createdAt: attachment.createdAt || null,
                createdBy: attachment.createdBy || null,
                updateBy: attachment.updateBy || null,
                updatedAt: attachment.updatedAt || null,
            }))));

            formData.attachments.forEach((attachment, index) => {
                if (attachment.file instanceof File) {
                    formDataObj.append(`attachments[${index}]`, attachment.file, attachment.filename);
                }
            });
        }

        // console.log("FormData contents:");
        // for (let [key, value] of formDataObj.entries()) {
        //     if (value instanceof File) {
        //         console.log(key, value.name, value.type, value.size + " bytes");
        //     } else {
        //         console.log(key, value);
        //     }
        // }

        try{
            if (formData.id) {
                const result = await dispatch(updateL2PMForm(formDataObj));
                if (result.success) {
                    showSuccessDialog("L2PM  Updated Successfully")
                    resetForm()
                    handleCloseModal()
                } else {
                    showWarningDialog(result.message)
                }
            } else {
                const result = await dispatch(addL2PMForm(formDataObj));
                if (result.success) {
                    showSuccessDialog("L2PM  Added Successfully")
                    resetForm()
                    handleCloseModal()
                } else {
                    showWarningDialog(result.message)
                    //message.error(result.message);
                }
            }
        }
        catch(ex)
        {
            console.log("Exception")
        }
        finally{
            setIsProcessing(false)
        }   

        // resetForm()
        // setModalOpen(false)
    };

    useEffect(() => {
        if (singleL2PM && L2PMValues) {
            console.log(singleL2PM)
            setCurrentL2PM(singleL2PM);

            const sitesOptions = L2PMValues?.sites?.map(site => ({
                value: site.id.toString(),
                label: site.name
            })) || []

            const l3 = L2PMValues?.l3?.map(site => ({
                value: site.id.toString(),
                label: site.name
            })) || []

            const reporters = L2PMValues?.reporters?.map(user => ({
                value: user.id.toString(),
                label: user.name
            }))

            const selectedSite = sitesOptions.find(source => source.value === singleL2PM.siteId?.toString()) || null;
            const selectedL3 = l3.find(source => source.value === singleL2PM.l3Id?.toString()) || null;
            const selectedReporter = reporters.find(source => source.value === singleL2PM.reportedBy?.toString()) || null;

            const editFormData = {
                id: parseInt(singleL2PM.id),
                actionId: parseInt(singleL2PM.actionId),
                fileId: parseInt(singleL2PM.fileId),
                site: selectedSite,
                l3Contractor: selectedL3,
                locationPlace: singleL2PM.location,
                reportedAt: formatDateWithTime(singleL2PM.reportedAt, false),
                reportedByUser: selectedReporter,
                actions: (singleL2PM.actions || []).map(action => ({
                    id: action.id,
                    userId: action.userId,
                    description: action.description,
                    targetDays: action.targetDays,
                    createdAt: action.createdAt,
                    createdBy: action.createdBy,
                    updatedAt: action.updatedAt,
                    updatedBy: action.updatedBy,
                    submittedAt: action.submittedAt,
                    targetAt: action.targetAt,
                    reply: action.reply,
                    tags: action.tags,
                    comments: action.comments,
                    type: action.type,
                    status: action.status
                })),
                attachments: (singleL2PM.files || []).map(file => ({
                    id: file.id,
                    path: file.path,
                    filename: file.fileName,
                    description: file.description,
                    createdAt: file.createdAt,
                    createdBy: file.createdBy,
                    updatedAt: file.updatedAt,
                    updatedBy: file.updatedBy,
                    fullPath: file.fullPath,
                })),
                R1_1A: parseFloat(singleL2PM.R1_1A || 0),
                R1_1B: parseFloat(singleL2PM.R1_1B || 0),
                R1_2: parseFloat(singleL2PM.R1_2 || 0),
                R1_3A: parseFloat(singleL2PM.R1_3A || 0),
                R1_4A: parseFloat(singleL2PM.R1_4A || 0),
                R1_4B: parseFloat(singleL2PM.R1_4B || 0),
                R1_4C: singleL2PM.R1_4C || '',
                R1_5: parseFloat(singleL2PM.R1_5 || 0),
                R1_6: parseFloat(singleL2PM.R1_6 || 0),
                R2_A: parseFloat(singleL2PM.R2_A || 0),
                R2_B: parseFloat(singleL2PM.R2_B || 0),
                R2_C: parseFloat(singleL2PM.R2_C || 0),
                R2_D: parseFloat(singleL2PM.R2_D || 0),
                R2_E: parseFloat(singleL2PM.R2_E || 0),
                R2_F: parseFloat(singleL2PM.R2_F || 0),
                R2_G: parseFloat(singleL2PM.R2_G || 0),
                R2_H: parseFloat(singleL2PM.R2_H || 0),

            }
            setFormData(editFormData)

        }
    }, [singleL2PM]);

    const handleSelectChange = (name, value) => {
        setFormData({...formData, [name]: value});
    };
    const handleAttachmentChange = (newAttachments) => {
        setFormData({...formData, attachments: newAttachments});
    };

    useEffect(() => {
        dispatch(getL2PMValues());
        dispatch(getUserL2PM(access.moduleId));
    }, [dispatch]);


    const handleEdit = async (data) => {
        await dispatch(getSingleL2PM(data.id));
        setModalOpen(true);
    };

    const handleDelete = async (l2pm) => {
        try {
          const result = await showConfirmDialog("Are you sure you want to delete this L2PM Weekly Reporting ?");
          if (result.isConfirmed) {
            const object = await dispatch(deleteL2PM(l2pm.id))
            if (object.success) {
              Swal.fire('Deleted!', 'L2PM Deleted', 'success');
              dispatch(getUserL2PM(access.moduleId));
            }
            else {
              Swal.fire('Error!', object.message, 'error');
            }
          }
        } catch (error) {
          console.error('Error in handleDelete:', error);
        }
    };

    let columns = [
        {
            title: "Action",
            key: "action",
            width: '120px',
            render: (text, data) => (
                <div style={{display: 'flex', gap: '8px'}}>
                    {data.submittedAt === null && (
                        <Button color="primary" size="sm" onClick={() => handleEdit(data)} disabled={!accessRights.U}>
                            <EditOutlined/>
                        </Button>
                    )}
                   {data.submittedAt === null && (<>
                        <Button color="danger" size="sm" onClick={() => handleDelete(data)} disabled={!accessRights.D}>
                            <DeleteOutlined />
                        </Button>
                    </>)}
                    <Button color="info" size="sm" onClick={() => viewRecord(data)} disabled={!accessRights.R}>
                        <EyeFilled/>
                    </Button>
                    {data.submittedAt !== null && (
                        <Button color="info" size="sm" onClick={() => handleActionClick(data)} disabled={accessFilter === 'Shared'}> 
                            <PlusCircleFilled/>
                        </Button>
                    )}
                </div>
            ),
        },
        {
            title: "Sequence Number",
            dataIndex: "submittedAt",
            key: "submittedAt",
            sorter: stringSorter("submittedAt"),
            sortDirections: ["ascend", "descend"],
            render: (text, data) => {
                if (data.submittedAt !== null) {
                    return data.sequenceNo; // Assuming the sequence string is stored in the 'sequence' property
                } else {
                    return (
                        <Button color="primary" size="sm" onClick={() => handleGenerate(data)}>
                            Generate
                        </Button>
                    );
                }
            }
        },
        {
            title: "Reported At",
            dataIndex: "reportedAt",
            key: "reportedAt",
            sorter: dateSorter("reportedAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "L3 Contractor",
            dataIndex: "l3Contractor",
            key: "l3Contractor",
            sorter: stringSorter("l3Contractor"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
            sorter: stringSorter("country"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Business Unit",
            dataIndex: "businessUnit",
            key: "businessUnit",
            sorter: stringSorter("businessUnit"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Project/Program",
            dataIndex: "project",
            key: "project",
            sorter: stringSorter("project"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Site",
            dataIndex: "site",
            key: "site",
            sorter: stringSorter("site"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Location Place",
            dataIndex: "location",
            key: "location",
            sorter: stringSorter("location"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            sorter: stringSorter("description"),
            ellipsis: true,
        },
    ];


    const handleGenerate = async (data) => {
        setL2PMGenerate(data);
        setConfirmModalOpen(true);
        console.log(confirmModalOpen)
    };


    const confirmL2PMGenerate = async () => {
        if (L2PMGenerate) {
            const object={
                moduleId:parseInt(access.moduleId),
                id:L2PMGenerate.id
            }
            const response = await dispatch(submitL2PMToGenerate(object));
            if (response && response.data.code === 1) {
                showSuccessDialog("L2PM Generated Successfully")
            } else {
                showWarningDialog("Failed to generate L2PM")
            }
            setConfirmModalOpen(false);
            setL2PMGenerate(null);
        }
    };

    const handleActionClick = (data) => {
        setActionData(data);
        setActionModalOpen(true);
    };

    const handleInitiateAction = async (data) => {
        delete data.submitDate;
        if (data.message) {
            data.reply = actionData.message;
            delete data.message;
        }
        const payload = {
            informRpmwId: parseInt(actionData.id),
            id: parseInt(actionData.actionId),
            actions: JSON.stringify(data)
        };

        const response = await dispatch(submitActionForL2PM(payload));
        if (response.success) {
            showSuccessDialog(response.message)
            setActionData([]);
            setActionModalOpen(false);
            setModalOpen(false)
            dispatch(getUserL2PM(access.moduleId))

        } else {
            showWarningDialog(response.message)
        }
    };

    const filteredL2PM = useMemo(() => {
        switch (accessFilter) {
            case 'My':
                return [
                    ...(userL2PM.user || [])
                ];
            case 'Shared':
                return userL2PM.shared || [];
            case 'Assigned':
                return userL2PM.assigned || [];
            default:
                return [];
        }
      }, [userL2PM, accessFilter]);


    return (
        <Card>
            <CardHeader>
                <Row className="align-items-center mb-3">
                        <Col md={3}>
                            <h5 className="mb-0">Manage L2PM Weekly Reporting</h5>
                        </Col>
                        <Col md={9} className="d-flex justify-content-end align-items-center">
                            <AccessFilter
                                accessFilter={accessFilter}
                                handleAccessFilterChange={handleAccessFilterChange}
                                sharedAccess={userL2PM?.sharedAccess || []}
                            />
                            <Button color="primary" onClick={() => setModalOpen(true)} className="ms-2" disabled={!accessRights.C}>
                                <PlusCircleFilled size={18} /> New
                            </Button>
                        </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <ReactStrapDataTable
                    dataSource={filteredL2PM ?? []}
                    columns={columns}
                    rowKey="id"
                />
            </CardBody>
            <Modal isOpen={modalOpen} toggle={handleCloseModal} size="xl" backdrop="static">
                <Form onSubmit={handleSubmit}>
                    <ModalHeader toggle={handleCloseModal}>
                        {currentL2PM ? 'Edit L2PM Weekly Reporting' : 'Add L2PM Weekly Reporting'}
                    </ModalHeader>
                    <ModalBody>

                        <AdminSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            data={L2PMValues}
                        />
                        <L2IndiaPMWeeklyReport
                            formData={formData}
                            handleInputChange={handleInputChange}
                            data={L2PMValues}
                        />
                        <ActionSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            actionUsers={L2PMValues?.actionUsers ?? []}
                        />
                        <AttachmentsSection
                            attachments={formData.attachments}
                            onAttachmentsChange={handleAttachmentChange}
                        />

                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleCloseModal}>Back</Button>
                        <DebouncedSaveButton
                            onSave={handleSubmit}
                            isProcessing={isProcessing}
                        />
                    </ModalFooter>
                </Form>
            </Modal>
           
            <Modal isOpen={confirmModalOpen} toggle={() => setConfirmModalOpen(false)}>
                <ModalHeader toggle={() => setConfirmModalOpen(false)}>Confirm Submission</ModalHeader>
                <ModalBody>
                    <p>Are you sure you want to submit this L2 PM Weekly Reporting?</p>
                    <p style={{color: 'red'}}>
                        Note: Once the L2 PM Weekly Reporting is submitted it cannot be edited.
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setConfirmModalOpen(false)}>Cancel</Button>
                    <Button color="primary" onClick={confirmL2PMGenerate}>Yes, Submit</Button>
                </ModalFooter>
            </Modal>

            <ActionModal
                isOpen={actionModalOpen}
                toggle={toggleActionModal}
                data={actionData}
                message={"Action For L2 PM Weekly Reporting"}
                onInitiateAction={handleInitiateAction}
            />
            <L2WeeklyViewModal
                isOpen={viewModalOpen}
                toggle={() => setViewModalOpen(!viewModalOpen)}
                data={selectedViewRecord}
            />
        </Card>

    )
}

export default L2PMWeeklyReporting