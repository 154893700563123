import { ADD_TAG, GET_TAGS} from "../types";


const initialState = {list: [], single: null};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_TAGS:
            return {...state, list: payload};
        case ADD_TAG:
            return {
                ...state,
                list: [payload, ...state.list],
            };

        default:
            return state;
    }
};

export default reducer;
