import axios from "../../../../axios";
import {ADD_CHAPTER, DELETE_CHAPTER, GET_CHAPTERS, GET_CHAPTER, UPDATE_CHAPTER} from "../../../types";
import {setAlert} from "../../alert";
import {message} from "antd";

export const getChapters = () => async (dispatch) => {
    try {
        const res = await axios.get("/chapter/all");
        
        if (res.data.code === 1) {
            dispatch({type: GET_CHAPTERS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const getCoursesChapters = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/chapter/course/${id}`);
        
        if (res.data.code === 1) {
            dispatch({type: GET_CHAPTERS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const addChapter = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/chapter/add", formData);
        ;
        if (res.data.code === 1) {
            dispatch({type: ADD_CHAPTER, payload: res.data.data});
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const getChapter = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/chapter/${id}`);
        if (res.data.code === 1) {
            dispatch({type: GET_CHAPTER, payload: res.data.data});
        }
    } catch (err) {
        // dispatch(setAlert(err.message, "danger"));
    }
};

export const updateChapter = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(`/chapter/update`, formData);
        ;
        if (res.data.code === 1) {
            dispatch({type: UPDATE_CHAPTER, payload: res.data.data});
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        // dispatch(setAlert(err.message, "danger"));
    }
};
export const deleteChapter = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/chapter/delete/${id}`);
        if (res.data.code === 1) {
            message.success(res.data.message);
            dispatch({type: DELETE_CHAPTER, payload: res.data.data});
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
