import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import ReactStrapDataTable from "../../CustomDatatable/ReactStrapDataTable";
import { getQuestions, addQuestion, updateQuestion, deleteQuestion } from "../../../store/actions/competancy/question";
import { getCourses } from "../../../store/actions/competancy/courses";
import { getCoursesChapters } from "../../../store/actions/competancy/chapters";
import { dateTimeSort, stringSorter } from "../../../utils/functions";
import Select from 'react-select';

const QuestionTable = ({ fields, control, errors, remove, handleAddRow }) => (
    <div className="mb-4">
        <h2 className="h4">Questions</h2>
        {fields.map((item, index) => (
            <Card key={item.id} className="mb-4 shadow" style={{
                boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                marginBottom: '10px',
                backgroundColor: '#f5f5f5',
                border: '1px solid #ddd',
                padding: '5px'
            }}>
                <CardBody>
                    <Row className="mb-3">
                        <Col xs="11">
                            <strong>Question {index + 1}</strong>
                        </Col>
                        <Col xs="1" className="text-right">
                            <Button color="link" size="sm" onClick={() => remove(index)}>
                                <DeleteOutlined />
                            </Button>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs="12">
                            <Controller
                                name={`questions.${index}.question`}
                                control={control}
                                rules={{ required: "Question is required" }}
                                render={({ field }) => <Input {...field} type="textarea" rows={3} />}
                            />
                            {errors.questions?.[index]?.question && (
                                <span className="text-danger">{errors.questions[index].question.message}</span>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs="6">
                            <Controller
                                name={`questions.${index}.A`}
                                control={control}
                                rules={{ required: "Option A is required" }}
                                render={({ field }) => <Input {...field} placeholder="Option A" />}
                            />
                            {errors.questions?.[index]?.A && (
                                <span className="text-danger">{errors.questions[index].A.message}</span>
                            )}
                        </Col>
                        <Col xs="6">
                            <Controller
                                name={`questions.${index}.B`}
                                control={control}
                                rules={{ required: "Option B is required" }}
                                render={({ field }) => <Input {...field} placeholder="Option B" />}
                            />
                            {errors.questions?.[index]?.B && (
                                <span className="text-danger">{errors.questions[index].B.message}</span>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs="6">
                            <Controller
                                name={`questions.${index}.C`}
                                control={control}
                                rules={{ required: "Option C is required" }}
                                render={({ field }) => <Input {...field} placeholder="Option C" />}
                            />
                            {errors.questions?.[index]?.C && (
                                <span className="text-danger">{errors.questions[index].C.message}</span>
                            )}
                        </Col>
                        <Col xs="6">
                            <Controller
                                name={`questions.${index}.D`}
                                control={control}
                                rules={{ required: "Option D is required" }}
                                render={({ field }) => <Input {...field} placeholder="Option D" />}
                            />
                            {errors.questions?.[index]?.D && (
                                <span className="text-danger">{errors.questions[index].D.message}</span>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="6">
                            <FormGroup>
                                <Label for={`questions.${index}.answer`}>Correct Answer</Label>
                                <Controller
                                    name={`questions.${index}.answer`}
                                    control={control}
                                    rules={{ required: "Correct answer is required" }}
                                    render={({ field }) => (
                                        <Input type="select" {...field}>
                                            <option value="">Select answer</option>
                                            <option value="A">A</option>
                                            <option value="B">B</option>
                                            <option value="C">C</option>
                                            <option value="D">D</option>
                                        </Input>
                                    )}
                                />
                                {errors.questions?.[index]?.answer && (
                                    <span className="text-danger">{errors.questions[index].answer.message}</span>
                                )}
                            </FormGroup>
                        </Col>
                        <Col xs="6">
                            <FormGroup>
                                <Label for={`questions.${index}.mandatory`}>Mandatory</Label>
                                <Controller
                                    name={`questions.${index}.mandatory`}
                                    control={control}
                                    rules={{ required: "This field is required" }}
                                    render={({ field }) => (
                                        <Input type="select" {...field}>
                                            <option value="">Select</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </Input>
                                    )}
                                />
                                {errors.questions?.[index]?.mandatory && (
                                    <span className="text-danger">{errors.questions[index].mandatory.message}</span>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        ))}
        <div className="d-flex justify-right-between align-items-center mb-3">
            <Button color="primary" size="sm" onClick={handleAddRow}>
                <PlusCircleOutlined style={{ marginRight: '8px' }} /> Add Question
            </Button>
        </div>
    </div>
);


const QuestionManagement = () => {
    const dispatch = useDispatch();
    const questions = useSelector((state) => state.question.list);
    const courses = useSelector((state) => state.course.list);
    const chapters = useSelector((state) => state.chapter.list);


    const [modalOpen, setModalOpen] = useState(false);
    const [editingQuestion, setEditingQuestion] = useState(null);
    const [loadingChapters, setLoadingChapters] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [filteredQuestions, setFilteredQuestions] = useState([]);

    useEffect(() => {
        if (questions.length > 0 && !selectedCourse) {
            const firstCourse = questions[0];
            setSelectedCourse({ value: firstCourse.courseId, label: firstCourse.course });
        }
    }, [questions, selectedCourse]);

    useEffect(() => {
        if (selectedCourse) {
            const filtered = questions.filter(q => q.courseId === selectedCourse.value);
            setFilteredQuestions(filtered);
        } else {
            setFilteredQuestions(questions);
        }
    }, [selectedCourse, questions]);


    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
        setValue,
        watch,
    } = useForm({
        defaultValues: {
            courseId: null,
            chapterId: null,
            questions: [{ question: '', A: '', B: '', C: '', D: '', answer: null, mandatory: null }]
        }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "questions"
    });

    const watchCourse = watch("courseId");

    useEffect(() => {
        dispatch(getQuestions());
        dispatch(getCourses());
    }, [dispatch]);

    useEffect(() => {
        if (watchCourse) {
            setLoadingChapters(true);
            dispatch(getCoursesChapters(watchCourse.value))
                .then(() => {
                    setLoadingChapters(false);
                    if (!editingQuestion) {
                        setValue("chapterId", null);
                    }
                });
        }
    }, [watchCourse, dispatch, setValue, editingQuestion]);

    const handleEdit = (question) => {
        const courseOption = courses.find(course => course.id === question.courseId);
        const chapterOption = chapters.find(chapter => chapter.id === question.chapterId);

        setEditingQuestion(question);
        reset({
            courseId: courseOption ? { value: courseOption.id, label: courseOption.name } : null,
            chapterId: chapterOption ? { value: chapterOption.id, label: chapterOption.name } : null,
            questions: question.questions
        });
        setModalOpen(true);
    };

    const handleDelete = async (question) => {
        if (window.confirm("Are you sure you want to delete this question?")) {
            await dispatch(deleteQuestion(question.id));
            dispatch(getQuestions());
        }
    };

    const onFormSubmit = async (data) => {

        const formattedQuestions = JSON.stringify(data.questions.map(question => ({
            id: question.id ?? null,
            question: question.question,
            A: question.A ?? null,
            B: question.B ?? null,
            C: question.C ?? null,
            D: question.D ?? null,
            answer: question.answer ?? null,
            mandatory: question.mandatory ?? null,
            status: question.status ?? true,
            createdAt: question.createdAt ?? null,
            createdBy: question.createdBy ?? null,
            updateBy: question.updateBy ?? null,
            updatedAt: question.updatedAt ?? null
        })));


        let formData = {
            chapterId: parseInt(data.chapterId.value),
            questions: formattedQuestions
        };

        let result;
        if (editingQuestion) {
            formData.id = parseInt(editingQuestion.id);
            result = await dispatch(updateQuestion(formData));
        } else {
            result = await dispatch(addQuestion(formData));
        }
        if (result.success??false) {
            handleCloseModal();
            dispatch(getQuestions());
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setEditingQuestion(null);
        reset({
            courseId: null,
            chapterId: null,
            questions: [{ question: '', A: '', B: '', C: '', D: '', answer: null, mandatory: null }]
        });
    };

    const handleAddNew = () => {
        setEditingQuestion(null);
        reset({
            courseId: null,
            chapterId: null,
            questions: [{ question: '', A: '', B: '', C: '', D: '', answer: null, mandatory: null }]
        });
        setModalOpen(true);
    };

    const handleAddRow = () => {
        append({ question: '', A: '', B: '', C: '', D: '', answer: null, mandatory: null });
    };

    const columns = [
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: dateTimeSort("createdAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Course",
            dataIndex: "course",
            key: "course",
            sorter: stringSorter("course"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Chapter",
            dataIndex: "chapter",
            key: "chapter",
            sorter: stringSorter("chapter"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
                <>
                    <Button color="link" onClick={() => handleEdit(record)}>
                        <EditOutlined />
                    </Button>
                    <Button color="link" onClick={() => handleDelete(record)}>
                        <DeleteOutlined />
                    </Button>
                </>
            ),
        },
    ];

    const courseOptions = questions
        .map(question => ({
            value: question.courseId,
            label: question.course
        }))
        .filter((v, i, a) => a.findIndex(t => (t.value === v.value && t.label === v.label)) === i);

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">Manage Questions</h5>
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" onClick={handleAddNew}>
                                <PlusCircleOutlined /> Add New
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <FormGroup>
                        <Label for="courseFilter">Filter by Course</Label>
                        <Select
                            id="courseFilter"
                            options={courseOptions}
                            value={selectedCourse}
                            onChange={(option) => setSelectedCourse(option)}
                            isClearable
                        />
                    </FormGroup>
                    <ReactStrapDataTable
                        dataSource={filteredQuestions}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} fullscreen={true}>
                <ModalHeader toggle={handleCloseModal}>
                    {editingQuestion ? 'Edit Question' : 'Add Question'}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                        <FormGroup>
                            <Label for="courseId">Course</Label>
                            <Controller
                                name="courseId"
                                control={control}
                                rules={{ required: "Course is required" }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={courses.map(course => ({
                                            value: course.id,
                                            label: course.name
                                        }))}
                                    />
                                )}
                            />
                            {errors.courseId && <span className="text-danger">{errors.courseId.message}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="chapterId">Chapter</Label>
                            <Controller
                                name="chapterId"
                                control={control}
                                rules={{ required: "Chapter is required" }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={chapters.map(chapter => ({
                                            value: chapter.id,
                                            label: chapter.name
                                        }))}
                                        isDisabled={!watchCourse || loadingChapters}
                                        isLoading={loadingChapters}
                                    />
                                )}
                            />
                            {errors.chapterId && <span className="text-danger">{errors.chapterId.message}</span>}
                        </FormGroup>

                        <QuestionTable
                            fields={fields}
                            control={control}
                            errors={errors}
                            remove={remove}
                            handleAddRow={handleAddRow}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {editingQuestion ? 'Update' : 'Save'}
                        </Button>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
};

export default QuestionManagement;