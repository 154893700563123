import axios from '../../axios';
import {ADD_TAG, GET_TAGS} from '../types';
import {setAlert} from "./alert";

export const getTags = () => async (dispatch) => {
    try {
        const res = await axios.get("/tag/active");
        if (res.data.code === 1) {
            dispatch({type: GET_TAGS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};


export const addTags = (formData) => async (dispatch) => {
    try {
        
        const res = await axios.post("/tag/add",formData);
        if (res.data.code === 1) {
            dispatch({type: ADD_TAG, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

