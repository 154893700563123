import { GET_GLOBAL_MODULES } from "../../types";
import axios from "../../../axios";
import {setAlert} from "../alert";
//GLOBAL QUICK ROUTES
export const getGlobalQuickRoutes = () => async (dispatch) => {
    try {
        const res = await axios.get("quick/module/global");
        if (res.data.code === 1) {
            dispatch({type: GET_GLOBAL_MODULES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const addGlobalQuickRoutes=(formData)=>async(dispatch)=>{
    try {
        const res = await axios.post("quick/module/global/save",formData);
       
        if (res.data.code === 1) {
            dispatch({type: GET_GLOBAL_MODULES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const updateGlobalQuickRoutes=(formData)=>async(dispatch)=>{
    try {
        const res = await axios.post("quick/module/global/update",formData);
        if (res.data.code === 1) {
            dispatch({type: GET_GLOBAL_MODULES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const deleteGlobalQuickRoutes = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`quick/module/global/delete/${id}`);
        if (res.data.code === 1) {
            dispatch({type: GET_GLOBAL_MODULES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}