import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import { DeleteOutlined, EditOutlined, PlusCircleFilled, UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import ReactStrapDataTable from "../../CustomDatatable/ReactStrapDataTable";
import { getUsers, getUserValues, registerUser, updateUser, addBulkUser } from "../../../store/actions/user";
import { getOrganizations } from "../../../store/actions/organization";
import { dateTimeSort, stringSorter } from "../../../utils/functions";
import { message } from "antd";
import { showSuccessDialog, showWarningDialog } from "../../../utils/dialogs";
import * as XLSX from 'xlsx';


const User = () => {
    const dispatch = useDispatch();
    const users = useSelector((state) => state.users.list);
    const userValues = useSelector((state) => state.users.userValues);
    const organizations = useSelector((state) => state.organizations.list);

    const [modalOpen, setModalOpen] = useState(false);
    const [editingUser, setEditingUser] = useState(null);
    const [bulkImportModalOpen, setBulkImportModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const fileInputRef = useRef(null);


    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0]);
    };



    const handleSaveBulkImport = async () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
    
            try {
                const result = await dispatch(addBulkUser(formData));
                if (result.success) {
                    // console.log(result.data);
                    if (result.data.errors && result.data.errors.length > 0) {
                        // Create Excel file for errors
                        const workbook = XLSX.utils.book_new();
                        const worksheet = XLSX.utils.json_to_sheet(result.data.errors.map(error => ({
                            fName: error.data.fName,
                            lName: error.data.lName,
                            email: error.data.email,
                            organization: error.data.organization,
                            userType: error.data.userType,
                            remarks: `${error.error}`
                        })));
    
                        XLSX.utils.book_append_sheet(workbook, worksheet, "Errors");
    
                        // Generate Excel file
                        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        
                        // Create download link
                        const url = window.URL.createObjectURL(data);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'issueUsers.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
    
                        showWarningDialog('Bulk import completed with errors. The issueUsers.xlsx file has been downloaded.');
                    } else {
                        showSuccessDialog('Bulk import successful');
                    }
                    dispatch(getUsers());
                    handleCloseBulkImportModal();
                } else {
                    showWarningDialog(result.message);
                }
            } catch (error) {
                showWarningDialog('An error occurred during bulk import');
            }
        } else {
            showWarningDialog('Please select a file to upload');
        }
    };

    const handleCloseBulkImportModal = () => {
        setBulkImportModalOpen(false);
        setSelectedFile(null);
    };



    useEffect(() => {
        // console.log(userValues)
    }, [userValues]);

    useEffect(() => {
        dispatch(getUsers());
        dispatch(getOrganizations());
        dispatch(getUserValues());
    }, [dispatch]);

    const {
        control,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm();

    const password = watch("password");

    const [filteredUsers, setFilteredUsers] = useState([]);
    const [filters, setFilters] = useState({
        isVerified: null,
        userTypeId: null,
        status: null
    });

    useEffect(() => {
        applyFilters();
    }, [users, filters]);

    const applyFilters = () => {
        let result = users;

        if (filters.isVerified !== null) {
            result = result.filter(user => user.isVerified === filters.isVerified);
        }

        if (filters.userTypeId !== null) {
            result = result.filter(user => user.userTypeId === filters.userTypeId);
        }

        if (filters.status !== null) {
            result = result.filter(user => user.status === filters.status);
        }

        setFilteredUsers(result);
    };

    const handleFilterChange = (filterType, value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterType]: value
        }));
    };

    const columns = [
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: dateTimeSort("createdAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: stringSorter("name"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: stringSorter("email"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "User Type",
            dataIndex: "userTypeId",
            key: "userTypeId",
            render: (userTypeId) => {
                const userType = userValues?.userTypes?.find(type => type.id === userTypeId);
                return userType ? userType.name : "Loading...";
            },
        },
        {
            title: "Organization",
            dataIndex: "organization",
            key: "organization",
            sorter: stringSorter("organization"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Verified",
            dataIndex: "isVerified",
            key: "isVerified",
            render: (isVerified) => (isVerified ? "Yes" : "No"),
        },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
                <>
                    <Button color="link" onClick={() => handleEdit(record)}>
                        <EditOutlined />
                    </Button>
                    <Button color="link" onClick={() => handleDelete(record)}>
                        <DeleteOutlined />
                    </Button>
                </>
            ),
        },
    ];

    const handleEdit = (user) => {
        setEditingUser(user);
        reset({
            firstName: user.fName,
            middleName: user.mName,
            lastName: user.lName,
            email: user.email,
            organization: { value: user.organizationId, label: user.organization },
            userTypeId: { value: user.userTypeId, label: getUserTypeName(user.userTypeId) },
            isVerified: user.isVerified,
            status: user.status
        });
        setModalOpen(true);
    };

    const getUserTypeName = (userTypeId) => {
        const userType = userValues.userTypes.find(type => type.id === userTypeId);
        return userType ? userType.name : "Unknown";
    };

    const handleDelete = async (user) => {
        if (window.confirm("Are you sure you want to delete this user?")) {
            //await dispatch(deleteUser(user.id));
            dispatch(getUsers());
        }
    };

    const handleBulkImport = () => {
        setBulkImportModalOpen(true);
    };

   

    const handleDownloadSample = () => {
        const link = document.createElement('a');
        link.href = "http://hsse.meinhardtepcm.com/hsseapi/assets/sampleUserBulkImport.xlsx";
        link.target = "_blank";
        link.download = "sampleUserBulkImport.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            try {
                const result = await dispatch(addBulkUser(formData));
                if (result.success) {
                    showSuccessDialog('Bulk import successful');
                    dispatch(getUsers());
                    handleCloseBulkImportModal();
                } else {
                    showWarningDialog(result.message);
                }
            } catch (error) {
                showWarningDialog('An error occurred during bulk import');
            }
            // Reset the file input
            event.target.value = null;
        }
    };

    const onFormSubmit = async (data) => {
        let payload = {
            fName: data.firstName,
            mName: data.middleName,
            lName: data.lastName,
            email: data.email,
            password: data.password,
            organizationId: data.organization?.value,
            userTypeId: data.userTypeId.value,
        };

        if (data.middleName === "" || data.middleName === undefined || data.middleName === null) {
            delete payload.mName
        }
        let result;

        if (editingUser) {
            payload.id = editingUser.id;
            result = await dispatch(updateUser(payload));
        } else {
            result = await dispatch(registerUser(payload));
        }
        if (result.success) {
            message.success(result.message);
            dispatch(getUsers())
            handleCloseModal();
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setEditingUser(null);
        reset();
    };

    const handleAddNew = () => {
        setEditingUser(null);
        reset({
            firstName: "",
            middleName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: "",
            organization: null,
            userTypeId: null
        });
        setModalOpen(true);
    };

    const getOrganizationOptions = () => {
        return userValues?.organizations?.map(org => ({
            value: org.id,
            label: org.name
        })) || [];
    };

    const getUserTypeOptions = () => {
        return userValues?.userTypes?.map(type => ({
            value: type.id,
            label: type.name
        })) || [];
    };

    const organizationOptions = getOrganizationOptions();
    const userTypeOptions = getUserTypeOptions();

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">Manage Users</h5>
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" onClick={handleAddNew}>
                                <PlusCircleFilled /> Add New
                            </Button>
                            <Button color="secondary" onClick={handleBulkImport}>
                                <UploadOutlined /> Bulk Import
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row className="mb-3">
                        <Col md={4}>
                            <label>Verification Status:</label>
                            <select
                                className="form-control"
                                onChange={(e) => handleFilterChange('isVerified', e.target.value === '' ? null : e.target.value === 'true')}
                                value={filters.isVerified === null ? '' : filters.isVerified.toString()}
                            >
                                <option value="">All</option>
                                <option value="true">Verified</option>
                                <option value="false">Not Verified</option>
                            </select>
                        </Col>
                        <Col md={4}>
                            <label>User Type:</label>
                            <select
                                className="form-control"
                                onChange={(e) => handleFilterChange('userTypeId', e.target.value === '' ? null : parseInt(e.target.value))}
                                value={filters.userTypeId === null ? '' : filters.userTypeId}
                            >
                                <option value="">All</option>
                                {userValues?.userTypes?.map(type => (
                                    <option key={type.id} value={type.id}>{type.name}</option>
                                )) || <option value="">Loading...</option>}
                            </select>
                        </Col>
                        <Col md={4}>
                            <label>Status:</label>
                            <select
                                className="form-control"
                                onChange={(e) => handleFilterChange('status', e.target.value === '' ? null : e.target.value === 'true')}
                                value={filters.status === null ? '' : filters.status.toString()}
                            >
                                <option value="">All</option>
                                <option value="true">Active</option>
                                <option value="false">Inactive</option>
                            </select>
                        </Col>
                    </Row>
                    <ReactStrapDataTable
                        dataSource={filteredUsers}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            {/* Bulk Import Modal */}
            <Modal isOpen={bulkImportModalOpen} toggle={handleCloseBulkImportModal}>
                <ModalHeader toggle={handleCloseBulkImportModal}>
                    Bulk Import Users
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Button color="link" onClick={handleDownloadSample}>
                            <DownloadOutlined /> Download Sample File
                        </Button>
                    </FormGroup>
                    <FormGroup>
                        <Label for="bulkImportFile">Upload File</Label>
                        <Input
                            type="file"
                            id="bulkImportFile"
                            accept=".xlsx"
                            onChange={handleFileSelect}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSaveBulkImport} disabled={!selectedFile}>
                        Save
                    </Button>
                    <Button color="secondary" onClick={handleCloseBulkImportModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>


            {/* Add/Edit User Modal */}
            <Modal isOpen={modalOpen} toggle={handleCloseModal} backdrop="static">
                <ModalHeader toggle={handleCloseModal}>
                    {editingUser ? 'Edit User' : 'Add User'}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <ModalBody>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="firstName">First Name</Label>
                                    <Controller
                                        name="firstName"
                                        control={control}
                                        rules={{ required: "First Name is required" }}
                                        render={({ field }) => <Input {...field} id="firstName" />}
                                    />
                                    {errors.firstName && <span className="text-danger">{errors.firstName.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="lastName">Last Name</Label>
                                    <Controller
                                        name="lastName"
                                        control={control}
                                        rules={{ required: "Last Name is required" }}
                                        render={({ field }) => <Input {...field} id="lastName" />}
                                    />
                                    {errors.lastName && <span className="text-danger">{errors.lastName.message}</span>}
                                </FormGroup>
                            </Col>
                        </Row>

                        <FormGroup>
                            <Label for="middleName">Middle Name (Optional)</Label>
                            <Controller
                                name="middleName"
                                control={control}
                                render={({ field }) => <Input {...field} id="middleName" />}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: "Email is required",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid email address"
                                    }
                                }}
                                render={({ field }) => <Input {...field} id="email" type="email" />}
                            />
                            {errors.email && <span className="text-danger">{errors.email.message}</span>}
                        </FormGroup>

                        {!editingUser && (
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="password">Password</Label>
                                        <Controller
                                            name="password"
                                            control={control}
                                            rules={{
                                                required: "Password is required",
                                                minLength: {
                                                    value: 8,
                                                    message: "Password must be at least 8 characters long"
                                                }
                                            }}
                                            render={({ field }) => <Input {...field} id="password" type="password" />}
                                        />
                                        {errors.password && <span className="text-danger">{errors.password.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="confirmPassword">Confirm Password</Label>
                                        <Controller
                                            name="confirmPassword"
                                            control={control}
                                            rules={{
                                                required: "Please confirm your password",
                                                validate: value => value === password || "The passwords do not match"
                                            }}
                                            render={({ field }) => <Input {...field} id="confirmPassword" type="password" />}
                                        />
                                        {errors.confirmPassword && <span className="text-danger">{errors.confirmPassword.message}</span>}
                                    </FormGroup>
                                </Col>
                            </Row>
                        )}

                        <FormGroup>
                            <Label for="organization">Organization</Label>
                            <Controller
                                name="organization"
                                control={control}
                                rules={{ required: "Organization is required" }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={organizationOptions}
                                        isClearable
                                        isCreatable
                                        placeholder="Select or type to create new"
                                        onChange={(selectedOption) => {
                                            if (selectedOption?.__isNew__) {
                                                field.onChange({
                                                    value: selectedOption.value,
                                                    label: selectedOption.label,
                                                    isNew: true
                                                });
                                            } else {
                                                field.onChange(selectedOption);
                                            }
                                        }}
                                        value={userValues.organizationId
                                            ? organizationOptions.find(option => option.value === userValues.organizationId)
                                            : field.value}
                                    />
                                )}
                            />
                            {errors.organization && <span className="text-danger">{errors.organization.message}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="userType">User Type</Label>
                            <Controller
                                name="userTypeId"
                                control={control}
                                rules={{ required: "User Type is required" }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={userTypeOptions}
                                        placeholder="Select User Type"
                                    />
                                )}
                            />
                            {errors.userTypeId && <span className="text-danger">{errors.userTypeId.message}</span>}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {editingUser ? 'Update' : 'Save'}
                        </Button>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
};

export default User;