import React, {useCallback, useEffect} from 'react';
import PropTypes from "prop-types";
import withRouter from "../../components/Common/withRouter";

// import Components
import Sidebar from './Sidebar';
import Header from './Header';
import RightSidebar from '../../components/Common/RightSideBar';

//redux
import {useSelector, useDispatch} from "react-redux";

import {
    changeLayout,
    changeLayoutMode,
    changeSidebarTheme,
    changeSidebarType,
    changeTopbarTheme,
    changeLayoutWidth,
    showRightSidebarAction
} from "../../store/actions";

import {createSelector} from 'reselect';
import {useLocation} from "react-router-dom";

const Layout = props => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigation = useSelector((state) => state.navigation.list);

    const selectLayoutState = (state) => state.Layout;
    const selectLayoutProperties = createSelector(
        selectLayoutState,
        (layout) => ({
            layoutModeTypes: layout.layoutModeTypes,
            leftSideBarType: layout.leftSideBarType,
            layoutWidth: layout.layoutWidth,
            topbarTheme: layout.topbarTheme,
            showRightSidebar: layout.showRightSidebar,
            leftSideBarTheme: layout.leftSideBarTheme,
        })
    );

    const isDynamicOrDashboardRoute = useCallback(() => {
        const currentPath = location.pathname.slice(1);
        return currentPath === 'dashboard' || navigation.some(item =>
            item.menu_name.toLowerCase().replace(/ /g, '-') === currentPath
        );
    }, [location.pathname, navigation]);

    const shouldShowSidebar = !isDynamicOrDashboardRoute();

    const styles = {
        layoutWrapper: {
            display: 'flex',
            minHeight: '100vh',
            overflow: 'hidden',
        },
        mainContent: {
            flexGrow: 1,
            padding: '20px',
            transition: 'margin-left 0.3s',
            marginLeft: shouldShowSidebar ? '250px' : '0', // Adjust the width as needed
        },
    };

    const {
        layoutModeTypes,
        layoutWidth,
        leftSideBarType,
        topbarTheme,
        showRightSidebar,
        leftSideBarTheme,
    } = useSelector(selectLayoutProperties);

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const toggleMenuCallback = () => {
        if (leftSideBarType === "default") {
            dispatch(changeSidebarType("condensed", isMobile));
        } else if (leftSideBarType === "condensed") {
            dispatch(changeSidebarType("default", isMobile));
        }
    };

    //hides right sidebar on body click
    const hideRightbar = useCallback((event) => {
        var rightbar = document.getElementById("right-bar");
        //if clicked in inside right bar, then do nothing
        if (rightbar && rightbar.contains(event.target)) {
            return;
        } else {
            //if clicked in outside of rightbar then fire action for hide rightbar
            dispatch(showRightSidebarAction(false));
        }
    }, [dispatch]);

    /*
    layout  settings
    */

    useEffect(() => {
        if (
            // layoutTypes ||
            layoutModeTypes ||
            leftSideBarTheme ||
            layoutWidth ||
            leftSideBarType ||
            topbarTheme
        ) {
            window.dispatchEvent(new Event('resize'));
            dispatch(changeLayout('vertical'));
            dispatch(changeLayoutMode(layoutModeTypes));
            dispatch(changeSidebarTheme(leftSideBarTheme));
            dispatch(changeLayoutWidth(layoutWidth));
            dispatch(changeSidebarType(leftSideBarType));
            dispatch(changeTopbarTheme(topbarTheme));
        }
    }, [
        // layoutTypes,
        layoutModeTypes,
        leftSideBarTheme,
        layoutWidth,
        leftSideBarType,
        topbarTheme,
        dispatch]);

    useEffect(() => {
        //init body click event fot toggle rightbar
        document.body.addEventListener("click", hideRightbar, true);
    }, [hideRightbar]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // useEffect(() => {
    //   dispatch(changeLayout("vertical"));
    // }, [dispatch]);

    // useEffect(() => {
    //   if (layoutModeTypes) {
    //     dispatch(changeLayoutMode(layoutModeTypes));
    //   }
    // }, [layoutModeTypes, dispatch]);

    // useEffect(() => {
    //   if (leftSideBarTheme) {
    //     dispatch(changeSidebarTheme(leftSideBarTheme));
    //   }
    // }, [leftSideBarTheme, dispatch]);

    // useEffect(() => {
    //   if (layoutWidth) {
    //     dispatch(changeLayoutWidth(layoutWidth));
    //   }
    // }, [layoutWidth, dispatch]);

    // useEffect(() => {
    //   if (leftSideBarType) {
    //     dispatch(changeSidebarType(leftSideBarType));
    //   }
    // }, [leftSideBarType, dispatch]);

    // useEffect(() => {
    //   if (topbarTheme) {
    //     dispatch(changeTopbarTheme(topbarTheme));
    //   }
    // }, [topbarTheme, dispatch]);

    return (

        <div style={styles.layoutWrapper}>
            <Header toggleMenuCallback={toggleMenuCallback}/>
            {shouldShowSidebar && (
                <Sidebar
                    theme={leftSideBarTheme}
                    type={leftSideBarType}
                    isMobile={isMobile}
                />
            )}
            <div style={styles.mainContent}>
                {props.children}
            </div>
            {/*<Footer/>*/}
            {showRightSidebar ? <RightSidebar/> : null}
        </div>
    );
};

Layout.propTypes = {
    changeLayoutWidth: PropTypes.func,
    changeLayoutMode: PropTypes.func,
    changeSidebarTheme: PropTypes.func,
    changeSidebarType: PropTypes.func,
    changeTopbarTheme: PropTypes.func,
    children: PropTypes.object,
    layoutWidth: PropTypes.any,
    leftSideBarTheme: PropTypes.any,
    leftSideBarType: PropTypes.any,
    location: PropTypes.object,
    showRightSidebar: PropTypes.any,
    topbarTheme: PropTypes.any,
};

export default withRouter(Layout);
