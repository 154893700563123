import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Table,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import {
  getUsersBindings,
  saveUserBindings,
  getSelectedUserBindings,
} from "../../../../store/actions/user";

const UserRolesAccess = () => {
  document.title = "HSSE Meinhardt EPCM | User Roles Access";
  const dispatch = useDispatch();
  const usersBindings = useSelector((state) => state.users.userBindings) || {};
  const singleUserBinding =
    useSelector((state) => state.users.userBinding) || {};
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(getUsersBindings());
  }, [dispatch]);

  useEffect(() => {
    if (usersBindings.menus) {
      const initialRoles = {};
      usersBindings.menus.forEach((menu) => {
        initialRoles[menu.id] = [];
      });
      setSelectedRoles(initialRoles);
    }
  }, [usersBindings]);

  useEffect(() => {
    if (
      singleUserBinding.roles &&
      usersBindings.menus &&
      usersBindings.systemRoles
    ) {
      const preselectedRoles = {};
      usersBindings.menus.forEach((menu) => {
        const menuRoles = singleUserBinding.roles
          .filter((role) => role.menuId === menu.id)
          .map((role) => ({
            value: role.sRoleId,
            label: usersBindings.systemRoles.find(
              (sysRole) => sysRole.id === role.sRoleId,
            )?.name,
          }));
        preselectedRoles[menu.id] = menuRoles;
      });
      setSelectedRoles(preselectedRoles);
    }
  }, [singleUserBinding, usersBindings.menus, usersBindings.systemRoles]);

  const handleUserSelect = async (selectedOption) => {
    setSelectedUser(selectedOption);
    if (selectedOption) {
      setIsLoading(true);
      await dispatch(getSelectedUserBindings(selectedOption.value));
      setIsLoading(false);
    } else {
      setSelectedRoles({});
    }
  };

  const handleRoleSelect = (menuId, selectedOptions) => {
    setSelectedRoles((prev) => ({
      ...prev,
      [menuId]: selectedOptions,
    }));
  };

  const handleSubmit = async () => {
    if (!selectedUser) {
      console.error("No user selected");
      return;
    }

    setIsLoading(true);

    const payload = {
      userId: selectedUser.value,
      roles: JSON.stringify(
        Object.entries(selectedRoles)
          .filter(([, roles]) => roles.length > 0)
          .flatMap(([menuId, roles]) =>
            roles.map((role) => ({
              menuId: parseInt(menuId),
              sRoleId: role.value,
            })),
          ),
      ),
    };

    try {
      await dispatch(saveUserBindings(payload));
    } catch (error) {
      console.error("Error saving user roles:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const userOptions = usersBindings.users
    ? usersBindings.users.map((user) => ({
        value: user.id,
        label: user.name,
      }))
    : [];

  const roleOptions = useMemo(() => {
    if (!usersBindings.systemRoles) return [];
    return usersBindings.systemRoles.map((role) => ({
      value: role.id,
      label: role.name,
    }));
  }, [usersBindings.systemRoles]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "38px",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col>
            <h5 className="mb-0">Manage User Roles Access</h5>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row className="mb-4">
          <Col md={6}>
            <Select
              options={userOptions}
              value={selectedUser}
              onChange={handleUserSelect}
              placeholder="Select a user..."
              isClearable
              styles={customStyles}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
            />
          </Col>
        </Row>
        {selectedUser && (
          <>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Menu</th>
                  <th>Roles</th>
                </tr>
              </thead>
              <tbody>
                {usersBindings.menus &&
                  usersBindings.menus.map((menu) => (
                    <tr key={menu.id}>
                      <td>{menu.name}</td>
                      <td>
                        <Select
                          options={roleOptions}
                          value={selectedRoles[menu.id]}
                          onChange={(selectedOptions) =>
                            handleRoleSelect(menu.id, selectedOptions)
                          }
                          placeholder="Select roles..."
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <Row className="mt-4">
              <Col>
                <Button
                  color="primary"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? <Spinner size="sm" /> : "Submit"}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default UserRolesAccess;
