import React, { useState, useMemo } from 'react';
import { Table, Input, Button, Row, Col, Card,CardBody, ButtonGroup } from 'reactstrap';
import { CSVLink } from "react-csv";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { DownloadOutlined, FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
import { AutoSizer } from 'react-virtualized';

const commonKeys = {
    "location": "Location",
    "detectedAt": "Detected At",
    "identifiedOther": "Identified Other",
    "actionRequired": "Action Required",
    "createdAt": "Created At",
    "updatedAt": "Updated At",
    "submittedAt": "Submitted At",
    "status": "Status",
    "sequenceNo": "Sequence Number",
    "l3Contractor": "L3 Contractor",
    "site": "Site",
    "project": "Project",
    "city": "City",
    "state": "State",
    "country": "Country",
    "businessUnit": "Business Unit",
    "actions": "Actions",
    "costs": "Costs",
    "files": "Files",
    "createdByUser": "Created By User",
    "updatedByUser": "Updated By User",
    "responsibleManager": "Responsible Manager",
    "closeoutManager": "Closeout Manager"
}
const uniqueKeys = {
    "hazardData": {
        "details": "Details",
        "immediateAction": "Immediate Action",
        "isImprovement": "Is Improvement",
        "factors": "Factors",
        "actualHazards": "Actual Hazards",
        "supervisor": "Supervisor"
    },
    "incidentReport": {
        "dhsIncident": "DHS Incident",
        "description": "Description",
        "wri": "WRI",
        "rni": "RNI",
        "ci": "CI",
        "ni": "NI",
        "secondaryHazards": "Secondary Hazards",
        "aCategory": "Actual Category",
        "pCategory": "Potential Category",
        "involved": "Involved",
        "witnesses": "Witnesses"
    },
    "nonConformity": {
        "ncAP": "NC AP",
        "summary": "Summary",
        "detail": "Detail",
        "contractors": "Contractors",
        "suppliers": "Suppliers",
        "logs": "Logs"
    },
    "improvementOpportunity": {
        "improvementcategory": "Improvement Category"
    },
    "complaints": {
        "significance": "Significance",
        "receiveType": "Receive Type",
        "issuedAt": "Issued At",
        "clientName": "Client Name",
        "complainantName": "Complainant Name",
        "address1": "Address 1",
        "address2": "Address 2",
        "suburb": "Suburb",
        "postalCode": "Postal Code",
        "contact1": "Contact 1",
        "contact2": "Contact 2",
        "source": "Source",
        "receivedByUser": "Received By User"
    }
}
const ReactStrapDataTable = ({ dataSource, columns, title, rowKey = "id" }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const formatDataForExport = () => {
        return filteredAndSortedData.map(item => {
            const rowData = {};
            columns.forEach(column => {
                rowData[column.title] = item[column.dataIndex] || item[column.key] || '';
            });
            return rowData;
        });
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1);
    };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const filteredAndSortedData = useMemo(() => {
        if (!dataSource) return [];

        let processedData = [...dataSource];

        if (searchTerm) {
            processedData = processedData.filter(item =>
                columns.some(column =>
                    String(item[column.dataIndex] || item[column.key] || '').toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
        }

        if (sortConfig.key) {
            processedData.sort((a, b) => {
                const aValue = a[sortConfig.key];
                const bValue = b[sortConfig.key];
                if (aValue < bValue) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }

        return processedData;
    }, [dataSource, searchTerm, sortConfig, columns]);

    const pageCount = Math.ceil(filteredAndSortedData.length / itemsPerPage);
    const paginatedData = filteredAndSortedData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.autoTable({
            head: [columns.map(column => column.title)],
            body: filteredAndSortedData.map(item =>
                columns.map(column => item[column.dataIndex] || item[column.key] || '')
            )
        });
        const currentDate = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
        const filename = `${currentDate}.pdf`;
        doc.save(filename);
    };

    const exportToExcel = () => {
        // Determine the data type based on the unique keys present in the first item
        const dataType = Object.keys(uniqueKeys).find(type =>
            Object.keys(uniqueKeys[type]).some(key => filteredAndSortedData[0]?.hasOwnProperty(key))
        ) || 'unknown';

        // Combine common keys with unique keys for the specific data type
        const allKeys = { ...commonKeys, ...(uniqueKeys[dataType] || {}) };

        // Function to process a value for Excel
        const processValue = (value) => {
            if (Array.isArray(value)) {
                if (value.every(item => typeof item === 'number')) {
                    return ''; // Skip number-only arrays
                }
                return value.filter(item => typeof item === 'string').join(', ');
            }
            return value;
        };

        // Create a new array with the correct column names and processed data
        const excelData = filteredAndSortedData.map(item => {
            const rowData = {};
            Object.entries(allKeys).forEach(([key, title]) => {
                if (key !== 'files') { // Exclude 'files' list
                    const value = processValue(item[key]);
                    rowData[title] = value || '';
                }
            });
            return rowData;
        });

        // Create a worksheet
        const ws = XLSX.utils.json_to_sheet(excelData);

        // Set column widths (optional, but improves readability)
        const colWidths = Object.values(allKeys).map(title => ({ wch: Math.max(title.length, 15) }));
        ws['!cols'] = colWidths;

        // Create a workbook and append the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        // Generate filename with current date and data type
        const currentDate = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
        const filename = `${dataType.toUpperCase()}_${currentDate}.xlsx`;

        // Write the file
        XLSX.writeFile(wb, filename);
    };

    return (
        <Card>
            <CardBody>
                <Row className="mb-3">
                    <Col sm="4">
                        <Input
                            type="text"
                            className="form-control search"
                            placeholder="Search..."
                            onChange={handleSearch}
                        />
                    </Col>
                    <Col sm="4">
                        <Input
                            type="select"
                            id="recordsPerPage"
                            name="recordsPerPage"
                            value={itemsPerPage}
                            onChange={(e) => setItemsPerPage(Number(e.target.value))}
                        >
                            <option value="10">10 per page</option>
                            <option value="20">20 per page</option>
                            <option value="30">30 per page</option>
                            <option value="40">40 per page</option>
                            <option value="50">50 per page</option>
                            <option value="100">100 per page</option>
                        </Input>
                    </Col>
                    <Col sm="4" className="text-sm-end">
                        <ButtonGroup>
                            <Button color="primary" onClick={exportToPDF}>
                                <FilePdfOutlined/> PDF
                            </Button>
                            <CSVLink
                                data={formatDataForExport()}
                                filename={`${new Date().toISOString().split('T')[0]}.csv`}
                                className="btn btn-primary"
                            >
                                <DownloadOutlined/> CSV
                            </CSVLink>
                            <Button color="primary" onClick={exportToExcel}>
                                <FileExcelOutlined/> Excel
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>

                <AutoSizer disableHeight>
                    {({ width }) => (
                        <div style={{ width, overflowX: 'auto', overflowY: 'auto', maxHeight: '500px' }}>
                            <Table responsive striped style={{ width }}>
                                <thead>
                                <tr>
                                    {columns.map(column => (
                                        <th
                                            key={column.key}
                                            onClick={() => requestSort(column.dataIndex || column.key)}
                                            style={{ cursor: 'pointer', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}
                                        >
                                            {column.title}
                                            {sortConfig.key === (column.dataIndex || column.key) && (
                                                <span className="ms-1">
                                                        {sortConfig.direction === 'ascending' ? '▲' : '▼'}
                                                    </span>
                                            )}
                                        </th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {paginatedData.map((item) => (
                                    <tr key={item[rowKey]}>
                                        {columns.map(column => (
                                            <td key={column.key}>
                                                {column.render
                                                    ? column.render(item[column.dataIndex || column.key], item)
                                                    : item[column.dataIndex || column.key]}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </AutoSizer>

                {paginatedData.length === 0 && (
                    <div className="text-center my-3">
                        <h5>Sorry! No Result Found</h5>
                        {/*<p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any orders for your search.</p>*/}
                    </div>
                )}

                <div className="d-flex justify-content-between align-items-center mt-3">
                    <div>
                        Showing {(currentPage - 1) * itemsPerPage + 1} to {Math.min(currentPage * itemsPerPage, filteredAndSortedData.length)} of {filteredAndSortedData.length} entries
                    </div>
                    <div>
                        <Button
                            color="secondary"
                            onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                            disabled={currentPage === 1}
                            className="me-2"
                        >
                            Previous
                        </Button>
                        {[...Array(pageCount)].map((_, i) => (
                            <Button
                                key={i}
                                color={currentPage === i + 1 ? 'primary' : 'secondary'}
                                onClick={() => setCurrentPage(i + 1)}
                                className="me-2"
                            >
                                {i + 1}
                            </Button>
                        ))}
                        <Button
                            color="secondary"
                            onClick={() => setCurrentPage(p => Math.min(pageCount, p + 1))}
                            disabled={currentPage === pageCount}
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

export default ReactStrapDataTable;