import {
    GENERATE_LDO1,
    LDO1_VALUES,
    GET_USER_LDO1,
    GET_SINGLE_LDO1,
    ADD_LDO1,
    UPDATE_LDO1,
    DELETE_LDO1
} from "../../../types";

const initialState = { list: [], single: null, userLDO1: [],values:[] };

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GENERATE_LDO1:
            return {
                ...state,
                userLDO1: payload
            };
        case LDO1_VALUES:
            return { ...state, values: payload };
        case GET_USER_LDO1:
            return { ...state, userLDO1: payload };
        case GET_SINGLE_LDO1:
            return { ...state, single: payload };
        case ADD_LDO1:
            return { ...state, userLDO1: payload };
        case UPDATE_LDO1:
            return {
                ...state,
                userLDO1: payload
            };
        case DELETE_LDO1:
                return {
                    ...state,
                    userLDO1: state.userLDO1.user.filter(object => object.id !== payload)
                };
        default:
            return state;
    }
};

export default reducer;