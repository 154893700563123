import axios from '../../axios';
import {ADD_ITEM, DELETE_ITEM, GET_ITEM, GET_ITEMS, UPDATE_ITEM} from '../types';
import {setAlert} from "./alert";



export const getItems = () => async (dispatch) => {
    try {
        const res = await axios.get("/item/all");
        if (res.data.code === 1) {
            dispatch({type: GET_ITEMS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const addItem = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/item/add", formData);
        ;
        if (res.data.code === 1) {
            dispatch({type: ADD_ITEM, payload: res.data.data});
            dispatch(setAlert(res.data.message, 'success'));
        } else {
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const getItem = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/item/${id}`);
        if (res.data.code === 1) {
            dispatch({type: GET_ITEM, payload: res.data.data});
        }
    } catch (err) {
        // dispatch(setAlert(err.message, "danger"));
    }
};

export const updateItem = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(`/item/update`, formData);
        if (res.data.code === 1) {
            dispatch({type: UPDATE_ITEM, payload: res.data.data});
            dispatch(setAlert(res.data.message, "success"));
        } else {
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        // dispatch(setAlert(err.message, "danger"));
    }
};
export const deleteItem = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/item/delete/${id}`);
        if (res.data.code === 1) {
            dispatch(setAlert(res.data.message, "success"));
            dispatch({type: DELETE_ITEM, payload: res.data.data});
        } else {
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
