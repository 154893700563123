import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import { DeleteOutlined, EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import ReactStrapDataTable from "../../CustomDatatable/ReactStrapDataTable";
import { getCities, addCity, updateCity, deleteCity } from "../../../store/actions/region/city";
import { getCountries } from "../../../store/actions/region/country";
import { getStatesByCountry } from "../../../store/actions/region/state";
import { dateTimeSort, stringSorter } from "../../../utils/functions";
import Select from 'react-select';

const City = () => {
    const dispatch = useDispatch();
    const cities = useSelector((state) => state.city.list);
    const countries = useSelector((state) => state.country.list);
    const states = useSelector((state) => state.state.list);

    const [modalOpen, setModalOpen] = useState(false);
    const [editingCity, setEditingCity] = useState(null);
    const [loadingStates, setLoadingStates] = useState(false);

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
        setValue,
        watch,
    } = useForm();

    const watchCountry = watch("coId");

    useEffect(() => {
        dispatch(getCities());
        dispatch(getCountries());
    }, [dispatch]);

    useEffect(() => {
        if (watchCountry) {
            setLoadingStates(true);
            dispatch(getStatesByCountry(watchCountry.value))
                .then(() => {
                    setLoadingStates(false);
                    if (!editingCity) {
                        setValue("stId", null);
                    }
                });
        }
    }, [watchCountry, dispatch, setValue, editingCity]);

    useEffect(() => {
        if (editingCity && !loadingStates) {
            const stateOption = states.find(state => state.id === editingCity.stId);
            if (stateOption) {
                setValue("stId", { value: stateOption.id, label: stateOption.name });
            }
        }
    }, [editingCity, states, loadingStates, setValue]);

    const handleEdit = async (city) => {
        const countryOption = countries.find(country => country.id === city.coId);

        setLoadingStates(true);
        await dispatch(getStatesByCountry(city.coId));
        setLoadingStates(false);

        setEditingCity(city);
        reset({
            name: city.name,
            coId: countryOption ? { value: countryOption.id, label: countryOption.name } : null,
            // We'll set stId in the useEffect hook after states are loaded
        });
        setModalOpen(true);
    };

    const handleDelete = async (city) => {
        if (window.confirm("Are you sure you want to delete this city?")) {
            await dispatch(deleteCity(city.id));
            dispatch(getCities());
        }
    };

    const onFormSubmit = (data) => {
        const formData = {
            name: data.name,
            stId: data.stId.value,
        };
        if (editingCity) {
            dispatch(updateCity({ ...formData, id: editingCity.id }));
        } else {
            dispatch(addCity(formData));
        }
        handleCloseModal();
        dispatch(getCities());
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setEditingCity(null);
        reset();
    };

    const handleAddNew = () => {
        setEditingCity(null);
        reset({ name: '', coId: null, stId: null });
        setModalOpen(true);
    };


    const columns = [
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: dateTimeSort("createdAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
            sorter: stringSorter("country"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "State",
            dataIndex: "state",
            key: "state",
            sorter: stringSorter("state"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: stringSorter("name"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
                <>
                    <Button color="link" onClick={() => handleEdit(record)}>
                        <EditOutlined />
                    </Button>
                    <Button color="link" onClick={() => handleDelete(record)}>
                        <DeleteOutlined />
                    </Button>
                </>
            ),
        },
    ];

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">Manage City</h5>
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" onClick={handleAddNew}>
                                <PlusCircleFilled /> Add New
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactStrapDataTable
                        dataSource={cities}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} backdrop="static">
                <ModalHeader toggle={handleCloseModal}>
                    {editingCity ? 'Edit City' : 'Add City'}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="coId">Country</Label>
                            <Controller
                                name="coId"
                                control={control}
                                rules={{ required: "Country is required" }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={countries.map(country => ({
                                            value: country.id,
                                            label: country.name
                                        }))}
                                    />
                                )}
                            />
                            {errors.coId && <span className="text-danger">{errors.coId.message}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="stId">State</Label>
                            <Controller
                                name="stId"
                                control={control}
                                rules={{ required: "State is required" }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={states.map(state => ({
                                            value: state.id,
                                            label: state.name
                                        }))}
                                        isDisabled={!watchCountry || loadingStates}
                                        isLoading={loadingStates}
                                    />
                                )}
                            />
                            {errors.stId && <span className="text-danger">{errors.stId.message}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: "Name is required",
                                    pattern: {
                                        value: /\S/,
                                        message: "Blank space is not allowed.",
                                    },
                                }}
                                render={({ field }) => <Input {...field} id="name" />}
                            />
                            {errors.name && <span className="text-danger">{errors.name.message}</span>}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {editingCity ? 'Update' : 'Save'}
                        </Button>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
};

export default City;