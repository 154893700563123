import React, { useEffect, useState,useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button, Card, CardHeader, CardBody, Row, Col, Form,
    Modal, ModalHeader, ModalBody, ModalFooter, Table,
} from 'reactstrap';
import {
    addImprovementOpportunityForm,
    getImprovementOpportunities,
    getUserImprovementOpportunities,
    submitImprovementOpportunity,
    getUserSingleImprovementOpportunity, updateImprovementOpportunityForm, submitActionForImprovementOpportunity,deleteImprovementOppourtionity
} from "../../../store/actions/Incident/improvementOpportunity";

import {
    ActionModal,
    AdminSection,
    AttachmentsSection,
    ContributingFactorsSection,
    GeneralSection, ImprovementOpportunityDetailsSection,
} from "../Common/Forms";
import { message } from "antd";
import { DeleteOutlined, EditOutlined, EyeFilled, PlusCircleFilled } from "@ant-design/icons";
import {AccessFilter, dateSorter, formatDateWithTime, manageCURDAccess, stringSorter} from "../../../utils/functions";
import ReactStrapDataTable from "../../CustomDatatable/ReactStrapDataTable";
import { ViewImprovementOpportunityModal } from "../Views";
import DebouncedSaveButton from '../../../components/Buttons/DebounceSaveButton';
import { showSuccessDialog, showWarningDialog } from "../../../utils/dialogs";


const ImprovementOpportunities = ({access}) => {
    const accessRights = manageCURDAccess(access);
    const dispatch = useDispatch();
    const improvementOpportunities = useSelector((state) => state.improvementOpportunity.list);
    const userImprovementOpportunities = useSelector((state) => state.improvementOpportunity.userImprovementOpportunity);
    const getSingleImprovementOpportunity = useSelector((state) => state.improvementOpportunity.single);

    const [modalOpen, setModalOpen] = useState(false);
    const [currentImprovementOpportunity, setCurrentImprovementOpportunity] = useState(null);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [improvementOpportunityToDelete, setImprovementOpportunityToDelete] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [actionModalOpen, setActionModalOpen] = useState(false);
    const [selectedImprovementOpportunity, setSelectedImprovementOpportunity] = useState(null);
    const [actionData, setActionData] = useState({})
    const [statusActionModel, setStatusActionModal] = useState(false)

    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [improvementOpportunityToGenerate, setImprovementOpportunityToGenerate] = useState(null);

    //View ImprovementOpportunity
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedViewImprovementOpportunity, setSelectedViewImprovementOpportunity] = useState(null);

    //SaveButton
    const [isProcessing, setIsProcessing] = useState(false);

    //SharedAccessFilter
    const [accessFilter, setAccessFilter] = useState('My');

    const handleAccessFilterChange = (selectedAccess) => {
        setAccessFilter(selectedAccess);
    };

    const toggleActionModal = () => setActionModalOpen(!actionModalOpen);

    const handleActionClick = (improvementOpportunity) => {
        //console.log(improvementOpportunity)
        // setStatusActionModal(true)
        setSelectedImprovementOpportunity(improvementOpportunity);
        setActionModalOpen(true);
    };

    const viewImprovementOpportunity = (improvementOpportunity) => {
        //console.log("helloo")
        setSelectedViewImprovementOpportunity(improvementOpportunity);
        setViewModalOpen(true);
    }

    const handleInitiate = (actionData) => {
        // console.log("Status Click")
        // setStatusActionModal(true)
        setActionData(actionData)
    };
    const handleInitiateAction = async (actionData) => {
        delete actionData.submitDate
        if (actionData.message) {
            actionData.reply = actionData.message;
            delete actionData.message;
        }

        const payload = {
            improvementOpportunityId: selectedImprovementOpportunity.id,
            id: selectedImprovementOpportunity.actionId,
            actions: JSON.stringify(actionData)
        }
        const response = await dispatch(submitActionForImprovementOpportunity(payload))
        if (response.success) {
            showSuccessDialog(response.message)
            setActionData([]);
            setActionModalOpen(false);
            setModalOpen(false)
            dispatch(getUserImprovementOpportunities(access.moduleId))
        } else {
            showWarningDialog(response.message)
        }
    };

    const [formData, setFormData] = useState({
        responsibleManager: '',
        closeoutManager: '',
        site: '',
        l3Contractor: '',
        locationPlace: '',
        identifiedBy: '',
        detectedOn: new Date().toISOString().split('T')[0],
        furtherActionsRequired: 'No',
        identifiedOther: '',
        actions: [],
        attachments: []
    });

    const resetForm = () => {
        setFormData({
            responsibleManager: '',
            closeoutManager: '',
            supervisor: '',
            site: '',
            l3Contractor: '',
            detectedOn: new Date().toISOString().split('T')[0],
            furtherActionsRequired: 'No',
            locationPlace: '',
            identifiedBy: '',
            actions: [],
            attachments: []
        });
    };

    //Validation
    const validateForm = () => {
        if (!formData.responsibleManager) {
            message.error("Responsible Manager is required.");
            return false;
        }
        if (!formData.closeoutManager) {
            message.error("Closeout Manager is required.");
            return false;
        }
        if (!formData.site) {
            message.error("Site is required.");
            return false;
        }
        if (!formData.l3Contractor) {
            message.error("L3 Contractor is required.");
            return false;
        }
        if (!formData.detectedOn) {
            message.error("Detected On date is required.");
            return false;
        }
        // if (formData.identifiedByUser.length === 0 && !formData.identifiedByOther) {
        //     message.error("At least one Identified By option or Identified By Other is required.");
        //     return false;
        // }
        if (!formData.improvementCategory) {
            message.error("Improvement Category is required.");
            return false;
        }
        if (formData.furtherActionsRequired === 'Yes' && formData.actions.length === 0) {
            message.error("At least one action is required when Further Actions Required is Yes.");
            return false;
        }
        if (formData.furtherActionsRequired === 'Yes') {
            for (let action of formData.actions) {
                if (!action.userId || !action.description || !action.targetDays) {
                    message.error("All fields in Contributing Factors and Actions are required.");
                    return false;
                }
            }
        }
        return true;
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSelectChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };

    const addImprovementOpportunity = async (improvementOpportunityData) => {
        try {

            const formdata = transformFormDataForSubmission(improvementOpportunityData)

            setIsProcessing(true)
            // console.log(formDataToObject(formdata))

            if (formData.id) {
                const result = await dispatch(updateImprovementOpportunityForm(formdata))
                if (result.success) {
                    showSuccessDialog("ImprovementOpportunity Updated Successfully")
                    resetForm()
                    handleCloseModal()
                } else {
                    showWarningDialog(result.message)
                }
            } else {
                const result = await dispatch(addImprovementOpportunityForm(formdata))
                
                if (result.success) {
                    showSuccessDialog("ImprovementOpportunity Added Successfully")
                    resetForm()
                    handleCloseModal()
                    dispatch(getUserImprovementOpportunities(access.moduleId))
                } else {
                    showWarningDialog(result.message)
                }
            }

        } catch (error) {
            setIsProcessing(false)
            // console.log(error)
            showWarningDialog("An error occurred while adding the improvementOpportunity. Please try again.")
            setErrorMessage()
        }
        finally{
            setIsProcessing(false)
        }
    };

    const transformFormDataForSubmission = (formData) => {
        const formDataObj = new FormData();
        formDataObj.append('moduleId',parseInt(access.moduleId))

        if (formData.id) {
            formDataObj.append('id', parseInt(formData.id));
            formDataObj.append('actionId', parseInt(formData.actionId));
            formDataObj.append('fileId', parseInt(formData.fileId));
            formDataObj.append('costId', parseInt(formData.costId));
        }

        formDataObj.append('rmId', parseInt(formData.responsibleManager.value));
        formDataObj.append('cmId', parseInt(formData.closeoutManager.value));
        formDataObj.append('siteId', parseInt(formData.site.value));
        formDataObj.append('l3Id', parseInt(formData.l3Contractor.value));
        formDataObj.append('location', formData.locationPlace);
        formDataObj.append('detectedAt', formData.detectedOn);
        if (formData.identifiedBy) {
            formDataObj.append('identifiedBy', parseInt(formData.identifiedBy.value))
        }
        formDataObj.append('identifiedOther', formData.identifiedByOther || '');
        formDataObj.append('imCatId', parseInt(formData.improvementCategory.value));
        formDataObj.append('actionRequired', formData.furtherActionsRequired === 'Yes');
        formDataObj.append('actions', JSON.stringify(formData.actions.map(action => ({
            id: action.id ?? null,
            userId: parseInt(action.userId),
            description: action.description ?? null,
            createdAt: action.createdAt ?? null,
            createdBy: action.createdBy ?? null,
            updatedAt: action.updatedAt ?? null,
            updatedBy: action.updatedBy ?? null,
            submittedAt: action.submittedAt ?? null,
            targetAt: action.targetAt ?? null,
            reply: action.reply ?? null,
            tags: action.tags ?? [],
            comments: action.comments ?? null,
            targetDays: parseInt(action.targetDays, 10),
            type: action.type ?? "N",
            status: action.status ?? "PENDING",
        }))));

        // Handle file attachments
        formData.attachments.forEach((attachment, index) => {
            if (attachment.file instanceof File) {
                formDataObj.append(`attachments[${index}]`, attachment.file, attachment.filename);
            }
        });

        formDataObj.append('files', JSON.stringify(formData.attachments.map(attachment => ({
            id: attachment.id ?? null,
            fileName: attachment.filename,
            description: attachment.description ?? null,
            path: attachment.path ?? null,
            createdAt: attachment.createdAt ?? null,
            createdBy: attachment.createdBy ?? null,
            updateBy: attachment.updateBy ?? null,
            updatedAt: attachment.updatedAt ?? null,
        }))));

        if (formData.costImplications !== undefined) {
            formDataObj.append('costs', JSON.stringify(formData.costImplications.map(action => ({
                id: action.id ?? null,
                description: action.description ?? null,
                amount: parseFloat(action.amount),
                allocation: action.allocation ?? null,
                comment: action.comment ?? null,
                createdAt: action.createdAt ?? null,
                createdBy: action.createdBy ?? null,
                updatedAt: action.updatedAt ?? null,
                updatedBy: action.updatedBy ?? null,
            }))));
        }
        else {
            formDataObj.append('costs', JSON.stringify([]))
        }


        return formDataObj;
    };

    const handleSubmit = async () => {
        
        setErrorMessage("");

        if (!validateForm()) {
            throw new Error("Form validation failed");
        }

        const contributingFactorsAndActions = formData.furtherActionsRequired === 'Yes'
            ? formData.actions.map(action => ({
                // id: Date.now(), // This should be replaced with a proper ID generation method
                userId: action.userId,
                description: action.description,
                // createdAt: null,
                createdBy: null, // This should be set to the current user's ID
                updatedAt: null,
                updatedBy: null,
                submittedAt: null,
                targetDays: action.targetDays,
                targetAt: null,
                reply: null,
                tags: [],
                comments: []
            }))
            : null;

        const improvementOpportunityPayload = {
            ...formData,
            contributingFactorsAndActions
        };

        await addImprovementOpportunity(improvementOpportunityPayload);
    };

    const handleEdit = async (improvementOpportunity) => {
        setCurrentImprovementOpportunity(improvementOpportunity);
        await dispatch(getUserSingleImprovementOpportunity(improvementOpportunity.id));
        setModalOpen(true);
    };

    useEffect(() => {
        if (getSingleImprovementOpportunity && currentImprovementOpportunity && improvementOpportunities) {

            const improvementCategoriesOptions = improvementOpportunities?.improvementCategories?.map(source => ({
                value: source.id.toString(),
                label: source.name
            })) || [];

            const identifiersOptions = improvementOpportunities?.identifiers?.map(source => ({
                value: source.id.toString(),
                label: source.name
            })) || [];

            const selectImprovementCat = improvementCategoriesOptions.find(source => source.value === getSingleImprovementOpportunity.imCatId?.toString()) || null;
            const selectIdentifer = identifiersOptions.find(source => source.value === getSingleImprovementOpportunity.identifiedBy?.toString()) || null;



            const improvementOpportunityData = getSingleImprovementOpportunity;
            console.log(improvementOpportunityData)
            const newFormData = {
                actionId: improvementOpportunityData.actionId,
                fileId: improvementOpportunityData.fileId,
                id: improvementOpportunityData.id,
                costId: improvementOpportunityData.costId,
                responsibleManager: { value: improvementOpportunityData.rmId.toString(), label: improvementOpportunityData.responsibleManager },
                closeoutManager: { value: improvementOpportunityData.cmId.toString(), label: improvementOpportunityData.closeoutManager },
                site: { value: improvementOpportunityData.siteId.toString(), label: improvementOpportunityData.site },
                l3Contractor: { value: improvementOpportunityData.l3Id.toString(), label: improvementOpportunityData.l3Contractor },
                locationPlace: improvementOpportunityData.location || '',
                detectedOn: improvementOpportunityData.detectedAt ? improvementOpportunityData.detectedAt.split(' ')[0] : '',
                identifiedByUser: selectIdentifer ?? null,
                identifiedByOther: improvementOpportunityData.identifiedOther || '',
                improvementCategory: selectImprovementCat,
                furtherActionsRequired: improvementOpportunityData.actionRequired ? 'Yes' : 'No',
                actions: (improvementOpportunityData.actions || []).map(action => ({
                    id: action.id,
                    userId: action.userId,
                    description: action.description,
                    targetDays: action.targetDays,
                    createdAt: action.createdAt,
                    createdBy: action.createdBy,
                    updatedAt: action.updatedAt,
                    updatedBy: action.updatedBy,
                    submittedAt: action.submittedAt,
                    targetAt: action.targetAt,
                    reply: action.reply,
                    tags: action.tags,
                    comments: action.comments,
                    type: action.type,
                    status: action.status
                })),
                attachments: (improvementOpportunityData.files || []).map(file => ({
                    id: file.id,
                    path: file.path,
                    filename: file.fileName,
                    description: file.description,
                    createdAt: file.createdAt,
                    createdBy: file.createdBy,
                    updatedAt: file.updatedAt,
                    updatedBy: file.updatedBy,
                    fullPath: file.fullPath,
                })),
                costImplications: (improvementOpportunityData.costs || []).map(cost => ({
                    id: cost.id,
                    description: cost.description,
                    amount: cost.amount,
                    allocation: cost.allocation,
                    comment: cost.comment,
                    createdAt: cost.createdAt,
                    createdBy: cost.createdBy,
                    updatedAt: cost.updatedAt,
                    updatedBy: cost.updatedBy,
                }))
            };

            setFormData(newFormData);
        }
    }, [getSingleImprovementOpportunity, currentImprovementOpportunity]);

    const handleCloseModal = () => {
        resetForm();
        setCurrentImprovementOpportunity(null);
        setModalOpen(false);
    };

    const handleDelete = (improvementOpportunity) => {
        setImprovementOpportunityToDelete(improvementOpportunity);
        setDeleteModalVisible(true);
    };

    const confirmDelete = async () => {
        try {
            const object=dispatch(deleteImprovementOppourtionity(improvementOpportunityToDelete.id))
            if(object.result)
            {
                showSuccessDialog(object.message)
                dispatch(getImprovementOpportunities());
            }
            setImprovementOpportunityToDelete(null)
            setDeleteModalVisible(false);
        } catch (error) {
            showWarningDialog('An error occurred while deleting the improvementOpportunity')
        }
    };

    const handleGenerate = async (improvementOpportunity) => {
        setImprovementOpportunityToGenerate(improvementOpportunity);
        setConfirmModalOpen(true);
    };

    const confirmGenerate = async () => {
        if (improvementOpportunityToGenerate) {
            const object={
                moduleId:parseInt(access.moduleId),
                id:improvementOpportunityToGenerate.id
            }
            
            const response = await dispatch(submitImprovementOpportunity(object));
            if (response && response.data.code === 1) {
                showSuccessDialog("ImprovementOpportunity generated successfully")
                
            } else {
                showWarningDialog("Failed to generate improvementOpportunity")
            }
            setConfirmModalOpen(false);
            setImprovementOpportunityToGenerate(null);
            // You might want to refresh the improvementOpportunity list here
            //dispatch(getUserImprovementOpportunities(access.moduleId));
        }
    };

    let columns = [
        {
            title: "Action",
            key: "action",
            width: '120px',
            render: (text, improvementOpportunity) => (
                <div style={{ display: 'flex', gap: '8px' }}>
                    {improvementOpportunity.submittedAt === null && (
                        <Button color="primary" size="sm" onClick={() => handleEdit(improvementOpportunity)} disabled={!accessRights.U}>
                            <EditOutlined />
                        </Button>
                    )}
                    {improvementOpportunity.submittedAt === null && (<>
                        <Button color="danger" size="sm" onClick={() => handleDelete(improvementOpportunity)} disabled={!accessRights.D}>
                            <DeleteOutlined />
                        </Button>
                    </>)}
                    <Button color="info" size="sm" onClick={() => viewImprovementOpportunity(improvementOpportunity)} disabled={!accessRights.R}>
                        <EyeFilled />
                    </Button>
                    {improvementOpportunity.submittedAt !== null && (
                        <Button color="info" size="sm" onClick={() => handleActionClick(improvementOpportunity)} disabled={accessFilter === 'Shared'}>
                            <PlusCircleFilled />
                        </Button>
                    )}

                </div>
            ),
        },
        {
            title: "Sequence Number",
            dataIndex: "submittedAt",
            key: "submittedAt",
            sorter: stringSorter("submittedAt"),
            sortDirections: ["ascend", "descend"],
            render: (text, improvementOpportunity) => {
                if (improvementOpportunity.submittedAt !== null) {

                    return improvementOpportunity.sequenceNo; // Assuming the sequence string is stored in the 'sequence' property
                } else {
                    return (
                        <Button color="primary" size="sm" onClick={() => handleGenerate(improvementOpportunity)}>
                            Generate
                        </Button>
                    );
                }
            }
        },
        {
            title: "Detected On",
            dataIndex: "detectedAt",
            key: "detectedAt",
            sorter: dateSorter("detectedAt"),
            sortDirections: ["ascend", "descend"],
            render: (text) => (
                <>{formatDateWithTime(text, false)}</>
            )  
        },
        {
            title: "Entered By",
            dataIndex: "createdByUser",
            key: "createdByUser",
            sorter: stringSorter("createdByUser"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "L3 Contractor",
            dataIndex: "l3Contractor",
            key: "l3Contractor",
            sorter: stringSorter("l3Contractor"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
            sorter: stringSorter("country"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Business Unit",
            dataIndex: "businessUnit",
            key: "businessUnit",
            sorter: stringSorter("businessUnit"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Project/Program",
            dataIndex: "project",
            key: "project",
            sorter: stringSorter("project"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Site",
            dataIndex: "site",
            key: "site",
            sorter: stringSorter("site"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Location Place",
            dataIndex: "location",
            key: "location",
            sorter: stringSorter("location"),
            sortDirections: ["ascend", "descend"],
        },
    ];

    // columns = enhanceColumnsWithFilters(columns)

    useEffect(() => {
        dispatch(getImprovementOpportunities());
        dispatch(getUserImprovementOpportunities(access.moduleId))
    }, [dispatch]);

    useEffect(() => {
        // console.log("USER IMPROVEMENT OPP")
        // console.log(userImprovementOpportunities)
    }, [userImprovementOpportunities]);

    const handleAttachmentChange = (newAttachments) => {
        setFormData({ ...formData, attachments: newAttachments });
    };

    const filteredImprovementOppourtionites = useMemo(() => {
        switch (accessFilter) {
            case 'My':
                return [
                    ...(userImprovementOpportunities.user || []),
                ];
            case 'Shared':
                return userImprovementOpportunities.shared || [];
            case 'Assigned':
                return userImprovementOpportunities.assigned || [];
            default:
                return [];
        }
    }, [userImprovementOpportunities, accessFilter]);


    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="align-items-center mb-3">
                        <Col md={3}>
                            <h5 className="mb-0">Manage ImprovementOpportunities</h5>
                        </Col>
                        <Col md={9} className="d-flex justify-content-end align-items-center">
                            <AccessFilter
                                accessFilter={accessFilter}
                                handleAccessFilterChange={handleAccessFilterChange}
                                sharedAccess={userImprovementOpportunities?.sharedAccess || []}
                            />
                            <Button color="primary" onClick={() => setModalOpen(true)} className="ms-2" disabled={!accessRights.C}>
                                <PlusCircleFilled size={18} /> New
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactStrapDataTable
                        dataSource={filteredImprovementOppourtionites ?? []}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} size="xl" backdrop="static">
                <Form onSubmit={handleSubmit}>
                    <ModalHeader toggle={handleCloseModal}>
                        {currentImprovementOpportunity ? 'Edit ImprovementOpportunity' : 'Add ImprovementOpportunity'}
                    </ModalHeader>
                    <ModalBody>
                        <GeneralSection
                            formData={formData}
                            handleSelectChange={handleSelectChange}
                            hazardData={improvementOpportunities}
                            showSupervisor={false}
                        />
                        <AdminSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            hazardData={improvementOpportunities}
                        />
                        <ImprovementOpportunityDetailsSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            incidentData={improvementOpportunities}
                        />
                        <ContributingFactorsSection
                            formData={formData}
                            handleInputChange={handleInputChange}
                            actionUsers={improvementOpportunities.actionUsers}
                            showIsImprovement={false}
                        />
                        <AttachmentsSection
                            attachments={formData.attachments}
                            onAttachmentsChange={handleAttachmentChange}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleCloseModal}>Back</Button>
                        <DebouncedSaveButton 
                            onSave={handleSubmit} 
                            isProcessing={isProcessing}
                        />
                    </ModalFooter>
                </Form>
            </Modal>

            <ViewImprovementOpportunityModal
                isOpen={viewModalOpen}
                toggle={() => setViewModalOpen(!viewModalOpen)}
                improvementOpportunity={selectedViewImprovementOpportunity}
            />

            <Modal isOpen={deleteModalVisible} toggle={() => setDeleteModalVisible(false)}>
                <ModalHeader toggle={() => setDeleteModalVisible(false)}>Confirm Delete</ModalHeader>
                <ModalBody>
                    <p>Are you sure you want to delete this improvementOpportunity?</p>
                    
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setDeleteModalVisible(false)}>Cancel</Button>
                    <Button color="danger" onClick={confirmDelete}>Delete</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={confirmModalOpen} toggle={() => setConfirmModalOpen(false)}>
                <ModalHeader toggle={() => setConfirmModalOpen(false)}>Confirm Submission</ModalHeader>
                <ModalBody>
                    <p>Are you sure you want to submit this improvementOpportunity?</p>
                    <p style={{ color: 'red' }}>
                        Note: Once the ImprovementOpportunity is submitted it cannot be edited.
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setConfirmModalOpen(false)}>Cancel</Button>
                    <Button color="primary" onClick={confirmGenerate}>Yes, Submit</Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={actionModalOpen}
                toggle={toggleActionModal}
                size="xl"
            >
                <ModalHeader toggle={toggleActionModal}>
                    Improvement Oppourtionity Number: {selectedImprovementOpportunity?.sequenceNo}
                </ModalHeader>
                <ModalBody>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Action Type</th>
                                <th>Submit Date</th>
                                <th>From</th>
                                <th>Message Description</th>
                                <th>To</th>
                                <th>Response Message</th>
                                <th>Response On</th>
                                <th>Response By</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedImprovementOpportunity?.actions?.map((action, index) => (
                                <tr key={index}>
                                    <td>
                                        <Button color="primary" size="sm" onClick={() => handleInitiate(action)}>
                                            Initiate
                                        </Button>
                                    </td>
                                    <td>{action.actionType}</td>
                                    <td>{action.submittedAt}</td>
                                    <td>{action.createdByUser}</td>
                                    <td>{action.description}</td>
                                    <td>{action.createdFor}</td>
                                    <td>{action.reply}</td>
                                    <td>{action.responseAt ?? "-"}</td>
                                    <td>{action.responseByUser ?? "-"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleActionModal}>Close</Button>
                </ModalFooter>
            </Modal>

            <ActionModal
                isOpen={actionModalOpen}
                toggle={toggleActionModal}
                selectedHazard={selectedImprovementOpportunity}
                message={"Action For Hazard"}
                onInitiateAction={handleInitiateAction}
            />
        </div>

        //Confirm Dialog

    );
}

export default ImprovementOpportunities;