import axios from "../../../axios";
import { GET_QC_DATA, GET_QCFORMVALUES, GET_QCVALUES, UPDATE_QC,SAVE_QC} from "../../types";
import { setAlert } from "../alert";

export const getQCCheckListValues = () => async (dispatch) => {
    try {
      const res = await axios.get("inform/qc/values");
      // console.log(res.data)
      if (res.data.code === 1) {
        dispatch({ type: GET_QCVALUES, payload: res.data.data });
      }
    } catch (err) {
      dispatch(setAlert(err.message, "danger"));
    }
};

export const getQCFormValues=(route)=>async(dispatch)=>{
    try {
        const res = await axios.get(`${route}values`);
        if (res.data.code === 1) {
          dispatch({ type: GET_QCFORMVALUES, payload: res.data.data });
        }
      } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}
export const clearQCFormValues=()=>async(dispatch)=>{
  try {
      dispatch({ type: GET_QCFORMVALUES, payload: [] });
    } catch (err) {
      dispatch(setAlert(err.message, "danger"));
  }
}
export const clearQCData=()=>async(dispatch)=>{
  try {
      dispatch({ type: GET_QC_DATA, payload: [] });
    } catch (err) {
      dispatch(setAlert(err.message, "danger"));
  }
}
export const getQCData=(route,accessModuleId)=>async(dispatch)=>{
    try {
        const object={
          moduleId:accessModuleId
        }
        // console.log(object)
        
        const res = await axios.post(`${route}user`,object);
        // console.log("QC DATA")
        // console.log(res)
        if (res.data.code === 1) {
          dispatch({ type: GET_QC_DATA, payload: res.data.data });
        }
      } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const addQCForm=(route,object)=>async(dispatch)=>{
  try {
    const config = {
      headers: {
          "Content-Type": "multipart/form-data",
      },
    };
    const res = await axios.post(`${route}add`,object,config);
    
    if (res.data.code === 1) {
      dispatch({ type: SAVE_QC, payload: res.data.data });
      return {message:res.data.message,success:true}
    }
  } catch (err) {
    return {message:'Contact to Admin',success:false}
  }
}
export const updateQCForm=(route,object)=>async(dispatch)=>{
  try{
    const config = {
      headers: {
          "Content-Type": "multipart/form-data",
      },
    };
    const res = await axios.post(`${route}update`,object,config);
    if (res.data.code === 1) {
      dispatch({ type: UPDATE_QC, payload: res.data.data });
      return {message:res.data.message,success:true}
    }
  }
  catch(err){
    return {message:'Contact to Admin',success:false}
    
  }
}

export const submitQCToGenerate=(route,id)=>async(dispatch)=>{
    try {
      const res = await axios.get(`${route}submit/${id}`);     
      if (res.data.code === 1) {
          return {success:true,message:res.data.message}
      }
      else{
        return {success:false,message:res.data.message}
      }
  } catch (err) {
    return {success:false,message:'Contact to Admin'}
  }
}

export const submitActionForQC = (route,formData) => async (dispatch) => {
  try {
      const res = await axios.post(`${route}action/update`, formData);
      if (res.data.code === 1) {
          return {success: true, message: res.data.message};
      } else {
        return {success: false, message: res.data.message};
      }
  } catch (err) {
      return {success: false, message: err.response?.data?.message || "Contact Admin"};
  }
}

