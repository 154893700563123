import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table
} from "reactstrap";
import { DeleteOutlined, EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import ReactStrapDataTable from "../../CustomDatatable/ReactStrapDataTable";
import { getTemplates, getTemplateValues, addTemplate, updateTemplate, deleteTemplate } from "../../../store/actions/Template";
import { dateTimeSort, stringSorter } from "../../../utils/functions";
import fileDownload from "react-file-download";
import Select from "react-select";

const AttachmentsSection = ({ attachments = [], onAttachmentsChange }) => {
    const [internalKeys, setInternalKeys] = useState({});

    useEffect(() => {
        // Initialize internalKeys with existing attachments
        const initialKeys = attachments.reduce((acc, attachment, index) => {
            acc[`attachment_${index}`] = attachment;
            return acc;
        }, {});
        setInternalKeys(initialKeys);
    }, []);

    const generateKey = () =>
        `attachment_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

    const addNewRow = () => {
        const newAttachment = {
            filename: "",
            name: "",
            description: "",
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
            file: null,
            path: null,
        };
        const newKey = generateKey();
        setInternalKeys((prev) => ({ ...prev, [newKey]: newAttachment }));
        onAttachmentsChange([...attachments, newAttachment]);
    };

    const updateAttachment = (key, field, value) => {
        const updatedAttachments = Object.values(internalKeys).map((att, index) =>
            att === internalKeys[key] ? { ...att, [field]: value } : att,
        );
        setInternalKeys((prev) => ({
            ...prev,
            [key]: { ...prev[key], [field]: value },
        }));
        onAttachmentsChange(updatedAttachments);
    };

    const handleFileChange = (key, file) => {
        if (file && file.size > 5 * 1024 * 1024) {
            alert("File size should not exceed 5MB");
            return;
        }

        const updatedAttachment = {
            ...internalKeys[key],
            filename: file.name,
            name: file.name,
            file: file,
            path: null,
        };
        setInternalKeys((prev) => ({
            ...prev,
            [key]: updatedAttachment,
        }));
        const updatedAttachments = Object.values(internalKeys).map((att) =>
            att === internalKeys[key] ? updatedAttachment : att,
        );
        onAttachmentsChange(updatedAttachments);
    };

    const deleteAttachment = (key) => {
        const filteredAttachments = Object.values(internalKeys).filter(
            (att) => att !== internalKeys[key],
        );
        setInternalKeys((prev) => {
            const newKeys = { ...prev };
            delete newKeys[key];
            return newKeys;
        });
        onAttachmentsChange(filteredAttachments);
    };

    const viewAttachment = (attachment) => {
        if (attachment.file instanceof File) {
            const url = URL.createObjectURL(attachment.file);
            window.open(url, "_blank");
        } else if (attachment.fullPath || attachment.path) {
            fetch(`${encodeURIComponent(attachment.fullPath || attachment.path)}`)
                .then((response) => response.blob())
                .then((blob) => {
                    fileDownload(
                        blob,
                        attachment.fileName || attachment.filename || "download",
                    );
                })
                .catch((error) => {
                    console.error("Error downloading file:", error);
                    alert("Error downloading file. Please try again.");
                });
        }
    };

    return (
        <Card
            style={{
                border: "2px solid #ced4da",
                borderRadius: "5px",
                boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            }}
        >
            <CardHeader
                style={{
                    backgroundColor: "#e6f2ff",
                    borderBottom: "1px solid #b8daff",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <h2 className="card-title mb-0">Attachments</h2>
                <Button color="primary" size="sm" onClick={addNewRow}>
                    <i className="fas fa-plus"></i>
                </Button>
            </CardHeader>
            <CardBody>
                <Table>
                    <thead>
                        <tr>
                            <th>File Name</th>
                            <th>Description</th>
                            <th>Upload File</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(internalKeys).map(([key, attachment]) => (
                            <tr key={key}>
                                <td>{attachment.filename || attachment.name}</td>
                                <td>
                                    <Input
                                        type="text"
                                        value={attachment.description}
                                        onChange={(e) =>
                                            updateAttachment(key, "description", e.target.value)
                                        }
                                        style={{ borderColor: "black" }}
                                    />
                                </td>
                                <td>
                                    <FormGroup>
                                        <Label for={`file-${key}`} className="btn btn-secondary">
                                            {attachment.file || attachment.path
                                                ? "Change File"
                                                : "Upload File"}
                                        </Label>
                                        <Input
                                            type="file"
                                            id={`file-${key}`}
                                            onChange={(e) => handleFileChange(key, e.target.files[0])}
                                            style={{ display: "none" }}
                                        />
                                    </FormGroup>
                                </td>
                                <td>
                                    <Button
                                        color="secondary"
                                        size="sm"
                                        className="mr-2"
                                        onClick={() => viewAttachment(attachment)}
                                        disabled={!attachment.file && !attachment.path}
                                    >
                                        {attachment.fullPath || attachment.path
                                            ? "Download"
                                            : "View"}
                                    </Button>
                                    <Button
                                        color="danger"
                                        size="sm"
                                        onClick={() => deleteAttachment(key)}
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
};

const Template = () => {
    const dispatch = useDispatch();
    const templates = useSelector((state) => state.template.list);
    const templateValues = useSelector((state) => state.template.values);

    const [attachments, setAttachments] = useState([]);

    const [modalOpen, setModalOpen] = useState(false);
    const [editingTemplate, setEditingTemplate] = useState(null);

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        dispatch(getTemplates());
        dispatch(getTemplateValues());
    }, [dispatch]);

    const handleEdit = (template) => {
        // console.log(template)
        const formattedTemplate = {
          template:template.name,
          code:template.code,
          templateCategory1: { value: template.tc1Id, label: template.templateCategory1 },
          templateCategory2: { value: template.tc2Id, label: template.templateCategory2 },
          templateCategory3: { value: template.tc3Id, label: template.templateCategory3 },
          fileId:template.fileId,
        };

        if (template.files !== null) {
            const formattedAttachments = template.files.map((file) => ({
              id: file.id,
              filename: file.fileName,
              name: file.fileName,
              description: file.description,
              path: file.path,
              fullPath: file.fullPath,
              createdAt: file.createdAt,
              createdBy: file.createdBy,
              updatedAt: file.updatedAt,
              updatedBy: file.updatedBy,
            }));
            setAttachments(formattedAttachments);
          } else {
            setAttachments([]);
        }
      
    
        setEditingTemplate(template);
        reset(formattedTemplate);
        setModalOpen(true);
      };

    const handleDelete = async (template) => {
        if (window.confirm("Are you sure you want to delete this template?")) {
            await dispatch(deleteTemplate(template.id));
            dispatch(getTemplates());
        }
    };

    const onFormSubmit = async (data) => {
        
        const formDataObj = new FormData();
      
        const mappedData = {
          name: data.template,
          code: data.code,
          tc1Id: data.templateCategory1.value,
          tc2Id: data.templateCategory2.value,
          tc3Id: data.templateCategory3.value,
        };

        // Append mapped data to FormData
        Object.keys(mappedData).forEach((key) => {
          formDataObj.append(key, mappedData[key]);
        });
      
        // Handle attachments
        if (Array.isArray(attachments)) {
          const filesMetadata = attachments.map((attachment) => ({
            id: attachment.id || null,
            fileName: attachment.filename || attachment.name || "",
            description: attachment.description || null,
            path: attachment.path || null,
            createdAt: attachment.createdAt || null,
            createdBy: attachment.createdBy || null,
            updatedBy: attachment.updatedBy || null,
            updatedAt: attachment.updatedAt || null,
          }));
          formDataObj.append("files", JSON.stringify(filesMetadata));
      
          attachments.forEach((attachment, index) => {
            if (attachment.file instanceof File) {
              formDataObj.append(
                `files[${index}]`,
                attachment.file,
                attachment.filename || attachment.name,
              );
            }
          });
        } else {
          formDataObj.append("files", JSON.stringify([]));
        }
    
        let result;
        if (editingTemplate) {
          formDataObj.append("id", editingTemplate.id);
          formDataObj.append("fileId", editingTemplate.fileId);
          result = await dispatch(updateTemplate(formDataObj));
        } else {
          result = await dispatch(addTemplate(formDataObj));
        }
      
        if (result) {
          handleCloseModal();
          dispatch(getTemplates()); // Refresh the template list
        }
      };

    const handleCloseModal = () => {
        setModalOpen(false);
        setEditingTemplate(null);
        reset();
    };

    const handleAddNew = () => {
        setEditingTemplate(null);
        reset({ template: '', code: '', templateCategory1: '', templateCategory2: '', templateCategory3: '' });
        setAttachments([])
        setModalOpen(true);
    };

    const columns = [
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: dateTimeSort("createdAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Template",
            dataIndex: "name",
            key: "name",
            sorter: stringSorter("name"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Code",
            dataIndex: "code",
            key: "code",
            sorter: stringSorter("code"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Template Category 1",
            dataIndex: "templateCategory1",
            key: "templateCategory1",
            sorter: stringSorter("templateCategory1"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Template Category 2",
            dataIndex: "templateCategory2",
            key: "templateCategory2",
            sorter: stringSorter("templateCategory2"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Template Category 3",
            dataIndex: "templateCategory3",
            key: "templateCategory3",
            sorter: stringSorter("templateCategory3"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
                <>
                    <Button color="link" onClick={() => handleEdit(record)}>
                        <EditOutlined />
                    </Button>
                    <Button color="link" onClick={() => handleDelete(record)}>
                        <DeleteOutlined />
                    </Button>
                </>
            ),
        },
    ];

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">Manage Templates</h5>
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" onClick={handleAddNew}>
                                <PlusCircleFilled /> Add New
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactStrapDataTable
                        dataSource={templates}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal} size="xl" backdrop="static">
                <ModalHeader toggle={handleCloseModal}>
                    {editingTemplate ? 'Edit Template' : 'Add Template'}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <ModalBody>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="template">Template</Label>
                                    <Controller
                                        name="template"
                                        control={control}
                                        rules={{ required: "Template is required" }}
                                        render={({ field }) => <Input {...field} id="template" />}
                                    />
                                    {errors.template && <span className="text-danger">{errors.template.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="code">Code</Label>
                                    <Controller
                                        name="code"
                                        control={control}
                                        rules={{ required: "Code is required" }}
                                        render={({ field }) => <Input {...field} id="code" />}
                                    />
                                    {errors.code && <span className="text-danger">{errors.code.message}</span>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="templateCategory1">Template Category 1</Label>
                                    <Controller
                                        name="templateCategory1"
                                        control={control}
                                        rules={{ required: "Template Category 1 is required" }}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                options={templateValues.templateCategories1.map(category => ({
                                                    value: category.id,
                                                    label: category.name
                                                }))}
                                                placeholder="Select Category 1"
                                                isClearable
                                            />
                                        )}
                                    />
                                    {errors.templateCategory1 && <span className="text-danger">{errors.templateCategory1.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="templateCategory2">Template Category 2</Label>
                                    <Controller
                                        name="templateCategory2"
                                        control={control}
                                        rules={{ required: "Template Category 2 is required" }}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                options={templateValues.templateCategories2.map(category => ({
                                                    value: category.id,
                                                    label: category.name
                                                }))}
                                                placeholder="Select Category 2"
                                                isClearable
                                            />
                                        )}
                                    />
                                    {errors.templateCategory2 && <span className="text-danger">{errors.templateCategory2.message}</span>}
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="templateCategory3">Template Category 3</Label>
                                    <Controller
                                        name="templateCategory3"
                                        control={control}
                                        rules={{ required: "Template Category 3 is required" }}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                options={templateValues.templateCategories3.map(category => ({
                                                    value: category.id,
                                                    label: category.name
                                                }))}
                                                placeholder="Select Category 3"
                                                isClearable
                                            />
                                        )}
                                    />
                                    {errors.templateCategory3 && <span className="text-danger">{errors.templateCategory3.message}</span>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <AttachmentsSection
                            attachments={attachments}
                            onAttachmentsChange={setAttachments}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {editingTemplate ? 'Update' : 'Save'}
                        </Button>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
};

export default Template;