import axios from "../../../axios";
import {setAlert} from "../alert";
import {ADD_CITY, DELETE_CITY, GET_CITIES, GET_CITY, UPDATE_CITY, UPDATE_COUNTRY} from "../../types";
import {message} from "antd";



// Add City
export const addCity = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("city/add", formData);
        if (res.data.code === 1) {
            dispatch({ type: ADD_CITY, payload: res.data.data });
            message.success(res.data.message);
        } else {
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const updateCityStatus = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("city/status", formData);
        if (res.data.code === 1) {
            dispatch({type: UPDATE_COUNTRY, payload: res.data.data});
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
};

// Get getCities
export const getCities = () => async (dispatch) => {
    try {
        const res = await axios.get("city/all");
        if (res.data.code === 1) {
            dispatch({ type: GET_CITIES, payload: res.data.data });
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

// Get Single City
export const getCity = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`city/${id}`);
        if (res.data.code === 1) {
            dispatch({ type: GET_CITY, payload: res.data.data });

        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
};

// Clear Single City
export const clearSingleCity = () => async (dispatch) => {
    try {
        dispatch({ type: GET_CITY, payload: '' });
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
};

// Update City
export const updateCity = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("city/update", formData);
        if (res.data.code === 1) {
            dispatch({ type: UPDATE_CITY, payload: res.data.data });
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
};

// Delete City
export const deleteCity = (id) => async (dispatch) => {
    try {
        if(!id){
            dispatch(setAlert('Please select any record', 'warning'));
            return false;
        }
        const res = await axios.get(`city/delete/${id}`);
        if (res.data.code === 1) {
            dispatch({ type: DELETE_CITY, payload: {id} });
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
};



