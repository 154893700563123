import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getItems, addItem, updateItem, deleteItem } from "../../store/actions/item";
import {dateTimeSort, numberSort, stringSorter} from "../../utils/functions";
import DataTable from "../../components/CustomDatatable";
import { Modal, Input, InputNumber, Button, Card, Space, message, Typography, Row, Col } from 'antd';
import { useForm, Controller } from "react-hook-form";
import { PlusOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons';
import {enhanceColumnsWithFilters} from "../../components/ColumnFilters";

const { TextArea } = Input;
const { Title } = Typography;

const Items = () => {
    document.title = "HSSE Meinhardt EPCM | Items";
    const dispatch = useDispatch();
    const items = useSelector((state) => state.item.list);

    const [modalOpen, setModalOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        defaultValues: {
            name: "",
            rate: 0,
            description: ""
        }
    });

    const handleStatus = async (id, status) => {
        await dispatch(updateItem({ id, status: status === 1 ? 0 : 1 }));
        await dispatch(getItems());
    };

    const onFormSubmit = async (formData) => {
        try {
            if (currentItem) {
                await dispatch(updateItem({ ...formData, id: currentItem.id }));
            } else {
                await dispatch(addItem(formData));
            }
            handleCloseModal();
            message.success(`Item ${currentItem ? 'updated' : 'added'} successfully`);
            dispatch(getItems());
        } catch (error) {
            message.error('An error occurred. Please try again.');
        }
    };

    const handleEdit = (item) => {
        setCurrentItem(item);
        Object.keys(item).forEach(key => {
            setValue(key, item[key]);
        });
        setModalOpen(true);
    };

    const handleCloseModal = useCallback(() => {
        reset();
        setCurrentItem(null);
        setModalOpen(false);
    }, [reset]);

    const handleDelete = (item) => {
        setItemToDelete(item);
        setDeleteModalVisible(true);
    };

    const confirmDelete = async () => {
        try {
            await dispatch(deleteItem(itemToDelete.id));
            message.success('Item deleted successfully');
            setDeleteModalVisible(false);
            dispatch(getItems());
        } catch (error) {
            message.error('An error occurred while deleting the item');
        }
    };

    let columns = [
        {
            title: "Sr. No",
            key: 'srno',
            render: (text, record, index) => index + 1,
            width: '80px',
        },
        {
            title: "CreatedAt",
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: dateTimeSort("createdAt"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: stringSorter("name"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Rate",
            dataIndex: "rate",
            key: "rate",
            render: (text) => `${parseFloat(text).toFixed(2)}`,
            sorter:numberSort("rate"),
            sortDirections: ["ascend", "descend"],
            width: '120px',
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            ellipsis: true,
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            width: '120px',
            render: (text, item) => (
                <Button
                    type={item?.status === 1 ? "primary" : "default"}
                    onClick={() => handleStatus(item.id, item.status)}
                >
                    {item?.status === 1 ? "Active" : "Inactive"}
                </Button>
            )
        },
        {
            title: "Action",
            key: "action",
            width: '200px',
            render: (text, item) => (
                <Space>
                    <Button type="primary" icon={<EditOutlined />} onClick={() => handleEdit(item)}>
                        Edit
                    </Button>
                    <Button danger icon={<DeleteOutlined />} onClick={() => handleDelete(item)}>
                        Delete
                    </Button>
                </Space>
            ),
        },
    ];
    columns=enhanceColumnsWithFilters(columns)

    useEffect(() => {
        dispatch(getItems());
    }, [dispatch]);

    return (
        <div className="page-content">
            <Card>
                <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                    <Row justify="space-between" align="middle">
                        <Col>
                            <Title level={2} style={{ margin: 0 }}>Manage Items</Title>
                        </Col>
                        <Col>
                            <Button type="primary" icon={<PlusOutlined />} onClick={() => setModalOpen(true)}>
                                Add Item
                            </Button>
                        </Col>
                    </Row>

                    <DataTable
                        dataSource={items.map((item, index) => ({
                            ...item,
                            key: index,
                        }))}
                        columns={columns}
                    />
                </Space>
            </Card>

            <Modal
                title={currentItem ? 'Edit Item' : 'Add Item'}
                visible={modalOpen}
                onCancel={handleCloseModal}
                footer={null}
                zIndex={100000000}
            >
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                        <div>
                            <label>Name</label>
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: "Name is required" }}
                                render={({ field }) => <Input {...field} />}
                            />
                            {errors.name && <span style={{ color: 'red' }}>{errors.name.message}</span>}
                        </div>

                        <div>
                            <label>Rate</label>
                            <Controller
                                name="rate"
                                control={control}
                                rules={{
                                    required: "Rate is required",
                                    min: { value: 0, message: "Rate must be greater than or equal to 0" },
                                    validate: {
                                        isNumber: v => !isNaN(parseFloat(v)) || "Rate must be a number",
                                        twoDecimals: v => /^\d+(\.\d{1,2})?$/.test(v) || "Rate can have up to 2 decimal places"
                                    }
                                }}
                                render={({ field }) => <InputNumber {...field} style={{ width: '100%' }} step="0.01" precision={2} />}
                            />
                            {errors.rate && <span style={{ color: 'red' }}>{errors.rate.message}</span>}
                        </div>

                        <div>
                            <label>Description</label>
                            <Controller
                                name="description"
                                control={control}
                                rules={{
                                    maxLength: { value: 500, message: "Description cannot be longer than 500 characters" },
                                    validate: {
                                        notWhitespace: v => (v && v.trim().length > 0) || "Description cannot be only whitespace"
                                    }
                                }}
                                render={({ field }) => <TextArea {...field} rows={4} />}
                            />
                            {errors.description && <span style={{ color: 'red' }}>{errors.description.message}</span>}
                        </div>

                        <Space>
                            <Button type="primary" htmlType="submit">
                                {currentItem ? "Update" : "Save"}
                            </Button>
                            <Button onClick={handleCloseModal}>
                                Cancel
                            </Button>
                        </Space>
                    </Space>
                </form>
            </Modal>

            <Modal
                title="Confirm Delete"
                zIndex={100000000}
                visible={deleteModalVisible}
                onCancel={() => setDeleteModalVisible(false)}
                footer={[
                    <Button key="cancel" onClick={() => setDeleteModalVisible(false)}>
                        Cancel
                    </Button>,
                    <Button key="delete" danger onClick={confirmDelete}>
                        Delete
                    </Button>,
                ]}
            >
                <p>Are you sure you want to delete this item?</p>
                {itemToDelete && (
                    <p>
                        <strong>Item Name:</strong> {itemToDelete.name}
                    </p>
                )}
            </Modal>
        </div>
    );
}

export default Items;