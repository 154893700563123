import axios from "../../../axios";
import {setAlert} from "../alert";
import {ADD_PROJECT, DELETE_PROJECT, GET_PROJECTS, GET_PROJECT, UPDATE_PROJECT, GET_PROJECT_VALUES} from "../../types";
import {message} from "antd";

export const addProject = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("project/add", formData);
        if (res.data.code === 1) {
            dispatch({ type: ADD_PROJECT, payload: res.data.data });
            message.success(res.data.message);
        } else {
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const updateProjectStatus = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("project/status", formData);
        if (res.data.code === 1) {
            dispatch({type: UPDATE_PROJECT, payload: res.data.data});
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
};

// Get getCities
export const getProjects = () => async (dispatch) => {
    try {
        const res = await axios.get("project/all");
        if (res.data.code === 1) {
            dispatch({ type: GET_PROJECTS, payload: res.data.data });
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const getProjectValues= () => async (dispatch) => {
    try {
        const res = await axios.get("project/values");
        ;
        if (res.data.code === 1) {
            dispatch({ type: GET_PROJECT_VALUES, payload: res.data.data });
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

// Get Single Project
export const getProject = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`project/${id}`);
        if (res.data.code === 1) {
            dispatch({ type: GET_PROJECT, payload: res.data.data });

        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
};

// Clear Single Project
export const clearSingleProject = () => async (dispatch) => {
    try {
        dispatch({ type: GET_PROJECT, payload: '' });
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
};

// Update Project
export const updateProject = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("project/update", formData);
        if (res.data.code === 1) {
            dispatch({ type: UPDATE_PROJECT, payload: res.data.data });
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
};

// Delete Project
export const deleteProject = (id) => async (dispatch) => {
    try {
        if(!id){
            dispatch(setAlert('Please select any record', 'warning'));
            return false;
        }
        const res = await axios.get(`project/delete/${id}`);
        if (res.data.code === 1) {
            dispatch({ type: DELETE_PROJECT, payload: {id} });
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
};



