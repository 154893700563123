import axios from "../../../axios";
import {
    ADD_IMPROVEMENT_OPPORTUNITY,
    ADD_ITEM, DELETE_IMPROVEMENT_OPPORTUNITY, GENERATE_IMPROVEMENT_OPPORTUNITY, GET_ACTION_IMPROVEMENT_OPPORTUNITY,
    GET_IMPROVEMENT_OPPORTUNITY_VALUES,
    GET_SINGLE_USER_IMPROVEMENT_OPPORTUNITY,
    GET_USER,
    GET_USER_IMPROVEMENT_OPPORTUNITY,
    UPDATE_IMPROVEMENT_OPPORTUNITY
} from "../../types";
import {setAlert} from "../alert";
import {message} from "antd";

export const getImprovementOpportunities = () => async (dispatch) => {
    try {
        const res = await axios.get("/improvement/values");
        if (res.data.code === 1) {
            dispatch({type: GET_IMPROVEMENT_OPPORTUNITY_VALUES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const submitImprovementOpportunity = (object) => async (dispatch) => {
    try {
        const res = await axios.post(`/improvement/submit/`,object);
        if (res.data.code === 1) {
            dispatch({type: GENERATE_IMPROVEMENT_OPPORTUNITY, payload: res.data.data});
        }
        return res
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const addImprovementOpportunityForm = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/improvement/add", formData, config);
        if (res.data.code === 1) {
            dispatch({type: ADD_IMPROVEMENT_OPPORTUNITY, payload: res.data.data});
            return {success: true, message: res.data.message};
        } else {
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        console.error("Error in addImprovementOpportunityForm:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
};

export const updateImprovementOpportunityForm = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("/improvement/update", formData, config);
        
        //message.info(res.data.message)
        if (res.data.code === 1) {
            dispatch({type: UPDATE_IMPROVEMENT_OPPORTUNITY, payload: res.data.data});
            return {success: true, message: res.data.message};
        } else {
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        console.error("Error in addImprovementOpportunityForm:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
};

export const deleteImprovementOppourtionity = (id) => async (dispatch) => {
    try {
        const res = await axios.delete(`/improvement/delete/${id}`);
        if (res.data.code === 1) {
            dispatch({ type: DELETE_IMPROVEMENT_OPPORTUNITY, payload: id });
            return { success: true, message: res.data.message };
        } else {
            return { success: false, message: res.data.message };
        }
    } catch (err) {
        console.error("Error in deleteNonconformity:", err);
        return { success: false, message: err.response?.data?.message || "An error occurred" };
    }
};


export const getUserImprovementOpportunities = (accessModuleId) => async (dispatch) => {
    try {
        const object={
            moduleId:accessModuleId
        }
        const res = await axios.post(`/improvement/user`,object);
        if (res.data.code === 1) {
            dispatch({type: GET_USER_IMPROVEMENT_OPPORTUNITY, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const getUserSingleImprovementOpportunity = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/improvement/${id}`);
        // console.log(res.data.data)
        if (res.data.code === 1) {
            dispatch({type: GET_SINGLE_USER_IMPROVEMENT_OPPORTUNITY, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }

}

export const submitActionForImprovementOpportunity = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/improvement/action/update", formData);

        if (res.data.code === 1) {
            message.success(res.data.message);
            //dispatch({type: GET_USER_IMPROVEMENT_OPPORTUNITY, payload: res.data.data});
            return {success: true, message: res.data.message};
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        console.error("Error in addImprovementOpportunityForm:", err);
        return {success: false, message: err.response?.data?.message || "Contact Admin"};
    }
}