import {ADD_QUESTION,GET_QUESTION, GET_QUESTIONS, UPDATE_QUESTION,GET_COURSE_QUESTIONS} from "../../types";


const initialState = {list: [], single: null,questions:[]};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_QUESTIONS:
            return {...state, list: payload};
        case GET_COURSE_QUESTIONS:
            return {...state, questions: payload};
        case GET_QUESTION:
            return {
                ...state,
                list: [payload, ...state.list],
            };
        case ADD_QUESTION:
            return {
                ...state,
                list: [payload, ...state.list],
            };
        case UPDATE_QUESTION:
            return {
                ...state,
                list: state.list.map((item) =>
                    item.id === payload.id ? payload : item
                ),
            };

        default:
            return state;
    }
};

export default reducer;
