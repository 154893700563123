import {
    PERMIT_FILTER_VALUES,
    PERMIT_FILTER
} from "../../../types";

const initialState = {
    ptwFilters:[],ptwValues:[]
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case PERMIT_FILTER_VALUES:
            return { ...state, ptwValues: payload };
        case PERMIT_FILTER:
            return { ...state, ptwFilters: payload };
        default:
            return state;
    }
};

export default reducer;